import styled from '../../../../styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Card = styled.div`
  align-items: center;
  background-color: ${themeGet('colors.surface')};
  border-radius: 4px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 8px;
  width: min-content;
  min-width: 100%;
  color: ${themeGet('colors.surfaceOn')};
`;
