import { Contact, FilesMessage, MessagesEntity } from '@getgo/caps-redux';
import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { User } from '../../../../types/pulse-web';
import { State } from '../../../view-model/types';
import MessageList from './MessageList/MessageList';
import { LoadMoreEntry } from './LoadMoreEntry/LoadMoreEntry';
import './MessageHistory.css';

let scrollBarIsAtBottom = true;

export interface Props {
  app: State['core']['app'];
  children?: React.ReactElement<any>;
  contact: Contact;
  hasMoreMessages?: boolean;
  isOnline?: boolean;
  isPartialLoading: boolean;
  messageInputHeight?: number;
  messages: MessagesEntity[];
  onJoinMeeting: (meetingUrl: string) => void;
  onLoadMore: () => void;
  onNewReplaceMessage: (originalMessage: string, text: string, options: {}) => void;
  onPreviewOpen: (message: FilesMessage) => void;
  today: Date | string;
  user: User;
}

class MessageHistory extends React.PureComponent<Props & WrappedComponentProps, {}> {
  private scrollTop: number;
  private scrollHeight: number;
  private preserveScrollPos: boolean;
  private div: HTMLDivElement | null = null;

  constructor(props: Props & WrappedComponentProps) {
    super(props);
    this.scrollTop = 0;
    this.scrollHeight = 0;
    this.preserveScrollPos = false;
  }

  componentDidMount() {
    this.scrollToBottom();
    window.addEventListener('wheel', this.handleScrollWheel);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.contact.jid !== nextProps.contact.jid) {
      this.scrollTop = 0;
      this.scrollHeight = 0;
      this.preserveScrollPos = false;
    } else {
      this.preserveScrollPos = true;
    }

    if (this.props.messageInputHeight !== nextProps.messageInputHeight && scrollBarIsAtBottom) {
      setTimeout(() => {
        this.scrollToBottom();
      }, 50);
    }
  }

  UNSAFE_componentWillUpdate() {
    if (this.div) {
      this.scrollTop = this.div.scrollTop;
      this.scrollHeight = this.div.scrollHeight;
    }
  }

  componentDidUpdate() {
    if (this.preserveScrollPos) {
      this.adjustScrollPosition();
    } else {
      this.scrollToBottom();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', this.handleScrollWheel);
  }

  scrollBarIsAtBottom = () => {
    return !!this.div && this.div.clientHeight + this.div.scrollTop === this.div.scrollHeight;
  };

  handleScrollWheel = () => {
    scrollBarIsAtBottom = this.scrollBarIsAtBottom();
  };

  adjustScrollPosition() {
    // only if the component has not been unmounted
    if (this.div) {
      // keep the scroll position at the same place
      const wasAtBottom = this.div.clientHeight + this.scrollTop === this.scrollHeight;
      if (wasAtBottom) {
        this.div.scrollTop = this.div.scrollHeight;
      } else {
        this.div.scrollTop += this.div.scrollHeight - this.scrollHeight;
      }
    }
  }

  scrollToBottom = () => {
    if (this.div) {
      this.div.scrollTop = this.div.scrollHeight;
    }
  };

  render() {
    const { hasMoreMessages = false } = this.props;
    return (
      <div className="message-history" onWheel={this.handleScrollWheel} ref={(c) => (this.div = c)}>
        <div className="inner-align">
          {hasMoreMessages ? <LoadMoreEntry {...this.props} /> : null}
          <MessageList {...this.props} />
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default injectIntl(MessageHistory);
