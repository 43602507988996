import { connect } from 'react-redux';
import { GroupActions, GroupContact, Selectors } from '@getgo/caps-redux';
import { Contact } from '../../types/pulse-web';
import { navigateTo } from '../services/NavService';
import AddMembers from '../components/Chat/AddMembers/AddMembers';
import errorActions from '../view-model/modules/core/errors/errorActions';
import { clearQuery, queryAllPeople } from '../view-model/modules/messaging/people/peopleActions';
import { Dispatch, State } from '../view-model/types';

const mapStateToProps = (state: State) => {
  const currentRoomId = Selectors.getCurrentRoomId(state);

  return {
    people: state.messaging.people,
    contact: Selectors.findContact(state, currentRoomId) as GroupContact
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onAddMembers: (groupJid: string, members: Contact[]) => {
      if (members.length) {
        dispatch(GroupActions.inviteMembers(groupJid, members));
        dispatch(GroupActions.updateGroupMembers(groupJid));
      }

      navigateTo('chat');
    },

    onCancel: () => {
      dispatch(clearQuery());
      navigateTo('chat');
    },

    onQuery: (query?: string, nextPageKey?: string) => {
      if ((query?.trim && query.trim().length) || Number(nextPageKey) > 0) {
        dispatch(errorActions.clearSocialGraphError());
        dispatch(queryAllPeople(query, Number(nextPageKey)));
      } else {
        dispatch(clearQuery());
      }
    },

    clearQuery: () => {
      dispatch(clearQuery());
    }
  };
};

const AddMembersContainer = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AddMembers);
export default AddMembersContainer;
