import * as t from 'io-ts';
import loggerService from '../services/LoggerService';
import config from 'config';
import rest from '../../lib/rest';
import { timeout } from '../../lib/async';

const logger = loggerService.create('PushNotificationService');

export interface PushNotificationsRegistrationBase {
  endpoint: string;
  referenceId: string;
}

// Notification service response
// https://confluence.ops.expertcity.com/pages/viewpage.action?pageId=107971285#NOSUserGuide(APIs)-Request(Web)
const TCreateSubscription = t.type({
  referenceId: t.string
});

// from https://developers.google.com/web/fundamentals/getting-started/codelabs/push-notifications/
function base64UrlToUint8Array(base64UrlData: string) {
  const padding = '='.repeat((4 - (base64UrlData.length % 4)) % 4);
  const base64 = (base64UrlData + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const buffer = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    buffer[i] = rawData.charCodeAt(i);
  }

  return buffer;
}

function setRegistration(svc: PushNotificationService, registration: PushNotificationsRegistrationBase | null) {
  svc.registration = registration;
  svc.onRegistrationChange(registration);
}

async function subscribeAndRegister(svc: PushNotificationService, pushManager: PushManager) {
  const subscription = await timeout(
    pushManager.subscribe({
      applicationServerKey: svc.applicationServerKey,
      userVisibleOnly: true
    }),
    config.notifications.browserApiTimeout
  );

  let response = null;
  try {
    const subscriptionJson = subscription.toJSON();
    if (!subscriptionJson.keys || !subscriptionJson.keys.auth || !subscriptionJson.keys.p256dh) {
      throw new Error('Browser does not support encrypted payload');
    }

    response = await rest.postValidated(TCreateSubscription, `${config.notifications.url}/v1/registration`, {
      type: 'web',
      applicationId: config.notifications.applicationId,
      subscription: subscriptionJson
    });
  } catch (e) {
    await subscription.unsubscribe();
    throw e;
  }

  setRegistration(svc, {
    endpoint: subscription.endpoint,
    referenceId: response.referenceId
  });
}

async function unregister(svc: PushNotificationService) {
  if (!svc.registration) {
    return;
  }

  const refId = svc.registration.referenceId;
  setRegistration(svc, null);

  try {
    await rest.deleteValidated(t.any, `${config.notifications.url}/v1/registration/${refId}`);
  } catch (e) {
    logger.error('unregister', 'error=', e);
  }
}

async function unsubscribeAndUnregister(svc: PushNotificationService, subscription: PushSubscription | null) {
  await Promise.all([unregister(svc), subscription && (await subscription.unsubscribe())]);
}

async function updatePush(svc: PushNotificationService, nextEnabled: boolean) {
  const { pushManager } = await timeout(navigator.serviceWorker.ready, config.notifications.browserApiTimeout);
  const subscription = await pushManager.getSubscription();

  const registration = svc.registration;
  let isEnabled = !!(subscription && registration);

  // clean up inconsistent states
  if (subscription && !registration) {
    // e.g., registration didn't complete, or persisted state was deleted
    await subscription.unsubscribe(); // always unsubscribe to avoid duplicate registrations
  } else if (!subscription && registration) {
    // e.g., user revoked permission
    await unregister(svc);
  } else if (subscription && registration) {
    if (subscription.endpoint !== registration.endpoint) {
      // unexpected
      logger.error('updatePush', 'error=', new Error('endpoints of subscription and registration differ'));
      await unsubscribeAndUnregister(svc, subscription);
      isEnabled = false;
    }
  }

  if (nextEnabled && !isEnabled) {
    await subscribeAndRegister(svc, pushManager);
  } else if (!nextEnabled && isEnabled) {
    await unsubscribeAndUnregister(svc, subscription);
  }
}

export interface PushNotificationServiceOptions {
  registration: PushNotificationsRegistrationBase | null;
  onRegistrationChange: (registration: PushNotificationsRegistrationBase | null) => void;
  onError: (error: Error) => void;
}

export class PushNotificationService {
  registration: PushNotificationsRegistrationBase | null;
  onRegistrationChange: (registration: PushNotificationsRegistrationBase | null) => void;
  private onError: (error: Error) => void;
  applicationServerKey: Uint8Array;
  private isBusy: boolean;
  private nextEnabledState: boolean | null;
  private forceDisable: boolean;

  constructor({ registration, onRegistrationChange, onError }: PushNotificationServiceOptions) {
    this.registration = registration;
    this.onRegistrationChange = onRegistrationChange;
    this.onError = onError;
    this.applicationServerKey = base64UrlToUint8Array(config.notifications.vapidPublicKey);

    this.isBusy = false;
    this.nextEnabledState = null;
    this.forceDisable = false;
  }

  /**
   * Determines whether the browser supports Web Push.
   */
  static isSupported() {
    // eslint-disable-next-line no-undef
    return USE_SERVICE_WORKER && 'serviceWorker' in navigator && 'PushManager' in window;
  }

  /**
   * Sets the desired status of the push subscription and backend registration, and clears inconsistent states.
   * Any resulting changes will be communicated via the onRegistrationChange callback passed to the constructor.
   * Calling this method again before it is completed will enqueue the last call.
   */
  setEnabled(enabled: boolean) {
    if (this.isBusy) {
      if (this.nextEnabledState === false && enabled) {
        // ensure that calling `setEnabled(false)` will always attempt to drop the subscription
        this.forceDisable = true;
      }

      this.nextEnabledState = enabled;
      return;
    }

    this.isBusy = true;

    const finishUpdate = () => {
      this.isBusy = false;

      if (this.forceDisable) {
        this.forceDisable = false;
        this.setEnabled(false);
        return;
      }

      const next = this.nextEnabledState;
      if (next != null) {
        this.nextEnabledState = null;
        if (next !== enabled) {
          this.setEnabled(next);
        }
      }
    };

    updatePush(this, enabled)
      .catch((error) => this.onError(error))
      // finally..
      .then(
        () => finishUpdate(),
        (error) => {
          finishUpdate();
          throw error;
        }
      );
  }

  /**
   * Unsubscribes the active push subscription and service registration if there are any.
   */
  static async unsubscribe(registration: PushNotificationsRegistrationBase | null) {
    const swRegistration = await navigator.serviceWorker.getRegistration();
    if (swRegistration && swRegistration.pushManager) {
      const subscription = await swRegistration.pushManager.getSubscription();
      if (subscription) {
        await subscription.unsubscribe();
      }
    }

    if (registration) {
      const refId = registration.referenceId;
      try {
        await rest.deleteValidated(t.any, `${config.notifications.url}/v1/registration/${refId}`);
      } catch (e) {
        logger.error('unregister', 'error=', e);
      }
    }
  }
}
