import * as React from 'react';
import { MeetingsTabContainer, MeetingsTabId, MeetingsTabSubject, MeetingsTabTitle } from '../MeetingComponents';
import { Box, Item } from '../../Common/Toolkit/Box/Box';
import styled from '../../../styled-components';
import { CalendarStar } from '../../Common/Toolkit/Icon/ReactIcons';
import device from '../../Common/Toolkit/devices';
import { FormattedMessage, defineMessages } from 'react-intl';

export const MeetNowTabIcon = styled((props) => (
  <div className={props.className}>
    <CalendarStar size={30} unit={'px'} />
  </div>
))`
  align-self: center;
  padding-right: 32px;
  flex: 0 0 auto;

  @media ${device.laptop} {
    padding-right: 20px;
  }

  @media ${device.mobile} {
    padding-right: 16px;
  }
`;

export const messages = defineMessages({
  title: {
    id: 'meetingHub.oneTimeMeetingContent.title',
    defaultMessage: 'Meet Now'
  },
  description: {
    id: 'meetingHub.oneTimeMeetingContent.description',
    defaultMessage: 'Start an instant meeting in seconds'
  },
  detailedDescription: {
    id: 'meetingHub.oneTimeMeetingContent.detailedDescription',
    defaultMessage: 'Start a new meeting with a random ID, then easily invite others to join you.'
  }
});

export const OneTimeMeetingItem: React.FC<{ className?: string }> = (props) => {
  return (
    <MeetingsTabContainer className={props.className}>
      <Box flexDirection="row">
        <MeetNowTabIcon />
        <Item>
          <MeetingsTabSubject>
            <MeetingsTabTitle data-testid="meeting-subject">
              <FormattedMessage {...messages.title} />
            </MeetingsTabTitle>
            <MeetingsTabId>
              <FormattedMessage {...messages.description} />
            </MeetingsTabId>
          </MeetingsTabSubject>
        </Item>
      </Box>
    </MeetingsTabContainer>
  );
};
