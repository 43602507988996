import React from 'react';
import { WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { User } from '../../../../../types/pulse-web';
import {
  computeMsgProps,
  isErrorMessage,
  isFilesMessage,
  isMeetNowCSSSMessage,
  isMeetNowInvitationMessage,
  isPreviewableFile,
  shouldIncludeDaySeparator
} from '../../../../services/ChatHistoryFormatService';
import { State } from '../../../../view-model/types';
import { DaySeparator } from '../DaySeparator/DaySeparator';
import ErrorMessageEntry from '../ErrorMessageEntry/ErrorMessageEntry';
import { FilesMessageEntry } from '../FilesMessageEntry/FilesMessageEntry';
import { ImageFileMessageEntry } from '../ImageFileMessageEntry/ImageFileMessageEntry';
import { MeetNow } from '../MeetNow/MeetNow';
import MessageHistoryEntry from '../MessageHistoryEntry';
import { TextMessageEntry } from '../TextMessageEntry/TextMessageEntry';
import { Contact, ControlMessage, FilesMessage, MessagesEntity } from '@getgo/caps-redux';

const localized = defineMessages({
  unknownUser: {
    id: 'chat.messageHistory.messageList.unknownUser',
    defaultMessage: 'Unknown user'
  },
  downloadDisabledTooltip: {
    id: 'chat.messageHistory.messageList.downloadDisabledTooltip',
    defaultMessage: 'Downloading files is disabled. Contact your administrator for more information.'
  }
});

interface Props {
  app: State['core']['app'];
  contact: Contact;
  isOnline?: boolean;
  messages: MessagesEntity[];
  onJoinMeeting: (meetingUrl: string) => void;
  onNewReplaceMessage: (originalMessage: string, text: string, options: {}) => void;
  onPreviewOpen: (message: FilesMessage) => void;
  today: Date | string;
  user: User;
}

class MessageList extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    if (!this.props.messages || !this.props.messages.length) {
      return null;
    }

    const { onJoinMeeting = () => {} } = this.props;

    const { formatMessage } = this.props.intl;

    const messages = this.props.messages
      .filter((message) => !(message as ControlMessage).isControl)
      .reduce((prev: Array<React.ReactElement<any>>, msg, index) => {
        const last = index === this.props.messages.length - 1;
        const prevMsg = prev.length && prev[prev.length - 1].props.message;
        const props = Object.assign(
          {
            key: `${msg.date}/${msg.from}/${msg.id}`,
            app: this.props.app
          },
          computeMsgProps(msg, prevMsg, this.props.user, this.props.contact)
        );

        if (!props.name) {
          props.name = formatMessage(localized.unknownUser);
        }

        if (last) {
          (props as any).ref = 'activeItem';
        }

        if (shouldIncludeDaySeparator(msg, prevMsg)) {
          prev.push(<DaySeparator today={this.props.today} date={msg.date} key={msg.date} />);
        }

        if (isMeetNowInvitationMessage(msg)) {
          // old invitation protocol
          prev.push(
            <MessageHistoryEntry {...props} hideEditedLabel>
              <MeetNow
                joinLink={msg.invitation.meetnow.href}
                onJoinMeeting={onJoinMeeting}
                isMine={props.isMine}
                contactName={this.props.contact.name}
                senderName={props.name}
                status="started"
              />
            </MessageHistoryEntry>
          );
        } else if (isMeetNowCSSSMessage(msg)) {
          // new protocol with meeting statuses
          prev.push(
            <MessageHistoryEntry {...props} hideEditedLabel>
              <MeetNow
                joinLink={msg.meeting.status === 'started' ? msg.meeting.joinLink : undefined}
                onJoinMeeting={onJoinMeeting}
                isMine={props.isMine}
                contactName={this.props.contact.name}
                senderName={props.name}
                status={msg.meeting.status}
              />
            </MessageHistoryEntry>
          );
        } else if (isErrorMessage(msg)) {
          prev.push(<ErrorMessageEntry error={msg.error} {...props} />);
        } else if (isFilesMessage(msg)) {
          const isFileSharingEnabled = this.props.app.features.includes('file_sharing');
          const downloadDisabledTooltip = formatMessage(localized.downloadDisabledTooltip);
          if (isPreviewableFile(msg.files[0])) {
            prev.push(
              <MessageHistoryEntry {...props}>
                <ImageFileMessageEntry
                  message={msg}
                  onPreviewClick={this.props.onPreviewOpen}
                  isDownloadEnabled={isFileSharingEnabled}
                  disabledTooltip={downloadDisabledTooltip}
                />
              </MessageHistoryEntry>
            );
          } else {
            prev.push(
              <MessageHistoryEntry {...props}>
                <FilesMessageEntry
                  message={msg}
                  isDownloadEnabled={isFileSharingEnabled}
                  disabledTooltip={downloadDisabledTooltip}
                />
              </MessageHistoryEntry>
            );
          }
        } else {
          prev.push(
            <MessageHistoryEntry
              isEditableMessageType
              isOnline={this.props.isOnline}
              onNewReplaceMessage={this.props.onNewReplaceMessage}
              {...props}
            >
              <TextMessageEntry message={msg} />
            </MessageHistoryEntry>
          );
        }
        return prev;
      }, []);

    return <ol className="message-list">{messages}</ol>;
  }
}

export default injectIntl(MessageList);
