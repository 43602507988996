import { Contact } from '@getgo/caps-redux';
import React from 'react';
import { Omit } from 'react-redux';
import { ChatHeaderContact, Props as ChatHeaderContactProps } from './ChatHeaderContact';
import { ChatHeaderGroup, Props as ChatHeaderGroupProps } from './ChatHeaderGroup';

interface OwnProps {
  onSetSidebarOpen?: () => void;
  sidebarDocked: boolean;

  // Fixing Typescript not assigning contact to union. Remove contact from used interfaces and add as Contact
  contact: Contact;
}

export type Props = Omit<ChatHeaderContactProps | ChatHeaderGroupProps, 'contact' | 'sidebarOpenButton'> & OwnProps;

export const ChatHeader: React.FC<Props> = (props) => {
  const { sidebarDocked = false } = props;

  let sidebarOpenButton;
  if (!sidebarDocked) {
    sidebarOpenButton = (
      <button
        className="btn btn-icon sidebar-open-button"
        onClick={props.onSetSidebarOpen}
        data-testid="sidebar-open-button"
      >
        ☰
      </button>
    );
  }

  return (
    <div className="chat-header">
      {props.contact.isGroup === true ? (
        <ChatHeaderGroup
          sidebarOpenButton={sidebarOpenButton}
          {...(props as ChatHeaderGroupProps & OwnProps)}
          contact={props.contact}
        />
      ) : (
        <ChatHeaderContact
          sidebarOpenButton={sidebarOpenButton}
          {...(props as ChatHeaderContactProps & OwnProps)}
          contact={props.contact}
        />
      )}
    </div>
  );
};
