import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import styled from '../../../../styled-components';

interface Props {
  href: string;
  clickHandler?: () => void;
  shouldLocalize?: boolean;
  linkLocalizedText?: MessageDescriptor;
  rawText?: string;
}

export const Item: React.FC<Props> = ({
  href,
  clickHandler = () => {},
  linkLocalizedText,
  shouldLocalize = false,
  rawText = ''
}) => (
  <ItemWrapper>
    <a href={href} target="_blank" rel="noopener noreferrer" onClick={clickHandler}>
      {shouldLocalize && linkLocalizedText ? <FormattedMessage {...linkLocalizedText} /> : <span>{rawText}</span>}
    </a>
  </ItemWrapper>
);

export const ItemWrapper = styled.li`
  cursor: pointer;
  display: flex;
  a {
    padding: 8px 16px 8px 16px;
    width: 100%;
    text-decoration: none;
    color: #25282d;
    cursor: pointer;
    border-radius: 0;
  }
  :hover {
    background-color: #f0f2f5;
    border-radius: 4px;
  }
`;
