import { Document, Packer, Paragraph, TextRun } from 'docx';
import FileSaver from 'file-saver';
import { formatToHoursMinutesSeconds } from '../../../lib/date';
import { correlateNotesToVideo } from './utils/createMeetingAssetDetails';

export const smartNotesDownload = (notes: any, session: any, recordingTimes: any) => {
  const { subject } = session;

  const doc = new Document();

  const fontTextRun = (input: string, properties = {}) => {
    return new TextRun({
      size: 27,
      text: input,
      ...properties,
      font: {
        name: 'Calibri'
      }
    });
  };
  const updatedNotes = correlateNotesToVideo(notes, recordingTimes);
  const smartNotesList = () => {
    if (updatedNotes && updatedNotes.length) {
      return updatedNotes.map((item: any) => {
        return new Paragraph({
          children: [
            fontTextRun('', { break: 1 }),
            fontTextRun(`${item.videoTime > 0 ? formatToHoursMinutesSeconds(item.videoTime) : ''}`, {
              color: '#8B939D',
              size: 25
            }),
            fontTextRun(item.text, { break: 1 })
          ]
        });
      });
    }
    return [];
  };

  doc.addSection({
    children: [
      new Paragraph({
        children: [fontTextRun(subject, { bold: true, size: 52 })]
      }),
      ...smartNotesList()
    ]
  });
  try {
    Packer.toBlob(doc)
      .then((blob) => {
        FileSaver.saveAs(blob, `${subject} - Meeting Smart Notes.docx`, { autoBom: true });
      })
      .catch(() => {
        // fail silently
      });
  } catch (e) {
    // fail silently
  }
};
