import React, { Component, RefObject } from 'react';
import { FormattedMessage, defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Popover, PopoverMethods } from '../Common/Popover/Popover';

const messages = defineMessages({
  title: {
    id: 'chat.groupChatSetting.title',
    defaultMessage: 'OPTIONS'
  },
  leaveGroup: {
    id: 'chat.groupChatSetting.optionLeave',
    defaultMessage: 'Leave Group'
  },
  deleteGroup: {
    id: 'chat.groupChatSetting.optionDelete',
    defaultMessage: 'Delete Group'
  },
  groupSettings: {
    id: 'chat.groupChatSetting.groupSettings',
    defaultMessage: 'Group Chat Settings'
  }
});

interface Props {
  contact: {
    affiliation: string;
    jid: string;
    selected: boolean;
  };
  isOnline?: boolean;
  onDeleteGroup?: (groupjid: string, selected: boolean) => any;
  onLeaveGroup?: (groupjid: string, selected: boolean) => void;
}

class GroupChatSetting extends Component<Props & WrappedComponentProps> {
  private popoverRef: RefObject<PopoverMethods>;

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.popoverRef = React.createRef();
  }

  leaveGroup = () => {
    const { onLeaveGroup = () => {} } = this.props;
    const { jid, selected } = this.props.contact;
    onLeaveGroup(jid, selected);
    if (this.popoverRef.current) {
      this.popoverRef.current.hide();
    }
  };

  deleteGroup = () => {
    const { onDeleteGroup = () => {} } = this.props;
    const { jid, selected } = this.props.contact;
    onDeleteGroup(jid, selected);
    if (this.popoverRef.current) {
      this.popoverRef.current.hide();
    }
  };

  render() {
    const leaveGroup = (
      <button className="btn btn-link settings-option leave-group" onClick={this.leaveGroup}>
        <FormattedMessage {...messages.leaveGroup} />
      </button>
    );

    const deleteGroup = (
      <button className="btn btn-link settings-option delete-group" onClick={this.deleteGroup}>
        <FormattedMessage {...messages.deleteGroup} />
      </button>
    );

    const disclosure = (
      <button
        data-testid="group-settings"
        className="group-chat-setting btn btn-link"
        disabled={!this.props.isOnline}
        aria-label={this.props.intl.formatMessage(messages.groupSettings)}
      >
        <i className="togo-icon togo-icon-settings togo-icon-lg" />
      </button>
    );

    return (
      <Popover
        className="group-chat-settings-popover"
        disclosure={disclosure}
        ariaLabel={this.props.intl.formatMessage(messages.title)}
        ref={this.popoverRef}
        id="group-chat-settings-popover"
      >
        <div>
          <div className="settings-header">
            <FormattedMessage {...messages.title} />
          </div>
          <div className="settings-content">
            {this.props.contact && this.props.contact.affiliation === 'owner' ? deleteGroup : leaveGroup}
          </div>
        </div>
      </Popover>
    );
  }
}

export default injectIntl(GroupChatSetting);
