import { GroupContact } from '@getgo/caps-redux';
import * as React from 'react';
import { keyBy } from 'lodash';
import { MultiSelectSuggestionsList } from './MultiSelectSuggestionsList';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { getDisplayName, getSuggestionTitle } from '../../../../lib/person';
import { Contact, CoreContact } from '../../../../types/pulse-web';
import { State as RootState } from '../../../view-model/types';

export interface Member {
  affiliation: string;
  availability: string;
  avatarUrl: string;
  email: string;
  isExternal: boolean;
  jid: string;
  name: string;
}

export interface Props {
  autoFocus?: boolean;
  members?: GroupContact['members'];
  onQuery: (query?: string, nextPageKey?: string) => void;
  onSelectedContactsChange: (contacts: Contact[]) => void;
  people: RootState['messaging']['people'];
  renderSuggestion: (contact: CoreContact) => React.ReactElement<any> | null;
  onCancel?: () => void;
}

interface State {
  query: string;
  suggestedContacts: CoreContact[];
}

class MultiContactSelector extends React.PureComponent<Props & WrappedComponentProps, State> {
  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = {
      query: '',
      suggestedContacts: props.people.suggestions
    };
  }

  UNSAFE_componentWillMount() {
    this.setDisableForGroupMembers(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setDisableForGroupMembers(nextProps);
  }

  // set "disabled" attribute to suggestions which are already in the group
  setDisableForGroupMembers(props: Props) {
    const index = keyBy(props.members, 'jid'); // todo rewrite remove lodash
    const suggestions = props.people.suggestions.map((s) => (index[s.jid] ? { isDisabled: true, ...s } : s));

    this.setState({
      suggestedContacts: suggestions
    });
  }

  handleInputChange = (query: any) => {
    this.props.onQuery(query);
    this.setState({ query });
  };

  handleSelectedContactsChange = (selectedContacts: Contact[]) => {
    this.props.onQuery();
    this.props.onSelectedContactsChange(selectedContacts);
  };

  loadNextPage = () => {
    if (!this.props.people.isFetching && this.props.people.paging.hasPages) {
      this.props.onQuery(this.state.query, this.props.people.paging.nextPageKey.toString());
    }
  };

  formatSuggestedContactsTitle = (contact: any) => {
    return getSuggestionTitle(contact);
  };

  renderSelectedContact = (contact: any) => {
    return getDisplayName(contact);
  };

  render() {
    return (
      <MultiSelectSuggestionsList
        autoFocus={!!this.props.autoFocus}
        intl={this.props.intl}
        isLoading={this.props.people.isFetching}
        listLabel={this.formatSuggestedContactsTitle}
        listValue={({ jid }) => jid}
        onInputChange={this.handleInputChange}
        onMenuScrollToBottom={this.loadNextPage}
        onSelectValue={this.handleSelectedContactsChange}
        renderSelectedSuggestion={this.renderSelectedContact}
        renderSuggestion={this.props.renderSuggestion}
        suggestions={this.state.suggestedContacts}
        onCancel={this.props.onCancel}
      />
    );
  }
}

export default injectIntl(MultiContactSelector, { forwardRef: true });
