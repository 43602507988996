import { useHistory, generatePath } from 'react-router-dom';

const routeMap: { [key: string]: string } = {
  addContact: '/chats/add-contact',
  addMembers: '/chats/groups/add-members',
  chat: '/chats',
  chatConversation: '/chats/:roomId',
  createGroup: '/chats/groups/create',
  disabledChat: '/chats/disabled',
  groupSearch: '/chats/groups/search',
  meetings: '/meetings',
  upcomingMeetings: '/meetings/upcoming-meetings',
  anytimeMeetings: '/meetings/anytime-meetings',
  historyMeetings: '/meetings/history-meetings',
  settings: '/settings/',
  notificationPreferences: '/settings/notifications',
  customBackgroundPreferences: '/settings/custom-background',
  calendarSyncPreferences: '/settings/calendar-sync',
  goToApp: '/settings/goto-app',
  about: '/settings/about',
  meetingPreferences: '/settings/meetings',
  newExperiencePreferences: '/settings/new-experience',
  discover: '/discover',
  alternatePhoneNumbers: '/alternate-phone-numbers.html',
  emailVerification: 'email-verification.html',
  signup: 'signup.html',
  termsOfService: 'terms-of-service.html'
};

export const getRouteFor = (
  componentName: string,
  params?: { [paramName: string]: string | number | boolean | undefined }
) => {
  if (!(componentName in routeMap)) {
    return '/';
  }

  if (!params) {
    return routeMap[componentName];
  }

  return generatePath(routeMap[componentName], params);
};

export const getHrefFor = (
  componentName: string,
  params?: { [paramName: string]: string | number | boolean | undefined }
) => {
  return `#${getRouteFor(componentName, params)}`;
};

export const navigateTo = (
  componentName: string,
  params?: { [paramName: string]: string | number | boolean | undefined }
) => {
  window.location.hash = getHrefFor(componentName, params);
};

export const redirectTo = (componentName: string) => {
  window.location.href = getRouteFor(componentName);
};

export const navigateBack = () => {
  const history = useHistory();
  history.goBack();
};

export const matchBase = (base: string) => {
  return (location: { pathname: string }) => {
    const pattern = new RegExp(`^${base}`);
    if (pattern.test(location.pathname)) {
      return location;
    }
    return { ...location, pathname: base };
  };
};

export const displaysChat = (pathname: string) => {
  return pathname.startsWith('/chats');
};
