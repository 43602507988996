import { Contact } from '@getgo/caps-redux';
import {
  clearText,
  createState,
  getExcessCharacters,
  hasText,
  MessageInput,
  removeDecorator,
  stateToPulseXMPPFormat
} from '../MessageInputField';
import { EditorState } from 'draft-js';
import React from 'react';
import { WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { createFilteredMentionList } from '../../../services/MentionService';
import { buildEmojiUrl } from '../../../services/MessageFormatService';
import { DraftJSPersistedMessageInput } from '../../../view-model/modules/messaging/messageInputs/messageInputsReducer';
import { State as RootState } from '../../../view-model/types';
import { Mention } from '../MessageInputField/component/MessageInput/MessageInput';
import { MentionEntry } from './MentionEntry';
import './MessageInputRTE.css';
import { PasteImagePreview } from '../../Common/PasteImagePreview/PasteImagePreview';
import { KeyNames } from '../../../../lib/keycodes';

const styleMap = {
  BOLD: {
    fontFamily: "'lato-bold', 'Helvetica Neue', arial, sans-serif"
  },
  CODE: {
    // from togo <code> style
    color: '#c7254e',
    backgroundColor: '#f9f2f4',
    whiteSpace: 'nowrap',
    borderRadius: 2,
    fontFamily: 'Menlo,Monaco,Consolas,"Courier New",monospace',
    padding: '2px 4px',
    fontSize: '90%'
  }
};

const DEFAULT_FILENAME = 'image.png';

const localized = defineMessages({
  fileSharingNotAllowedTooltip: {
    id: 'chat.messageInputRTE.fileSharingNotAllowedTooltip',
    defaultMessage: 'Uploading files is disabled. Contact your administrator for more information.'
  },
  messageExceedsMaxLengthTooltip: {
    id: 'chat.messageInputRTE.messageExceedsMaxLengthTooltip',
    defaultMessage: 'Your message is {excessChars} {excessChars, plural, one {character} other {characters}} too long'
  }
});

interface Props {
  addTypingIndicator: (roomId: string, isGroup: boolean) => void;
  app?: RootState['core']['app'];
  autoFocus?: boolean;
  changeMessageInputHeight?: (roomId: string, newInputHeight: number) => void;
  contact?: Contact;
  groupMembers?: any[];
  isOnline?: boolean;
  maxLength?: number;
  messageInputHeight?: number;
  placeholder: string;
  onNewMessage?: (xmppMessage: any, options: object) => void;
  persistDraftJSMessageInput?: (roomId: string, persistedMessageInput: DraftJSPersistedMessageInput) => void;
  persistedMessageInput?: DraftJSPersistedMessageInput;
  removeTypingIndicator: (roomId: string, isGroup: boolean) => void;
  roomId: string;
  showEmojiButton?: boolean;
  spellCheck?: boolean;
  submitOnEnter?: boolean;
  uploadFiles: (files: FileList | File[]) => void;
}

interface State {
  editorState: EditorState;
  mentionSearch: string | null;
  imagePreviewUrl: string | null;
  imageFilename: string | null;
  uploadsEnabled: boolean;
}

const PastePopover = (props: { dataUrl: string; previewCloseClick: () => void }) => (
  <div className="popover fade top in paste-image-preview-content">
    <div className="remove-popover-wrapper">
      <button className="btn-icon" type="button" onClick={props.previewCloseClick} tabIndex={-1}>
        <i className="togo-icon togo-icon-closes" />
      </button>
    </div>
    <img className="paste-image-preview paste-image" src={props.dataUrl} alt="" />
    <div className="arrow" />
  </div>
);

class MessageInputRTE extends React.Component<Props & WrappedComponentProps, State> {
  private div: HTMLDivElement | null = null;
  private input: MessageInput | null = null;
  private typingIndicatorTimer: number | null = null;

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = {
      editorState: (props.persistedMessageInput && props.persistedMessageInput.editorState) || createState(),
      mentionSearch: null,
      imagePreviewUrl: null,
      imageFilename: null,
      uploadsEnabled: props?.app?.features?.includes('file_sharing') || false
    };
  }

  componentDidMount() {
    this.focusInput();
    ReactTooltip.rebuild();
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    if (this.props.roomId !== newProps.roomId) {
      this.persistEditorState();
      this.clearTypingTimer();
      this.clearImagePreview();
      this.setState({
        editorState: (newProps.persistedMessageInput && newProps.persistedMessageInput.editorState) || createState(),
        mentionSearch: null
      });
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const propKeys = [
      'persistedMessageInput',
      'isOnline',
      'roomId',
      'app.features',
      'showMentionList',
      'groupMembers',
      'showEmojiButton',
      'submitOnEnter',
      'spellCheck'
    ];
    const stateKeys = ['editorState', 'mentionSearch', 'imagePreviewUrl', 'imageFilename'];
    return (
      // @ts-ignore
      propKeys.some((k: string) => this.props[k] !== nextProps[k]) ||
      // @ts-ignore
      stateKeys.some((k: string) => this.state[k] !== nextState[k])
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.roomId !== prevProps.roomId) {
      this.focusInput();
    }
  }

  componentWillUnmount() {
    this.persistEditorState();
  }

  persistEditorState = () => {
    let editorState = null;
    if (hasText(this.state.editorState)) {
      editorState = removeDecorator(this.state.editorState);
    }

    if (this.props.persistedMessageInput && this.props.persistedMessageInput.editorState === editorState) {
      return;
    }

    if (this.props.persistDraftJSMessageInput) {
      this.props.persistDraftJSMessageInput(this.props.roomId, { editorState });
    }
  };

  clearTypingTimer = () => {
    if (this.typingIndicatorTimer !== null) {
      clearTimeout(this.typingIndicatorTimer);
      this.handleRemoveTypingIndicator();
    }
  };

  /*
    Checks for typing Indicator. If not, a typing indicator is setup and a timer with 4 seconds is started
    After 4 seconds, typing indicator will be removed.
    If function is called during a running typing indicator,the time is reset.
   */
  triggerIsTypingIndicator = () => {
    if (this.typingIndicatorTimer === null) {
      this.props.addTypingIndicator(this.props.roomId, this.isGroupChat());
      // Do not remove window. Without window typescript selects the nodejs Timer which is typed completely different
      this.typingIndicatorTimer = window.setTimeout(this.handleRemoveTypingIndicator, 4000);
    } else {
      clearTimeout(this.typingIndicatorTimer);
      this.typingIndicatorTimer = window.setTimeout(this.handleRemoveTypingIndicator, 4000);
    }
  };

  handleRemoveTypingIndicator = () => {
    this.typingIndicatorTimer = null;
    this.props.removeTypingIndicator(this.props.roomId, this.isGroupChat());
  };

  handleInputChange = (newState: EditorState) => {
    this.setState({
      editorState: newState
    });

    if (this.div != null) {
      const newInputHeight = this.div.clientHeight;
      if (this.props.messageInputHeight !== newInputHeight && this.props.changeMessageInputHeight) {
        this.props.changeMessageInputHeight(this.props.roomId, newInputHeight);
      }
    }
    // hide potentially outdated tooltip for submit button
    ReactTooltip.hide();
  };

  focusInput = () => {
    const { autoFocus = true } = this.props;
    if (autoFocus && this.input) {
      this.input.focus();
    }
  };

  isGroupChat = () => {
    return !this.props.contact || this.props.contact.isGroup;
  };

  handleSubmit = (state: EditorState) => {
    const { isOnline = true } = this.props;
    if (!isOnline) {
      return;
    }

    if (this.typingIndicatorTimer !== null) {
      clearTimeout(this.typingIndicatorTimer);
      this.typingIndicatorTimer = null;
    }

    this.handleImageSubmit();

    this.focusInput();

    const { xmppMessage, options } = stateToPulseXMPPFormat(state);
    if (!xmppMessage) {
      return;
    }

    if (this.props.onNewMessage) {
      this.props.onNewMessage(xmppMessage, options);
    }

    this.setState({
      editorState: clearText(state)
    });
  };

  handleImageSubmit = async () => {
    if (!this.state.imagePreviewUrl || this.state.imageFilename == null) {
      return;
    }

    const imageFilename = this.state.imageFilename || DEFAULT_FILENAME;

    const fileName = imageFilename.endsWith('.png') ? imageFilename : `${imageFilename}.png`;

    const res = await fetch(this.state.imagePreviewUrl);
    const buf = await res.arrayBuffer();
    const file = new File([buf], fileName, { type: 'image/png' });

    const files = [file];
    this.props.uploadFiles!(files);
    this.clearImagePreview();
  };

  handleMentionSearchChange = (value: string | null) => {
    this.setState({
      mentionSearch: value
    });
  };

  formatMessage = (descriptor: string, values?: { [key: string]: any }) => {
    // @ts-ignore
    return this.props.intl.formatMessage(localized[descriptor], values);
  };

  clearImagePreview = () => {
    this.setState({
      imagePreviewUrl: null,
      imageFilename: null
    });
    if (this.input) {
      this.input.focus();
    }
  };

  handlePastePreviewKeydown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === KeyNames.ENTER) {
      e.preventDefault();
      e.stopPropagation();
      if (this.input) {
        this.input.focus();
      }
    } else if (e.key === KeyNames.ESCAPE || e.key === KeyNames.DELETE || e.key === KeyNames.BACKSPACE) {
      e.preventDefault();
      e.stopPropagation();
      this.clearImagePreview();
    }
  };

  private generateFilename = () => {
    const today = new Date();
    const day = `${today.getDate()}`.padStart(2, '0');
    const month = `${today.getMonth() + 1}`.padStart(2, '0');
    const year = today.getFullYear();
    const hours = `${today.getHours()}`.padStart(2, '0');
    const mins = `${today.getMinutes()}`.padStart(2, '0');
    const seconds = `${today.getSeconds()}`.padStart(2, '0');
    return `${year}-${month}-${day}-${hours}-${mins}-${seconds}.png`;
  };

  handlePaste = async (files: File[]) => {
    if (!this.state.uploadsEnabled) {
      return;
    }
    const getImagePreviewUrl = (): Promise<string> =>
      new Promise((resolve) => {
        const blob = files[0];
        const reader = new FileReader();
        reader.addEventListener('load', () => {
          resolve(reader.result as string);
        });
        reader.readAsDataURL(blob);
      });

    const previewImageUrl = await getImagePreviewUrl();
    const fileName = this.generateFilename();
    this.setState({
      imagePreviewUrl: previewImageUrl,
      imageFilename: fileName
    });
  };

  getKeyCommand = (e: KeyboardEvent) => {
    // handle deletion of pasted image
    if (e.key === KeyNames.BACKSPACE) {
      const selectionState = this.state.editorState.getSelection();
      const firstBlockKey = this.state.editorState
        .getCurrentContent()
        .getFirstBlock()
        .getKey();
      const cursorInFirstLine = selectionState.getAnchorKey() === firstBlockKey;
      const cursorAtStartOfBlock = selectionState.getAnchorOffset() === 0;
      const noTextMarked = selectionState.isCollapsed();
      if (cursorInFirstLine && cursorAtStartOfBlock && noTextMarked) {
        return 'delete-pasted-image';
      }
    }
    return null;
  };

  handleKeyCommand = (command: string) => {
    if (command === 'delete-pasted-image') {
      this.clearImagePreview();
      return true;
    }
    return;
  };

  handleEscape = () => {
    if (this.state.editorState.getCurrentContent().getPlainText() === '') {
      this.clearImagePreview();
    }
  };

  render() {
    const {
      groupMembers = [],
      isOnline = true,
      maxLength = 10000,
      showEmojiButton = false,
      spellCheck = false,
      submitOnEnter = false
    } = this.props;

    let mentionSuggestions: Mention[] = [];
    if (this.state.mentionSearch != null) {
      mentionSuggestions = createFilteredMentionList(groupMembers, this.state.mentionSearch).map((member) => ({
        id: member.jid,
        name: member.name,
        email: member.email,
        avatar: member.avatarUrl
      }));
    } else {
      mentionSuggestions = [];
    }

    return (
      <div ref={(el) => (this.div = el)} className="message-input-wrapper" data-testid="message-input-rte">
        {this.state.imagePreviewUrl && (
          <PastePopover dataUrl={this.state.imagePreviewUrl} previewCloseClick={this.clearImagePreview} />
        )}
        <MessageInput
          key={this.props.roomId}
          ref={(el) => (this.input = el)}
          state={this.state.editorState}
          onChange={this.handleInputChange}
          placeholder={this.props.placeholder}
          readOnly={!isOnline}
          customStyleMap={styleMap}
          onSubmit={this.handleSubmit}
          onFileUpload={this.props.uploadFiles}
          isFileSharingAllowed={this.state.uploadsEnabled}
          enableMentions={this.isGroupChat()}
          mentionSuggestions={mentionSuggestions}
          onMentionSearchChange={this.handleMentionSearchChange}
          mentionEntryComponent={MentionEntry as any}
          showEmojiButton={showEmojiButton}
          submitOnEnter={submitOnEnter}
          enableToolbar={false}
          buildEmojiUrl={buildEmojiUrl}
          getKeyCommand={this.getKeyCommand as any}
          handleKeyCommand={this.handleKeyCommand as any}
          formatMessage={this.formatMessage}
          excessCharacters={getExcessCharacters(this.state.editorState, maxLength)}
          spellCheck={spellCheck}
          onTyping={this.triggerIsTypingIndicator}
          enablePasteFiles={true}
          pasteElement={
            this.state.imagePreviewUrl && (
              <PasteImagePreview filename={this.state.imageFilename} onKeyDown={this.handlePastePreviewKeydown} />
            )
          }
          onPaste={this.handlePaste}
          onEscape={this.handleEscape}
        />
      </div>
    );
  }
}

export default injectIntl(MessageInputRTE);
