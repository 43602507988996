import FeatureFlip from '@getgo/featureflip';
import platform from 'platform';
import config from 'config';
import featureRules from '../../config/features';

export default new FeatureFlip(
  {
    browser: {
      name: platform.name || 'unknown',
      version: platform.version || 'unknown',
      ua: platform.ua || 'unknown'
    },
    env: config.env
  },
  featureRules
);
