import { ThunkAction } from 'redux-thunk';
import { Reducer } from 'redux';
import { ExtraArgument, Actions as RawActions, State as RootState } from './types';

/*
  Helper for redux thunks
    thunkCreator = (...args): Thunk => (dispatch, getState) => { ... }
 */
export type Thunk<ReturnValue = void, LocalActions extends RawActions = RawActions> = ThunkAction<
  ReturnValue,
  RootState,
  ExtraArgument,
  LocalActions
>;

/*
  Helpers for raw actions https://medium.com/@martin_hotell/improved-redux-type-safety-with-typescript-2-8-2c11a8062575
*/
export interface Action<T extends string> {
  type: T;
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}

export type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

type FunctionType = (...args: any[]) => any;
interface ActionCreatorsMapObject {
  [actionCreator: string]: FunctionType;
}

export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

/*
  Reducers
 */
export type UntypedReducer = Reducer<{ [prop: string]: any }>;
