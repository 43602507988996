// Note: extends Error is not yet supported by Babel
import ExtendableError from 'es6-error';

export class EntitlementError extends ExtendableError {}

export class UnauthorizedError extends ExtendableError {}

export class XMPPConnectionError extends ExtendableError {}

export class CreateMeetingError extends ExtendableError {}

export class ConcurrentMeetingError extends ExtendableError {}
