import React, { ReactNode, useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import config from 'config';
import styled from '../../../styled-components';
import { themeGet } from '@styled-system/theme-get';
import { Link } from '../../Common/Toolkit/Link/Link';
import { getExternalAdminInformation } from '../../../../models/User';
import { useLocation } from 'react-router';
import { CenteredSpinner } from '../../Common/Toolkit/Spinner/Spinner';
import logger from '../../../services/LoggerService';
import AdminList from './AdminList';

const messages = defineMessages({
  privacyPolicy: {
    id: 'about.privacyPolicy',
    defaultMessage: 'Privacy Policy'
  },
  termsOfService: {
    id: 'about.termsOfService',
    defaultMessage: 'Terms of Service'
  },
  version: {
    id: 'about.version',
    defaultMessage: 'Version'
  },
  email: {
    id: 'about.email',
    defaultMessage: 'Registered to'
  },
  license: {
    id: 'about.license',
    defaultMessage: 'License'
  },
  adminInfo: {
    id: 'about.adminInfo',
    defaultMessage: 'Admin Info'
  },
  machineId: {
    id: 'about.machineId',
    defaultMessage: 'Device ID'
  },
  copyright: {
    id: 'about.copyright',
    defaultMessage: 'Copyright'
  },
  copyrightContent: {
    id: 'about.copyrightContent',
    defaultMessage: '© {year} LogMeIn, Inc. All rights reserved.'
  },
  copyrightFooterNotice: {
    id: 'about.copyrightFooterNotice',
    defaultMessage:
      'All OpenVoice audio conferencing services are provided by LogMeIn Audio, LLC. LogMeIn Audio, LLC is the telecommunications provider and is responsible for the rates, terms and conditions of the audio conferencing services.'
  }
});

const AboutWrapper = styled.div<{ isFetching?: boolean }>`
  color: ${themeGet('colors.surfaceOn')};
  line-height: 2;
  margin-top: 4px;
  padding: 0 5px;
  ${(props) =>
    props.isFetching &&
    `
    filter: grayscale(1);
    opacity: 0.5;
  `}
`;

const Highlight = styled.div`
  position: relative;
  left: -5px;
  padding-left: 5px;
  border: 1px solid ${themeGet('colors.primaryScale.500')};
  border-radius: 4px;
  background-color: #f0f7ff;
`;

const ConditionalHighlight: React.FC<{ condition: boolean; children: ReactNode }> = ({ condition, children }) =>
  condition ? <Highlight>{children}</Highlight> : <>{children}</>;

type AdminInformation = Pick<
  (ReturnType<typeof getExternalAdminInformation> extends Promise<infer T> ? T : never)['accounts'][0],
  'adminList' | 'customContactUrl' | 'customContactEmail'
>;

interface Props {
  version?: string;
  email: string;
  machineId: string;
  isAdmin: boolean;
  license?: string;
  openLink: (linkTargetType: string) => void;
}

export const About: React.FC<Props> = (props) => {
  const [adminInfo, setAdminInfo] = useState<AdminInformation>({
    adminList: [],
    customContactUrl: undefined,
    customContactEmail: undefined
  });
  const [isFetching, setIsFetching] = useState(false);
  const location = useLocation();
  const isAdminInfoHighlighted = !props.isAdmin && location.search.includes('highlightAdmin=true');

  useEffect(() => {
    if (props.isAdmin) {
      return;
    }
    const fetchExternalAdminInformation = async () => {
      setIsFetching(true);
      try {
        const adminInformation = await getExternalAdminInformation();
        setAdminInfo(adminInformation.accounts[0]);
      } catch (err) {
        logger.error('fetchExternalAdminInformation', 'e=', err);
      } finally {
        setIsFetching(false);
      }
    };
    fetchExternalAdminInformation();
  }, []);

  const handleTermsOfServiceClick = () => props.openLink('termsOfService');
  const handlePrivacyPolicyClick = () => props.openLink('privacyPolicy');

  return (
    <AboutWrapper isFetching={isFetching}>
      {isFetching && <CenteredSpinner />}
      <section>
        {props.version && (
          <div>
            <FormattedMessage {...messages.version} />: {props.version}
          </div>
        )}
        <div>
          <FormattedMessage {...messages.email} />: {props.email}
        </div>
        {props.license && (
          <div>
            <FormattedMessage {...messages.license} />: {props.license}
          </div>
        )}

        {!props.isAdmin && (
          <ConditionalHighlight condition={isAdminInfoHighlighted}>
            <div>
              <FormattedMessage {...messages.adminInfo} />:{' '}
              {adminInfo.customContactUrl ? (
                <Link target="_blank" rel="noopener noreferrer" href={adminInfo.customContactUrl}>
                  {adminInfo.customContactUrl.replace(/^https?:\/\//, '')}
                </Link>
              ) : adminInfo.customContactEmail ? (
                <Link target="_blank" rel="noopener noreferrer" href={`mailto:${adminInfo.customContactEmail}`}>
                  {adminInfo.customContactEmail}
                </Link>
              ) : (
                <AdminList admins={adminInfo.adminList} />
              )}
            </div>
          </ConditionalHighlight>
        )}

        <div>
          <FormattedMessage {...messages.machineId} />: {props.machineId}
        </div>
        <div>
          <FormattedMessage {...messages.copyright} />:{' '}
          <FormattedMessage {...messages.copyrightContent} values={{ year: new Date().getUTCFullYear() }} />
        </div>
        <div>
          <FormattedMessage {...messages.copyrightFooterNotice} />
        </div>
      </section>
      <br />
      <section>
        <div>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={config.externalLinks.termsOfService}
            onClick={handleTermsOfServiceClick}
          >
            <FormattedMessage {...messages.termsOfService} />
          </Link>
        </div>
        <div>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={config.externalLinks.privacyPolicy}
            onClick={handlePrivacyPolicyClick}
          >
            <FormattedMessage {...messages.privacyPolicy} />
          </Link>
        </div>
      </section>
    </AboutWrapper>
  );
};
