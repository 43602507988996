import { Logger } from '@getgo/logger';
import { connect } from 'react-redux';
import { Selectors } from '@getgo/caps-redux';
import errorActions from '../view-model/modules/core/errors/errorActions';
import Application from '../components/Application';
import { Dispatch, State } from '../view-model/types';

interface OwnProps {
  logger: Logger;
  environment?: string;
}

const mapStateToProps = (state: State) => {
  return {
    app: state.core.app,
    chatConnectionStatus: Selectors.getConnectionStatus(state).xmppConnected,
    user: state.core.user
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    clearChatError: () => {
      dispatch(errorActions.clearChatError());
    }
  };
};

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true
  }
)(Application);
