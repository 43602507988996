import React from 'react';
import styled from '../../styled-components';
import { SelectableCard } from '../Common/Toolkit/SelectableCard/SelectableCard';

const DiscoverCardHeading = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-family: 'lato-bold';
  margin: 0;
`;

const DiscoverCardSubtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 24px 12px 32px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const UnreadIndicator = styled.div`
  flex: 0 0 auto;
  background: #e02d52;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 6px 6px 0 -18px;
`;

export interface Props {
  title: string;
  subtitle: string;
  unread?: boolean;
  isSelected: boolean;
  onClick: () => void;
  onClose: () => void;
}

export const DiscoverCard: React.FC<Props> = (props) => {
  return (
    <SelectableCard isSelected={props.isSelected} onClose={props.onClose} onClick={props.onClick}>
      <ContentContainer>
        <HeadingContainer>
          {props.unread && <UnreadIndicator />}
          <DiscoverCardHeading>{props.title}</DiscoverCardHeading>
        </HeadingContainer>
        <DiscoverCardSubtitle>{props.subtitle}</DiscoverCardSubtitle>
      </ContentContainer>
    </SelectableCard>
  );
};
