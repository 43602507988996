// @ts-nocheck
import { IntlShape, defineMessages } from 'react-intl';
import { hasSameElements } from '../lib/array';
import { AudioSettingItems } from '../lib/audio';
import {
  addMinutesToDate,
  compareDate,
  compareTime,
  getDateString,
  getDateTimeInUtc,
  getTimeString
} from '../lib/date';
import {
  AudioRequest,
  AudioType,
  MeetingRequest,
  MeetingType,
  SchedulerFullMeeting,
  SchedulerMeeting
} from '../app/view-model/modules/scheduler/schedulerReducer';
import { isScheduledMeeting, Meeting, Profile } from '../app/view-model/modules/meetings/MeetingsTypes';
import { DEFAULT_TIMEZONE } from '../app/view-model/modules/scheduler/schedulerActions';
import logger from '../app/services/LoggerService';
import { MeetingSettings } from './Meeting';
import moment from 'moment-timezone';

const messages = defineMessages({
  defaultSubject: {
    id: 'models.meeting.defaultSubject',
    defaultMessage: 'New Meeting'
  }
});

export const createMeetingRequestFromSchedulerMeeting = (
  schedulerMeeting: SchedulerMeeting,
  originalMeeting: SchedulerMeeting | undefined,
  profile: Profile | undefined | null,
  intl: IntlShape
): MeetingRequest => {
  const meetingRequest: MeetingRequest = {
    ...updateSubject(intl, schedulerMeeting, originalMeeting),
    ...updateStartWithoutOrganizerSupported(schedulerMeeting, originalMeeting),
    ...updatePasswordRequired(schedulerMeeting, originalMeeting),
    ...updateForMeetingUrl(schedulerMeeting, originalMeeting, profile),
    ...updateForCoorganizers(schedulerMeeting, originalMeeting),
    ...updateForSchedule(schedulerMeeting),
    breakoutsAllowed: schedulerMeeting.breakoutsAllowed
  };
  const audioRequest = updateForAudio(schedulerMeeting, originalMeeting);
  return audioRequest ? { audio: audioRequest, ...meetingRequest } : meetingRequest;
};

export const ensureSubject = (subject: string, intl: IntlShape): string => {
  return subject || intl.formatMessage(messages.defaultSubject);
};

const updateSubject = (intl: IntlShape, schedulerMeeting: SchedulerMeeting, originalMeeting?: SchedulerMeeting) => {
  const newSubject = ensureSubject(schedulerMeeting.subject, intl);
  return originalMeeting?.subject === newSubject ? {} : { subject: newSubject };
};

const updateStartWithoutOrganizerSupported = (
  schedulerMeeting: SchedulerMeeting,
  originalMeeting?: SchedulerMeeting
) => {
  const newValue = schedulerMeeting.startWithoutOrganizerSupported;
  return originalMeeting?.startWithoutOrganizerSupported === newValue
    ? {}
    : { startWithoutOrganizerSupported: newValue };
};

const updatePasswordRequired = (schedulerMeeting: SchedulerMeeting, originalMeeting?: SchedulerMeeting) => {
  const newValue = schedulerMeeting.passwordRequired;
  return originalMeeting?.passwordRequired === newValue ? {} : { passwordRequired: newValue };
};

const updateForMeetingUrl = (
  schedulerMeeting: SchedulerMeeting,
  originalMeeting: SchedulerMeeting | undefined,
  profile: Profile | undefined | null
) => {
  if (schedulerMeeting.personalizeMeeting) {
    if (!profile || !schedulerMeeting.roomName || !schedulerMeeting.theme) {
      throw new Error('missing fields for personalize meeting');
    }
    const personalizedOriginalMeeting = originalMeeting?.personalizeMeeting ? originalMeeting : undefined;
    const meetingUrl = {
      profileId: !personalizedOriginalMeeting ? profile.profileId : undefined,
      roomName: updateRoomName(schedulerMeeting, personalizedOriginalMeeting),
      theme: updateTheme(schedulerMeeting, personalizedOriginalMeeting)
    };
    return Object.values(meetingUrl).find((item) => item !== undefined) ? { meetingUrl } : {};
  }
  return !originalMeeting || !originalMeeting.personalizeMeeting ? {} : { meetingUrl: {} };
};

const updateRoomName = (schedulerMeeting: SchedulerMeeting, originalMeeting?: SchedulerMeeting) => {
  const newValue = schedulerMeeting.roomName;
  return originalMeeting?.roomName === newValue ? undefined : newValue;
};

const updateTheme = (schedulerMeeting: SchedulerMeeting, originalMeeting?: SchedulerMeeting) => {
  const newValue = schedulerMeeting.theme;
  return originalMeeting?.theme === newValue ? undefined : newValue;
};

const updateForCoorganizers = (schedulerMeeting: SchedulerMeeting, originalMeeting?: SchedulerMeeting) => {
  const newCoOrgKeys = toKeys(schedulerMeeting.coorganizerKeys);
  if (!originalMeeting) {
    return { coorganizerKeys: newCoOrgKeys };
  }
  const origCoOrgKeys = toKeys(originalMeeting.coorganizerKeys);
  return !hasSameElements(newCoOrgKeys, origCoOrgKeys) ? { coorganizerKeys: newCoOrgKeys } : {};
};

const toKeys = (coOrgs?: Array<{ key: string; email: string }>) => {
  return coOrgs ? coOrgs.map((item) => item.key) : [];
};

const updateForAudio = (schedulerMeeting: SchedulerMeeting, originalMeeting?: SchedulerMeeting): AudioRequest => {
  const type = audioSettingsToType(schedulerMeeting as AudioSettingItems);
  if (type === AudioType.private) {
    return updatePrivateAudio(schedulerMeeting, originalMeeting);
  }

  if (!originalMeeting || hasAudioChanged(type, schedulerMeeting, originalMeeting)) {
    return {
      audioType: type,
      tollCountries: schedulerMeeting.longDistanceEnabled ? schedulerMeeting.longDistanceCountries : undefined,
      tollFreeCountries: schedulerMeeting.tollFreeEnabled ? schedulerMeeting.tollFreeCountries : undefined,
      dialOutCountries: schedulerMeeting.callMeEnabled ? schedulerMeeting.callMeCountries : undefined,
      isDialOut: !!schedulerMeeting.callMeEnabled
    };
  }
  return undefined;
};

const hasAudioChanged = (
  type: AudioType,
  schedulerMeeting: SchedulerMeeting,
  originalMeeting: SchedulerMeeting
): boolean => {
  return (
    audioTypeChanged(type, originalMeeting) ||
    audioProvidersChanged(schedulerMeeting, originalMeeting) ||
    anyCountrySelectionChanged(schedulerMeeting, originalMeeting)
  );
};

const audioTypeChanged = (type: AudioType, originalMeeting: SchedulerMeeting): boolean => {
  const oldType = audioSettingsToType(originalMeeting as AudioSettingItems);
  return type !== oldType;
};

const audioProvidersChanged = (schedulerMeeting: SchedulerMeeting, originalMeeting: SchedulerMeeting): boolean => {
  return (
    schedulerMeeting.callMeEnabled !== originalMeeting.callMeEnabled ||
    schedulerMeeting.longDistanceEnabled !== originalMeeting.longDistanceEnabled ||
    schedulerMeeting.tollFreeEnabled !== originalMeeting.tollFreeEnabled
  );
};

const anyCountrySelectionChanged = (schedulerMeeting: SchedulerMeeting, originalMeeting: SchedulerMeeting): boolean => {
  return (
    countrySelectionChanged(
      schedulerMeeting.callMeEnabled,
      schedulerMeeting.callMeCountries,
      originalMeeting.callMeCountries
    ) ||
    countrySelectionChanged(
      schedulerMeeting.longDistanceEnabled,
      schedulerMeeting.longDistanceCountries,
      originalMeeting.longDistanceCountries
    ) ||
    countrySelectionChanged(
      schedulerMeeting.tollFreeEnabled,
      schedulerMeeting.tollFreeCountries,
      originalMeeting.tollFreeCountries
    )
  );
};

const countrySelectionChanged = (enabled?: boolean, newCountries?: string[], oldCountries?: string[]): boolean => {
  return !!enabled && !hasSameElements(newCountries, oldCountries);
};

const updatePrivateAudio = (schedulerMeeting: SchedulerMeeting, originalMeeting?: SchedulerMeeting): AudioRequest => {
  if (
    originalMeeting &&
    originalMeeting.ownConferenceEnabled &&
    originalMeeting.ownConferenceMessage === schedulerMeeting.ownConferenceMessage
  ) {
    return undefined;
  }
  return {
    audioType: AudioType.private,
    privateMessage: schedulerMeeting.ownConferenceMessage ? schedulerMeeting.ownConferenceMessage : '' // Needs to have better error handling
  };
};

const audioSettingsToType = (audioSettings: AudioSettingItems): AudioType => {
  if (audioSettings.ownConferenceEnabled) {
    return AudioType.private;
  }
  if (audioSettings.voipEnabled) {
    if (audioSettings.longDistanceEnabled || audioSettings.tollFreeEnabled) {
      return AudioType.voipAndPstn;
    }
    return AudioType.voip;
  }
  if (audioSettings.longDistanceEnabled || audioSettings.tollFreeEnabled) {
    return AudioType.pstn;
  }
  return AudioType.none;
};

const updateForSchedule = (schedulerMeeting: SchedulerMeeting): MeetingRequest => {
  if (schedulerMeeting.meetingType === MeetingType.scheduled) {
    const startTime = getDateTimeInUtc(
      getDateString(schedulerMeeting.when),
      getTimeString(schedulerMeeting.starts),
      schedulerMeeting.displayTimeZone
    );
    const endTime = addMinutesToDate(startTime, schedulerMeeting.duration);
    return {
      meetingType: MeetingType.scheduled,
      displayTimeZone: schedulerMeeting.displayTimeZone,
      scheduledStartTime: startTime,
      scheduledEndTime: endTime
    };
  }
  return {
    meetingType: MeetingType.recurring
  };
};

/**
 * Create a SchedulerMeeting from a MeetingResponse object.
 * Used to edit a meeting inside the scheduler.
 * Also appends account settings for audio (default/allowed).
 *
 * @param response
 * @param settings
 */
export const createSchedulerMeetingFromMeetingResponseAndSettings = (
  response: Meeting,
  settings: MeetingSettings
): SchedulerMeeting => {
  let longDistanceCountries: string[] = [];
  let tollFreeCountries: string[] = [];
  let callMeCountries: string[] = [];
  if (response.audio?.audioType !== AudioType.private) {
    callMeCountries = response.audio?.dialOutInfo?.map((item) => item.country) || [];
  }
  if (response.audio?.audioType === AudioType.pstn || response.audio?.audioType === AudioType.voipAndPstn) {
    longDistanceCountries =
      response.audio?.phoneNumbers?.filter((item) => !item.tollFree).map((item) => item.country) || [];
    tollFreeCountries = response.audio?.phoneNumbers?.filter((item) => item.tollFree).map((item) => item.country) || [];
  }

  let meeting = {
    subject: response.subject,
    displayTimeZone: response.displayTimeZone || DEFAULT_TIMEZONE,
    personalizeMeeting: !!response.meetingUrl,
    roomName: response.meetingUrl ? response.meetingUrl.roomName : '',
    theme: response.meetingUrl ? response.meetingUrl.theme : 'default',
    coorganizerKeys: !response.coorganizers
      ? []
      : response.coorganizers.map((item) => ({
          ...item,
          email: item.email || '',
          value: item.key,
          label: `${item.firstName} ${item.lastName}`
        })),
    startWithoutOrganizerSupported: response.startWithoutOrganizerSupported,
    passwordRequired: response.passwordRequired,
    breakoutsAllowed: response.breakoutsAllowed,
    allowedCallMeCountries: settings.allowedCallMeCountries,
    allowedLongDistanceCountries: settings.allowedLongDistanceCountries,
    allowedTollFreeCountries: settings.allowedTollFreeCountries,
    callMeAllowed: settings.callMeAllowed,
    longDistanceAllowed: settings.longDistanceAllowed,
    tollFreeAllowed: settings.tollFreeAllowed,
    voipAllowed: settings.voipAllowed,

    voipEnabled: response?.audio?.audioType === AudioType.voip || response?.audio?.audioType === AudioType.voipAndPstn,
    callMeEnabled: response?.audio?.audioType !== AudioType.private && response?.audio?.isDialOut,
    longDistanceCountries,
    tollFreeCountries,
    longDistanceEnabled: !!longDistanceCountries.length,
    tollFreeEnabled: !!tollFreeCountries.length,
    callMeCountries,
    ownConferenceAllowed: settings.ownConferenceAllowed,
    ownConferenceEnabled: response?.audio?.audioType === AudioType.private,
    ownConferenceMessage: response?.audio?.audioType === AudioType.private ? response?.audio?.privateMessage : ''
  };

  if (moment.tz.zone(meeting.displayTimeZone) === null) {
    meeting = {
      ...meeting,
      displayTimeZone: DEFAULT_TIMEZONE
    };
    logger.error(`Invalid timezone ${response.displayTimeZone}`);
  }

  if (isScheduledMeeting(response)) {
    const start = new Date(response.scheduledStartTime);
    const end = new Date(response.scheduledEndTime);
    const duration = Math.round((end.getTime() - start.getTime()) / 60000);
    const scheduledStartDateTimeInTZ = moment.utc(response.scheduledStartTime).tz(meeting.displayTimeZone, false);
    const startDateInTimeZone = new Date(
      scheduledStartDateTimeInTZ.year(),
      scheduledStartDateTimeInTZ.month(),
      scheduledStartDateTimeInTZ.date()
    );
    const startTimeInTimeZone = new Date(
      1990,
      0,
      1,
      scheduledStartDateTimeInTZ.hour(),
      scheduledStartDateTimeInTZ.minute()
    );

    return {
      ...meeting,
      meetingType: MeetingType.scheduled,
      when: startDateInTimeZone,
      starts: startTimeInTimeZone,
      duration
    };
  }
  return { ...meeting, meetingType: MeetingType.recurring };
};

/**
 * Compares two SchedulerFullMeeting objects and returns all changed keys, if they are relevant.
 * Relevant means i.e. if countries differ, but the associated enabled fields are disabled, they are
 * ignored.
 *
 * @param a
 * @param b
 */
export const getChangedMeetingKeys = (
  a: SchedulerFullMeeting,
  b: SchedulerFullMeeting
): Array<keyof SchedulerFullMeeting> => {
  return Object.keys(a).filter((item) => {
    const key = item as keyof SchedulerFullMeeting;
    const left = a[key];
    const right = b[key];

    switch (key) {
      case 'when':
        return !compareDate(a.when, b.when);

      case 'starts':
        return !compareTime(a.starts, b.starts);

      case 'coorganizerKeys':
        if (Array.isArray(left) && Array.isArray(right)) {
          return left.length !== right.length || !left.every((x: any) => right.some((y: any) => x.key === y.key));
        }
        return (left ? (left as []).length : 0) !== (right ? (right as []).length : 0);

      case 'theme':
      case 'roomName':
        if (!a.personalizeMeeting && !b.personalizeMeeting) {
          return false;
        }
        break;

      case 'callMeCountries':
        if (a.callMeEnabled && b.callMeEnabled) {
          return !hasSameElements(left as string[], right as string[]);
        }
        return false;

      case 'tollFreeCountries':
        if (a.tollFreeEnabled && b.tollFreeEnabled) {
          return !hasSameElements(left as string[], right as string[]);
        }
        return false;

      case 'longDistanceCountries':
        if (a.longDistanceEnabled && b.longDistanceEnabled) {
          return !hasSameElements(left as string[], right as string[]);
        }
        return false;
    }

    return left !== right;
  }) as Array<keyof SchedulerFullMeeting>;
};
