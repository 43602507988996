import React from 'react';
import styled from '../../../styled-components';

interface Props {
  filename: string | null;
  onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void;
}

const FilenameBadge = styled.div`
  align-self: baseline;
  background: #f1f2f6;
  color: #737c88;
  padding: 2px 6px;
  border-radius: 5px;
  margin-bottom: 5px;
  outline-offset: -4px;
  cursor: default;
`;

export const PasteImagePreview: React.FC<Props> = (props) => {
  const { filename = '' } = props;
  return (
    <FilenameBadge data-testid="filename-badge" tabIndex={0} onKeyDown={props.onKeyDown}>
      {filename}
    </FilenameBadge>
  );
};
