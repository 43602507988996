import { defineMessages } from 'react-intl';

const messages = defineMessages({
  genericError: {
    id: 'lib.messages.genericError',
    defaultMessage: 'An Error Occurred'
  },
  genericErrorMessage: {
    id: 'lib.messages.genericErrorMessage',
    defaultMessage: 'Please try again in a few minutes'
  },
  advancedInfo: {
    id: 'lib.messages.advancedInfo',
    defaultMessage: 'Advanced Info'
  },
  disabledTooltipAdmin: {
    id: 'lib.messages.disabledTooltipAdmin',
    defaultMessage: 'Your admin has disabled this setting.'
  },
  disabledTooltipExclusive: {
    id: 'lib.messages.disabledTooltipExclusive',
    defaultMessage: 'This option is available only with built-in audio.'
  }
});

export default messages;
