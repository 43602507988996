export default function hash(str: string) {
  let ret = 0;

  for (let char, i = 0; i < str.length; i++) {
    char = str.charCodeAt(i);
    ret = ret * 32 - ret + char;
    ret = ret & ret; // Convert to 32bit integer
  }

  return ret;
}
