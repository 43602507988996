import * as React from 'react';
import { ModalDialog } from '../../Common/ModalDialog/ModalDialog';
import { MeetingDelete, Props as MeetingDeleteProps } from './MeetingDelete';

type Props = MeetingDeleteProps & {
  hideDeleteMeeting: () => void;
};

export const MeetingDeleteModal: React.FC<Props> = (props) => (
  <ModalDialog data-testid="meeting-delete-modal" contentLabel="MeetingDeleteModal" onClose={props.hideDeleteMeeting}>
    <MeetingDelete {...props} />
  </ModalDialog>
);
