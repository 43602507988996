import * as React from 'react';
import { InputFieldset } from '../../Common/Toolkit/Input/InputFieldset';
import { Box } from '../../Common/Toolkit/Box/Box';
import styled from '../../../styled-components';
import { Spinner } from '../../Common/Toolkit/Spinner/Spinner';
import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { themeGet } from '@styled-system/theme-get';

const ProfileIdInput = styled.input`
  line-height: 20px;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  min-width: 0;

  &:disabled {
    background: none;
    color: #888;
  }
`;

const NoBreakLabel = styled.label`
  white-space: nowrap;
  line-height: 20px;
  color: ${themeGet('colors.neutralScale.400')};
`;

export type ValidationResult =
  | true
  | {
      message: React.ReactNode;
    };

const debounced = debounce((fun: () => void) => fun(), 500);

interface Props {
  onChange: (value: string) => void;
  label: React.ReactNode;
  value: string | null;
  initial?: string;
  disabled?: boolean;

  isLoading?: boolean;
  prefix: string;

  successMessage?: React.ReactChild | null;
  hideMessage?: boolean;

  validateInput?: (value: string) => Promise<ValidationResult>;
  onValidationError?: (value: string) => void;
  onValidationSuccess?: (value: string) => void;
  sanitize?: boolean;
}

export const MeetingLinkInput = (props: Props) => {
  const [requestCount, setRequestCount] = useState(0);
  const [validValues, setValidValues] = useState([] as string[]);
  const [validationResult, setValidationResult] = useState(true as ValidationResult);
  const requestCountRef = useRef(requestCount);
  const validValuesRef = useRef(validValues);
  let unmounted = false;

  requestCountRef.current = requestCount;
  validValuesRef.current = validValues;

  const validate = (value: string) => {
    if (value && value === props.initial) {
      return;
    }

    debounced(async () => {
      let currentValidValues = [] as string[];
      if (props.validateInput) {
        if (validValuesRef.current.indexOf(value) === -1) {
          setRequestCount(requestCountRef.current + 1);
          const result = await props.validateInput(value);
          if (result === true) {
            setValidValues((currentValidValues = [...validValuesRef.current, value]));
          }
          setValidationResult(result);
          setRequestCount(requestCountRef.current - 1);
        }

        if (requestCountRef.current === 0 || unmounted) {
          if ([...currentValidValues, ...validValuesRef.current].indexOf(value) >= 0) {
            if (props.onValidationSuccess) {
              props.onValidationSuccess(value);
            }
          } else if (props.onValidationError) {
            props.onValidationError(value);
          }
        }
      }
    });
  };

  // validate immediately enable
  useEffect(() => {
    if (!props.disabled) {
      validate(props.value || '');
    }
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      unmounted = true;
    };
  }, [props.disabled]);

  // validate after
  useEffect(() => {
    if (!props.disabled) {
      setValidationResult({ message: '' });
      if (props.onValidationError && (!props.initial || props.initial !== props.value)) {
        props.onValidationError(props.value || '');
      }
      validate(props.value || '');
    }
  }, [props.value]);

  let messageType: null | 'error' | 'success' = null;
  let messageLabel = null;
  if (!props.disabled && !props.hideMessage && props.initial !== props.value) {
    if (props.value && validValues.indexOf(props.value) !== -1) {
      messageType = 'success';
      messageLabel = props.successMessage;
    } else if (validationResult !== true) {
      messageType = 'error';
      messageLabel = validationResult.message;
    }
  }

  const sanitize = (value: string) =>
    props.sanitize ? value.replace(/[^\u00C0-\u1FFF\u2C00-\uD7FF\w0-9_-]/g, '') : value;

  return (
    <InputFieldset legend={props.label} messageType={messageType} message={messageLabel} testId="meeting-link">
      <Box flexDirection="row">
        <NoBreakLabel htmlFor="profileIdInput">{props.prefix}/</NoBreakLabel>
        <ProfileIdInput
          type="text"
          id="profileIdInput"
          data-testid="meeting-link-input"
          onChange={(e) => props.onChange(sanitize(e.target.value))}
          value={props.value || ''}
          autoComplete="off"
          disabled={props.disabled}
          maxLength={50}
        />
        {(!!props.isLoading || requestCount > 0) && (
          <div>
            <Spinner width="12px" height="12px" lineWidth="3px" />
          </div>
        )}
      </Box>
    </InputFieldset>
  );
};
