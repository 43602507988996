import { connect } from 'react-redux';
import { get } from 'lodash';
import { FilesMessage, GroupActions, MessageActions, Selectors, ChatActions as chatActions } from '@getgo/caps-redux';
import { cleanId } from '@getgo/format-meeting-id';
import { User } from '../../types/pulse-web';
import { FileUploadOptions } from '../services/UploadService';
import {
  DraftJSPersistedMessageInput,
  PlainPersistedMessageInput
} from '../view-model/modules/messaging/messageInputs/messageInputsReducer';
import messagingActions from '../view-model/modules/messaging/messagingActions';
import MessageInputActions from '../view-model/modules/messaging/messageInputs/messageInputsActions';
import { join } from '../view-model/modules/meetings/meetingsActions';
import { FileUploadEntry } from '../view-model/modules/messaging/uploads/uploadsReducer';
import { selectNextContactWithHistory } from '../view-model/capsActions';
import { openImagePreview } from '../view-model/modules/messaging/imagePreview/imagePreviewActions';
import UploadActions, { uploadFile } from '../view-model/modules/messaging/uploads/uploadsActions';
import { ChatPane } from '../components/Chat/ChatPane';
import { hydrateGroupChatNames } from '../services/ChatHistoryFormatService';
import { navigateTo } from '../services/NavService';
import { Dispatch, State } from '../view-model/types';

const { clearQuery, searchGroupExactMatch } = GroupActions;

const mapStateToProps = (state: State, _: OwnProps) => {
  const roomId = Selectors.getCurrentRoomId(state);
  const contact = Selectors.findContact(state, roomId);
  const room = Selectors.getChatRoom(state, roomId);
  const connectionStatus = Selectors.getConnectionStatus(state);
  const { networkOnline } = state.core.app.connectionStatus;
  const isOnlineAndConnected = networkOnline && connectionStatus.xmppConnected;

  const persistedMessageInput = state.messaging?.messageInputs[roomId] || {};
  const messages = (room && hydrateGroupChatNames(room.messages, contact)) || [];

  const currentGroup = Selectors.findGroup(state, roomId);
  const groupMembers = currentGroup ? currentGroup.members : [];
  const inputHeight = state.messaging.messageInputs[roomId]
    ? state.messaging.messageInputs[roomId].messageInputHeight
    : 0;
  const publicGroupExists = state.messaging.group.suggestions.some((suggestion) => suggestion.isPublic);

  return {
    contact,
    groupMembers,
    messageInputHeight: inputHeight,
    isLoading: state.messaging.chat.isFetching,
    isOnline: isOnlineAndConnected,
    isPartialLoading: state.messaging.chat.isPartialFetching,
    hasMoreMessages: !get(room, 'paging.complete', true),
    nextPage: get(room, 'paging.first', ''),
    messages,
    user: state.core.user as User,
    roomId,
    persistedMessageInput,
    app: state.core.app,
    uploads: state.messaging.uploads,
    imagePreviewActive: state.messaging.imagePreview.isOpen,
    today: state.core.app.today,
    showingGroupSettings: state.core.app.showingGroupSettings,
    publicGroupExists
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onNewMessage: (roomId: string, text: string, options: {}) => {
      dispatch(MessageActions.sendMessage(roomId, text, options));
    },

    onNewReplaceMessage: (roomId: string, originalMessage: string, text: string, options: {}) => {
      dispatch(MessageActions.replaceMessage(roomId, originalMessage, text, options));
    },

    onReconnectNow: () => {
      chatActions.reconnect();
    },

    onLeaveGroup: (group: string, selected: any) => {
      if (selected) {
        dispatch(selectNextContactWithHistory(group));
      }
      dispatch(GroupActions.leaveGroup(group));
    },

    onDeleteGroup: (group: string, selected: any) => {
      if (selected) {
        dispatch(selectNextContactWithHistory(group));
      }
      dispatch(GroupActions.deleteGroup(group));
    },

    onAddMember: () => {
      navigateTo('addMembers');
    },

    onRemoveMember: (groupJid: string, userJid: string) => {
      dispatch(GroupActions.removeGroupMember(groupJid, userJid));
    },

    onLoadMore: (roomId: string, nextPage: number, isGroup: boolean) => {
      dispatch(MessageActions.loadMoreHistory(roomId, nextPage, isGroup));
    },

    persistDraftJSMessageInput: (roomId: string, persistedMessageInput: DraftJSPersistedMessageInput) => {
      dispatch(MessageInputActions.persistDraftJSMessageInput(roomId, persistedMessageInput));
    },

    persistPlainMessageInput: (roomId: string, persistedMessageInput: PlainPersistedMessageInput) => {
      dispatch(MessageInputActions.persistPlainMessageInput(roomId, persistedMessageInput));
    },

    onUpdateGroupName: async (group: string, groupName: string) => {
      await dispatch(GroupActions.updateGroupName(group, groupName));
      dispatch(clearQuery());
    },

    uploadFiles: (options: FileUploadOptions, files: File[] | FileList) => {
      for (let i = 0; i < files.length; i++) {
        dispatch(uploadFile(options, files[i]));
      }
    },

    cancelUpload: (upload: FileUploadEntry) => {
      dispatch(UploadActions.uploadCancel(upload));
    },

    clearUpload: (upload: FileUploadEntry) => {
      dispatch(UploadActions.uploadClear(upload));
    },

    onPreviewOpen: (message: FilesMessage) => {
      dispatch(openImagePreview(message));
    },

    onJoinMeeting: (meetingUrl: string) => {
      const meetingId = cleanId(meetingUrl);
      dispatch(join(meetingId));
    },

    changeMessageInputHeight: (roomId: string, height: number) => {
      dispatch(MessageInputActions.changeMessageInputHeight(roomId, height));
    },

    addTypingIndicator: (roomId: string, isGroupChat: boolean) => {
      dispatch(MessageActions.sendTypingIndicator(roomId, isGroupChat));
    },

    removeTypingIndicator: (roomId: string, isGroupChat: boolean) => {
      dispatch(MessageActions.removeTypingIndicator(roomId, isGroupChat));
    },

    onSearchGroup: async (query: string, startIndex = 0) => {
      if (query.trim && query.trim().length) {
        dispatch(clearQuery());
        await dispatch(searchGroupExactMatch(query, startIndex));
      } else {
        dispatch(clearQuery());
      }
    },

    onGroupMemberPopoverOpen: () => {
      dispatch(messagingActions.openGroupMembers());
    }
  };
};

const mergeProps = (stateProps: StateProps, dispatchProps: DispatchProps, ownProps: OwnProps) => {
  const uploadOptions: FileUploadOptions = {
    from: stateProps.user.jid,
    to: stateProps.roomId,
    token: stateProps.user.token
  };

  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    sidebarDocked: !!ownProps.sidebarDocked,
    onLoadMore: () => dispatchProps.onLoadMore(stateProps.roomId, stateProps.nextPage, stateProps.contact.isGroup),
    onNewMessage: (text: string, options: {}) => dispatchProps.onNewMessage(stateProps.roomId, text, options),
    onNewReplaceMessage: (originalMessage: string, text: string, options: {}) =>
      dispatchProps.onNewReplaceMessage(stateProps.roomId, originalMessage, text, options),
    uploadFiles: (files: File[] | FileList) => dispatchProps.uploadFiles(uploadOptions, files)
  };
};

interface OwnProps {
  autoFocus?: boolean;
  onSetSidebarOpen?: () => void;
  screenShareId?: string;
  sidebarDocked?: boolean;
  user?: User;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const ChatContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ChatPane);

export default ChatContainer;
