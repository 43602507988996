import * as React from 'react';
import styled from '../../styled-components';
import { FormattedMessage, defineMessages } from 'react-intl';
import { PrimaryButton } from '../Common/Toolkit/Button/PrimaryButton';
import config from 'config';
import { State as UserState } from '../../view-model/modules/core/user/userReducer';
import { startBuyFlowEvent } from '../../services/GoogleTagManagerService';
import device from '../Common/Toolkit/devices';
import { MediaQueryFeatureSelector } from '../Common/MediaQueryFeatureSelector';

const messages = defineMessages({
  trialExpire: {
    id: 'meetingsDashboard.buyNowBar.trialExpire',
    defaultMessage: 'Your trial expires {daysRemaining, plural, =0 {today} one {in # day} other {in # days}}.'
  },
  freeUserInfo: {
    id: 'meetingsDashboard.buyNowBar.freeUserInfo',
    defaultMessage: 'You are using GoToMeeting Free.'
  },
  buyNowLabel: {
    id: 'meetingsDashboard.buyNowBar.buyNowLabel',
    defaultMessage: 'Buy Now'
  },
  upgradeLabel: {
    id: 'meetingsDashboard.buyNowBar.upgradeLabel',
    defaultMessage: 'Upgrade Now'
  }
});

const BuyNowButton = styled(
  (props: { className?: string; isFreeUser?: boolean; onClick: (buyNowProps: Props) => void }) => (
    <PrimaryButton data-testid="buy-now-button" className={props.className} iconSize={12} onClick={props.onClick}>
      <FormattedMessage {...(props.isFreeUser ? messages.upgradeLabel : messages.buyNowLabel)} />
    </PrimaryButton>
  )
)`
  text-transform: uppercase;
  margin-left: 32px;
  font-size: 12px;
`;

const BuyNowContainer = styled.div`
  border-top: 1px solid #9aa8bb;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 48px;
  background-color: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TrialExpireText = styled.span`
  font-size: 16px;
  color: #25282d;
`;

interface Props {
  trialExpiresInDays: number;
  show: boolean;
  user: UserState;
  isFreeUser?: boolean;
}

export const BuyNowBar: React.FC<Props> = (props) => {
  if (!props.show) {
    return null;
  }

  const openBuyNowLink = () => {
    const accountKey = props.user.hasLoaded ? props.user.accountKey : '0';
    startBuyFlowEvent(accountKey);
    window.setTimeout(() => {
      window.open(`${config.externalLinks.buyNowMarketingPage}`, '_self');
    }, 100);
  };

  const desktopView = (
    <BuyNowContainer data-testid="buy-now-container">
      <TrialExpireText>
        {props.isFreeUser ? (
          <FormattedMessage {...messages.freeUserInfo} />
        ) : (
          <FormattedMessage {...messages.trialExpire} values={{ daysRemaining: props.trialExpiresInDays }} />
        )}
      </TrialExpireText>
      <BuyNowButton onClick={openBuyNowLink} isFreeUser={props.isFreeUser} />
    </BuyNowContainer>
  );
  return <MediaQueryFeatureSelector query={device.mobile} queryMatched={null} queryNotMatched={desktopView} />;
};
