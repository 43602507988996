import * as React from 'react';
import './Avatar.css';
import { isHoverSupported } from '../../../services/ClientService';
import { getAvatarInitials } from '../../../../lib/person';
import styled, { css } from '../../../styled-components';
import { LockClosed, PeopleFilled } from '../Toolkit/Icon/ReactIcons';

const avatarSizeMapping = {
  small: '24px',
  medium: '100px',
  large: '150px'
};

const AvatarImage = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 0;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

const transparentInitialsStyles = css`
  color: #342e41;
  background-color: transparent;
`;

const Initials = styled.span<{ color?: string; backgroundColor?: string; transparent: boolean | undefined }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: ${(props) => props.color || '#fff'};
  background-color: ${(props) => props.backgroundColor || '#444444'};
  font-size: inherit;
  ${(props) => (props.transparent ? transparentInitialsStyles : null)}
`;

const transparentAvatarWrapperStyles = css`
  background: none;
  border: 1.5px dashed #444;
  box-sizing: border-box;
`;

const AvatarWrapper = styled.span<{ transparent: boolean | undefined }>`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  ${(props) => (props.transparent ? transparentAvatarWrapperStyles : null)}
`;

const AvatarWithTooltip = styled.span<{ size: 'small' | 'medium' | 'large' | undefined }>`
  position: relative;
  display: inline-block;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  margin: ${(props) => (props.size && props.size === 'small' ? '0 10px;' : '0 12px')};
  font-size: ${(props) =>
    (props.size && props.size === 'small') || !props.size ? '14px' : props.size === 'medium' ? '30px' : '80px'};
  width: ${(props) => (!props.size ? '35px' : avatarSizeMapping[props.size])};
  height: ${(props) => (!props.size ? '35px' : avatarSizeMapping[props.size])};
  line-height: ${(props) => (!props.size ? '35px' : avatarSizeMapping[props.size])};
`;

export interface Props {
  children?: React.ReactElement<any> | null;
  name?: string;
  img?: string;
  size?: 'small' | 'medium' | 'large';
  icon?: typeof PeopleFilled | typeof LockClosed;
  transparent?: boolean;
  color?: string;
  backgroundColor?: string;
  tooltip?: string;
  className?: string;
}

interface State {
  isImgError: boolean;
}

export class Avatar extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isImgError: false
    };
  }

  handleError = () => {
    this.setState({ isImgError: true });
  };

  getInitials(): string {
    return this.props.name ? getAvatarInitials(this.props.name) : '';
  }

  renderIcon(): React.ReactElement<any> | null | false {
    if (this.props.icon) {
      const size = this.props?.size === 'small' ? 14 : 18;
      return <this.props.icon size={size} unit={'px'} color={this.props.color || '#fff'} />;
    }
    return null;
  }

  renderIconOrInitials(): React.ReactElement<any> | null | false {
    if (!this.props.img || this.state.isImgError) {
      return (
        <Initials
          color={this.props.color}
          backgroundColor={this.props.backgroundColor}
          transparent={this.props.transparent}
        >
          {this.renderIcon() || this.getInitials()}
        </Initials>
      );
    }
    return null;
  }

  renderImage(): React.ReactElement<any> | null | false {
    if (!this.state.isImgError && this.props.img) {
      return (
        <AvatarImage>
          <img alt={this.getInitials()} src={this.props.img} onError={this.handleError} />
        </AvatarImage>
      );
    }
    return null;
  }

  render() {
    return (
      <AvatarWithTooltip
        className={this.props.className}
        size={this.props.size}
        data-delay-show="500"
        data-place="right"
        data-tip={isHoverSupported ? this.props.tooltip : null}
        data-testid="avatar"
      >
        <AvatarWrapper transparent={this.props.transparent}>
          {this.renderIconOrInitials()}
          {this.renderImage()}
        </AvatarWrapper>
        {this.props.children}
      </AvatarWithTooltip>
    );
  }
}
