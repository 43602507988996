import { FilesMessage, File } from '@getgo/caps-redux';
import bytes from 'bytes';
import React from 'react';
import { isHoverSupported } from '../../../../services/ClientService';
import { FileIcon } from '../../../Common/FileIcon/FileIcon';
import './FilesMessageEntry.css';

interface Props {
  message: FilesMessage;
  isDownloadEnabled: boolean;
  disabledTooltip: string;
}

export const FilesMessageEntry: React.FC<Props> = (props) => {
  const { message, isDownloadEnabled, disabledTooltip } = props;
  // for now only support a single file
  const [file]: File[] = message.files;

  let linkProperties;
  if (isDownloadEnabled) {
    linkProperties = {
      className: 'files-message',
      href: file.href,
      target: '_blank',
      rel: 'noopener noreferrer'
    };
  } else {
    linkProperties = {
      className: 'files-message disabled',
      'data-offset': '{"top": -5 }',
      'data-tip': isHoverSupported ? disabledTooltip : null,
      'data-multiline': 'true'
    };
  }

  return (
    <a {...linkProperties}>
      <span className="file-icon">
        <FileIcon type={file.contentType} />
      </span>
      <span className="description">
        <span className="file-name">{file.name}</span>
        <span className="file-size">{bytes(file.size)}</span>
      </span>
      <span className="controls">
        <span className="download-icon">
          <i className="togo-icon togo-icon-download" />
        </span>
      </span>
    </a>
  );
};
