import config from 'config';
import { Pendo, PendoFunctions } from '../models/Pendo';
import { getWindowPendo } from '../lib/dom-helper';

export const appendPendo = (scriptURL: string, onLoad: () => void, onError: () => void) => {
  // Code snippet retrieved from the Pendo App
  const pendoLib = getWindowPendo()['pendo'] || ({} as Pendo);
  getWindowPendo()['pendo'] = pendoLib;
  pendoLib._q = pendoLib._q || [];
  for (const func of Object.values(PendoFunctions)) {
    if (!pendoLib[func]) {
      pendoLib[func] = (...args: unknown[]) => pendoLib._q.push([func, [...args]]);
    }
  }
  const script = getWindowPendo().document.createElement('script');
  script.async = true;
  script.onload = onLoad;
  script.onerror = onError;
  script.src = scriptURL;
  getWindowPendo().document.body.appendChild(script);
};

export const initializePendo = async (pendoData: PendoData) => {
  const { pendo } = config;
  const scriptUrl = `${pendo.resourceUrl}/${pendo.apiKey}/${pendo.resourceName}`;
  await new Promise<void>((resolve, reject) =>
    appendPendo(
      scriptUrl,
      () => resolve(),
      () => reject()
    )
  );
  getWindowPendo().pendo?.initialize(pendoData);
};
