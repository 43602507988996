import { Note } from '../../../view-model/modules/meetings/meetingsReducer';

interface AttributeType {
  fileId: string;
  mediaType: string;
  downloadUrl: string;
}

interface FileType {
  type: string;
  attributes: AttributeType[];
}

interface FileInformation {
  files: FileType[];
}

interface Attendee {
  attendeeIP?: string;
  attendeeType: string;
  buildNumber: string;
  email?: string;
  name: string;
  participantKey: string;
  sessionParticipationTimes: Array<{
    joinTime: string;
    leaveTime: string;
  }>;
  talkTimeData?: any[];
  totalTalkTimePercentage?: any;
}

interface AnalyticsResponse {
  accountKey?: string;
  analyzedTranscriptJsonPreSignedUrl?: string;
  attendees?: Attendee[];
  callType: string;
  downloadRecordingEntitled: boolean;
  endTime: string;
  featureState?: {
    actionItems: {
      enabled: boolean;
    };
    highlights: {
      enabled: boolean;
    };
    newUI: {
      enabled: boolean;
    };
  };
  fileInformation?: FileInformation;
  meetingId: string;
  meetingName?: string;
  participantCount: string;
  playbackPreSignedUrl: string;
  recordingTimes: Array<{
    startTime: number;
    stopTime: number;
  }>;
  scope: Array<{
    type: string;
  }>;
  sessionKey?: string;
  shareKey: string;
  startTime: string;
  telemetryEvents: {
    product: string;
    sessionKey: string;
    [key: string]: any;
  };
  title?: string;
  transcriptEnabled: boolean;
  transcriptJsonPreSignedUrl: string;
  sharedResources?: Array<'VIDEO' | 'TRANSCRIPT' | 'SESSION_INFO' | 'NOTES'>;
  sttTranscriptJsonPreSignedUrl?: string;
  userKey?: string;
  shareExpired?: boolean;
  insessionChatPreSignedUrl?: string;
}

export function createMeetingAssetDetails(analytics: AnalyticsResponse) {
  const pdfURL = getPDFUrlFromAssets(analytics);
  const chatURL = getChatUrlFromAnalytics(analytics);
  const attendees = addTalkTimesToAttendees(analytics);
  const engagement = calculateEngagementMetrics(attendees);
  return {
    pdfURL,
    chatURL,
    ...engagement,
    recordingTimes: analytics.recordingTimes,
    attendees
  };
}

const getPDFUrlFromAssets = (analtyicsResponse: AnalyticsResponse) =>
  get(
    analtyicsResponse,
    (a) =>
      a.fileInformation?.files
        ?.find((file: any) => file.type === 'pdf')
        ?.attributes?.find((attr: any) => attr.fileId === 'indexesPdf')?.downloadUrl,
    ''
  );

const getChatUrlFromAnalytics = (analtyicsResponse: AnalyticsResponse) =>
  get(analtyicsResponse, () => analtyicsResponse?.insessionChatPreSignedUrl);

/**
 * Type-safe access of deep property of an object. *
 * @param obj                   Object to get deep property
 * @param unsafeDataOperation   Function that returns the deep property
 * @param valueIfFail           Value to return in case if property not found
 */
const get = <O, T>(obj: O, unsafeDataOperation: (x: O) => T, valueIfFail?: any): T => {
  try {
    return unsafeDataOperation(obj);
  } catch (error) {
    return valueIfFail;
  }
};

function totalTime(starts = [], ends = [], duration: number, startTime: number) {
  const events: any = {};

  starts.forEach((start) => {
    events[start] = 'start';
  });
  ends.forEach((end) => {
    events[end] = 'end';
  });

  let lastEvent: any = {};
  return Object.keys(events)
    .map((timestamp) => parseInt(timestamp, 10))
    .sort((a, b) => a - b)
    .reduce((array: any, timestamp) => {
      const type = events[timestamp];

      if (lastEvent.type !== type) {
        if (lastEvent.type === 'start') {
          const startTimestamp = lastEvent.timestamp;
          const startPercentage = ((startTimestamp - startTime) / duration) * 100;
          const endPercentage = ((timestamp - startTime) / duration) * 100;
          const durationPercentage = endPercentage - startPercentage;
          const durationTime = timestamp - startTimestamp;
          array.push({
            durationPercentage,
            startTimestamp,
            durationTime,
            startPercentage,
            endPercentage
          });
        }
        lastEvent = { timestamp, type };
      }

      return array;
    }, []);
}

function sortAttendees(attendees: Attendee[]) {
  if (attendees && attendees.length > 0) {
    const me: any = attendees.shift();
    attendees.sort((a, b) => b.totalTalkTimePercentage - a.totalTalkTimePercentage);
    attendees.unshift(me);
  }
}

function addTalkTimesToAttendees(session: any) {
  const { telemetryEvents = {} } = session;
  let { attendees = [] } = session;

  if (attendees.length === 0) {
    return [];
  }
  attendees = attendees.filter((attendee: Attendee) => !!attendee);

  const startTime = parseInt(session.startTime, 10);
  const endTime = parseInt(session.endTime, 10);
  const sessionDuration = endTime - startTime;

  // sort by participantKey assumes that the first is the organizer which is used later
  attendees.sort((a: any, b: any) => Number(a.participantKey) - Number(b.participantKey));

  const newAttendees = attendees.reduce((array: any, attendee: Attendee) => {
    const telemetryEvent = telemetryEvents[attendee.participantKey];
    const newAttendee = {
      ...attendee,
      ...telemetryEvent
    };
    const leaveTimes = attendee.sessionParticipationTimes.map((t) => parseInt(t.leaveTime, 10));
    // @ts-ignore
    const audioStopTimes = [].concat(newAttendee.audioStopTimes || [], leaveTimes, endTime);

    newAttendee.talkTimeData = totalTime(newAttendee.audioStartTimes, audioStopTimes, sessionDuration, startTime);
    newAttendee.totalTalkTimePercentage = newAttendee.talkTimeData
      .reduce((totalPercentage: any, talkTimeData: any) => totalPercentage + talkTimeData.durationPercentage, 0)
      .toFixed(0);

    array.push(newAttendee);

    return array;
  }, []);

  // Guarantee that the organizer is first on the list and the rest are sorted by talk time
  sortAttendees(newAttendees);
  return newAttendees;
}

const calculateEngagementMetrics = (attendees: Attendee[]) => {
  let organizerTotalTalkDuration = 0;
  let attendeeTotalTalkDuration = 0;
  attendees.forEach((attendee) => {
    if (attendee.attendeeType === 'ORGANIZER') {
      attendee.talkTimeData?.forEach(({ durationTime }) => {
        organizerTotalTalkDuration += durationTime;
      });
    } else {
      attendee.talkTimeData?.forEach(({ durationTime }) => {
        attendeeTotalTalkDuration += durationTime;
      });
    }
  });

  const totalTalkTimeDuration = organizerTotalTalkDuration + attendeeTotalTalkDuration;
  const organizerEngagement = Math.round((organizerTotalTalkDuration / totalTalkTimeDuration) * 100);
  const attendeeEngagement = 100 - organizerEngagement;
  return { attendeeEngagement, organizerEngagement };
};

const calculateRecordingTimesWithPause = (
  recordingEpochTimes: Array<{
    startTime: number;
    stopTime: number;
  }>
) => {
  let pauseTime = 0;
  return recordingEpochTimes.map((recordingTime, i) => {
    pauseTime = i === 0 ? pauseTime : pauseTime + (recordingTime.startTime - recordingEpochTimes[i - 1].stopTime);

    return {
      ...recordingTime,
      pauseTime
    };
  });
};

export const correlateNotesToVideo = (
  notes: Note[],
  recordingEpochTimes: Array<{
    startTime: number;
    stopTime: number;
  }>
) => {
  if (!recordingEpochTimes.length || !notes) {
    return notes;
  }

  const recordingStartTime = recordingEpochTimes[0].startTime;
  const recordingEndTime = recordingEpochTimes[recordingEpochTimes.length - 1].stopTime;
  const recordingTimesWithPause = calculateRecordingTimesWithPause(recordingEpochTimes);
  const calculateNoteTime = (note: Note) => {
    let videoTime;
    if (note.startEpochTimeStamp < recordingStartTime) {
      videoTime = 0;
    } else if (note.startEpochTimeStamp > recordingEndTime) {
      videoTime = -1; // taking -1 to compare later and show/hide play button on UI
    } else {
      const timeObj = recordingTimesWithPause.find((time: { startTime: number; stopTime: number }) => {
        const { startTime, stopTime } = time;
        // if the note time is withing a start and end time
        const withinRecordingTime = note.startEpochTimeStamp >= startTime && note.startEpochTimeStamp <= stopTime;
        const lessThanStartTime = note.startEpochTimeStamp < startTime;

        if (!withinRecordingTime && lessThanStartTime) {
          return true;
        }

        return withinRecordingTime;
      });

      // If note start time is less than the current recording start time, then assume note is the current recording startTime
      videoTime =
        note.startEpochTimeStamp < timeObj!.startTime
          ? timeObj!.startTime - recordingStartTime - timeObj!.pauseTime
          : note.startEpochTimeStamp - recordingStartTime - timeObj!.pauseTime;
    }

    return {
      ...note,
      videoTime
    };
  };

  return notes.map(calculateNoteTime);
};
