import { ActionsUnion, createAction } from '../../../type-helpers';
import { DraftJSPersistedMessageInput, PlainPersistedMessageInput } from './messageInputsReducer';

export enum ActionTypes {
  PERSIST_DRAFTJS_MESSAGE_INPUT_STATE = 'PERSIST_DRAFTJS_MESSAGE_INPUT_STATE',
  PERSIST_PLAIN_MESSAGE_INPUT_STATE = 'PERSIST_PLAIN_MESSAGE_INPUT_STATE',
  CHANGE_MESSAGE_INPUT_HEIGHT = 'CHANGE_MESSAGE_INPUT_HEIGHT'
}

const actions = {
  persistDraftJSMessageInput: (roomId: string, persistedMessageInput: DraftJSPersistedMessageInput) =>
    createAction(ActionTypes.PERSIST_DRAFTJS_MESSAGE_INPUT_STATE, { roomId, persistedMessageInput }),
  persistPlainMessageInput: (roomId: string, persistedMessageInput: PlainPersistedMessageInput) =>
    createAction(ActionTypes.PERSIST_PLAIN_MESSAGE_INPUT_STATE, { roomId, persistedMessageInput }),
  changeMessageInputHeight: (roomId: string, height: number) =>
    createAction(ActionTypes.CHANGE_MESSAGE_INPUT_HEIGHT, { roomId, height })
};

export default actions;
export type Actions = ActionsUnion<typeof actions>;
