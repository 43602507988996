import styled from '../../../../styled-components';
import { themeGet } from '@styled-system/theme-get';
import { defineMessages, useIntl } from 'react-intl';
import React, { createRef, useEffect } from 'react';
import { ButtonBar } from '../Button/ButtonBar';

export const StyledCheckboxAlertContainer = styled.div`
  padding: 4px 20px 20px 20px;
  color: ${themeGet('colors.backgroundOn')};
  display: flex;
  flex-direction: column-reverse;
`;

const StyledChildrenOfContainer = styled.div`
  flex-direction: column;
`;

const messages = defineMessages({
  continue: {
    id: 'common.checkboxalert.button.continue',
    defaultMessage: 'Continue'
  },
  cancel: {
    id: 'common.checkboxalert.button.cancel',
    defaultMessage: 'Cancel'
  }
});

interface Props {
  children: React.ReactNode;
  onSave: () => void;
  onCancel: () => void;
}

export const CheckboxAlertContainer: React.FC<Props> = (props: Props) => {
  const { formatMessage: f } = useIntl();
  const containerRef = createRef<HTMLDivElement>();
  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, []);
  return (
    <StyledCheckboxAlertContainer ref={containerRef}>
      <ButtonBar
        buttonOk={f(messages.continue)}
        buttonCancel={f(messages.cancel)}
        onOk={props.onSave}
        onCancel={props.onCancel}
      />
      <StyledChildrenOfContainer>{props.children}</StyledChildrenOfContainer>
    </StyledCheckboxAlertContainer>
  );
};
