import * as React from 'react';
import { DefaultDraftBlockRenderMap, EditorState, getDefaultKeyBinding } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import config from 'config';
import { EmojiPicker } from '../../../EmojiPicker/EmojiPicker';
import createMentionPlugin from 'draft-js-mention-plugin';
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import 'draft-js/dist/Draft.css';
import 'draft-js-linkify-plugin/lib/plugin.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import * as platform from 'platform';
import createPasteFilesPlugin from '../../Plugins/PasteFiles';
import { UploadButton } from '../UploadButton/UploadButton';
import { addEmojiEntityToState } from '../../Plugins/Emoji/addEmoji';
import createEmojiPlugin from '../../Plugins/Emoji';
import '../../../EmojiPicker/Picker.css';
import './MessageInput.css';
import sendIcon from './main.send.svg';
import { OnClickOutside } from '../../../../Common/OnClickOutside';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  emojiLabel: {
    id: 'chat.messageInput.emojiLabel',
    defaultMessage: 'Add an Emoji'
  },
  submitLabel: {
    id: 'chat.messageInput.submitLabel',
    defaultMessage: 'Submit'
  }
});

export interface Mention {
  id: string;
  name: string;
  email?: string;
  avatar?: string;
}

export interface Props {
  className?: string;
  state: EditorState;
  onChange: (state: EditorState) => void;
  onSubmit: (state: EditorState) => void;
  placeholder?: string;
  readOnly?: boolean;
  customStyleMap?: {
    ITALIC?: {};
    BOLD?: {};
    UNDERLINE?: {};
    CODE?: {};
  };
  onTyping?: () => void;
  enableToolbar?: boolean;
  noSubmitButton?: boolean;
  spellCheck?: boolean;
  isFileSharingAllowed?: boolean;
  onFileUpload?: (files: FileList | File[]) => void;
  onEscape?: () => void;
  submitOnEnter?: boolean;
  getKeyCommand?: (e: React.KeyboardEvent<{}>) => string | null;
  handleKeyCommand?: (command: string, editorState: EditorState) => boolean | null;
  formatMessage: (descriptor: string, values?: { [key: string]: number }) => string;

  // emoji
  showEmojiButton?: boolean;
  useNativeEmojis?: boolean;
  buildEmojiUrl?: (hexCode: string, pngSize: string) => string;

  // mentions
  enableMentions?: boolean;
  mentionSuggestions?: Mention[];
  onMentionSearchChange?: (value: string | null) => void;
  onAddMention?: (mention: Mention) => void;
  mentionEntryComponent?: React.ComponentClass<{ mention: Mention }>;
  excessCharacters?: number;

  // paste
  enablePasteFiles?: boolean;
  onPaste?: (files: File[]) => void;
  pasteElement?: React.ReactNode;
  isShowingPreview?: boolean;
}

export interface InputState {
  showMentions: boolean;
  showEmojiPicker: boolean;
}

const blockRenderMap = DefaultDraftBlockRenderMap.delete('unordered-list-item').delete('ordered-list-item');

function immutableMentionToJs(mention: any): Mention {
  return {
    id: mention.get('id'),
    name: mention.get('name'),
    email: mention.get('email'),
    avatar: mention.get('avatar')
  };
}

export class MessageInput extends React.Component<Props, InputState> {
  static defaultProps: Partial<Props> = {
    className: '',
    readOnly: false,
    isFileSharingAllowed: true,
    enableMentions: false,
    enableToolbar: false,
    showEmojiButton: false,
    // IE11 shows resize handles for non-native emojis
    // Edge 16 renders native Emoji on top of Emojione
    useNativeEmojis: platform.name === 'IE' || platform.name === 'Microsoft Edge',
    submitOnEnter: false,
    spellCheck: false
  };

  state = {
    showMentions: false,
    showEmojiPicker: false
  };

  private editor: any;

  focus = () => {
    // https://github.com/draft-js-plugins/draft-js-plugins/issues/357
    setTimeout(() => this.editor && this.editor.focus());
  };

  private handleImagePaste = (files: File[]) => {
    if (this.props.onPaste) {
      this.props.onPaste(files);
    }
  };

  private plugins: any = {
    linkify: createLinkifyPlugin({ component: 'span' }),
    emoji: createEmojiPlugin({
      useNativeArt: this.props.useNativeEmojis,
      buildEmojiUrl: this.props.buildEmojiUrl
    }),
    mentions:
      this.props.enableMentions &&
      createMentionPlugin({
        entityMutability: 'IMMUTABLE',
        mentionPrefix: '@',
        supportWhitespace: true,
        theme: {
          mentionSuggestions: 'mention-suggestions',
          mention: 'mention-text',
          mentionSuggestionsEntry: 'mention-suggestion',
          mentionSuggestionsEntryFocused: 'mention-suggestion mention-suggestion-focused'
        }
      }),
    toolbar:
      this.props.enableToolbar &&
      createInlineToolbarPlugin({
        theme: {
          toolbarStyles: {
            toolbar: 'inline-toolbar'
          },
          buttonStyles: {
            button: 'inline-toolbar-button',
            active: 'inline-toolbar-button-active',
            buttonWrapper: 'inline-toolbar-button-wrapper'
          }
        }
      }),
    pasteFiles: this.props.enablePasteFiles && createPasteFilesPlugin(this.handleImagePaste)
  };

  private mentionEntryWrapper = ({ mention, isFocused, theme: _1, searchValue: _2, ...rest }: any) => {
    const Comp = this.props.mentionEntryComponent;
    if (!Comp) {
      return null;
    }

    return <Comp {...rest} isFocused={isFocused} mention={immutableMentionToJs(mention)} />;
  };

  private handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // do not change focus when emoji picker is clicked
    let curElement = event.target as HTMLElement | null;
    while (curElement && !curElement.classList.contains('message-input')) {
      if (curElement.classList.contains('emoji-picker') || curElement.classList.contains('paste-input')) {
        return;
      }
      curElement = curElement.parentElement;
    }

    this.focus();
  };

  private handleSubmit = () => {
    this.props.onSubmit(this.props.state);
  };

  private handleSearchChange = ({ value }: { value: string | null }) => {
    if (this.props.onMentionSearchChange) {
      this.props.onMentionSearchChange(value);
    }
  };

  private handleMentionsOpen = () => {
    this.setState({
      showMentions: true
    });
    this.handleTypingNotification();
  };

  private handleAddMention = (mention: Mention) => {
    if (!this.props.onAddMention) {
      return;
    }

    const jsMention = immutableMentionToJs(mention);
    this.props.onAddMention(jsMention);
    this.handleTypingNotification();
  };

  private handleClickOutside = () => {
    this.setState({
      showEmojiPicker: false
    });
  };

  private handleTypingNotification = () => {
    return this.props.onTyping ? this.props.onTyping() : null;
  };

  private handleEmojiSelect = (event: any) => {
    const { shortname } = event;
    const newEditorstate = addEmojiEntityToState(this.props.state, shortname);
    this.props.onChange(newEditorstate);
    this.handleTypingNotification();

    this.setState({
      showEmojiPicker: false
    });
  };

  private toggleEmojiPicker = () => {
    this.setState((prev: InputState) => ({
      showEmojiPicker: !prev.showEmojiPicker
    }));
  };

  private handleMentionsClose = () => {
    this.setState({
      showMentions: false
    });
  };

  private handleReturn = (e: React.KeyboardEvent<{}>, state: EditorState): Draft.DraftHandleValue => {
    if (this.state.showMentions || e.shiftKey || !this.props.submitOnEnter) {
      return 'not-handled';
    }
    if (!this.isMaxLengthExceeded()) {
      this.props.onSubmit(state);
    }
    return 'handled';
  };

  private keyBindingFn = (e: React.KeyboardEvent<{}>) => {
    this.handleTypingNotification();
    const result = this.props.getKeyCommand && this.props.getKeyCommand(e);
    return result != null ? result : getDefaultKeyBinding(e);
  };

  private handleKeyCommand = (command: string, state: Draft.EditorState): Draft.DraftHandleValue => {
    this.handleTypingNotification();
    const result = this.props.handleKeyCommand && this.props.handleKeyCommand(command, state);
    return result ? 'handled' : 'not-handled';
  };

  private isMaxLengthExceeded = () => (this.props.excessCharacters != null ? this.props.excessCharacters > 0 : false);

  private getMaxLengthExceededTooltip = () => {
    if (this.props.excessCharacters != null && this.isMaxLengthExceeded()) {
      return {
        'data-tip': this.props.formatMessage('messageExceedsMaxLengthTooltip', {
          excessChars: this.props.excessCharacters
        }),
        'data-place': 'left'
      };
    }
    return {};
  };

  UNSAFE_componentWillMount() {
    if (config.env !== 'prod') {
      if (this.props.state.getDecorator()) {
        console.error(
          'Warning (react-message-input-field): Received a `state` during mount ' +
            'which already has a decorator attached to it. Try mounting with a fresh `state` from ' +
            '`createState`. For hydration, use `removeDecorator` before dehydrating the `state`.'
        );
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (config.env !== 'prod') {
      if (this.props.state.getDecorator() && !nextProps.state.getDecorator()) {
        console.error(
          'Warning (react-message-input-field): Received a new `state without a ' +
            'decorator. This likely means that you attempted to replace the state without ' +
            "preserving the decorator. See the component's README file for possible solutions."
        );
      }
    }
  }

  render() {
    const className = ['message-input', this.props.readOnly && 'read-only', this.props.className]
      .filter(Boolean)
      .join(' ');

    const emojiButtonClassName = ['btn', 'btn-icon', 'emoji-button', this.state.showEmojiPicker && 'active']
      .filter(Boolean)
      .join(' ');

    return (
      <div data-testid="message-input" className={className} onClick={this.handleClick}>
        {this.props.onFileUpload && (
          <UploadButton
            disabled={this.props.readOnly || this.props.isShowingPreview}
            isFileSharingAllowed={this.props.isFileSharingAllowed}
            onFileUpload={this.props.onFileUpload}
            formatMessage={this.props.formatMessage}
          />
        )}
        <div className="message-input-editor">
          <div className="paste-container">
            {this.props.enablePasteFiles && this.props.pasteElement}
            <Editor
              ref={(el: any) => (this.editor = el)}
              editorState={this.props.state}
              onChange={this.props.onChange}
              placeholder={this.props.placeholder}
              readOnly={this.props.readOnly}
              plugins={Object.keys(this.plugins)
                .map((key) => this.plugins[key])
                .filter(Boolean)}
              handleReturn={this.handleReturn}
              customStyleMap={this.props.customStyleMap}
              defaultBlockRenderMap={false}
              blockRenderMap={blockRenderMap}
              keyBindingFn={this.keyBindingFn}
              handleKeyCommand={this.handleKeyCommand}
              spellCheck={this.props.spellCheck}
              onEscape={this.props.onEscape}
              ariaLabel={this.props.placeholder}
            />
          </div>
        </div>
        <div className="button-container">
          {this.props.showEmojiButton && (
            <EmojiButtonWrapper
              className={emojiButtonClassName}
              disabled={this.props.readOnly}
              onClick={this.toggleEmojiPicker}
            >
              <img
                src={
                  this.props.buildEmojiUrl
                    ? this.props.buildEmojiUrl('1f642', '32')
                    : '//cdn.jsdelivr.net/emojione/assets/3.1/png/32/1f642.png'
                }
                alt=""
              />
            </EmojiButtonWrapper>
          )}
          {!this.props.noSubmitButton && (
            <span className="tooltip-container" {...this.getMaxLengthExceededTooltip()}>
              <SubmitButtonWrapper
                disabled={
                  this.props.readOnly ||
                  this.isMaxLengthExceeded() ||
                  (this.props.state
                    .getCurrentContent()
                    .getPlainText()
                    .trim() === '' &&
                    !this.props.pasteElement)
                }
                onClick={this.handleSubmit}
              >
                <img src={sendIcon} width="18" height="19" alt="" />
              </SubmitButtonWrapper>
            </span>
          )}
        </div>
        {this.props.enableMentions && (
          <this.plugins.mentions.MentionSuggestions
            onSearchChange={this.handleSearchChange}
            suggestions={this.props.mentionSuggestions}
            onAddMention={this.handleAddMention}
            onOpen={this.handleMentionsOpen}
            onClose={this.handleMentionsClose}
            entryComponent={this.props.mentionEntryComponent && this.mentionEntryWrapper}
          />
        )}
        {this.state.showEmojiPicker && (
          <div className="emoji-picker" data-testid="emoji-picker">
            <OnClickOutside handleClickOutside={this.handleClickOutside}>
              <EmojiPicker onChange={this.handleEmojiSelect} buildEmojiUrl={this.props.buildEmojiUrl} search />
            </OnClickOutside>
          </div>
        )}
        {this.props.enableToolbar && <this.plugins.toolbar.InlineToolbar />}
      </div>
    );
  }
}

interface EmojiButtonWrapperProps {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
}

const EmojiButtonWrapper: React.FC<EmojiButtonWrapperProps> = ({ className, disabled, onClick, children }) => {
  const { formatMessage: f } = useIntl();
  return (
    <button
      type="button"
      data-testid="emoji-button"
      className={className}
      disabled={disabled}
      onClick={onClick}
      aria-label={f(messages.emojiLabel)}
    >
      {children}
    </button>
  );
};

interface SubmitButtonWrapperProps {
  disabled?: boolean;
  onClick?: () => void;
}

const SubmitButtonWrapper: React.FC<SubmitButtonWrapperProps> = ({ disabled, onClick, children }) => {
  const { formatMessage: f } = useIntl();
  return (
    <button
      data-testid="message-submit-button"
      className="btn btn-icon submit-button"
      disabled={disabled}
      onClick={onClick}
      type="submit"
      aria-label={f(messages.submitLabel)}
    >
      {children}
    </button>
  );
};
