import * as React from 'react';
import classNames from 'classnames';
import './PageTemplate.css';

interface Props {
  className?: string;
}

export const Page: React.FC<Props> = (props) => {
  if (React.Children.count(props.children) > 0) {
    return <div className={classNames('pulse-page', props.className)}>{props.children}</div>;
  }
  return null;
};
