import { get } from 'lodash';
import { Reducer } from 'redux';
import { Error, ActionTypes as ErrorActionTypes, Actions as ErrorActions, category } from './errorActions';

export type State = Readonly<Error | {}>;

const errors: Reducer<State, ErrorActions> = (state = {}, action) => {
  switch (action.type) {
    case ErrorActionTypes.GROUP_CANT_JOIN:
    case ErrorActionTypes.GROUP_CANT_LEAVE:
    case ErrorActionTypes.XMPP_AUTH_ERROR:
    case ErrorActionTypes.NETWORK_ERROR:
    case ErrorActionTypes.MEETING_CONCURRENT_ERROR:
    case ErrorActionTypes.MEETING_ERROR_CREATING:
    case ErrorActionTypes.GROUP_MEMBERS_CANT_REMOVE:
    case ErrorActionTypes.SETTINGS_UPDATING_ERROR:
    case ErrorActionTypes.SETTINGS_FETCHING_ERROR:
    case ErrorActionTypes.DISCOVER_FETCHING_ERROR:
    case ErrorActionTypes.API_ERROR: {
      // Currently, the error system shows only one error
      // Higher priority error is shown and the lower one removed, if any.
      // Equal priority: last in wins
      const existing = get(state, 'priority', 0);
      if (existing > action.payload.priority) {
        return state;
      }
      return action.payload;
    }

    case ErrorActionTypes.CLEAR_ERROR:
      return {};

    // Categorizing errors enables the relevant error to be cleared from the corresponding action.
    // For example: We do not want to clear the meeting error when we only wanted to clear chat err.
    case ErrorActionTypes.CLEAR_NETWORK_ERROR: {
      const existing = get(state, 'category', '');
      if (existing === category.network) {
        return {};
      }
      return state;
    }

    case ErrorActionTypes.CLEAR_CHAT_ERROR: {
      const existing = get(state, 'category', '');
      if (existing === category.chat) {
        return {};
      }
      return state;
    }

    case ErrorActionTypes.CLEAR_MEETING_ERROR: {
      const existing = get(state, 'category', '');
      if (existing === category.meeting) {
        return {};
      }
      return state;
    }

    case ErrorActionTypes.CLEAR_SOCIAL_GRAPH_ERROR: {
      const existing = get(state, 'category', '');
      if (existing === category.socialGraph) {
        return {};
      }
      return state;
    }

    default:
      return state;
  }
};

export default errors;
