import { connect } from 'react-redux';
import { Selectors as CapsSelectors, ChatActions as chatActions } from '@getgo/caps-redux';
import { logout } from '../view-model/modules/core/user/userActions';
import { UserMenu } from '../components/Shell/UserMenu/UserMenu';
import { getIsOnline, getUserStatus } from '../view-model/selectors';
import { Dispatch, State } from '../view-model/types';
import { User } from '../../types/pulse-web';
import sharedActions from '../view-model/sharedActions';

const mapStateToProps = (state: State) => {
  const { features } = state.core.app;
  return {
    user: state.core.user as User,
    isOnline: getIsOnline(state),
    isConnected: CapsSelectors.getConnectionStatus(state).xmppConnected,
    userStatus: getUserStatus(state),
    features
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onLogout: () => {
      dispatch(logout());
    },
    changeUserStatus: (status: string) => {
      dispatch(chatActions.setAvailabilityState(status));
    },
    openLink(linkTargetType: string) {
      dispatch(sharedActions.webPageOpened(linkTargetType));
    }
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(UserMenu);

export default Container;
