import { Reducer } from 'redux';
import { compareByCompositeId, EngagementEntry } from '../../../../models/EngagementEngine';
import { Actions, ActionTypes } from './discoverActions';

export type DiscoverState = Readonly<{
  isFetching: boolean;
  entries: ReadonlyArray<EngagementEntry>;
  selectedEntry: EngagementEntry | null;
}>;

export const defaultState: DiscoverState = {
  isFetching: false,
  entries: [],
  selectedEntry: null
};

const reducer: Reducer<DiscoverState, Actions> = (state: DiscoverState = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.DISCOVER_FETCHING:
      return { ...state, isFetching: true };
    case ActionTypes.DISCOVER_FETCHED:
      return { ...state, isFetching: false, entries: [...action.payload.entries] };
    case ActionTypes.DISCOVER_FETCH_ERROR:
      return { ...state, isFetching: false };
    case ActionTypes.DISCOVER_ITEM_SELECTED:
      return {
        ...state,
        selectedEntry: action.payload.entry,
        entries: state.entries.map((entry) => {
          if (compareByCompositeId(entry, action.payload.entry)) {
            return { ...entry, engagementMessage: { ...entry.engagementMessage, contentRead: true } };
          }
          return entry;
        })
      };
    case ActionTypes.DISCOVER_ITEM_DISMISSED:
      return {
        ...state,
        entries: state.entries.filter((entry) => !compareByCompositeId(entry, action.payload.entry)),
        selectedEntry:
          state.selectedEntry && compareByCompositeId(state.selectedEntry, action.payload.entry)
            ? null
            : state.selectedEntry
      };
    default:
      return state;
  }
};

export default reducer;
