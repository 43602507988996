import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { isHoverSupported } from '../../../services/ClientService';
import { Block, Description, ErrorMessage, Note } from '../Toolkit/FormElements/TextElements';

interface Props {
  id: string; // for automation
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  children?: React.ReactNode;
  label?: string;
  labelMessage?: MessageDescriptor;
  labelMessageValues?: { [key: string]: string };
  errorMessage?: MessageDescriptor;
  descriptionMessage?: MessageDescriptor;
  description?: React.ReactNode;
  noteMessage?: MessageDescriptor;
  disabledTooltip?: MessageDescriptor;
  enabledTooltip?: MessageDescriptor;
  maxLength?: number;
  placeholder?: string;
}

export const PreferencesItemText: React.FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(evt.target.value);
  };

  const {
    id,
    label,
    labelMessage,
    labelMessageValues,
    descriptionMessage,
    description,
    noteMessage,
    value,
    disabled,
    disabledTooltip,
    errorMessage,
    maxLength,
    placeholder,
    children
  } = props;

  return (
    <Block>
      <label id={id + '_label'} aria-label={label}>
        <span
          data-tip={isHoverSupported && disabledTooltip ? f(disabledTooltip) : null}
          data-delay-show="500"
          data-place="right"
        >
          <input
            id={id}
            type="text"
            value={value}
            disabled={disabled}
            onChange={handleChange}
            maxLength={maxLength}
            placeholder={placeholder}
            data-testid={id}
          />
        </span>

        {labelMessage && <Description>{f(labelMessage, labelMessageValues)}</Description>}
        {errorMessage && <ErrorMessage data-testid={`${id}-error`}>{f(errorMessage)}</ErrorMessage>}
        {descriptionMessage && <Description>{f(descriptionMessage)}</Description>}
        {description && <Description>{description}</Description>}
        {noteMessage && <Note>{f(noteMessage)}</Note>}
      </label>
      <div>{children}</div>
    </Block>
  );
};
