import { Response, ResponseError } from 'superagent';
import { EntitlementError, UnauthorizedError } from './Errors';

export default (err: ResponseError & { response: Response }) => {
  switch (Number(err.status)) {
    case 401:
      return new UnauthorizedError('Token not valid (expired?)');
    case 403:
      return new EntitlementError('User is not entitled for GoToMeeting Hub');
    default:
      return err;
  }
};
