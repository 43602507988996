import React, { useState } from 'react';
import { SessionFromElasticSearch } from '../../../view-model/modules/meetings/meetingsReducer';
import styled from '../../../styled-components';
import { formatTime } from '../../../../lib/date';
import { useIntl } from 'react-intl';
import actions, {
  fetchSingleSession,
  selectMeeting,
  triggerTelemetryElasticSearch
} from '../../../view-model/modules/meetings/meetingsActions';
import { useDispatch } from 'react-redux';
import {
  evaluateMeetingYear,
  OpenRecordingContainer,
  OpenSearchRecordingResultButton
} from '../GlobalSearchComponents';
import { SearchListContent } from '../GlobalSearchDetailsView';
import { SelectableItemType } from '../../../view-model/modules/meetings/MeetingsTypes';
import { DotSeparator } from './TranscriptsTabRow';

const Content = styled.div`
  color: rgba(37, 40, 45, 0.6);
  font-size: 14px;
  margin-right: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  em {
    background: #fff7d9;
    font-style: normal;
  }
`;

const SessionDetails = styled.span`
  color: rgba(37, 40, 45, 0.6);
  font-size: 14px;
`;

const SessionTitle = styled(SessionDetails)`
  font-weight: 600;
  color: #0d0d0d;
  margin-right: 6px;
`;

const SessionEntry = styled.div`
  border: 1px solid #eeeeee;
  margin-bottom: 16px;
  position: relative;
  padding: 16px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    border: solid 1px rgba(76, 83, 93, 0.24);
  }
`;

const SessionTimeDetails = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

interface Props {
  handleRecording: (session: SessionFromElasticSearch) => void;
  disableOnClickOutside: () => void;
  loadMore: (attendeeName?: string) => void;
  selectedKey: string;
  session: any;
  selectedAttendee: string;
}

export const AttendeeSessions: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const openSearchResultView = () => {
    const id = props.session?.session?.id?.split('-')[1];
    props.disableOnClickOutside();
    if (id) {
      dispatch(triggerTelemetryElasticSearch('sessionPreviewFromGlobalSearch'));
      dispatch(actions.selectedGlobalSearchTab('attendees', props.selectedAttendee));
      dispatch(fetchSingleSession(id));
      dispatch(selectMeeting(SelectableItemType.ELASTICSEARCHRESULTSELECTED, ''));
    }
  };

  const [showOpenButton, setShowOpenButton] = useState(false);

  const handleMouseEnter = () => {
    setShowOpenButton(true);
  };

  const handleMouseLeave = () => {
    setShowOpenButton(false);
  };

  const { startTime, id, title } = props.session.session;
  const meetingStartTime = startTime ? formatTime(intl, startTime) : '';
  const meetingDate = evaluateMeetingYear(startTime);
  const highlightedContent =
    props.session.highlight &&
    props.session.highlight['contentDetails.content'] &&
    props.session.highlight['contentDetails.content'].length
      ? props.session.highlight['contentDetails.content'][0]
      : '';
  const content = highlightedContent
    ? highlightedContent
    : props.session && props.session.session?.contentDetails && props.session.session.contentDetails?.length
    ? props.session.session.contentDetails[0].content
    : '';
  return (
    <SessionEntry key={'session' + id} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <SearchListContent onClick={openSearchResultView}>
        <SessionTimeDetails>
          <SessionTitle>{title}</SessionTitle>
          <SessionDetails>{meetingDate}</SessionDetails>
          {meetingStartTime && <DotSeparator>.</DotSeparator>}
          <SessionDetails>{meetingStartTime}</SessionDetails>
        </SessionTimeDetails>
        <Content dangerouslySetInnerHTML={{ __html: `"${content}"` }} />
      </SearchListContent>
      {showOpenButton && (
        <OpenRecordingContainer>
          <OpenSearchRecordingResultButton onClick={() => props.handleRecording(props.session.session)} />
        </OpenRecordingContainer>
      )}
    </SessionEntry>
  );
};
