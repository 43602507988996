import React from 'react';
import { RoomRow } from './RoomRow';
import { State } from '../../../view-model/types';
import { UnreadCount } from '@getgo/caps-redux';

interface Props {
  rooms: State['messaging']['contacts']['items'];
  unreadPerRoom: UnreadCount;
  isOnline?: boolean;
  onRemove?: (jid: string, selected: boolean) => void;
  onSelect?: (jid: string) => void;
}

export const RoomList: React.FC<Props> = (props) => {
  const { isOnline = true, onRemove = () => {}, onSelect = () => {}, unreadPerRoom } = props;

  if (!props.rooms.length) {
    return null;
  }

  return (
    <ul className="rooms-list">
      {props.rooms.map((room) => {
        const unreadCount = unreadPerRoom[room.jid] || 0;

        return (
          <RoomRow
            {...props}
            isOnline={isOnline}
            onRemove={onRemove}
            onSelect={onSelect}
            key={room.jid}
            room={room}
            isSelected={room.selected}
            unreadCount={unreadCount}
            userStatus={room.status === 'online' ? room.availability : 'chat'}
          />
        );
      })}
    </ul>
  );
};
