import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import classNames from 'classnames';
import './Overlay.css';

const messages = defineMessages({
  uploadMessage: {
    id: 'common.upload.fileDropZone.overlay.uploadMessage',
    defaultMessage: 'Drop to share your file with {name}'
  },
  uploadDisabledMessage: {
    id: 'common.upload.fileDropZone.overlay.uploadDisabledMessage',
    defaultMessage: 'Uploading files is disabled. Contact your administrator for more information.'
  }
});

interface Props {
  isDragActive?: boolean;
  contactName?: string;
  children?: React.ReactElement<any> | null;
  className: string;
  isUploadEnabled?: boolean;
}

export class Overlay extends React.Component<Props> {
  render() {
    const { isDragActive = false, contactName = '', children = null, className, isUploadEnabled = true } = this.props;

    let overlay: React.ReactElement<any> | null = null;
    if (isDragActive) {
      overlay = (
        <div className={'overlay'}>
          <div className={'upload-message-box'}>
            <div className="file-icon">
              <i
                className={classNames('togo-icon', 'togo-icon-3x', {
                  'togo-icon-file-txt': isUploadEnabled,
                  'togo-icon-error': !isUploadEnabled
                })}
              />
            </div>
            <div className="upload-message">
              {isUploadEnabled ? (
                <FormattedMessage {...messages.uploadMessage} values={{ name: contactName }} />
              ) : (
                <FormattedMessage {...messages.uploadDisabledMessage} />
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={className}>
        {children}
        {overlay}
      </div>
    );
  }
}
