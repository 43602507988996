export enum PendoFunctions {
  initialize = 'initialize',
  identify = 'identify',
  updateOptions = 'updateOptions',
  pageLoad = 'pageLoad',
  track = 'track'
}

interface PendoFunctionCallRecord {
  _q: (readonly [string, readonly unknown[]])[];
}
export type Pendo = { [K in PendoFunctions]: (...args: readonly unknown[]) => void } & PendoFunctionCallRecord;
export type WindowPendo = Window & { pendo?: Pendo };
