import React from 'react';
import { CustomNavigationButton } from '../GlobalSearchComponents';
import { FormattedMessage } from 'react-intl';
import { GlobalSearchMessages as messages } from '../../GlobalSearch/GlobalSearchMessages';
import styled from 'styled-components';
import {
  fetchSingleSession,
  selectMeeting,
  triggerTelemetryElasticSearch
} from '../../../view-model/modules/meetings/meetingsActions';
import { AttendeeBucket, ElasticSearchResult } from '../../../view-model/modules/meetings/meetingsReducer';
import { useDispatch, useSelector } from 'react-redux';
import device from '../../Common/Toolkit/devices';
import { State } from '../../../view-model/types';
import { SelectableItemType } from '../../../view-model/modules/meetings/MeetingsTypes';

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f1f2f5;
  height: 60px;
  align-items: center;
  position: absolute;
  width: 100%;
  top: -70px;

  @media ${device.mobile} {
    position: relative;
    height: 36px;
    background: inherit;
    top: 0px;
    padding-bottom: 25px;
  }
`;

const NextPreviousButtons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin-left: 12px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;

interface Props {
  disableOnClickOutside: () => void;
}

export const SearchResultsNavigation: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const meetingsState = useSelector((state: State) => state.meetings);
  const elasticSearchResult = meetingsState.elasticSearchContentList;
  const session = meetingsState.elasticSearchSession;
  const tab = meetingsState.selectedGlobalSearchTab.tab;
  const selectedAttendeeName = meetingsState.selectedGlobalSearchTab.attendeeName;

  const getSelectedList = () => {
    let list: any = [];
    switch (tab) {
      case 'title':
        list = elasticSearchResult.titleList;
        break;
      case 'attendees':
        list = elasticSearchResult.attendeeList;
        break;
      case 'transcripts':
        list = elasticSearchResult.transcriptList;
        break;
      default:
        list = elasticSearchResult.transcriptList;
        break;
    }
    return list;
  };

  const findAttendeeSession = () => {
    return getSelectedList().find((attendee: AttendeeBucket) => attendee.key === selectedAttendeeName);
  };

  const selectedMeetingIndex = () => {
    if (tab === 'attendees') {
      return findAttendeeSession()?.sessions?.session.findIndex(
        (result: any) => result.session?.id === 'G2M-' + session?.sessionId
      );
    }
    return getSelectedList().findIndex(
      (result: ElasticSearchResult) => result.session?.id === 'G2M-' + session?.sessionId
    );
  };

  const nextMeetingIndex = selectedMeetingIndex() + 1;
  const previousMeetingIndex = selectedMeetingIndex() - 1;

  const backToSearchResults = () => {
    dispatch(triggerTelemetryElasticSearch('backToSearchResultsButtonClicked'));
    props.disableOnClickOutside();
    dispatch(selectMeeting(SelectableItemType.ELASTICSEARCH, ''));
  };

  const nextMeeting = () => {
    dispatch(triggerTelemetryElasticSearch('nextSessionButtonClicked'));
    handleNavigation(nextMeetingIndex);
  };

  const previousMeeting = () => {
    dispatch(triggerTelemetryElasticSearch('previousSessionButtonClicked'));
    handleNavigation(previousMeetingIndex);
  };

  const getSessionsList = () => {
    let session;
    if (tab === 'attendees') {
      session = findAttendeeSession()?.sessions?.session;
    } else {
      session = getSelectedList();
    }
    return session;
  };

  const handleNavigation = (index: number) => {
    const session = getSessionsList()[index];
    let id;
    if (session) {
      id = session.session?.id?.split('-')[1];
      dispatch(fetchSingleSession(id));
    }
  };

  return (
    <NavigationContainer>
      <CustomNavigationButton
        onClick={backToSearchResults}
        data-testid="back-to-search-results"
        startIcon={'togo-icon togo-icon-lg togo-icon-chevron togo-icon-rotate-90'}
      >
        <FormattedMessage {...messages.navigationBack} />
      </CustomNavigationButton>

      <NextPreviousButtons>
        <CustomNavigationButton
          disabled={!(previousMeetingIndex >= 0)}
          onClick={previousMeeting}
          data-testid="previous"
          startIcon={'togo-icon togo-icon-lg togo-icon-chevron togo-icon-rotate-90'}
        >
          <FormattedMessage {...messages.navigationPrevious} />
        </CustomNavigationButton>

        <CustomNavigationButton
          disabled={!(nextMeetingIndex > 0 && nextMeetingIndex < getSessionsList()?.length)}
          onClick={nextMeeting}
          data-testid="next"
          endIcon={'togo-icon togo-icon-lg togo-icon-chevron togo-icon-rotate-270'}
        >
          <FormattedMessage {...messages.navigationNext} />
        </CustomNavigationButton>
      </NextPreviousButtons>
    </NavigationContainer>
  );
};
