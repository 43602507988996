import { FilesMessage, MessagesEntity, File } from '@getgo/caps-redux';
import { isFilesMessage, isPreviewableFile } from './ChatHistoryFormatService';

export function getCurrentIndex(messages: MessagesEntity[], message: FilesMessage) {
  const idx = messages.findIndex((item: MessagesEntity) => {
    if (isFilesMessage(item)) {
      const [file]: File[] = item.files;
      if (isPreviewableFile(file)) {
        const [file1]: File[] = item.files;
        const [file2]: File[] = message.files;
        return file1 === file2;
      }
    }
    return false;
  });
  return idx;
}

export function getNextIndex(messages: MessagesEntity[], index: number, increment: number): number {
  let newIndex = index;
  for (;;) {
    newIndex = (newIndex + increment) % messages.length;
    if (newIndex < 0) {
      newIndex = messages.length - 1;
    }
    if (newIndex === index) {
      return newIndex;
    }
    const filesMessage = messages[newIndex];
    if (isFilesMessage(filesMessage)) {
      const [file]: File[] = filesMessage.files;
      if (isPreviewableFile(file)) {
        return newIndex;
      }
    }
  }
}
