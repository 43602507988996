import React from 'react';
import { HashRouter, Switch, Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { getRouteFor } from './services/NavService';
import { NotFoundErrorPage, AppErrorPage } from './components/Common/ErrorPages/ErrorPages';
import { State } from './view-model/types';
import { useSelector } from 'react-redux';
import { Shell } from './components/Shell/Shell';
import { User } from './view-model/modules/core/user/userReducer';
import { Tooling } from './components/Routing/Tooling';
import { NotFoundRedirect } from './components/Routing/NotFoundRedirect';

const upcomingMeetingsRedirect = <Redirect to={getRouteFor('upcomingMeetings')} />;
const anytimeMeetingsRedirect = <Redirect to={getRouteFor('anytimeMeetings')} />;

export const RootRouter: React.FC = () => {
  const user = useSelector((state: State) => state.core.user as User);
  const defaultPage = user.meetNow ? 'meetings' : 'chat';

  return (
    <HashRouter hashType="slash">
      <Route
        render={({ location }: RouteComponentProps) =>
          // @ts-ignore-next-line
          location?.state?.is404 ? (
            <NotFoundErrorPage />
          ) : (
            <Tooling>
              <Switch>
                <Route exact path="/" render={() => <Redirect to={getRouteFor(defaultPage)} />} />
                {/* Supporting outdated MFE routes TODO: replace after MFE decommission */}
                <Route exact path="/setup" render={() => upcomingMeetingsRedirect} />
                <Route exact path="/labs" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/reserve" render={() => upcomingMeetingsRedirect} />
                <Route exact path="/beta" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/betas" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/trialEnding" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/meetings*details" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/sessionInProgress" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/inviteUsers" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/number-change" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/numbers/primary/:meetingId" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/numbers/alternate/:meetingId" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/codec" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/internalServerError" component={AppErrorPage} />
                <Route exact path="/pageNotFound" render={() => anytimeMeetingsRedirect} />
                <Route exact path="/unsupportedBrowser" render={() => anytimeMeetingsRedirect} />\
                <Route exact path="/meetings/upcoming" render={() => upcomingMeetingsRedirect} />
                <Route exact path="/meetings/past" render={() => <Redirect to={getRouteFor('historyMeetings')} />} />
                {/* END Supporting old outdated MFE routes TODO: replace after MFE decommission */}
                <Route path={getRouteFor('meetings')} component={Shell} />
                <Route exact path="/schedule" component={Shell} />
                <Route exact path={getRouteFor('discover')} component={Shell} />
                <Route path={getRouteFor('chat')} component={Shell} />
                <Route path={getRouteFor('settings')} component={Shell} />
                <Route component={NotFoundRedirect} />
              </Switch>
            </Tooling>
          )
        }
      />
    </HashRouter>
  );
};
