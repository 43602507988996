import uuid from 'uuid';
import { GroupActions, MessageActions, MessagesEntity, Selectors } from '@getgo/caps-redux';
import { selectContact } from '../capsActions';
import { ActionTypes as AppActionTypes } from '../modules/core/app/appActions';
import actions, { ActionTypes } from '../modules/core/notifications/notificationsActions';
import loggerService from '../../services/LoggerService';
import { NotificationService, SwPayload } from '../../services/NotificationService';
import { PushNotificationService } from '../../services/PushNotificationService';
import { formatToNotification } from '../../services/MessageFormatService';
import { User } from '../modules/core/user/userReducer';
import { Middleware, MiddlewareAPI, State } from '../types';
import { getActiveSetting } from '../modules/core/preferences/preferencesReducer';
import { isChatEnabled } from '../selectors';

const logger = loggerService.create('NotificationsMiddleware');

const service = new NotificationService();

const getDnd = (state: State) => getActiveSetting(state, 'notifications', 'doNotDisturb');
const isRead = (msg: MessagesEntity) => msg.read;
const isGroup = (msg: MessagesEntity) => msg.type === 'groupchat';
const isMine = (msg: MessagesEntity) => msg.cc === msg.from;
const isGroupChatButNotMentioningMe = (user: User, msg: MessagesEntity) => {
  if (isGroup(msg) && msg.references) {
    return msg.references.every((reference) => {
      if (reference.type && reference.type === 'mention' && reference.uri && reference.uri.endsWith(user.jid)) {
        return false;
      }
      return true;
    });
  }
  return isGroup(msg);
};

const createSwPayload = (msg: MessagesEntity, state: State) => {
  if (isGroup(msg)) {
    const group = Selectors.findGroup(state, msg.roomId);
    return {
      type: 'mention',
      senderJid: msg.from,
      senderName: msg.fromName || msg.from,
      message: msg.text,
      messageId: msg.msgId,
      groupJid: msg.roomId,
      groupName: group?.name || 'Group'
    };
  }

  return {
    type: 'unread',
    senderJid: msg.from,
    senderName: msg.fromName || msg.from,
    message: msg.text,
    messageId: msg.msgId
  };
};

const handleUnreadNotification = (store: MiddlewareAPI, action: any) => {
  const state = store.getState();
  const { user } = state.core;

  if (!user.hasLoaded) {
    return;
  }

  if (getDnd(state)) {
    return;
  }

  const msg = action.payload.message;
  if (!msg || isRead(msg) || isMine(msg) || isGroupChatButNotMentioningMe(user, msg)) {
    return;
  }

  const body = formatToNotification(msg.text);

  const swPayload = createSwPayload(msg, state);

  service
    .notify(
      swPayload as SwPayload,
      msg.fromName || msg.from,
      {
        body,
        icon: msg.image,
        id: msg.id,
        roomId: msg.roomId,
        tag: 'unread'
      },
      () => {
        store.dispatch(selectContact(msg.roomId));
      }
    )
    .catch((err) => {
      logger.error('notify.unread', 'error=', err);
    });
};

const handleGroupInvite = (store: MiddlewareAPI, action: any) => {
  const group = action.payload || {};
  if (!group.fromInvitation) {
    return;
  }

  service
    .notify(
      null,
      group.name,
      {
        body: `You were invited to join the group chat "${group.name}"`,
        id: uuid.v4(),
        tag: 'groupInvite'
      },
      () => {
        store.dispatch(selectContact(group.jid));
      }
    )
    .catch((err) => {
      logger.error('notify.groupInvite', 'error=', err);
    });
};

const notificationsMiddleware: Middleware = (store) => (next) => {
  let pushService: PushNotificationService | null = null;

  return (action) => {
    const state = store.getState();
    const { core } = state;
    const { notifications } = core;
    const registration = notifications.pushRegistration;

    switch (action.type) {
      // push notifications are only sent by backend when offline, so it should be safe to always use
      // old background notifications when app is running, without causing duplicates
      case GroupActions.groupJoined.toString():
        handleGroupInvite(store, action);
        break;

      case MessageActions.messageReceived.toString():
        handleUnreadNotification(store, action);
        break;

      case MessageActions.chatRoomMessagesRead.toString():
        service.clear();
        break;

      case ActionTypes.ACCEPT_DOUBLE_BANNER:
        service.requestPermission().then((permission) => {
          store.dispatch(actions.notificationPermissionUpdated(permission));
        });
        break;
    }

    const result = next(action);
    const nextState = store.getState();

    if (pushService) {
      // sync with DnD setting
      if (getDnd(state) !== getDnd(nextState)) {
        pushService.setEnabled(!getDnd(nextState));
      }

      // trigger change when going online in case subscribing failed while offline
      if (!state.core.app.connectionStatus.networkOnline && nextState.core.app.connectionStatus.networkOnline) {
        pushService.setEnabled(!getDnd(nextState));
      }

      // drop subscription when user changed
      const userKey = state.core.user.hasLoaded && state.core.user.key;
      if (registration && userKey != null && userKey !== registration.userKey) {
        pushService.setEnabled(false);
      }
    }

    switch (action.type) {
      case AppActionTypes.APP_LOAD: {
        // initialize push service
        const supportsWebPush = PushNotificationService.isSupported();
        const nextRegistration = nextState.core.notifications.pushRegistration;
        const hasChat = isChatEnabled(nextState);

        if (supportsWebPush && hasChat) {
          pushService = new PushNotificationService({
            registration: nextRegistration,
            onRegistrationChange: (reg) => {
              if (reg) {
                const user = store.getState().core.user;
                if (user.hasLoaded) {
                  const userKey = user.key;
                  store.dispatch(actions.pushRegistrationUpdated(reg.referenceId, reg.endpoint, userKey));
                }
              } else {
                store.dispatch(actions.pushRegistrationRemoved());
              }
            },
            onError: (error) => {
              logger.error('pushService', 'err=', error);
            }
          });

          pushService.setEnabled(!getDnd(nextState));
        } else {
          // clean up registration if push support has gone somehow or chat feature was disabled
          if (supportsWebPush && !hasChat) {
            PushNotificationService.unsubscribe(nextRegistration);
          }

          if (nextRegistration) {
            store.dispatch(actions.pushRegistrationRemoved());
          }
        }

        // initialize notification permission state
        if (service.isSupported()) {
          store.dispatch(actions.notificationPermissionUpdated(service.getPermission()));
        }

        break;
      }
    }

    return result;
  };
};

export default notificationsMiddleware;
