import * as React from 'react';
import './SpinnerInline.css';

interface Props {
  addClassName?: string;
}

export const SpinnerInline: React.FC<Props> = (props) => {
  let dotClass = 'dots';
  if (props.addClassName) {
    dotClass += ` ${props.addClassName}`;
  }
  return (
    <span className="spinner-inline" data-testid="spinner-inline">
      <span className={dotClass} />
    </span>
  );
};
