import machine from './machine';
import platform from 'platform';
import { browser, clientType } from '../app/services/ClientService';
import config from 'config';
import { User } from '../app/view-model/modules/core/user/userReducer';

const productName = 'GoToMeeting Hub';

export interface FeatureUseData {
  distinct_id: string;
  hw_model_id: string;
  hw_model_name: string;
  sw_plat: string;
  sw_prod_name: string;
  sw_build: string;
  sw_prod_ver: string;
  ga_userKey: string;
  sa_clientType: string;
  sa_accountKey: string;
  sa_productTier: string;
  sa_licenseType: string;
  sa_userType: string;
  sw_env: string;
  sa_accountLocale: string;
}

export interface OsUserData {
  os_type: string;
  os_ver: string;
  os_arch: string;
  os_locale: string;
}

export const buildFeatureUseData = (user: Partial<User> = {}): FeatureUseData => {
  return {
    distinct_id: machine.id, // for mixpanel
    hw_model_id: 'unavailable',
    hw_model_name: platform.product || 'unavailable',
    sw_plat: browser || 'unavailable',
    sw_prod_name: productName,
    // eslint-disable-next-line no-undef
    sw_build: BUILD || 'unavailable', // git partial hash
    // eslint-disable-next-line no-undef
    sw_prod_ver: VERSION || 'unavailable', // hub version
    ga_userKey: user.key || 'unavailable',
    sa_clientType: clientType,
    sa_accountKey: user.accountKey || 'unavailable',
    sa_productTier: user.tier != null ? `${user.tier}` : 'unavailable',
    sa_licenseType: user.licenseType || 'unavailable',
    sa_userType: user.entitlements && user.entitlements.includes('acctadmin') ? 'admin' : 'non-admin',
    sw_env: config.env,
    sa_accountLocale: user.locale || 'unavailable'
  };
};

export const buildOsUserData = (): OsUserData => {
  return {
    os_type: platform.os?.family || 'unavailable',
    os_ver: platform.os?.version || 'unavailable',
    os_arch: String(platform.os?.architecture || 'unavailable'),
    os_locale: navigator.language
  };
};
