import ExtendableError from 'es6-error';
import React from 'react';
import { WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { EntitlementError } from '../../models/Errors';

const messages = defineMessages({
  defaultActionText: {
    id: 'error.defaultActionText',
    defaultMessage: 'Retry'
  },
  entitlementActionText: {
    id: 'error.entitlementActionText',
    defaultMessage: 'Sign in again'
  },
  entitlementMessage: {
    id: 'error.entitlementMessage',
    defaultMessage: 'Business Messaging is not enabled. Please contact your administrator for help.'
  }
});

interface Props {
  action?: () => void;
  error?: ExtendableError;
}

class Error extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    if (!this.props.error) {
      return null;
    }

    const { formatMessage } = this.props.intl;
    let actionText = formatMessage(messages.defaultActionText);
    let message = this.props.error.message;

    if (this.props.error instanceof EntitlementError) {
      actionText = formatMessage(messages.entitlementActionText);
      message = formatMessage(messages.entitlementMessage);
    }

    return (
      <div className="error">
        <div className="content">
          <p className="message">{message}</p>
          {this.props.action && typeof this.props.action === 'function' && (
            <button className="btn btn-primary" onClick={this.props.action}>
              {actionText}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(Error, { forwardRef: true });
