import * as React from 'react';
import classNames from 'classnames';
import './PageTemplate.css';

export const Actions = ({ children, className, ...rest }: { children?: React.ReactNode; className?: string }) => {
  if (React.Children.count(children) > 0) {
    return (
      <div className={classNames('pulse-actions', className)} {...rest}>
        {children}
      </div>
    );
  }
  return null;
};
