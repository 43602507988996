import {
  Selectors,
  CapabilitiesActions as capabilitiesActions,
  ChatActions as chatActions,
  GroupActions as groupActions,
  RosterActions as rosterActions
} from '@getgo/caps-redux';
// @ts-ignore
import ServiceLocator from '@getgo/caps-redux/lib/IoC/Container';
import { Logger } from '@getgo/logger';
import config from 'config';
import capabilities from '../../config/capabilities';
import { User } from '../../types/pulse-web';
import { restoreSelectedContact, selectContact } from '../view-model/capsActions';
import errorActions from '../view-model/modules/core/errors/errorActions';
import capabilityMessageHandler from '../view-model/message-handlers/iq/CapabilityMessageHandler';
import capabilityPresenceMessageHandler from '../view-model/message-handlers/presence/CapabilityPresenceMessageHandler';
import groupRevokeMessageHandler from '../view-model/message-handlers/presence/GroupRevokedMessageHandler';
import { queryPeople } from '../view-model/modules/messaging/people/peopleActions';
import { getIsChatVisible, getIsOnline, getIsPageVisible, getUser } from '../view-model/selectors';
import { Dispatch, Store } from '../view-model/types';
import { generateSessionId } from './IdHelper';
import logger from './LoggerService';

ServiceLocator.register('getUser', getUser);
ServiceLocator.register('getIsOnline', getIsOnline);
ServiceLocator.register('getIsChatVisible', getIsChatVisible);
ServiceLocator.register('getIsPageVisible', getIsPageVisible);
ServiceLocator.register('maxReplacements', config.messaging.maxReplacements);
ServiceLocator.register('maxReplacementInterval', config.messaging.maxReplacementInterval);
ServiceLocator.register('logger', logger.create('caps-redux'));
ServiceLocator.register('mode', config.env === 'development' ? 'debug' : 'production');

// register extra message handlers with the chat actions
chatActions.registerHandler('iq', capabilityMessageHandler);
chatActions.registerHandler('presence', capabilityPresenceMessageHandler);
chatActions.registerHandler('presence', groupRevokeMessageHandler);

export class ChatService {
  private store: Store;
  private logger: Logger;

  constructor(store: Store) {
    this.store = store;
    this.logger = logger.create('ChatService');
  }

  start(
    store = this.store,
    user: User,
    roomId: string,
    onReconnectionFailed: (dispatch: Dispatch, errorCode: Error) => void
  ) {
    this.store = store;

    this.store.dispatch(capabilitiesActions.setPotentialCaps(capabilities));
    return Promise.all([this.initChat(user, roomId, onReconnectionFailed), this.initSocialGraph()]).then(() =>
      this.syncXmppStatus(store)
    );
  }

  stop() {
    return chatActions.disconnect();
  }

  // This is a workaround for GTMTWO-10002(race condition), since we are not able to reproduce.
  // Hopefully this can be removed as fast as possible.
  syncXmppStatus(store: Store) {
    setInterval(() => {
      const stropheConnectionStatus = ServiceLocator.instance('connection').connection.connected;
      const xmppConnectedFromState = store.getState().messaging.chat.connectionStatus.xmppConnected;
      if (stropheConnectionStatus && xmppConnectedFromState !== stropheConnectionStatus) {
        this.store.dispatch(chatActions.chatConnectedChange(stropheConnectionStatus));
        this.logger.info(
          'Syncing Strophe and CAPS-REDUX XMPP connection statuses, because they are different.',
          'stropheConnectionStatus=',
          stropheConnectionStatus,
          'xmppConnectedFromState=',
          xmppConnectedFromState
        );
      }
    }, 5000);
  }

  changeAppOnlineState(online: boolean) {
    if (online) {
      this.store.dispatch(chatActions.reconnect());
      this.store.dispatch(errorActions.clearChatError());
    }
  }

  initChat(user: User, roomId: string, onReconnectionFailed: (dispatch: Dispatch, errorCode: Error) => void) {
    const { dispatch, getState } = this.store;
    const options = {
      sessionId: generateSessionId(),
      keepAlive: true,
      xmpp: { ...config.xmpp, restUrl: config.rest.ejabberdUrl, disableFeatures: ['stream-management'] },
      onReconnectionFailed: (errorCode: Error) => onReconnectionFailed(dispatch, errorCode)
    };

    return Promise.all([
      dispatch(errorActions.clearNetworkError()),
      dispatch(errorActions.clearChatError()),
      dispatch(chatActions.connect(user, options))
        .then(() => dispatch(capabilitiesActions.broadcastCapabilities()))
        .then(() => Promise.all([dispatch(rosterActions.loadRoster()), dispatch(groupActions.loadGroups())]))
        .then(() => {
          const { presence } = Selectors.getChat(this.store.getState());
          dispatch(groupActions.enterAllGroups((presence || {}).availability));
        })
        .then(() => {
          if (!getState().core.app.isChatVisible) {
            return null;
          }

          if (roomId) {
            return dispatch(selectContact(roomId));
          }

          return dispatch(restoreSelectedContact());
        })
        .catch((err: Error) => {
          const ErrorTypes: { [index: string]: any } = {
            UnauthorizedError: errorActions.xmppAuthError,
            generic: errorActions.initChatError
          };

          const action = err.name in ErrorTypes ? ErrorTypes[err.name.toString()] : ErrorTypes.generic;
          this.logger.error('initChat.connect', 'error=', err, 'action=', action);

          return dispatch(
            action(err, () => {
              this.initChat(user, roomId, onReconnectionFailed);
            })
          );
        })
    ]);
  }

  initSocialGraph() {
    return this.store.dispatch(queryPeople(undefined, undefined, undefined, { preventUiError: true }));
  }
}
