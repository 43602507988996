import * as React from 'react';
import styled from '../../../styled-components';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary';
import { CloseButton } from '../Toolkit/Button/CloseButton';

const ModalDivOuter = styled.div`
  border-top: 6px solid #f68d2e;
  color: #25282d;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  width: 600px;
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 48px);
  overflow-y: auto;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const ModalDivInner = styled.div`
  padding: 30px 30px 30px 30px;
  position: relative;
`;

const Title = styled.h2`
  font-size: 18px;
  font-family: 'lato-bold';
  font-weight: 600;
  margin: 0;
  padding-bottom: 24px;
`;

const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  right: 40px;
  top: 28px;
  height: 30px;
`;

interface Props {
  title?: React.ReactNode | string;
  onClose?: () => void;
  showClose?: boolean;
  children?: React.ReactNode;
  testId?: string;
  alert?: React.ReactNode;
}

export const ModalDialogContentWrapper: React.FC<Props> = ({
  title = '',
  onClose = () => {},
  children,
  showClose = true,
  testId,
  alert
}) => {
  return (
    <ModalDivOuter data-testid={testId}>
      {alert}
      <ModalDivInner>
        {showClose && <PositionedCloseButton onClick={onClose} size={30} dataTestId="close-button" />}
        <ErrorBoundary>
          <Title>{title}</Title>
          {children}
        </ErrorBoundary>
      </ModalDivInner>
    </ModalDivOuter>
  );
};
