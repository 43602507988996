import React from 'react';
import { useIntl } from 'react-intl';
import { getFormattedDay } from '../../../../../lib/date';
import './DaySeparator.css';

interface Props {
  date: Date | string;
  today: Date | string;
}

export const DaySeparator: React.FC<Props> = (props) => {
  const intl = useIntl();
  let date = props.date;
  if (typeof props.date === 'string') {
    date = new Date(props.date);
  }
  let today = props.today;
  if (typeof props.today === 'string') {
    today = new Date(props.today);
  }

  return (
    <li className="day-separator">
      <span>{getFormattedDay(intl, date, today)}</span>
    </li>
  );
};
