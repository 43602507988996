import { FormattedMessage, defineMessages } from 'react-intl';
import React from 'react';
import { ErrorPage } from './ErrorPage';
import { redirectTo } from '../../../services/NavService';
import { PrimaryButton } from '../Toolkit/Button/PrimaryButton';
import styled from '../../../styled-components';
import config from 'config';

export const errorMessages = defineMessages({
  errorTitle: {
    id: 'errorPages.errorTitle',
    defaultMessage: 'We couldn’t find that page.'
  },
  notFoundErrorDescription: {
    id: 'errorPages.notFoundErrorDescription',
    defaultMessage: 'Sorry, but that page either moved or doesn’t exist anymore.'
  },
  notFoundButtonLabel: {
    id: 'errorPages.notFoundButtonLabel',
    defaultMessage: 'Go Back'
  },
  electronDecomErrorTitle: {
    id: 'errorPages.electronDecom.title',
    defaultMessage: 'GoToMeeting v9 Has Retired'
  },
  electronDecomErrorDescription: {
    id: 'errorPages.electronDecom.description',
    defaultMessage:
      'You can access your info on the GoToMeeting Hub, the new and improved browser-based replacement for v9.'
  },
  discoverHubButtonLabel: {
    id: 'errorPages.electronDecom.discoverHubButtonLabel',
    defaultMessage: 'Discover GoToMeeting Hub'
  },
  uninstallv9Label: {
    id: 'errorPages.electronDecom.uninstallv9Label',
    defaultMessage: 'Learn how to {link}.'
  },
  uninstallv9Link: {
    id: 'errorPages.electronDecom.uninstallv9Link',
    defaultMessage: 'uninstall GoToMeeting v9'
  },
  renderErrorTitle: {
    id: 'errorPages.renderError.title',
    defaultMessage: "Hmm, something didn't work right."
  },
  renderErrorDescription: {
    id: 'errorPages.renderError.description',
    defaultMessage: "Let's give it another try. If that doesn't help, please contact our {link}."
  },
  renderErrorDescriptionLink: {
    id: 'errorPages.renderError.descriptionLink',
    defaultMessage: 'support team'
  },
  tryAgainButtonLabel: {
    id: 'errorPages.renderError.tryAgainButtonLabel',
    defaultMessage: 'Try Again'
  }
});

export const NotFoundErrorPage = () => (
  <ErrorPage
    title={<FormattedMessage {...errorMessages.errorTitle} />}
    description={<FormattedMessage {...errorMessages.notFoundErrorDescription} values={{ lineBreak: <br /> }} />}
    errorCode="NOT_FOUND"
    button={{
      onClick: () => {
        redirectTo('Shell');
      },
      label: <FormattedMessage {...errorMessages.notFoundButtonLabel} />
    }}
  />
);

export const AppErrorPage = (props: { error: Error }) => {
  const supportPageUrl = 'https://support.goto.com/meeting';
  return (
    <ErrorPage
      title={<FormattedMessage {...errorMessages.renderErrorTitle} />}
      description={
        <FormattedMessage
          {...errorMessages.renderErrorDescription}
          values={{
            link: (
              <Link href={supportPageUrl} target="_blank" rel="noopener noreferer">
                <FormattedMessage {...errorMessages.renderErrorDescriptionLink} />
              </Link>
            ),
            lineBreak: <br />
          }}
        />
      }
      errorCode=""
      button={{
        onClick: () => {
          window.location.reload();
        },
        label: <FormattedMessage {...errorMessages.tryAgainButtonLabel} />
      }}
      footer={props.error && <span>{props.error.toString()}</span>}
    />
  );
};

const DiscoverHubButton = styled((props) => {
  const handleClick = () => window.open(config.externalLinks.globalUrl, '_blank');
  return (
    <PrimaryButton className={props.className} textTransform={'capitalize'} onClick={handleClick}>
      <FormattedMessage {...errorMessages.discoverHubButtonLabel} />
    </PrimaryButton>
  );
})`
  margin-top: 16px;
`;

const Link = styled.a`
  font-size: 16px;
  cursor: pointer;
  color: #309ddc;
  :hover {
    color: #1c72a4;
    text-decoration: underline;
  }
`;

export const ElectronDecomErrorPage = () => {
  const title = <FormattedMessage {...errorMessages.electronDecomErrorTitle} />;
  const description = (
    <>
      <p>
        <FormattedMessage {...errorMessages.electronDecomErrorDescription} />
      </p>
      <DiscoverHubButton />
    </>
  );
  const footer = (
    <FormattedMessage
      {...errorMessages.uninstallv9Label}
      values={{
        link: (
          <Link href={config.externalLinks.v9uninstallSupportPageUrl} target="_blank" rel="noopener noreferer">
            <FormattedMessage {...errorMessages.uninstallv9Link} />
          </Link>
        )
      }}
    />
  );
  return <ErrorPage title={title} description={description} footer={footer} />;
};
