import { IntlShape, defineMessages } from 'react-intl';

export const messages = defineMessages({
  externalMembers: {
    id: 'service.TooltipHelper.externalMembers',
    defaultMessage: 'Contains external members'
  },
  typePrivate: {
    id: 'service.TooltipHelper.typePrivate',
    defaultMessage: 'Private'
  },
  typePublic: {
    id: 'service.TooltipHelper.typePublic',
    defaultMessage: 'Public'
  }
});

const getGroupTypeString = (formatMessage: IntlShape['formatMessage'], isPublic: boolean) => {
  return isPublic ? formatMessage(messages.typePublic) : formatMessage(messages.typePrivate);
};

export function getAvatarTooltipText(
  formatMessage: IntlShape['formatMessage'],
  isPublic: boolean,
  hasExternal: boolean
) {
  return hasExternal
    ? `${getGroupTypeString(formatMessage, isPublic)} / ${formatMessage(messages.externalMembers)}`
    : getGroupTypeString(formatMessage, isPublic);
}
