import { Contact } from '@getgo/caps-redux';
import { connect } from 'react-redux';
import GoToMeeting from '../components/Chat/GoToMeeting/GoToMeeting';
import { meetNow } from '../view-model/modules/messaging/messagingActions';
import { Dispatch, State } from '../view-model/types';

interface OwnProps {
  contact: Contact;
  buttonType: string;
  isOnline?: boolean;
}

const mapStateToProps = (state: State) => {
  return {
    app: state.core.app,
    meeting: {
      enabled: true
    }
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onMeetNow: (contact: Contact) => {
      const action = meetNow(contact);
      dispatch(action);
    }
  };
};

const GoToMeetingContainer = connect<
  ReturnType<typeof mapStateToProps>,
  ReturnType<typeof mapDispatchToProps>,
  OwnProps,
  State
>(
  mapStateToProps,
  mapDispatchToProps
)(GoToMeeting);

export default GoToMeetingContainer;
