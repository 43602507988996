import { Reducer } from 'redux';
import Events from '../../../EventConstants';
import { ActionTypes, Actions } from './preferencesActions';
import { State as RootState } from '../../../types';
import { ActionTypes as SharedActionTypes, Actions as SharedActions } from '../../../sharedActions';
import { ActionTypes as AppActionTypes, Actions as AppActions } from '../../../modules/core/app/appActions';
import { getPreferences } from '../../../selectors';
import { parseDataUrl } from '../../../../../lib/data-urls';
import { AudioSettingItems, AudioValidationErrors, getAudioValidationErrors } from '../../../../../lib/audio';

const version = 'v2';

export interface Client {
  name: string;
  url: string;
  version: string;
}

/* Meeting details for the array keys (large letters)
https://confluence.ops.expertcity.com/display/collaboration/Meeting+Service+REST+API+2.0#MeetingServiceRESTAPI2.0-GetOrganizerSettingsGetOrganizerSettings
  voip - VOIP
  longDistance - TOLL
  tollFree - TOLLFREE
  callMe - DIALOUT
  ownConference - PRIVATE / text is defaultModeratorPrivateMessage
  Countries are stored with their short names in capital letters DE, US etc */
export interface MeetingSettings extends AudioSettingItems {
  webViewerAllowed: boolean; // read-only
  webViewerDefault: boolean;
  presenterMode: 'open' | 'moderated';
  logo:
    | null
    | undefined
    | {
        dataUrl: string;
        allowDeletion: boolean; // initial logo set by admin cannot be deleted
      };
  cbrProvisioned: boolean;
  cbrEntitled: boolean;
  cbrEnabled: boolean;
  transcriptsProvisioned: boolean;
  transcriptsEntitled: boolean;
  transcriptsEnabled: boolean;
  shareRecordingContentEnabled: boolean;
  videoInsightsEntitled: boolean;
  videoInsightsEnabled: boolean;
  videoInsightsProvisioned: boolean;
  conferenceModeratorPin: string;
  isStandaloneAudioProvisioned: boolean;
  isStandaloneAudioEntitled: boolean;
  isStandaloneAudioEnabled: boolean;
  isStandaloneAudioPinEnforced: boolean;
  isCoorganizerFeatureEnabled: boolean;
  isOpenMeetingsProvisioned: boolean;
  isOpenMeetingsEntitled: boolean;
  isOpenMeetingsDefault: boolean;
  isBrandingProvisioned: boolean;
  isBrandingEntitled: boolean;
  chromaCamEnabled: boolean;
  chromaCamProvisioned: boolean;
  shareRecordingLinkExpirationInDays: number;
}

export interface UX2019Settings {
  ux2019ControllableByUser: boolean;
  ux2019Enabled: boolean;
  meetingHubEnabled: boolean;
  meetingHubControllableByUser: boolean;
}

export interface GoToAppEapSettings {
  gotoAppProvisioned: boolean;
  gotoAppEntitled: boolean;
  gotoAppEnabled: boolean;
}

export type State = Readonly<{
  [version]: boolean;

  notifications: SettingGroup<{
    doNotDisturb: boolean;
  }>;

  theme: AsyncSettingGroup<{
    useHighContrast: boolean;
  }>;

  meetings: AsyncSettingGroup<MeetingSettings>;
  ux2019Settings: AsyncSettingGroup<UX2019Settings>;
  gotoAppEapSettings: AsyncSettingGroup<GoToAppEapSettings>;
}>;

const createSettingsGroup = <T extends object>(initial: T): SettingGroup<T> => ({
  current: initial,
  update: initial
});

const createAsyncSettingsGroup = <T extends object>(initial: T): AsyncSettingGroup<T> => ({
  current: initial,
  update: initial,
  isFetching: false,
  hasFetchingError: false,
  isUpdating: false,
  hasUpdatingError: false
});

export const defaultState: State = {
  [version]: true,

  notifications: createSettingsGroup({
    doNotDisturb: false
  }),
  theme: createAsyncSettingsGroup({
    useHighContrast: false
  }),
  meetings: createAsyncSettingsGroup({
    webViewerAllowed: true,
    webViewerDefault: false,
    presenterMode: 'moderated' as 'moderated',
    voipEnabled: true,
    voipAllowed: true,
    longDistanceEnabled: false,
    longDistanceAllowed: false,
    longDistanceCountries: [],
    allowedLongDistanceCountries: [],
    tollFreeEnabled: false,
    tollFreeAllowed: false,
    tollFreeCountries: [],
    allowedTollFreeCountries: [],
    callMeEnabled: false,
    callMeAllowed: false,
    callMeCountries: [],
    allowedCallMeCountries: [],
    ownConferenceEnabled: false,
    ownConferenceAllowed: false,
    ownConferenceMessage: '',
    logo: undefined,
    cbrProvisioned: false,
    cbrEntitled: false,
    cbrEnabled: false,
    transcriptsProvisioned: false,
    transcriptsEntitled: false,
    transcriptsEnabled: false,
    shareRecordingContentEnabled: false,
    videoInsightsEntitled: false,
    videoInsightsEnabled: false,
    videoInsightsProvisioned: false,
    conferenceModeratorPin: '',
    isStandaloneAudioProvisioned: true,
    isStandaloneAudioEntitled: true,
    isStandaloneAudioEnabled: true,
    isStandaloneAudioPinEnforced: false,
    isCoorganizerFeatureEnabled: false,
    isOpenMeetingsProvisioned: true,
    isOpenMeetingsEntitled: true,
    isOpenMeetingsDefault: false,
    isBrandingProvisioned: true,
    isBrandingEntitled: true,
    chromaCamEnabled: false,
    chromaCamProvisioned: false,
    shareRecordingLinkExpirationInDays: 7
  }),
  ux2019Settings: createAsyncSettingsGroup({
    ux2019ControllableByUser: false,
    ux2019Enabled: false,
    meetingHubEnabled: true,
    meetingHubControllableByUser: true
  }),
  gotoAppEapSettings: createAsyncSettingsGroup({
    gotoAppProvisioned: true,
    gotoAppEntitled: false,
    gotoAppEnabled: false
  })
};

const overwriteUndefinedValues = (target: { [key: string]: any }, overwrites: { [key: string]: any }) => {
  return Object.keys(target).reduce((result: any, key) => {
    result[key] = typeof target[key] !== 'undefined' ? target[key] : overwrites[key];
    return result;
  }, {});
};

const reducer: Reducer<State, Actions | SharedActions | AppActions> = (state = defaultState, action) => {
  switch (action.type as any) {
    case Events.REHYDRATE:
      const payload = (action as any).payload;
      if (payload && payload.core && payload.core.preferences) {
        return rehydrateState(state, payload.core.preferences);
      }
      return state;
  }

  switch (action.type) {
    // initial theme values come from me call
    case AppActionTypes.APP_LOAD:
      return {
        ...state,
        theme: {
          ...state.theme,
          isFetching: true
        }
      };
    case SharedActionTypes.UPDATE_USER:
      const accessibility = action.payload.accessibility;
      if (typeof accessibility !== 'boolean') {
        return {
          ...state,
          theme: {
            ...state.theme,
            isFetching: false,
            hasFetchingError: true
          }
        };
      }

      return {
        ...state,
        theme: {
          ...state.theme,
          isFetching: false,
          current: { useHighContrast: accessibility },
          update: { useHighContrast: accessibility }
        }
      };

    case ActionTypes.INITIAL_SETTINGS_FETCHED:
      return Object.keys(action.payload).reduce((newState: Record<string, any>, group: string) => {
        return {
          ...newState,
          [group]: {
            ...newState[group],
            current: { ...newState[group].current, ...(action.payload as Record<string, any>)[group] },
            update: { ...newState[group].update, ...(action.payload as Record<string, any>)[group] }
          }
        };
      }, state) as any;

    case ActionTypes.INITIAL_SETTINGS_FAILED:
      return {
        ...state,
        hasFetchingError: false
      };

    case ActionTypes.CHANGE_SETTING:
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          update: {
            ...state[action.payload.group].update,
            [action.payload.setting]: action.payload.value
          }
        }
      };
    case ActionTypes.ROLLBACK_SETTING_GROUP:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          update: state[action.payload].current
        }
      };
    case ActionTypes.SAVE_SETTING_GROUP:
      return {
        ...state,
        [action.payload]: {
          current: state[action.payload].update,
          update: state[action.payload].update
        }
      };

    case ActionTypes.SETTING_GROUP_FETCH_STARTED:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isFetching: true,
          hasFetchingError: false
        }
      };
    case ActionTypes.SETTING_GROUP_FETCH_SUCCEEDED:
      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          isFetching: false,
          current: action.payload.values,
          update: action.payload.resetUpdates
            ? action.payload.values
            : overwriteUndefinedValues(state[action.payload.group].update, action.payload.values)
        }
      };
    case ActionTypes.SETTING_GROUP_FETCH_FAILED:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isFetching: false,
          hasFetchingError: true
        }
      };

    case ActionTypes.SETTING_GROUP_UPDATE_STARTED:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isUpdating: true,
          hasUpdatingError: false
        }
      };
    case ActionTypes.SETTING_GROUP_UPDATE_SUCCEEDED:
      const updatedSettings = {
        ...state[action.payload.group].current,
        ...action.payload.values
      };

      return {
        ...state,
        [action.payload.group]: {
          ...state[action.payload.group],
          isUpdating: false,
          current: updatedSettings,
          update: updatedSettings
        }
      };
    case ActionTypes.SETTING_GROUP_UPDATE_FAILED:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          isUpdating: false,
          hasUpdatingError: true
        }
      };
  }

  return state;
};

export default reducer;

const rehydrateState = (state: State, hydratedState: Partial<State>): State => {
  if (!hydratedState[version]) {
    return state;
  }

  return {
    ...state,
    ...hydratedState
  };
};

export const hydrateState = (state: State): Partial<State> => {
  const groupPersistence: { [group in SettingGroupName]: boolean } = {
    theme: false,
    meetings: false,
    notifications: true,
    ux2019Settings: false,
    gotoAppEapSettings: false
  };

  // reset updates and async flags
  return Object.keys(groupPersistence).reduce((stateToHydrate: any, group) => {
    let groupToHydrate;
    if ((groupPersistence as any)[group]) {
      if (isAsyncGroup(stateToHydrate[group])) {
        groupToHydrate = {
          isFetching: false,
          isUpdating: false,
          hasFetchingError: false,
          hasUpdatingError: false,
          current: stateToHydrate[group].current,
          update: stateToHydrate[group].current
        } as AsyncSettingGroup<any>;
      } else {
        // sync
        groupToHydrate = {
          current: stateToHydrate[group].current,
          update: stateToHydrate[group].current
        } as SettingGroup<any>;
      }
    }

    return {
      ...stateToHydrate,
      [group]: groupToHydrate
    };
  }, state);
};

export const hasPreferencesChanges = (state: RootState, group: SettingGroupName) => {
  return Object.keys(getChangedSettings(state, group)).length > 0;
};

const hasSettingChanged = (group: string, setting: string, current: any, update: any): boolean => {
  if (group === 'meetings' && setting === 'logo' && current && update) {
    // mime type is lost when uploading to server, so just compare data
    return parseDataUrl(current.dataUrl).data !== parseDataUrl(update.dataUrl).data;
  }

  // read only settings => cannot/should not change or trigger a save
  const skipList = ['allowedTollFreeCountries', 'allowedLongDistanceCountries', 'allowedCallMeCountries'];
  if (skipList.includes(setting)) {
    return false;
  }

  // special handling for array properties
  const whiteListedArraySettings = ['longDistanceCountries', 'tollFreeCountries', 'callMeCountries'];

  if (
    group === 'meetings' &&
    whiteListedArraySettings.includes(setting) &&
    Array.isArray(current) &&
    Array.isArray(update) &&
    current.length === update.length
  ) {
    return !current.every((item, index) => item === update[index]);
  }

  return current !== update;
};

export const getChangedSettings = <G extends SettingGroupName>(
  state: RootState,
  group: G
): Partial<SettingGroupValues<G>> => {
  const { current, update }: { current: Record<string, any>; update: Record<string, any> } = getPreferences(state)[
    group
  ];
  const changedKeys = Object.keys(current).filter((key) => hasSettingChanged(group, key, current[key], update[key]));
  return changedKeys.reduce((changedSettings, key) => {
    (changedSettings as any)[key] = (update as any)[key];
    return changedSettings;
  }, {});
};

export enum ValidationErrors {
  CONFERENCE_MODERATOR_PIN_MANDATORY = 'CONFERENCE_MODERATOR_PIN_MANDATORY',
  CONFERENCE_MODERATOR_PIN_MIN_LENGTH = 'CONFERENCE_MODERATOR_PIN_MIN_LENGTH',
  CONFERENCE_MODERATOR_PIN_FORMAT = 'CONFERENCE_MODERATOR_PIN_FORMAT'
}

/* Returns errors by id of the element => error message so that they can be displayed next
 * to the UI element => components should query by their id*/
export const getValidationErrors = (
  state: RootState,
  group: string
): Array<ValidationErrors | AudioValidationErrors> => {
  let errors: Array<ValidationErrors | AudioValidationErrors> = [];
  const meetingsPreferences = { ...state.core.preferences.meetings.update };
  const previousMeetingsPreferences = { ...state.core.preferences.meetings.current };

  if (group === 'meetings') {
    errors = errors.concat(getAudioValidationErrors(meetingsPreferences));

    // no validation errors when ownConference is selected
    if (!meetingsPreferences.ownConferenceEnabled) {
      const isOrganizerPINFeatureEnabled =
        meetingsPreferences.isStandaloneAudioProvisioned &&
        meetingsPreferences.isStandaloneAudioEntitled &&
        meetingsPreferences.isStandaloneAudioEnabled;
      if (
        isOrganizerPINFeatureEnabled &&
        (meetingsPreferences.isStandaloneAudioPinEnforced ||
          previousMeetingsPreferences.conferenceModeratorPin.length > 0)
      ) {
        if (meetingsPreferences.conferenceModeratorPin.length === 0) {
          errors.push(ValidationErrors.CONFERENCE_MODERATOR_PIN_MANDATORY);
        } else if (!meetingsPreferences.conferenceModeratorPin.match('^[0-9]+$')) {
          errors.push(ValidationErrors.CONFERENCE_MODERATOR_PIN_FORMAT);
        } else if (meetingsPreferences.conferenceModeratorPin.length < 4) {
          errors.push(ValidationErrors.CONFERENCE_MODERATOR_PIN_MIN_LENGTH);
        }
      }
    }
  }

  return errors;
};

export const getActiveSetting = <G extends SettingGroupName, S extends SettingName<G>>(
  state: RootState,
  group: G,
  name: S
): SettingValue<G, S> => {
  return (getPreferences(state) as any)[group].current[name];
};

export const isAsyncGroup = (settings: State[SettingGroupName]): settings is State[AsyncSettingGroupName] => {
  return (settings as any).isFetching != null;
};

// Group Types
interface SettingGroup<T extends object> {
  current: T;
  update: T;
}

interface AsyncSettingGroup<T extends object> extends SettingGroup<T> {
  isFetching: boolean;
  hasFetchingError: boolean;
  isUpdating: boolean;
  hasUpdatingError: boolean;
}

export type SettingGroupName = NonNullable<
  { [key in keyof State]: State[key] extends SettingGroup<any> ? key : never }[keyof State]
>;
export type SettingName<G extends SettingGroupName> = keyof State[G]['current'];
export type SettingValue<G extends SettingGroupName, S extends SettingName<G>> = State[G]['current'][S];
export type SettingGroupValues<G extends SettingGroupName> = { [setting in SettingName<G>]: SettingValue<G, setting> };

export type AsyncSettingGroupName = NonNullable<
  { [key in keyof State]: State[key] extends AsyncSettingGroup<any> ? key : never }[keyof State]
>;
export type AsyncSettingName<G extends AsyncSettingGroupName> = keyof State[G]['current'];
export type AsyncSettingValue<G extends AsyncSettingGroupName, S extends AsyncSettingName<G>> = State[G]['current'][S];
export type AsyncSettingGroupValues<G extends AsyncSettingGroupName> = {
  [setting in AsyncSettingName<G>]: AsyncSettingValue<G, setting>;
};

export type SyncSettingGroupName = Exclude<SettingGroupName, AsyncSettingGroupName>;
