import React from 'react';
import { getFullName } from '../../../../../lib/person';
import { User } from '../../../../../types/pulse-web';
import MessageHistoryEntry from '../MessageHistoryEntry';
import { UploadProgressItem } from '../../../Common/Upload/UploadProgressItem/UploadProgressItem';
import { FileUploadEntry } from '../../../../view-model/modules/messaging/uploads/uploadsReducer';

export interface Props {
  cancelUpload: (upload: FileUploadEntry) => void;
  clearUpload: (upload: FileUploadEntry) => void;
  uploads: ReadonlyArray<FileUploadEntry>;
  user: User;
}

export class UploadProgress extends React.PureComponent<Props> {
  render() {
    if (!this.props.uploads.length) {
      return null;
    }

    const historyProps = {
      avatarUrl: this.props.user.avatarUrl,
      name: getFullName(this.props.user)
    };

    return (
      <ol className="upload-progress">
        <MessageHistoryEntry {...historyProps} isMine>
          <>
            {this.props.uploads.map((upload, i) => (
              <UploadProgressItem
                onCancel={this.props.cancelUpload}
                onClear={this.props.clearUpload}
                key={`${upload.file.name}-${i}`}
                upload={upload}
              />
            ))}
          </>
        </MessageHistoryEntry>
      </ol>
    );
  }
}
