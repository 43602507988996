import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RosterActions } from '@getgo/caps-redux';
import { navigateTo } from '../services/NavService';
import { selectContact } from '../view-model/capsActions';
import { clearQuery, queryAllPeople } from '../view-model/modules/messaging/people/peopleActions';
import errorActions from '../view-model/modules/core/errors/errorActions';
import { AddContact } from '../components/Chat/AddContact/AddContact';
import { getIsOnline } from '../view-model/selectors';
import Paging from '../../models/Paging';
import { Dispatch, State } from '../view-model/types';

export const useContactSearchConnector = () => {
  const dispatch = useDispatch();
  const messagingPeople = useSelector((state: State) => state.messaging.people);
  const isOnline = useSelector((state: State) => getIsOnline(state));
  const inviting = useSelector((state: State) => state.messaging.contacts.inviting);
  const allowInviteExternal = useSelector((state: State) => state.core.app.features.includes('external_contacts'));

  const dispatches = useMemo(() => mapDispatchToProps(dispatch), []);

  // when offline state.people will be incomplete.
  const people = {
    // @ts-expect-error
    suggestions: [],
    // @ts-expect-error
    paging: new Paging(),
    ...messagingPeople
  };

  const state = {
    isOnline,
    people,
    inviting,
    allowInviteExternal
  };

  return { ...state, ...dispatches };
};

const ContactSearch = () => {
  const connectorProps = useContactSearchConnector();

  return <AddContact {...connectorProps} />;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onQuery: (query?: string, nextPageKey?: number) => {
      // Empty query implies getting users social graph
      // And, a valid page key for empty query implies gettin the next set of nodes in the graph.
      if ((query?.trim && query.trim().length) || (nextPageKey && nextPageKey > 0)) {
        dispatch(errorActions.clearSocialGraphError());
        dispatch(queryAllPeople(query, nextPageKey));
      } else {
        dispatch(clearQuery());
      }
    },
    onAddContact: async (jid: string, email: string, name: string) => {
      await dispatch(RosterActions.addContact(jid, email, name));
      dispatch(errorActions.clearChatError());
      dispatch(selectContact(jid));
    },
    onInviteExternal: async (email: string) => {
      const { payload } = await dispatch(RosterActions.addExternalContact(email));
      dispatch(errorActions.clearChatError());
      dispatch(selectContact(payload.jid));
    },
    onCancel: () => {
      dispatch(errorActions.clearChatError());
      navigateTo('chat');
    },
    clearQuery: (options?: {}) => {
      dispatch(errorActions.clearError());
      dispatch(clearQuery(options));
    }
  };
};

export default ContactSearch;
