import Mousetrap from 'mousetrap';
import React, { useEffect, useState, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { getDisplayName } from '../../../../lib/person';
import { SuggestionsList } from '../../Common/SuggestionsList';
import { Body, Header, Page } from '../../Common/PageTemplate';
import './AddContact.css';
import Paging from '../../../../models/Paging';
import { SingleSuggestion, Suggestion } from '@getgo/caps-redux';
import { clamp } from '../../../../lib/numbers-utility';
import { bindKey } from '../../../../lib/keybindings';
import { debounce } from 'lodash';
import { KeyNames } from '../../../../lib/keycodes';
import { CenteredSpinner } from '../../Common/Toolkit/Spinner/Spinner';

const messages = defineMessages({
  title: {
    id: 'addContact.title',
    defaultMessage: 'Start a conversation'
  },
  placeholder: {
    id: 'addContact.placeholder',
    defaultMessage: 'Type a name or email address'
  },
  offlineWarning: {
    id: 'addContact.offlineWarning',
    defaultMessage: 'Not connected to the Internet'
  },
  invitingExternalContact: {
    id: 'addContact.invitingExternalContact',
    defaultMessage: 'Sending invitation to {email}'
  }
});

interface Props {
  people: {
    isFetching: boolean;
    suggestions: any[];
    paging: Paging;
    apiError?: boolean;
  };
  inviting?: boolean;
  onQuery?: (query?: string, nextPageKey?: number) => void;
  onAddContact?: (jid: string, email: string, name: string) => void;
  onInviteExternal?: (email: string) => void;
  onCancel?: (e?: React.MouseEvent<HTMLButtonElement> | ExtendedKeyboardEvent) => void;
  clearQuery?: (options?: object) => void;
  allowInviteExternal?: boolean;
  isOnline?: boolean;
}

export const AddContact: React.FC<Props> = ({
  people,
  inviting = false,
  onQuery = () => {},
  onAddContact = () => {},
  onInviteExternal = () => {},
  onCancel = () => {},
  clearQuery = () => {},
  allowInviteExternal = false,
  isOnline = true
}) => {
  const [email, setEmail] = useState('');
  const [highlight, setHighlight] = useState(-1);
  const { formatMessage: f } = useIntl();

  const onSuggestionSelected = useCallback(
    (suggestedContact: Suggestion) => {
      suggestedContact = suggestedContact as SingleSuggestion;

      setEmail(suggestedContact.email);
      setHighlight(-1);

      onAddContact(suggestedContact.jid, suggestedContact.email, getDisplayName(suggestedContact));
    },
    [onAddContact]
  );

  const selectUp = useCallback(() => {
    if (!people.suggestions.length) return;

    setHighlight(clamp(highlight - 1, 0, people.suggestions.length - 1));
  }, [highlight, people.suggestions]);

  const selectDown = useCallback(() => {
    if (!people.suggestions.length) return;

    setHighlight(clamp(highlight + 1, 0, people.suggestions.length - 1));
  }, [highlight, people.suggestions]);

  const commitSelection = useCallback(() => {
    const selection = people.suggestions[highlight];

    if (selection) {
      onSuggestionSelected(selection);
    }
  }, [highlight, people.suggestions, onSuggestionSelected]);

  useEffect(() => {
    Mousetrap.bind([KeyNames.ARROW_UP], selectUp);
    Mousetrap.bind([KeyNames.ARROW_DOWN], selectDown);
    Mousetrap.bind([KeyNames.ENTER], commitSelection);

    const unbindEscape = bindKey(onCancel, KeyNames.ESCAPE);

    return () => {
      Mousetrap.unbind([KeyNames.ARROW_UP, KeyNames.ARROW_DOWN, KeyNames.ENTER]);
      unbindEscape();
    };
  }, [selectUp, selectDown, commitSelection, onCancel]);

  useEffect(() => {
    return () => {
      clearQuery({ preventUiError: true });
    };
  }, [clearQuery]);

  useEffect(() => {
    if (people.apiError && isOnline) {
      clearQuery();
    }
  }, [isOnline, people.apiError, clearQuery]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setEmail(query);
    setHighlight(-1);

    debounce(
      (query: string) => {
        onQuery(query);
      },
      400,
      { leading: true }
    )(query);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    Mousetrap.trigger(e.key);
  };

  const onMouseOverSuggestion = (arrayIndex: number) => {
    setHighlight(arrayIndex);
  };

  const formatSuggestionTitle = (suggestedContact: Suggestion) => {
    suggestedContact = suggestedContact as SingleSuggestion;

    return (
      <div className="name-container">
        <div className="name">{getDisplayName(suggestedContact)}</div>
        {getDisplayName(suggestedContact) && <div className="email">{suggestedContact.email}</div>}
      </div>
    );
  };

  const suggestionsList = () => {
    return (
      <SuggestionsList
        suggestions={people.suggestions}
        onSelect={onSuggestionSelected}
        onInviteExternal={onInviteExternal}
        queryText={email}
        highlightIndex={highlight}
        avatarName={(s) => getDisplayName(s as SingleSuggestion)}
        suggestionTitle={formatSuggestionTitle}
        allowInviteExternal={allowInviteExternal}
        onMouseOverSuggestion={onMouseOverSuggestion}
        apiError={people.apiError}
      />
    );
  };

  const suggestionsArea = () =>
    people.isFetching && people.suggestions.length === 0 ? <CenteredSpinner /> : suggestionsList();

  const invitingNotice = (
    <div className="sending-invitation-notice">
      {f(messages.invitingExternalContact, { email: <strong key="email">{email}</strong> })}
    </div>
  );

  const suggestionAreaWrapper = isOnline ? (
    <div className="suggestions-area-wrapper">{inviting ? invitingNotice : suggestionsArea()}</div>
  ) : null;

  const offlineWarning = !isOnline ? <div className="offline-warning">{f(messages.offlineWarning)}</div> : null;

  return (
    <Page className="add-contact">
      <Header enableCancel={true} onCancel={(!inviting && onCancel) || undefined}>
        {f(messages.title)}
      </Header>
      <Body>
        <input
          autoFocus
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          spellCheck={false}
          placeholder={f(messages.placeholder)}
          type="text"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={inviting || !isOnline}
        />
        {suggestionAreaWrapper}
        {offlineWarning}
      </Body>
    </Page>
  );
};
