import * as React from 'react';
import styled from '../../styled-components';
import device from '../Common/Toolkit/devices';
import { Spinner } from '../Common/Toolkit/Spinner/Spinner';
import { Item } from '../Common/Toolkit/Box/Box';
import { Icon } from '../Common/Toolkit/Icon/Icon';
import { Icons } from '../Common/Toolkit/ICONS';
import { FormattedMessage, defineMessages, WrappedComponentProps, injectIntl } from 'react-intl';
import { Brush, Check, Copy, Download, Edit, Leave, Link, Trash } from '../Common/Toolkit/Icon/ReactIcons';
import { GhostButton, Props as GhostButtonProps } from '../Common/Toolkit/Button/GhostButton';
import { PrimaryButton } from '../Common/Toolkit/Button/PrimaryButton';
import { Props as ButtonProps } from '../Common/Toolkit/Button/Button';
import { formatId } from '@getgo/format-meeting-id';
import { themeGet } from '@styled-system/theme-get';
import { Badge } from '../Common/Badge/Badge';
import { Meeting } from '../../view-model/modules/meetings/MeetingsTypes';

export const messages = defineMessages({
  start: {
    id: 'meetingHub.meetingComponents.start',
    defaultMessage: 'start'
  },
  starting: {
    id: 'meetingHub.meetingComponents.starting',
    defaultMessage: 'starting'
  },
  startInApp: {
    id: 'meetingHub.meetingComponents.startInApp',
    defaultMessage: 'Start in the App'
  },
  join: {
    id: 'meetingHub.meetingComponents.join',
    defaultMessage: 'join'
  },
  copy: {
    id: 'meetingHub.meetingComponents.copy',
    defaultMessage: 'Copy invitation'
  },
  edit: {
    id: 'meetingHub.meetingComponents.edit',
    defaultMessage: 'edit'
  },
  personalize: {
    id: 'meetingHub.meetingComponents.personalize',
    defaultMessage: 'personalize'
  },
  delete: {
    id: 'meetingHub.meetingComponents.delete',
    defaultMessage: 'delete'
  },
  download: {
    id: 'meetingHub.meetingComponents.download',
    defaultMessage: 'download'
  },
  share: {
    id: 'meetingHub.meetingComponents.share',
    defaultMessage: 'share'
  },
  end: {
    id: 'meetingHub.meetingComponents.end',
    defaultMessage: 'end'
  },
  openRecording: {
    id: 'meetingHub.meetingComponents.openRecording',
    defaultMessage: 'open recording'
  },
  processingRecording: {
    id: 'meetingHub.meetingComponents.processingRecording',
    defaultMessage: 'processing recording'
  },
  runningBadge: {
    id: 'meetingHub.meetingComponents.runningBadge',
    defaultMessage: 'running'
  }
});

export const MeetingsTabSpinner = styled((props: any) => (
  <div className={props.className}>
    <Spinner />
  </div>
))`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MeetingsTabContainer = styled.div`
  padding: 24px 32px;

  @media ${device.laptop} {
    padding: 16px 20px;
  }

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const MeetingsTabTitle = styled.h3<{ color?: string; textAlign?: string; whiteSpace?: string }>`
  margin: -4px 0 5px 0;
  padding-top: 4px;
  font-size: 16px;
  line-height: 19px; /* work around so that emojis don't break the line */
  font-family: Lato-bold;
  font-weight: bold;
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MeetingsId = styled(({ meeting }: { meeting: Meeting }) => (
  <div title={formatId(meeting.meetingId)}>
    {meeting.isOrganizer && meeting.meetingUrl?.meetingUrl
      ? meeting.meetingUrl.meetingUrl.replace(/^https?:\/\/[^\/]+/, '')
      : `ID: ${formatId(meeting.meetingId)}`}
  </div>
))`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MeetingsTabId = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MeetingsTabTime = styled(Item)`
  flex: 0 0 75px;
  text-align: right;
  padding: 0 24px 0 0;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    padding: 0 16px 0 0;
  }
`;

export const MeetingsTabSubject = styled(Item)`
  flex: 1;
  display: flex;
  flex-direction: column;
  display: inline-grid;
  word-break: break-all;
`;

export const AnytimeTabIconContainer = styled.div`
  align-self: center;
  padding-right: 32px;
  flex: 0 0 30px;

  @media ${device.laptop} {
    padding-right: 20px;
  }

  @media ${device.mobile} {
    padding-right: 16px;
  }
`;

export const AnytimeIcon = () => <Icon size={30} icon={Icons.calendarRecurring} />;

export const IconButton = styled(GhostButton)`
  font-family: lato-bold;
  letter-spacing: 1px;
  padding: 0 24px;

  div.spinnerIcon {
    margin: 0 8px 0 0;
  }

  .main-path {
    fill: ${themeGet('colors.primary')};
  }

  @media ${device.laptop} {
    font-size: 12px;
  }

  @media ${device.tablet} {
    flex: 1 1 auto;
    padding: 0 20px;
  }

  @media ${device.mobile} {
    padding: 0;
    margin-top: 8px;
  }

  &:disabled {
    color: rgba(37, 40, 45, 0.36);

    .main-path {
      fill: #9aa8bb;
    }
  }
`;

const RedIconButton = styled(IconButton)`
  color: ${themeGet('colors.danger.primaryScale.400')};

  .main-path {
    fill: ${themeGet('colors.danger.primaryScale.400')};
  }
  &:active:enabled {
    background-color: ${themeGet('colors.danger.primaryScale.100')};
  }
  &:focus {
    box-shadow: 0 0 0 2px ${themeGet('colors.danger.primaryScale.400')};
  }
`;

const BasicPrimaryButton = styled((props: ButtonProps) => (
  <PrimaryButton {...props} iconSize={12}>
    {props.children}
  </PrimaryButton>
))`
  padding: 0 24px;

  @media ${device.laptop} {
    font-size: 12px;
  }

  @media ${device.tablet} {
    flex: 1 1 auto;
    justify-content: center;
    margin-bottom: 16px;
  }

  @media ${device.mobile} {
    padding: 0;
    margin: 0;
  }
`;

export interface CopyClipboardResult {
  copied: boolean;
  error: boolean;
}

export class CopyButton extends React.Component<
  { onClick: () => Promise<CopyClipboardResult>; timeoutDuration?: number },
  { showChecked: boolean }
> {
  private timer: any = null;

  state = {
    showChecked: false
  };

  componentWillUnmount(): void {
    clearTimeout(this.timer);
  }

  handleClick = async () => {
    const { timeoutDuration = 3000 } = this.props;
    const result = await this.props.onClick();
    if (result.copied) {
      this.setState({ showChecked: true });
      this.timer = setTimeout(() => {
        this.setState({ showChecked: false });
      }, timeoutDuration);
    }
  };

  render() {
    return (
      <IconButton onClick={this.handleClick} icon={this.state.showChecked ? <Check /> : <Copy />}>
        <FormattedMessage {...messages.copy} />
      </IconButton>
    );
  }
}

export const EditButton = ({ onClick }: GhostButtonProps) => (
  <IconButton data-testid="button-edit" onClick={onClick} icon={<Edit />}>
    <FormattedMessage {...messages.edit} />
  </IconButton>
);

export const PersonalizeButton = ({ onClick }: GhostButtonProps) => (
  <IconButton data-testid="button-personalize" onClick={onClick} icon={<Brush />}>
    <FormattedMessage {...messages.personalize} />
  </IconButton>
);

export const DeleteButton = ({ onClick }: GhostButtonProps) => (
  <IconButton data-testid="button-delete" onClick={onClick} icon={<Trash />}>
    <FormattedMessage {...messages.delete} />
  </IconButton>
);

export const DownloadButton = ({ onClick, disabled }: GhostButtonProps) => (
  <IconButton data-testid="button-download" disabled={disabled} onClick={onClick} icon={<Download />}>
    <FormattedMessage {...messages.download} />
  </IconButton>
);

export const ShareButton = ({ onClick }: GhostButtonProps) => (
  <IconButton data-testid="button-share" onClick={onClick} icon={<Link />}>
    <FormattedMessage {...messages.share} />
  </IconButton>
);

export const StartButton = (props: { onClick: () => void; loading?: boolean; disabled?: boolean }) => (
  <BasicPrimaryButton data-testid="button-start" {...props}>
    {props.loading ? <FormattedMessage {...messages.starting} /> : <FormattedMessage {...messages.start} />}
  </BasicPrimaryButton>
);

export const StartInAppButton = (props: GhostButtonProps) => (
  <BasicPrimaryButton data-testid="button-start-in-app" onClick={props.onClick}>
    <FormattedMessage {...messages.startInApp} />
  </BasicPrimaryButton>
);

export const JoinButton = (props: GhostButtonProps) => (
  <BasicPrimaryButton data-testid="button-join" onClick={props.onClick}>
    <FormattedMessage {...messages.join} />
  </BasicPrimaryButton>
);

export const EndButton = (props: GhostButtonProps) => (
  <RedIconButton data-testid="button-end" onClick={props.onClick} icon={<Leave color="#f66355" />}>
    <FormattedMessage {...messages.end} />
  </RedIconButton>
);

export const OpenRecordingButton = (props: GhostButtonProps) => (
  <BasicPrimaryButton data-testid="open-recording-button" onClick={props.onClick}>
    <FormattedMessage {...messages.openRecording} />
  </BasicPrimaryButton>
);

export const ProcessingRecordingButton = () => (
  <IconButton
    data-testid="button-processing-recording"
    disabled
    icon={<Spinner width={'14px'} height={'14px'} lineWidth={'2px'} />}
  >
    <FormattedMessage {...messages.processingRecording} />
  </IconButton>
);

export const ScheduleMeetingButton = styled(PrimaryButton)`
  padding: 0 16px;
  margin: 24px 16px 0 16px;
  justify-content: center;
  flex-shrink: 0;

  span {
    padding: 0 8px 0 0;
  }

  @media ${device.laptop} {
    margin: 16px 16px 0 16px;
  }

  @media ${device.mobile} {
    margin: 8px;
  }

  .main-path {
    fill: ${themeGet('colors.primaryOn')};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const MeetingRunningBadge = styled(
  injectIntl((props: { className?: string } & WrappedComponentProps) => (
    <Badge
      className={props.className}
      text={props.intl.formatMessage(messages.runningBadge)}
      data-testid="meeting-running-badge"
    />
  ))
)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
