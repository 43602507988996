import * as t from 'io-ts';
import config from 'config';
import api from '../lib/rest';

export enum VideoInteractions {
  play = 'play',
  pause = 'pause',
  playbackComplete = 'playbackComplete'
}

const tEngagementMessageBase = t.intersection([
  t.type({
    templateId: t.string,
    contentId: t.string,
    meetingCardTitle: t.string,
    meetingCardDescription: t.string
  }),
  t.partial({ contentRead: t.boolean })
]);

const tEngagementMessageBlogTemplate = t.intersection([
  tEngagementMessageBase,
  t.intersection([
    t.type({
      blogTitle: t.string,
      blogDescription: t.string,
      blogArticle: t.string,
      url: t.string
    }),
    t.partial({ alt: t.string, ctaLinkText: t.string, ctaURL: t.string })
  ])
]);

const tEngagementMessage = t.type({
  engagementId: t.string,
  engagementMessage: tEngagementMessageBase
});

const responseTypes = {
  getEngagementEngineMessages: t.array(tEngagementMessage)
};

const getRequestBody = (entry: EngagementEntry, props: any) => {
  return {
    engagementId: entry.engagementId,
    engagementMessage: {
      contentId: entry.engagementMessage.contentId,
      ...props
    }
  };
};

const getUrl = (userId: string, accountId: string) => {
  return `${config.engagementEngine.discoverUrl}/account/${accountId}/user/${userId}`;
};

export const getEngagementEngineMessages = async (
  userId: string,
  accountId: string,
  params: {
    product?: string;
    locale?: string;
  }
) => {
  const url = `${config.engagementEngine.discoverUrl}/account/${accountId}/user/${userId}`;
  return await api.getValidated(responseTypes.getEngagementEngineMessages, url, params);
};

export const markMessageAsRead = async (
  userId: string,
  accountId: string,
  entry: EngagementEntry,
  params: {
    product?: string;
    locale?: string;
  }
) => {
  const url = getUrl(userId, accountId);
  return await api.postValidated(t.any, url, getRequestBody(entry, { contentRead: true }), undefined, params);
};

export const markCtaClicked = async (
  userId: string,
  accountId: string,
  entry: EngagementEntry,
  params: {
    product?: string;
    locale?: string;
  }
) => {
  const url = getUrl(userId, accountId);
  return await api.postValidated(t.any, url, getRequestBody(entry, { ctaClicked: true }), undefined, params);
};

export const markMessageAsDismissed = async (
  userId: string,
  accountId: string,
  entry: EngagementEntry,
  params: {
    product?: string;
    locale?: string;
  }
) => {
  const url = getUrl(userId, accountId);
  return await api.postValidated(t.any, url, getRequestBody(entry, { contentDismissed: true }), undefined, params);
};

export const sendVideoInteraction = async (
  userId: string,
  accountId: string,
  entry: EngagementEntry,
  interactionType: VideoInteractions,
  params: {
    product?: string;
    locale?: string;
  }
) => {
  const url = getUrl(userId, accountId);
  return await api.postValidated(
    t.any,
    url,
    getRequestBody(entry, { videoInteraction: interactionType }),
    undefined,
    params
  );
};

export type EngagementEntry = t.TypeOf<typeof tEngagementMessage>;
export type EngagementMessageBlogTemplate = t.TypeOf<typeof tEngagementMessageBlogTemplate>;

export const isImageBlogTemplate = (
  engagementEngineMessage: any
): engagementEngineMessage is EngagementMessageBlogTemplate => {
  return (engagementEngineMessage as EngagementMessageBlogTemplate).templateId == 'imageBlog';
};

export const isVideoBlogTemplate = (
  engagementEngineMessage: any
): engagementEngineMessage is EngagementMessageBlogTemplate => {
  return (engagementEngineMessage as EngagementMessageBlogTemplate).templateId == 'videoBlog';
};

// needed because engagementId is not unique
export const getCompositeId = (e: EngagementEntry) => {
  return `${e.engagementId}${e.engagementMessage.contentId}${e.engagementMessage.templateId}`;
};

export const compareByCompositeId = (a: EngagementEntry, b: EngagementEntry) => {
  return getCompositeId(a) === getCompositeId(b);
};
