import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { DashboardHeader } from '../Common/Dashboard/DashboardHeader';
import { HubLogo } from '../Common/Dashboard/HubLogo';
import { DashboardContainer } from '../Common/Dashboard/DashboardContainer';
import { Sidebar } from '../Meetings/MeetingsDashboard';
import { Box } from '../Common/Toolkit/Box/Box';
import { DashboardContentPane } from '../Common/Dashboard/DashboardContentPane';
import styled from '../../styled-components';
import device from '../Common/Toolkit/devices';
import { DiscoverCard } from './DiscoverCard';
import { CardList } from '../Common/Dashboard/CardList';
import { DiscoverContentCard } from './DiscoverContentCard';
import { compareByCompositeId, EngagementEntry } from '../../../models/EngagementEngine';
import Scrollbars from 'react-custom-scrollbars';
import { DiscoverDashboardPlaceholder } from './DiscoverDashboardPlaceholder';
import { Spinner } from '../Common/Toolkit/Spinner/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../view-model/types';
import {
  dismissDiscoverItem,
  fetchDiscover,
  selectDiscoverItem
} from '../../view-model/modules/discover/discoverActions';
import { getDiscoverUnreadCount } from '../../view-model/selectors';
import { themeGet } from '@styled-system/theme-get';

const messages = defineMessages({
  sidebarHeading: {
    id: 'discoverDashboard.sidebarHeading',
    defaultMessage: "Discover What's New"
  },
  sidebarHeadingRead: {
    id: 'discoverDashboard.sidebarHeadingRead',
    defaultMessage: "You're Up to Date"
  },
  sidebarEmptyLabel: {
    id: 'discoverDashboard.sidebarEmptyLabel',
    defaultMessage: 'More to Come Soon'
  }
});

const SidebarHeading = styled.h3`
  color: #25282d;
  font-size: 24px;
  margin: 0 0 24px 0px;
  padding: 0 0 0 16px;

  @media ${device.tablet} {
    padding: 0 0 0 16px;
  }
`;

const DiscoverSidebar = styled(Sidebar)`
  @media ${device.mobile} {
    display: none;
  }
`;

const SpinnerContainer = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyLabelWrapper = styled.div`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  margin-top: -24px;
  align-items: center;
`;

const EmptyLabel = styled.h3`
  color: ${themeGet('colors.neutralScale.450')};
  margin: 0;
`;

export const DiscoverDashboard: React.FC = () => {
  const discover = useSelector((state: State) => state.discover);
  const dispatch = useDispatch();

  const [discoverUnreadCount, setDiscoverUnreadCount] = useState(0);

  useEffect(() => {
    if (!discover.isFetching) {
      setDiscoverUnreadCount(getDiscoverUnreadCount({ discover: discover }));
    }
  }, [discover.isFetching]);

  useEffect(() => {
    dispatch(fetchDiscover());
  }, []);

  const handleCardClick = (entry: EngagementEntry) => {
    dispatch(selectDiscoverItem(entry));
  };

  const handleCardClose = (entry: EngagementEntry) => {
    dispatch(dismissDiscoverItem(entry));
  };

  return (
    <DashboardContainer>
      <DashboardHeader role="banner">
        <HubLogo />
      </DashboardHeader>
      <Box flexDirection="row" flex={'1 1 0%'} role="main">
        <DiscoverSidebar>
          {discover.isFetching ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              <SidebarHeading>
                <FormattedMessage
                  {...(discoverUnreadCount === 0 ? messages.sidebarHeadingRead : messages.sidebarHeading)}
                />
              </SidebarHeading>
              {discover.entries.length > 0 ? (
                <CardList>
                  {discover.entries.map((entry) => {
                    return (
                      <li key={`discover-card-${entry.engagementId}`}>
                        <DiscoverCard
                          isSelected={!!discover.selectedEntry && compareByCompositeId(discover.selectedEntry, entry)}
                          title={entry.engagementMessage.meetingCardTitle}
                          subtitle={entry.engagementMessage.meetingCardDescription}
                          unread={!entry.engagementMessage.contentRead}
                          onClick={() => handleCardClick(entry)}
                          onClose={() => handleCardClose(entry)}
                        />
                      </li>
                    );
                  })}
                </CardList>
              ) : (
                <EmptyLabelWrapper>
                  <EmptyLabel>
                    <FormattedMessage {...messages.sidebarEmptyLabel} />
                  </EmptyLabel>
                </EmptyLabelWrapper>
              )}
            </>
          )}
        </DiscoverSidebar>
        <div style={{ flex: '1 1 0%' }}>
          <Scrollbars autoHide>
            <DashboardContentPane style={{ display: 'flex', flexDirection: 'column' }}>
              {discover.selectedEntry ? (
                <DiscoverContentCard engagementEntry={discover.selectedEntry} />
              ) : (
                <DiscoverDashboardPlaceholder />
              )}
            </DashboardContentPane>
          </Scrollbars>
        </div>
      </Box>
    </DashboardContainer>
  );
};
