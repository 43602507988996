// import { handleActions } from 'redux-actions';
import { ActionTypes, Actions } from './messageInputsActions';
import { createState } from '../../../../components/Chat/MessageInputField';

import { Reducer } from 'redux';

interface Mention {
  mentionText: string;
  jid: string;
}

type EditorState = ReturnType<typeof createState>;

export interface DraftJSPersistedMessageInput {
  editorState: EditorState | null;
  messageInputHeight?: number;
}

export interface PlainPersistedMessageInput {
  message: string;
  mentionList: Mention[];
  messageInputHeight?: number;
}

type State = Readonly<{
  [roomId: string]: DraftJSPersistedMessageInput | PlainPersistedMessageInput;
}>;

export const defaultState = Object.freeze({});

const reducer: Reducer<State, Actions> = (state: State = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.PERSIST_DRAFTJS_MESSAGE_INPUT_STATE:
      return {
        ...state,
        [action.payload.roomId]: {
          ...state[action.payload.roomId],
          editorState: action.payload.persistedMessageInput.editorState,
          messageInputHeight: action.payload.persistedMessageInput.messageInputHeight
        }
      };
    case ActionTypes.PERSIST_PLAIN_MESSAGE_INPUT_STATE:
      return {
        ...state,
        [action.payload.roomId]: {
          ...state[action.payload.roomId],
          message: action.payload.persistedMessageInput.message,
          mentionList: action.payload.persistedMessageInput.mentionList,
          messageInputHeight: action.payload.persistedMessageInput.messageInputHeight
        }
      };
    case ActionTypes.CHANGE_MESSAGE_INPUT_HEIGHT:
      return {
        ...state,
        [action.payload.roomId]: {
          ...state[action.payload.roomId],
          messageInputHeight: action.payload.height
        }
      };
  }
  return state;
};

export default reducer;
