import logger from '../../services/LoggerService';
import { Middleware } from '../types';

export const errorMiddleware: Middleware = () => (next) => (action) => {
  try {
    return next(action);
  } catch (error) {
    logger.error('errorMiddleware', 'error=', error, 'action=', action);
    throw error;
  }
};

export default errorMiddleware;
