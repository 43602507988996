import { defineMessages, FormattedDate, FormattedMessage } from 'react-intl';
import * as React from 'react';
import styled from '../../styled-components';
import { Props as ButtonProps } from '../Common/Toolkit/Button/Button';
import { PrimaryButton } from '../Common/Toolkit/Button/PrimaryButton';
import device from '../Common/Toolkit/devices';
import { SecondaryButton } from '../Common/Toolkit/Button/SecondaryButton';
import { Icons } from '../Common/Toolkit/ICONS';
import { Icon } from '../Common/Toolkit/Icon/Icon';
import { getBackdatedValue, getDiffInDays } from '../../../lib/date';
import { isLeapYear } from 'date-fns';

const messages = defineMessages({
  open: {
    id: 'transcriptSearch.transcriptSearchComponents.open',
    defaultMessage: 'Open'
  }
});

export const OpenRecordingContainer = styled.div`
  position: absolute;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), #ffffff 31%);
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  width: 114px;
  justify-content: center;

  @media ${device.mobile} {
    padding: 16px;
    display: none;
  }
`;

const BasicPrimaryButton = styled((props: ButtonProps) => (
  <PrimaryButton {...props} iconSize={12}>
    {props.children}
  </PrimaryButton>
))`
  padding: 0 15px;

  @media ${device.laptop} {
    font-size: 12px;
  }

  @media ${device.mobile} {
    padding: 0;
    margin: 0;
  }
`;

export const OpenSearchRecordingResultButton = (props: {
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
}) => (
  <BasicPrimaryButton data-testid="button-open-recording" {...props}>
    <FormattedMessage {...messages.open} />
  </BasicPrimaryButton>
);

const COLORS_COLLECTION = [
  '#6739BF',
  '#004FBD',
  '#166549',
  '#8C4400',
  '#7B44E4',
  '#E02D52',
  '#F68D2E',
  '#006AFF',
  '#008858',
  '#B38F00'
];

export const generateRandomColors = (initialName: string) => {
  if (!initialName) {
    return COLORS_COLLECTION[0];
  }
  const charCodes = initialName
    .split('')
    .map((char: string) => char.charCodeAt(0))
    .join('');
  const index = parseInt(charCodes, 10);
  return COLORS_COLLECTION[index % COLORS_COLLECTION.length];
};

export const Avatar = styled.div<{ background: string }>`
  background-color: ${({ background }) => background};
  height: 36px;
  width: 36px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  margin-right: 14px;
  text-transform: uppercase;
`;

const NavigationButton = styled(SecondaryButton)`
  font-weight: bold;
  font-size: 12px;
  padding: 0 16px;
  height: 32px;
  line-height: 30px;
  border-radius: 4px;
  border: solid 1px rgba(76, 83, 93, 0.24);
  background: #f1f2f5;
  @media ${device.mobile} {
    background: #ffffff;
  }
`;

const IconContainer = styled.i`
  color: #6b7582;
`;

export const CustomNavigationButton = styled(
  (props: {
    startIcon?: string;
    endIcon?: string;
    className?: string;
    onClick?: () => void;
    id?: string;
    children?: React.ReactNode;
    disabled?: boolean;
  }) => (
    <NavigationButton onClick={props.onClick} data-testid={props.id} disabled={props.disabled}>
      {props.startIcon && <IconContainer className={props.startIcon} />}
      {props.children}
      {props.endIcon && <IconContainer className={props.endIcon} />}
    </NavigationButton>
  )
)``;

const TranscriptIconContainer = styled.div<{ border: string }>`
  position: relative;
  height: 23px;
  width: 29px;
  margin-right: 8px;
  padding-top: 2px;

  .note-icon {
    border-radius: 4px;
    padding: 1px;
    border: ${(props) => `2px ${props.border} solid !important`};
  }
  .mic-icon {
    position: absolute;
    width: 12px;
    height: 12px;
    bottom: -3px;
    right: -10px;
    background: white;
  }
`;

export const TranscriptIcon = (props: { color: string; border: string; tooltip?: string }) => (
  <TranscriptIconContainer border={props.border} data-tip={props.tooltip} data-place="bottom">
    <Icon color={props.color} icon={Icons.note} size={16} className={'note-icon'} />
    <Icon color={props.color} icon={Icons.micFilled} size={20} className={'mic-icon'} />
  </TranscriptIconContainer>
);

export const evaluateMeetingYear = (meetingStartTime: number, complete?: boolean) => {
  if (!meetingStartTime) {
    return '';
  }
  let yearFormat;
  let monthFormat = 'long';
  if (!complete) {
    yearFormat = new Date(meetingStartTime).getFullYear() === new Date().getFullYear() ? undefined : 'numeric';
    monthFormat = 'short';
  } else {
    yearFormat = 'numeric';
  }
  return <FormattedDate month={monthFormat} day="numeric" year={yearFormat} value={meetingStartTime} />;
};

// todo: Can be deleted after all the data is ingested in the cluster.
export const updateGlobalSearchStartTime = () => {
  const key = 'update-global-search-start-time';
  try {
    const ldFlags = window.localStorage.getItem('@@LaunchDarkly/flags');
    if (ldFlags != null) {
      const parsedLdFlags = JSON.parse(ldFlags);
      if (parsedLdFlags && parsedLdFlags[key]) {
        const numberOfDays = getDiffInDays(+parsedLdFlags[key], new Date());
        const isLeap = isLeapYear(new Date());
        const numberOfDaysInCurrentYear = isLeap ? 366 : 365;
        const lastOneYearDate = getBackdatedValue(new Date(), isLeap ? +1 : 0, 0, -1);

        //get difference between current date and received date. if the difference is greater than 1 year then reset the value back to 1 year
        return numberOfDays > numberOfDaysInCurrentYear ? lastOneYearDate : new Date(+parsedLdFlags[key]);
      }
    }
    return getBackdatedValue(new Date(), 0, -1, 0);
  } catch (e) {
    return getBackdatedValue(new Date(), 0, -1, 0); // in case of error return last 1 month as limit
  }
};
