import { Reducer } from 'redux';
import { Actions, ActionTypes } from './schedulerActions';
import { SchedulerTabs } from '../../../components/Meetings/MeetingScheduler/MeetingScheduler';
import {
  createAudioSettingItemsFromSettings,
  createMeetingSettingsItemsFromSettings,
  MeetingSettings,
  BrandTheme
} from '../../../../models/Meeting';
import { AudioSettingItems } from '../../../../lib/audio';
import { ActionTypes as MeetingActionTypes } from '../meetings/meetingsActions';
import FeatureService from '../../../services/FeatureService';

interface AudioPrivate {
  privateMessage: string;
}

interface AudioPublic {
  tollCountries?: string[];
  tollFreeCountries?: string[];
  dialOutCountries?: string[];
  isDialOut: boolean;
}

export enum AudioType {
  private = 'private',
  voipAndPstn = 'voipAndPstn',
  voip = 'voip',
  pstn = 'pstn',
  none = 'none'
}

export enum MeetingType {
  recurring = 'recurring',
  scheduled = 'scheduled',
  impromptu = 'impromptu'
}

export type Audio =
  | ({
      audioType: AudioType.private;
    } & AudioPrivate)
  | ({
      audioType: AudioType.voipAndPstn | AudioType.voip | AudioType.pstn | AudioType.none;
    } & AudioPublic);

export type AudioRequest = Audio | undefined;

interface OptionalMeetingProperties extends AudioSettingItems {
  startWithoutOrganizerSupported: boolean;
  passwordRequired: boolean;
  coorganizerKeys: Array<{
    key: string;
    email: string;
  }>;
}

interface BasicSchedulerMeeting extends Partial<OptionalMeetingProperties> {
  meetingId?: string;
  subject: string;
  passwordRequired?: boolean;

  personalizeMeeting: boolean;
  roomName?: string;
  theme?: string;
  displayTimeZone: string;
  breakoutsAllowed?: boolean;
}

interface RecurringSchedulerMeeting extends BasicSchedulerMeeting {
  meetingType: MeetingType.recurring;
}

interface ScheduledSchedulerMeeting extends BasicSchedulerMeeting {
  meetingType: MeetingType.scheduled;
  duration: number;
  when: Date;
  starts: Date;
}

interface RecurringMeetingRequest {
  subject: string;
  displayTimeZone: string;
  startWithoutOrganizerSupported: boolean;
  passwordRequired: boolean;
  coorganizerKeys: string[];
  meetingUrl?:
    | {
        profileId: string;
        roomName: string;
        theme: string;
      }
    | {};
  audio: AudioRequest;
  invitationType?: string;
  breakoutsAllowed?: boolean;
}

interface ScheduledMeetingRequest extends RecurringMeetingRequest {
  scheduledStartTime: Date;
  scheduledEndTime: Date;
}

export type SchedulerMeeting = ScheduledSchedulerMeeting | RecurringSchedulerMeeting;

export type SchedulerFullMeeting = BasicSchedulerMeeting & {
  meetingType: MeetingType.scheduled | MeetingType.recurring;
  duration: number;
  when: Date;
  starts: Date;
};

export type MeetingRequestFull =
  | ({
      meetingType: MeetingType.scheduled;
    } & ScheduledMeetingRequest)
  | ({
      meetingType: MeetingType.recurring;
    } & RecurringMeetingRequest);

export type MeetingRequest = Partial<MeetingRequestFull>;

export interface PropertyState {
  tab: SchedulerTabs;
  state: 'success' | 'error';
}

export interface Scheduler {
  isNew: boolean;
  isLoadingMeeting: boolean;
  isLoadingSettings: boolean;
  isUpdating: boolean;
  initialTab: SchedulerTabs;

  settings?: MeetingSettings;

  meetingId: string;
  meeting: {
    initial: SchedulerMeeting;
    update: SchedulerMeeting;
  };

  propertyState: {
    [index in keyof SchedulerFullMeeting]?: PropertyState;
  };
}

export type State = Readonly<{
  current: null | Scheduler;
  themes: BrandTheme[];

  interstitial: {
    show: boolean;
    isFetchingInvitation?: boolean;
    skip?: boolean;
  };

  lastCreatedMeetingId?: string;
  hasSettings?: boolean;
  settings: {
    hideInterstitial: boolean;
  };
}>;

export const defaultState: State = {
  current: null,
  themes: [],
  interstitial: {
    show: false,
    skip: true
  },
  settings: {
    hideInterstitial: false
  }
};

const reducer: Reducer<State, Actions> = (state = defaultState, action) => {
  if ((action.type as any) == MeetingActionTypes.PROFILE_FETCHED) {
    return {
      ...state,
      interstitial: {
        ...state.interstitial,
        skip: !(action as any).payload.profile
      }
    };
  }

  switch (action.type) {
    case ActionTypes.SCHEDULER_MEETING_CREATE_SHOW:
      return {
        ...state,
        interstitial: {
          show:
            !state.settings.hideInterstitial &&
            !state.interstitial.skip &&
            FeatureService.isEnabled('show-scheduler-interstitial'),
          skip: state.interstitial.skip
        },
        current: {
          isNew: true,
          isLoadingMeeting: false,
          isLoadingSettings: false,
          isUpdating: false,
          hasSettings: false,
          initialTab: SchedulerTabs.meeting,

          meetingId: '',
          meeting: {
            initial: action.payload.meeting,
            update: action.payload.meeting
          },
          propertyState: {}
        }
      };

    case ActionTypes.SCHEDULER_MEETING_EDIT_SHOW:
      return {
        ...state,
        current: {
          isNew: false,
          isLoadingMeeting: false,
          isLoadingSettings: false,
          isUpdating: false,
          hasSettings: false,
          initialTab: action.payload.tab,
          meetingId: action.payload.meetingId,
          meeting: {
            initial: action.payload.meeting,
            update: action.payload.meeting
          },
          propertyState: {}
        }
      };

    case ActionTypes.MEETING_SCHEDULE_HIDE:
      return { ...state, current: null };

    case ActionTypes.SCHEDULER_FETCH_INVITATION:
      return {
        ...state,
        interstitial: {
          ...state.interstitial,
          isFetchingInvitation: true
        }
      };

    case ActionTypes.SCHEDULER_HIDE_INTERSTITIAL:
      return {
        ...state,
        interstitial: {
          show: false,
          skip: state.interstitial.skip
        },
        current: action.payload.showScheduler ? state.current : null,
        settings: {
          ...state.settings,
          hideInterstitial: action.payload.hideAlways
        }
      };
  }

  if (!state.current) {
    return state;
  }

  switch (action.type) {
    case ActionTypes.FETCH_MEETING_SETTINGS:
      return {
        ...state,
        current: {
          ...state.current,
          isLoadingSettings: true
        }
      };

    case ActionTypes.FETCH_MEETING:
      return {
        ...state,
        current: {
          ...state.current,
          isLoadingMeeting: true
        }
      };

    case ActionTypes.INITIALIZE_MEETING:
      return {
        ...state,
        hasSettings: true,
        current: {
          ...state.current,
          isLoadingMeeting: false,
          isLoadingSettings: false,
          settings: action.payload.settings,

          meeting: {
            initial: action.payload.meeting,
            update: action.payload.meeting
          }
        }
      };

    case ActionTypes.INITIALIZE_MEETING_SETTINGS:
      const audioSettings = createAudioSettingItemsFromSettings(action.payload.settings);
      const meetingSettings = createMeetingSettingsItemsFromSettings(action.payload.settings);
      return {
        ...state,
        hasSettings: true,
        current: {
          ...state.current,
          isLoadingSettings: false,
          settings: action.payload.settings,
          meeting: {
            update: { ...state.current.meeting.update, ...audioSettings, ...meetingSettings },
            initial: { ...state.current.meeting.initial, ...audioSettings, ...meetingSettings }
          }
        }
      };

    case ActionTypes.INITIALIZE_MEETING_ERROR:
      return {
        ...state,
        hasSettings: false,
        current: null
      };

    case ActionTypes.INITIALIZE_MEETING_SETTINGS_ERROR:
      return {
        ...state,
        current: {
          ...state.current,
          isLoadingSettings: false
        }
      };

    case ActionTypes.SCHEDULER_CHANGE_PROPERTY:
      return {
        ...state,
        current: {
          ...state.current,
          meeting: {
            ...state.current.meeting,
            update: {
              ...state.current.meeting.update,
              [action.payload.property]: action.payload.value
            }
          }
        }
      };

    case ActionTypes.SCHEDULER_VALIDATION_SUCCESS:
    case ActionTypes.SCHEDULER_VALIDATION_ERROR:
      return {
        ...state,
        current: {
          ...state.current,
          propertyState: {
            ...state.current.propertyState,
            [action.payload.property]: {
              tab: action.payload.tab,
              state: action.type === ActionTypes.SCHEDULER_VALIDATION_SUCCESS ? 'success' : 'error'
            }
          }
        }
      };

    case ActionTypes.SCHEDULER_MEETING_CREATING:
      return {
        ...state,
        current: {
          ...state.current,
          isUpdating: true
        }
      };

    case ActionTypes.SCHEDULER_MEETING_CREATE_ERROR:
      return {
        ...state,
        current: {
          ...state.current,
          isUpdating: false
        }
      };

    case ActionTypes.SCHEDULER_MEETING_CREATED:
      return {
        ...state,
        current: null,
        lastCreatedMeetingId: action.payload.meetingId
      };

    case ActionTypes.SCHEDULER_MEETING_UPDATING:
      return {
        ...state,
        current: {
          ...state.current,
          isUpdating: true
        }
      };

    case ActionTypes.SCHEDULER_MEETING_UPDATE_ERROR:
      return {
        ...state,
        current: {
          ...state.current,
          isUpdating: false
        }
      };

    case ActionTypes.SCHEDULER_MEETING_UPDATED:
      return {
        ...state,
        current: null
      };

    default:
      return state;
  }
};

export default reducer;
