import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import UserMenuContainer from '../../../containers/UserMenuContainer';
import { getRouteFor, matchBase } from '../../../services/NavService';
import AdditionalItemsContainer from '../../../containers/AdditionalItemsContainer';
import { BulbActive, Chat, Meetings, Settings } from '../../Common/Toolkit/Icon/ReactIcons';
import {
  AdditionalItemsWrapper,
  AppMenuUnreadCount,
  AppMenuDiv,
  AppMenuItem,
  AppMenuList,
  AppMenuWrapper,
  ImageBadgeFrame,
  LinkItemText,
  MainContent,
  MenuNavLink,
  AppMenuItemContainer,
  DiscoverMenuItem
} from './AppMenuComponents';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../view-model/types';
import sharedActions from '../../../view-model/sharedActions';
import { useHistory } from 'react-router';
import { getDiscoverUnreadCount } from '../../../view-model/selectors';

const messages = defineMessages({
  chats: {
    id: 'appMenu.chats',
    defaultMessage: 'Chats'
  },
  meetings: {
    id: 'appMenu.meetings',
    defaultMessage: 'Meetings'
  },
  settings: {
    id: 'appMenu.settings',
    defaultMessage: 'Settings'
  },
  new: {
    id: 'appMenu.new',
    defaultMessage: 'NEW'
  },
  discover: {
    id: 'appMenu.discovery',
    defaultMessage: 'Discover'
  },
  navigation: {
    id: 'appMenu.navigation',
    defaultMessage: 'Main Navigation'
  }
});

interface Props {
  children?: React.ReactElement<any>;
  chatUnreadCount?: number;
}

export const AppMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const enableMyMeetings = useSelector((state: State) => state.core.user.hasLoaded && state.core.user.meetNow);
  const disabledChat = useSelector((state: State) => !state.core.app.features.includes('chat'));
  const disabledDiscover = useSelector(
    (state: State) => !state.core.app.features.includes('show-discover-menu-button')
  );
  const discoverUnreadCount = useSelector((state: State) => getDiscoverUnreadCount(state));

  const reportNavigation = (event: React.MouseEvent) => {
    const element = event.currentTarget as HTMLAnchorElement;
    if (`#${history.location.pathname}` !== element.hash) {
      dispatch(sharedActions.navigationByClick(element.hash));
    }
  };

  const renderChats = () => {
    return disabledChat ? null : (
      <AppMenuItem data-testid="chats-menu-item">
        <MenuNavLink to={matchBase(getRouteFor('chat'))} title={f(messages.chats)} onClick={reportNavigation}>
          <ImageBadgeFrame>
            <Chat size={24} unit={'px'} color={'#fff'} />
            <AppMenuUnreadCount count={props.chatUnreadCount} testId="unread-count" />
          </ImageBadgeFrame>
          <LinkItemText>{f(messages.chats)}</LinkItemText>
        </MenuNavLink>
      </AppMenuItem>
    );
  };

  const renderMyMeetings = () => {
    return !enableMyMeetings ? null : (
      <AppMenuItem data-testid="meetings-menu-item">
        <MenuNavLink to={matchBase(getRouteFor('meetings'))} title={f(messages.meetings)} onClick={reportNavigation}>
          <Meetings size={24} unit="px" color={'#fff'} />
          <LinkItemText>{f(messages.meetings)}</LinkItemText>
        </MenuNavLink>
      </AppMenuItem>
    );
  };

  const renderSettings = () => (
    <AppMenuItem data-testid="settings-menu-item">
      <MenuNavLink to={matchBase(getRouteFor('settings'))} title={f(messages.settings)} onClick={reportNavigation}>
        <Settings size={24} unit={'px'} color={'#fff'} />
        <LinkItemText>{f(messages.settings)}</LinkItemText>
      </MenuNavLink>
    </AppMenuItem>
  );

  const renderDiscover = () => {
    return disabledDiscover ? null : (
      <DiscoverMenuItem data-testid="discover-menu-item">
        <MenuNavLink to={getRouteFor('discover')} title={f(messages.discover)} onClick={reportNavigation}>
          <ImageBadgeFrame>
            <BulbActive size={24} unit={'px'} color={'#fff'} />
            <AppMenuUnreadCount count={discoverUnreadCount} testId="unread-count" />
          </ImageBadgeFrame>
          <LinkItemText>{f(messages.discover)}</LinkItemText>
        </MenuNavLink>
      </DiscoverMenuItem>
    );
  };

  return (
    <AppMenuWrapper>
      <AppMenuDiv role="navigation" aria-label={f(messages.navigation)}>
        <AppMenuItemContainer>
          <UserMenuContainer />
          <AppMenuList>
            {renderMyMeetings()}
            {renderChats()}
            {renderSettings()}
            {renderDiscover()}
          </AppMenuList>
          <AdditionalItemsWrapper>
            <AdditionalItemsContainer />
          </AdditionalItemsWrapper>
        </AppMenuItemContainer>
      </AppMenuDiv>
      <MainContent>{props.children}</MainContent>
    </AppMenuWrapper>
  );
};
