import * as React from 'react';
import styled from '../../../styled-components';
import { Box } from '../Toolkit/Box/Box';
import device from '../Toolkit/devices';
import { FormattedMessage } from 'react-intl';
import { errorMessages } from '../ErrorPages/ErrorPages';
import { PrimaryButton } from '../Toolkit/Button/PrimaryButton';

const Title = styled.h2`
  font-size: 28px;
  font-family: 'lato-semibold';
  font-weight: 600;
  margin: 0;
  padding-bottom: 14px;
  color: rgba(37, 40, 45, 0.67);
  line-height: 1.2;
  text-align: center;

  @media ${device.mobile} {
    font-size: 20px;
  }
`;

const Container = styled.div`
  padding: 24px 32px 24px 29px;
  height: 500px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    padding: 16px 20px;
  }

  @media ${device.mobile} {
    padding: 16px;
    margin-top: 0px;
    max-height: 300px;
    overflow: scroll;

    button {
      font-size: 12px;
    }
  }
`;

interface State {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

interface Props {
  children: React.ReactNode;
}

export class MeetingDetailsErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
    errorInfo: null
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      return (
        <Container>
          <Box flexDirection="column" justifyContent="center" alignItems="center">
            <Title>
              <FormattedMessage {...errorMessages.renderErrorTitle} />
            </Title>
            <PrimaryButton onClick={() => window?.location.reload()}>
              <FormattedMessage {...errorMessages.tryAgainButtonLabel} />
            </PrimaryButton>
          </Box>
        </Container>
      );
    }
    return this.props.children;
  }
}
