import { Theme } from './theme';

export const palette = {
  purple: {
    100: '#e6d9ff',
    200: '#d5bfff',
    300: '#ac80ff',
    400: '#9b66ff',
    500: '#7b44e4',
    600: '#6739bf'
  },
  blue: {
    100: '#d9e9ff',
    200: '#bfdaff',
    300: '#80b5ff',
    400: '#4090ff',
    500: '#006aff',
    600: '#004fbd'
  },
  green: {
    100: '#d9fff2',
    200: '#80ffd2',
    300: '#00cc85',
    400: '#00aa6e',
    500: '#008858',
    600: '#166549'
  },
  yellow: {
    100: '#fff7d9',
    200: '#ffe88c',
    300: '#ffcb00',
    400: '#d9ad00',
    500: '#b38f00',
    600: '#997a00'
  },
  orange: {
    100: '#feedde',
    200: '#ffc48c',
    300: '#f68d2e',
    400: '#db781c',
    500: '#b35f11',
    600: '#8c4400'
  },
  red: {
    100: '#fee2e8',
    200: '#fabcc8',
    300: '#fd94a9',
    400: '#fd4c6f',
    500: '#e02d52',
    600: '#a12e45'
  },
  neutral: {
    50: '#f7f8fa',
    100: '#f0f2f5',
    150: '#dee4ec',
    200: '#ced8e3',
    250: '#b3bfcf',
    300: '#9aa8bb',
    350: '#828e9e',
    400: '#6b7582',
    450: '#5b646f',
    500: '#4c535d',
    550: '#383d45',
    600: '#25282d'
  },
  oldBlue: {
    300: '#00b6ff',
    500: '#309ddc',
    600: '#1c72a4'
  },
  oldOrange: {
    600: '#f35d02'
  }
};

export const oldHubTheme: Theme = {
  colors: {
    background: palette.neutral[100],
    backgroundOn: palette.neutral[500],
    primary: palette.blue[500],
    primaryOn: 'white',
    secondary: palette.orange[300],
    secondaryOn: 'white',
    primaryScale: palette.blue,
    surface: 'white',
    surfaceOn: palette.neutral[600],
    hover: {
      primary: palette.blue[600],
      primaryOn: 'white'
    },
    neutralScale: palette.neutral,
    modes: {
      dark: {
        background: 'black',
        backgroundOn: 'white',
        primary: 'blue',
        primaryOn: 'white',
        surface: 'darkgrey',
        surfaceOn: 'white',
        hover: {
          primary: 'lightblue',
          primaryOn: 'white'
        },
        info: {
          primary: 'pink',
          primaryOn: 'white'
        }
      }
    },
    info: {
      primary: palette.blue[500],
      primaryOn: 'white'
    },
    warning: {
      primary: palette.yellow[500],
      primaryOn: 'white'
    },
    danger: {
      primary: palette.red[500],
      primaryOn: 'white',
      primaryScale: palette.red
    },
    success: {
      primary: palette.green[500],
      primaryOn: 'white'
    }
  },
  toast: {
    colors: {
      primary: palette.green[300],
      primaryOn: 'white',
      success: {
        primary: palette.green[300],
        primaryOn: 'white'
      },
      warning: {
        primary: palette.yellow[300],
        primaryOn: 'white'
      },
      danger: {
        primary: palette.red[500],
        primaryOn: 'white'
      },
      modes: {
        dark: {
          primary: 'black',
          primaryOn: 'white'
        }
      }
    }
  },
  link: {
    colors: {
      primary: palette.blue[500],
      primaryOn: 'white'
    }
  },
  badge: {
    colors: {
      primary: palette.orange[100],
      primaryOn: palette.orange[600]
    }
  }
};
