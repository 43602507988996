import * as React from 'react';
import './UploadButton.css';
import { injectIntl, WrappedComponentProps, defineMessages } from 'react-intl';

export const messages = defineMessages({
  uploadLabel: {
    id: 'common.upload.uploadLabel',
    defaultMessage: 'Upload file'
  }
});

interface Props {
  disabled?: boolean;
  uploadFiles: (files: File[]) => void;
}

class UploadButton extends React.PureComponent<Props & WrappedComponentProps, {}> {
  handleClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  handleFiles: (e: { target: { files: any; value: any } }) => void;
  fileInput: any;

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.handleClick = () => {
      if (this.fileInput) {
        this.fileInput.click();
      }
    };

    this.handleFiles = (event) => {
      this.props.uploadFiles(event.target.files);
      // clear file picker to enable re-uploading same files (e.g. on error)
      event.target.value = null;
    };
  }

  render() {
    const { disabled = false, intl } = this.props;

    return (
      <button
        id="upload-button"
        data-testid="upload-button"
        className="btn btn-icon upload-button"
        disabled={disabled}
        onClick={this.handleClick}
        type="button"
        aria-label={intl.formatMessage(messages.uploadLabel)}
      >
        <i className="togo-icon togo-icon-clip" />
        <input
          data-testid="upload-input"
          disabled={disabled}
          id="fileInput"
          multiple
          onChange={this.handleFiles}
          ref={(elm) => (this.fileInput = elm)}
          type="file"
          aria-labelledby="upload-button"
        />
      </button>
    );
  }
}

export default injectIntl(UploadButton);
