import React from 'react';
import { ModalDialog } from '../../Common/ModalDialog/ModalDialog';
import ProfilePreview from './ProfilePreview';

interface Props {
  hideProfilePreview: () => void;
  theme: string;
  userKey: string;
}

export const ProfilePreviewModal: React.FC<Props> = (props: Props) => (
  <ModalDialog contentLabel="ProfilePreview" shouldCloseOnOverlayClick onClose={props.hideProfilePreview}>
    <ProfilePreview {...props} />
  </ModalDialog>
);
