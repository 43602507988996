import * as React from 'react';
import { Time } from '../../Meetings/AttendeeList';
import styled from '../../../styled-components';
import { Box } from '../../Common/Toolkit/Box/Box';
import { Avatar, generateRandomColors } from '../GlobalSearchComponents';
import config from 'config';
import { DiagnosticsButton } from '../../Meetings/DiagnosticsButton';
import { useIntl } from 'react-intl';
import { GlobalSearchMessages as messages } from '../GlobalSearchMessages';
import device from '../../Common/Toolkit/devices';

const AttendeeTable = styled.table`
  width: 100%;
  border-radius: 4px;
  border-spacing: 0;
  margin-top: 6px;
  border-bottom: 1px solid #e0e4ea;

  th {
    font-size: 12px;
    text-align: left;
    color: #58585b;
    font-weight: 600;
    line-height: 1.4;
  }

  td,
  th {
    padding: 16px 0px;

    &:last-child {
      padding: 16px 32px;
    }

    &:first-child {
      padding-left: 13px;
    }
  }

  td {
    font-size: 14px;
  }

  thead {
    th {
      border: 0;
    }
  }

  td,
  th {
    border-top: 1px solid #e0e4ea;
  }
`;

const AttendeeTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 4px;
`;

const AttendeeName = styled.div`
  font-size: 14px;
  color: #25282d;
  line-height: 1.4;
  text-transform: capitalize;
  margin-bottom: 0.4px;
`;
const AttendeeEmail = styled.div`
  font-size: 12px;
  line-height: 1.4;
  color: #58585b;
`;

const AttendeeContainer = styled.div`
  margin-top: 38px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DiagnosticsContainer = styled(DiagnosticsButton)`
  border-radius: 4px;
  border: solid 1px rgba(76, 83, 93, 0.24);
  @media ${device.mobile} {
    padding: 0 20px;
  }
`;

interface Props {
  attendeeList: any;
  sessionId: string;
}

export const AttendeeView: React.FC<Props> = (props: Props) => {
  const { formatMessage: f } = useIntl();
  const getLocation = (locations: Array<any>) => {
    let location;
    if (locations && locations.length > 0) {
      location = locations && locations.length > 0 && locations[0].location;
    }
    return location ? location : '-';
  };

  const view = (
    <AttendeeContainer>
      <Header>
        <AttendeeTitle>{f(messages.attendees)}</AttendeeTitle>
        <DiagnosticsContainer
          targetUrl={`${config.externalLinks.meetingDiagnostics}/#/sessions/${props.sessionId}`}
          isLive={true}
        />
      </Header>
      <AttendeeTable>
        <thead>
          <tr>
            <th>{f(messages.attendee)}</th>
            <th>{f(messages.joinLeaveTime)}</th>
            <th>{f(messages.location)}</th>
          </tr>
        </thead>
        <tbody>
          {props.attendeeList?.length > 0 &&
            props.attendeeList.map((attendee: any, index: any) => (
              <tr key={`${attendee.name}_${index}`}>
                <td>
                  <Box alignItems={'center'}>
                    <Avatar background={generateRandomColors(attendee.name?.substring(0, 2))}>
                      {attendee.name?.substring(0, 2)}
                    </Avatar>
                    <div>
                      <AttendeeName>{attendee.name}</AttendeeName>
                      <AttendeeEmail>{attendee.email}</AttendeeEmail>
                    </div>
                  </Box>
                </td>
                <td>
                  <Time joinTime={attendee.joinTime?.toString()} leaveTime={attendee.leaveTime?.toString()} />
                </td>
                <td>{getLocation(attendee.location)}</td>
              </tr>
            ))}
        </tbody>
      </AttendeeTable>
    </AttendeeContainer>
  );

  return <>{view}</>;
};
