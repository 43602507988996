import platform from 'platform';
import { IFeature } from '@getgo/featureflip';
import { LaunchDarklyFlags } from '../app/services/LaunchDarklyService';
import { Entitlement, Settings } from '../app/view-model/modules/core/user/userReducer';
import { Client } from '../app/view-model/modules/core/preferences/preferencesReducer';
import { UserWithPreferences } from '../app/view-model/sharedActions';

interface FeatureData {
  browser: {
    name: string;
    version: string;
    ua: string;
  };
  env: 'development' | 'ed' | 'test' | 'rc' | 'stage' | 'prod';
  user?: UserWithPreferences;
  client?: Client;
  launchDarklyFlags?: LaunchDarklyFlags;
}

const getOsFamily = (data: FeatureData) => {
  const parsed = platform.parse && platform.parse(data.browser.ua);
  if (!parsed || !parsed.os || !parsed.os.family) {
    return null;
  }

  return parsed.os.family;
};

const isAndroid = (data: FeatureData) => getOsFamily(data) === 'Android';
const isIOS = (data: FeatureData) => getOsFamily(data) === 'iOS';
const isExternalContactsEnabled = (data: FeatureData) => !!(data.user && data.user.externalContacts);
const isGotomeetingEnabled = (data: FeatureData) => !!(data.user && data.user.meetNow);
const isFileSharingEnabled = (data: FeatureData) => !!(data.user && data.user.fileSharing);
const hasEntitlement = (data: FeatureData, entitlement: Entitlement) =>
  !!(data.user && data.user.entitlements && data.user.entitlements.includes(entitlement));
const supportsDraftJs = (data: FeatureData) => !isAndroid(data) && !isIOS(data); // https://draftjs.org/docs/advanced-topics-issues-and-pitfalls.html#mobile-not-yet-supported
const hasChat = (data: FeatureData) => !!(data.user && data.user.chat);
const settingsEnabled = (data: FeatureData, keys: Array<keyof Settings>) =>
  !!(data.user && data.user.settings && keys.every((setting) => !!(data.user as any).settings[setting]));
const getLDFlag = <K extends keyof LaunchDarklyFlags>(data: FeatureData, key: K, defaultValue: boolean) => {
  if (!data.launchDarklyFlags || data.launchDarklyFlags[key] == null) {
    return defaultValue;
  }
  return !!data.launchDarklyFlags[key];
};

const createLDRule = <K extends keyof LaunchDarklyFlags>(key: K, defaultValue: boolean): IFeature<FeatureData> => ({
  name: key,
  rule(data) {
    return getLDFlag(data, key, defaultValue);
  }
});

const rules: Array<IFeature<FeatureData>> = [
  {
    name: 'external_contacts',
    rule(data) {
      return isExternalContactsEnabled(data);
    }
  },
  {
    name: 'file_sharing',
    rule(data) {
      return isFileSharingEnabled(data);
    }
  },
  {
    name: 'gotomeeting',
    rule(data) {
      return isGotomeetingEnabled(data);
    }
  },
  {
    name: 'hasG2ARSEntitlement',
    rule(data) {
      return hasEntitlement(data, 'g2ars');
    }
  },
  {
    name: 'hasG2TEntitlement',
    rule(data) {
      return hasEntitlement(data, 'g2t');
    }
  },
  {
    name: 'hasG2WEntitlement',
    rule(data) {
      return hasEntitlement(data, 'g2w');
    }
  },
  {
    name: 'hasOpenVoiceEntitlement',
    rule(data) {
      return hasEntitlement(data, 'openvoice');
    }
  },
  {
    name: 'hasAccountAdminEntitlement',
    rule(data) {
      return hasEntitlement(data, 'acctadmin');
    }
  },
  {
    name: 'hasAtLeastOneSupportMenuEntitlement',
    rule(data) {
      return (
        hasEntitlement(data, 'g2ars') ||
        hasEntitlement(data, 'g2t') ||
        hasEntitlement(data, 'g2w') ||
        hasEntitlement(data, 'openvoice')
      );
    }
  },
  {
    name: 'cbrDownloadRecording',
    rule(data) {
      return settingsEnabled(data, ['downloadrecordingentitled']);
    }
  },
  {
    name: 'cbrDeleteRecording',
    rule(data) {
      return settingsEnabled(data, ['deleterecordingentitled']);
    }
  },
  {
    name: 'cbrTranscripts',
    rule(data) {
      return settingsEnabled(data, ['transcriptsenabled', 'transcriptsentitled', 'transcriptsprovisioned']);
    }
  },
  {
    name: 'cbrContentSearch',
    rule(data) {
      return settingsEnabled(data, ['contentsearchenabled', 'contentsearchentitled', 'contentsearchprovisioned']);
    }
  },
  {
    name: 'richTextInput',
    rule(data) {
      return supportsDraftJs(data);
    }
  },
  {
    name: 'chat',
    rule(data) {
      return hasChat(data);
    }
  },
  {
    name: 'messageEditing',
    rule(data) {
      return supportsDraftJs(data);
    }
  },
  createLDRule('show-scheduler-interstitial', false),
  createLDRule('meeting-hub-enabled', false),
  createLDRule('show-live-diagnostics-button', false),
  createLDRule('show-discover-menu-button', false),
  createLDRule('show-elastic-search-view', false),
  createLDRule('show-elastic-search-account', false),
  createLDRule('meeting-title-edit-enabled', false),
  createLDRule('hub-branding-account-themes-enabled', false)
];

export default rules;
