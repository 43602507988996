import featureService from '../../services/FeatureService';
import appActions from '../modules/core/app/appActions';
import { ActionTypes as SharedActionTypes } from '../sharedActions';
import { Middleware } from '../types';

const featureServiceMiddleware: Middleware = (store) => (next) => (action) => {
  let update = null;
  switch (action.type) {
    case SharedActionTypes.UPDATE_USER:
      update = { user: action.payload };
      break;
  }

  const result = next(action);

  // synchronize with feature service
  if (update) {
    featureService.setData(update);
    const features = featureService.enabled;
    store.dispatch(appActions.updateFeatures(features));
  }

  return result;
};

export default featureServiceMiddleware;
