import { ActionsUnion, createAction } from '../../../type-helpers';
import { AuthService } from '../../../../services/auth';
import { PushNotificationService } from '../../../../services/PushNotificationService';
import loggerService from '../../../../services/LoggerService';
import config from 'config';
import { Thunk } from '../../../type-helpers';

export enum ActionTypes {
  START_LOGOUT = 'START_LOGOUT'
}

const actions = {
  startLogout: () => createAction(ActionTypes.START_LOGOUT)
};

export default actions;

export type Actions = ActionsUnion<typeof actions>;

const auth = new AuthService(config.oauth);
const logger = loggerService.create('UserActions');

const logoutWebApp: Thunk = async (_, getState) => {
  if (PushNotificationService.isSupported()) {
    const registration = getState().core.notifications.pushRegistration;
    try {
      await PushNotificationService.unsubscribe(registration);
    } catch (err) {
      logger.error('logout', 'err=', err);
    }
  }

  return auth.logout();
};

export const logout = (): Thunk => (dispatch, getState) => {
  dispatch(actions.startLogout());
  return logoutWebApp(dispatch, getState, undefined);
};
