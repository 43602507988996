import React from 'react';
import device from '../Common/Toolkit/devices';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetElasticFetchResults,
  selectMeeting,
  triggerTelemetryElasticSearch
} from '../../view-model/modules/meetings/meetingsActions';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { GlobalSearchMessages as messages } from './GlobalSearchMessages';
import { State } from '../../view-model/types';
import { RightArrow, Search } from '../Common/Toolkit/Icon/ReactIcons';
import { isMobile } from '../../services/ClientService';
import { SelectableItemType } from '../../view-model/modules/meetings/MeetingsTypes';

const StyledInput = styled.input`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  padding: 0px 36px;
  height: 36px;
  line-height: 36px;
  border: 1px solid rgba(76, 83, 93, 0.16);
  border-radius: 4px;
  background-color: rgba(37, 40, 45, 0.04);
  font-family: 'lato-regular';
  font-size: 16px;
  flex-grow: 2;

  @media ${device.mobile} {
    height: 32px;
    line-height: 32px;
  }
  &:focus {
    outline: none;
  }
`;
const SearchFieldContainer = styled.div`
  display: flex;
  margin: 12px;
  padding: 2px;
  border-bottom: 1px solid #9aa8ba;
  padding-bottom: 20px;
  position: relative;
`;

const SearchIcon = styled((props: { className?: string }) => {
  return <Search className={props.className} data-testid="search-icon" size={20} unit={'px'} />;
})`
  position: absolute;
  top: 5px;
  left: 6px;
`;

const ArrowIcon = styled((props: { className?: string }) => {
  return <RightArrow className={props.className} data-testid="search-icon" size={20} unit={'px'} />;
})`
  position: absolute;
  top: 5px;
  right: 9px;
`;

const GlobalSearchInput: React.FC<WrappedComponentProps> = (props) => {
  const selectedItemType = useSelector((state: State) => state.meetings.selectedItemType);
  const dispatch = useDispatch();
  const { formatMessage: f } = props.intl;
  const transcriptFlag =
    selectedItemType === SelectableItemType.ELASTICSEARCH ||
    selectedItemType === SelectableItemType.ELASTICSEARCHRESULTSELECTED;
  const placeHolderText = transcriptFlag ? f(messages.searching) : f(messages.search);
  const icon = transcriptFlag ? <ArrowIcon /> : <SearchIcon />;

  const searchBoxClicked = () => {
    dispatch(triggerTelemetryElasticSearch('globalSearchClicked'));
    dispatch(resetElasticFetchResults());
    dispatch(selectMeeting(SelectableItemType.ELASTICSEARCH, ''));
  };

  return (
    <SearchFieldContainer data-testid="elastic-search-input">
      {icon}
      <StyledInput
        type="text"
        placeholder={placeHolderText}
        onClick={searchBoxClicked}
        disabled={transcriptFlag}
        aria-label={f(messages.search)}
        onFocus={isMobile ? undefined : searchBoxClicked} // remove onFocus on mobile to fix handleClickOutside issue
      />
    </SearchFieldContainer>
  );
};

export default injectIntl(GlobalSearchInput);
