import { ContentChatItem, ContentChatResponse, ChatListItem } from './meetingsReducer';

export const extractPublicChat = (response: Readonly<ContentChatResponse>): ChatListItem[] | [] => {
  if (!response?.chat?.length) {
    return [];
  }
  return response?.chat
    ?.filter((chatItem: ContentChatItem) => chatItem?.content?.group === 'all')
    .map((chatItem: ContentChatItem) => {
      const {
        timestamp,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        content: { receiveTime, group, authorId, ...rest }
      } = chatItem;
      return {
        id: `${authorId}_${timestamp}`,
        authorId,
        ...rest
      };
    });
};
