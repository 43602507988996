import { ActionsUnion, Thunk, createAction } from '../../../type-helpers';
import * as React from 'react';
import uuid from 'uuid';
import { ToastOptions } from './notificationsReducer';

export enum ActionTypes {
  ACCEPT_DOUBLE_BANNER = 'ACCEPT_DOUBLE_BANNER',
  REJECT_DOUBLE_BANNER = 'REJECT_DOUBLE_BANNER',
  NOTIFICATION_PERMISSION_UPDATED = 'NOTIFICATION_PERMISSION_UPDATED',
  PUSH_REGISTRATION_UPDATED = 'PUSH_REGISTRATION_UPDATED',
  PUSH_REGISTRATION_REMOVED = 'PUSH_REGISTRATION_REMOVED',

  SHOW_TOAST_NOTIFICATION = 'SHOW_TOAST_NOTIFICATION',
  HIDE_TOAST_NOTIFICATION = 'HIDE_TOAST_NOTIFICATION'
}

const actions = {
  acceptDoubleBanner: (timestamp: number) => createAction(ActionTypes.ACCEPT_DOUBLE_BANNER, { timestamp }),
  rejectDoubleBanner: (timestamp: number) => createAction(ActionTypes.REJECT_DOUBLE_BANNER, { timestamp }),
  notificationPermissionUpdated: (permission: NotificationPermission) =>
    createAction(ActionTypes.NOTIFICATION_PERMISSION_UPDATED, permission),
  pushRegistrationUpdated: (referenceId: string, endpoint: string, userKey: string) =>
    createAction(ActionTypes.PUSH_REGISTRATION_UPDATED, { referenceId, endpoint, userKey }),
  pushRegistrationRemoved: () => createAction(ActionTypes.PUSH_REGISTRATION_REMOVED),

  showToastNotification: (id: string, content: React.ReactNode, options: ToastOptions) =>
    createAction(ActionTypes.SHOW_TOAST_NOTIFICATION, { id, content, options }),
  hideToastNotification: (id: string) => createAction(ActionTypes.HIDE_TOAST_NOTIFICATION, id)
};

export default actions;

export type Actions = ActionsUnion<typeof actions>;

export const showToastNotification = (content: React.ReactNode, options?: ToastOptions): Thunk => (dispatch) => {
  return dispatch(actions.showToastNotification(uuid.v4(), content, options || {}));
};
