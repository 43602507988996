import telemetryClient from '@getgo/telemetry-client';
import transportFactory from '@getgo/telemetry-client/lib/fetch-buffered-queue';
import uuid from 'uuid';
import machine from '../../lib/machine';
import config from 'config';
import { buildOsUserData } from '../../lib/user-data-utility';

class TelemetryService {
  private ticker: number;
  event: (name: string, attributes?: object) => Promise<any>;
  flush: () => void;
  setGlobalAttribute: (name: string, value: any) => void;

  constructor(telemetryConfig: EnvConfig['telemetry']) {
    const telemetryOptions = {
      ev_source: telemetryConfig.ev_source.web,
      id_dev: machine.id,
      id_run: uuid.v4(),

      attributes: {
        ev_namespace: telemetryConfig.ev_namespace,

        ...buildOsUserData()
      }
    };

    const transport = transportFactory.createTransport(`${telemetryConfig.url}/reportEvent`, telemetryOptions);
    const telemetry = telemetryClient.createClient(transport.add.bind(transport), telemetryOptions);

    this.event = telemetry.event;
    this.flush = transport.flush;
    this.setGlobalAttribute = telemetry.setGlobalAttribute;

    this.ticker = window.setInterval(() => this.flush(), 10000);
  }

  destroy() {
    window.clearInterval(this.ticker);
  }
}

export default new TelemetryService(config.telemetry);
