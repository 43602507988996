import * as React from 'react';
import { AnytimeMeetingsList } from './AnytimeMeetingsList';
import { MeetingsTabSpinner } from '../MeetingComponents';
import { User } from 'src/app/view-model/modules/core/user/userReducer';
import {
  ImpromptuMeeting,
  RecurringMeeting,
  SelectableItemType
} from '../../../view-model/modules/meetings/MeetingsTypes';

interface Props {
  isOnline: boolean;
  isFetching: boolean;
  location: Location;
  recurringMeetings: RecurringMeeting[];
  runningImpromptuMeeting?: ImpromptuMeeting;
  fetchMyMeetings: () => void;
  openScheduler: (user: User) => void;
  reportSchedulerEndpointAccess: () => void;
  meetNow: () => void;
  profileMeeting: RecurringMeeting | null;
  selectedId: string | null;
  selectedItemType: SelectableItemType;
  selectMeeting?: (itemType: SelectableItemType, id?: string) => void;
  user: User;
}

export class AnytimeMeetings extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchMyMeetings();
    if (this.props.location.pathname == '/schedule') {
      this.props.openScheduler(this.props.user);
      this.props.reportSchedulerEndpointAccess();
    }
  }

  componentDidUpdate(prevProps: Props) {
    // refresh meeting history when coming back online
    if (this.props.isOnline && !prevProps.isOnline) {
      this.props.fetchMyMeetings();
    }
  }

  render() {
    const { profileMeeting } = this.props;
    let { recurringMeetings } = this.props;
    if (profileMeeting) {
      recurringMeetings = recurringMeetings.filter((meeting) => profileMeeting.meetingId !== meeting.meetingId);
    }

    const meetingsList = (
      <AnytimeMeetingsList
        recurringMeetings={recurringMeetings}
        runningImpromptuMeeting={this.props.runningImpromptuMeeting}
        meetNow={this.props.meetNow}
        selectMeeting={this.props.selectMeeting}
        selectedItemType={this.props.selectedItemType}
        selectedId={this.props.selectedId}
      />
    );

    return this.props.isFetching && !recurringMeetings.length ? <MeetingsTabSpinner /> : meetingsList;
  }
}
