import React from 'react';
import { ModalDialog } from '../../../Common/ModalDialog/ModalDialog';
import { Interstitial, Props } from './Interstitial';

export const InterstitialModal = (props: Props) => (
  <ModalDialog
    contentLabel="SchedulerModal"
    shouldCloseOnOverlayClick
    onClose={() => {
      props.hideWindow(false, false);
    }}
  >
    <Interstitial {...props} />
  </ModalDialog>
);
