import { EditorState } from 'draft-js';
import { unicodeStrategy } from './emojiStrategy';
import { Emoji } from '../../component/Emoji/Emoji';
import decorateComponentWithProps from 'decorate-component-with-props';
import { attachImmutableEntitiesToEmojis } from './addEmoji';

const DEFAULT_EMOJI_SIZE = '64';
const createEmojiPlugin: any = ({
  useNativeArt,
  buildEmojiUrl
}: {
  useNativeArt: boolean;
  buildEmojiUrl?: (hexCode: string, pngSize: string) => string;
}) => {
  const store: any = {};

  return {
    initialize: ({ getEditorState, setEditorState }: PluginFunctions) => {
      store.getEditorState = getEditorState;

      store.setEditorState = setEditorState;
    },
    decorators: [
      {
        strategy: unicodeStrategy,
        component: decorateComponentWithProps(Emoji, {
          store,
          useNativeArt,
          buildEmojiUrl,
          emojiSizePNG: DEFAULT_EMOJI_SIZE
        })
      }
    ],
    onChange: (state: EditorState) => {
      let newEditorState = attachImmutableEntitiesToEmojis(state);

      if (!newEditorState.getCurrentContent().equals(state.getCurrentContent())) {
        // Forcing the current selection ensures that it will be at it's right place.
        // This solves the issue where inserting an Emoji on OSX with Apple's Emoji
        // selector led to the right selection the data, but wrong position in
        // the contenteditable.
        newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());
      }

      return newEditorState;
    }
  };
};

export default createEmojiPlugin;
