import React, { Component } from 'react';
import pick from 'lodash/pick';
import emojione from 'emojione';
import { KeyNames } from '../../../../lib/keycodes';

/* eslint-disable @typescript-eslint/no-unused-vars */
interface Props {
  ariaLabel?: string;
  name?: string;
  onSelect: (ev: React.SyntheticEvent, options: any) => void;
  shortname?: string;
  title?: string;
  role?: string;
  buildEmojiUrl?: (hexCode: string, pngSize: string) => string;
  id?: any;
}

export class Emoji extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    // avoid rerendering the Emoji component if the shortname hasn't changed
    return nextProps.shortname !== this.props.shortname;
  }

  private handleKeyUp = (ev: React.KeyboardEvent) => {
    ev.preventDefault();
    if (ev.key === KeyNames.ENTER || ev.key === KeyNames.SPACE) {
      this.handleClick(ev);
    }
  };

  private handleClick = (ev: React.SyntheticEvent) => {
    this.props.onSelect(
      ev,
      pick(
        this.props,
        'shortname',
        'aliases',
        'aliases_ascii',
        'category',
        'name',
        'shortcode',
        'unicode',
        'unicode_alternates',
        'keywords'
      )
    );
  };

  buildEmojiUrl = (code: string, size: string) => {
    // from emojione shortnameToImage
    const ePath =
      emojione.imagePathPNG !== emojione.defaultPathPNG ? emojione.imagePathPNG : `${emojione.defaultPathPNG + size}/`;

    return `${ePath}${code}.png`;
  };

  render() {
    const shortname = this.props.shortname || '';
    const buildEmojiUrl = this.props.buildEmojiUrl || this.buildEmojiUrl;

    return (
      <div
        onKeyUp={this.handleKeyUp}
        onClick={this.handleClick}
        tabIndex={0}
        className="emoji"
        aria-label={this.props.ariaLabel}
        title={this.props.name}
        role={this.props.role}
      >
        <img
          className="emojione"
          alt={emojione.shortnameToUnicode(shortname)}
          title={shortname}
          src={buildEmojiUrl(emojione.emojioneList[shortname].uc_base, emojione.emojiSize)}
        />
      </div>
    );
  }
}
