import React from 'react';
import { Mention } from '../MessageInputField/component/MessageInput/MessageInput';
import { scrollIntoViewIfNeeded } from '../../../../lib/scrolling';
import { Avatar } from '../../Common/Avatar/Avatar';
import './MentionEntry.css';

interface Props {
  className: string;
  isFocused: boolean;
  mention: Mention;
}

export class MentionEntry extends React.PureComponent<Props, {}> {
  private el: HTMLDivElement | null = null;

  componentDidMount() {
    // initial selection when re-opening mention list
    if (this.props.isFocused && this.el) {
      scrollIntoViewIfNeeded(this.el);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isFocused && this.props.isFocused && this.el) {
      scrollIntoViewIfNeeded(this.el);
    }
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { mention: m, isFocused, ...rest } = this.props;

    return (
      <div ref={(el) => (this.el = el)} {...rest} className={[rest.className, 'mention-entry'].join(' ')}>
        <Avatar img={m.avatar} name={m.name} />
        <div>
          <div className="name">{m.name || m.email}</div>
          {m.name && <div className="email">{m.email}</div>}
        </div>
      </div>
    );
  }
}
