import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';
import { Flip } from '@getgo/featureflip-react';
import Scrollbars from 'react-custom-scrollbars';
import { getRouteFor } from '../../services/NavService';
import './Preferences.css';
import device from '../Common/Toolkit/devices';
import { MediaQueryFeatureSelector } from '../Common/MediaQueryFeatureSelector';
import config from 'config';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../view-model/types';
import preferencesActions from '../../view-model/modules/core/preferences/preferencesActions';
import NotificationPreferences from './NotificationPreferences/NotificationPreferences';
import MeetingPreferences from './MeetingPreferences/MeetingPreferences';
import { CustomBackgroundPreferences } from './CustomBackgroundPreferences/CustomBackgroundPreferences';
import CalendarSyncPreferences from '../../containers/CalendarSyncPreferencesContainer';
import About from './About/AboutContainer';
import NewExperiencePreferences from './NewExperiencePreferences/NewExperiencePreferencesContainer';
import { GoToAppEapPreferences } from './GoToAppEapPreferences/GoToAppEapPreferences';
import { ChatRoute } from '../Routing/ChatRoute';
import { NotFoundRedirect } from '../Routing/NotFoundRedirect';
import { PreferencesBadge } from '../Common/Preferences/PreferencesBadge/PreferencesBadge';

const messages = defineMessages({
  notifications: {
    id: 'preferences.notifications',
    defaultMessage: 'Notifications'
  },
  meeting: {
    id: 'preferences.meeting',
    defaultMessage: 'Meeting'
  },
  calendarSync: {
    id: 'preferences.calendarSync',
    defaultMessage: 'Calendar Sync'
  },
  themes: {
    id: 'preferences.themes',
    defaultMessage: 'Themes'
  },
  branding: {
    id: 'preferences.branding',
    defaultMessage: 'Manage Brands'
  },
  about: {
    id: 'preferences.about',
    defaultMessage: 'About'
  },
  newExperiencePreferences: {
    id: 'preferences.newExperiencePreferences',
    defaultMessage: 'New GoToMeeting'
  },
  navigation: {
    id: 'preferences.navigation',
    defaultMessage: 'Preferences Navigation'
  },
  customBackground: {
    id: 'preferences.customBackground',
    defaultMessage: 'Custom background'
  },
  goToApp: {
    id: 'preferences.gotoApp',
    defaultMessage: 'GoTo App'
  }
});

const PreferencesRoutes = () => (
  <Switch>
    <ChatRoute path={getRouteFor('notificationPreferences')} component={NotificationPreferences} />
    <Route path={getRouteFor('meetingPreferences')} component={MeetingPreferences} />
    <Route path={getRouteFor('customBackgroundPreferences')} component={CustomBackgroundPreferences} />
    <Route path={getRouteFor('calendarSyncPreferences')} component={CalendarSyncPreferences} />
    <Route path={getRouteFor('about')} component={About} />
    <Route path={getRouteFor('newExperiencePreferences')} component={NewExperiencePreferences} />
    <Route path={getRouteFor('goToApp')} component={GoToAppEapPreferences} />
    <Route exact path={getRouteFor('settings')} />
    <Route component={NotFoundRedirect} />
  </Switch>
);

export const PreferencesNavigation: React.FC = () => {
  const preferences = useSelector((state: State) => state.core.preferences.meetings.current);
  const ux2019Settings = useSelector((state: State) => state.core.preferences.ux2019Settings.current);
  const brandingEnabled = preferences.isBrandingEntitled && preferences.isBrandingProvisioned;
  const showNewGotoMeeting = !ux2019Settings.ux2019Enabled || !ux2019Settings.meetingHubEnabled;
  const showCustomBackgroundPreferences = preferences.chromaCamEnabled && preferences.chromaCamProvisioned;
  const isGotoAppEapAvailable = useSelector(
    (state: State) =>
      state.core.preferences.gotoAppEapSettings.current.gotoAppProvisioned &&
      state.core.preferences.gotoAppEapSettings.current.gotoAppEntitled
  );
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();

  return (
    <nav className="navigation" aria-label={f(messages.navigation)}>
      <NavLink
        to={getRouteFor('meetingPreferences')}
        className="link meeting-preferences-link"
        activeClassName="active"
      >
        {f(messages.meeting)}
      </NavLink>
      <Flip name="chat">
        <NavLink
          to={getRouteFor('notificationPreferences')}
          className="link notifications-link"
          activeClassName="active"
        >
          {f(messages.notifications)}
        </NavLink>
      </Flip>

      {showCustomBackgroundPreferences && (
        <NavLink
          to={getRouteFor('customBackgroundPreferences')}
          className="link custom-background-preferences-link"
          activeClassName="active"
          data-testid="preferences-custom-background"
          onClick={() => dispatch(preferencesActions.customBackgroundPreferencesAccessed())}
        >
          {f(messages.customBackground)}
        </NavLink>
      )}

      <NavLink to={getRouteFor('calendarSyncPreferences')} className="link calendar-sync-link" activeClassName="active">
        {f(messages.calendarSync)}
      </NavLink>

      {showNewGotoMeeting && (
        <NavLink
          to={getRouteFor('newExperiencePreferences')}
          className="link new-experience-link"
          data-testid="preferences-new-experience"
          activeClassName="active"
        >
          {f(messages.newExperiencePreferences)}
        </NavLink>
      )}

      {isGotoAppEapAvailable && (
        <NavLink
          to={getRouteFor('goToApp')}
          className="link goto-app-link"
          activeClassName="active"
          data-testid="preferences-goto-app-eap"
          onClick={() => dispatch(preferencesActions.gotoAppEapPreferencesAccessed())}
        >
          {f(messages.goToApp)}
          <PreferencesBadge badge="new" marginleft="12px" />
        </NavLink>
      )}

      {brandingEnabled && (
        <>
          <hr className="preferences-separator" />

          <a href={`${config.rest.brandingService}`} target="branding-service" className="link new-experience-link">
            {f(messages.branding)}
          </a>
        </>
      )}
      <hr className="preferences-separator" />

      <NavLink to={getRouteFor('about')} className="link about-link" activeClassName="active">
        {f(messages.about)}
      </NavLink>
    </nav>
  );
};

export const PreferencesMobile = () => {
  return (
    <Scrollbars>
      <PreferencesNavigation />
      <section className="content" role="main">
        <PreferencesRoutes />
      </section>
    </Scrollbars>
  );
};

export const PreferencesDesktop = () => {
  return (
    <>
      <PreferencesNavigation />
      <Scrollbars>
        <section className="content" role="main">
          <PreferencesRoutes />
        </section>
      </Scrollbars>
    </>
  );
};

export const Preferences = () => {
  return (
    <div className="preferences">
      <MediaQueryFeatureSelector
        query={device.mobile}
        queryMatched={<PreferencesMobile />}
        queryNotMatched={<PreferencesDesktop />}
      />
    </div>
  );
};
