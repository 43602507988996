import * as React from 'react';
import { Icons } from '../ICONS';

export interface Props {
  className?: string;
  color?: string;
  size?: number;
  viewBox?: string;
  rotate?: number;
  padding?: number;
}

export const Icon: React.FC<Props & { icon: Icons }> = (props) => {
  const styles = {
    svg: {
      display: 'inline-block',
      transform: 'scaleY(-1)' + (props.rotate ? ' rotate(' + props.rotate + 'deg)' : '')
    },
    path: {
      fill: props.color
    }
  };

  return (
    <svg
      className={props.className}
      style={styles.svg}
      width={`${props.size || 8}px`}
      height={`${props.size || 8}px`}
      viewBox={props.viewBox || '0 0 1000 1000'}
    >
      <path style={styles.path} d={props.icon} />
    </svg>
  );
};

Icon.displayName = 'Icon'; // somehow not automatically inferred in snapshot tests
