import * as meetingService from '../../../services/MeetingService';
import { ConcurrentMeetingError } from '../../../../models/Errors';
import { createImpromptuMeeting } from '../../../../models/Meeting';
import { meetingConcurrentError, meetingCreateError } from '../core/errors/errorActions';
import { ActionsUnion, Thunk, createAction } from '../../type-helpers';
import { Contact, MessageActions } from '@getgo/caps-redux';
import loggerService from '../../../services/LoggerService';
import { getNewSessionTrackingId } from '../meetings/meetingsActions';
import config from 'config';

export enum ActionTypes {
  SENDING_MEET_NOW_INVITE = 'SENDING_MEET_NOW_INVITE',
  OPEN_GROUP_MEMBERS = 'OPEN_GROUP_MEMBERS'
}

const actions = {
  sendingMeetingInvite: (roomId: string, sessionTrackingId: string) =>
    createAction(ActionTypes.SENDING_MEET_NOW_INVITE, { roomId, sessionTrackingId }),
  openGroupMembers: () => createAction(ActionTypes.OPEN_GROUP_MEMBERS)
};

export default actions;
export type Actions = ActionsUnion<typeof actions>;

const logger = loggerService.create('MessagingActions');
export const meetNow = (contact: Contact): Thunk => {
  // we need to open the window in the new tab before any async
  // otherwise browsers like Firefox and IE will block (as pop up)
  // the reference will be used later to set the correct meeting join url
  const newWindow = window.open('about:blank', 'meetnow');

  return async (dispatch) => {
    const sessionTrackingId = getNewSessionTrackingId();
    dispatch(actions.sendingMeetingInvite(contact.jid, sessionTrackingId));

    let meetingId;
    try {
      const meeting = await createImpromptuMeeting();
      meetingId = meeting.meetingId;
      const [jid] = location.hash.split('/').slice(-1);
      // Hack since the CSSS service has been shutdown
      dispatch(MessageActions.sendMessage(jid, `${config.externalLinks.g2mm}/${meetingId}`, {}));
    } catch (err) {
      if (newWindow) {
        newWindow.close();
      }
      logger.error('meetNowImpromptu.error', 'error=', err, 'sessionTrackingId=', sessionTrackingId);

      if (err instanceof ConcurrentMeetingError) {
        return dispatch(meetingConcurrentError(err));
      }
      return dispatch(meetingCreateError(err));
    }

    await meetingService.joinMeeting(meetingId, { window: newWindow, sessionTrackingId });

    return meetingId;
  };
};
