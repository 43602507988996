import React from 'react';
import { Chat, Props as ChatProps } from './Chat';
import { ChatHeader, Props as ChatHeaderProps } from './ChatHeader';
import { NoContactChat, Props as NoContactChatProps } from './NoContactChat';

type Props = NoContactChatProps & ChatProps & ChatHeaderProps;

export const ChatPane: React.FC<Props> = (props) => {
  const propsWithDefault = {
    onSetSidebarOpen: () => {},
    autoFocus: true,
    ...props
  };

  if (propsWithDefault.contact) {
    return (
      <div className="chat-container">
        <ChatHeader {...propsWithDefault} />
        <Chat {...propsWithDefault} />
      </div>
    );
  }

  return (
    <div className="chat-container">
      <NoContactChat {...propsWithDefault} />
    </div>
  );
};
