import React from 'react';
import { ModalDialog } from '../../Common/ModalDialog/ModalDialog';
import ErrorWindow from './ErrorWindow';
import { ErrorWindowProps } from '../../../view-model/modules/meetings/meetingsReducer';

interface Props extends ErrorWindowProps {
  onClose: () => void;
}

export const ErrorWindowModal = (props: Props) => (
  <ModalDialog contentLabel="ErrorWindow" shouldCloseOnOverlayClick onClose={props.onClose}>
    <ErrorWindow {...props} />
  </ModalDialog>
);
