import { connect } from 'react-redux';
import { NewExperiencePreferences } from './NewExperiencePreferences';
import { Dispatch, State } from '../../../view-model/types';
import sharedActions from '../../../view-model/sharedActions';
import { showToastNotification } from '../../../view-model/modules/core/notifications/notificationsActions';
import { ToastOptions } from '../../../view-model/modules/core/notifications/notificationsReducer';
import preferencesActions from '../../../view-model/modules/core/preferences/preferencesActions';

const mapStateToProps = (state: State) => {
  return {
    user: state.core.user,
    current: state.core.preferences.ux2019Settings.current,
    update: state.core.preferences.ux2019Settings.update
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    newExperienceOptOut: () => {
      dispatch(sharedActions.newExperienceOptOut());
    },
    showWarningNotification: (content: React.ReactNode, options?: ToastOptions) => {
      dispatch(showToastNotification(content, options));
    },
    changeSettingUX2019Enabled: (value: boolean) => {
      dispatch(preferencesActions.changeSettingUX2019Enabled(value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewExperiencePreferences);
