import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { isHoverSupported } from '../../../services/ClientService';
import Scroller from '../../Common/Scroller';
import { RoomList } from './RoomList';
import { EmptyContacts } from './EmptyContacts';
import { State } from '../../../view-model/types';
import { UnreadCount } from '@getgo/caps-redux';
import { UnstyledButton } from '../../Common/Toolkit/Button/Button';
import styled from '../../../styled-components';
import { getRouteFor } from '../../../services/NavService';

const messages = defineMessages({
  unknownUser: {
    id: 'rooms.unknownUser',
    defaultMessage: 'Unknown user'
  },
  addContactTooltip: {
    id: 'rooms.addContactTooltip',
    defaultMessage: 'Start a conversation'
  },
  addGroupTooltip: {
    id: 'rooms.addGroupTooltip',
    defaultMessage: 'Find or create'
  },
  groupsTitle: {
    id: 'rooms.groupsTitle',
    defaultMessage: 'Groups'
  },
  contactsTitle: {
    id: 'rooms.contactsTitle',
    defaultMessage: 'Contacts'
  }
});

const sortByNameAndJidComparisonCriterion = (
  a: State['messaging']['contacts']['items'][0],
  b: State['messaging']['contacts']['items'][0]
) => {
  const nameComparison = a.name.localeCompare(b.name);

  return nameComparison !== 0 ? nameComparison : a.jid.localeCompare(b.jid);
};

const SidebarIconButton = styled(UnstyledButton)`
  width: 14px;
  height: 14px;
  float: right !important;
  background: transparent;
  border: none;
  color: #8997ad;
  top: 2px;
  position: relative;

  &:disabled {
    pointer-events: none;
    cursor: default;
    color: #caced3;
  }

  &:hover {
    color: #485164;
  }

  i {
    font-weight: bold;
  }
`;

const AddButton: React.FC<{ url?: string; tooltip?: string; disabled: boolean; testId?: string }> = (props) => {
  const history = useHistory();

  return (
    <SidebarIconButton
      disabled={props.disabled}
      data-place="right"
      data-tip={isHoverSupported ? props.tooltip : null}
      aria-label={props.tooltip}
      onClick={() => props.url && history.replace(props.url)}
      data-testid={props.testId}
    >
      <i className="togo-icon togo-icon-add" style={{ color: '#5b646f' }} />
    </SidebarIconButton>
  );
};

interface Props {
  addContactUrl?: string;
  addGroupUrl?: string;
  isRosterLoaded?: boolean;
  isOnline?: boolean;
  rooms?: State['messaging']['contacts']['items'];
  unreadPerRoom: UnreadCount;
  onRemove?: (jid: string, selected: boolean) => void;
  onSelect?: (jid: string) => void;
}

export const Rooms: React.FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();
  const { isRosterLoaded = false, isOnline = true, unreadPerRoom = {}, rooms = [] } = props;
  const isAddDisabled = !props.isOnline || !props.isRosterLoaded;

  const normalizeName = (contact: State['messaging']['contacts']['items'][0]) => {
    if (contact.name === contact.jid) {
      return Object.assign({}, contact, {
        name: f(messages.unknownUser)
      });
    }

    return contact;
  };

  const groups = rooms.filter((room) => room.isGroup && room.name);
  groups.sort(sortByNameAndJidComparisonCriterion);
  const contacts = rooms.filter((room) => !room.isGroup && room.name).map(normalizeName);
  contacts.sort(sortByNameAndJidComparisonCriterion);

  return (
    <Scroller className="rooms" selector=".unread-count" watch={unreadPerRoom}>
      <div className="rooms-group groups">
        <div className="header">
          <span>{f(messages.groupsTitle)}</span>
          <AddButton
            disabled={isAddDisabled}
            url={getRouteFor('groupSearch')}
            tooltip={f(messages.addGroupTooltip)}
            testId="button-add-group"
          />
        </div>
        <RoomList {...props} rooms={groups} />
      </div>
      <div className="rooms-group contacts">
        <div className="header">
          <span>{f(messages.contactsTitle)}</span>
          <AddButton
            disabled={isAddDisabled}
            url={getRouteFor('addContact')}
            tooltip={f(messages.addContactTooltip)}
            testId="button-add-contact"
          />
        </div>
        <RoomList {...props} rooms={contacts} />
      </div>
      {!rooms.length && isOnline && isRosterLoaded ? <EmptyContacts /> : null}
    </Scroller>
  );
};
