import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import sharedActions from '../../view-model/sharedActions';

export const Tooling: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(sharedActions.pageChanged(history.location.pathname));
  }, [history.location.pathname]);
  return <>{children}</>;
};
