import { Document, Packer, Paragraph, TextRun } from 'docx';
import FileSaver from 'file-saver';
import { camelCase, startCase } from 'lodash';
import { formatToMinutes, getDurationInMinutes } from '../../../lib/date';

interface DateFormatSession {
  startDate: string;
  endDate: string;
  startTime: string;
  expiry: string;
}

export const meetingInfoDownload = (sessions: any, dateTimeFormat: DateFormatSession, meetingAnalytics: any) => {
  const { subject, meetingId, shareRecording, attendees } = sessions;
  const { startDate, startTime, expiry } = dateTimeFormat;

  const scope =
    shareRecording && shareRecording.scope && shareRecording.scope.length ? shareRecording.scope[0].type : '';

  const assetsShareScope = () => {
    const share =
      shareRecording && shareRecording.sharedResources && shareRecording.sharedResources.length
        ? shareRecording.sharedResources
        : '';
    if (share) {
      const sharingArray: string[] = [];
      share.forEach((item: string) => {
        if (item === 'SESSION_INFO') {
          sharingArray.push('Meeting info');
        } else {
          sharingArray.push(startCase(camelCase(item)));
        }
      });
      return sharingArray.join(', ');
    }
    return share;
  };

  const getAttendeeEngagementReport = (attendee: { totalTalkTimePercentage: number }) => {
    const sessionDuration = getDurationInMinutes(
      new Date(meetingAnalytics.recordingTimes[0].startTime),
      new Date(meetingAnalytics.recordingTimes[0].stopTime)
    );
    const percentageInDecimal = Number(attendee.totalTalkTimePercentage) / 100;
    return formatToMinutes(sessionDuration * percentageInDecimal);
  };

  const attendeeList = () => {
    return attendees.map((item: any) => {
      return new Paragraph({
        children: [
          fontTextRun(item.name),
          fontTextRun(item.email, { color: '#006aff', underline: { type: 'thick', color: '#006aff' }, break: 1 })
        ]
      });
    });
  };

  const attendeeEnagement = () => {
    if (meetingAnalytics && meetingAnalytics.attendees && meetingAnalytics.attendees.length > 0) {
      return meetingAnalytics.attendees.map((item: any) => {
        return new Paragraph({
          children: [
            fontTextRun(`${item.name}`, { bold: true }),
            fontTextRun(`${getAttendeeEngagementReport(item)} mins . ${item.totalTalkTimePercentage}%`, { break: 1 })
          ]
        });
      });
    }
    return [];
  };

  const overallMeetingEngagementReport = () => {
    let organizerEngagement = '';
    let attendeeEngagement = '';
    if (meetingAnalytics && meetingAnalytics.organizerEngagement) {
      organizerEngagement = String(meetingAnalytics.organizerEngagement).padStart(2, '0') + '%';
    }

    if (meetingAnalytics && meetingAnalytics.attendeeEngagement >= 0) {
      attendeeEngagement = String(meetingAnalytics.attendeeEngagement).padStart(2, '0') + '%';
    }
    return { organizerEngagement, attendeeEngagement };
  };

  const doc = new Document();

  const fontTextRun = (input: string, properties = {}) => {
    return new TextRun({
      size: 26,
      text: input,
      ...properties,
      font: {
        name: 'Calibri'
      }
    });
  };

  doc.addSection({
    children: [
      new Paragraph({
        children: [fontTextRun(subject, { bold: true, size: 52 })]
      }),
      new Paragraph({
        children: [
          fontTextRun(startDate + ' . ', { break: 1 }),
          fontTextRun(startTime + ' . '),
          fontTextRun(`ID: ${meetingId}`)
        ]
      }),
      new Paragraph({
        children: [fontTextRun('Share Status', { bold: true, size: 32, break: 2 })]
      }),
      new Paragraph({
        children: [fontTextRun('Access: ', { bold: true, break: 1 }), fontTextRun(startCase(camelCase(scope)))]
      }),
      new Paragraph({
        children: [fontTextRun('Expires: ', { bold: true }), fontTextRun(expiry)]
      }),
      new Paragraph({
        children: [fontTextRun('Shared content: ', { bold: true }), fontTextRun(assetsShareScope())]
      }),
      new Paragraph({
        children: [fontTextRun('Attendees', { bold: true, size: 32, break: 2 }), fontTextRun('', { break: 2 })]
      }),
      ...attendeeList(),
      new Paragraph({
        children: [
          fontTextRun('Talk Time', { bold: true, size: 32, break: 2 }),
          fontTextRun('Organizer', { bold: true, break: 2 }),
          fontTextRun(overallMeetingEngagementReport().organizerEngagement, { break: 1 }),
          fontTextRun('Attendees', { bold: true, break: 2 }),
          fontTextRun(overallMeetingEngagementReport().attendeeEngagement, { break: 1 }),
          fontTextRun('', { break: 2 })
        ]
      }),
      ...attendeeEnagement()
    ]
  });

  try {
    Packer.toBlob(doc)
      .then((blob) => {
        FileSaver.saveAs(blob, `${subject} - Meeting Info.docx`, { autoBom: true });
      })
      .catch(() => {
        // fail silently
      });
  } catch {
    // fail silently
  }
};
