import { UnstyledButton } from './Button';
import { Close } from '../Icon/ReactIcons';
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  closeLabel: {
    id: 'common.button.closeButton.closeLabel',
    defaultMessage: 'Close'
  }
});

interface Props {
  className?: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  size?: number;
  dataTestId?: string;
  color?: string;
  disabled?: boolean;
}

export const CloseButton: React.FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();
  const { dataTestId = 'close-button', size = 30, color = '#8B8D99', className, onClick, disabled = false } = props;

  return (
    <UnstyledButton
      data-testid={dataTestId}
      className={className}
      onClick={onClick}
      aria-label={f(messages.closeLabel)}
      disabled={disabled}
    >
      <Close size={size} unit={'px'} color={color} />
    </UnstyledButton>
  );
};
