import * as React from 'react';
import './PageTemplate.css';

interface Props {
  children: React.ReactNode;
}

export const Body: React.FC<Props> = (props) => {
  if (React.Children.count(props.children) > 0) {
    return <div className="pulse-body">{props.children}</div>;
  }
  return null;
};
