import React from 'react';
import { WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { User } from '../../view-model/modules/core/user/userReducer';
import { Box, Item } from '../Common/Toolkit/Box/Box';
import styled from '../../styled-components';
import { JoinForm, JoinMeetingForm } from '../Common/Toolkit/JoinMeetingForm/JoinMeetingForm';
import url from 'url';
import config from 'config';
import device from '../Common/Toolkit/devices';
import { HubLogo } from '../Common/Dashboard/HubLogo';
import { DashboardHeader } from '../Common/Dashboard/DashboardHeader';

const externalLinks = config.externalLinks;
const g2mmUrlPrefix = `${url.parse(externalLinks.g2mm).hostname}/`;
const unifiedLaunchUrlPrefix = `${url.parse(externalLinks.unifiedLaunchUrl).hostname}/`;

const TopContainer = styled(Box)`
  flex-direction: row;
  flex: 1 0 auto;

  @media ${device.mobile} {
    border-bottom: 1px solid #e0e4ea;
  }
`;

const JoinItem = styled(Item)`
  flex: 1 0 240px;
  padding: 24px 32px;
  align-self: stretch;
  max-width: 380px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.laptop} {
    padding: 20px 24px;
  }

  @media ${device.tablet} {
    padding: 16px;
  }

  @media ${device.mobile} {
    flex: 1 0 auto;
    padding: 16px 8px;
    max-width: none;
  }

  ${JoinForm} {
    flex-grow: 1;
  }
`;

export const meetingDashboardMessages = defineMessages({
  schedule: {
    id: 'meetingHub.meetingsHeader.schedule',
    defaultMessage: 'Create Meeting'
  },
  join: {
    id: 'meetingHub.meetingsHeader.join',
    defaultMessage: 'Join'
  },
  start: {
    id: 'meetingHub.meetingsHeader.start',
    defaultMessage: 'Start'
  },
  joinPlaceholder: {
    id: 'meetingHub.meetingsHeader.joinPlaceholder',
    defaultMessage: 'Enter a meeting ID or link'
  }
});

interface Props {
  user: User;
  isOnline: boolean;
  joinMeeting: (meetingId: string) => void;
}

interface State {
  meetingId: string;
  isMeetingIdValid: boolean;
}

class MeetingsDashboardHeader extends React.Component<Props & WrappedComponentProps, State> {
  state = {
    meetingId: '',
    isMeetingIdValid: false
  };

  shouldComponentUpdate(nextProps: Props & WrappedComponentProps, nextState: State) {
    return nextProps.user !== this.props.user || nextProps.isOnline !== this.props.isOnline || nextState !== this.state;
  }

  render() {
    const { formatMessage } = this.props.intl;
    const { joinMeeting = () => {} } = this.props;

    return (
      <DashboardHeader data-testid="meetings-dashboard-header" role="banner">
        <TopContainer>
          <HubLogo />
        </TopContainer>
        <JoinItem alignSelf={'center'}>
          <JoinMeetingForm
            joinMeetingPlaceholder={formatMessage(meetingDashboardMessages.joinPlaceholder)}
            joinMeetingLabel={formatMessage(meetingDashboardMessages.join)}
            meetingId={this.state.meetingId}
            joinMeeting={joinMeeting}
            g2mmUrlPrefix={g2mmUrlPrefix}
            unifiedLaunchUrlPrefix={unifiedLaunchUrlPrefix}
            joinDisabled={!this.props.isOnline}
            meetingIdInputDisabled={!this.props.isOnline}
          />
        </JoinItem>
      </DashboardHeader>
    );
  }
}

export default injectIntl(MeetingsDashboardHeader, { forwardRef: true });
