import { Contact, Selectors } from '@getgo/caps-redux';
import { State } from './types';
import { Invitation } from './modules/meetings/meetingsReducer';
import { getDiffInDays } from '../../lib/date';
import { createSelector } from 'reselect';
import { MeetingType } from './modules/scheduler/schedulerReducer';
import { DiscoverState } from './modules/discover/discoverReducer';
import {
  ImpromptuMeeting,
  isRecurringMeeting,
  isScheduledMeeting,
  Meeting,
  RecurringMeeting,
  ScheduledMeeting,
  SelectableItemType,
  Session
} from './modules/meetings/MeetingsTypes';

export const getUser = (state: State) => state.core.user;

export const getFeatures = (state: State) => state.core.app.features;
export const isFeatureEnabled = (state: State, feature: string) => state.core.app.features.includes(feature);

export const getIsOnline = (state: State) => state.core.app.connectionStatus.networkOnline;
export const getUserStatus = (state: State) => state.messaging.chat.presence.availability;
export const getIsChatVisible = (state: State) => state.core.app.isChatVisible;
export const getIsPageVisible = (state: State) => state.core.app.hasFocus;
export const getTodayFromState = (state: State) => state.core.app.today;
export const getPreferences = (state: State) => state.core.preferences;
const getMeetingsById = (state: State) => state.meetings.meetings.byId;
const getAllMeetingIds = (state: State) => state.meetings.meetings.allIds;
const getProfile = (state: State) => state.meetings.profile;

export const getNextContactJid = (state: State, jid: string): string | null => {
  const findNextContact = (items: Contact[], currContactJid: string) => {
    const index = items.findIndex((contact) => contact.jid === currContactJid);
    let nextContact: Contact | undefined;
    if (index === -1) {
      // not found; do nothing
    } else if (index + 1 < items.length) {
      nextContact = items[index + 1];
    } else if (index > 0) {
      nextContact = items[index - 1];
    } else {
      nextContact = items[index];
    }
    return nextContact;
  };

  const groups = Selectors.getContacts(state).items.filter((contact) => contact.isGroup);
  const contacts = Selectors.getContacts(state).items.filter((contact) => !contact.isGroup);
  let next: Contact | undefined | null = findNextContact(groups, jid);
  if (!next) {
    next = findNextContact(contacts, jid);
    if (!next) {
      if (contacts.length > 0) {
        next = contacts[0];
      } else if (groups.length > 0) {
        next = groups[0];
      }
    } else if (next.jid === jid) {
      next = groups.length > 0 ? groups[groups.length - 1] : null;
    }
  } else if (next.jid === jid) {
    next = contacts.length > 0 ? contacts[0] : null;
  }
  if (next) {
    return next.jid;
  }

  return null;
};

export const getNextContactJidWithHistory = (state: State, jid: string): string | null => {
  const contacts = Selectors.getContacts(state);
  const { selectionHistory } = contacts;
  const nextContactJid = (selectionHistory || [])
    .filter((it) => it !== jid)
    .reverse()
    .find((it) => !!contacts.items.find((contact) => contact.jid === it));

  if (nextContactJid) {
    return nextContactJid;
  }

  return getNextContactJid(state, jid);
};

export const getUserLocalPrefix = (state: State) => {
  const user = getUser(state);
  return user.hasLoaded && user.locale ? user.locale.substring(0, 2) : 'en';
};

export const getProfileFromMeetingList = (state: State): RecurringMeeting | null => {
  if (state.meetings.profile) {
    const profileMeeting = state.meetings.meetings.byId[state.meetings.profile.meetingId];
    if (isRecurringMeeting(profileMeeting)) {
      return profileMeeting;
    }
  }
  return null;
};

/* Get the items for the current selection
 * assume valid states for selectedId and selectedItemType */
export const getItemForCurrentSelection = (state: State): Meeting | Session | null => {
  switch (state.meetings.selectedItemType) {
    case SelectableItemType.PERSONAL:
      return getProfileFromMeetingList(state);
    case SelectableItemType.RECURRING:
    case SelectableItemType.SCHEDULED:
    case SelectableItemType.IMPROMPTU:
      return (state.meetings.selectedId && state.meetings.meetings.byId[state.meetings.selectedId]) || null;
    case SelectableItemType.SESSION:
      return (state.meetings.selectedId && state.meetings.history.sessions.byId[state.meetings.selectedId]) || null;
    case SelectableItemType.NONE:
    case SelectableItemType.ELASTICSEARCH:
    case SelectableItemType.ELASTICSEARCHRESULTSELECTED:
      return null;
  }
};

export const getInvitationForMeetingId = (state: State): Invitation | null => {
  if (state.meetings.selectedId != null) {
    return state.meetings.invitationsById[state.meetings.selectedId];
  }
  return null;
};

export const isFreeUser = (state: State): boolean => {
  return state.core.user.hasLoaded && state.core.user.licenseType === 'free';
};

export const isTrialUser = (state: State): boolean => {
  return state.core.user.hasLoaded && state.core.user.licenseType === 'trial';
};

export const getRemainingTrialDays = (state: State): number => {
  if (state.core.user.hasLoaded && state.core.user.trialInformation) {
    // todo remove:  -1 so that the calculation is the same as MFE
    const diffInDays = getDiffInDays(state.core.app.today, state.core.user.trialInformation.endDate) - 1;
    if (diffInDays < 0) {
      return 0;
    }
    return diffInDays;
  }
  return -1;
};

export const hasLicense = (state: State): boolean => {
  return state.core.user.hasLoaded && state.core.user.licenseType !== undefined;
};

export const isChatEnabled = (state: State): boolean => {
  return state.core.user.hasLoaded && state.core.user.chat;
};

export const getRecurringMeetings = createSelector([getMeetingsById, getAllMeetingIds], (byId, allIds) => {
  const recurringMeetings: RecurringMeeting[] = [];

  allIds.forEach((id) => {
    const meeting = byId[id];
    if (meeting.meetingType === MeetingType.recurring) {
      recurringMeetings.push(meeting);
    }
  });
  return recurringMeetings;
});

export const getScheduledMeetings = createSelector([getMeetingsById, getAllMeetingIds], (byId, allIds) => {
  const scheduledMeetings: ScheduledMeeting[] = [];

  allIds.forEach((id) => {
    const meeting = byId[id];
    if (isScheduledMeeting(meeting)) {
      scheduledMeetings.push(meeting);
    }
  });

  return scheduledMeetings;
});

export const getRunningImpromptuMeeting = createSelector([getMeetingsById, getAllMeetingIds], (byId, allIds) => {
  const impromptuMeetings: ImpromptuMeeting[] = [];

  allIds.forEach((id) => {
    const meeting = byId[id];
    if (meeting.meetingType === MeetingType.impromptu && !!meeting.screenSharing) {
      impromptuMeetings.push(meeting);
      // consider only the first running impromptu meeting - early return
      return;
    }
  });

  return impromptuMeetings[0];
});

export const getRooms = createSelector([getMeetingsById, getAllMeetingIds, getProfile], (byId, allIds, profile) => {
  return allIds
    .map((id) => byId[id])
    .filter(
      (room) =>
        (!!Object.keys(room.meetingUrl || {}).length && isRecurringMeeting(room)) ||
        (profile && room.meetingId === profile.meetingId)
    ) as RecurringMeeting[];
});

export const getDiscoverUnreadCount = (state: State | { discover: DiscoverState }): number => {
  let count = 0;
  state.discover.entries.forEach((entry) => {
    if (!entry.engagementMessage.contentRead) {
      count += 1;
    }
  });
  return count;
};
