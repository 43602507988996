import platform from 'platform';

export const isChrome: boolean = /Chrome/i.test(platform.name || '');
export const isElectron: boolean = /electron/i.test(platform.ua || '');
export const isV11: boolean = /GoToMeeting-Electron/i.test(platform.ua || '');
export const isHoverSupported = !window.matchMedia('(hover:none)').matches;
export const isMobile: boolean = /Mobile/i.test(platform.ua || '');
export const isMac: boolean = !!(platform.os?.family && platform.os.family.indexOf('OS X') !== -1);
export const isWin: boolean = !!(platform.os?.family && platform.os.family.includes('Windows'));
export const isWin7Plus: boolean = !!(isWin && platform.os?.version && parseInt(platform.os.version, 10) >= 7);
export const clientType: 'electron' | 'mobile' | 'web' = (() => {
  if (isElectron) {
    return 'electron';
  }
  if (isMobile) {
    return 'mobile';
  }
  return 'web';
})();
export const isWeb: boolean = clientType === 'web';
export const isIOS: boolean = !!(platform.os?.family === 'iOS');
export const isAndroid: boolean = !!(platform.os?.family === 'Android');
export const browser: string | null = platform.name && platform.version ? `${platform.name} ${platform.version}` : null;
