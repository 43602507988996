import { MessageDescriptor } from 'react-intl';
import messages from './messages';

export const getDisabledToolTipMessage = (
  exclusiveDisable: boolean,
  adminDisable = false
): MessageDescriptor | undefined => {
  if (exclusiveDisable) {
    return messages.disabledTooltipExclusive;
  }
  if (adminDisable) {
    return messages.disabledTooltipAdmin;
  }
  return undefined;
};
