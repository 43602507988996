import styled from '../../../../styled-components';
import { Button } from './Button';
import { themeGet } from '@styled-system/theme-get';

export const SecondaryButton = styled(Button)`
  background: white;
  border-radius: 4px;
  border: 2px solid ${themeGet('colors.primary')};
  color: ${themeGet('colors.primary')};
  white-space: nowrap;
  height: 36px;
  line-height: 32px;
  padding: 0 16px;
  outline: none;
  align-items: center;

  &:focus:enabled {
    box-shadow: 0 0 0 1px ${themeGet('colors.surface')}, 0 0 0 3px ${themeGet('colors.primary')};
  }

  &:hover:enabled {
    background-color: ${themeGet('colors.neutralScale.100')};
  }

  &:active:enabled {
    background-color: ${themeGet('colors.primaryScale.100')};
  }

  &:disabled {
    background: none;
    color: grey;
  }
`;
