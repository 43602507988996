import { Response, ResponseError } from 'superagent';
import { Token } from '@getgo/auth-client';
import * as t from 'io-ts';
import config from 'config';
import errorMapper from './ErrorMapper';
import api from '../lib/rest';
import { Entitlement } from '../app/view-model/modules/core/user/userReducer';
import { optional } from '../lib/io-ts-helpers';
import LoggerService from '../app/services/LoggerService';
import { UserWithPreferences } from '../app/view-model/sharedActions';
import { UnwrapPromise } from '../app/view-model/type-helpers';
const logger = LoggerService.create('user-model');

const responseTypes = {
  // https://confluence.ops.expertcity.com/display/SOA/CaPS+API
  ejabberdMe: t.type({
    accessibility: optional(t.boolean), // TODO optional for now for backward compatibility
    accountKey: t.string,
    chat: t.boolean,
    email: t.string,
    emailVerified: t.boolean,
    externalContacts: t.boolean,
    fileSharing: t.boolean,
    firstName: optional(t.string),
    jid: t.string,
    key: t.string,
    lastName: optional(t.string),
    licenseName: optional(t.string),
    licenseType: optional(t.union([t.literal('free'), t.literal('trial'), t.literal('paid')])),
    locale: optional(t.string),
    meetNow: t.boolean,
    tcAgreement: t.boolean,
    tier: optional(t.number),
    timeZone: t.string
  }),

  // SCIM service: https://confluence.ops.expertcity.com/display/SOA/SCIM+Identity+Service#SCIMIdentityService-GetUser
  identity: t.type({
    id: t.string,
    locale: optional(t.string),
    entitlements: optional(t.array(t.string)),
    emails: optional(
      t.array(t.type({ value: optional(t.string), type: optional(t.string), primary: optional(t.boolean) }))
    )
  }),

  // SetSale service: https://confluence.ops.expertcity.com/display/collaboration/SetSale+Service+Rest+API#SetSaleServiceRestAPI-GetMe
  setSaleMe: optional(
    t.type({
      settings: optional(
        t.type({
          downloadrecordingentitled: optional(t.boolean),
          deleterecordingentitled: optional(t.boolean),
          transcriptsenabled: optional(t.boolean),
          transcriptsentitled: optional(t.boolean),
          transcriptsprovisioned: optional(t.boolean),
          contentsearchenabled: optional(t.boolean),
          contentsearchentitled: optional(t.boolean),
          contentsearchprovisioned: optional(t.boolean)
        })
      )
    })
  ),

  // Meeting Service (no url found for documentation but this API exists and works)
  getProfileFromMeetingService: t.type({
    accountKey: t.string,
    key: t.string,
    email: t.string,
    licenseKey: optional(t.string),
    locale: optional(t.string)
  }),

  getProductFromMeetingService: optional(
    t.type({
      product: t.string,
      endDate: t.string,
      trial: t.boolean,
      active: t.boolean
    })
  ),

  getExternalAdminMe: t.type({
    key: t.string,
    accountKey: t.string,
    email: t.string,
    firstName: t.string,
    lastName: t.string,
    locale: t.string,
    timeZone: t.string,
    adminRoles: optional(t.array(t.string)),
    accounts: t.array(
      t.type({
        key: t.string,
        name: t.string,
        customContactEmail: optional(t.string),
        customContactUrl: optional(t.string),
        adminRoles: optional(t.array(t.string)),
        adminList: t.array(
          t.type({
            email: t.string,
            firstName: t.string,
            lastName: t.string
          })
        )
      })
    )
  })
};

export const getProfileFromMeetingService = async () => {
  const url = `${config.rest.meetingService}/rest/me`;
  return await api.getValidated(responseTypes.getProfileFromMeetingService, url, { clientName: true });
};

export const getMember = async () => {
  const url = `${config.rest.ejabberdUrl}/rest/v3/members/me`;
  return await api.getValidated(responseTypes.ejabberdMe, url);
};

export const getProductInformation = async () => {
  const url = `${config.rest.meetingService}/rest/2/product`;
  try {
    return await api.getValidated(responseTypes.getProductFromMeetingService, url, { clientName: true });
  } catch {
    return undefined;
  }
};

export const getExternalAdminInformation = async () => {
  const url = `${config.rest.externalAdmin}/ext-admin/rest/me`;
  return await api.getValidated(responseTypes.getExternalAdminMe, url, { includeAdmins: true });
};

export const isEmailVerified = async () => {
  try {
    const me = await getMember();
    return me.emailVerified;
  } catch (err) {
    logger.error('isEmailVerified', 'error=', err);
    return null;
  }
};

export const getIdentity = async () => {
  const url = `${config.rest.accounts}/identity/v1/Users/me`;
  return await api.getValidated(responseTypes.identity, url);
};

const getSettings = async () => {
  const url = `${config.rest.insights}/v1/products/g2m/me`;
  try {
    return await api.getValidated(responseTypes.setSaleMe, url);
  } catch (err) {
    // if this fails, just resolve with an empty object. It could mean that
    // the user does not have g2m account yet and he will not get the meeting interface anyway.
    logger.error('getSettings', 'error=', err);
    return null;
  }
};

export const acceptTermsOfService = async () => {
  const url = `${config.rest.ejabberdUrl}/rest/v1/members/me/tcagreement`;
  await api.postValidated(t.any, url);
};

const validEntitlements = [
  'g2ars',
  'g2t',
  'g2w',
  'g2m',
  'openvoice',
  'acctadmin',
  'orgadmin',
  'goview',
  'prompt',
  'g2aseeit'
];

const filterEntitlements = (entitlements: string[]): Entitlement[] => {
  return entitlements.filter((e) => validEntitlements.includes(e)) as Array<
    'g2ars' | 'g2t' | 'g2w' | 'g2m' | 'openvoice' | 'acctadmin' | 'orgadmin' | 'goview' | 'prompt' | 'g2aseeit'
  >;
};

export const loadMe = async (token?: Token): Promise<UserWithPreferences> => {
  try {
    const values = (await Promise.all([getIdentity(), getSettings(), getMember()])) as [
      UnwrapPromise<ReturnType<typeof getIdentity>>,
      UnwrapPromise<ReturnType<typeof getSettings>>,
      UnwrapPromise<ReturnType<typeof getMember>>
    ];

    const entitlements = values[0].entitlements || [];
    const settings = (values[1] || { settings: undefined }).settings;
    const me = values[2];

    let trialInformation;
    if (me.licenseType === 'trial') {
      const productInformation = await getProductInformation();
      if (productInformation) {
        trialInformation = {
          active: productInformation.active,
          endDate: productInformation.endDate
        };
      }
    }

    return {
      ...me,
      trialInformation,
      id: me.jid,
      accessibility: !!me.accessibility,
      token: token && {
        token_type: token.token_type,
        access_token: token.access_token,
        expires: token.expires,
        issued: token.issued,
        scope: token.scope
      },
      entitlements: filterEntitlements(entitlements),
      settings: settings && {
        downloadrecordingentitled: !!settings.downloadrecordingentitled,
        deleterecordingentitled: !!settings.deleterecordingentitled,
        transcriptsenabled: !!settings.transcriptsenabled,
        transcriptsentitled: !!settings.transcriptsentitled,
        transcriptsprovisioned: !!settings.transcriptsprovisioned,
        contentsearchenabled: !!settings.contentsearchenabled,
        contentsearchentitled: !!settings.contentsearchentitled,
        contentsearchprovisioned: !!settings.contentsearchprovisioned
      },
      avatarUrl: await getUserAvatar(me.key, 'medium')
    };
  } catch (err) {
    const error: ResponseError & { response: Response } = err;
    throw errorMapper(error);
  }
};

export const sendVerificationEmail = async (automatic: boolean) => {
  const url = `${config.rest.ejabberdUrl}/rest/v2/emailverification`;
  // TODO add types iff email verification app is still needed
  return await api.postValidated(t.any, url, { automatic });
};

export const createEmailVerification = async (email: string) => {
  const url = `${config.rest.accounts}/verification/emails`;
  await api.postValidated(t.any, url, { email });
};

export const verifyEmailWithCode = async (token: string, code: string) => {
  const url = `${config.rest.accounts}/verification/emails/${token}`;
  await api.postValidated(t.any, url, { code });
};

export const setHighContrast = async (useHighContrast: boolean) => {
  const url = `${config.rest.ejabberdUrl}/rest/v1/members/me/settings`;
  return api.putValidated(t.any, url, {
    accessibility: useHighContrast
  });
};

export const setNewExperience = (optIn: boolean) => {
  return api.postRaw(config.rest.hubRolloutApi, { optIn });
};

export const getUserAvatar = async (userKey: string, size: 'small' | 'medium' | 'large' | 'xlarge') => {
  const url = `${config.avatarServiceUrl}/${userKey}_${size}.jpg?timestamp=${Date.now()}`;
  return await fetch(url)
    .then((response) => {
      // distinguish custom avatar from default one
      if (response.headers.get('x-amz-meta-type') === 'default') {
        return undefined;
      }
      return url;
    })
    .catch(() => {
      return undefined;
    });
};
