export class WindowEventsService {
  private onBeforeUnload: () => void;
  private onOnlineChange: (isOnline: boolean) => void;
  private onVisibilityChange: (isVisible: boolean) => void;
  private onFocus: () => void;

  constructor({
    onBeforeUnload,
    onOnlineChange,
    onVisibilityChange,
    onFocus
  }: {
    onBeforeUnload: () => void;
    onOnlineChange: (isOnline: boolean) => void;
    onVisibilityChange: (isVisible: boolean) => void;
    onFocus: () => void;
  }) {
    this.onBeforeUnload = onBeforeUnload;
    this.onOnlineChange = onOnlineChange;
    this.onVisibilityChange = onVisibilityChange;
    this.onFocus = onFocus;
  }

  bindWindowHandlers = () => {
    window.addEventListener('visibilitychange', this.handleVisibilityChange, false);
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    window.addEventListener('online', this.handleOnlineChange);
    window.addEventListener('offline', this.handleOnlineChange);
    window.addEventListener('dragover', this.handleDragAndDrop);
    window.addEventListener('drop', this.handleDragAndDrop);
    window.addEventListener('focus', this.handleFocus);
  };

  unbindWindowHandlers = () => {
    window.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    window.removeEventListener('online', this.handleOnlineChange);
    window.removeEventListener('offline', this.handleOnlineChange);
    window.removeEventListener('dragover', this.handleDragAndDrop);
    window.removeEventListener('drop', this.handleDragAndDrop);
    window.removeEventListener('focus', this.handleFocus);
  };

  handleVisibilityChange = () => {
    this.onVisibilityChange(document.visibilityState === 'visible');
  };

  handleOnlineChange = () => {
    this.onOnlineChange(navigator.onLine);
  };

  handleDragAndDrop = (event: DragEvent) => {
    // allow opting out of disabling dnd by setting data-dnd attribute
    let target = event.target as HTMLElement | null;
    while (target) {
      if (target.dataset.dnd && target.dataset.dnd !== null) {
        return;
      }

      target = target.parentElement;
    }

    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'none';
    }
    event.preventDefault();
  };

  handleBeforeUnload = () => {
    this.onBeforeUnload();

    // make sure we don't return anything, since it causes IE to show
    // a "confirm navigation" alert box
    return undefined;
  };

  handleFocus = () => {
    this.onFocus();
  };
}
