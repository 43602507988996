import * as React from 'react';
import styled from '../../../styled-components';

interface Props {
  count?: number;
  testId?: string;
}

const UnreadCountLayout: React.FC<Props> = ({ count = 0, testId, ...rest }) => {
  if (!count) {
    return null;
  }

  return (
    <div {...(testId ? { 'data-testid': testId } : {})} {...rest}>
      {count >= 0 ? <span>{count}</span> : null}
    </div>
  );
};

export const UnreadCount = styled(UnreadCountLayout)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  border-radius: 9px;
  background: #e02d52;
  color: #fff;
  font-family: lato-bold;
  font-size: 1rem;
  text-align: center;
  span {
    padding: 3px 5px;
  }
`;
