import { Contact } from '@getgo/caps-redux';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import './EmptyMessage.css';

const messages = defineMessages({
  forContacts: {
    id: 'chat.emptyMessage.forContacts',
    defaultMessage: 'Start a conversation or meet now with {name}.'
  },
  forGroups: {
    id: 'chat.emptyMessage.forGroups',
    defaultMessage: 'Start a conversation with your group {name}.'
  },
  hello: {
    id: 'chat.emptyMessage.hello',
    defaultMessage: 'Hello,'
  },
  offlineTitle: {
    id: 'chat.emptyMessage.offlineTitle',
    defaultMessage: 'You are offline'
  },
  offlineDescription: {
    id: 'chat.emptyMessage.offlineDescription',
    defaultMessage:
      'You are currently not connected to the chat server. Please check your network connection and try again.'
  }
});

export interface Props {
  contact: Contact;
  isOnline?: boolean;
}

export const EmptyMessage: React.FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();

  const renderEmptyMessage = (contact: Contact) => {
    const message = contact.isGroup ? messages.forGroups : messages.forContacts;
    return (
      <div className="empty-message">
        <h2>{f(messages.hello)}</h2>
        <p>{f(message, { name: <strong key="name">{contact.name}</strong> })}</p>
      </div>
    );
  };

  const renderOfflineMessage = () => {
    return (
      <div className="offline-message">
        <h2>{f(messages.offlineTitle)}</h2>
        <p>{f(messages.offlineDescription)}</p>
      </div>
    );
  };

  const { contact, isOnline = true } = props;
  let content;
  if (!isOnline) {
    content = renderOfflineMessage();
  } else {
    content = renderEmptyMessage(contact);
  }
  return content;
};
