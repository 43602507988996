import React from 'react';
import styled from '../../../../styled-components';
import { themeGet } from '@styled-system/theme-get';

const colorTypeMapping = {
  error: '#f56880',
  success: '#66ae83'
};

const Message = styled.div`
  font-size: 12px;
  padding-left: 2px;
`;

const Fieldset = ({ legend, className, children, message, messageType, hideMessage, testId }: FieldsetProps) => (
  <div className={className} data-testid={testId ? testId : `input-fieldset`} data-message-type={messageType}>
    <fieldset>
      <legend>{legend}</legend>

      {children}
    </fieldset>
    {!hideMessage && (
      <Message data-testid={testId ? `${testId}-message` : undefined}>
        {message ? message : <span>&nbsp;</span>}
      </Message>
    )}
  </div>
);

interface FieldsetProps {
  children: React.ReactNode;
  legend: React.ReactNode;
  className?: string;
  messageType?: null | 'error' | 'success';
  message?: React.ReactNode;
  hideMessage?: boolean;
  testId?: string;
  disabled?: boolean;
}

export const InputFieldset = styled(Fieldset)<FieldsetProps>`
  fieldset {
    ${(props) => props.disabled && `background-color: #f9f9f9;`}
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    margin: 0;
    border-color: ${(props) => (props.messageType && colorTypeMapping[props.messageType]) || null};

    legend {
      font-size: 12px;
      color: ${themeGet('colors.neutralScale.400')};
    }

    & > div,
    select,
    input {
      border: none;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
  }

  ${Message} {
    color: ${(props) => (props.messageType && colorTypeMapping[props.messageType]) || null};
  }
`;
