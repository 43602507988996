import * as React from 'react';
import { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import styled from '../../../../styled-components';
import { Box } from '../../../Common/Toolkit/Box/Box';
import { Checkbox } from '../../../Common/Toolkit/Input/Selectable';
import purlImage from '../../../../../assets/images/main.purl.png';
import Select, { components } from 'react-select';
import { styledSelectMenu, styleSelect } from '../../../Common/Toolkit/Input/SelectStyles';
import { InputFieldset } from '../../../Common/Toolkit/Input/InputFieldset';
import { PrimaryButton } from '../../../Common/Toolkit/Button/PrimaryButton';
import { GhostButton } from '../../../Common/Toolkit/Button/GhostButton';
import { MeetingType, SchedulerFullMeeting } from '../../../../view-model/modules/scheduler/schedulerReducer';
import { DisableStateBox } from '../../../Common/Toolkit/Box/DisableStateBox';
import { Icons } from '../../../Common/Toolkit/ICONS';
import { RotatingIcon } from '../../../Common/Toolkit/Icon/RotatingIcon';
import device from '../../../Common/Toolkit/devices';
import { themeGet } from '@styled-system/theme-get';
import { CloseButton } from '../../../Common/Toolkit/Button/CloseButton';
import { Meeting, Profile, RecurringMeeting } from '../../../../view-model/modules/meetings/MeetingsTypes';

const StyledSelect = styleSelect(Select);
const Menu = styledSelectMenu(components.Menu);

const Window = styled.div.attrs({ 'data-testid': 'interstitial-window' })`
  padding: 30px 30px 30px 30px;
  border-top: 6px solid #f68d2e;
  color: #25282d;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  width: 655px;
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 48px);
  overflow-y: auto;
  border-radius: 4px;
`;

const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  right: 40px;
  top: 28px;
  height: 30px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-family: 'lato-semibold';
  font-weight: 600;
  margin: 0;
  padding-bottom: 32px;
  padding-right: 50px;
`;

const PurlImage = styled.div`
  margin-right: 58px;
  @media ${device.mobile} {
    margin-right: 0;
  }

  & img {
    max-width: 260px;
  }
`;

const HighlightList = styled.ul`
  list-style: none;
  padding-left: 0;

  @media ${device.mobile} {
    margin-bottom: 0;
  }

  li {
    padding-left: 24px;
    padding-bottom: 24px;
    position: relative;

    &:before {
      content: '';
      border-left: 2px solid ${themeGet('colors.primary')};
      border-bottom: 2px solid ${themeGet('colors.primary')};
      width: 16px;
      height: 8px;
      display: block;
      position: absolute;
      left: 0;
      top: 2px;
      transform: rotate(-45deg);
    }
  }
`;

const HighlightBox = styled(Box)`
  background-color: #fff;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const SelectRoomBox = styled(Box)`
  align-items: flex-end;
  margin-top: 40px;
  @media ${device.mobile} {
    margin-top: 0;
    flex-direction: column;
  }

  ${InputFieldset} {
    display: flex;
    width: 100%;
  }

  ${PrimaryButton} {
    display: flex;
    margin-left: 14px;
    padding: 0;
    width: 350px;
    text-align: center;
    height: 43px;
    ${RotatingIcon} {
      margin-right: 8px;
    }
    @media ${device.mobile} {
      margin-left: 0;
      margin-top: 8px;
      width: 100%;
    }
  }

  ${InputFieldset} fieldset {
    width: 100%;
  }
`;

const Divider = styled.div`
  text-align: center;
  position: relative;
  margin: 16px 0;

  span {
    color: #6b7582;
    font-weight: 600;
    font-family: 'lato-semibold';
    font-size: 18px;
    text-transform: uppercase;
    position: relative;
    background-color: #fff;
    padding: 0 8px;
  }

  &:before {
    content: '';
    background-color: #f0f2f5;
    position: absolute;
    height: 2px;
    width: 100%;
    top: 9px;
    left: 0;
  }
`;

const CenterBox = styled.div`
  text-align: center;
  ${Checkbox} {
    display: inline-block;
    margin-top: 24px;
    font-family: lato-semibold;
    font-weight: 500;
    color: #444;
  }
`;

const CreateMeetingButton = styled(GhostButton)`
  font-weight: 500;
  font-size: 16px;
  font-family: lato-semibold;
  text-transform: none;
  margin-top: 4px;
`;

const CreateNewMeetingOptionContainer = styled.div`
  & .react-select__option,
  & .react-select__option.react-select__option--is-focused {
    background-color: #fff;
  }
`;

const CreateNewMeetingOption = styled.div`
  border-top: 2px solid #ccc;
  color: ${themeGet('link.colors.primary')};

  cursor: pointer;
  padding-top: 8px;
  font-weight: bold;
`;

const renderMenu = (props: any) => <Menu {...props} />;

const renderOption = (props: any) => {
  const option = props.data;

  if (option.existing) {
    return <components.Option {...props} innerProps={{ ...props.innerProps, 'data-testid': 'select-option' }} />;
  }

  return (
    <CreateNewMeetingOptionContainer>
      <components.Option {...props} innerProps={{ ...props.innerProps, 'data-testid': 'select-option' }}>
        <CreateNewMeetingOption>
          + <FormattedMessage {...messages.newAnytimeMeeting} />
        </CreateNewMeetingOption>
      </components.Option>
    </CreateNewMeetingOptionContainer>
  );
};

const messages = defineMessages({
  title: {
    id: 'meetingDashboard.meetingScheduler.interstitial.title',
    defaultMessage: 'Save Time With Personal Meeting Rooms'
  },
  bulletOne: {
    id: 'meetingDashboard.meetingScheduler.interstitial.bulletOne',
    defaultMessage: 'Your meeting rooms are always available.'
  },
  bulletTwo: {
    id: 'meetingDashboard.meetingScheduler.interstitial.bulletTwo',
    defaultMessage: 'Custom meeting links are easy to recognize.'
  },
  bulletThree: {
    id: 'meetingDashboard.meetingScheduler.interstitial.bulletThree',
    defaultMessage: 'A professional start page leaves a lasting impression.'
  },
  meetingRoom: {
    id: 'meetingDashboard.meetingScheduler.interstitial.meetingRoom',
    defaultMessage: 'Title'
  },
  copyInvitation: {
    id: 'meetingDashboard.meetingScheduler.interstitial.copyInvitation',
    defaultMessage: 'Copy Invite'
  },
  or: {
    id: 'meetingDashboard.meetingScheduler.interstitial.or',
    defaultMessage: 'or'
  },
  createANewMeeting: {
    id: 'meetingDashboard.meetingScheduler.interstitial.createANewMeeting',
    defaultMessage: 'Create a new meeting'
  },
  dontShowAgain: {
    id: 'meetingDashboard.meetingScheduler.interstitial.dontShowAgain',
    defaultMessage: "Don't show again"
  },
  newAnytimeMeeting: {
    id: 'meetingDashboard.meetingScheduler.interstitial.newAnytimeMeeting',
    defaultMessage: 'New Anytime Meeting Room'
  }
});

export interface Props {
  rooms: RecurringMeeting[];
  hideWindow: (showScheduler: boolean, hideAlways: boolean) => void;
  onPropertyChange: (property: keyof SchedulerFullMeeting, value: any) => void;
  profile?: Profile;
  copyInvitation: (meeting: Meeting, profile?: Profile, dontShowAgain?: boolean) => void;

  isFetchingInvitation?: boolean;
  showCreateAnytimeMeeting?: boolean;
}

interface OptionType {
  label: string | null;
  value: string | null;
  existing: boolean;
}

export const Interstitial = (props: Props) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const { formatMessage: f } = useIntl();

  const rooms: OptionType[] = props.rooms.map((room) => ({
    label: room.subject,
    value: room.meetingId,
    existing: true
  }));

  if (props.showCreateAnytimeMeeting) {
    rooms.push({
      label: null,
      value: null,
      existing: false
    });
  }

  const personalMeetingId = props.profile ? props.profile.meetingId : false;
  const [value, setValue] = useState(rooms.find((room) => room.value === personalMeetingId) as OptionType);

  const selectMeeting = (newValue: OptionType) => {
    if (!newValue.existing) {
      props.onPropertyChange('meetingType', MeetingType.recurring);
      props.hideWindow(true, dontShowAgain);
    }
    setValue(newValue);
  };

  const clickCopyInvitation = () => {
    if (value) {
      const meeting = props.rooms.find((room) => room.meetingId === value.value) as Meeting;
      props.copyInvitation(meeting, props.profile, dontShowAgain);
    }
  };

  return (
    <Window>
      <Title>
        <FormattedMessage {...messages.title} />
      </Title>
      <PositionedCloseButton
        onClick={() => props.hideWindow(false, dontShowAgain)}
        dataTestId="interstitial-close-button"
        size={30}
      />
      <HighlightBox flexDirection="row" alignItems="center">
        <PurlImage>
          <img src={purlImage} alt="" />
        </PurlImage>
        <HighlightList>
          <li>
            <FormattedMessage {...messages.bulletOne} />
          </li>
          <li>
            <FormattedMessage {...messages.bulletTwo} />
          </li>
          <li>
            <FormattedMessage {...messages.bulletThree} />
          </li>
        </HighlightList>
      </HighlightBox>
      <SelectRoomBox flexDirection="row">
        <InputFieldset
          legend={<FormattedMessage {...messages.meetingRoom} />}
          hideMessage={true}
          testId="meeting-room-select"
        >
          <StyledSelect
            classNamePrefix="react-select"
            options={rooms}
            defaultValue={value}
            onChange={selectMeeting}
            isDisabled={!!props.isFetchingInvitation}
            components={{
              Menu: renderMenu,
              Option: renderOption
            }}
            styles={{
              menuPortal: (base: Record<string, any>) => ({ ...base, zIndex: 100 })
            }}
            menuPortalTarget={document.getElementById('app')}
            aria-label={f(messages.meetingRoom)}
          />
        </InputFieldset>
        <PrimaryButton
          onClick={clickCopyInvitation}
          disabled={!!props.isFetchingInvitation || !value}
          data-testid="copy-invitation-button"
        >
          <Box alignItems="center" margin="auto">
            {props.isFetchingInvitation && <RotatingIcon icon={Icons.spinnerSegment} size={16} />}
            <FormattedMessage {...messages.copyInvitation} />
          </Box>
        </PrimaryButton>
      </SelectRoomBox>
      <Divider>
        <span>
          <FormattedMessage {...messages.or} />
        </span>
      </Divider>
      <DisableStateBox disabled={!!props.isFetchingInvitation}>
        <CenterBox>
          <CreateMeetingButton
            data-testid="interstitial-create-meeting-button"
            onClick={() => {
              props.hideWindow(true, dontShowAgain);
            }}
          >
            <FormattedMessage {...messages.createANewMeeting} />
          </CreateMeetingButton>
        </CenterBox>
        <CenterBox>
          <Checkbox
            id="dont-show-again"
            checked={dontShowAgain}
            onChange={(e: any) => {
              setDontShowAgain(e.target.checked);
            }}
          >
            <FormattedMessage {...messages.dontShowAgain} />
          </Checkbox>
        </CenterBox>
      </DisableStateBox>
    </Window>
  );
};
