import superagent, { ProgressEvent, Request } from 'superagent';
import uuid from 'uuid';
import config from 'config';
import * as t from 'io-ts';
import { validate } from '../../lib/io-ts-helpers';
import { Token } from '../view-model/modules/core/user/userReducer';

const responseTypes = {
  // File Upload Service https://confluence.ops.expertcity.com/display/Broker/File+Upload+Microservice
  createUploadTicket: t.type({
    downloadUrl: t.string,
    uploadUrl: t.string,
    formData: t.object
  })
};

export interface FileUploadOptions {
  from: string;
  to: string;
  token: Token;
}

export interface FileUploadTicket {
  downloadUrl: string;
  uploadUrl: string;
  formData: object;
}

function format(jid: string) {
  return jid.substring(0, jid.indexOf('@'));
}

function getUrl(fromJid: string, toJid: string) {
  const from = format(fromJid);
  const to = format(toJid);
  const id = uuid.v4();

  return `${config.upload.url}/rooms/${to}/${from}/${id}`;
}

/**
 * Ensures file respects size limit and is readable
 * @param file
 */
function checkForFileIssues(file: File) {
  return new Promise((resolve, reject) => {
    if (file.size > config.upload.limit) {
      reject(new Error('sizeLimitExceeded'));
    } else {
      // Detect if the file is a directory
      const reader: FileReader = new (window as any).FileReader();
      reader.onload = () => resolve();
      reader.onerror = () => reject(new Error('unsupportedFileType'));
      reader.readAsText(file);
    }
  });
}

/**
 * Create ticket before uploading the file itself
 * @param token
 * @param url
 * @param file
 */
function createUploadTicket(token: Token, url: string, file: File) {
  return superagent
    .post(url)
    .set('Authorization', `${token.token_type} ${token.access_token}`)
    .send({
      filename: file.name,
      contentType: file.type
    });
}

/**
 * Create the request and send it to upload the attached file
 * @param options
 * @param file
 * @param progressCallback superagent request called regularly on progress
 */
function createFileUploadRequest(
  options: FileUploadTicket,
  file: File,
  progressCallback: (event: ProgressEvent) => void
): Request {
  return superagent
    .post(options.uploadUrl)
    .field(options.formData as any)
    .attach('file', file)
    .on('progress', progressCallback);
}

/**
 * Performs necessary checks and sends the file.
 * Returns the request of the file upload that can be aborted
 * @param options
 * @param file
 * @param progressCallback
 */
export async function send(
  options: FileUploadOptions,
  file: File,
  progressCallback: (event: ProgressEvent) => void
): Promise<{ request: Request; validatedBody: FileUploadTicket }> {
  await checkForFileIssues(file);
  const url = getUrl(options.from, options.to);

  const ticketResponse = await createUploadTicket(options.token, url, file);
  const validatedBody = validate(
    ticketResponse.body,
    responseTypes.createUploadTicket,
    'File Upload Service Ticket Response Validation failed'
  );

  const request = createFileUploadRequest(validatedBody, file, progressCallback);
  return { request, validatedBody }; // prevent promise unwrapping since Request extends Promise<Response>
}
