import { getCountryNameForLocale } from '../app/services/TranslationService';

const locales = {
  en: 'en-US',
  de: 'de-DE',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  pt: 'pt-BR'
};

export const getShortLocale = (locale: string): null | keyof typeof locales => {
  const prefix = locale.substr(0, 2).toLowerCase();
  if (Object.keys(locales).includes(prefix)) {
    return prefix as keyof typeof locales;
  }

  return null;
};

export const getHyphenedLocale = (locale: string): null | string => {
  const normalized = getShortLocale(locale);

  if (normalized) {
    return locales[normalized];
  }

  return null;
};

export const getUnderscoredLocale = (locale: string): null | string => {
  const hyphened = getHyphenedLocale(locale);

  if (!hyphened) {
    return null;
  }

  return hyphened.replace('-', '_');
};

export const translateCountryNames = (values: string[], locale: string) => {
  return values.map((val) => ({
    value: val,
    label: getCountryNameForLocale(val, locale)
  }));
};
