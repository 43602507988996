import { connect } from 'react-redux';
import { About } from './About';
import { Dispatch, State } from '../../../view-model/types';
import machine from '../../../../lib/machine';
import sharedActions from '../../../view-model/sharedActions';

export const mapStateToProps = (state: State) => ({
  // eslint-disable-next-line no-undef
  version: `${VERSION} Build ${BUILD}`,
  email: state.core.user.hasLoaded ? state.core.user.email : '',
  machineId: machine.id,
  license: state.core.user.hasLoaded ? state.core.user.licenseName : '',
  isAdmin: state.core.app.features.includes('hasAccountAdminEntitlement')
});

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    openLink(linkTargetType: string) {
      dispatch(sharedActions.webPageOpened(linkTargetType));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
