import styled from '../../../../styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Link = styled.a`
  color: ${themeGet('link.colors.primary')};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
