import * as React from 'react';
import { ModalDialog } from '../../Common/ModalDialog/ModalDialog';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from '../../../styled-components';
import { PrimaryButton } from '../../Common/Toolkit/Button/PrimaryButton';
import config from 'config';
import { removeProtocol } from '../../../../lib/url';
import { isElectron, isV11 } from '../../../services/ClientService';

const messages = defineMessages({
  title: {
    id: 'electronDecomModal.title',
    defaultMessage: 'Switching From v9 to the Hub'
  },
  info: {
    id: 'electronDecomModal.info',
    defaultMessage:
      'In a few weeks, GoToMeeting will discontinue the v9 desktop app and move everyone over to the Hub, our new and improved online experience.'
  },
  alternative: {
    id: 'electronDecomModal.alternative',
    defaultMessage:
      'You can switch over to the Hub now at {link}. If you continue with v9, we’ll remind you again in a few weeks.'
  },
  ok: {
    id: 'electronDecomModal.ok',
    defaultMessage: 'OK'
  },
  freeUserWarning: {
    id: 'electronDecomModal.freeUserWarning',
    defaultMessage:
      'Have a free GoToMeeting subscription? You’ll need Google Chrome to host and join meetings with the Hub. During the transition, your meeting experience may also vary from time to time.'
  },
  moreInfo: {
    id: 'electronDecomModal.moreInfo',
    defaultMessage: 'More About v9'
  }
});

const Container = styled.div`
  width: 500px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin: 0 20px 10px;
`;

const Title = styled.h4`
  color: #404040;
  font-size: 24px;
  font-family: 'lato-bold', 'Helvetica Neue', arial, sans-serif;
  font-weight: bold;
  margin-bottom: 30px;
`;

const Actions = styled.div`
  box-sizing: border-box;
  min-height: 3em;
  padding: 20px;
  text-align: right;
`;

const Link = styled.a`
  color: #309ddc;
  text-decoration: none;
`;

interface State {
  showElectronDecomModal: boolean;
}

export class ElectronDecomModal extends React.Component<{}, State> {
  state = {
    showElectronDecomModal: true
  };

  private hideElectronDecomModal = () => {
    this.setState({
      showElectronDecomModal: false
    });
  };

  render() {
    return (
      <>
        {isElectron && !isV11 && this.state.showElectronDecomModal && (
          <ModalDialog contentLabel="ElectronDecomModal" onClose={this.hideElectronDecomModal}>
            <Container data-testid="electron-decom-modal">
              <Header>
                <Title>
                  <FormattedMessage {...messages.title} />
                </Title>
                <p>
                  <FormattedMessage {...messages.info} />
                </p>
                <p>
                  <FormattedMessage
                    {...messages.alternative}
                    values={{
                      link: (
                        <Link href={config.externalLinks.globalUrl} target="_blank" rel="noopener noreferrer">
                          {removeProtocol(config.externalLinks.globalUrl)}
                        </Link>
                      )
                    }}
                  />
                </p>
                <p>
                  <FormattedMessage {...messages.freeUserWarning} />
                </p>
                <Link href={config.externalLinks.electronDecommissioning} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage {...messages.moreInfo} />
                </Link>
              </Header>
              <Actions>
                <PrimaryButton onClick={this.hideElectronDecomModal}>
                  <FormattedMessage {...messages.ok} />
                </PrimaryButton>
              </Actions>
            </Container>
          </ModalDialog>
        )}
      </>
    );
  }
}
