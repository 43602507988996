import * as React from 'react';
import { getTimeZonesTranslated } from '../../../services/TimeZoneService';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import styled from '../../../styled-components';
import { InputFieldset } from '../Toolkit/Input/InputFieldset';
import Select, { components } from 'react-select';
import { styleSelect, styledSelectMenu } from '../Toolkit/Input/SelectStyles';
import { themeGet } from '@styled-system/theme-get';

export interface Props {
  value?:
    | string
    | {
        label: string;
        value: string;
      };
  label?: React.ReactChild;
  onChange: (value: string) => void;
  disabled?: boolean;
}

interface State {
  isOpen: boolean;
}

const ShowSelectButton = styled.button`
  background: none;
  border: none;
  color: ${themeGet('colors.primary')};
  cursor: pointer;
  margin: 0 0 4px 4px;
  align-self: flex-start;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledSelect = styleSelect(Select);
const Menu = styledSelectMenu(components.Menu);

class TimeZonePicker extends React.Component<Props & WrappedComponentProps, State> {
  state = {
    isOpen: false
  };

  private onShowSelectButtonClick = () => {
    this.setState({ isOpen: true });
  };

  private onChange = (option: { value: string }) => {
    this.props.onChange(option.value);
  };

  render() {
    const timezones = getTimeZonesTranslated(this.props.intl).map((item) => ({ value: item.region, label: item.zone }));
    const value = this.props.disabled ? null : this.props.value;

    const timezone = typeof value === 'string' ? timezones.find((item) => item.value === value) : value;
    const renderMenu = (props: any) => <Menu {...props} />;

    return this.state.isOpen ? (
      <InputFieldset hideMessage={true} legend={this.props.label} testId="timezone-picker">
        <StyledSelect
          isDisabled={this.props.disabled}
          classNamePrefix="react-select"
          options={timezones}
          value={timezone}
          onChange={this.onChange}
          components={{
            Menu: renderMenu
          }}
          styles={{
            menuPortal: (base: Record<string, any>) => ({ ...base, zIndex: 100 })
          }}
          menuPortalTarget={document.getElementById('app')}
        />
      </InputFieldset>
    ) : (
      <ShowSelectButton
        disabled={this.props.disabled}
        data-testid="show-select-timezone-button"
        onClick={this.onShowSelectButtonClick}
      >
        {this.props.label && <>{this.props.label}: </>}
        {(timezone && timezone.label) || value}
      </ShowSelectButton>
    );
  }
}

export default injectIntl(TimeZonePicker);
