import styled from '../../../styled-components';
import device from '../Toolkit/devices';

export const DashboardContentPane = styled.div.attrs({
  'data-testid': 'meetings-dashboard-content-pane'
})`
  height: 100%;
  display: block;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 32px 32px 0 16px;

  @media ${device.laptop} {
    padding: 32px 24px 0 16px;
  }

  @media ${device.tablet} {
    padding: 32px 16px 0 16px;
  }

  @media ${device.mobile} {
    display: none;
  }
`;
