import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { PrimaryButton } from '../Toolkit/Button/PrimaryButton';
import styled from '../../../styled-components';
import { GhostButton } from '../Toolkit/Button/GhostButton';
import { CenteredSpinner } from '../Toolkit/Spinner/Spinner';

const messages = defineMessages({
  save: {
    id: 'common.preferencesPageLayout.save',
    defaultMessage: 'Save'
  },
  saving: {
    id: 'common.preferencesPageLayout.saving',
    defaultMessage: 'Saving'
  },
  cancel: {
    id: 'common.preferencesPageLayout.cancel',
    defaultMessage: 'Cancel'
  }
});

export const CancelButton = styled(GhostButton)`
  padding: 0 16px;
`;

const PreferencesPage = styled.div`
  max-width: 640px;
  margin: 0 0 30px 4px;
  display: inline-block;
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: 30px;
`;

const ButtonWrapper = styled.div`
  margin: 0 12px;
`;

interface Props {
  children: React.ReactNode;
  disableSaveButton: boolean;
  isFetching: boolean;
  isUpdating: boolean;
  hasChanges: boolean;
  hideButtons: boolean;
  onSave: () => void;
  onCancel: () => void;
}

export const PreferencesPageLayout = (props: Props) => {
  const { disableSaveButton, isFetching, isUpdating, hasChanges, hideButtons, children, onCancel, onSave } = props;

  const disableButtons = !hasChanges || isUpdating || isFetching;
  return (
    <PreferencesPage>
      {isFetching && <CenteredSpinner />}
      {children}
      {!hideButtons && (
        <ButtonBar>
          <ButtonWrapper>
            <CancelButton disabled={disableButtons} onClick={onCancel} data-testid="preferences-cancel">
              <FormattedMessage {...messages.cancel} />
            </CancelButton>
          </ButtonWrapper>
          <PrimaryButton
            disabled={disableButtons || disableSaveButton}
            onClick={onSave}
            loading={isUpdating}
            data-testid="preferences-save"
          >
            <FormattedMessage {...(isUpdating ? messages.saving : messages.save)} />
          </PrimaryButton>
        </ButtonBar>
      )}
    </PreferencesPage>
  );
};
