import React from 'react';
import { Box } from '../../Common/Toolkit/Box/Box';
import styled from 'styled-components';
import { Play, TranscriptChat, ContentChat } from '../../Common/Toolkit/Icon/ReactIcons';
import { Icons } from '../../Common/Toolkit/ICONS';
import { Icon } from '../../Common/Toolkit/Icon/Icon';
import { defineMessages, FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import device from '../../Common/Toolkit/devices';
import { TranscriptIcon } from '../GlobalSearchComponents';
import { ShareResponse } from '../../../view-model/modules/meetings/meetingsReducer';

const messages = defineMessages({
  shareStatus: {
    id: 'recordingView.shareStatus',
    defaultMessage: 'Share Status'
  },
  chat: {
    id: 'recordingView.chat',
    defaultMessage: 'Chat'
  },
  access: {
    id: 'recordingView.access',
    defaultMessage: 'Access'
  },
  expires: {
    id: 'recordingView.expires',
    defaultMessage: 'Expires'
  },
  notApplicable: {
    id: 'recordingView.notApplicable',
    defaultMessage: 'N/A'
  },
  off: {
    id: 'recordingView.off',
    defaultMessage: 'Off'
  },
  anyone: {
    id: 'recordingView.anyone',
    defaultMessage: 'Anyone'
  },
  on: {
    id: 'recordingView.on',
    defaultMessage: 'On'
  },
  specificPeople: {
    id: 'recordingView.specificPeople',
    defaultMessage: 'Specific People'
  },
  transcripts: {
    id: 'recordingView.transcripts',
    defaultMessage: 'Transcripts'
  },
  recording: {
    id: 'recordingView.recording',
    defaultMessage: 'Recording'
  },
  notes: {
    id: 'recordingView.notes',
    defaultMessage: 'Notes'
  },
  meetingInfo: {
    id: 'recordingView.meetingInfo',
    defaultMessage: 'Meeting info'
  },
  sharedContent: {
    id: 'recordingView.sharedContent',
    defaultMessage: 'Shared content'
  }
});

const RecordingViewContainer = styled(Box)`
  border-radius: 4px;
  border: solid 1px #dee4ec;
  background-color: #f7f8fa;
  padding: 16px 16px 24px 24px;
  margin-top: 20px;
`;

const ShareTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 4px;
`;

const ShareContent = styled.div`
  display: flex;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const AccessContainer = styled.div`
  flex: 1 1 0px;
  border-right: 1px solid rgba(76, 83, 93, 0.24);

  @media ${device.mobile} {
    border-right: none;
    border-bottom: 1px solid rgba(76, 83, 93, 0.24);
    padding-bottom: 16px;
  }
`;

const SharedContainer = styled.div`
  flex: 1 1 0px;
  margin-left: 24px;
  @media ${device.mobile} {
    margin-left: 0px;
    padding-top: 16px;
  }
`;

const ShareContentHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-right: 6px;
`;

const TextContainer = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 1.4;
`;

const ShareHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IconContainer = styled(Box)`
  margin-top: 6px;
  svg {
    margin-right: 12px;
  }
`;

interface Props {
  recording: {
    recording?: {
      stored?: boolean;
      downloadUrl?: string;
    };
    shareRecording?: ShareResponse;
  };
}

export const RecordingView: React.FC<Props> = (props: Props) => {
  const { formatMessage: f } = useIntl();
  const expireTimestamp = props.recording?.shareRecording?.expires;
  const isSharingExpired = expireTimestamp && expireTimestamp - new Date().getTime() < 0;
  const recordingIsProcessing =
    props.recording?.recording && (!props.recording.recording?.stored || !props.recording.recording?.downloadUrl);
  const sharingOff = isSharingExpired || recordingIsProcessing;
  const sharingConst = {
    PUBLIC: 'PUBLIC',
    EMAIL: 'EMAIL',
    PRIVATE: 'PRIVATE',
    SHARING_OFF: 'SHARING_OFF'
  };
  const isShared = (
    resource: string,
    resources = props.recording?.shareRecording && props.recording.shareRecording?.sharedResources
  ) => resources && resources.includes(resource);

  const notesShared = isShared('NOTES');
  const transcriptShared = isShared('TRANSCRIPT');
  const videoShared = isShared('VIDEO');
  const sessionInfoShared = isShared('SESSION_INFO');
  const chatsShared = isShared('INSESSION_CHAT');

  const accessCriteria = (isSharingOff: boolean | undefined) => {
    let accessStatus;
    let shareStatus;
    let expiryStatus;
    let scope;
    if (
      (props.recording.shareRecording &&
        props.recording.shareRecording.scope &&
        props.recording.shareRecording.scope.length) ||
      recordingIsProcessing
    ) {
      scope = isSharingOff ? sharingConst.SHARING_OFF : props.recording?.shareRecording?.scope[0].type;
      switch (scope) {
        case sharingConst.PUBLIC:
          accessStatus = <FormattedMessage {...messages.anyone} />;
          shareStatus = <FormattedMessage {...messages.on} />;
          break;
        case sharingConst.EMAIL:
          accessStatus = <FormattedMessage {...messages.specificPeople} />;
          shareStatus = <FormattedMessage {...messages.on} />;
          break;
        case sharingConst.PRIVATE:
        case sharingConst.SHARING_OFF:
          accessStatus = <FormattedMessage {...messages.notApplicable} />;
          shareStatus = <FormattedMessage {...messages.off} />;
          expiryStatus = <FormattedMessage {...messages.notApplicable} />;
          break;
        default:
          accessStatus = '';
          shareStatus = '';
          expiryStatus = '';
          break;
      }
    }
    return { accessStatus, shareStatus, expiryStatus, scope };
  };
  const accessCriteriaProp = accessCriteria(sharingOff);
  return (
    <RecordingViewContainer flexDirection={'column'}>
      <ShareHeader>
        <ShareTitle>
          {f(messages.shareStatus)}: {accessCriteriaProp.shareStatus}
        </ShareTitle>
      </ShareHeader>
      <ShareContent>
        <AccessContainer>
          <Box alignItems={'baseline'}>
            <ShareContentHeading>{f(messages.access)}:</ShareContentHeading>
            <TextContainer>{accessCriteriaProp.accessStatus}</TextContainer>
          </Box>
          <Box alignItems={'baseline'}>
            <ShareContentHeading>{f(messages.expires)}:</ShareContentHeading>
            <TextContainer>
              {accessCriteriaProp.scope !== sharingConst.PRIVATE && !sharingOff && expireTimestamp ? (
                <FormattedDate month="short" day="numeric" year="numeric" value={expireTimestamp} />
              ) : (
                accessCriteriaProp.expiryStatus
              )}
            </TextContainer>
          </Box>
        </AccessContainer>
        <SharedContainer>
          <Box flexDirection={'column'}>
            <ShareContentHeading>{f(messages.sharedContent)}</ShareContentHeading>
            <IconContainer>
              <Play
                data-tip={f(messages.recording)}
                data-place="bottom"
                size={25}
                unit={'px'}
                color={!sharingOff && videoShared ? '#4C535D' : '#C4C6C9'}
              />
              <TranscriptIcon
                tooltip={f(messages.transcripts)}
                color={!sharingOff && transcriptShared ? '#4C535D' : '#C4C6C9'}
                border={!sharingOff && transcriptShared ? '#4C535D' : '#C4C6C9'}
              />
              <TranscriptChat
                data-place="bottom"
                size={26}
                unit={'px'}
                data-tip={f(messages.notes)}
                feColorMatrix={
                  !sharingOff && notesShared
                    ? '0.3 0 0 0 0 0 0.33 0 0 0 0 0 0.36 0 0 0 0 0 1 0'
                    : '0.77 0 0 0 0 0 0.78 0 0 0 0 0 0.79 0 0 0 0 0 1 0'
                }
                color={!sharingOff && notesShared ? '#4C535D' : '#C4C6C9'}
              />
              <ContentChat
                data-place="bottom"
                size={25}
                unit={'px'}
                data-tip={f(messages.chat)}
                color={!sharingOff && chatsShared ? '#4c535d' : '#C4C6C9'}
              />
              <div data-tip={f(messages.meetingInfo)} data-place="bottom">
                <Icon color={!sharingOff && sessionInfoShared ? '#4c535d' : '#C4C6C9'} icon={Icons.info} size={25} />
              </div>
            </IconContainer>
          </Box>
        </SharedContainer>
      </ShareContent>
    </RecordingViewContainer>
  );
};
