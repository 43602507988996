import React from 'react';
import { useIntl, defineMessages, MessageDescriptor } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { PreferencesBadge } from './PreferencesBadge/PreferencesBadge';
import styled from '../../../styled-components';
import { Info } from '../Toolkit/Icon/ReactIcons';
import { themeGet } from '@styled-system/theme-get';

export const messages = defineMessages({
  learnMore: {
    id: 'meetingPreferences.learnMore',
    defaultMessage: 'Learn more'
  }
});

export const LearnMoreLink = styled.a`
  color: #309ddc;
  text-decoration: none;
  padding: 0 0 0 10px;
`;

const Title = styled.h4`
  font-family: 'lato-regular', 'Helvetica Neue', arial, sans-serif;
  font-size: 18px;
  color: ${themeGet('colors.backgroundOn')};
  margin: 0;
  display: inline;
`;

const Block = styled.div<{ disabled: boolean }>`
  ${Title} {
    ${(props) => props.disabled && `color: #8c8c8c;`}
  }

  ${PreferencesBadge} {
    margin-left: 8px;
  }
`;

export const InfoIcon = styled(Info)`
  font-size: 21px;
  margin: -6px 0 -3px 2px;
  display: inline-block;
  cursor: pointer;

  path {
    fill: #485164;
  }
`;

export const TooltipInner = styled.div`
  max-width: 300px;
`;

interface Props {
  id?: string;
  titleMessage: MessageDescriptor;
  disabled?: boolean;
  learnMoreLink?: string;
  learnMoreLinkLabel?: string;
  learnMoreLinkIcon?: JSX.Element;
  infoMessage?: MessageDescriptor;
  badge?: 'new' | 'beta';
}

export const PreferencesHeader = ({
  id,
  titleMessage,
  learnMoreLink,
  learnMoreLinkLabel,
  learnMoreLinkIcon,
  disabled = false,
  infoMessage,
  badge
}: Props) => {
  const { formatMessage: f } = useIntl();
  return (
    <Block disabled={disabled}>
      <Title>{f(titleMessage)}</Title>
      {badge && <PreferencesBadge data-testid={`is-${badge}-badge`} badge={badge} />}
      {learnMoreLink && (
        <LearnMoreLink data-testid="learn-more-link" target="_blank" rel="noopener noreferrer" href={learnMoreLink}>
          {learnMoreLinkLabel ? learnMoreLinkLabel : f(messages.learnMore)}
          {learnMoreLinkIcon}
        </LearnMoreLink>
      )}
      {infoMessage && (
        <>
          <span data-tip data-for={`${id}-info-tooltip`}>
            <InfoIcon />
          </span>
          <ReactTooltip id={`${id}-info-tooltip`} place="right" effect="solid">
            <TooltipInner data-testid={`${id}-info-tooltip-inner`}>{f(infoMessage)}</TooltipInner>
          </ReactTooltip>
        </>
      )}
    </Block>
  );
};
