import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../view-model/types';
import { Box, Item } from '../../Common/Toolkit/Box/Box';
import { Clock } from '../../Common/Toolkit/Icon/ReactIcons';
import { GlobalSearchMessages as messages } from '../GlobalSearchMessages';
import styled from '../../../styled-components';
import device from '../../Common/Toolkit/devices';
import { formatTime, getRoundedDurationInMinutes } from '../../../../lib/date';
import { FormattedDate, useIntl } from 'react-intl';
import { RecordingView } from './RecordingView';
import { AttendeeView } from './AttendeeView';
import { ActionButtonsView } from './ActionButtonsView';
import { User } from '../../../view-model/modules/core/user/userReducer';
import { Spinner } from '../../Common/Toolkit/Spinner/Spinner';
import { SelectableItemType, Session } from '../../../view-model/modules/meetings/MeetingsTypes';
import { MeetingTitle } from '../../Meetings/MeetingDetails/MeetingTitle/MeetingTitle';
import { SessionUpdates } from '../../../view-model/modules/meetings/meetingsReducer';

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: wrap;
`;

const ResultItemTime = styled(Item)`
  flex: 0 0 40px;
  text-align: center;
  padding: 0 18px 0 0;
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 24px;

  @media ${device.mobile} {
    padding: 0 16px 0 0;
  }
`;

const ItemDate = styled(Item)`
  font-size: 32px;
`;

const SearchDetails = styled(Item)`
  font-size: 16px;
  margin-right: 10px;
  @media ${device.mobile} {
    margin-top: 5px;
  }
`;

const IconContainer = styled(({ component, ...props }) => React.cloneElement(component, props))`
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
  margin-bottom: 1px;
`;

const SearchText = styled.span`
  vertical-align: middle;
`;

const MeetingDuration = styled(SearchText)`
  border-right: 1px solid #25282d;
  padding-right: 10px;
  @media ${device.mobile} {
    border: none;
  }
`;

const MeetingsTitle = styled.h3<{ color?: string; textAlign?: string; whiteSpace?: string }>`
  margin: -4px 0 5px 0;
  padding-top: 4px;
  font-size: 28px;
  line-height: 24px; /* work around so that emojis don't break the line */
  font-family: Lato-bold;
  font-weight: bold;
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;

  em {
    background: #fff7d9;
    font-style: normal;
  }
`;

const ItemMonth = styled(MeetingsTitle)`
  color: #e02d52;
  font-size: 14px;
  font-weight: 600;
  margin: -4px 0 3px 0;
  text-transform: uppercase;
`;

const TranscriptsSubject = styled(Item)`
  flex: 1;
  display: flex;
  flex-direction: column;
  display: inline-grid;
  word-break: break-all;
  padding-top: 3px;
`;

const SearchViewContainer = styled.div<{ view?: boolean }>`
  padding: 24px 32px 24px 29px;
  margin-top: ${(props) => (props.view ? '70px' : '0px')};

  @media ${device.laptop} {
    padding: 16px 20px;
  }

  @media ${device.mobile} {
    padding: 16px;
    margin-top: 0px;
    max-height: 468px;
    overflow: scroll;
  }
`;

const SearchViewSpinner = styled((props: any) => (
  <div className={props.className}>
    <Spinner />
  </div>
))`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 32px 24px 29px;
  margin-top: ${(props) => (props.view ? '70px' : '0px')};
`;

interface Props {
  userSettings: User['settings'];
  session?: Session;
  handleOpenRecording: () => void;
  onDownloadRecording: () => void;
  showDeleteRecordingConfirmationModal: () => void;
  enableOnClickOutside: () => void;
  copyStandalonePlayerUrl: (meetingId: string, recordingId: string) => void;
  showRecordingAssetModal: () => void;
  editMeetingTitle: (sessionId: string, updatedSession: SessionUpdates) => void;
  meetingTitleEdited: (view: string) => void;
  showMeetingTitleEditModal: () => void;
  hideMeetingTitleEditModal: () => void;
  updateMeetingTitleEditError: (error: boolean) => void;
}

export const SelectedSearchResultView: React.FC<Props> = (props: Props) => {
  const session = props.session || useSelector((state: State) => state.meetings.elasticSearchSession);
  const selectedItemType = useSelector((state: State) => state.meetings.selectedItemType);
  const fetching = useSelector((state: State) => state.meetings.openElasticSearchSession);
  const canUserEditMeetingTitle = useSelector((state: State) =>
    state.core.app.features.includes('meeting-title-edit-enabled')
  );
  const intl = useIntl();
  const { formatMessage: f } = intl;

  useEffect(() => {
    props.enableOnClickOutside();
  }, [session?.sessionId]);

  if (fetching) {
    return <SearchViewSpinner view={selectedItemType === SelectableItemType.ELASTICSEARCHRESULTSELECTED} />;
  }

  if (session && Object.keys(session).length) {
    const title = session.subject;
    const meetingStartTime = session.startTime ? formatTime(intl, session.startTime) : '';
    const duration =
      session.endTime && getRoundedDurationInMinutes(new Date(session.startTime), new Date(session.endTime));
    const month = session.startTime ? <FormattedDate value={new Date(session.startTime)} month="short" /> : '';
    const date = session.startTime ? new Date(session.startTime).getDate() : '';

    return (
      <SearchViewContainer view={selectedItemType === SelectableItemType.ELASTICSEARCHRESULTSELECTED}>
        <Box flexDirection="row">
          <ResultItemTime>
            <ItemMonth>{month}</ItemMonth>
            <ItemDate>{date}</ItemDate>
          </ResultItemTime>
          <TranscriptsSubject>
            {canUserEditMeetingTitle ? (
              <MeetingTitle
                meetingTitle={title}
                session={session}
                updateMeetingTitle={props.editMeetingTitle}
                meetingTitleEdited={props.meetingTitleEdited}
                showMeetingTitleEditModal={props.showMeetingTitleEditModal}
                hideMeetingTitleEditModal={props.hideMeetingTitleEditModal}
                updateMeetingTitleEditError={props.updateMeetingTitleEditError}
              />
            ) : (
              <MeetingsTitle dangerouslySetInnerHTML={{ __html: title }} data-testid={'meeting-title-input'} />
            )}

            <DetailsContainer>
              <SearchDetails>
                <IconContainer component={<Clock />} />
                <MeetingDuration>
                  {meetingStartTime}
                  {duration ? ` - ${duration} ${f(messages.min)}` : ''}
                </MeetingDuration>
              </SearchDetails>
              <SearchDetails>
                <SearchText>ID: {session.meetingId}</SearchText>
              </SearchDetails>
            </DetailsContainer>
          </TranscriptsSubject>
        </Box>
        {session.recording && (
          <ActionButtonsView
            userSettings={props.userSettings}
            handleOpenRecording={props.handleOpenRecording}
            onDownloadRecording={props.onDownloadRecording}
            showDeleteRecordingConfirmationModal={props.showDeleteRecordingConfirmationModal}
            session={session}
            copyStandalonePlayerUrl={props.copyStandalonePlayerUrl}
            showRecordingAssetModal={props.showRecordingAssetModal}
          />
        )}
        {session.recording && <RecordingView recording={session} />}
        <AttendeeView attendeeList={session.attendees} sessionId={session.sessionId} />
      </SearchViewContainer>
    );
  }

  return (
    <SearchViewContainer view={selectedItemType === SelectableItemType.ELASTICSEARCHRESULTSELECTED}>
      No Result Found
    </SearchViewContainer>
  );
};
