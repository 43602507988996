import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { ButtonBar } from '../../Common/Toolkit/Button/ButtonBar';
import { ModalDialogContentWrapper } from '../../Common/ModalDialog/ModalDialogContentWrapper';

const messages = defineMessages({
  title: {
    id: 'meetingDashboard.meetingDelete.title',
    defaultMessage: 'Delete This Meeting?'
  },
  hintMessage: {
    id: 'meetingDashboard.meetingDelete.hintMessage',
    defaultMessage: "You'll need to remove it from your calendar as well."
  },
  cancel: {
    id: 'meetingDashboard.meetingDelete.cancel',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'meetingDashboard.meetingDelete.delete',
    defaultMessage: 'Delete'
  }
});

export interface Props {
  meetingId: string;
  canDelete: boolean;
  deleteMeeting: (meetingId: string) => void;
  hideDeleteMeeting: () => void;
}

export const MeetingDelete: React.FC<Props> = ({ meetingId, canDelete, deleteMeeting, hideDeleteMeeting }) => {
  const deleteMeetingAndHide = () => {
    deleteMeeting(meetingId);
    hideDeleteMeeting();
  };

  return (
    <ModalDialogContentWrapper onClose={hideDeleteMeeting} title={<FormattedMessage {...messages.title} />}>
      <FormattedMessage {...messages.hintMessage} />
      <ButtonBar
        buttonOk={<FormattedMessage {...messages.delete} />}
        buttonCancel={<FormattedMessage {...messages.cancel} />}
        onOk={deleteMeetingAndHide}
        onCancel={hideDeleteMeeting}
        okDisabled={!canDelete}
      />
    </ModalDialogContentWrapper>
  );
};
