import * as React from 'react';
import { Box, Item } from '../../Common/Toolkit/Box/Box';
import { formatTime, getRoundedDurationInMinutes } from '../../../../lib/date';
import { formatId } from '@getgo/format-meeting-id';
import { MeetingsTabContainer, MeetingsTabSubject, MeetingsTabTime, MeetingsTabTitle } from '../MeetingComponents';
import styled from '../../../styled-components';
import { Start } from '../../Common/Toolkit/Icon/ReactIcons';
import device from '../../Common/Toolkit/devices';
import { defineMessages, useIntl } from 'react-intl';
import { Session } from 'src/app/view-model/modules/meetings/MeetingsTypes';

const messages = defineMessages({
  recodingTitle: {
    id: 'meetingHub.historyMeetings.recordedTitle',
    defaultMessage: 'recording available'
  }
});

export interface Props {
  session: Session;
  className?: string;
}

const HistoryMeetingsTabContainer = styled(MeetingsTabContainer)`
  position: relative;
`;

export const RecordingAvailableIcon = styled((props: { className?: string }) => {
  const { formatMessage: f } = useIntl();
  return (
    <Start
      className={props.className}
      data-testid="recording-icon"
      size={16}
      unit={'px'}
      title={f(messages.recodingTitle)}
    />
  );
})`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AttendeeList = styled.div`
  flex: 1 1 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 24px;
  line-height: 18px; /* work around so that emojis don't break the line */

  @media ${device.tablet} {
    display: none;
  }

  @media ${device.mobile} {
    display: block;
  }
`;

export const HistoryMeetingItem: React.FC<Props> = (props) => {
  const intl = useIntl();
  const formattedId = formatId(props.session.meetingId);
  const filteredAttendees = props.session.attendees.filter((attendee) => attendee.name);
  let attendeesNameList = '';
  filteredAttendees.forEach((attendee, index) => {
    if (index === 0) {
      attendeesNameList += attendee.name;
    } else {
      attendeesNameList += `, ${attendee.name}`;
    }
  });

  const startDate = new Date(props.session.startTime);
  /* session.endTime does not exist for sessions that have recordings and are under processing */
  const endDate = props.session.endTime ? new Date(props.session.endTime) : undefined;

  const startTimeString = formatTime(intl, startDate);
  const duration = endDate && getRoundedDurationInMinutes(startDate, endDate);

  return (
    <HistoryMeetingsTabContainer className={props.className}>
      <Box flexDirection="row">
        <MeetingsTabTime>
          <MeetingsTabTitle>{startTimeString}</MeetingsTabTitle>
          {endDate ? <Item>{duration} min</Item> : null}
        </MeetingsTabTime>
        <MeetingsTabSubject>
          <MeetingsTabTitle data-testid="meeting-subject">{props.session.subject}</MeetingsTabTitle>
          <DetailsContainer>
            <Item>ID: {formattedId}</Item>
            <AttendeeList>{attendeesNameList}</AttendeeList>
          </DetailsContainer>
        </MeetingsTabSubject>
        {props.session.recording && <RecordingAvailableIcon />}
      </Box>
    </HistoryMeetingsTabContainer>
  );
};
