import * as React from 'react';
import { WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { getRouteFor } from '../../services/NavService';
import { PersonalizeMeetingModal } from './MeetingScheduler/PersonalizeMeetingModal';
import { ErrorWindowModal } from './ErrorWindow/ErrorWindowModal';
import { MeetingDeleteModal } from './MeetingDelete/MeetingDeleteModal';
import { User, State as UserState } from '../../view-model/modules/core/user/userReducer';
import MeetingsDashboardHeader, { meetingDashboardMessages } from './MeetingsDashboardHeader';
import styled from '../../styled-components';
import {
  ErrorWindowProps,
  Invitation,
  MeetingsState,
  PersonalizeMeetingProps,
  SessionUpdates
} from '../../view-model/modules/meetings/meetingsReducer';
import { PersonalMeeting } from './PersonalMeeting';
import device from '../Common/Toolkit/devices';
import { MeetingInSessionModal } from './MeetingInSession/MeetingInSessionModal';
import { MeetingDetailsWrapper } from './MeetingDetails/MeetingDetailsWrapper';
import { ProfilePreviewModal } from './MeetingScheduler/ProfilePreviewModal';
import { MeetingSchedulerModal } from './MeetingScheduler/MeetingSchedulerModal';
import {
  MeetingRequest,
  State as Scheduler,
  SchedulerFullMeeting
} from '../../view-model/modules/scheduler/schedulerReducer';
import { SchedulerTabs } from './MeetingScheduler/MeetingScheduler';
import { BuyNowBar } from './BuyNowBar';
import InterstitialContainer from '../../containers/MeetingDashboard/MeetingScheduler/InterstitialContainer';
import { CalendarAdd } from '../Common/Toolkit/Icon/ReactIcons';
import { ScheduleMeetingButton } from './MeetingComponents';
import { CreateMeetingResponse } from '../../../models/Meeting';
import Scrollbars from 'react-custom-scrollbars';
import { DashboardContainer } from '../Common/Dashboard/DashboardContainer';
import AnytimeMeetings from '../../containers/AnytimeMeetingsContainer';
import UpcomingMeetings from '../../containers/UpcomingMeetingsContainer';
import { HistoryMeetings } from './MeetingsHistory/HistoryMeetings';
import { NotFoundRedirect } from '../Routing/NotFoundRedirect';
import { themeGet } from '@styled-system/theme-get';
import { Meeting, Profile, SelectableItemType, Session } from '../../view-model/modules/meetings/MeetingsTypes';

// todo refactor all upcoming to onetime (?!) classnames etc
const messages = defineMessages({
  upcoming: {
    id: 'meetingHub.upcoming',
    defaultMessage: 'One-Time'
  },
  anytime: {
    id: 'meetingHub.anytime',
    defaultMessage: 'Rooms'
  },
  history: {
    id: 'meetingHub.history',
    defaultMessage: 'History'
  }
});

const MainContainer = styled.div<{ showBuyNowBar?: boolean }>`
  height: ${(props) => (props.showBuyNowBar ? 'calc(100% - 48px)' : '100%')};
  display: flex;
  margin-bottom: ${(props) => props.showBuyNowBar && '48px'};
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 438px;
  padding: 32px 0 0 16px;

  @media ${device.mobile} {
    padding: 8px 0 0 0;
    margin: 0;
  }

  @media ${device.laptop} {
    flex: 0 0 380px;
    padding: 32px 0 0 8px;
  }

  @media ${device.tablet} {
    flex: 0 0 320px;
    padding: 32px 0 4px;
  }

  @media ${device.mobile} {
    width: unset;
    max-width: none;
    flex: 1 1 0%;
    padding: 8px 0 4px;
  }
`;

const Nav = styled.ul`
  list-style-type: none;
  margin: 8px 16px 0 16px;
  padding: 0;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #9aa8ba;
  flex: 0 0 auto;

  @media ${device.mobile} {
    margin: 8px 8px 0 8px;
  }
`;

// color: #25282d;
const LinkHighlighter = styled.div<{ active: boolean }>`
  border-top: 4px solid ${(props) => (props.active ? '#f68d2e' : 'transparent')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const Tab = styled((props) => {
  return (
    <li className={props.className} onClick={props.onClick} data-testid={props.dataTestId}>
      {props.children}
      <LinkHighlighter active={props.active} />
    </li>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  justify-content: center;
  position: relative;
  a {
    display: block;
    padding: 8px;
    text-decoration: inherit;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: lato-bold;
    color: ${themeGet('colors.surfaceOn')};
    text-align: center;
    box-sizing: border-box;
  }
`;

const PersonalMeetingCardPaddingWrapper = styled.div`
  margin: 0;
  padding: 0 16px;
  flex-shrink: 0;
  @media ${device.mobile} {
    padding: 0 8px;
  }
`;

const CustomVerticalBar = styled.div`
  right: 0;
  top: 0;
  height: 100%;
  border-radius: 5px;
  display: none;

  @media ${device.mobile} {
    display: block;
  }
`;

const CustomHorizontalBar = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  border-radius: 5px;
  display: none;

  @media ${device.mobile} {
    display: block;
  }
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
const MobileOnlyScrollbars = styled(({ hideScrollbar, ...props }) => <Scrollbars {...props} />)<{
  hideScrollbar: boolean;
}>`
  flex: 1;
  > ${CustomVerticalBar} {
    transition: opacity 500ms;
    opacity: 0;

    display: ${(props) => (props.hideScrollbar ? 'none' : 'block')};
  }
  &:hover {
    > ${CustomVerticalBar} {
      opacity: 1;
      transition: opacity 200ms;
    }
  }
`;

/* Supporting outdated MFE routes TODO: replace after MFE decommission */
const anytimeHighlightPaths = [
  '/schedule',
  '/setup',
  '/labs',
  '/reserve',
  '/beta',
  '/betas',
  '/trialEnding',
  '/meetings*details',
  '/sessionInProgress',
  '/inviteUsers',
  '/number-change',
  '/codec',
  '/pageNotFound',
  '/meetings/upcoming',
  '/unsupportedBrowser'
];
const historyHighlightPaths = ['/meetings/past'];

const MeetingDashboardRoutes = () => (
  <Switch>
    <Route exact path="/schedule" component={AnytimeMeetings} />
    <Route exact path={getRouteFor('upcomingMeetings')} component={UpcomingMeetings} />
    <Route exact path={getRouteFor('anytimeMeetings')} component={AnytimeMeetings} />
    <Route exact path={getRouteFor('historyMeetings')} component={HistoryMeetings} />
    <Route exact path={getRouteFor('meetings')} />
    <Route component={NotFoundRedirect} />
  </Switch>
);

interface Props extends RouteComponentProps<any, any> {
  scheduler: Scheduler;
  currentPersonalizeMeeting: null | PersonalizeMeetingProps;
  profilePreview: null | {
    theme: string;
  };
  user: UserState;
  isOnline: boolean;
  isFetching: boolean;
  isFetchingProfile: boolean;
  brandingEnabled: boolean;
  fetchMyMeetings: () => void;
  fetchProfile: () => void;
  startMeeting: (meetingId: string, meetingType: SelectableItemType) => Promise<void>;
  invitationCopied: () => void;
  showDeleteMeeting: (meetingId: string) => void;

  showPersonalizeMeeting: () => void;
  hidePersonalizeMeeting: () => void;
  schedulerChangeProperty: (property: keyof SchedulerFullMeeting, value: any) => void;
  schedulerValidationError: (property: keyof SchedulerFullMeeting, tab: SchedulerTabs) => void;
  schedulerValidationSuccess: (property: keyof SchedulerFullMeeting, tab: SchedulerTabs) => void;
  createMeeting: (meeting: MeetingRequest) => Promise<void>;
  updateMeeting: (
    meetingId: string,
    meeting: MeetingRequest,
    updatedMeeting: MeetingRequest,
    isPersonalMeeting: boolean
  ) => Promise<void>;

  showCreatePersonalMeeting: () => void;
  suggestProfileId: (params: { firstName: string; lastName: string }) => void;

  showProfilePreview: (theme: string, roomDisplayName?: string) => void;
  hideProfilePreview: () => void;

  updateProfile: (profileId: string, profile: Profile) => void;
  createProfile: (profile: Profile, subject?: string) => void;

  showCreateMeeting: (user: User) => void;
  showEditMeeting: (meetingId: string) => void;

  interstitialCopyInvitation: (meeting: Meeting, profile?: Profile, dontShowAgain?: boolean) => void;

  changeInvitation: (meetingId: string, invitation: string) => void;

  hideScheduleMeeting: () => void;
  joinMeeting: (meetingId: string) => void;
  endMeeting: (meeting: Meeting) => void;
  meetNow: () => Promise<CreateMeetingResponse>;
  deleteSessionRecording: (sessionId: string, meetingId: string, recordingId: string) => void;
  recordingSessionOpened: () => void;
  downloadSessionRecording: (meetingId: string, recordingId: string) => void;
  copyStandalonePlayerUrl: (meetingId: string, recordingId: string) => void;

  reportNavigation: (destination: string) => void;

  profile?: Profile;
  profileMeeting: Meeting | null;

  errorWindow: ErrorWindowProps | null;
  hideErrorWindow: () => void;

  selectedItemType: SelectableItemType;
  selectedItem: Meeting | Session | null;

  selectMeeting: (itemType: SelectableItemType, id: string | null) => void;

  /* TODO: refactor meetingsReducer/actions to fit the new use cases */
  currentDeleteMeeting: MeetingsState['currentDeleteMeeting'];
  currentMeetingIsInSessionErrorData: MeetingsState['currentMeetingIsInSessionErrorData'];
  deleteMeeting: (meetingId: string) => void;
  hideDeleteMeeting: () => void;
  hideMeetingInSessionError: () => void;

  invitation: Invitation | null;

  isFreeUser: boolean;
  isTrialUser: boolean;
  trialExpiresInDays: number;
  elasticSearchContentList: {
    [key: string]: any;
  };
  elasticSearchPagesInfo: {
    [key: string]: any;
  };
  isFetchingElasticSearch: boolean;
  elasticSearchSession: null | Session;
  updateMeetingSessionData: (sessionId: string, updatedSession: SessionUpdates) => void;
  meetingTitleEdited: (view: string) => void;
  updateMeetingTitleEditError: (error: boolean) => void;
}

class MeetingsDashboard extends React.Component<Props & WrappedComponentProps> {
  componentDidMount(): void {
    this.props.fetchProfile();
  }

  private onSchedule = () => {
    if (this.props.user?.hasLoaded) {
      this.props.showCreateMeeting(this.props.user);
    }
  };

  private reportNavigation = (event: React.MouseEvent) => {
    const element = event.currentTarget as HTMLAnchorElement;
    this.props.reportNavigation(element.hash);
  };

  render() {
    if (!this.props.user.hasLoaded) {
      return null;
    }

    const { user, selectedItemType } = this.props;
    const { formatMessage } = this.props.intl;
    const showBuyNowBar = (this.props.isTrialUser && this.props.trialExpiresInDays >= 0) || this.props.isFreeUser;

    const personalMeetingCard = (
      <PersonalMeetingCardPaddingWrapper>
        <PersonalMeeting
          selected={selectedItemType === SelectableItemType.PERSONAL && this.props.profile != null}
          selectMeeting={this.props.selectMeeting}
          isFetchingProfile={this.props.isFetchingProfile}
          profile={this.props.profile}
          showCreatePersonalMeeting={this.props.showCreatePersonalMeeting}
        />
      </PersonalMeetingCardPaddingWrapper>
    );

    const profilePreviewModal = !!this.props.profilePreview && (
      <ProfilePreviewModal
        hideProfilePreview={this.props.hideProfilePreview}
        userKey={this.props.user.key}
        {...this.props.profilePreview}
      />
    );

    const personalizeMeetingModal = !!this.props.currentPersonalizeMeeting &&
      (!!this.props.profile || !this.props.isFetchingProfile) && (
        <PersonalizeMeetingModal
          hidePersonalizeMeeting={this.props.hidePersonalizeMeeting}
          updateProfile={this.props.updateProfile}
          createProfile={this.props.createProfile}
          showProfilePreview={this.props.showProfilePreview}
          suggestProfileId={this.props.suggestProfileId}
          profile={this.props.profile}
          user={this.props.user}
          brandingEnabled={this.props.brandingEnabled}
          {...this.props.currentPersonalizeMeeting}
        />
      );

    const errorWindow = !!this.props.errorWindow && (
      <ErrorWindowModal onClose={this.props.hideErrorWindow} {...this.props.errorWindow} />
    );

    const schedulerInterstitial = this.props.scheduler.interstitial.show && <InterstitialContainer />;

    const schedulerModal = !this.props.scheduler.interstitial.show && !!this.props.scheduler.current && (
      <MeetingSchedulerModal
        hideScheduleMeeting={this.props.hideScheduleMeeting}
        onPropertyChange={this.props.schedulerChangeProperty}
        onValidationError={this.props.schedulerValidationError}
        onValidationSuccess={this.props.schedulerValidationSuccess}
        showProfilePreview={this.props.showProfilePreview}
        createMeeting={this.props.createMeeting}
        updateMeeting={this.props.updateMeeting}
        user={this.props.user}
        profile={this.props.profile}
        brandingEnabled={this.props.brandingEnabled}
        {...this.props.scheduler.current}
      />
    );

    const deleteModal = this.props.currentDeleteMeeting ? (
      <MeetingDeleteModal
        {...this.props.currentDeleteMeeting}
        hideDeleteMeeting={this.props.hideDeleteMeeting}
        deleteMeeting={this.props.deleteMeeting}
      />
    ) : null;

    const inSessionModal = this.props.currentMeetingIsInSessionErrorData ? (
      <MeetingInSessionModal
        {...this.props.currentMeetingIsInSessionErrorData}
        hideMeetingInSessionError={this.props.hideMeetingInSessionError}
        fetchMyMeetings={this.props.fetchMyMeetings}
      />
    ) : null;

    return (
      <DashboardContainer>
        <MeetingsDashboardHeader user={user} isOnline={this.props.isOnline} joinMeeting={this.props.joinMeeting} />
        <MobileOnlyScrollbars
          hideScrollbar={selectedItemType !== SelectableItemType.NONE}
          renderTrackVertical={(props: any) => <CustomVerticalBar {...props} />}
          renderTrackHorizontal={(props: any) => <CustomHorizontalBar {...props} />}
        >
          <MainContainer showBuyNowBar={showBuyNowBar} role="main">
            <Sidebar>
              {personalMeetingCard}
              <ScheduleMeetingButton
                disabled={!this.props.isOnline}
                onClick={this.onSchedule}
                data-testid="create-meeting-button"
              >
                <CalendarAdd />
                {formatMessage(meetingDashboardMessages.schedule)}
              </ScheduleMeetingButton>
              <Nav data-testid="meetings-dashboard-nav">
                <Tab
                  dataTestId="rooms-tab"
                  active={
                    this.props.location.pathname === getRouteFor('anytimeMeetings') ||
                    anytimeHighlightPaths.includes(this.props.location.pathname)
                  }
                >
                  <Link to={getRouteFor('anytimeMeetings')} onClick={this.reportNavigation}>
                    {formatMessage(messages.anytime)}
                  </Link>
                </Tab>
                <Tab
                  dataTestId="one-time-tab"
                  active={this.props.location.pathname === getRouteFor('upcomingMeetings')}
                >
                  <Link to={getRouteFor('upcomingMeetings')} onClick={this.reportNavigation}>
                    {formatMessage(messages.upcoming)}
                  </Link>
                </Tab>
                <Tab
                  dataTestId="history-tab"
                  active={
                    this.props.location.pathname === getRouteFor('historyMeetings') ||
                    historyHighlightPaths.includes(this.props.location.pathname)
                  }
                >
                  <Link to={getRouteFor('historyMeetings')} onClick={this.reportNavigation}>
                    {formatMessage(messages.history)}
                  </Link>
                </Tab>
              </Nav>
              <MeetingDashboardRoutes />
            </Sidebar>
            <MeetingDetailsWrapper
              {...this.props}
              meeting={this.props.selectedItem}
              user={user}
              elasticSearchSession={this.props.elasticSearchSession}
              updateMeetingSessionDetails={this.props.updateMeetingSessionData}
            />
          </MainContainer>
        </MobileOnlyScrollbars>
        {schedulerModal}
        {schedulerInterstitial}
        {personalizeMeetingModal}
        {profilePreviewModal}
        {deleteModal}
        {inSessionModal}
        {errorWindow}
        <BuyNowBar
          show={showBuyNowBar}
          user={user}
          trialExpiresInDays={this.props.trialExpiresInDays}
          isFreeUser={this.props.isFreeUser}
        />
      </DashboardContainer>
    );
  }
}

export default injectIntl(MeetingsDashboard);
