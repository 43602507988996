export const encodeFormData = (body: Record<string, string>) =>
  Object.entries(body)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

export const getQueryParams = (search?: string) => {
  if (!search || search.length < 1) {
    return {};
  }
  return search
    .substr(1)
    .split('&')
    .reduce((acc, item) => {
      const [encodedKey, encodedValue] = item.split('=');
      if (encodedKey) {
        acc[decodeURIComponent(encodedKey)] = decodeURIComponent(encodedValue);
      }
      return acc;
    }, {} as Record<string, string>);
};
