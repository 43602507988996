import React, { Component } from 'react';
import findIndex from 'lodash/findIndex';
import throttle from 'lodash/throttle';
import { CategoryHeader } from './CategoryHeader';
import { EmojiRow } from './EmojiRow';
import { Modifiers } from './Modifiers';
import { AutoSizer, List } from 'react-virtualized';

interface Props {
  rows: any;
  modifier: string;
  onActiveCategoryChange: (category: any) => void;
  onChange: (emoji: string) => void;
  onModifierChange: (modifier: string) => void;
  buildEmojiUrl?: (hexCode: string, pngSize: string) => string;
  nonce?: any;
}

// These height values must be kept in sync with the heights
// (margin + padding + content height) defined in CSS.
const CATEGORY_HEADER_ROW_HEIGHT = 46;
const EMOJI_ROW_HEIGHT = 32;

export class Categories extends Component<Props> {
  private lastActiveCategory: any;
  private categories: any;
  private list: any = undefined;

  constructor(props: Props, context: any) {
    super(props, context);

    this.lastActiveCategory = null;
    this.categories = {};
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.rows !== prevProps.rows || this.props.modifier !== prevProps.modifier) {
      this.list.recomputeRowHeights();
    }
  }

  private setListRef = (list: any) => {
    this.list = list;
  };

  private onScroll = throttle(({ scrollTop }) => {
    const activeCategory = this.getActiveCategory(scrollTop);
    if (activeCategory !== this.lastActiveCategory) {
      this.lastActiveCategory = activeCategory;
      this.props.onActiveCategoryChange(activeCategory);
    }
  }, 100);

  getActiveCategory = (scrollTop = 0) => {
    const { rows } = this.props;

    if (scrollTop === 0) {
      if (rows.length === 0) {
        return undefined;
      }
      return rows[0].id;
    }

    let firstFullyVisibleRowIndex = 0;
    let accumulatedScrollTop = 0;

    while (accumulatedScrollTop < scrollTop) {
      accumulatedScrollTop += this.rowHeight({
        index: firstFullyVisibleRowIndex
      });

      if (accumulatedScrollTop <= scrollTop) {
        firstFullyVisibleRowIndex += 1;
      }
    }

    const currentRow = this.props.rows[firstFullyVisibleRowIndex];

    if (Array.isArray(currentRow)) {
      return currentRow[0].category;
    }

    return currentRow.id;
  };

  private rowHeight = ({ index }: { index: number }) => {
    const row = this.props.rows[index];
    return Array.isArray(row) ? EMOJI_ROW_HEIGHT : CATEGORY_HEADER_ROW_HEIGHT;
  };

  private rowRenderer = ({ key, index, style }: { key: any; index: number; style: any }) => {
    const row = this.props.rows[index];
    const { onChange } = this.props;

    if (Array.isArray(row)) {
      return (
        <EmojiRow key={key} onChange={onChange} style={style} emojis={row} buildEmojiUrl={this.props.buildEmojiUrl} />
      );
    }

    const { category, id } = row;
    const headingDecoration: any = undefined;
    const attributes = {
      key,
      category,
      onChange,
      ref: this.setCategoryRef(id),
      style,
      headingDecoration
    };

    if (index === 0) {
      const { modifier, onModifierChange } = this.props;

      attributes.headingDecoration = <Modifiers active={modifier} onChange={onModifierChange} />;
    }

    return <CategoryHeader {...attributes} />;
  };

  private setCategoryRef = (id: any) => {
    return (category: any) => {
      this.categories[id] = category;
    };
  };

  jumpToCategory = (id: any) => {
    const index = findIndex(this.props.rows, (category: any) => category.id === id);
    this.list.scrollToRow(index);
  };

  render() {
    const rowCount = this.props.rows.length;

    return (
      <AutoSizer nonce={this.props.nonce}>
        {({ height, width }: { height: number; width: number }) => (
          <List
            height={height}
            onScroll={this.onScroll}
            ref={this.setListRef}
            rowCount={rowCount}
            rowHeight={this.rowHeight}
            rowRenderer={this.rowRenderer}
            scrollToAlignment="start"
            tabIndex={null}
            width={width}
          />
        )}
      </AutoSizer>
    );
  }
}
