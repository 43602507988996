import { MessagesEntity } from '@getgo/caps-redux';
import { Reducer } from 'redux';
import { ActionTypes as ImagePreviewActionTypes, Actions as imagePreviewActions } from './imagePreviewActions';
import { getCurrentIndex, getNextIndex } from '../../../../services/ImagePreviewService';

type State =
  | Readonly<{
      isOpen: true;
      index: number;
      messages: MessagesEntity[];
      currentMessage: MessagesEntity;
    }>
  | Readonly<{
      isOpen: false;
    }>;

const defaultState = {
  isOpen: false as false
};

const reducer: Reducer<State, imagePreviewActions> = (state: State = defaultState, action) => {
  switch (action.type) {
    case ImagePreviewActionTypes.NAVIGATE_IMAGE_PREVIEW:
      if (state.isOpen) {
        const nextIndex = getNextIndex(state.messages, state.index, action.payload);
        if (nextIndex !== -1) {
          return {
            ...state,
            index: nextIndex
          };
        }
      }
      return state;
    case ImagePreviewActionTypes.OPEN_IMAGE_PREVIEW:
      const currentIndex = getCurrentIndex(action.payload.messages, action.payload.message);
      return {
        isOpen: true,
        currentMessage: action.payload.message,
        messages: action.payload.messages,
        index: currentIndex
      };
    case ImagePreviewActionTypes.CLOSE_IMAGE_PREVIEW:
      return { isOpen: false };
  }

  return state;
};

export default reducer;
