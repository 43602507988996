import React from 'react';
import GoToMeetingContainer from '../../containers/GoToMeetingContainer';
import { ButtonTypes } from './GoToMeeting/GoToMeeting';
import { State } from '../../view-model/types';
import { Contact } from '@getgo/caps-redux';

interface Props {
  app?: State['core']['app'];
  contact: Contact;
  isOnline?: boolean;
}

export const ContactActions: React.FC<Props> = (props) => {
  const { isOnline = true } = props;

  const features = props.app?.features ? props.app.features : [];

  return (
    (features.includes('gotomeeting') && (
      <GoToMeetingContainer buttonType={ButtonTypes.MEET_NOW_BUTTON} contact={props.contact} isOnline={isOnline} />
    )) ||
    null
  );
};
