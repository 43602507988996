import * as React from 'react';
import styled from '../../../../styled-components';
import { RotatingIcon } from '../Icon/RotatingIcon';
import { Icons } from '../ICONS';
import { themeGet } from '@styled-system/theme-get';

/* eslint-disable @typescript-eslint/no-unused-vars */
export interface Props {
  background?: string;
  border?: string;
  borderRadius?: number;
  className?: string;
  color?: string;
  disabled?: boolean;
  disabledBackground?: string;
  disabledColor?: string;
  fontSize?: string;
  iconSize?: number;
  height?: number;
  hoverBackground?: string;
  id?: string;
  loading?: boolean;
  padding?: number;
  width?: number;
  alignItems?: string;
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  textTransform?: string;

  onClick?(event: any): void;
}

const ButtonLayout: React.FC<Props> = (props) => {
  const {
    children,
    loading,
    color,
    background,
    border,
    borderRadius,
    disabledBackground,
    disabledColor,
    fontSize,
    height,
    hoverBackground,
    padding,
    width,
    alignItems,
    iconSize = 14,
    ...rest
  } = props;

  return (
    <button {...rest} type={props.type || 'button'}>
      {loading && <RotatingIcon color={color} className={'loading-icon'} icon={Icons.spinnerSegment} size={iconSize} />}
      {children}
    </button>
  );
};

export const Button = styled(ButtonLayout)<Props>`
  background: ${(props) => props.background || 'none'};
  border-radius: ${(props) => props.borderRadius || 4}px;
  border: ${(props) => props.border || 'none'};
  box-sizing: border-box;
  color: ${(props) => props.color || themeGet('colors.primary')(props)};
  cursor: ${(props) => (props.loading ? 'progress' : 'pointer')};
  display: inline-flex;
  font-family: lato-regular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: ${(props) => props.fontSize || '14px'};
  height: ${(props) => (props.height ? props.height + 'px' : '36px')};
  letter-spacing: 0.5px;
  line-height: 1;
  padding: ${(props) => props.padding || '8px 16px'};
  width: ${(props) => (props.width ? props.width + 'px' : '')};
  text-transform: ${(props) => props.textTransform || 'capitalize'};
  align-items: ${(props) => props.alignItems || ''};

  &:disabled {
    cursor: not-allowed;
    color: ${themeGet('colors.primaryOn')};
  }

  &:hover:enabled {
    background: ${(props) => props.hoverBackground};
  }

  .loading-icon {
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    height: ${(props) => (props.height ? props.height + 'px' : '32px')};

    .loading-icon {
      top: 8px;
    }
  }
`;

export const UnstyledButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize \`line-height\`. Cannot be changed from \`normal\` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable \`input\` types in iOS */
  -webkit-appearance: none;
`;
