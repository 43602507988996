import chunk from 'lodash/chunk';
import values from 'lodash/values';
import map from 'lodash/map';
import { emojioneList } from 'emojione';
import { escapeRegex } from '../../../../../lib/escapeRegex';

function rowsSelector(categories: any, emojisByCategory: any, modifier: any, search: any, term: any) {
  const findEmojiVariant = (emojis: any) => (modifier && emojis[modifier] ? emojis[modifier] : emojis[0]);
  const searchTermRegExp = new RegExp(`^(?:.* +)*${escapeRegex(term)}`, 'i');
  const keywordMatchesSearchTerm = (keyword: string) => searchTermRegExp.test(keyword);
  const emojiMatchesSearchTerm = (emoji: any) => emoji.keywords.concat(emoji.name).some(keywordMatchesSearchTerm);

  return map(categories, (category, id) => {
    const list = emojisByCategory[id] || {};
    let emojis = values(list).map(findEmojiVariant);

    if (emojis.length > 0) {
      emojis = emojis.sort((a, b) => {
        return a.order - b.order;
      });

      emojis = emojis.filter((emoji) => {
        return emoji.shortname in emojioneList;
      });
    }

    if (search && term) {
      emojis = emojis.filter(emojiMatchesSearchTerm);
    }

    return {
      category,
      emojis,
      id
    };
  })
    .filter(({ emojis }) => emojis.length > 0)
    .map(({ category, emojis, id }) => [
      {
        category,
        id
      },
      ...chunk(emojis, 8)
    ])
    .reduce((rows, categoryAndEmojiRows) => [...rows, ...categoryAndEmojiRows], []);
}

export default function createRowsSelector() {
  let lastCategories: any;
  let lastEmojisByCategory: any;
  let lastModifier: any;
  let lastSearch: any;
  let lastTerm: any;
  let lastResult: any;

  return function memoizedRowsSelector(categories: any, emojisByCategory: any, modifier: any, search: any, term: any) {
    if (
      categories !== lastCategories ||
      emojisByCategory !== lastEmojisByCategory ||
      modifier !== lastModifier ||
      search !== lastSearch ||
      term !== lastTerm
    ) {
      lastResult = rowsSelector(categories, emojisByCategory, modifier, search, term);
      lastCategories = categories;
      lastEmojisByCategory = emojisByCategory;
      lastModifier = modifier;
      lastSearch = search;
      lastTerm = term;
    }

    return lastResult;
  };
}
