import { Feature } from '@getgo/caps-redux';
import caps from './capabilities';

export function getFeatureByNS(xmlNS: string): Feature[] {
  // @ts-ignore
  return caps.filter((el: { xmlNS: string }) => {
    return el.xmlNS && el.xmlNS === xmlNS;
  });
}

export function getFeatureByName(featureName: string): Feature[] {
  // @ts-ignore
  return caps.filter((el: any) => {
    return el.name === featureName;
  });
}
