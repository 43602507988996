import ExtendableError from 'es6-error';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import sw from 'offline-plugin/runtime';
import Modal from 'react-modal';
import { FeatureFlipProvider } from '@getgo/featureflip-react';
import { Store } from 'redux';
import Token from '../../node_modules/@getgo/auth-client/lib/token';
import { AnyTODO, User } from '../types/pulse-web';
import featureService from './services/FeatureService';
import { redirectTo } from './services/NavService';
import { AuthService } from './services/auth';
import ErrorComponent from './components/Error';
import EntitlementContainer from './containers/EntitlementContainer';
import { loadMe, setNewExperience } from '../models/User';
import config from 'config';
import { EntitlementError, UnauthorizedError } from '../models/Errors';
import logger from './services/LoggerService';
import rest from '../lib/rest';
import { getTranslationsForLocale, getUserLocale } from './services/TranslationService';
import { UserWithPreferences } from './view-model/sharedActions';
import { createAppStoreAsync } from './view-model/store';
import notificationsMiddleware from './view-model/middleware/notifications';
import appActions, { unload } from './view-model/modules/core/app/appActions';
import { fetchInitialSettings } from './view-model/modules/core/preferences/preferencesActions';
import { AppService } from './services/AppService';
import { ChatService } from './services/ChatService';
import { WindowEventsService } from './services/WindowEventsService';
import { RootRouter } from './router';
import { PushNotificationService } from './services/PushNotificationService';
import errorActions from './view-model/modules/core/errors/errorActions';
import { LaunchDarklyFlags, initLaunchDarklyService } from './services/LaunchDarklyService';
import { ThemeProvider } from './styled-components';
import { oldHubTheme } from './defaultTheme';
import './styles/base.css';
import { Dispatch, State } from './view-model/types';
import { initializeGoogleTagManager } from './services/GoogleTagManagerService';
import { initializeDatePickerLocales } from '../lib/date';
import { isElectron, isV11 } from './services/ClientService';
import { ElectronDecomErrorPage } from './components/Common/ErrorPages/ErrorPages';
import { buildFeatureUseData, buildOsUserData } from '../lib/user-data-utility';
import { fetchDiscover } from './view-model/modules/discover/discoverActions';
import { purgeStoredState } from 'redux-persist';
import localForage from 'localforage';
import { setCorrectFavicon } from '../lib/favicons';

setCorrectFavicon();

const intlFormats = {
  date: {
    customShort: {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    },
    customLong: {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    }
  }
};

const dependenciesLoaded = Promise.all([
  // Polyfill Intl for unsupported browsers (IE10, Safari 9)
  global.Intl.PluralRules || import(/* webpackChunkName: "intl-polyfill-pluralrules" */ './intl-polyfill-pluralrules'),
  // @ts-ignore
  global.Intl.RelativeTimeFormat ||
    import(/* webpackChunkName: "intl-polyfill-relativetimeformat" */ './intl-polyfill-relativetimeformat')
]);

let userLocale: string;
let translationsForUserLocale: any;
let store: Store<State>;
const roomId = '';
let appIsUp = false;
let chatServiceStarted = false;
let shouldRenderPrimaryApp = true;

const render = (component: React.ReactElement<any>) => {
  dependenciesLoaded.then(() => {
    Modal.setAppElement('#app');
    ReactDOM.render(
      <AppContainer>
        <IntlProvider formats={intlFormats} locale={userLocale} messages={translationsForUserLocale}>
          <Provider store={store}>
            <FeatureFlipProvider context={featureService}>
              <ThemeProvider theme={oldHubTheme}>{component}</ThemeProvider>
            </FeatureFlipProvider>
          </Provider>
        </IntlProvider>
      </AppContainer>,
      document.getElementById('app')
    );
  });
};

const renderApp = () => {
  if (shouldRenderPrimaryApp) {
    render(<RootRouter />);
  }
};

const renderError = (error?: ExtendableError, action?: () => void) => {
  shouldRenderPrimaryApp = false;
  render(<ErrorComponent action={action} error={error} />);
};

const renderElectronDecomApp = () => {
  shouldRenderPrimaryApp = false;
  render(<ElectronDecomErrorPage />);
  return new Promise(() => {}) as Promise<never>;
};

const renderNoLicenseApp = () => {
  shouldRenderPrimaryApp = false;
  return render(<EntitlementContainer />);
};

const auth = new AuthService(config.oauth);
// @ts-ignore
const appService = new AppService(store);
// @ts-ignore
const chatService = new ChatService(store);
const windowService = new WindowEventsService({
  onOnlineChange: (online) => {
    appService.changeAppOnlineState(online);
    if (chatServiceStarted) {
      chatService.changeAppOnlineState(online);
    }
  },
  onVisibilityChange: (visible) => {
    appService.changeAppVisibility(visible);
  },
  onBeforeUnload: () => {
    if (chatServiceStarted) {
      chatService.stop();
    }
  },
  onFocus: () => {
    appService.changeAppFocus();
  }
});

const onChatReconnectionFailed = (dispatch: Dispatch, errorCode: Error): void => {
  const reload = () => window.location.reload();

  const isOnline = () => {
    const { core, messaging } = store.getState();
    const { chat } = messaging;
    const { app } = core;
    return app.connectionStatus.networkOnline && chat.connectionStatus.xmppConnected;
  };

  // try to check if the auth fail is due to an entitlement
  // or some other reason.
  loadMe()
    .then(() => {
      if (isOnline()) {
        // in case of race condition, check for online state
        logger.error(
          'initApp.onChatReconnectionFailed - Clearing chat error instead throwing xmppAuthError (race condition).'
        );
        dispatch(errorActions.clearChatError());
      } else {
        logger.error('initApp.onChatReconnectionFailed', 'errorCode=', errorCode);
        dispatch(errorActions.xmppAuthError(errorCode, reload));
      }
    })
    .catch((err) => {
      if (isOnline()) {
        // in case of race condition, check for online state
        logger.error(
          'initApp.onChatReconnectionFailed - Clearing chat error instead throwing xmppAuthError in catch block (race condition).'
        );
        return dispatch(errorActions.clearChatError());
      }
      logger.error('initApp.onChatReconnectionFailed - Throwing xmppAuthError in catch block', 'error=', err);
      return dispatch(errorActions.xmppAuthError(errorCode, reload));
    });
};

function initTranslations(user?: User['identity']) {
  userLocale = getUserLocale(user);
  translationsForUserLocale = getTranslationsForLocale(userLocale);
  initializeDatePickerLocales();

  // update service worker locale
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((reg) => {
      if (reg.active) {
        reg.active.postMessage({
          type: 'locale',
          payload: userLocale.toLowerCase()
        });
      }
    });
  }
}
const preEntryCheck = (me: UserWithPreferences) => {
  if (!me.tcAgreement) {
    // Let user accept the Terms of Service
    return redirectTo('termsOfService');
  } else if (me.chat && !me.emailVerified) {
    // Only validate email if chat is enabled
    return redirectTo('emailVerification');
  } else if (!me.licenseType) {
    // If user has no G2M license
    return renderNoLicenseApp();
  }
};

function connect(token: Token) {
  return loadMe(token).then((me) => {
    const storedUser = store.getState().core.user;
    if (storedUser.hasLoaded && storedUser.locale != me.locale) {
      purgeStoredState({
        storage: localForage as any
      }).then(() => window.location.reload());
    }
    appService.start(store, me);
    const { core } = store.getState();
    const { app } = core;
    preEntryCheck(me);

    if (app.features.includes('chat')) {
      chatService.start(store, me, roomId, onChatReconnectionFailed);
      chatServiceStarted = true;
    }

    windowService.bindWindowHandlers();
    appIsUp = true;
  });
}

async function handleInitErrors(err: any) {
  logger.error('initApp', 'error=', err);

  if (!store) {
    // eslint-disable-next-line require-atomic-updates
    store = await createAppStoreAsync({});
  }

  if (PushNotificationService.isSupported()) {
    try {
      await PushNotificationService.unsubscribe(null);
    } catch (e) {
      logger.error('PushNotificationService.unsubscribe', 'err=', e);
    }
  }

  if (err instanceof UnauthorizedError) {
    // Token has expired/is no longer valid so clear cache and relaunch login flow
    auth.retrySignIn();
  } else if (err instanceof EntitlementError) {
    // User is not entitled, so sign out completely
    renderError(err, () => auth.logout());
  } else {
    // Render global error if no valid user, otherwise let the app handle messaging
    if (!store) {
      renderError(err, () => auth.retrySignIn());
      return;
    }

    const { core } = store.getState();
    const { user } = core;
    if (!user.hasLoaded) {
      renderError(err, () => auth.retrySignIn());
    }
  }
}

const initFeatureService = async (user: UserWithPreferences, featureStore: Store) => {
  const launchDarklyFlags = await initLaunchDarklyService({
    userKey: user.key,
    userEmail: user.email,
    userData: {
      accountKey: user.accountKey,
      g2m_locale: user.locale || 'en_US',
      ...buildFeatureUseData(user),
      ...buildOsUserData()
    },
    onChange: (updatedFlags) => {
      featureService.setData({ launchDarklyFlags: updatedFlags });
      const feat = featureService.enabled;
      featureStore.dispatch(appActions.updateFeatures(feat));
    }
  });

  featureService.setData({ user, launchDarklyFlags: launchDarklyFlags as LaunchDarklyFlags });
  const features = featureService.enabled;
  featureStore.dispatch(appActions.updateFeatures(features));
};

async function checkElectronDecom(token: Token) {
  if (!isElectron || isV11) {
    /* skip for web users */
    return token;
  }
  /* create store without notification middleware */
  const storeWithoutNotifications = await createAppStoreAsync({});

  store = storeWithoutNotifications;
  const { user } = store.getState().core;

  if (user.hasLoaded) {
    await initFeatureService(user as any, storeWithoutNotifications);
    if (!featureService.isEnabled('meeting-hub-enabled')) {
      setNewExperience(true);
    }
    if (PushNotificationService.isSupported()) {
      try {
        const registration = store.getState().core.notifications.pushRegistration || null;
        await PushNotificationService.unsubscribe(registration);
      } catch (e) {
        logger.error('PushNotificationService.unsubscribe', 'err=', e);
      }
    }
    return renderElectronDecomApp();
  }
  /* should only get here if isElectron and user could not be loaded */
  return token;
}

function initApp() {
  const initStore = async (token: Token) => {
    const newStore: Store<State> = await createAppStoreAsync({}, [notificationsMiddleware]);

    store = newStore;
    const state = newStore.getState();
    const dispatch: Dispatch = newStore.dispatch;

    // check we have at least a saved user
    if (state.core.user.hasLoaded) {
      await initFeatureService(state.core.user as AnyTODO, store);
      initTranslations(state.core.user);
      initializeGoogleTagManager(state.core.user);

      dispatch(fetchInitialSettings());
      dispatch(fetchDiscover());
      renderApp();
    } else {
      // if not, wait until the store has the user from the /me call
      const unsubscribe = newStore.subscribe(async () => {
        const newState = newStore.getState();
        const { user: newUser } = newState.core;

        if (newUser.hasLoaded) {
          unsubscribe();
          await initFeatureService(newUser as AnyTODO, store);
          initTranslations(newUser);
          initializeGoogleTagManager(newUser);

          dispatch(fetchInitialSettings());
          dispatch(fetchDiscover());
          renderApp();
        }
      });
    }

    return token;
  };

  rest.setAuth(auth);
  auth
    .init()
    .then(checkElectronDecom)
    .then(initStore)
    .then((token: Token) => {
      store.dispatch(appActions.appLoad());
      return token;
    })
    .then(connect)
    .then(() => {
      store.dispatch(appActions.appLoaded());
    })
    .catch(handleInitErrors);
}

initApp();

window.addEventListener('beforeunload', () => {
  if (!store) {
    return;
  }
  // @ts-ignore
  if (store.persistor) {
    // @ts-ignore
    store.persistor.pause();
  }
  (store.dispatch as Dispatch)(unload());
});

const onlineHandler = () => {
  if (!appIsUp) {
    logger.info('initApp.retry', 'errorDescription=', 'Network is available again, will retry to initialize app');

    auth
      .init()
      .then(connect)
      .then(() => window.removeEventListener('online', onlineHandler))
      .catch(handleInitErrors);
  }
};

// if we are offline when we start the app, the app will not complete
// initialization, so we need to try to re-initialize whenever we come
// back online... after we are online, the regular WindowEventsService
// handlers will take over and handle the reconnection
if (!window.navigator.onLine) {
  window.addEventListener('online', onlineHandler);
}

sw.install({
  onUpdateReady: () => {
    sw.applyUpdate();
    unregisterServiceWorkers();
  },
  onUpdated: () => {
    if (store && 'dispatch' in store) {
      store.dispatch(appActions.updateReady());
      unregisterServiceWorkers();
    }
  }
});

const unregisterServiceWorkers = () => {
  navigator.serviceWorker.getRegistrations().then((registrations: readonly ServiceWorkerRegistration[]) => {
    for (const registration of registrations) {
      registration.unregister().then((success: boolean) => console.log(`${registration} unregistered: ${success}`));
    }
  });
};

unregisterServiceWorkers();
// HMR
if (module.hot) {
  module.hot.accept('./router', () => {
    renderApp();
  });
}
