import * as React from 'react';
import styled from '../../styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';

const Title = styled.h4`
  font-size: 16px;
  font-family: 'lato-bold', sans-serif;
  color: #25282d;
  margin: 0 0 8px 0;
  padding: 0;
`;

const Content = styled.div`
  font-size: 14px;
  font-family: 'lato-regular', sans-serif;
  color: #25282d;
`;

const messages = defineMessages({
  meetingRoomReady: {
    id: 'notifications.messages.meetingRoomReady',
    defaultMessage: 'Meeting Room Ready'
  },
  meetingRoomReadyText: {
    id: 'notifications.messages.meetingRoomReadyText',
    defaultMessage: 'Your personal room is set up and ready to use anytime.'
  },
  meetingCreated: {
    id: 'notifications.messages.meetingCreated',
    defaultMessage: 'Meeting Created'
  },
  meetingCreatedText: {
    id: 'notifications.messages.meetingCreatedText',
    defaultMessage: 'To share your meeting, copy the info below and paste it into an email or message.'
  },
  copyInvitation: {
    id: 'notifications.messages.copyInvitation',
    defaultMessage: 'Invite Copied to Clipboard'
  },
  copyInvitationText: {
    id: 'notifications.messages.copyInvitationText',
    defaultMessage: 'Send attendees the details for your personal meeting room.'
  },
  shareRecording: {
    id: 'notifications.messages.shareRecording',
    defaultMessage: 'Recording link copied'
  },
  shareRecordingText: {
    id: 'notifications.messages.shareRecordingText',
    defaultMessage:
      'Your recording link has been copied to the clipboard. You can share your recording by sending the link.'
  },
  shareRecordingError: {
    id: 'notifications.messages.shareRecordingError',
    defaultMessage: 'Oops, there was a problem copying your meeting recording info.'
  },
  invitationTypeInvalid: {
    id: 'notifications.messages.invitationTypeInvalid',
    defaultMessage: 'Oops, phone numbers are missing'
  },
  invitationTypeInvalidText: {
    id: 'notifications.messages.invitationTypeInvalidText',
    defaultMessage: 'Add long-distance or toll-free numbers in audio settings and then try again.'
  },
  audioSettingsText: {
    id: 'notifications.messages.audioSettingsText',
    defaultMessage: 'Audio settings'
  }
});

export const SimpleNotification = ({ title, content }: { title?: React.ReactNode; content: React.ReactNode }) => (
  <div>
    {title && <Title>{title}</Title>}
    <Content>{content}</Content>
  </div>
);

export const MeetingRoomReady = () =>
  SimpleNotification({
    title: <FormattedMessage {...messages.meetingRoomReady} />,
    content: <FormattedMessage {...messages.meetingRoomReadyText} />
  });

export const InvitationTypeInvalid = () =>
  SimpleNotification({
    title: <FormattedMessage {...messages.invitationTypeInvalid} />,
    content: <FormattedMessage {...messages.invitationTypeInvalidText} />
  });

export const EditButtonText = () => <FormattedMessage {...messages.audioSettingsText} />;

export const MeetingCreated = () =>
  SimpleNotification({
    title: <FormattedMessage {...messages.meetingCreated} />,
    content: <FormattedMessage {...messages.meetingCreatedText} />
  });

export const CopyInvitation = () =>
  SimpleNotification({
    title: <FormattedMessage {...messages.copyInvitation} />,
    content: <FormattedMessage {...messages.copyInvitationText} />
  });

export const ShareRecordingLink = () =>
  SimpleNotification({
    title: '',
    content: <FormattedMessage {...messages.shareRecording} />
  });

export const ShareRecordingLinkError = () =>
  SimpleNotification({
    title: '',
    content: <FormattedMessage {...messages.shareRecordingError} />
  });
