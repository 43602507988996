import { Document, Packer, Paragraph, TextRun } from 'docx';
import FileSaver from 'file-saver';
import { formatToHoursMinutesSeconds } from '../../../lib/date';

export const transcriptsDownload = (
  shareTranscript: any,
  session: any,
  dateTimeFormat: {
    startDate: string;
    endDate: string;
    startTime: string;
    expiry: string;
  }
) => {
  const doc = new Document();
  const { subject, meetingId } = session;
  const { startDate, startTime } = dateTimeFormat;
  const fontTextRun = (input: string, properties = {}) => {
    return new TextRun({
      size: 26,
      text: input,
      ...properties,
      font: {
        name: 'Calibri'
      }
    });
  };

  const objTest: { [key: string]: any } = {};
  session?.attendees.forEach((item: any) => {
    if (!objTest[item.participantId]) {
      objTest[item.participantId] = item.name;
    }
  });

  const arr = [];
  if (shareTranscript && shareTranscript.utterances) {
    for (const key of Object.keys(shareTranscript.utterances)) {
      const temp = shareTranscript.utterances[key];

      const obj = {
        participant: objTest[temp.speakers[0]],
        transcript: temp?.text,
        startTime: formatToHoursMinutesSeconds(Number(temp?.startTime) / 1000, true),
        endTime: formatToHoursMinutesSeconds(Number(temp?.stopTime) / 1000, true)
      };
      arr.push(obj);
    }
    downloadTranscripts(arr);
  }

  function downloadTranscripts(arr: any) {
    const transcripts = () => {
      if (arr && arr.length) {
        return arr.map((item: any) => {
          const participant = item.participant ? item.participant : '[speaker unknown]';
          return new Paragraph({
            children: [
              fontTextRun(`${item.startTime} - ${item.endTime}`, { color: '#8B939D', break: 1 }),
              fontTextRun(participant, { bold: true, break: 1 }),
              fontTextRun(item.transcript, { break: 1 }),
              fontTextRun('', { break: 1 })
            ]
          });
        });
      }
      return [];
    };

    doc.addSection({
      children: [
        new Paragraph({
          children: [
            fontTextRun(subject, { bold: true, size: 52 }),
            fontTextRun(startDate + ' . ', { break: 2 }),
            fontTextRun(startTime + ' . '),
            fontTextRun(`ID: ${meetingId}`),
            fontTextRun('Transcript', { bold: true, size: 40, break: 2 })
          ]
        }),
        ...transcripts()
      ]
    });

    try {
      Packer.toBlob(doc)
        .then((blob) => {
          FileSaver.saveAs(blob, `${subject} - Meeting Transcripts.docx`, { autoBom: true });
          // props.closeDownloadModal();
        })
        .catch(() => {
          // fail silently
        });
    } catch (e) {
      // fail silently
    }
  }
};
