import Auth, { Token } from '@getgo/auth-client';
import localForage from 'localforage';
import { join } from '../../../lib/url';
import { IAuthOptions } from '@getgo/auth-client/lib/auth';
import CookieStore from '../../../lib/cookie-store';
import LoggerService from '../LoggerService';
import { encodeFormData, getQueryParams } from './util';

import 'fast-text-encoding';
import 'webcrypto-shim';

const PKCE_VERIFIER_KEY = 'auth-pkce-verifier';
const PKCE_NONCE_KEY = 'auth-pkce-nonce';
// Because in testcafe we have a 2019 fixed date and the cookie would immediately be deleted
const TESTCAFE = !!(window as any)['%testCafeCore%'];
const COOKIE_EXPIRES = TESTCAFE ? 9999 : 30;

// Testcafe don't like the __ in the cookie name
export const accessTokenCookieKey = TESTCAFE ? 'webAppAccessToken' : '__Host-webAppAccessToken';
export const refreshTokenCookieKey = TESTCAFE ? 'webAppRefreshToken' : '__Host-webAppRefreshToken';
export const accessTokenCookieOptions = { expires: COOKIE_EXPIRES, secure: true };

type AuthServiceOptions = IAuthOptions;

interface InitialAuthOptions {
  client_id: string;
  url: string;
  enableTokenRefresh?: boolean;
}

const logger = LoggerService.create('AuthService');

export class AuthService {
  private browserLocation: Location;
  private readonly config: AuthServiceOptions;
  private auth: Auth;
  private tokenRefreshOptions: Partial<AuthServiceOptions> = {
    enableTokenRefresh: true,
    onTokenRefresh: () => this.refresh(),
    tokenRefreshTimestamp: this.getRefreshTimestamp
  };

  constructor(config: InitialAuthOptions, browserLocation: Location = window.location) {
    this.browserLocation = browserLocation;
    if (typeof config.enableTokenRefresh === 'boolean') {
      this.tokenRefreshOptions.enableTokenRefresh = config.enableTokenRefresh;
    }

    this.config = {
      ...config,
      redirect_url: this.getRedirectUrl()
    };

    this.auth = new Auth({
      ...this.config,
      ...this.tokenRefreshOptions
    });
  }

  private initNonce() {
    const nonce = this.auth.randomString(32);
    localForage.setItem(PKCE_NONCE_KEY, nonce);
    return nonce;
  }

  private async verifyNonce(responseNonce?: unknown) {
    const storedNonce = await localForage.getItem(PKCE_NONCE_KEY);
    await localForage.removeItem(PKCE_NONCE_KEY);
    if (!storedNonce || !responseNonce || storedNonce !== responseNonce) {
      return false;
    }
    return true;
  }

  private initVerifier() {
    const verifier = this.auth.randomString(64);
    localForage.setItem(PKCE_VERIFIER_KEY, verifier);
    return verifier;
  }

  private async popInitialVerifier() {
    const value = await localForage.getItem(PKCE_VERIFIER_KEY);
    await localForage.removeItem(PKCE_VERIFIER_KEY);
    return value as string;
  }

  private async login() {
    const queryParams = getQueryParams(this.browserLocation.search);

    if (queryParams.state && queryParams.code) {
      const state = JSON.parse(decodeURIComponent(queryParams.state));
      const code = queryParams.code;
      const initialVerifier = await this.popInitialVerifier();
      const isNonceValid = await this.verifyNonce(state.nonce);
      if (!initialVerifier || !isNonceValid) {
        logger.error('auth.login', 'error=', new Error('Invalid nonce or verifier'));
        return this.retrySignIn('/');
      }

      try {
        const token = await this.auth.requestPKCEToken({
          code,
          code_verifier: initialVerifier
        });

        if (token.refresh_token) {
          this.setRefreshToken(token.refresh_token);
          delete token.refresh_token;
        }
        this.setToken(token);
        token.state = state;
        return token;
      } catch {
        logger.error('auth.login', 'error=', new Error('Failed to request PKCE token'));
        return this.retrySignIn('/');
      }
    } else {
      const options = this.getOptions();

      await this.auth.loginWithPKCE({
        ...options
      });
    }
  }

  private initToken(token: Token) {
    if (this.tokenRefreshOptions.enableTokenRefresh && this.getRefreshToken()) {
      token
        .startRefreshTimer(this.tokenRefreshOptions.tokenRefreshTimestamp)
        .then(this.tokenRefreshOptions.onTokenRefresh);
    }
  }

  async init() {
    let token = this.getToken();

    if (!token) {
      const result = await this.login();
      if (result) {
        token = result;
        await this.redirectAfterLogin(token);
      } else {
        return new Promise(() => {}) as Promise<never>;
      }
    }

    this.initToken(token);
    return token;
  }

  getToken() {
    try {
      const cookie = CookieStore.get(accessTokenCookieKey);
      if (!cookie) {
        return undefined;
      }
      const tokenish = JSON.parse(cookie);
      const token = new Token(tokenish);

      if (!token.isValid()) {
        return undefined;
      }
      return token;
    } catch (e) {
      console.error(`Token could not be retrieved: ${e.message}`);
      return undefined;
    }
  }

  private setToken(token: Token) {
    const tokenWithoutState = { ...token, state: undefined };
    CookieStore.set(accessTokenCookieKey, JSON.stringify(tokenWithoutState), accessTokenCookieOptions);
    this.initToken(token);
    return tokenWithoutState;
  }

  private setRefreshToken(refreshToken: string) {
    CookieStore.set(refreshTokenCookieKey, refreshToken, accessTokenCookieOptions);
  }

  private getRefreshToken() {
    return CookieStore.get(refreshTokenCookieKey);
  }

  private getPath() {
    let path = this.browserLocation.pathname;
    if (this.browserLocation.hash) {
      path += this.browserLocation.hash;
    }
    return path !== '/' ? path : undefined;
  }

  private getRoomId() {
    const matches = /roomId=([^&]+)/.exec(this.browserLocation.search);
    if (matches) {
      return decodeURIComponent(matches[1]);
    }
    return undefined;
  }

  private getRedirectUrl() {
    return `${this.browserLocation.protocol}//${this.browserLocation.host}/`;
  }

  private getOptionsState(nonce: string) {
    const path = this.getPath();
    const roomId = this.getRoomId();

    return {
      ...(path ? { path } : {}),
      ...(roomId ? { roomId } : {}),
      nonce
    };
  }

  private getOptions() {
    const nonce = this.initNonce();
    const verifier = this.initVerifier();

    const state = this.getOptionsState(nonce);
    const options: Partial<IAuthOptions> = {
      theme: 'g2m',
      state: encodeURIComponent(JSON.stringify(state)) as any,
      code_verifier: verifier
    };

    return options;
  }

  getRefreshTimestamp(token: Token) {
    const issued = Number(token.issued) || Date.now();
    const expires = Number(token.expires);

    return Math.floor((issued + expires) / 2);
  }

  clearCache() {
    this.clearTokenCache();
    // clear IndexedDB
    return localForage.clear();
  }

  async refresh() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      logger.error('auth.refresh', 'error=', new Error('Invalid refresh token'));
      return this.retrySignIn('/');
    }

    const body = {
      grant_type: 'refresh_token',
      client_id: this.config.client_id,
      refresh_token: refreshToken
    };
    let response: Response | undefined = undefined;
    try {
      response = await fetch(`${this.config.url}/oauth/token`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json'
        },
        method: 'POST',
        body: encodeFormData(body)
      });
    } catch (e) {
      // fallthrough
    }

    if (response && response.ok) {
      const token = new Token(await response.json());
      if (token.refresh_token) {
        this.setRefreshToken(token.refresh_token);
        delete token.refresh_token;
      }
      this.setToken(token);
      return;
    }

    logger.error('auth.refresh', 'error=', new Error('Failed to get refresh token'));
    return this.retrySignIn('/');
  }

  clearTokenCache() {
    CookieStore.remove(accessTokenCookieKey, accessTokenCookieOptions);
    CookieStore.remove(refreshTokenCookieKey, accessTokenCookieOptions);
  }

  logout() {
    return this.clearCache().then(() => this.auth.logout());
  }

  retrySignIn(redirectUri?: string) {
    return this.clearCache().then(() => {
      if (redirectUri) {
        this.browserLocation.assign(redirectUri);
      } else {
        this.browserLocation.reload();
      }
    });
  }

  private async redirectAfterLogin(token: Token): Promise<Token> {
    const path = token.state?.path || '/';

    let pathname = '';
    if (token.state.roomId) {
      const search = `?roomId=${encodeURIComponent(token.state.roomId)}`;
      pathname = join(path, search);
    } else {
      pathname = path;
    }

    if (pathname.replace(/#[^#]+$/, '') === this.browserLocation.pathname) {
      this.browserLocation.replace(pathname);
    } else {
      this.browserLocation.assign(join(this.config.redirect_url, pathname));
    }

    // return promise that never resolves to prevent anything else from happening before navigation is done
    return new Promise(() => {});
  }
}
