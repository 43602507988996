import React, { useRef } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { State } from '../../../view-model/types';
import { AvatarOnlineStatus } from '../../Common/AvatarOnlineStatus';
import status from '../../../../lib/availabilityStatuses';
import { getFullName } from '../../../../lib/person';
import { User } from '../../../../types/pulse-web';
import { Popover, PopoverMethods } from '../../Common/Popover/Popover';
import styled, { css } from '../../../styled-components';
import { statusColorMapping } from '../../Common/OnlineStatus/OnlineStatus';
import { DownArrow } from '../../Common/Toolkit/Icon/ReactIcons';
import device from '../../Common/Toolkit/devices';
import config from 'config';

const messages = defineMessages({
  statusHeadline: {
    id: 'userMenu.statusHeader',
    defaultMessage: 'Status'
  },
  statusAvailable: {
    id: 'userMenu.available',
    defaultMessage: 'Available'
  },
  statusDnd: {
    id: 'userMenu.dnd',
    defaultMessage: 'Hard at work'
  },
  statusAway: {
    id: 'userMenu.away',
    defaultMessage: 'Away'
  },
  signOut: {
    id: 'userMenu.signOut',
    defaultMessage: 'Sign out'
  },
  myProfile: {
    id: 'userMenu.myProfile',
    defaultMessage: 'My profile'
  },
  details: {
    id: 'userMenu.userDetails',
    defaultMessage: 'User details'
  }
});

const StyledDownArrow = styled(DownArrow)`
  font-weight: bold;
  font-size: 16px;
  opacity: 0.6;
`;

const DownArrowContainer = styled.div`
  position: absolute;
  right: 0;
  top: 5px;

  @media ${device.mobile} {
    top: 10px;
  }
`;

const DisclosureButton = styled.button`
  position: relative;
  width: 100%;
  background-color: transparent;
  border: 0;
  text-align: center;
  padding: 25px 0;
  flex-grow: 0;
  cursor: pointer;
  margin: 0;

  @media ${device.mobile} {
    height: 60px;
    width: 70px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 0;
    padding: 0;
  }

  &:hover,
  &:focus {
    background-color: rgb(38, 42, 52);

    ${StyledDownArrow} {
      opacity: 0.9;
    }
  }
`;

const AvatarWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const UserMenuContent = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  font-size: 13px;
  hr {
    width: 170px;
    margin: 0 15px 10px 15px;
    border: none;
    border-top: solid 1px #d9d9d9;
  }
`;

const UserMenuHeader = styled.div`
  margin: 5px 15px 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #6a778e;
`;

const StatusList = styled.ul`
  padding: 0 0 10px 10px;
  margin-bottom: 0;
  list-style-type: none;
  li button {
    padding: 8px 15px;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    background: 0;
    border: 0;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      outline: 0;
      background-color: #dadde0;
    }
  }
`;

const LinksContainer = styled.div`
  padding: 0 15px;
  a:first-of-type {
    margin-bottom: 8px;
  }
  white-space: nowrap;
`;

const StatusListOnlineStatus = styled.i<{ color: string }>`
  margin: 0 10px;
  border: none;
  box-sizing: border-box;
  display: inline-block;
  width: 12px;
  height: 12px;
  line-height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

const statusMenuLinkStyles = css`
  color: #006aff;
  text-decoration: none;
  display: flex;
  font-size: 13px;
  padding: 2px;
  background: 0;
  border: 0;
  width: 100%;

  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px #006aff;
    border-radius: 3px;
  }
`;

const StatusMenuLinkButton = styled.button`
  ${statusMenuLinkStyles}
`;
const StatusMenuLink = styled.a`
  ${statusMenuLinkStyles}
`;

interface Props {
  user: User;
  isConnected: boolean;
  isOnline: boolean;
  onLogout?: () => void;
  openLink: (linkTargetType: string) => void;
  changeUserStatus?: (status: string) => void;
  userStatus: 'available' | 'chat' | 'dnd' | 'away';
  features: State['core']['app']['features'];
}

export const UserMenu: React.FC<Props> = ({
  user,
  onLogout,
  openLink,
  changeUserStatus = () => {},
  userStatus = 'chat' as 'chat',
  features = []
}) => {
  const fullName = getFullName(user);
  const { formatMessage: f } = useIntl();
  const popoverRef = useRef<PopoverMethods>(null);

  const toggleUserStatus = (availabilityStatus: string) => {
    changeUserStatus(availabilityStatus);
    if (popoverRef.current) {
      popoverRef.current.hide();
    }
  };

  const chatEnabled = features.includes('chat');

  const handleProfileClick = () => {
    openLink('accountProfile');
    if (popoverRef.current) {
      popoverRef.current.hide();
    }
  };

  const disclosure = (
    <DisclosureButton aria-label={f(messages.details)}>
      <AvatarWrapper>
        <AvatarOnlineStatus
          name={fullName}
          img={user.avatarUrl}
          userStatus={userStatus}
          chatEnabled={chatEnabled}
          isExternal={false}
          color={'#342E41'}
          backgroundColor={'#CACED3'}
        />
        <DownArrowContainer>
          <StyledDownArrow size={20} unit="px" color={'#fff'} />
        </DownArrowContainer>
      </AvatarWrapper>
    </DisclosureButton>
  );

  return (
    <div data-testid="user-menu">
      <Popover
        placement="right-start"
        disclosure={disclosure}
        ariaLabel={f(messages.statusHeadline)}
        ref={popoverRef}
        id="user-menu-popover"
      >
        <UserMenuContent data-testid="user-menu-content">
          {chatEnabled && (
            <span>
              <UserMenuHeader>
                <FormattedMessage {...messages.statusHeadline} />
              </UserMenuHeader>
              <StatusList>
                <li>
                  <button onClick={() => toggleUserStatus(status.chat)}>
                    <StatusListOnlineStatus color={statusColorMapping.available} />
                    <FormattedMessage {...messages.statusAvailable} />
                  </button>
                </li>
                <li>
                  <button onClick={() => toggleUserStatus(status.dnd)}>
                    <StatusListOnlineStatus color={statusColorMapping.dnd} />
                    <FormattedMessage {...messages.statusDnd} />
                  </button>
                </li>
                <li>
                  <button onClick={() => toggleUserStatus(status.away)}>
                    <StatusListOnlineStatus color={statusColorMapping.away} />
                    <FormattedMessage {...messages.statusAway} />
                  </button>
                </li>
              </StatusList>
              <hr />
            </span>
          )}
          <LinksContainer>
            <StatusMenuLink
              href={config.externalLinks.profileInfo}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleProfileClick}
            >
              <FormattedMessage {...messages.myProfile} />
            </StatusMenuLink>
            <StatusMenuLinkButton onClick={onLogout}>
              <FormattedMessage {...messages.signOut} />
            </StatusMenuLinkButton>
          </LinksContainer>
        </UserMenuContent>
      </Popover>
    </div>
  );
};
