import { SchedulerTabProps, SchedulerTabs } from '../MeetingScheduler';
import { AudioSettings } from '../../../Preferences/AudioSettings/AudioSettings';
import * as React from 'react';
import { useEffect } from 'react';
import { Spinner } from '../../../Common/Toolkit/Spinner/Spinner';
import styled from '../../../../styled-components';
import { AudioSettingItems, getAudioValidationErrors } from '../../../../../lib/audio';
import ReactTooltip from 'react-tooltip';
import { defineMessages, useIntl } from 'react-intl';
import { AlertLevel } from '../../../../view-model/modules/core/errors/errorActions';

const messages = defineMessages({
  breakoutRoomAlertOwnConference: {
    id: 'meetingDashboard.meetingScheduler.audioTab.AlertNoBreakoutWithOwnConference',
    defaultMessage: 'Breakout rooms are disabled when using your own conference call service'
  },
  breakoutRoomAlertPhoneNumbers: {
    id: 'meetingDashboard.meetingScheduler.audioTab.AlertNoBreakoutWithPhoneNumbers',
    defaultMessage: 'Breakout rooms are disabled when using phone numbers'
  }
});

const AudioContainer = styled.div`
  padding: 10px 16px 8px 4px;
`;

const SpinnerCentered = styled(Spinner)`
  margin: auto;
`;

export const AudioTab = (props: SchedulerTabProps) => {
  const { formatMessage: f } = useIntl();
  const showSpinner = props.isLoadingSettings || props.meeting.update.voipEnabled === undefined;
  const { meeting, onValidationSuccess, onValidationError } = props;

  useEffect(() => {
    if (showSpinner) {
      return;
    }

    ReactTooltip.rebuild();
    const validationErrors = getAudioValidationErrors(meeting.update as AudioSettingItems);
    if (validationErrors.length === 0) {
      onValidationSuccess('voipEnabled', SchedulerTabs.audio);
    } else {
      onValidationError('voipEnabled', SchedulerTabs.audio);
    }
  }, [meeting.update, showSpinner, onValidationSuccess, onValidationError]);

  if (showSpinner) {
    return <SpinnerCentered />;
  }

  return (
    <AudioContainer>
      <AudioSettings
        isDisabled={props.isLoadingSettings || props.isUpdating}
        audioSettings={props.meeting.update as AudioSettingItems}
        onChange={props.onPropertyChange}
        displayBreakoutRoomWarning={props.meeting.update.breakoutsAllowed}
        onDisableBreakoutRoom={(isOwnConferenceSelected) => {
          props.onPropertyChange('breakoutsAllowed', false);
          const alertText = isOwnConferenceSelected
            ? f(messages.breakoutRoomAlertOwnConference)
            : f(messages.breakoutRoomAlertPhoneNumbers);
          props.showAlert({
            alertLevel: AlertLevel.INFO,
            alertText,
            closeOnTabChange: true
          });
        }}
      />
    </AudioContainer>
  );
};
