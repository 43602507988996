import React from 'react';
import styled from '../../../styled-components';
import { themeGet } from '@styled-system/theme-get';

interface Props {
  text: string;
  className?: string;
}

export const Badge = styled(({ text, ...rest }: Props) => (
  <span {...rest}>
    <span>{text.toLowerCase()}</span>
  </span>
))`
  display: inline-block;
  background: ${themeGet('badge.colors.primary')};
  color: ${themeGet('badge.colors.primaryOn')};
  border-radius: 4px;
  height: 15px;
  font-family: 'lato-bold', 'Helvetica Neue', arial, sans-serif;
  font-size: 12px;
  span {
    padding: 0 8px 0 8px;
  }
`;
