import { get } from 'lodash';
import { RosterActions } from '@getgo/caps-redux';
import Events from '../../EventConstants';
import { getFeatureByNS } from '../../../../config/featuresHelper';

export default {
  handle({ type, from, resource }: { type: string; from: string; resource: string }, _: object) {
    if (type !== 'available') {
      return null;
    }

    const caps = RosterActions.getCapabilities(`${from}/${resource}`);

    if (caps?.length > 0) {
      const features = caps
        .filter((el: { name: string }) => el.name === 'feature')
        .map((feature: any) => {
          return get(feature, 'attributes.var.nodeValue', '');
        })
        .map((xmlNS: any) => {
          const feature = getFeatureByNS(xmlNS);
          return feature.length > 0 ? feature[0].name : xmlNS;
        });

      return {
        type: Events.CONTACT_CAPABILITIES_UPDATED,
        data: {
          jid: from,
          resource,
          features: features || []
        }
      };
    }
    return null;
  }
};
