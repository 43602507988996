import * as React from 'react';
import config from 'config';
import { Box, Item } from '../Common/Toolkit/Box/Box';
import styled from '../../styled-components';
import { FormattedTime, defineMessages, useIntl } from 'react-intl';
import { getDiffInDays } from '../../../lib/date';
import { DiagnosticsButton } from './DiagnosticsButton';
import { Session } from 'src/app/view-model/modules/meetings/MeetingsTypes';

const AttendeeTable = styled.table`
  width: 100%;
  border: 1px solid #e0e4ea;
  border-radius: 4px;
  border-spacing: 0;
  margin-top: 16px;

  th {
    font-size: 12px;
    text-align: left;
    color: #6b7582;
    font-weight: normal;
  }

  td,
  th {
    padding: 16px 0px;

    &:first-child,
    &:last-child {
      padding: 16px 32px;
    }
  }

  thead {
    th {
      border: 0;
    }
  }

  td,
  th {
    border-top: 1px solid #e0e4ea;
  }
`;

const Title = styled.h3`
  font-size: 16px;
  flex: 1 1 100%;
  margin: 0;
  font-weight: normal;
`;

const messages = defineMessages({
  title: {
    id: 'meetingHub.attendeeList.title',
    defaultMessage: 'Attendee List'
  },
  name: {
    id: 'meetingHub.attendeeList.name',
    defaultMessage: 'Name'
  },
  email: {
    id: 'meetingHub.attendeeList.email',
    defaultMessage: 'Email Address'
  },
  times: {
    id: 'meetingHub.attendeeList.times',
    defaultMessage: 'Join & Leave Times'
  }
});

export const Time = ({ joinTime, leaveTime }: { joinTime?: string; leaveTime?: string }) => {
  if (!joinTime || !leaveTime) {
    return null;
  }
  return (
    <>
      <FormattedTime value={joinTime} />
      {' - '}
      <FormattedTime value={leaveTime} />
    </>
  );
};

interface Props {
  session: Session;
}

export const AttendeeList: React.FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();
  let diagnosticsButton;
  if (props.session.diagnosticsProcessingStatus === 'available') {
    const isMoreThanSixMonthsOld = Math.abs(getDiffInDays(new Date(), props.session.startTime)) > 6 * 30;
    if (!isMoreThanSixMonthsOld) {
      diagnosticsButton = (
        <DiagnosticsButton
          targetUrl={`${config.externalLinks.meetingDiagnostics}/#/sessions/${props.session.sessionId}`}
          isLive={false}
        />
      );
    } else {
      diagnosticsButton = <DiagnosticsButton expired isLive={false} />;
    }
  }

  return (
    <Box flexDirection={'column'}>
      <Box alignItems={'center'}>
        <Title>{f(messages.title)}</Title>
        {diagnosticsButton}
      </Box>
      <Item>
        <AttendeeTable>
          <thead>
            <tr>
              <th>{f(messages.name)}</th>
              <th>{f(messages.email)}</th>
              <th>{f(messages.times)}</th>
            </tr>
          </thead>
          <tbody>
            {props.session.attendees.map((attendee, index) => (
              <tr key={`${attendee.name}_${index}`}>
                <td>{attendee.name}</td>
                <td>{attendee.email}</td>
                <td>
                  <Time joinTime={attendee.joinTime} leaveTime={attendee.leaveTime} />
                </td>
              </tr>
            ))}
          </tbody>
        </AttendeeTable>
      </Item>
    </Box>
  );
};
