import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { ButtonBar } from '../../Common/Toolkit/Button/ButtonBar';
import { ModalDialogContentWrapper } from '../../Common/ModalDialog/ModalDialogContentWrapper';
import actions from '../../../view-model/modules/meetings/meetingsActions';
import { useDispatch } from 'react-redux';

interface Props {
  deleteRecording: () => void;
  hideDeleteRecording: () => void;
}

const messages = defineMessages({
  title: {
    id: 'meetingsDashboard.meetingHistory.recordingDelete.delete.title',
    defaultMessage: 'Delete meeting content'
  },
  confirmationMessage: {
    id: 'meetingsDashboard.meetingHistory.recordingDelete.delete.confirmationMessage',
    defaultMessage: "Are you sure you want to delete all of this meeting's content?"
  },
  cancel: {
    id: 'meetingsDashboard.meetingHistory.recordingDelete.delete.cancel',
    defaultMessage: 'Cancel'
  },
  delete: {
    id: 'meetingsDashboard.meetingHistory.recordingDelete.delete.delete',
    defaultMessage: 'Delete'
  }
});

export const RecordingDelete: React.FC<Props> = ({ deleteRecording, hideDeleteRecording }) => {
  const dispatch = useDispatch();
  const deleteRecordingAndHide = () => {
    dispatch(actions.sessionDeleteInProcess(true));
    deleteRecording();
    hideDeleteRecording();
  };

  return (
    <ModalDialogContentWrapper
      onClose={hideDeleteRecording}
      title={<FormattedMessage {...messages.title} />}
      testId={'recording-delete-modal'}
    >
      <FormattedMessage {...messages.confirmationMessage} />
      <ButtonBar
        buttonOk={<FormattedMessage {...messages.delete} />}
        buttonCancel={<FormattedMessage {...messages.cancel} />}
        onOk={deleteRecordingAndHide}
        onCancel={hideDeleteRecording}
      />
    </ModalDialogContentWrapper>
  );
};
