import React, { Component, ReactNode } from 'react';
import shallowCompare from 'react-addons-shallow-compare';

interface Props {
  category: {
    title: string;
  };
  headingDecoration?: ReactNode;
  style?: object;
}

export class CategoryHeader extends Component<Props, {}> {
  shouldComponentUpdate(nextProps: Props, nextState: {}): boolean {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    const { category, headingDecoration, style } = this.props;

    return (
      <div className="emoji-category-header" style={style}>
        <h2 className="emoji-category-title">{category.title}</h2>
        <div className="emoji-category-heading-decoration">{headingDecoration}</div>
      </div>
    );
  }
}
