import * as React from 'react';
import { UpcomingMeetingsList } from './UpcomingMeetingsList';
import { MeetingsTabSpinner } from '../MeetingComponents';
import { User, State as UserState } from '../../../view-model/modules/core/user/userReducer';
import { ScheduledMeeting, SelectableItemType } from '../../../view-model/modules/meetings/MeetingsTypes';

interface Props {
  user: UserState;
  isOnline: boolean;
  isFetching: boolean;
  upcomingMeetings: ScheduledMeeting[];
  fetchMyMeetings: () => void;
  selectedId: string | null;
  selectedItemType: SelectableItemType;
  selectMeeting?: (itemType: SelectableItemType, id?: string) => void;
  showCreateMeeting: (user: User) => void;
}

export class UpcomingMeetings extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchMyMeetings();
  }

  componentDidUpdate(prevProps: Props) {
    // refresh meeting history when coming back online
    if (this.props.isOnline && !prevProps.isOnline) {
      this.props.fetchMyMeetings();
    }
  }

  render() {
    const { upcomingMeetings, selectedId, selectedItemType } = this.props;

    const meetingsList = (
      <UpcomingMeetingsList
        isOnline={this.props.isOnline}
        upcomingMeetings={upcomingMeetings}
        selectedId={selectedId}
        selectedItemType={selectedItemType}
        selectMeeting={this.props.selectMeeting}
        user={this.props.user}
      />
    );

    return this.props.isFetching && !upcomingMeetings.length ? <MeetingsTabSpinner /> : meetingsList;
  }
}
