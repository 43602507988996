import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '../../../styled-components';
import { GlobalSearchMessages as messages } from '../GlobalSearchMessages';
import {
  ElasticSearchPage,
  ElasticSearchResult,
  SessionFromElasticSearch
} from '../../../view-model/modules/meetings/meetingsReducer';
import { formatTime } from '../../../../lib/date';
import { LoadMoreButtonTranscript, UnorderedList } from './TitleTab';
import { Spinner } from '../../Common/Toolkit/Spinner/Spinner';
import { TranscriptsTabRow } from './TranscriptsTabRow';

export const ElasticSearchSpinner = styled((props: any) => (
  <div className={props.className}>
    <Spinner />
  </div>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const NoSearchPlaceholder = styled.div`
  font-size: 16px;
  color: rgba(37, 40, 45, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;

export const MessagePlaceHolder = (props: { message: any }) => {
  return <NoSearchPlaceholder>{props.message}</NoSearchPlaceholder>;
};

const PlaceHolderMessageStrong = styled.strong`
  text-align: center;
  font-size: 20px;
`;

const PlaceHolderMessageHeading = styled.h4`
  text-align: center;
`;

interface Props {
  search: ElasticSearchResult[] | [];
  openRecording: (sessionId: string) => void;
  searchText: string | undefined;
  pageInfo: ElasticSearchPage;
  fetching: boolean;
  loadMore: (name?: string) => void;
  disableOnClickOutside: () => void;
}

export const ErrorMessage = (props: {
  message?: any;
  searchText?: string;
  list?: Array<React.ReactElement<any>>;
  pageInfo?: ElasticSearchPage;
}) => {
  return props.searchText?.length && !props.list?.length && props.pageInfo?.resultsFetched === 'done' ? (
    <FormattedMessage {...messages.noResultMessage} values={{ search: `"${props.searchText}"` }} />
  ) : (
    <>
      <PlaceHolderMessageStrong>
        <FormattedMessage {...messages.searchMessageOnLoadTop} />
      </PlaceHolderMessageStrong>
      <PlaceHolderMessageHeading>
        <FormattedMessage {...messages.searchMessageOnLoadBottom} />{' '}
      </PlaceHolderMessageHeading>
    </>
  );
};

export const TranscriptsTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const transcriptList: Array<React.ReactElement<any>> = [];
  const handleRecording = (session: SessionFromElasticSearch) => {
    props.openRecording(session.id);
  };
  const placeholder = <ErrorMessage {...props} />;

  props.search?.forEach((result) => {
    const { isCbr, id, startTime, title } = result?.session;
    const content =
      result.highlight &&
      result.highlight['contentDetails.content'] &&
      result.highlight['contentDetails.content'].length
        ? result.highlight['contentDetails.content'][0]
        : '';
    if (content) {
      const meetingStartTime = startTime ? formatTime(intl, startTime) : '';

      transcriptList.push(
        <TranscriptsTabRow
          disableOnClickOutside={props.disableOnClickOutside}
          isCbr={isCbr}
          key={id}
          title={title}
          meetingStartTime={meetingStartTime}
          handleRecording={handleRecording}
          session={result.session}
          content={content}
          sessionId={id}
          startTime={startTime}
        />
      );
    }
  });

  if (transcriptList.length && props.pageInfo.hasNext > 0) {
    transcriptList.push(
      <li key="loadMore-transcript-button">
        <LoadMoreButtonTranscript
          className={'loadMore-transcript-tab-class'}
          id="loadMore-transcript-tab"
          onClick={() => props.loadMore('')}
          isLoading={props.fetching}
        />
      </li>
    );
  }

  return props.fetching && !transcriptList.length ? (
    <ElasticSearchSpinner />
  ) : transcriptList.length ? (
    <UnorderedList>{transcriptList}</UnorderedList>
  ) : (
    <MessagePlaceHolder message={placeholder} />
  );
};
