import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../lib/messages';
import styled from '../../../styled-components';
import { Box } from '../Toolkit/Box/Box';
import { Icon } from '../Toolkit/Icon/Icon';
import { Icons } from '../Toolkit/ICONS';

const Title = styled.h2`
  font-size: 24px;
  font-family: 'lato-semibold';
  font-weight: 600;
  margin: 0;
  padding-bottom: 14px;
`;

const ShowAdvancedInfoLink = styled.a`
  color: #21a0eb;
  cursor: pointer;
`;

const InfoBox = styled.div`
  font-family: 'lato-italic';
  font-style: italic;
`;

const ErrorIconBox = styled.div`
  margin-right: 20px;
`;

interface State {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
  showAdvancedInfo: boolean;
}

interface Props {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
    errorInfo: null,
    showAdvancedInfo: false
  };

  private onShowAdvancedInfoClick = () => {
    this.setState({
      showAdvancedInfo: true
    });
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.error && this.state.errorInfo) {
      return (
        <Box flexDirection="row">
          <ErrorIconBox>
            <Icon color="#FE9500" icon={Icons.alert} size={30} />
          </ErrorIconBox>
          <div>
            <Title>
              <FormattedMessage {...messages.genericError} />
            </Title>
            <p>
              <FormattedMessage {...messages.genericErrorMessage} />
            </p>
            {!this.state.showAdvancedInfo ? (
              <ShowAdvancedInfoLink data-testid="advanced-info-link" onClick={this.onShowAdvancedInfoClick}>
                <FormattedMessage {...messages.advancedInfo} />
              </ShowAdvancedInfoLink>
            ) : (
              <InfoBox>{(this.state.error as any).message}</InfoBox>
            )}
          </div>
        </Box>
      );
    }
    return this.props.children;
  }
}
