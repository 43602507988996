import React, { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import device from '../Common/Toolkit/devices';
import { CloseFilled, Search } from '../Common/Toolkit/Icon/ReactIcons';
import Scrollbars from 'react-custom-scrollbars';
import { TitleTab } from './Tabs/TitleTab';
import { TranscriptsTab } from './Tabs/TranscriptsTab';
import { FormattedDate, FormattedMessage, injectIntl, IntlShape, WrappedComponentProps } from 'react-intl';
import { GlobalSearchMessages as messages } from './GlobalSearchMessages';
import { UnstyledButton } from '../Common/Toolkit/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import actions, {
  fetchElasticSearchResults,
  resetElasticFetchResults,
  triggerTelemetryElasticSearch
} from '../../view-model/modules/meetings/meetingsActions';
import { themeGet } from '@styled-system/theme-get';
import { State } from '../../view-model/types';
import config from 'config';
import { TabChild, Tabs } from '../Meetings/MeetingScheduler/Tabs/Tabs';
import { PrimaryButton } from '../Common/Toolkit/Button/PrimaryButton';
import './GlobalSearchDetailsView.css';
import { KeyCodes } from '../../../lib/keycodes';
import { getEndOfDay } from '../../../lib/date';
import { DateRangePicker } from '../Common/DateTime/DateRangePicker';
import { AttendeesTab } from './Tabs/AttendeesTab';
import { SearchMessage } from './SearchMessage/SearchMessage';
import { updateGlobalSearchStartTime } from './GlobalSearchComponents';

const StyledInput = styled.input`
  box-sizing: border-box;
  padding: 0 36px 0 45px;
  height: 42px;
  line-height: 36px;
  background-color: transparent;
  font-family: 'lato-regular';
  font-size: 16px;
  max-width: 480px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(76, 83, 93, 0.24);

  &:focus {
    outline: none;
    background-color: rgba(37, 40, 45, 0.04);
  }
  &:disabled {
    cursor: not-allowed;
  }

  @media ${device.tablet} {
    margin-bottom: 5px;
  }
  ::-ms-clear {
    display: none;
  }
`;

const SearchIcon = styled((props: { className?: string }) => {
  return <Search className={props.className} data-testid="transcript-search-icon" size={20} unit={'px'} />;
})`
  position: absolute;
  top: 6px;
  left: 6px;
`;

const TranscriptSearchViewContainer = styled.div`
  display: flex;
  padding: 32px;
  position: relative;
  flex-direction: column;

  @media ${device.mobile} {
    max-height: 400px;
    height: 100%;

    li[data-testid='tab-title'] a > span,
    li[data-testid='tab-transcripts'] a > span,
    li[data-testid='tab-attendees'] a > span {
      width: auto !important;
    }
  }
`;

const SearchHeader = styled.div`
  font-size: 28px;
  font-weight: 600;
  color: #25282d;
  line-height: 1.2;
  font-family: lato-bold;
`;
const SearchInputContainer = styled.div`
  position: relative;
  max-width: 480px;
  flex-grow: 1;

  @media ${device.laptop} {
    margin-right: 6px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
  margin-top: 16px;
  align-items: center;
  flex-wrap: wrap;
`;

const TabContainer = styled.div`
  flex-basis: 417px;
  display: flex;
  flex-direction: column;
  > div {
    flex: 1;
  }
`;

const CloseButton = styled(UnstyledButton)`
  position: absolute;
  top: 11px;
  right: 11px;
  z-index: 2;
  overflow: hidden;
  height: 22px;
  width: 22px;
  &:disabled {
    cursor: not-allowed;
  }
`;
const CrossIcon = (props: {
  onClick: () => void;
  color?: string;
  dataTestId: string;
  disabled?: boolean;
  label: string;
}) => {
  return (
    <CloseButton onClick={props.onClick} disabled={props.disabled} aria-label={props.label}>
      <CloseFilled size={26} unit={'px'} color={props.color} data-testid={props.dataTestId} />
    </CloseButton>
  );
};

export const TranscriptsTabContainer = styled.div`
  padding: 24px 32px 24px 29px;

  @media ${device.laptop} {
    padding: 16px 20px;
  }

  @media ${device.mobile} {
    padding: 16px;
  }
`;

export const SearchListContent = styled(UnstyledButton)`
  width: 100%;
  text-align: initial;
`;

interface PrimaryButtonProps {
  selected: boolean;
}
const SelectableButtonContainer = styled(PrimaryButton)<PrimaryButtonProps>`
  padding: 0 12px 0 8px;
  margin: 0 0 0 10px;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 40px;
  color: ${(props) => (props.selected ? `#ffffff` : '#25282d')};
  background: ${(props) => (props.selected ? `${themeGet('colors.primaryOn')}` : 'rgba(154, 168, 187, 0.16)')};

  span {
    padding: 0 8px 0 0;
  }

  @media ${device.mobile} {
    margin: 8px 8px 8px 0px;
  }

  &:hover:enabled {
    background: ${(props) => (props.selected ? `${themeGet('colors.primaryOn')}` : '#c4c9cd')};
  }

  .main-path {
    fill: ${(props) => (props.selected ? `${themeGet('colors.primaryOn')}` : 'rgba(154, 168, 187, 0.16)')};
  }

  &:focus:enabled {
    box-shadow: 0 0 0 1px ${themeGet('colors.surface')},
      0 0 0 3px ${(props) => (props.selected ? `${themeGet('colors.primaryOn')}` : '#c4c9cd')};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

const ButtonIcon = styled.i`
  margin-right: 8px;
`;

const SelectableButton = forwardRef(function selectableButton(
  props: {
    icon?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    selected: boolean;
    disabled?: boolean;
    intl: IntlShape;
    start?: Date;
    end?: Date;
  },
  _ref: any
) {
  const selectedStartDateYear = props.start?.getFullYear() === new Date().getFullYear() ? undefined : 'numeric';
  const selectedEndDateYear = props.end?.getFullYear() === new Date().getFullYear() ? undefined : 'numeric';
  return (
    <SelectableButtonContainer {...props}>
      {props.icon && <ButtonIcon className={props.icon} />}
      {props.start && <FormattedDate month="short" day="numeric" year={selectedStartDateYear} value={props.start} />}
      {props.end && (
        <>
          {' - '}
          <FormattedDate month="short" day="numeric" year={selectedEndDateYear} value={props.end} />
        </>
      )}
    </SelectableButtonContainer>
  );
});

export enum ElasticSearchTab {
  title = 'title',
  transcripts = 'transcripts',
  attendees = 'attendees'
}

const elasticSearchCriteria: { [any: string]: string } = {
  title: 'TITLE',
  transcripts: 'TRANSCRIPT',
  attendees: 'ATTENDEE'
};

const SearchButton = styled((props: { onClick: () => void; disabled?: boolean }) => (
  <PrimaryButton data-testid="button-search" {...props} disabled={props.disabled}>
    <FormattedMessage {...messages.search} />
  </PrimaryButton>
))`
  display: none;
  @media ${device.mobile} {
    display: inline-block;
    margin: 0px 7px 0px 4px;
    height: 40px;
  }
`;

const TabTitle = styled.span`
  display: inline-block;
`;

interface Props {
  showElasticSearchDateRangePicker: () => void;
  hideElasticSearchDateRangePicker: () => void;
}

const GlobalSearchDetailsView: React.FC<WrappedComponentProps & Props> = (props) => {
  const [tab, setTab] = useState(ElasticSearchTab.transcripts);
  const [lastOneMonthMeetings, setLastOneMonthMeetings] = useState(false);
  const [query, setQuery] = useState('');
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [selectionComplete, toggleSelectionComplete] = useState(false);
  const [retainFlag, setRetainFlag] = useState(false);

  const datepickerRef = useRef<any>(null);

  const meeting = useSelector((state: State) => state.meetings);
  const selectedItemType = useSelector((state: State) => state.meetings.selectedItemType);
  const elasticSearchResult = meeting.elasticSearchContentList;
  const elasticSearchPagesInfo = meeting.elasticSearchPagesInfo;
  const fetching = meeting.isFetchingElasticSearch;
  const retain = meeting.retainSearch;
  const attendeeCountFlag = useSelector((state: State) => state.meetings.attendeeCount);

  useEffect(() => {
    const { current } = searchField;
    if (current) {
      setQuery(retain.query);
      current.value = retain.query;
    }
    let selectedTab: ElasticSearchTab;
    switch (retain.tab) {
      case 'TITLE':
        selectedTab = ElasticSearchTab.title;
        break;
      case 'TRANSCRIPT':
        selectedTab = ElasticSearchTab.transcripts;
        break;
      case 'ATTENDEE':
        selectedTab = ElasticSearchTab.attendees;
        break;
      default:
        selectedTab = ElasticSearchTab.transcripts;
        break;
    }
    if (retain?.interval?.fromTime && retain?.interval?.toTime) {
      setLastOneMonthMeetings(retain.dateSelected);
    }
    setRetainFlag(true);
    setTab(selectedTab);
    setTimeout(() => props.hideElasticSearchDateRangePicker(), 500);
  }, [selectedItemType]);

  const leftExtremeCalendarValue = updateGlobalSearchStartTime();
  const dispatch = useDispatch();
  const { formatMessage: f } = props.intl;
  const searchField = React.createRef<HTMLInputElement>();

  const calculateDateRanges = () => {
    const fromTime = startDate ? startDate.getTime() : leftExtremeCalendarValue.getTime();
    const toTime = endDate ? getEndOfDay(endDate).getTime() : getEndOfDay(new Date()).getTime();
    return {
      fromTime,
      toTime
    };
  };

  const handleInputClickButton = () => {
    if (trimSearchString().length) {
      setQuery(getInputValue());
      dispatch(triggerTelemetryElasticSearch('enterButtonClicked'));
      dispatch(
        fetchElasticSearchResults(
          trimSearchString(),
          calculateDateRanges().fromTime,
          elasticSearchCriteria[tab],
          0,
          calculateDateRanges().toTime,
          lastOneMonthMeetings
        )
      );
    }
  };

  const trimSearchString = () => {
    return getInputValue().trim();
  };

  const getInputValue = () => {
    return searchField?.current?.value ? searchField.current.value : '';
  };

  const searchHandler = (event: { keyCode: number; which: number }) => {
    if ((event.keyCode === KeyCodes.Enter || event.which === KeyCodes.Enter) && trimSearchString().length) {
      handleInputClickButton();
    }
  };

  const resetSearchList = () => {
    dispatch(resetElasticFetchResults(retain, lastOneMonthMeetings));
  };

  const handleCancel = () => {
    const { current } = searchField;
    if (current?.value) {
      current.value = '';
      setQuery('');
      resetSearchList();
    }
  };

  const onTabChange = (updatedTabValue: any) => {
    setTab(updatedTabValue);
    setQuery(getInputValue());
    resetSearchList();
    const searchString = trimSearchString();
    if (searchString.length) {
      dispatch(
        fetchElasticSearchResults(
          searchString,
          calculateDateRanges().fromTime,
          elasticSearchCriteria[updatedTabValue],
          0,
          calculateDateRanges().toTime,
          lastOneMonthMeetings
        )
      );
      setQuery(getInputValue());
    }
  };

  const closeDateRangePicker = () => {
    datepickerRef.current!.setOpen(false);
  };

  const fetchAttendeeSessions = (attendeeName?: string) => {
    const searchString = query.trim();
    dispatch(triggerTelemetryElasticSearch('attendeeExpanded'));
    dispatch(
      fetchElasticSearchResults(
        searchString,
        calculateDateRanges().fromTime,
        elasticSearchCriteria[tab],
        0,
        calculateDateRanges().toTime,
        lastOneMonthMeetings,
        attendeeName,
        0
      )
    );
  };

  const loadMore = (attendeeName?: string) => {
    let list;
    let attendeeOffsetValue;
    if (tab === 'title') {
      list = elasticSearchPagesInfo.titleList;
    } else if (tab === 'attendees') {
      list = elasticSearchPagesInfo.attendeeList;
      attendeeOffsetValue = elasticSearchResult.attendeeList.length;
    } else {
      list = elasticSearchPagesInfo.transcriptList;
    }
    if (attendeeName) {
      list = elasticSearchResult.attendeeList.find((attendee: any) => attendee.key === attendeeName)?.sessions
        .elasticSearchPage;
      attendeeOffsetValue = undefined;
    }
    const offset = list && list.totalHits - list.hasNext;
    const searchString = query.trim();
    dispatch(
      fetchElasticSearchResults(
        searchString,
        calculateDateRanges().fromTime,
        elasticSearchCriteria[tab],
        offset,
        calculateDateRanges().toTime,
        lastOneMonthMeetings,
        attendeeName,
        attendeeOffsetValue
      )
    );
  };

  useEffect(() => {
    dispatch(triggerTelemetryElasticSearch('tabSelected', undefined, tab));
    dispatch(actions.selectedGlobalSearchTab(tab));
  }, [tab]);

  useEffect(() => {
    if (selectionComplete && startDate && endDate && !retainFlag) {
      dispatch(triggerTelemetryElasticSearch('dateRangeSelected'));
      closeDateRangePicker();
      setQuery(getInputValue());
      setLastOneMonthMeetings(true);
      if (trimSearchString().length) {
        dispatch(
          fetchElasticSearchResults(
            trimSearchString(),
            calculateDateRanges().fromTime,
            elasticSearchCriteria[tab],
            0,
            calculateDateRanges().toTime,
            lastOneMonthMeetings
          )
        );
      }
    }
  }, [selectionComplete, startDate, endDate]);

  const handleOpenRecording = (id: string) => {
    const sessionId = id ? id.split('-')[1] : '';
    if (sessionId) {
      dispatch(actions.openElasticResultRecording(sessionId, tab.toUpperCase(), query));
      dispatch(triggerTelemetryElasticSearch('openRecordingFromGlobalSearchResult'));
      let search = '';
      if (tab === 'attendees') {
        search = query
          .trim()
          .split(' ')
          .filter((item) => item.indexOf('@') === -1)
          .join('_');
      } else {
        search = query
          .trim()
          .split(' ')
          .join('_');
      }

      if (tab == 'title') {
        window.open(`${config.externalLinks.g2mTranscripts}/#/sessionId/${sessionId}`, '_blank');
      } else {
        window.open(`${config.externalLinks.g2mTranscripts}/#/sessionId/${sessionId}?search=${search}`, '_blank');
      }
    }
  };

  const receiveValuesFromDateRangePicker = (start: Date | undefined, end: Date | undefined, selection: boolean) => {
    if (start && end && selection) {
      setStartDate(start);
      setEndDate(end);
      toggleSelectionComplete(selection);
      setLastOneMonthMeetings(true);
    }
  };

  const updateRetainFlag = () => {
    setRetainFlag(false);
  };

  const tabs: Array<TabChild<ElasticSearchTab>> = [];
  tabs.push({
    count: elasticSearchPagesInfo.transcriptList.totalHits,
    title: f(messages.transcripts),
    name: ElasticSearchTab.transcripts,
    child: (
      <TabContainer>
        <Scrollbars>
          <TranscriptsTab
            search={elasticSearchResult.transcriptList}
            openRecording={handleOpenRecording}
            searchText={query}
            pageInfo={elasticSearchPagesInfo.transcriptList}
            fetching={fetching}
            loadMore={loadMore}
            disableOnClickOutside={props.showElasticSearchDateRangePicker}
          />
        </Scrollbars>
      </TabContainer>
    ),
    icon: <TabTitle>{f(messages.transcripts)}</TabTitle>
  });
  tabs.push({
    count: elasticSearchPagesInfo.titleList.totalHits,
    title: f(messages.title),
    name: ElasticSearchTab.title,
    child: (
      <TabContainer>
        <Scrollbars>
          <TitleTab
            search={elasticSearchResult.titleList}
            openRecording={handleOpenRecording}
            searchText={query}
            pageInfo={elasticSearchPagesInfo.titleList}
            fetching={fetching}
            loadMore={loadMore}
            disableOnClickOutside={props.showElasticSearchDateRangePicker}
          />
        </Scrollbars>
      </TabContainer>
    ),
    icon: <TabTitle>{f(messages.title)}</TabTitle>
  });
  tabs.push({
    count: elasticSearchPagesInfo.attendeeList.totalHits < 0 ? -1 : elasticSearchResult.attendeeList?.length,
    plusSymbol: attendeeCountFlag,
    title: f(messages.attendees),
    name: ElasticSearchTab.attendees,
    child: (
      <TabContainer>
        <Scrollbars>
          <AttendeesTab
            search={elasticSearchResult.attendeeList}
            openRecording={handleOpenRecording}
            searchText={query}
            pageInfo={elasticSearchPagesInfo.attendeeList}
            fetching={fetching}
            loadMore={loadMore}
            disableOnClickOutside={props.showElasticSearchDateRangePicker}
            fetchAttendeeSessions={fetchAttendeeSessions}
            attendeeCountFlag={attendeeCountFlag}
          />
        </Scrollbars>
      </TabContainer>
    ),
    icon: <TabTitle>{f(messages.attendees)}</TabTitle>
  });

  return (
    <TranscriptSearchViewContainer>
      <SearchMessage />
      <SearchHeader>{f(messages.search)}</SearchHeader>
      <TopContainer>
        <SearchInputContainer>
          <SearchIcon />
          <StyledInput
            ref={searchField}
            type="text"
            placeholder={f(messages.search)}
            onKeyPress={searchHandler}
            disabled={fetching}
            aria-label={f(messages.search)}
            autoFocus={true}
            maxLength={255}
          />
          <CrossIcon
            onClick={handleCancel}
            color="#6B7582"
            dataTestId="search-clear"
            disabled={fetching}
            label={f(messages.clear)}
          />
        </SearchInputContainer>
        <SearchButton onClick={handleInputClickButton} disabled={fetching} />
        <DateRangePicker
          monthsShown={2}
          maxDate={new Date()}
          minDate={leftExtremeCalendarValue} // fetching this value from launch darkly flag
          setRef={datepickerRef}
          disabled={fetching}
          updateRetainFlag={updateRetainFlag}
          leftExtremeCalendarValue={leftExtremeCalendarValue}
          showElasticSearchDateRangePicker={props.showElasticSearchDateRangePicker}
          hideElasticSearchDateRangePicker={props.hideElasticSearchDateRangePicker}
          selectedDates={receiveValuesFromDateRangePicker}
          retainStartDate={retain.dateSelected ? retain?.interval?.fromTime : undefined}
          retainEndDate={retain.dateSelected ? retain?.interval?.toTime : undefined}
          customInput={
            <SelectableButton
              selected={lastOneMonthMeetings}
              data-class="button__toolTip"
              intl={props.intl}
              start={startDate ? startDate : leftExtremeCalendarValue}
              end={endDate ? endDate : new Date()}
              icon={'togo-icon togo-icon-calendar togo-icon-lg'}
              data-testid={'calendar-custom-button'}
            />
          }
        />
      </TopContainer>
      {/* eslint-disable-next-line react/no-children-prop */}
      <Tabs<ElasticSearchTab> children={tabs} active={tab} onTabChange={onTabChange} />
    </TranscriptSearchViewContainer>
  );
};

export default injectIntl(GlobalSearchDetailsView);
