import React, { useState, useEffect } from 'react';

interface Props {
  query: string;
  queryMatched?: React.ReactElement<any> | null;
  queryNotMatched?: React.ReactElement<any> | null;
}

export const useMatchesMediaQuery = (query: string) => {
  const [matchesQuery, setMatchesQuery] = useState(false);
  const [mediaQueryList, setMediaQueryList] = useState<MediaQueryList>();

  useEffect(() => {
    const mql = window.matchMedia(query);
    setMediaQueryList(mql);
    setMatchesQuery(mql.matches);
  }, [query]);

  useEffect(() => {
    let onMediaQueryChanged: () => void;

    if (mediaQueryList) {
      onMediaQueryChanged = () => {
        setMatchesQuery(mediaQueryList.matches);
      };

      mediaQueryList.addListener(onMediaQueryChanged);
    }
    return () => {
      if (mediaQueryList) {
        mediaQueryList.removeListener(onMediaQueryChanged);
      }
    };
  }, [mediaQueryList]);

  return matchesQuery;
};

export const MediaQueryFeatureSelector: React.FC<Props> = ({ query, queryMatched = null, queryNotMatched = null }) => {
  const matchesQuery = useMatchesMediaQuery(query);

  return matchesQuery ? queryMatched : queryNotMatched;
};
