export enum KeyNames {
  ARROW_DOWN = 'ArrowDown',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_UP = 'ArrowUp',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  END = 'End',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  HOME = 'Home',
  SPACE = ' ',
  TAB = 'Tab',
  A = 'KeyA',
  Z = 'KeyZ'
}

export const KeyCodes = {
  ArrowDown: 40,
  ArrowLeft: 37,
  ArrowRight: 39,
  ArrowUp: 38,
  Backspace: 8,
  Delete: 46,
  End: 35,
  Enter: 13,
  Escape: 27,
  Home: 36,
  ' ': 32,
  Tab: 9,
  KeyA: 65,
  KeyZ: 90
};
