import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import styled from '../../../styled-components';
import ReactDOM from 'react-dom';
import { getFnsLocale } from '../../../../lib/date';
import { themeGet } from '@styled-system/theme-get';

type onChangeCallback = (date: Date, event?: React.SyntheticEvent<any, Event> | undefined) => void;

const StyledContainer = styled.div`
  & input {
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;

    &:disabled {
      background-color: #fff;
      color: #ccc;
    }
  }

  .react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
  }
`;

const StyledPopperContainer = styled.div`
  .react-datepicker {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 5px 8px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    padding: 10px;
    max-height: 320px;
    margin: 12px 0 0 -12px;
  }

  .react-datepicker.react-datepicker--time-only {
    overflow-y: scroll;
    padding: 0;
  }

  .react-datepicker-popper {
    z-index: 100;
    @media (max-width: 420px) {
      left: -30px !important;
    }
    @media (max-width: 360px) {
      left: -50px !important;
    }
  }

  .react-datepicker__header {
    text-align: center;
    padding: 20px 20px 10px 20px;
    position: relative;
  }

  .react-datepicker__current-month {
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 10px;

    margin-top: -10px;
    padding-top: 10px;
    border-top: 2px solid #e4e4e4;
  }

  .react-datepicker__navigation {
    background: none;
    cursor: pointer;
    position: absolute;
    top: 30px;
    border: none;
    z-index: 1;
    height: 20px;
    width: 20px;
    text-indent: -999em;
    overflow: hidden;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      top: 5px;
      width: 8px;
      height: 8px;
      border: 2px solid #8e9bad;
      transform: rotate(45deg);
      border-top: none;
      border-right: none;
    }

    :focus,
    :active {
      border-radius: 3px;
      box-shadow: 0 0 0 3px ${themeGet('colors.primary')}!important;
    }

    &:hover::after {
      border-color: #b3b3b3;
    }
  }

  .react-datepicker__navigation--previous {
    left: 40px;
  }

  .react-datepicker__navigation.react-datepicker__navigation--next {
    right: 40px;
    &::after {
      transform: rotate(225deg);
    }
  }

  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
  }

  .react-datepicker__day-names {
    border-bottom: 2px solid #e4e4e4;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: #000;
    display: inline-block;
    width: 16px;
    line-height: 16px;
    text-align: center;
    margin: 2px;
    padding: 8px;
    font-weight: 700;
  }

  .react-datepicker__day {
    cursor: pointer;
  }

  .react-datepicker__day--weekend {
    color: ${themeGet('colors.neutralScale.400')};
  }

  .react-datepicker__day--outside-month {
    color: #ccc;
  }

  && .react-datepicker__day--disabled,
  && .react-datepicker__day--disabled:hover {
    color: #eee;
    background-color: transparent;
    cursor: not-allowed;
  }

  .react-datepicker__day:hover {
    border-radius: 20px;
    background-color: #f0f0f0;
  }
  .react-datepicker__day--today {
    border-radius: 20px;
    background-color: #f0f0f0;
    font-weight: bold;
  }

  .react-datepicker__day--selected {
    border-radius: 20px;
    background-color: ${themeGet('colors.primary')};
    color: ${themeGet('colors.primaryOn')};
  }

  .react-datepicker__day--selected:hover {
    background-color: ${themeGet('colors.hover.primary')};
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 20px;
    background-color: ${themeGet('colors.primary')};
    color: ${themeGet('colors.primaryOn')};
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${themeGet('colors.hover.primary')};
  }
`;

// TODO can be removed after the de-scheduling initiative
const SchedulerCalendarContainer = ({ children }: { children: React.ReactNode[] }) => {
  const element = document.getElementById('app');
  if (element) {
    return ReactDOM.createPortal(<StyledPopperContainer>{children}</StyledPopperContainer>, element);
  }
  return null;
};

const CalendarContainer = ({ children }: { children: React.ReactNode[] }) => {
  return <StyledPopperContainer>{children}</StyledPopperContainer>;
};

interface Props {
  onChange: onChangeCallback;
  value?: Date | null;
  disabled?: boolean;
  minDate?: Date | null | undefined;
  maxDate?: Date | null | undefined;
  customInput?: React.ReactNode;
  isSchedulerDatePicker?: boolean;
}

class DatePicker extends React.Component<Props & WrappedComponentProps> {
  render() {
    const { disabled, value, onChange, minDate, maxDate, customInput, intl, isSchedulerDatePicker } = this.props;
    const locale = getFnsLocale(intl);
    const format = locale.formatLong.date({ width: 'short' });

    return (
      <StyledContainer>
        <ReactDatePicker
          disabled={disabled}
          locale={intl.locale}
          selected={value}
          onChange={onChange}
          minDate={minDate}
          maxDate={maxDate}
          dateFormat={format}
          popperContainer={isSchedulerDatePicker ? SchedulerCalendarContainer : CalendarContainer}
          customInput={customInput}
        />
      </StyledContainer>
    );
  }
}

export default injectIntl(DatePicker);
