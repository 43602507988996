import styled from '../../../../styled-components';
import * as React from 'react';
import device from '../devices';

interface Props {
  className?: string;
  image: React.ReactElement<HTMLImageElement>;
  label: string;
}

export const EmptyStatePlaceholder = styled((props: Props) => (
  <div className={props.className}>
    <div>{props.image}</div>
    <h3>{props.label}</h3>
  </div>
))`
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin-top: 64px;
  overflow: hidden;

  // IE needs the extra div and struggles with flex column
  div {
    text-align: center;
    img {
      min-width: 50%;
      max-width: 61.8%;
    }
  }

  h3 {
    color: #6b7581;
    text-align: center;
    padding: 0 16px;
  }

  @media ${device.mobile} {
    margin-top: 12px;
    overflow: visible;

    div {
      img {
        min-width: auto;
        max-width: unset;
        width: 320px;
      }
    }
  }
`;
