import { Reducer } from 'redux';
import { Actions, ActionTypes } from './meetingsActions';
import { ActionTypes as SharedActionTypes } from '../../sharedActions';
import { OptionalPagingAttributes } from '../../../../types/pulse-web';
import device from '../../../components/Common/Toolkit/devices';
import { MeetingType } from '../scheduler/schedulerReducer';
import config from 'config';
import { createMeetingAssetDetails } from '../../../services/DownloadMeetingAssets/utils/createMeetingAssetDetails';
import { Meeting, Organizer, Profile, SelectableItemType, Session } from './MeetingsTypes';

export const itemTypeHasMeetingInformation = (item: SelectableItemType): boolean => {
  const itemTypesWithInvitation = [
    SelectableItemType.SCHEDULED,
    SelectableItemType.RECURRING,
    SelectableItemType.PERSONAL,
    SelectableItemType.IMPROMPTU
  ];
  return itemTypesWithInvitation.includes(item);
};

export interface PersonalizeMeetingProps {
  isNew: boolean;
  isUpdating?: boolean;
  profileIdFetching?: boolean;
  profileIdSuggestion?: string;
}

export interface ErrorWindowProps {
  title?: string;
  message?: string;
  errorCode?: number;
  errorDescription?: string | { code: string; field: string; value: string };
}

export interface DiagnosticsSession {
  sessionId: string;
  meetingId: string;
  organizerKey: string;
  accountKey: string;
  subject: string;
  startTime: Date;
  endTime: Date;
  participants: Participant[];
}

export interface Participant {
  name: string;
  participantId: number;
  joinTime: number;
  leaveTime: number;
  attendeeType: string;
  email: string;
  locations: string;
}

export interface SessionFromElasticSearch {
  product: string;
  meetingId: number;
  title: string;
  userKey: string;
  accountKey: string;
  sourceTimestamp: number;
  isCbr: boolean;
  isPublic: boolean;
  startTime: number;
  id: string;
  endTime: number;
  sourceObjectVersion: number;
  docCreateTime: number;
  docUpdateTime: number;
  participants: Participant[];
}

export interface Highlight {
  title: string[];
  'participants.name': string[];
  'contentDetails.content': string[];
}

export interface ElasticSearchResult {
  session: SessionFromElasticSearch;
  highlight: Highlight;
}

export interface ElasticSearchPage {
  from: number;
  size: number;
  totalHits: number;
  hasNext: number;
  resultsFetched: string;
}

export interface SessionUpdates {
  title: string;
}

export interface AttendeeBucket {
  key: string;
  doc_count: number;
  doc_count_error_upper_bound: number;
  sessions: {
    session: SessionFromElasticSearch[];
    elasticSearchPage: ElasticSearchPage;
  };
}

export interface SessionRecordingScope {
  type: string;
  value: string;
}

export interface Note {
  id: string;
  userKey: number;
  productReferenceKey: string;
  accountKey: number;
  product: string;
  referenceKey: string;
  startEpochTimeStamp: number;
  stopEpochTimeStamp: number;
  type: string;
  origin: string;
  text: string;
  lastUpdatedTimeStamp: number;
  author?: any;
  externalReference?: any;
  version: number;
  status?: any;
  expirationTime?: any;
}

export interface ShareResponse {
  accountKey: string;
  userKey: string;
  sessionKey: string;
  created: number;
  expires: number;
  requestFromOrganizer: boolean;
  scope: Array<{
    type: 'PUBLIC' | 'EMAIL' | 'PRIVATE';
    value?: string;
  }>;
  shareKey: string;
  sharedResources: Array<string>;
}

export interface ContentChatItemContent {
  authorId: string;
  authorName: string;
  group: string;
  sendTime: string;
  receiveTime: string;
  message: string;
}
export interface ContentChatItem {
  timestamp: string;
  content: ContentChatItemContent;
}
export interface ContentChatSuccessResponse {
  sessionMetaData: {
    product: string;
    jobId: string;
  };
  version: number;
  chat: ContentChatItem[] | [];
}

export type ContentChatResponse = ContentChatSuccessResponse | undefined;
export interface ChatListItem {
  id: string;
  authorName: string;
  authorId: string;
  sendTime: string;
  message: string;
}

export type Utterance = {
  formattedTime: string;
  id: string;
  speakers: string[];
  startEpochTimestamp: string;
  startTime: string;
  stopEpochTimestamp: string;
  stopTime: string;
  text: string;
};

export type UtteranceBlock = {
  id: string;
  speakers: string[];
  startEpochTimestamp: string;
  startTime: string;
  stopEpochTimestamp: string;
  stopTime: string;
  utterances: string[];
};

export type TranscriptResponse = {
  duration: string;
  utterances: {
    [key: string]: Utterance;
  };
  utteranceBlock: {
    [key: string]: UtteranceBlock;
  };
  sessionId: string;
  parsingVersion: string;
  recordingKey: string;
  transcriptKey: string;
};

export type SessionHistory = Readonly<{
  sessions: {
    byId: {
      [sessionId: string]: Session;
    };
    allIds: string[];
  };
}> &
  OptionalPagingAttributes;

export type MeetingsState = Readonly<{
  userKey: null | string;

  currentMeetingIsInSessionErrorData: null | Readonly<{
    meetingId: string;
    action: 'edit' | 'delete';
  }>;

  isFetching: boolean;
  meetings: {
    byId: {
      [meetingId: string]: Meeting;
    };
    allIds: string[];
  };

  selectedId: string | null;
  selectedItemType: SelectableItemType;

  isFetchingProfile: boolean;
  profile?: Profile;

  history: SessionHistory & {
    isFetching: boolean;
  };

  currentScheduler: null | Readonly<{
    meetingId: string;
    schedulerUrl: string;
  }>;

  currentPersonalizeMeeting: null | Readonly<PersonalizeMeetingProps>;
  errorWindow: null | ErrorWindowProps;

  profilePreview: null | {
    theme: string;
    roomDisplayName?: string;
  };

  currentDeleteMeeting: null | Readonly<{ meetingId: string; canDelete: boolean }>;

  invitationsById: {
    [meetingId: string]: Invitation;
  };

  fetchTranscriptsShare: boolean;

  transcriptSharesById: {
    [sessionId: string]: { isPublic: boolean; url: string; error: boolean; linkExpired: boolean; shareKey: string };
  };

  elasticSearchContentList: {
    [key: string]: any;
  };
  elasticSearchPagesInfo: {
    titleList: ElasticSearchPage;
    transcriptList: ElasticSearchPage;
    attendeeList: ElasticSearchPage;
    [key: string]: any;
  };
  isFetchingElasticSearch: boolean;

  elasticSearchSession: null | Session;
  openElasticSearchSession: boolean;
  retainSearch: {
    query: string;
    interval: {
      fromTime: Date | undefined;
      toTime: Date | undefined;
    };
    dateSelected: boolean;
    tab: string;
  };
  attendeeCount: boolean;
  selectedGlobalSearchTab: {
    tab: string | undefined;
    attendeeName: string | undefined;
  };
  smartNotes: Note[];
  chats: ChatListItem[];
  meetingTranscriptions: TranscriptResponse | null;
  fetchSmartNotes: boolean;
  fetchChats: boolean;
  fetchMeetingTranscripts: boolean;
  fetchMeetingAnalytics: boolean;
  meetingAnalytics: {
    pdfURL: string | undefined;
    chatURL: string | undefined;
    recordingTimes: Array<{
      startTime: number;
      stopTime: number;
    }>;
    organizerEngagement: number;
    attendeeEngagement: number;
    attendees: any[];
  } | null;
  deleteInProcess: boolean;
  updatingMeetingData: boolean;
  updateMeetingDataError: boolean;
}>;

export interface Invitation {
  isFetching: boolean;
  hasError: boolean;
  invitationText?: string;
  invitationHtml?: string;
}

export const defaultState: MeetingsState = {
  isFetching: false,
  isFetchingProfile: false,
  meetings: {
    byId: {},
    allIds: []
  },
  profile: undefined,
  selectedId: null,
  selectedItemType: window.matchMedia(device.mobile).matches ? SelectableItemType.NONE : SelectableItemType.PERSONAL,

  history: {
    isFetching: false,
    sessions: {
      byId: {},
      allIds: []
    },
    size: 0,
    total: 0
  },
  currentScheduler: null,
  userKey: null,
  currentDeleteMeeting: null,
  currentMeetingIsInSessionErrorData: null,

  profilePreview: null,
  currentPersonalizeMeeting: null,
  errorWindow: null,

  invitationsById: {},

  transcriptSharesById: {},
  fetchTranscriptsShare: false,
  elasticSearchContentList: {
    titleList: [],
    transcriptList: [],
    attendeeList: []
  },
  elasticSearchPagesInfo: {
    titleList: {
      from: 0,
      size: 0,
      totalHits: 0,
      hasNext: 0,
      resultsFetched: 'none'
    },
    transcriptList: {
      from: 0,
      size: 0,
      totalHits: 0,
      hasNext: 0,
      resultsFetched: 'none'
    },
    attendeeList: {
      from: 0,
      size: 0,
      totalHits: 0,
      hasNext: 0,
      resultsFetched: 'none'
    }
  },
  isFetchingElasticSearch: false,
  elasticSearchSession: null,
  openElasticSearchSession: false,
  retainSearch: {
    query: '',
    interval: {
      fromTime: undefined,
      toTime: undefined
    },
    dateSelected: false,
    tab: ''
  },
  attendeeCount: false,
  selectedGlobalSearchTab: {
    tab: '',
    attendeeName: ''
  },
  smartNotes: [],
  chats: [],
  meetingTranscriptions: null,
  fetchSmartNotes: false,
  fetchChats: false,
  fetchMeetingTranscripts: false,
  fetchMeetingAnalytics: false,
  meetingAnalytics: {
    pdfURL: '',
    chatURL: '',
    recordingTimes: [
      {
        startTime: 0,
        stopTime: 0
      }
    ],
    organizerEngagement: 0,
    attendeeEngagement: 0,
    attendees: []
  },
  deleteInProcess: false,
  updatingMeetingData: false,
  updateMeetingDataError: false
};

/**
 * merges new sessions into the state, overwriting existing sessions if they have matching sessionIds.
 * If appendNewSession is set to false, new sessions will be added to the beginning of allIds (default),
 * if set to true sessions will be added at the end of allIds.
 * If flush is set to true, any existing sessions will be deleted.
 */
const mergeSessions = (state: MeetingsState, fetchedSessions: Session[], appendNewSessions = false, flush = false) => {
  const byId = flush ? {} : state.history.sessions.byId;
  const allIds = flush ? [] : state.history.sessions.allIds;

  fetchedSessions.forEach((session) => {
    byId[session.sessionId] = session;
    if (!allIds.includes(session.sessionId)) {
      if (appendNewSessions || flush) {
        allIds.push(session.sessionId);
      } else {
        allIds.unshift(session.sessionId);
      }
    }
  });

  return {
    byId,
    allIds
  };
};

const removeRecordingSession = (state: MeetingsState, sessionId: string) => {
  const sessionToRemoveRecording = { ...state.history.sessions.byId[sessionId], recording: undefined };

  return {
    ...state,
    deleteInProcess: false,
    history: {
      ...state.history,
      sessions: {
        ...state.history.sessions,
        byId: {
          ...state.history.sessions.byId,
          [sessionId]: sessionToRemoveRecording
        }
      }
    }
  };
};

const markEndedMeeting = (state: MeetingsState, meetingId: string, removeMeeting = false): MeetingsState => {
  const endedMeeting = { ...state.meetings.byId[meetingId], inSession: false, screenSharing: null };
  const byIdUpdate = { ...state.meetings.byId, [meetingId]: endedMeeting };
  if (removeMeeting) {
    delete byIdUpdate[meetingId];
  }
  const { allIds } = state.meetings;
  return {
    ...state,
    selectedId: removeMeeting ? null : state.selectedId,
    meetings: {
      byId: byIdUpdate,
      allIds: removeMeeting ? allIds.filter((id) => id !== meetingId) : allIds
    }
  };
};

const updateUserKey = (state: MeetingsState, userKey: string) => {
  const byIdUpdate = { ...state.meetings.byId };
  state.meetings.allIds.forEach((id) => {
    if (byIdUpdate[id].organizer.key === userKey) {
      byIdUpdate[id].isOrganizer = true;
    }
  });
  return {
    ...state,
    userKey,
    meetings: {
      ...state.meetings,
      byId: byIdUpdate
    }
  };
};

export const updateMeetings = (meetingsResponse: Omit<Meeting, 'inSession'>[], userKey: string | null) => {
  const byId: { [id: string]: Meeting } = {};
  const allIds: string[] = [];
  meetingsResponse.forEach((meeting) => {
    const newMeeting = {
      ...meeting,
      isOrganizer: meeting.organizer.key === userKey,
      inSession: !!meeting.screenSharing
    };
    byId[meeting.meetingId] = newMeeting;
    allIds.push(meeting.meetingId);
  });
  return { byId, allIds };
};

export const createMeeting = (
  state: MeetingsState,
  createdMeeting: Omit<Meeting, 'organizer'>
): MeetingsState['meetings'] => {
  let optionalScheduledMeetingProps = {};
  if (createdMeeting.meetingType === MeetingType.scheduled) {
    optionalScheduledMeetingProps = {
      scheduledStartTime: createdMeeting.scheduledStartTime,
      scheduledEndTime: createdMeeting.scheduledEndTime
    };
  }
  const newMeeting: Meeting = {
    meetingId: createdMeeting.meetingId,
    subject: createdMeeting.subject,
    meetingType: createdMeeting.meetingType,
    passwordRequired: createdMeeting.passwordRequired,
    breakoutsAllowed: createdMeeting.breakoutsAllowed,
    displayTimeZone: createdMeeting.displayTimeZone,
    audio: createdMeeting.audio,
    meetingUrl: createdMeeting.meetingUrl,
    coorganizers: createdMeeting.coorganizers?.map((coorganizer) => ({
      ...coorganizer,
      organizerKey: coorganizer.key
    })),
    organizer: {
      key: state.userKey || '',
      organizerKey: state.userKey || ''
    },
    ...optionalScheduledMeetingProps,
    isOrganizer: true,
    inSession: createdMeeting.inSession || false,
    screenSharing: null
  };
  return {
    byId: { ...state.meetings.byId, [newMeeting.meetingId]: newMeeting },
    allIds: [...state.meetings.allIds, newMeeting.meetingId]
  };
};

const mergeMeetingUrl = (existingMeeting: Meeting, updatedMeeting: Omit<Meeting, 'organizer'>) => {
  if (!updatedMeeting.meetingUrl || !Object.keys(updatedMeeting.meetingUrl).length) {
    return {};
  }
  let meetingUrl = existingMeeting.meetingUrl?.meetingUrl;
  const profileId = updatedMeeting.meetingUrl.profileId || existingMeeting.meetingUrl?.profileId;
  const roomName = updatedMeeting.meetingUrl.roomName || existingMeeting.meetingUrl?.roomName;
  const theme = updatedMeeting.meetingUrl.theme || existingMeeting.meetingUrl?.theme;
  if (updatedMeeting.meetingUrl.profileId || updatedMeeting.meetingUrl.roomName) {
    meetingUrl = `${config.externalLinks.g2mm}/${profileId}${roomName ? `/${roomName}` : ''}`;
  }
  return meetingUrl != undefined && profileId != undefined && roomName != undefined
    ? {
        meetingUrl: {
          meetingUrl,
          profileId,
          roomName,
          theme
        }
      }
    : {};
};

const hideSpinnerOnSelectedTab = (listName: string, selectedTab: string | undefined) => {
  if (listName && selectedTab) {
    let name = '';
    switch (listName) {
      case 'titleList':
        name = 'title';
        break;
      case 'transcriptList':
        name = 'transcripts';
        break;
      case 'attendeeList':
        name = 'attendees';
        break;
    }
    return name === selectedTab;
  }
  return false;
};

export const updateOrAddMeeting = (
  state: MeetingsState,
  meetingUpdate: Omit<Meeting, 'organizer'>
): MeetingsState['meetings'] => {
  const existingMeeting = state.meetings.byId[meetingUpdate.meetingId];
  const coorganizerUpdate: Organizer[] = [];
  meetingUpdate.coorganizers?.forEach((coorganizer: { key: string }) => {
    coorganizerUpdate.push({
      ...coorganizer,
      organizerKey: coorganizer.key
    });
  });
  const updatedMeeting = {
    ...existingMeeting,
    ...meetingUpdate,
    coorganizers: coorganizerUpdate,
    ...mergeMeetingUrl(existingMeeting, meetingUpdate)
  };
  const allIds = state.meetings.allIds;
  return {
    ...state.meetings,
    byId: { ...state.meetings.byId, [updatedMeeting.meetingId]: updatedMeeting },
    allIds: existingMeeting !== undefined ? allIds : [...allIds, updatedMeeting.meetingId]
  };
};

export const deleteMeeting = (state: MeetingsState, meetingId: string) => {
  const byIdUpdate = { ...state.meetings.byId };
  delete byIdUpdate[meetingId];
  return {
    byId: byIdUpdate,
    allIds: state.meetings.allIds.filter((id) => id !== meetingId)
  };
};

const reducer: Reducer<MeetingsState, Actions> = (state: MeetingsState = defaultState, action) => {
  if (action.type === SharedActionTypes.UPDATE_USER.toString()) {
    return updateUserKey(state, (action as any).payload.key);
  }

  switch (action.type) {
    case ActionTypes.PROFILE_FETCHING:
      return { ...state, isFetchingProfile: true };
    case ActionTypes.PROFILE_FETCH_ERROR:
      return { ...state, isFetchingProfile: false };
    case ActionTypes.PROFILE_FETCHED:
      return {
        ...state,
        isFetchingProfile: false,
        profile: action.payload.profile
      };
    case ActionTypes.MEETINGS_UPDATING:
      return { ...state, isFetching: true };

    case ActionTypes.MEETINGS_UPDATE_ERROR:
      return { ...state, isFetching: false };

    case ActionTypes.MEETINGS_UPDATED:
      return {
        ...state,
        isFetching: false,
        meetings: updateMeetings(action.payload.meetings, state.userKey)
      };

    case ActionTypes.MEETING_CREATED:
      return {
        ...state,
        meetings: createMeeting(state, action.payload.meeting)
      };

    case ActionTypes.MEETING_UPDATED:
      return {
        ...state,
        meetings: updateOrAddMeeting(state, action.payload.meeting)
      };

    case ActionTypes.MEETING_INVITATION_CHANGED:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          byId: {
            ...state.meetings.byId,
            [action.payload.meetingId]: {
              ...state.meetings.byId[action.payload.meetingId],
              invitationType: action.payload.invitationType
            }
          }
        }
      };

    case ActionTypes.FETCHING_INVITATION:
      return {
        ...state,
        invitationsById: {
          ...state.invitationsById,
          [action.payload.meetingId]: {
            invitationText: undefined,
            invitationHtml: undefined,
            hasError: false,
            isFetching: true
          }
        }
      };

    case ActionTypes.FETCHED_INVITATION:
      return {
        ...state,
        invitationsById: {
          ...state.invitationsById,
          [action.payload.meetingId]: {
            invitationText: action.payload.invitationText,
            invitationHtml: action.payload.invitationHtml,
            hasError: false,
            isFetching: false
          }
        }
      };

    case ActionTypes.REMOVE_INVITATIONS:
      return {
        ...state,
        invitationsById: {}
      };

    case ActionTypes.FETCHING_INVITATION_ERROR:
      return {
        ...state,
        invitationsById: {
          ...state.invitationsById,
          [action.payload.meetingId]: {
            invitationText: undefined,
            invitationHtml: undefined,
            hasError: true,
            isFetching: false
          }
        }
      };

    case ActionTypes.HIDE_ERROR_WINDOW:
      return { ...state, errorWindow: null };

    case ActionTypes.SHOW_PERSONALIZE_MEETING:
    case ActionTypes.SHOW_CREATE_PERSONAL_MEETING:
      return {
        ...state,
        currentPersonalizeMeeting: {
          isNew: action.type === ActionTypes.SHOW_CREATE_PERSONAL_MEETING,
          errors: {
            profileId: null
          }
        }
      };

    case ActionTypes.PROFILE_ID_SUGGESTING:
      if (!state.currentPersonalizeMeeting) {
        return state;
      }

      return {
        ...state,
        currentPersonalizeMeeting: {
          ...state.currentPersonalizeMeeting,
          profileIdFetching: true
        }
      };

    case ActionTypes.PROFILE_ID_SUGGESTED:
    case ActionTypes.PROFILE_ID_SUGGEST_ERROR:
      if (!state.currentPersonalizeMeeting) {
        return state;
      }

      return {
        ...state,
        currentPersonalizeMeeting: {
          ...state.currentPersonalizeMeeting,
          profileIdFetching: false,
          profileIdSuggestion: action.type === ActionTypes.PROFILE_ID_SUGGESTED ? action.payload : undefined
        }
      };

    case ActionTypes.SHOW_PROFILE_PREVIEW:
      return {
        ...state,
        profilePreview: action.payload
      };

    case ActionTypes.HIDE_PROFILE_PREVIEW:
      return {
        ...state,
        profilePreview: null
      };

    case ActionTypes.HIDE_PERSONALIZE_MEETING:
    case ActionTypes.HIDE_CREATE_PERSONAL_MEETING:
      return { ...state, currentPersonalizeMeeting: null };

    case ActionTypes.PROFILE_UPDATING:
    case ActionTypes.PROFILE_CREATING:
      if (!state.currentPersonalizeMeeting) {
        return state;
      }

      return {
        ...state,
        currentPersonalizeMeeting: {
          ...state.currentPersonalizeMeeting,
          isUpdating: true
        }
      };

    case ActionTypes.PROFILE_UPDATED:
    case ActionTypes.PROFILE_UPDATE_ERROR:
    case ActionTypes.PROFILE_CREATED:
    case ActionTypes.PROFILE_CREATE_ERROR:
      if (!state.currentPersonalizeMeeting) {
        return state;
      }

      return {
        ...state,
        currentPersonalizeMeeting: {
          ...state.currentPersonalizeMeeting,
          isUpdating: false
        },
        errorWindow:
          action.type === ActionTypes.PROFILE_CREATE_ERROR || action.type === ActionTypes.PROFILE_UPDATE_ERROR
            ? {
                errorCode: action.payload.errorCode,
                errorDescription: action.payload.errorDescription
              }
            : null
      };

    case ActionTypes.SHOW_ERROR_WINDOW:
      return {
        ...state,
        errorWindow: {
          errorCode: action.payload.errorCode,
          errorDescription: action.payload.errorDescription
        }
      };

    case ActionTypes.SHOW_DELETE_MEETING:
      return {
        ...state,
        currentDeleteMeeting: { meetingId: action.payload.meetingId, canDelete: action.payload.canDelete }
      };

    case ActionTypes.HIDE_DELETE_MEETING:
      return { ...state, currentDeleteMeeting: null };

    case ActionTypes.SHOW_MEETING_IN_SESSION_ERROR:
      return { ...state, currentMeetingIsInSessionErrorData: action.payload };

    case ActionTypes.HIDE_MEETING_IN_SESSION_ERROR:
      return { ...state, currentMeetingIsInSessionErrorData: null };

    case ActionTypes.MEETING_DELETED:
      return {
        ...state,
        selectedId: null,
        selectedItemType: action.payload.itemType || SelectableItemType.NONE,
        meetings: deleteMeeting(state, action.payload.meetingId)
      };

    case ActionTypes.RECORDING_SESSION_DELETED:
      return removeRecordingSession(state, action.payload.sessionId);

    case ActionTypes.MEETINGS_HISTORY_UPDATING:
      return {
        ...state,
        history: { ...state.history, isFetching: true }
      };

    case ActionTypes.MEETINGS_HISTORY_UPDATED:
      const { sessions, ...paging } = action.payload.history;
      const { append, flush } = action.payload;
      const { byId, allIds } = mergeSessions(state, sessions, append, flush);
      return {
        ...state,
        history: {
          isFetching: false,
          sessions: { byId, allIds },
          ...paging
        }
      };

    case ActionTypes.MEETINGS_HISTORY_ERROR:
      return { ...state, history: { ...state.history, isFetching: false } };

    case ActionTypes.SESSION_UPDATED:
      return {
        ...state,
        history: {
          ...state.history,
          sessions: {
            ...state.history.sessions,
            byId: {
              ...state.history.sessions.byId,
              [action.payload.session.sessionId]: action.payload.session
            }
          }
        }
      };

    case ActionTypes.MEETING_SESSION_DELETED:
      return markEndedMeeting(state, action.payload.meetingId, action.payload.removeMeeting);

    case ActionTypes.MEETING_SELECTED:
      return { ...state, selectedId: action.payload.id, selectedItemType: action.payload.itemType };

    case ActionTypes.START_MEETING:
      return {
        ...state,
        meetings: {
          ...state.meetings,
          byId: {
            ...state.meetings.byId,
            [action.payload.meetingId]: { ...state.meetings.byId[action.payload.meetingId], inSession: true }
          }
        }
      };

    case ActionTypes.FETCHING_TRANSCRIPTS_SHARE:
      return {
        ...state,
        fetchTranscriptsShare: action.payload.flag
      };

    case ActionTypes.TRANSCRIPT_SHARE_UPDATED:
      return {
        ...state,
        transcriptSharesById: {
          [action.payload.sessionId]: {
            isPublic: action.payload.isPublic,
            url: action.payload.url,
            error: action.payload.error,
            linkExpired: action.payload.linkExpired,
            shareKey: action.payload.shareKey
          }
        }
      };
    case ActionTypes.FETCHING_ELASTIC_SEARCH_LIST:
      return {
        ...state,
        isFetchingElasticSearch: true
      };
    case ActionTypes.ERROR_FETCHING_ELASTIC_SEARCH_LIST:
      return {
        ...state,
        isFetchingElasticSearch: false
      };
    case ActionTypes.FETCH_TRANSCRIPT_SEARCH_LIST:
      const search = action.payload.elasticSearchResult;
      const evaluation = hideSpinnerOnSelectedTab(action.payload.tab, state.selectedGlobalSearchTab.tab);
      const updateSpinnerRender = !state.isFetchingElasticSearch ? state.isFetchingElasticSearch : !evaluation;
      return {
        ...state,
        elasticSearchContentList: {
          ...state.elasticSearchContentList,
          [action.payload.tab]: search.payload
        },
        isFetchingElasticSearch: updateSpinnerRender,
        elasticSearchPagesInfo: {
          ...state.elasticSearchPagesInfo,
          [action.payload.tab]: {
            from: search.from,
            size: search.size,
            totalHits: search.totalHits,
            hasNext: search.totalHits - 30,
            resultsFetched: search.resultsFetched
          }
        },
        retainSearch: {
          query: action.payload.retain.query,
          interval: action.payload.retain.interval,
          tab: action.payload.retain.tab,
          dateSelected: action.payload.retain.dateSelected
        }
      };

    case ActionTypes.ATTENDEE_TAB_COUNT:
      return {
        ...state,
        attendeeCount: action.payload.attendeeCount
      };

    case ActionTypes.UPDATE_TRANSCRIPT_SEARCH_LIST:
      return {
        ...state,
        elasticSearchContentList: {
          ...state.elasticSearchContentList,
          [action.payload.tab]: [
            ...state.elasticSearchContentList[action.payload.tab],
            ...action.payload.elasticSearchResult.payload
          ]
        },
        isFetchingElasticSearch: false,
        elasticSearchPagesInfo: {
          ...state.elasticSearchPagesInfo,
          [action.payload.tab]: {
            ...state.elasticSearchPagesInfo[action.payload.tab],
            hasNext: state.elasticSearchPagesInfo[action.payload.tab]['hasNext'] - 30,
            resultsFetched: 'done'
          }
        }
      };

    case ActionTypes.FETCH_ATTENDEE_SESSION_LIST:
      return {
        ...state,
        isFetchingElasticSearch: false,
        elasticSearchContentList: {
          attendeeList: state.elasticSearchContentList.attendeeList.map((attendee: { key: string }) => {
            if (attendee.key === action.payload.attendeeName) {
              return {
                ...attendee,
                sessions: {
                  session: action.payload.elasticSearchResult.payload,
                  elasticSearchPage: {
                    from: action.payload.elasticSearchResult.from,
                    size: action.payload.elasticSearchResult.size,
                    totalHits: action.payload.elasticSearchResult.totalHits,
                    hasNext: action.payload.elasticSearchResult.totalHits - 30,
                    resultsFetched: action.payload.elasticSearchResult.resultsFetched
                  }
                }
              };
            }
            return attendee;
          })
        }
      };

    case ActionTypes.UPDATE_ATTENDEE_SESSION_LIST:
      return {
        ...state,
        isFetchingElasticSearch: false,
        elasticSearchContentList: {
          attendeeList: state.elasticSearchContentList.attendeeList.map(
            (attendee: { key: string; sessions: { session: any; elasticSearchPage: { hasNext: number } } }) => {
              if (attendee.key === action.payload.attendeeName) {
                return {
                  ...attendee,
                  sessions: {
                    session: [...attendee.sessions.session, ...action.payload.elasticSearchResult.payload],
                    elasticSearchPage: {
                      ...attendee.sessions.elasticSearchPage,
                      hasNext: attendee.sessions.elasticSearchPage.hasNext - 30,
                      resultsFetched: 'done'
                    }
                  }
                };
              }
              return attendee;
            }
          )
        }
      };
    case ActionTypes.UPDATE_ELASTIC_SEARCH_SESSION:
      return {
        ...state,
        elasticSearchSession: action.payload
      };

    case ActionTypes.SELECTED_GLOBAL_SEARCH_TAB:
      return {
        ...state,
        selectedGlobalSearchTab: {
          tab: action.payload.tab,
          attendeeName: action.payload.attendeeName
        }
      };

    case ActionTypes.FETCH_ELASTIC_SEARCH_SESSION:
      return {
        ...state,
        openElasticSearchSession: action.payload
      };
    case ActionTypes.FETCH_SMART_NOTES:
      const sortedNotes = action.payload.sort((a, b) => a.startEpochTimeStamp - b.startEpochTimeStamp);
      return {
        ...state,
        smartNotes: sortedNotes,
        fetchSmartNotes: false
      };

    case ActionTypes.FETCH_CHATS:
      return {
        ...state,
        chats: action.payload,
        fetchChats: false
      };

    case ActionTypes.FETCHING_CHATS:
      return {
        ...state,
        fetchChats: action.payload.flag
      };

    case ActionTypes.FETCH_MEETING_TRANSCRIPTIONS:
      return {
        ...state,
        meetingTranscriptions: action.payload,
        fetchMeetingTranscripts: false
      };

    case ActionTypes.FETCHING_SMART_NOTES:
      return {
        ...state,
        fetchSmartNotes: action.payload.flag
      };

    case ActionTypes.FETCHING_MEETING_TRANSCRIPTIONS:
      return {
        ...state,
        fetchMeetingTranscripts: action.payload.flag
      };
    case ActionTypes.FETCH_MEETING_ANALYTICS:
      const evaluatedMeetingAnalytics = createMeetingAssetDetails(action.payload.analytics);
      return {
        ...state,
        meetingAnalytics: evaluatedMeetingAnalytics,
        fetchMeetingAnalytics: false
      };
    case ActionTypes.FETCHING_MEETING_ANALYTICS:
      return {
        ...state,
        fetchMeetingAnalytics: action.payload.analytics
      };
    case ActionTypes.SESSION_DELETE_IN_PROCESS:
      return {
        ...state,
        deleteInProcess: action.payload.deleteStatus
      };
    case ActionTypes.UPDATING_MEETING_SESSION_DATA:
      return {
        ...state,
        updateMeetingDataError: false,
        updatingMeetingData: action.payload.sessionUpdateStatus
      };
    case ActionTypes.UPDATE_MEETING_SESSION_DATA:
      const selectedSession = state.history.sessions.byId[action.payload.sessionId];
      // in case the session is not available in meeting history list then don't update/add the session
      if (!selectedSession) {
        return {
          ...state,
          updatingMeetingData: false,
          updateMeetingDataError: false
        };
      }
      return {
        ...state,
        updatingMeetingData: false,
        updateMeetingDataError: false,
        elasticSearchSession: { ...state.elasticSearchSession, subject: action.payload.updatedSession.title },
        history: {
          ...state.history,
          sessions: {
            ...state.history.sessions,
            byId: {
              ...state.history.sessions.byId,
              [action.payload.sessionId]: {
                ...state.history.sessions.byId[action.payload.sessionId],
                subject: action.payload.updatedSession.title
              }
            }
          }
        }
      };
    case ActionTypes.UPDATING_MEETING_SESSION_DATA_ERROR:
      return {
        ...state,
        updatingMeetingData: false,
        updateMeetingDataError: action.payload.error
      };
    default:
      return state;
  }
};

export default reducer;
