import { connect } from 'react-redux';
import { Selectors, ChatActions as chatActions } from '@getgo/caps-redux';
import appActions from '../view-model/modules/core/app/appActions';
import { Error } from '../view-model/modules/core/errors/errorActions';
import notificationsActions from '../view-model/modules/core/notifications/notificationsActions';
import { Alerts } from '../components/Shell/Alerts/Alerts';
import { getRouteFor } from '../services/NavService';
import { Dispatch, State } from '../view-model/types';
import { isChatEnabled } from '../view-model/selectors';

export const shouldDisplayError = (error: Error, options: { path?: string } = {}) => {
  const { path } = options;
  if (error?.meta) {
    if (
      path &&
      error.meta.disableForComponents &&
      error.meta.disableForComponents.some((component: any) => getRouteFor(component) === path)
    ) {
      return false;
    }
    if (
      error.meta.enableForComponents &&
      !(path && error.meta.enableForComponents.some((component: any) => getRouteFor(component) === path))
    ) {
      return false;
    }
  }
  return true;
};

export const mapStateToProps = (state: State) => {
  const error = shouldDisplayError(state.core.errors, { path: location.hash.substring(1) }) ? state.core.errors : {};

  return {
    hideMeetingErrors: state.core.app.isChatVisible,
    connectionStatus: { ...state.core.app.connectionStatus, ...Selectors.getConnectionStatus(state) },
    error,
    updateReady: state.core.app.updateReady,
    isChatEnabled: isChatEnabled(state),
    doubleBannerAccepted: state.core.notifications.doubleBannerAccepted,
    lastDoubleBannerTimeMs: state.core.notifications.lastDoubleBannerTimeMs,
    notificationPermission: state.core.notifications.notificationPermission
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onReconnectNow() {
      dispatch(chatActions.reconnect());
    },
    applyUpdate() {
      dispatch(appActions.updateApply());
    },
    acceptDoubleBanner() {
      dispatch(notificationsActions.acceptDoubleBanner(Date.now()));
    },
    rejectDoubleBanner() {
      dispatch(notificationsActions.rejectDoubleBanner(Date.now()));
    }
  };
};

const AlertsContainer = connect(mapStateToProps, mapDispatchToProps)(Alerts);

export default AlertsContainer;
