import translationsEnUs from '../../../locales/en-US.json';
import translationsEsEs from '../../../locales/es-ES.json';
import translationsFrFr from '../../../locales/fr-FR.json';
import translationsDeDe from '../../../locales/de-DE.json';
import translationsItIt from '../../../locales/it-IT.json';
import translationsPtBr from '../../../locales/pt-BR.json';
import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesEs from 'i18n-iso-countries/langs/es.json';
import countriesFr from 'i18n-iso-countries/langs/fr.json';
import countriesDe from 'i18n-iso-countries/langs/de.json';
import countriesIt from 'i18n-iso-countries/langs/it.json';
import countriesPt from 'i18n-iso-countries/langs/pt.json';
import countries from 'i18n-iso-countries';
import { getShortLocale } from '../../lib/locales';

countries.registerLocale(countriesEn);
countries.registerLocale(countriesEs);
countries.registerLocale(countriesFr);
countries.registerLocale(countriesDe);
countries.registerLocale(countriesIt);
countries.registerLocale(countriesPt);

const defaultLocale = 'en-US';

interface TranslationFile {
  [id: string]: string;
}

export const getCountryNameForLocale = (countryCode: string, locale: string) => {
  const shortLocale = getShortLocale(locale);
  if (shortLocale === null) {
    throw new Error(`Locale not supported ${locale}`);
  }
  return countries.getName(countryCode, shortLocale);
};

const translations: { [lang: string]: TranslationFile } = {
  'en-us': translationsEnUs,
  'es-es': translationsEsEs,
  'fr-fr': translationsFrFr,
  'de-de': translationsDeDe,
  'it-it': translationsItIt,
  'pt-br': translationsPtBr
};

export const getTranslationsForLocale = (locale = defaultLocale) => {
  return translations[locale.toLowerCase()];
};

export const getUserLocale = (user?: { locale?: string }, preferredLocale?: string) => {
  if (preferredLocale && getTranslationsForLocale(preferredLocale)) {
    return preferredLocale;
  }

  let language = defaultLocale;
  if (user?.locale) {
    language = user.locale.replace(/_/i, '-');
    if (!getTranslationsForLocale(language)) {
      language = defaultLocale;
    }
  }

  return language;
};

// Once the iOS app decides on a UserAgent, we can use an adopted getUserLocale(), instead.
export const getUserLocaleForWebComponent = (preferredLocale = defaultLocale) => {
  return getTranslationsForLocale(preferredLocale) ? preferredLocale : defaultLocale;
};
