import React, { useState, useEffect } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { ConnectionStatus } from '../../../types/pulse-web';
import { Alert } from './Alert/Alert';
import { AlertLevel } from '../../view-model/modules/core/errors/errorActions';

const messages = defineMessages({
  reconnecting: {
    id: 'common.reconnectionMessage.reconnecting',
    defaultMessage: 'Reconnecting...'
  },
  reconnectingInSeconds: {
    id: 'common.reconnectionMessage.reconnectingInSeconds',
    defaultMessage: 'Will reconnect in {seconds} seconds...'
  },
  reconnectingButton: {
    id: 'common.reconnectionMessage.reconnectingButton',
    defaultMessage: 'Reconnect now'
  }
});

interface Props {
  connectionStatus: ConnectionStatus;
  error: {
    text?: string;
  };
  onReconnectNow: () => void;
}

const reconnectionInterval = 1000;

export const ReconnectionMessage: React.FC<Props> = ({ connectionStatus, error, onReconnectNow }) => {
  const [originalTimeRemaining, setOriginalTimeRemaining] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const { formatMessage: f } = useIntl();
  const delay = connectionStatus.reconnectionDelay || 0;

  useEffect(() => {
    if (originalTimeRemaining === delay) return;

    setOriginalTimeRemaining(delay);
    setTimeRemaining(Math.max(delay, 0));
  }, [delay, originalTimeRemaining]);

  useEffect(() => {
    if (timeRemaining <= 0) return;

    const timeout = setTimeout(() => {
      setTimeRemaining(Math.max(timeRemaining - reconnectionInterval, 0));
    }, reconnectionInterval);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeRemaining]);

  if (error?.text) {
    return null;
  }

  if (!connectionStatus.reconnecting && originalTimeRemaining < reconnectionInterval) {
    return null;
  }

  if (connectionStatus.reconnecting) {
    return (
      <Alert level={AlertLevel.WARNING}>
        <FormattedMessage {...messages.reconnecting} />
      </Alert>
    );
  }

  return (
    <Alert level={AlertLevel.WARNING} action={onReconnectNow} actionText={f(messages.reconnectingButton)}>
      {f(messages.reconnectingInSeconds, { seconds: Math.round(timeRemaining / 1000) })}
    </Alert>
  );
};
