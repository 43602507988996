import * as React from 'react';
import styled from '../../../styled-components';
import { AlertSVG, Check, Handraise, Info } from '../Toolkit/Icon/ReactIcons';
import { UnstyledButton } from '../Toolkit/Button/Button';
import device from '../Toolkit/devices';
import { AlertLevel } from '../../../view-model/modules/core/errors/errorActions';
import { themeGet } from '@styled-system/theme-get';
import { CloseButton } from '../Toolkit/Button/CloseButton';

const AlertWrapper = styled.div<{ level: AlertLevel }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  color: #ffffff;
  font-size: 18px;
  background: ${(props) => themeGet(`colors.${props.level}.primary`, themeGet('colors.info.primary'))(props)};

  @media ${device.mobile} {
    font-size: 14px;
    height: auto;
  }
`;

export const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  height: 14px;
`;

const IconWrapper = styled.div`
  margin-right: 12px;
`;

export const Action = styled(UnstyledButton)`
  margin-left: 5px;
  margin-right: 12px;
  text-decoration: underline;
`;

const AlertIcon = ({
  level = AlertLevel.INFO,
  size = 20,
  unit = 'px',
  color = '#ffffff'
}: {
  level?: AlertLevel;
  size?: number;
  unit?: 'em' | 'px';
  color?: string;
}) => {
  let icon = null;
  switch (level) {
    case AlertLevel.INFO:
      icon = <Info data-testid="alert-icon-info" size={size} unit={unit} color={color} />;
      break;
    case AlertLevel.SUCCESS:
      icon = <Check data-testid="alert-icon-success" size={size} unit={unit} color={color} />;
      break;
    case AlertLevel.DANGER:
      icon = <Handraise data-testid="alert-icon-danger" size={size} unit={unit} color={color} />;
      break;
    case AlertLevel.WARNING:
      icon = <AlertSVG data-testid="alert-icon-warning" size={size} unit={unit} color={color} />;
      break;
  }
  return <IconWrapper>{icon}</IconWrapper>;
};

interface Props {
  level?: AlertLevel;
  text?: string;
  actionText?: string;
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dismiss?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode | null;
}

export const Alert: React.FC<Props> = ({ level = AlertLevel.INFO, text, actionText, action, dismiss, children }) => {
  if (!text && !children) {
    return null;
  }

  return (
    <AlertWrapper level={level} data-testid="alert" role="status">
      <AlertIcon level={level} />
      <span>{children || text}</span>
      {action && (
        <Action data-testid="alert-action-button" onClick={action}>
          {actionText}
        </Action>
      )}
      {dismiss && <PositionedCloseButton onClick={dismiss} color="#ffffff" dataTestId="alert-close-button" size={18} />}
    </AlertWrapper>
  );
};
