import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import classNames from 'classnames';

export interface Contact {
  isGroup?: boolean;
  members?: any;
  name?: string;
  typingList?: any;
}

export interface Member {
  jid: string;
  name: string;
}

const messages = defineMessages({
  isTyping: {
    id: 'chat.isTypingIndicator.isTyping',
    defaultMessage: '{peopleTyping} {numberOfPeopleTyping, plural, one {is} other {are}} typing'
  },
  peopleAreTyping: {
    id: 'chat.isTypingIndicator.peopleAreTyping',
    defaultMessage: 'Multiple people are typing'
  }
});

const getNameForTypingIndicator = (fullName: string) => {
  if (fullName.trim().indexOf(' ') > -1) {
    return fullName.trim().substring(0, fullName.trim().indexOf(' '));
  }
  return fullName.trim();
};

export interface Props {
  contact: Contact;
  typingList?: any;
}

export const IsTypingIndicator: React.FC<Props> = (props) => {
  let typingList: any;

  if (props.contact.isGroup) {
    const isTypingList = props.contact.members.filter((groupMember: Member) => {
      if (props.contact.typingList.indexOf(groupMember.jid) > -1) {
        return groupMember;
      }
      return null;
    });
    const isTypingListNames = isTypingList.map((typingMember: Member) => getNameForTypingIndicator(typingMember.name));
    typingList = isTypingListNames.map((typingMember: string) => {
      return typingMember.length > 14 ? `${typingMember.substr(0, 15)}...` : typingMember;
    });
  } else {
    if (props.contact.name) {
      typingList = [getNameForTypingIndicator(props.contact.name)];
    }
  }

  const listOfPeopleTyping = typingList.join(', ');
  const numberOfPeopleTyping = typingList.length;
  const typingMessageProps = {
    peopleTyping: listOfPeopleTyping,
    numberOfPeopleTyping
  };

  const message =
    props.contact.isGroup && typingList.length > 3 ? (
      <FormattedMessage {...messages.peopleAreTyping} />
    ) : (
      <FormattedMessage values={typingMessageProps} {...messages.isTyping} />
    );

  return (
    <div className="is-typing-indicator-wrapper">
      <span
        className={classNames({
          'is-typing-indicator': typingList.length > 0,
          'is-typing-indicator-1on1': !props.contact.isGroup,
          'is-typing-indicator-group-one-user-typing': props.contact.isGroup && typingList.length === 1,
          'is-typing-indicator-group-2or3-users-typing': props.contact.isGroup && typingList.length > 1,
          'is-typing-indicator-group-multiple-users-typing': props.contact.isGroup && typingList.length > 3
        })}
      >
        {message}
      </span>
      <div className="is-typing-indicator-wave">
        <span className="dot" />
        <span className="dot" />
        <span className="dot" />
      </div>
    </div>
  );
};
