import { v4 } from 'uuid';

export const generateUniqueId = () => {
  return v4();
};

export const generateSessionId = () => {
  const generate = () => {
    return `${Math.floor(Math.random() * 9999)}`;
  };

  if (!window.sessionStorage) {
    return generate();
  }

  let sessionId;
  const key = 'jid-resId';
  sessionId = window.sessionStorage.getItem(key);

  if (!sessionId) {
    sessionId = generate();
    window.sessionStorage.setItem(key, sessionId);
  }

  return sessionId;
};
