import * as styledComponents from 'styled-components';
import { Theme } from './theme';

// enable css prop
declare module 'react' {
  interface Attributes {
    css?: styledComponents.CSSProp<Theme>;
  }
}

const {
  default: styled,
  css,
  keyframes,
  withTheme,
  ThemeProvider
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export { css, keyframes, ThemeProvider, withTheme };
export default styled;
