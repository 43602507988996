import { ActionsUnion, Thunk, createAction } from '../../../type-helpers';
import { MessageActions } from '@getgo/caps-redux';
import hash from '../../../../../lib/hash';
import loggerService from '../../../../services/LoggerService';
import { FileUploadOptions, send } from '../../../../services/UploadService';
import { ProgressEvent, Request } from 'superagent';
import { FileUploadEntry } from './uploadsReducer';

const { sendFilesMessage } = MessageActions;

const logger = loggerService.create('UploadActions');

export const getId = ({ name, size }: { name: string; size: number }) => {
  return hash(`${Date.now()}-${name}-${size}`);
};

export enum ActionTypes {
  UPLOAD_CANCEL = 'UPLOAD_CANCEL',
  UPLOAD_CLEAR = 'UPLOAD_CLEAR',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
  UPLOAD_SUCCESS = 'UPLOAD_SUCCESS',
  UPLOAD_PENDING = 'UPLOAD_PENDING'
}

const actions = {
  uploadCancel: (upload: FileUploadEntry) => {
    const outerRequest = upload.request.abort();

    return createAction(ActionTypes.UPLOAD_CANCEL, {
      ...upload,
      request: outerRequest
    });
  },
  uploadClear: (upload: FileUploadEntry) => createAction(ActionTypes.UPLOAD_CLEAR, upload),
  uploadPending: (id: number, file: File, request: Request) =>
    createAction(ActionTypes.UPLOAD_PENDING, { id, file, request }),
  uploadProgress: (id: number, percent: number) => createAction(ActionTypes.UPLOAD_PROGRESS, { id, percent }),
  uploadError: (id: number, error: any) => {
    if (error.response) {
      logger.error('uploadFile.error', 'error=', error, 'fileId=', id);
    }

    return createAction(ActionTypes.UPLOAD_ERROR, { id, error });
  },
  uploadSuccess: (id: number) => createAction(ActionTypes.UPLOAD_SUCCESS, { id })
};

export default actions;
export type Actions = ActionsUnion<typeof actions>;

export const uploadFile = (options: FileUploadOptions, file: File): Thunk => async (dispatch) => {
  const id = getId(file);
  const progressCallback = (event: ProgressEvent) => dispatch(actions.uploadProgress(id, event.percent || 0));

  const { request, validatedBody } = await send(options, file, progressCallback);

  /* Dispatch to add the request in state */
  dispatch(actions.uploadPending(id, file, request));

  /* Waits for the send to complete and removes request from the state */
  try {
    await request;
    dispatch(actions.uploadSuccess(id));
    dispatch(
      sendFilesMessage(options.to, [
        {
          name: file.name,
          href: validatedBody.downloadUrl,
          size: file.size,
          contentType: file.type
        }
      ])
    );
  } catch (error) {
    dispatch(actions.uploadError(id, error));
  }
};
