import React, { useRef } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Flip } from '@getgo/featureflip-react';
import { Popover, PopoverMethods } from '../../../Common/Popover/Popover';
import { Item, ItemWrapper } from './Item';
import config from 'config';
import { ThreeDots } from '../../../Common/Toolkit/Icon/ReactIcons';
import styled from '../../../../styled-components';
import device from '../../../Common/Toolkit/devices';
import { State as UserState } from '../../../../view-model/modules/core/user/userReducer';
import { themeGet } from '@styled-system/theme-get';
import { NavLink } from 'react-router-dom';
import { getRouteFor } from '../../../../services/NavService';

export const messages = defineMessages({
  helpAndSupportText: {
    id: 'appMenu.additionalItems.supportMenu.helpAndSupportText',
    defaultMessage: 'Help and Support'
  },
  freeProductTrainingText: {
    id: 'appMenu.additionalItems.supportMenu.freeProductTrainingText',
    defaultMessage: 'Free Product Training'
  },
  adminCenterText: {
    id: 'appMenu.additionalItems.supportMenu.adminCenterText',
    defaultMessage: 'Admin Center'
  },
  adminInfoText: {
    id: 'appMenu.additionalItems.supportMenu.adminInfoText',
    defaultMessage: 'Admin Info'
  },
  upgradeLinkText: {
    id: 'appMenu.additionalItems.upgradeLinkText',
    defaultMessage: 'Upgrade'
  },
  feedbackLinkText: {
    id: 'appMenu.additionalItems.feedbackLinkText',
    defaultMessage: 'Feedback'
  },
  myAccountText: {
    id: 'appMenu.additionalItems.supportMenu.myAccountText',
    defaultMessage: 'My Account'
  },
  exploreGoToText: {
    id: 'appMenu.additionalItems.exploreGoToText',
    defaultMessage: 'Explore GoTo'
  },
  additonalItemsLabel: {
    id: 'appMenu.additionalItems.label',
    defaultMessage: 'More'
  }
});

const MenuItem = styled.button`
  text-decoration: none;
  text-transform: uppercase;
  box-sizing: content-box;
  font-size: 10px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  color: #eaeaea;
  width: 80px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 20px 0;
  margin: 0;

  &:hover,
  &:focus {
    color: #f4f4f4;
    background-color: rgb(38, 42, 52);
  }
  @media ${device.mobile} {
    width: 56px;
    padding: 17px 0;
  }
`;

const MenuItemText = styled.span`
  margin-top: 4px;

  @media ${device.mobile} {
    display: none;
  }
`;

const AdditionalItemsList = styled.ul`
  padding: 0 8px 0 8px;
  margin: 0;
  white-space: nowrap;
  list-style-type: none;

  hr {
    width: 100%;
    border: none;
    border-top: solid 1px ${themeGet('colors.neutralScale.100')};
  }
`;

interface Props {
  isFreeUser: boolean;
  user: UserState;
  openLink: (linkTargetType: string) => void;
  upgradeAccount: () => void;
}

export const AdditionalItems: React.FC<Props> = (props) => {
  const popoverRef = useRef<PopoverMethods>(null);

  const handleClick = (linkTargetType: string) => {
    props.openLink(linkTargetType);
  };
  const handleMyAccountClick = () => handleClick('account');
  const handleAdminCenterClick = () => handleClick('adminCenter');
  const handleSupportClick = () => handleClick('support');
  const handleFreeProductTraining = () => handleClick('freeProductTraining');
  const handleGoToAssistClick = () => handleClick('goToAssist');
  const handleGoToTrainingClick = () => handleClick('goToTraining');
  const handleGoToWebinarClick = () => handleClick('goToWebinar');
  const handleOpenVoiceClick = () => handleClick('openVoice');
  const handleFeedbackClick = () => handleClick('feedback');
  const handleExploreGoToClick = () => handleClick('exploreGoTo');

  const closePopover = () => {
    if (popoverRef.current) {
      popoverRef.current.hide();
    }
  };

  const { isFreeUser, upgradeAccount } = props;
  const { formatMessage: f } = useIntl();

  const disclosure = (
    <MenuItem data-testid="additional-items">
      <ThreeDots size={26} unit="px" color={'#fff'} rotateDegrees={90} />
      <MenuItemText>
        <FormattedMessage {...messages.additonalItemsLabel} />
      </MenuItemText>
    </MenuItem>
  );

  return (
    <Popover
      id="additional-items-list"
      placement="right-start"
      disclosure={disclosure}
      ref={popoverRef}
      ariaLabel={f(messages.additonalItemsLabel)}
      hideArrow
    >
      <AdditionalItemsList data-testid="additional-items-list">
        <Item
          clickHandler={handleMyAccountClick}
          shouldLocalize
          href={config.externalLinks.secureServiceMyAccount}
          linkLocalizedText={messages.myAccountText}
        />
        <Flip name="hasAccountAdminEntitlement">
          <Item
            clickHandler={handleAdminCenterClick}
            shouldLocalize
            href={config.externalLinks.adminCenter}
            linkLocalizedText={messages.adminCenterText}
          />
        </Flip>
        <Flip not name="hasAccountAdminEntitlement">
          <ItemWrapper>
            <NavLink to={`${getRouteFor('about')}?highlightAdmin=true`} onClick={closePopover}>
              <FormattedMessage {...messages.adminInfoText} />
            </NavLink>
          </ItemWrapper>
        </Flip>
        {isFreeUser && (
          <Item
            clickHandler={upgradeAccount}
            shouldLocalize
            href={config.externalLinks.plansPricing}
            linkLocalizedText={messages.upgradeLinkText}
          />
        )}
        <hr />
        <Flip name="hasG2ARSEntitlement">
          <Item clickHandler={handleGoToAssistClick} href={config.externalLinks.g2a.urlFrontEnd} rawText="GoToAssist" />
        </Flip>
        <Flip name="hasG2TEntitlement">
          <Item
            clickHandler={handleGoToTrainingClick}
            href={config.externalLinks.g2t.urlFrontEnd}
            rawText="GoToTraining"
          />
        </Flip>
        <Flip name="hasG2WEntitlement">
          <Item
            clickHandler={handleGoToWebinarClick}
            href={config.externalLinks.g2w.urlFrontEnd}
            rawText="GoToWebinar"
          />
        </Flip>
        <Flip name="hasOpenVoiceEntitlement">
          <Item clickHandler={handleOpenVoiceClick} href={config.externalLinks.ov.urlFrontEnd} rawText="OpenVoice" />
        </Flip>
        <Flip name="hasAtLeastOneSupportMenuEntitlement">
          <hr />
        </Flip>
        <Item
          clickHandler={handleExploreGoToClick}
          shouldLocalize
          href={config.externalLinks.goto}
          linkLocalizedText={messages.exploreGoToText}
        />
        <Item
          clickHandler={handleFreeProductTraining}
          shouldLocalize
          href={config.externalLinks.freeProductTraining}
          linkLocalizedText={messages.freeProductTrainingText}
        />
        <Item
          clickHandler={handleSupportClick}
          shouldLocalize
          href={config.externalLinks.support}
          linkLocalizedText={messages.helpAndSupportText}
        />
        {props?.user?.hasLoaded && (
          <Item
            clickHandler={handleFeedbackClick}
            shouldLocalize
            href={`${config.externalLinks.hubFeedback}?email=${props.user.email}`}
            linkLocalizedText={messages.feedbackLinkText}
          />
        )}
      </AdditionalItemsList>
    </Popover>
  );
};
