import { defineMessages } from 'react-intl';

const messages = defineMessages({
  serverError: {
    id: 'meetingDashboard.personalize.serverError',
    defaultMessage: 'The service is currently not available - please try it again later'
  },
  alreadyExists: {
    id: 'meetingDashboard.personalize.alreadyExists',
    defaultMessage: 'This link is already taken by someone else'
  },
  invalidChar: {
    id: 'meetingDashboard.personalize.invalidChar',
    defaultMessage: 'Enter letters, numbers -, or _.'
  },
  invalidId: {
    id: 'meetingDashboard.personalize.invalidId',
    defaultMessage: "9-digit names currently aren't available"
  },
  successLabel: {
    id: 'meetingDashboard.personalize.successLabel',
    defaultMessage: 'This name is all yours!'
  },
  missingProfile: {
    id: 'meetingDashboard.personalize.missingProfile',
    defaultMessage: 'Profile missing'
  },
  notOnlyDigits: {
    id: 'meetingDashboard.personalize.notOnlyDigits',
    defaultMessage: 'Your link must have at least 1 letter'
  }
});

export default messages;
