import React, { Component } from 'react';

interface Props {
  onClick: (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>, type: string) => void;
  active?: boolean;
  type: string;
  hex: string;
}

export class Modifier extends Component<Props> {
  private handleClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    this.props.onClick(ev, this.props.type);
  };

  render() {
    return (
      <a
        data-testid="modifier-link"
        onClick={this.handleClick}
        className={this.props.active ? 'modifier active' : 'modifier'}
        style={{ background: this.props.hex }}
        aria-label={`Fitzpatrick type ${this.props.type}`}
      />
    );
  }
}
