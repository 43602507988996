import { PreferencesItemBool } from '../../Common/Preferences/PreferencesItemBool';
import PreferencesItemCheckedSearchList from '../../Common/Preferences/PreferencesItemCheckedSearchList';
import { PreferencesItemCheckedTextArea } from '../../Common/Preferences/PreferencesItemCheckedTextArea';
import React from 'react';
import { defineMessages, MessageDescriptor, useIntl } from 'react-intl';
import { getDisabledToolTipMessage } from '../../../../lib/disabled-message';
import { translateCountryNames } from '../../../../lib/locales';
import { AudioSettingItems, AudioValidationErrors, getAudioValidationErrors } from '../../../../lib/audio';
import styled from '../../../styled-components';
import { Radio } from '../../Common/Toolkit/Input/Selectable';

const MainRadioItem = styled(PreferencesItemBool)`
  & ${Radio} {
    input + label > span:first-child {
      font-weight: bold;
    }
  }
`;

const OwnConferenceCheckedTextArea = styled(PreferencesItemCheckedTextArea)`
  ${Radio} {
    label > span:first-child {
      font-weight: 500;
      font-family: 'lato-bold';
    }
  }
`;

const messages = defineMessages({
  audioSettingGroup: {
    id: 'meetingPreferences.audioSettingsGroup',
    defaultMessage: 'Use built-in audio (select one or more)'
  },
  voip: {
    id: 'meetingPreferences.voipLabel',
    defaultMessage: 'Computer mic & speakers (VoIP)'
  },
  longDistance: {
    id: 'meetingPreferences.longDistanceLabel',
    defaultMessage: 'Long-distance numbers'
  },
  tollFree: {
    id: 'meetingPreferences.tollFreeLabel',
    defaultMessage: 'Toll-free numbers'
  },
  callMe: {
    id: 'meetingPreferences.callMeLabel',
    defaultMessage: 'Call Me'
  },
  ownConference: {
    id: 'meetingPreferences.ownConferenceLabel',
    defaultMessage: 'Use my own conference call service'
  },
  audioNoBuiltInOptionSelectedError: {
    id: 'preferenceActions.errors.audioNoBuiltInOptionSelectedError',
    defaultMessage: 'Select at least one built-in audio option'
  },
  audioEmptyLongDistanceCountryListError: {
    id: 'preferenceActions.errors.audioEmptyLongDistanceCountryListError',
    defaultMessage: 'Select at least one country'
  },
  audioEmptyTollFreeCountryListError: {
    id: 'preferenceActions.errors.audioEmptyTollFreeCountryListError',
    defaultMessage: 'Select at least one country'
  },
  audioEmptyCallMeCountryListError: {
    id: 'preferenceActions.errors.audioEmptyCallMeCountryListError',
    defaultMessage: 'Select at least one country'
  },
  audioEmptyCustomMessageError: {
    id: 'preferenceActions.errors.audioEmptyCustomMessageError',
    defaultMessage: 'Provide a non-empty message'
  },
  preferredCountryLabel: {
    id: 'meetingPreferences.preferredCountryLabel',
    defaultMessage: 'Your preferred country is {label}. Attendees will see this first in invites and dial-in options.'
  },
  preferredOptionTip: {
    id: 'meetingPreferences.preferredOptionTip',
    defaultMessage: 'Attendees will see this country first in invites and dial-in options'
  },
  unselectedPreferredOptionTip: {
    id: 'meetingPreferences.unselectedPreferredOptionTip',
    defaultMessage: 'Click this country to list it first in invites and dial-in options'
  },
  breakoutRoomWarning: {
    id: 'meetingSettings.audio.breakoutRoomWarning',
    defaultMessage: 'Using phone numbers will turn off breakout rooms for this meeting.'
  }
});

const errorMessagesMap = ((): Map<AudioValidationErrors, MessageDescriptor> => {
  const map: Map<AudioValidationErrors, MessageDescriptor> = new Map();
  map.set(AudioValidationErrors.AUDIO_NO_BUILT_IN_OPTION_SELECTED, messages.audioNoBuiltInOptionSelectedError);
  map.set(
    AudioValidationErrors.AUDIO_EMPTY_LONG_DISTANCE_COUNTRY_LIST,
    messages.audioEmptyLongDistanceCountryListError
  );
  map.set(AudioValidationErrors.AUDIO_EMPTY_TOLL_FREE_COUNTRY_LIST, messages.audioEmptyTollFreeCountryListError);
  map.set(AudioValidationErrors.AUDIO_EMPTY_CALLME_COUNTRY_LIST, messages.audioEmptyCallMeCountryListError);
  map.set(AudioValidationErrors.AUDIO_EMPTY_CUSTOM_MESSAGE, messages.audioEmptyCustomMessageError);
  return map;
})();

const getErrorMessageIfApplicable = (validationErrorToCheck: AudioValidationErrors): MessageDescriptor | undefined => {
  return currentValidationErrors.indexOf(validationErrorToCheck) !== -1
    ? errorMessagesMap.get(validationErrorToCheck)
    : undefined;
};

let currentValidationErrors: AudioValidationErrors[] = [];

export interface Props {
  isDisabled: boolean;
  audioSettings: AudioSettingItems | undefined;
  onChange: (property: keyof AudioSettingItems, value: any) => void;
  preferredCountryEnabled?: boolean;
  displayBreakoutRoomWarning?: boolean;
  onDisableBreakoutRoom?: (ownConferenceSelected: boolean) => void;
}

export const AudioSettings: React.FC<Props> = (props) => {
  const intl = useIntl();
  if (props.audioSettings === undefined) {
    return <div className="audio-settings" />;
  }

  currentValidationErrors = getAudioValidationErrors(props.audioSettings);

  const changeSettingWithConsideringBreakoutRoomsHandler = (property: keyof AudioSettingItems) => (
    isEnabled: boolean
  ) => {
    props.onChange(property, isEnabled);
    if (props.displayBreakoutRoomWarning && isEnabled) {
      props.onDisableBreakoutRoom?.(property == 'ownConferenceEnabled');
    }
  };

  return (
    <>
      <MainRadioItem
        id="audio-container"
        type="radio"
        checked={!props.audioSettings.ownConferenceEnabled}
        disabled={props.isDisabled}
        onChange={(isEnabled) => props.onChange('ownConferenceEnabled', !isEnabled)}
        labelMessage={messages.audioSettingGroup}
        errorMessage={getErrorMessageIfApplicable(AudioValidationErrors.AUDIO_NO_BUILT_IN_OPTION_SELECTED)}
      >
        <PreferencesItemBool
          id="voip"
          type="checkbox"
          checked={props.audioSettings.voipEnabled}
          onChange={(isEnabled) => props.onChange('voipEnabled', isEnabled)}
          disabled={props.isDisabled || !props.audioSettings.voipAllowed || props.audioSettings.ownConferenceEnabled}
          labelMessage={messages.voip}
          disabledTooltip={getDisabledToolTipMessage(
            props.audioSettings.ownConferenceEnabled,
            !props.audioSettings.voipAllowed
          )}
        />
        <PreferencesItemCheckedSearchList
          id="long-distance"
          type="checkbox"
          checked={props.audioSettings.longDistanceEnabled}
          onCheckboxChange={changeSettingWithConsideringBreakoutRoomsHandler('longDistanceEnabled')}
          onInputFieldChange={(updatedValues) => props.onChange('longDistanceCountries', updatedValues)}
          disabled={
            props.isDisabled || !props.audioSettings.longDistanceAllowed || props.audioSettings.ownConferenceEnabled
          }
          labelMessage={messages.longDistance}
          disabledTooltip={getDisabledToolTipMessage(
            props.audioSettings.ownConferenceEnabled,
            !props.audioSettings.longDistanceAllowed
          )}
          isLoading={false}
          selectedOptions={translateCountryNames(props.audioSettings.longDistanceCountries, intl.locale)}
          allowedSearchValues={translateCountryNames(props.audioSettings.allowedLongDistanceCountries, intl.locale)}
          errorMessage={getErrorMessageIfApplicable(AudioValidationErrors.AUDIO_EMPTY_LONG_DISTANCE_COUNTRY_LIST)}
          enablePreferred={props.preferredCountryEnabled}
          preferredMessage={messages.preferredCountryLabel}
          preferredOptionTooltip={messages.preferredOptionTip}
          unselectedPreferredOptionTooltip={messages.unselectedPreferredOptionTip}
        />
        <PreferencesItemCheckedSearchList
          id="toll-free"
          type="checkbox"
          checked={props.audioSettings.tollFreeEnabled}
          onCheckboxChange={changeSettingWithConsideringBreakoutRoomsHandler('tollFreeEnabled')}
          onInputFieldChange={(updatedValues) => props.onChange('tollFreeCountries', updatedValues)}
          disabled={
            props.isDisabled || !props.audioSettings.tollFreeAllowed || props.audioSettings.ownConferenceEnabled
          }
          labelMessage={messages.tollFree}
          disabledTooltip={getDisabledToolTipMessage(
            props.audioSettings.ownConferenceEnabled,
            !props.audioSettings.tollFreeAllowed
          )}
          isLoading={false}
          selectedOptions={translateCountryNames(props.audioSettings.tollFreeCountries, intl.locale)}
          allowedSearchValues={translateCountryNames(props.audioSettings.allowedTollFreeCountries, intl.locale)}
          errorMessage={getErrorMessageIfApplicable(AudioValidationErrors.AUDIO_EMPTY_TOLL_FREE_COUNTRY_LIST)}
          enablePreferred={props.preferredCountryEnabled}
          preferredMessage={messages.preferredCountryLabel}
          preferredOptionTooltip={messages.preferredOptionTip}
          unselectedPreferredOptionTooltip={messages.unselectedPreferredOptionTip}
        />
        <PreferencesItemCheckedSearchList
          id="call-me"
          type="checkbox"
          checked={props.audioSettings.callMeEnabled}
          onInputFieldChange={(updatedValues) => props.onChange('callMeCountries', updatedValues)}
          onCheckboxChange={changeSettingWithConsideringBreakoutRoomsHandler('callMeEnabled')}
          disabled={props.isDisabled || !props.audioSettings.callMeAllowed || props.audioSettings.ownConferenceEnabled}
          labelMessage={messages.callMe}
          disabledTooltip={getDisabledToolTipMessage(
            props.audioSettings.ownConferenceEnabled,
            !props.audioSettings.callMeAllowed
          )}
          isLoading={false}
          selectedOptions={translateCountryNames(props.audioSettings.callMeCountries, intl.locale)}
          allowedSearchValues={translateCountryNames(props.audioSettings.allowedCallMeCountries, intl.locale)}
          errorMessage={getErrorMessageIfApplicable(AudioValidationErrors.AUDIO_EMPTY_CALLME_COUNTRY_LIST)}
        />
      </MainRadioItem>
      <OwnConferenceCheckedTextArea
        id="own-conference"
        type="radio"
        checked={props.audioSettings.ownConferenceEnabled}
        text={props.audioSettings.ownConferenceMessage}
        onCheckboxChange={changeSettingWithConsideringBreakoutRoomsHandler('ownConferenceEnabled')}
        onTextChange={(message) => props.onChange('ownConferenceMessage', message)}
        disabled={props.isDisabled || !props.audioSettings.ownConferenceAllowed}
        labelMessage={messages.ownConference}
        disabledTooltip={getDisabledToolTipMessage(false, !props.audioSettings.ownConferenceAllowed)}
        errorMessage={getErrorMessageIfApplicable(AudioValidationErrors.AUDIO_EMPTY_CUSTOM_MESSAGE)}
      />
    </>
  );
};
