import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import classNames from 'classnames';
import meetingInviteIcon from './main.daisy-orange.svg';
import './MeetNow.css';

const messages = defineMessages({
  initiateTo: {
    id: 'chat.messageHistory.controlMessages.meetNowControlMessage.initiateTo',
    defaultMessage: 'Meeting request sent to {to}.'
  },

  initiateFrom: {
    id: 'chat.messageHistory.controlMessages.meetNowControlMessage.initiateFrom',
    defaultMessage: '{from} wants to meet.'
  },

  meetingEnded: {
    id: 'chat.messageHistory.controlMessages.meetNowControlMessage.meetingEnded',
    defaultMessage: 'Meeting ended.'
  }
});

interface Props {
  contactName: string;
  isMine: boolean;
  joinLink?: string;
  onJoinMeeting: (meetingUrl: string) => void;
  senderName: string;
  status: 'started' | 'ended';
}

export class MeetNow extends React.PureComponent<Props> {
  joinMeeting = () => {
    if (this.props.joinLink) {
      this.props.onJoinMeeting(this.props.joinLink);
    }
  };

  render() {
    const { isMine, senderName, contactName, status } = this.props;

    return (
      <div className={classNames('meetnow-invite', status === 'ended' && 'meetnow-ended')}>
        <img alt="" role="presentation" src={meetingInviteIcon} />
        <div className="meetnow-content">
          <span className="text">
            {status === 'ended' ? (
              <FormattedMessage {...messages.meetingEnded} />
            ) : isMine ? (
              <FormattedMessage {...messages.initiateTo} values={{ to: <strong>{contactName}</strong> }} />
            ) : (
              <FormattedMessage {...messages.initiateFrom} values={{ from: <strong>{senderName}</strong> }} />
            )}
          </span>
          {!isMine && status === 'started' && (
            <button data-testid="meet-now-join-button" className="btn btn-g2m-custom" onClick={this.joinMeeting}>
              <FormattedMessage id="chat.messageHistory.controlMessages.joinMeeting" defaultMessage="Join meeting" />
            </button>
          )}
        </div>
      </div>
    );
  }
}
