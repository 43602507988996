import LinkifyIt from 'linkify-it';

const linkify = new LinkifyIt();

function isAlreadyMarkdownLink(org: string, start: number, end: number) {
  return (
    (org.substr(start - 2, 2) === '](' && org[end] === ')') || (org[start - 1] === '[' && org.substr(end, 2) === '](')
  );
}

function isInCode(org: string, start: number) {
  return (org.substring(0, start).split('`').length - 1) % 2 === 1;
}

export default function marklinks(str: string) {
  const matches = linkify.match(str);

  if (!matches) {
    return str;
  }

  let out = '';
  let lastIndex = 0;

  matches.forEach((match) => {
    if (match.index > lastIndex) {
      out += str.substring(lastIndex, match.index);
    }
    if ([isAlreadyMarkdownLink, isInCode].some((fn) => fn(str, match.index, match.lastIndex))) {
      out += match.raw;
    } else {
      out += `[${match.text}](${match.url})`;
    }
    lastIndex = match.lastIndex;
  });

  if (lastIndex < str.length) {
    out += str.substring(lastIndex);
  }

  return out;
}
