import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import ImagePreviewContainer from '../../containers/ImagePreviewContainer';
import { FileDropZone, Props as FileDropZoneProps } from '../Common/Upload/FileDropZone/FileDropZone';
import { EmptyMessage, Props as EmptyMessageProps } from './EmptyMessage/EmptyMessage';
import MessageHistory, { Props as MessageHistoryProps } from './MessageHistory/MessageHistory';
import { Props as UploadProgressProps, UploadProgress } from './MessageHistory/UploadProgress/UploadProgress';
import MessageInput, { Props as MessageInputProps } from './MessageInput/MessageInput';
import { CenteredSpinner } from '../Common/Toolkit/Spinner/Spinner';

interface OwnProps {
  imagePreviewActive?: boolean;
  isLoading?: boolean;
  className?: string;
}

export type Props = EmptyMessageProps &
  Pick<FileDropZoneProps, 'contactName' | 'uploadFiles'> &
  MessageHistoryProps &
  MessageInputProps &
  UploadProgressProps &
  OwnProps;

export const Chat: React.FC<Props> = (props) => {
  const propsWithDefault = Object.assign(
    {
      isLoading: false,
      messages: [],
      uploads: [],
      uploadFiles: () => {}
    },
    props
  );

  const showLoading = propsWithDefault.isLoading && !propsWithDefault.messages.length;
  const showEmpty =
    !propsWithDefault.isLoading && !propsWithDefault.messages.length && !propsWithDefault.uploads.length;
  const isUploadEnabled = propsWithDefault.app.features.includes('file_sharing');

  return (
    <FileDropZone
      className={classNames('chat-container', propsWithDefault.className)}
      uploadFiles={propsWithDefault.uploadFiles}
      contactName={get(propsWithDefault, 'contact.name', '')}
      isUploadEnabled={isUploadEnabled}
    >
      <>
        {showLoading ? <CenteredSpinner /> : null}
        <MessageHistory {...propsWithDefault}>
          {showEmpty ? <EmptyMessage {...propsWithDefault} /> : <UploadProgress {...propsWithDefault} />}
        </MessageHistory>
        {propsWithDefault.imagePreviewActive ? <ImagePreviewContainer /> : null}
        <MessageInput {...propsWithDefault} />
      </>
    </FileDropZone>
  );
};
