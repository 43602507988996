import config from 'config';
import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import purlImage from '../../../assets/images/main.purl.png';
import { Box } from '../Common/Toolkit/Box/Box';
import styled from '../../styled-components';
import { SecondaryButton } from '../Common/Toolkit/Button/SecondaryButton';
import { Spinner } from '../Common/Toolkit/Spinner/Spinner';
import { SelectableCard } from '../Common/Toolkit/SelectableCard/SelectableCard';
import { MeetingsTabContainer, MeetingsTabTitle } from './MeetingComponents';
import { removeProtocolAndWww } from '../../../lib/url';
import device from '../Common/Toolkit/devices';
import { Profile, SelectableItemType } from '../../view-model/modules/meetings/MeetingsTypes';

const ImageContainer = styled.div<{ marginTop?: string }>`
  width: 132px;
  margin-top: ${(props) => props.marginTop || '10px'};
  margin-right: 16px;
`;

const LoadingSpinner = styled(Spinner)`
  margin: auto;
`;

const CreatePersonalMeetingPanel = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const CreatePersonalMeetingButton = styled(SecondaryButton)`
  font-weight: bold;
  font-family: 'lato-bold';
  font-size: 12px;
  border-radius: 4px;
`;

const DetailsContainer = styled.div`
  display: inline-grid;
`;

const DetailsBlock = styled.div`
  flex: 1 1 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-size: 14px;
`;

const TucanImage = styled((props) => <img className={props.className} src={purlImage} alt="" />)`
  width: 100%;

  @media ${device.mobile} {
    width: 80px;
  }
`;

const messages = defineMessages({
  personalMeetingLabel: {
    id: 'meetingHub.personalMeetingLabel',
    defaultMessage: 'My Personal Meeting Room'
  },
  createPersonalMeetingLabel: {
    id: 'meetingHub.createPersonalMeetingLabel',
    defaultMessage: 'Meet like a pro with a personal meeting room.'
  },
  createPersonalMeetingDescription: {
    id: 'meetingHub.createPersonalMeetingDescription',
    defaultMessage: 'It only takes a minute.'
  },
  createPersonalMeetingButton: {
    id: 'meetingHub.createPersonalMeetingButton',
    defaultMessage: 'Set Up Your Room'
  }
});

interface Props {
  selected: boolean;
  className?: string;

  profile?: Profile;
  isFetchingProfile: boolean;

  selectMeeting: (itemType: SelectableItemType, id: string | null) => void;
  showCreatePersonalMeeting: () => void;
}

export class PersonalMeeting extends React.Component<Props> {
  private onProfileCardClick = () => {
    const { profile } = this.props;
    if (profile) {
      this.props.selectMeeting(SelectableItemType.PERSONAL, profile.meetingId);
    }
  };

  render() {
    const { profile, isFetchingProfile, showCreatePersonalMeeting } = this.props;
    return (
      <SelectableCard
        disabled={isFetchingProfile}
        onClick={!isFetchingProfile && !!profile ? this.onProfileCardClick : undefined}
        isSelected={this.props.selected}
        hasHoverEffect={!!profile}
      >
        <MeetingsTabContainer data-testid="personal-meeting" className={this.props.className}>
          {isFetchingProfile ? (
            <LoadingSpinner />
          ) : profile ? (
            <Box flexDirection="row">
              <ImageContainer marginTop="0">
                <TucanImage />
              </ImageContainer>
              <Box flexDirection="column" justifyContent="center" flex="1 1 100%">
                <MeetingsTabTitle whiteSpace="normal">
                  <FormattedMessage {...messages.personalMeetingLabel} />
                </MeetingsTabTitle>
                <DetailsContainer>
                  <DetailsBlock data-testid="personal-url">
                    {removeProtocolAndWww(`${config.externalLinks.g2mm}/${profile.profileId}`)}
                  </DetailsBlock>
                </DetailsContainer>
              </Box>
            </Box>
          ) : (
            <Box flexDirection="column">
              <MeetingsTabTitle whiteSpace="normal">
                <FormattedMessage {...messages.createPersonalMeetingLabel} />
              </MeetingsTabTitle>
              <Box flexDirection="row">
                <ImageContainer marginTop="10px">
                  <TucanImage />
                </ImageContainer>
                <Box flexDirection="column" justifyContent="center" alignItems="center">
                  <CreatePersonalMeetingPanel>
                    <CreatePersonalMeetingButton
                      onClick={showCreatePersonalMeeting}
                      data-testid="create-personal-meeting-button"
                    >
                      <FormattedMessage {...messages.createPersonalMeetingButton} />
                    </CreatePersonalMeetingButton>
                  </CreatePersonalMeetingPanel>
                  <FormattedMessage {...messages.createPersonalMeetingDescription} />
                </Box>
              </Box>
            </Box>
          )}
        </MeetingsTabContainer>
      </SelectableCard>
    );
  }
}
