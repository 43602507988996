import { debounce } from 'lodash';
import React, { RefObject } from 'react';
import classNames from 'classnames';
import { clamp } from '../../../../lib/numbers-utility';
import { Member, SingleSuggestion, Suggestion } from '@getgo/caps-redux';
import './MentionList.css';
import { KeyNames } from '../../../../lib/keycodes';
import { SuggestionsList } from '../../Common/SuggestionsList';

interface State {
  email: string;
  highlight: number;
  jid: string;
  name: string;
}

interface Props {
  hideMentionList: () => void;
  isOpen: boolean;
  mentions: Member[];
  onAddMention: (jid: string, name: string) => void;
  outerActionHandler: () => void;
  queryText?: string;
}

export class MentionList extends React.PureComponent<Props, State> {
  private allowPointerEvents: boolean;
  private scrollIntoViewAllowed: boolean;
  private scrollDisableDebouncer: () => void;
  private pointerEnableDebouncer: () => void;
  private suggestionsListRef: RefObject<SuggestionsList>;

  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      jid: '',
      highlight: 0
    };
    this.allowPointerEvents = true;
    this.scrollIntoViewAllowed = false;
    this.suggestionsListRef = React.createRef();

    this.scrollDisableDebouncer = debounce(() => {
      this.scrollIntoViewAllowed = false;
    }, 500);
    this.pointerEnableDebouncer = debounce(() => {
      this.allowPointerEvents = true;
      this.forceUpdate();
    }, 450);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.mentions && this.props.mentions !== nextProps.mentions) {
      this.setState({ highlight: 0 });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    this.props.hideMentionList();
  }

  handleScroll = () => {
    // prevent selection through mouse hover on scrolling
    this.disablePointerEvents();
  };

  disablePointerEvents = () => {
    if (this.allowPointerEvents) {
      this.allowPointerEvents = false;
    }
    this.pointerEnableDebouncer();
  };

  allowScrollIntoView = () => {
    if (!this.scrollIntoViewAllowed) {
      this.scrollIntoViewAllowed = true;
    }
    this.scrollDisableDebouncer();
  };

  getMentions = () => {
    const MAX_SIZE_LIST = 10;

    if (this.props.mentions) {
      return this.props.mentions.length > MAX_SIZE_LIST
        ? this.props.mentions.slice(0, MAX_SIZE_LIST)
        : this.props.mentions;
    }
    return [];
  };

  selectUp = () => {
    const mentions = this.getMentions();
    if (!mentions.length) {
      return;
    }
    this.allowScrollIntoView();
    this.setState((prevState) => {
      return {
        highlight: clamp(prevState.highlight - 1, 0, mentions.length - 1)
      };
    });
  };

  selectDown = () => {
    const mentions = this.getMentions();
    if (!mentions.length) {
      return;
    }
    this.allowScrollIntoView();
    this.setState((prevState) => {
      return {
        highlight: clamp(prevState.highlight + 1, 0, mentions.length - 1)
      };
    });
  };

  commitSelection = (event: KeyboardEvent) => {
    const mentions = this.getMentions();
    const selection = mentions[this.state.highlight];

    if (selection) {
      this.onSuggestionSelected(selection);
      event.stopImmediatePropagation();
    }
    event.preventDefault();
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === KeyNames.ENTER) {
      this.commitSelection(event);
    } else if (event.key === KeyNames.ARROW_UP) {
      this.selectUp();
    } else if (event.key === KeyNames.ARROW_DOWN) {
      this.selectDown();
    } else if (event.key === KeyNames.ESCAPE) {
      this.props.hideMentionList();
    }
  };

  onSuggestionSelected = (person: Suggestion) => {
    person = person as SingleSuggestion;
    this.setState({
      jid: person.jid,
      email: person.email,
      name: person.name,
      highlight: -1
    });
    this.props.hideMentionList();
    this.props.onAddMention(person.jid, person.name);
  };

  onMouseOverSuggestion = (arrayIndex: number) => {
    if (arrayIndex !== null && arrayIndex !== this.state.highlight) {
      this.setState({
        highlight: arrayIndex
      });
    }
  };

  formatSuggestionTitle = (s: Suggestion) => {
    s = s as SingleSuggestion;
    return (
      <div>
        <div className="name">{s.name || s.email}</div>
        {s.name && <div className="email">{s.email}</div>}
      </div>
    );
  };

  render() {
    const mentions = this.getMentions();
    const suggestionClass = classNames('mention-suggestions-plain', {
      'disable-pointer': !this.allowPointerEvents
    });

    const mentionList = (
      <SuggestionsList
        className={suggestionClass}
        suggestions={mentions}
        onSelect={this.onSuggestionSelected}
        onScroll={this.handleScroll}
        queryText={this.props.queryText}
        highlightIndex={this.state.highlight}
        avatarName={(s) => s.name}
        suggestionTitle={this.formatSuggestionTitle}
        allowScrollIntoView={this.scrollIntoViewAllowed}
        noEmptyMessage
        ref={this.suggestionsListRef}
        onMouseOverSuggestion={this.onMouseOverSuggestion}
      />
    );

    return <div className="mention-list-plain">{mentionList}</div>;
  }
}
