import { connect } from 'react-redux';
import { Toasts } from '../components/Common/Toolkit/Toasts/Toasts';
import actions from '../../app/view-model/modules/core/notifications/notificationsActions';
import { Dispatch, State } from '../view-model/types';

interface Props {
  position?: 'top' | 'bottom';
}

const mapStateToProps = (state: State, ownProps: Props) => {
  return {
    ...ownProps,
    toasts: state.core.notifications.toasts
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    hideToastNotification(id: string) {
      dispatch(actions.hideToastNotification(id));
    }
  };
};

const ToastsContainer = connect(mapStateToProps, mapDispatchToProps)(Toasts);

export default ToastsContainer;
