import * as React from 'react';
import './UploadButton.css';
import { messages } from '../../../../Common/Upload/UploadButton/UploadButton';
import { useIntl } from 'react-intl';

export const isHoverSupported = !window.matchMedia('(hover:none)').matches;

export interface Props {
  disabled?: boolean;
  isFileSharingAllowed?: boolean;
  onFileUpload?: (files: FileList) => void;
  formatMessage: (descriptor: string, values?: { [key: string]: number }) => string;
}

// todo: refactor the two UploadButton components / consolidate
export class UploadButton extends React.PureComponent<Props, {}> {
  static defaultProps: Partial<Props> = {
    disabled: false,
    isFileSharingAllowed: true
  };

  private fileInput: HTMLInputElement | null = null;

  private handleClick = () => {
    if (this.fileInput) {
      this.fileInput.click();
    }
  };

  private handleFiles = (event: React.SyntheticEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && this.props.onFileUpload) {
      this.props.onFileUpload(event.currentTarget.files);
    }

    // clear file picker to enable re-uploading same files (e.g. on error)
    event.currentTarget.value = '';
  };

  render() {
    const { disabled } = this.props;

    return (
      <UploadButonWrapper disabled={disabled || !this.props.isFileSharingAllowed} onClick={this.handleClick}>
        <i
          className="togo-icon togo-icon-clip"
          data-tip={
            isHoverSupported && !this.props.isFileSharingAllowed
              ? this.props.formatMessage('fileSharingNotAllowedTooltip')
              : null
          }
          data-place="right"
          data-multiline
        />
        <input
          disabled={disabled}
          multiple
          onChange={this.handleFiles}
          ref={(elm) => (this.fileInput = elm)}
          type="file"
          data-testid="upload-input"
          aria-labelledby="upload-button"
        />
      </UploadButonWrapper>
    );
  }
}

interface ButtonWrapperProps {
  onClick: () => void;
  disabled: boolean;
}

const UploadButonWrapper: React.FC<ButtonWrapperProps> = ({ onClick, disabled, children }) => {
  const { formatMessage: f } = useIntl();

  return (
    <button
      id="upload-button"
      className="btn btn-icon message-input-upload-button"
      disabled={disabled}
      onClick={onClick}
      type="button"
      data-testid="upload-button"
      aria-label={f(messages.uploadLabel)}
    >
      {children}
    </button>
  );
};
