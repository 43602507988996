import { themeGet } from '@styled-system/theme-get';
import config from 'config';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import breakoutIcon from '../../../../assets/images/eap/breakout-rooms.svg';
import gotoImg from '../../../../assets/images/logo.svg';
import gotoIcon from '../../../../assets/images/eap/goto.svg';
import handraisingIcon from '../../../../assets/images/eap/handraising.svg';
import layoutIcon from '../../../../assets/images/eap/layout.svg';
import styled from '../../../styled-components';
import preferencesActions from '../../../view-model/modules/core/preferences/preferencesActions';
import { getNewSessionTrackingId } from '../../../view-model/modules/meetings/meetingsActions';
import sharedActions from '../../../view-model/sharedActions';
import { PreferencesBlock } from '../../Common/Preferences/PreferencesBlock';
import { PreferencesItemBool } from '../../Common/Preferences/PreferencesItemBool';
import { AsyncPreferencesPage } from '../../Common/Preferences/PreferencesPage';
import device from '../../Common/Toolkit/devices';

const messages = defineMessages({
  heading: {
    id: 'gotoAppEapPreferences.header',
    defaultMessage: 'Explore the new GoTo early'
  },
  description: {
    id: 'gotoAppEapPreferences.description',
    defaultMessage:
      'Your organization has chosen to try the new GoTo app, an always-on workplace for all your collaboration needs. Soon, everything from messaging and meetings to webinars and trainings will be together in one consistent yet flexible experience.'
  },
  checkboxLabel: {
    id: 'gotoAppEapPreferences.checkboxLabel',
    defaultMessage: 'Use the new GoTo'
  },
  checkboxDescription: {
    id: 'gotoAppEapPreferences.checkboxDescription',
    defaultMessage:
      'Turning this on will move new meetings to the GoTo app. You can still use the Hub, and you can switch back to GoToMeeting anytime by unchecking this box.'
  },
  downloadLinkLabel: {
    id: 'gotoAppEapPreferences.downloadLinkLabel',
    defaultMessage: 'Get the GoTo app'
  },
  feedbackHeader: {
    id: 'gotoAppEapPreferences.feedbackHeader',
    defaultMessage: 'Have you tried it?'
  },
  feedbackLinkLabel: {
    id: 'gotoAppEapPreferences.feedbackLinkLabel',
    defaultMessage: 'Share your thoughts'
  },
  feedbackDescription: {
    id: 'gotoAppEapPreferences.feedbackDescription',
    defaultMessage: 'Help us build a better collaboration experience for everyone.'
  },
  introHeading: {
    id: 'gotoAppEapPreferences.introHeading',
    defaultMessage: 'What is new in GoTo?'
  },
  introDescription: {
    id: 'gotoAppEapPreferences.introDescription',
    defaultMessage: 'Be among the first to try our latest features. Here is what you can check out now in GoTo:'
  },
  introLookAndFeelHeading: {
    id: 'gotoAppEapPreferences.introLookAndFeelHeading',
    defaultMessage: 'Fresh look and feel'
  },
  introLookAndFeelDescription: {
    id: 'gotoAppEapPreferences.introLookAndFeelDescription',
    defaultMessage: 'Take advantage of a modern platform designed with you in mind.'
  },
  introOneAppHeading: {
    id: 'gotoAppEapPreferences.introOneAppHeading',
    defaultMessage: 'One app for everything'
  },
  introOneAppDescription: {
    id: 'gotoAppEapPreferences.introOneAppDescription',
    defaultMessage: 'Start, join and manage your meetings (and soon much more) in one place.'
  },
  introBreakoutRoomsHeading: {
    id: 'gotoAppEapPreferences.introBreakoutRoomsHeading',
    defaultMessage: 'Breakout rooms'
  },
  introBreakoutRoomsDescription: {
    id: 'gotoAppEapPreferences.introBreakoutRoomsDescription',
    defaultMessage: 'Split into small groups for brainstorming, workshops, and other activities.'
  },
  introHandRaisingHeading: {
    id: 'gotoAppEapPreferences.introHandRaisingHeading',
    defaultMessage: 'Hand raising'
  },
  introHandRaisingDescription: {
    id: 'gotoAppEapPreferences.introHandRaisingDescription',
    defaultMessage: 'Quickly see who has a question or take a poll with a show of hands.'
  }
});

const CustomDescription = styled.p`
  margin-top: 5px;
  color: ${themeGet('colors.neutralScale.400')};
`;

const StyledImage = styled.img`
  display: block;
  margin: 15px;
  margin-left: 30px;
  height: 85px;
  width: 85px;
`;

const IconWrapper = styled.div<{ color: string }>`
  margin: 0 10px;
  display: inline;
  color: ${(props) => props.color};
`;

const StyledDownloadButton = styled.button<{ isActive: boolean }>`
  display: inline-flex;
  text-decoration: none;
  flex-grow: 1;
  border: 0 none;
  background: ${(props) => (props.isActive ? themeGet('colors.primary') : themeGet('colors.neutralScale.100'))};
  border-radius: 4px;
  color: ${(props) => (props.isActive ? themeGet('colors.primaryOn') : themeGet('colors.neutralScale.300'))};
  white-space: nowrap;
  height: 36px;
  line-height: 32px;
  padding: 0 16px;
  outline: none;
  align-items: center;
  margin: 20px 0;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'not-allowed')};

  &:focus {
    box-shadow: 0 0 0 1px ${themeGet('colors.surface')}, 0 0 0 3px ${themeGet('colors.primary')};
  }

  &:hover {
    background: ${(props) => (props.isActive ? themeGet('colors.primary') : themeGet('colors.neutralScale.100'))};
  }

  @media ${device.mobile} {
    height: 32px;
    line-height: 28px;
  }
`;

const StyledIntroSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: center;
`;

const StyledIntroListItem = styled.li<{ listStyleImage: string }>`
  list-style: none;
  font-size: 16px;
  color: #4c535d;
  list-style-image: none;
  position: relative;
  margin-bottom: 8px;

  &:before {
    position: absolute;
    left: -22px;
    top: 4px;
    content: ${(props) => `url(${props.listStyleImage})`};
  }

  span {
    display: block;
    font-size: 12px;
    color: #6b7582;
  }
`;

export const GoToAppEapPreferences: React.FC = () => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const gotoAppDownloadUrl = `${
    config.externalLinks.gotoAppDownloadLink
  }?sessionTrackingId=${getNewSessionTrackingId()}`;
  return (
    <AsyncPreferencesPage group="gotoAppEapSettings" hideButtons={true}>
      {({ settings, onChange, onSave }) => (
        <>
          <PreferencesBlock titleMessage={messages.heading} learnMoreLink={config.externalLinks.gotoAppLearnMoreLink}>
            <CustomDescription>{f(messages.description)}</CustomDescription>
            <PreferencesItemBool
              id="goto-app-eap-enabled"
              type="checkbox"
              checked={settings.gotoAppEnabled}
              onChange={(isDefault) => {
                onChange('gotoAppEnabled', isDefault);
                dispatch(preferencesActions.settingGotoAppEnabledChanged(isDefault));
                onSave();
              }}
              disabled={!settings.gotoAppProvisioned || !settings.gotoAppEntitled}
              labelMessage={messages.checkboxLabel}
              description={f(messages.checkboxDescription)}
            />
            <StyledDownloadButton
              data-testid="goto-app-download-link"
              onClick={() => {
                dispatch(sharedActions.gotoAppEapDownloaded());
                window.open(gotoAppDownloadUrl, '_blank');
              }}
              disabled={!settings.gotoAppProvisioned || !settings.gotoAppEntitled || !settings.gotoAppEnabled}
              isActive={settings.gotoAppEnabled}
            >
              {f(messages.downloadLinkLabel)}
            </StyledDownloadButton>
          </PreferencesBlock>
          <PreferencesBlock titleMessage={messages.introHeading} descriptionMessage={messages.introDescription}>
            <StyledIntroSection>
              <StyledImage src={gotoImg} />
              <ul>
                <StyledIntroListItem listStyleImage={layoutIcon}>
                  <FormattedMessage {...messages.introLookAndFeelHeading} />
                  <span>
                    <FormattedMessage {...messages.introLookAndFeelDescription} />
                  </span>
                </StyledIntroListItem>
                <StyledIntroListItem listStyleImage={gotoIcon}>
                  <FormattedMessage {...messages.introOneAppHeading} />
                  <span>
                    <FormattedMessage {...messages.introOneAppDescription} />
                  </span>
                </StyledIntroListItem>
                <StyledIntroListItem listStyleImage={breakoutIcon}>
                  <FormattedMessage {...messages.introBreakoutRoomsHeading} />
                  <span>
                    <FormattedMessage {...messages.introBreakoutRoomsDescription} />
                  </span>
                </StyledIntroListItem>
                <StyledIntroListItem listStyleImage={handraisingIcon}>
                  <FormattedMessage {...messages.introHandRaisingHeading} />
                  <span>
                    <FormattedMessage {...messages.introHandRaisingDescription} />
                  </span>
                </StyledIntroListItem>
              </ul>
            </StyledIntroSection>
          </PreferencesBlock>
          <PreferencesBlock
            titleMessage={messages.feedbackHeader}
            learnMoreLink={config.externalLinks.gotoAppFeedbackLink}
            learnMoreLinkLabel={f(messages.feedbackLinkLabel)}
            learnMoreLinkIcon={
              <IconWrapper color={`${themeGet('colors.primary')}`}>
                <i className="togo-icon togo-icon-popout" />
              </IconWrapper>
            }
          >
            <CustomDescription>{f(messages.feedbackDescription)}</CustomDescription>
          </PreferencesBlock>
        </>
      )}
    </AsyncPreferencesPage>
  );
};
