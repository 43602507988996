import styled from '../../../../styled-components';
import device from '../devices';
import * as React from 'react';
import alertIcon from '../../../../../assets/images/icons/alert-active.svg';
import { themeGet } from '@styled-system/theme-get';

export const ErrorMessage = styled.span.attrs<{ 'data-testid'?: string }>((props) => ({
  'data-testid': props['data-testid'] || 'error-message'
}))`
  display: block;
  color: ${themeGet('colors.danger.primaryScale.400')};
`;

export const ErrorMessageInline = styled(ErrorMessage).attrs<{ 'data-testid'?: string }>((props) => ({
  'data-testid': props['data-testid'] || 'error-message-inline'
}))`
  display: inline-block;
  margin-left: 8px;
  @media ${device.mobile} {
    display: block;
    margin-left: 0;
  }
`;

export const Description = styled.span.attrs<{ 'data-testid'?: string }>((props) => ({
  'data-testid': props['data-testid'] || 'description'
}))`
  display: block;
  color: ${themeGet('colors.neutralScale.400')};
  margin-top: 3px;
`;

export const Note = styled(({ children, ...props }: any) => (
  <span {...props}>
    {children}
    <img alt="note" src={alertIcon} />
  </span>
)).attrs({ 'data-testid': 'note' })`
  display: block;
  color: ${themeGet('colors.neutralScale.350')};
  margin-top: 4px;
  font-size: 11px;

  img {
    width: 18px;
    margin-left: 12px;
  }
`;

export const Block = styled.div`
  margin-top: 14px;
`;
