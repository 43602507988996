import * as React from 'react';
import { Box, Item } from '../../Common/Toolkit/Box/Box';
import { formatTime, getRoundedDurationInMinutes } from '../../../../lib/date';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import {
  MeetingsTabContainer,
  MeetingsId,
  MeetingsTabSubject,
  MeetingsTabTime,
  MeetingsTabTitle,
  MeetingRunningBadge
} from '../MeetingComponents';
import { ScheduledMeeting } from '../../../view-model/modules/meetings/MeetingsTypes';

const messages = defineMessages({
  inMinLabel: {
    id: 'meetingHub.upcomingMeetingContent.inMinLabel',
    defaultMessage: 'In {min} min'
  },
  nowLabel: {
    id: 'meetingHub.upcomingMeetingContent.nowLabel',
    defaultMessage: 'Now'
  }
});

const getRemainingMinutes = (startTime: Date) => {
  const now = new Date();
  return getRoundedDurationInMinutes(now, startTime);
};

const Time = injectIntl(
  (
    props: {
      beginDate: Date;
      endDate: Date;
      minutesUntil: number | null;
      inSession: boolean;
    } & WrappedComponentProps
  ) => {
    if (!props.beginDate || !props.endDate) {
      return null;
    }
    const beginTime = formatTime(props.intl, props.beginDate);
    const duration = getRoundedDurationInMinutes(props.beginDate, props.endDate);

    let time: any = beginTime;
    let timeColor;
    if (props.inSession || props.minutesUntil === null || props.minutesUntil <= 0) {
      time = <FormattedMessage {...messages.nowLabel} values={{ min: props.minutesUntil }} />;
      timeColor = '#f68d2e';
    } else if (props.minutesUntil !== null && props.minutesUntil <= 15) {
      if (props.minutesUntil > 0) {
        time = <FormattedMessage {...messages.inMinLabel} values={{ min: props.minutesUntil }} />;
        timeColor = '#ac6117';
      }
    }

    return (
      <MeetingsTabTime>
        <MeetingsTabTitle data-testid="meeting-time" color={timeColor}>
          {time}
        </MeetingsTabTitle>
        <Item>{duration} min</Item>
      </MeetingsTabTime>
    );
  }
);

export interface Props {
  meeting: ScheduledMeeting;
  className?: string;
}

export class UpcomingMeetingItem extends React.Component<Props> {
  private countdownTimer: any = null;

  state = {
    minutesUntil: null
  };

  componentDidMount() {
    const beginDate = new Date(this.props.meeting.scheduledStartTime);

    this.updateCountdown();
    if (getRemainingMinutes(beginDate) >= 1) {
      this.countdownTimer = setInterval(() => this.updateCountdown(), 5000);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    /* for immediate updates when displayed in MeetingDetails */
    if (this.props.meeting.meetingId !== prevProps.meeting.meetingId) {
      this.updateCountdown();
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  private clearTimer() {
    clearInterval(this.countdownTimer);
  }

  private updateCountdown = () => {
    const minutesUntil = getRemainingMinutes(new Date(this.props.meeting.scheduledStartTime));
    if (minutesUntil < 1) {
      this.clearTimer();
    }
    this.setState({
      minutesUntil
    });
  };

  render() {
    const beginDate = new Date(this.props.meeting.scheduledStartTime);
    const endDate = new Date(this.props.meeting.scheduledEndTime);

    return (
      <MeetingsTabContainer className={this.props.className}>
        <Box flexDirection="row">
          <Time
            beginDate={beginDate}
            endDate={endDate}
            minutesUntil={this.state.minutesUntil}
            inSession={this.props.meeting.inSession}
          />
          <MeetingsTabSubject>
            <MeetingsTabTitle data-testid="meeting-subject">{this.props.meeting.subject}</MeetingsTabTitle>
            <MeetingsId meeting={this.props.meeting} />
          </MeetingsTabSubject>
        </Box>
        {this.props.meeting.inSession && <MeetingRunningBadge />}
      </MeetingsTabContainer>
    );
  }
}
