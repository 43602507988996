import styled from '../../../../styled-components';
import device from '../devices';
import React, { ReactElement } from 'react';
import { themeGet } from '@styled-system/theme-get';

const DEFAULT_LABEL = 'CANCEL';

export interface Props {
  icon?: ReactElement;
  label?: string;
  fontSize?: number;
  disabled?: boolean;
  onClick?(event: any): void;
  className?: string;
}

const GhostButtonLayout: React.FC<Props> = (props) => {
  const { icon, label, ...rest } = props;

  return (
    <button {...rest} type="button">
      {icon}
      <span className="ghost-button-label">{label || props.children || DEFAULT_LABEL}</span>
    </button>
  );
};

export const GhostButton = styled(GhostButtonLayout)<Props>`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '14px')};
  font-family: lato-regular, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: bold;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: capitalize;
  white-space: nowrap;
  color: ${themeGet('colors.primary')};
  border-radius: 4px;
  outline: none;

  svg {
    padding-right: 8px;
    vertical-align: middle;
    width: 1.3em;
    height: 1.3em;
  }

  &:hover:enabled {
    background-color: ${themeGet('colors.neutralScale.100')};
  }

  &:active:enabled {
    background-color: ${themeGet('colors.primaryScale.100')};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${themeGet('colors.primaryScale.100')};

    .main-path {
      fill: ${themeGet('colors.primaryScale.100')};
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px ${themeGet('colors.primary')};
  }

  @media ${device.mobile} {
    height: 32px;
    line-height: 32px;
  }
`;
