import React from 'react';
import shallowCompare from 'react-addons-shallow-compare';
import { Emoji } from './Emoji';

interface Props {
  emojis: any[];
  onChange: (emoji: string) => void;
  style: React.CSSProperties;
  buildEmojiUrl?: (hexCode: string, pngSize: string) => string;
}

export class EmojiRow extends React.Component<Props, {}> {
  shouldComponentUpdate(nextProps: Props, nextState: {}): boolean {
    return shallowCompare(this, nextProps, nextState);
  }

  handleEmojiSelect = (_: any, emoji: string) => {
    this.props.onChange(emoji);
  };

  render() {
    const { emojis, style } = this.props;

    return (
      <div className="emoji-row" style={style}>
        {emojis.map((emoji) => (
          <Emoji
            {...emoji}
            ariaLabel={emoji.name}
            role="option"
            key={emoji.shortname}
            onSelect={this.handleEmojiSelect}
            buildEmojiUrl={this.props.buildEmojiUrl}
          />
        ))}
      </div>
    );
  }
}
