import * as React from 'react';
import { AnytimeMeetingItem } from './AnytimeMeetingItem';
import { SelectableCard } from '../../Common/Toolkit/SelectableCard/SelectableCard';
import { OneTimeMeetingItem } from './OneTimeMeetingItem';
import { CardList } from '../../Common/Dashboard/CardList';
import { getNumberOfRunningMeetings, sortRecurringMeetings } from '../../../../lib/meeting';
import { ImpromptuMeeting, Meeting, SelectableItemType } from '../../../view-model/modules/meetings/MeetingsTypes';

interface Props {
  recurringMeetings: ReadonlyArray<Meeting>;
  runningImpromptuMeeting?: ImpromptuMeeting;
  meetNow: () => void;
  selectedId: string | null;
  selectedItemType: SelectableItemType;
  selectMeeting?: (itemType: SelectableItemType, id?: string) => void;
}

export const AnytimeMeetingsList: React.FC<Props> = (props) => {
  const { selectedId, selectMeeting = () => {} } = props;

  const sortedRecurringMeetings = sortRecurringMeetings(props.recurringMeetings);

  const meetingEntriesList = sortedRecurringMeetings.map((meeting) => {
    let isSelected = false;
    if (selectedId && meeting.meetingId === selectedId) {
      isSelected = true;
    }

    return (
      <li key={meeting.meetingId}>
        <SelectableCard
          onClick={() => selectMeeting(SelectableItemType.RECURRING, meeting.meetingId)}
          isSelected={isSelected}
        >
          <AnytimeMeetingItem meeting={meeting} />
        </SelectableCard>
      </li>
    );
  });

  const oneTimeMeetingId = props.runningImpromptuMeeting?.meetingId;
  const isOneTimeMeetingSelected = props.selectedItemType === SelectableItemType.IMPROMPTU;
  const numberOfRunningMeetings = getNumberOfRunningMeetings(sortedRecurringMeetings);

  meetingEntriesList.splice(
    numberOfRunningMeetings,
    0,
    <li key={'meetNow'}>
      <SelectableCard
        onClick={() => selectMeeting(SelectableItemType.IMPROMPTU, oneTimeMeetingId)}
        isSelected={isOneTimeMeetingSelected}
      >
        {props.runningImpromptuMeeting ? (
          <AnytimeMeetingItem meeting={props.runningImpromptuMeeting} />
        ) : (
          <OneTimeMeetingItem />
        )}
      </SelectableCard>
    </li>
  );

  return <CardList>{meetingEntriesList}</CardList>;
};
