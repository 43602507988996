import { combineReducers } from 'redux';
import {
  ChatReducer as chat,
  ContactsReducer as contacts,
  GroupReducer as group,
  UnreadMessagesReducer as unreadMessages
} from '@getgo/caps-redux';
import app from './modules/core/app/appReducer';
import errors from './modules/core/errors/errorsReducer';
import meetings from './modules/meetings/meetingsReducer';
import scheduler from './modules/scheduler/schedulerReducer';
import messageInputs from './modules/messaging/messageInputs/messageInputsReducer';
import people from './modules/messaging/people/peopleReducer';
import preferences from './modules/core/preferences/preferencesReducer';
import notifications from './modules/core/notifications/notificationsReducer';
import uploads from './modules/messaging/uploads/uploadsReducer';
import user from './modules/core/user/userReducer';
import imagePreview from './modules/messaging/imagePreview/imagePreviewReducer';
import discover from './modules/discover/discoverReducer';

const core = combineReducers({
  app,
  errors,
  notifications,
  preferences,
  user
});

const messaging = combineReducers({
  chat,
  contacts,
  group,
  imagePreview,
  messageInputs,
  people,
  unreadMessages,
  uploads
});

// change reduxStateVersion to invalidate the old local storage
export default combineReducers({
  core,
  messaging,
  meetings,
  scheduler,
  discover,
  reduxStateVersion: () => '1'
});
