import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import styled from '../../../styled-components';
import { TabChild, Tabs } from './Tabs/Tabs';
import { MeetingTab } from './Tabs/MeetingTab';
import { State as UserState } from '../../../view-model/modules/core/user/userReducer';
import { Icon } from '../../Common/Toolkit/Icon/Icon';
import { Icons } from '../../Common/Toolkit/ICONS';
import { ButtonBar } from '../../Common/Toolkit/Button/ButtonBar';
import {
  MeetingRequest,
  MeetingType,
  PropertyState,
  Scheduler,
  SchedulerFullMeeting
} from '../../../view-model/modules/scheduler/schedulerReducer';
import { MoreTab } from './Tabs/MoreTab';
import { PersonalizeTab } from './Tabs/PersonalizeTab';
import { DisableStateBox } from '../../Common/Toolkit/Box/DisableStateBox';
import { BrandTheme, fetchAvailableThemes } from '../../../../models/Meeting';
import { AudioTab } from './Tabs/AudioTab';
import { Spinner } from '../../Common/Toolkit/Spinner/Spinner';
import { createMeetingRequestFromSchedulerMeeting, getChangedMeetingKeys } from '../../../../models/UpdateMeeting';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getRouteFor } from '../../../services/NavService';
import Scrollbars from 'react-custom-scrollbars';
import { ModalDialogContentWrapper } from '../../Common/ModalDialog/ModalDialogContentWrapper';
import { AudioSettingItems, AudioValidationErrors, getAudioValidationErrors } from '../../../../lib/audio';
import { Profile } from '../../../view-model/modules/meetings/MeetingsTypes';
import FeatureService from '../../../services/FeatureService';
import { AlertLevel } from '../../../view-model/modules/core/errors/errorActions';
import { Alert } from '../../Common/Alert/Alert';

const SpinnerOverlay = styled(Spinner)`
  position: absolute;
  z-index: 100;
  margin: 200px 0 0 -30px;
  left: 50%;
`;

const TabContainer = styled.div`
  flex-basis: 417px;
  display: flex;
  flex-direction: column;

  /* safari fix: force scrollbars container to grow */
  > div {
    flex: 1;
  }
`;

const messages = defineMessages({
  newMeetingTitle: {
    id: 'meetingDashboard.meetingScheduler.newMeetingTitle',
    defaultMessage: 'New Meeting'
  },
  editMeetingTitle: {
    id: 'meetingDashboard.meetingScheduler.editMeetingTitle',
    defaultMessage: 'Edit Meeting'
  },
  meeting: {
    id: 'meetingDashboard.meetingScheduler.meeting',
    defaultMessage: 'Meeting'
  },
  audio: {
    id: 'meetingDashboard.meetingScheduler.audio',
    defaultMessage: 'Audio'
  },
  personalize: {
    id: 'meetingDashboard.meetingScheduler.personalize',
    defaultMessage: 'Personalize'
  },
  more: {
    id: 'meetingDashboard.meetingScheduler.more',
    defaultMessage: 'More'
  },
  save: {
    id: 'meetingDashboard.meetingScheduler.save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'meetingDashboard.meetingScheduler.cancel',
    defaultMessage: 'Cancel'
  }
});

export enum SchedulerTabs {
  meeting = 'meeting',
  audio = 'audio',
  personalize = 'personalize',
  more = 'more'
}

export interface Props extends Scheduler {
  hideScheduleMeeting: () => void;

  onPropertyChange: (property: keyof SchedulerFullMeeting, value: any) => void;
  onValidationError: (property: keyof SchedulerFullMeeting, tab: SchedulerTabs) => void;
  onValidationSuccess: (property: keyof SchedulerFullMeeting, tab: SchedulerTabs) => void;
  showProfilePreview: (theme: string, roomDisplayName?: string) => void;

  createMeeting: (meeting: MeetingRequest) => Promise<void>;
  updateMeeting: (
    meetingId: string,
    meeting: MeetingRequest,
    updatedMeeting: MeetingRequest,
    isPersonalMeeting: boolean
  ) => Promise<void>;

  user: UserState;
  profile?: Profile;
  brandingEnabled: boolean;
}

export interface SchedulerTabProps extends Props {
  showAlert: (alertProps: SchedulerAlertProps) => void;
}

interface SchedulerAlertProps {
  alertLevel: AlertLevel;
  alertText: string;
  closeOnTabChange?: boolean;
}

interface State {
  tab: string;
  themes: BrandTheme[];
  alert?: SchedulerAlertProps;
}

class MeetingScheduler extends React.Component<Props & WrappedComponentProps & RouteComponentProps, State> {
  state: State = {
    tab: SchedulerTabs.meeting,
    themes: []
  };

  audioScrollBars: Scrollbars | null = null;

  componentDidMount() {
    (async () => {
      if (this.props.brandingEnabled || FeatureService.isEnabled('hub-branding-account-themes-enabled')) {
        const themes = await fetchAvailableThemes();
        if (typeof themes !== 'undefined') {
          this.setState({ themes });
        }
      }
    })();
    this.setState({ tab: this.props.initialTab });
  }

  private static isEqual(a: SchedulerFullMeeting, b: SchedulerFullMeeting): boolean {
    return !getChangedMeetingKeys(a, b).length;
  }

  private get hasChanged(): boolean {
    return !MeetingScheduler.isEqual(
      this.props.meeting.update as SchedulerFullMeeting,
      this.props.meeting.initial as SchedulerFullMeeting
    );
  }

  private get hasErrors(): boolean {
    return Object.values(this.props.propertyState).some((item?: PropertyState) => item && item.state === 'error');
  }

  private get isPersonalMeeting(): boolean {
    return !!this.props.profile && this.props.profile.meetingId === this.props.meetingId;
  }

  private get canSave(): boolean {
    if (this.props.meeting.update.personalizeMeeting) {
      if (!this.props.propertyState.roomName || this.props.propertyState.roomName.state !== 'success') {
        if (this.props.meeting.initial.roomName !== this.props.meeting.update.roomName) {
          return false;
        }
      }
    }

    if (getAudioValidationErrors(this.props.meeting.update as AudioSettingItems).length > 0) return false;

    return (this.props.isNew || this.hasChanged) && !this.hasErrors;
  }

  private onPersonalizeButtonClick = () => {
    this.setState({
      tab: SchedulerTabs.personalize
    });
  };

  private onTabChange = (tab: string) => {
    this.setState({ tab });
    if (this.state.alert && this.state.alert.closeOnTabChange) {
      this.setState({ alert: undefined });
    }
  };

  private onSave = async (): Promise<void> => {
    if (
      !this.props.user.hasLoaded ||
      this.props.isLoadingMeeting ||
      this.props.isLoadingSettings ||
      this.props.isUpdating
    ) {
      return;
    }

    if (
      getAudioValidationErrors(this.props.meeting.update as AudioSettingItems).includes(
        AudioValidationErrors.AUDIO_EMPTY_CUSTOM_MESSAGE
      )
    ) {
      this.setState({ tab: SchedulerTabs.audio });
      setTimeout(() => this.audioScrollBars?.scrollToBottom());
      return;
    }

    const schedulerMeeting = this.props.meeting.update;
    const originalMeeting = this.props.isNew ? undefined : this.props.meeting.initial;
    const meetingRequest = createMeetingRequestFromSchedulerMeeting(
      schedulerMeeting,
      originalMeeting,
      this.props.profile,
      this.props.intl
    );

    if (this.props.isNew) {
      if (!this.props.meeting.update.personalizeMeeting) {
        // TO BE REMOVED: At the moment (2019-09-24) the MeetingService won't allow the setting meetingUrl: {} when
        // creating a meeting. This code here can be removed when the MeetingService is adapted.
        delete meetingRequest.meetingUrl;
      }
      // If we don't wait for createMeeting/updateMeeting, the rerouting below triggers refetching meeting data
      // which races with the create/update call.
      await this.props.createMeeting(meetingRequest);
    } else {
      if (this.isPersonalMeeting) {
        delete meetingRequest.meetingUrl;
      }
      // Wait for same reason as above.
      await this.props.updateMeeting(
        this.props.meetingId,
        meetingRequest,
        {
          ...schedulerMeeting,
          coorganizerKeys: schedulerMeeting?.coorganizerKeys?.map((coorg) => coorg.key) || []
        },
        this.isPersonalMeeting
      );
    }

    this.props.history.push(
      getRouteFor(meetingRequest.meetingType === MeetingType.scheduled ? 'upcomingMeetings' : 'anytimeMeetings')
    );
  };

  render() {
    const schedulerTabProps: SchedulerTabProps = {
      ...this.props,
      showAlert: (alertProps: SchedulerAlertProps) => this.setState({ alert: alertProps })
    };
    const { formatMessage: f } = this.props.intl;
    const tabs: Array<TabChild<SchedulerTabs>> = [];

    tabs.push({
      title: f(messages.meeting),
      name: SchedulerTabs.meeting,
      child: (
        <TabContainer>
          <Scrollbars>
            <MeetingTab {...schedulerTabProps} onPersonalizeButtonClick={this.onPersonalizeButtonClick} />
          </Scrollbars>
        </TabContainer>
      ),
      icon: <Icon icon={Icons.meetingsActive} size={30} />
    });
    tabs.push({
      title: f(messages.audio),
      name: SchedulerTabs.audio,
      child: (
        <TabContainer>
          <Scrollbars ref={(r) => (this.audioScrollBars = r)}>
            <AudioTab {...schedulerTabProps} />
          </Scrollbars>
        </TabContainer>
      ),
      icon: <Icon icon={Icons.voip} size={30} />,
      disabled: this.props.isLoadingSettings
    });
    if (this.props.profile && !this.isPersonalMeeting) {
      tabs.push({
        title: f(messages.personalize),
        name: SchedulerTabs.personalize,
        child: (
          <TabContainer>
            <Scrollbars>
              <PersonalizeTab {...schedulerTabProps} themes={this.state.themes} />
            </Scrollbars>
          </TabContainer>
        ),
        icon: <Icon icon={Icons.person} size={30} />
      });
    }
    if (!this.isPersonalMeeting) {
      tabs.push({
        title: f(messages.more),
        name: SchedulerTabs.more,
        child: (
          <TabContainer>
            <Scrollbars>
              <MoreTab {...schedulerTabProps} />
            </Scrollbars>
          </TabContainer>
        ),
        icon: <Icon icon={Icons.settings} size={30} />,
        disabled: this.props.isLoadingSettings
      });
    }

    return (
      <ModalDialogContentWrapper
        title={<FormattedMessage {...(this.props.isNew ? messages.newMeetingTitle : messages.editMeetingTitle)} />}
        onClose={this.props.hideScheduleMeeting}
        testId={'scheduler-window'}
        alert={
          this.state.alert ? <Alert level={this.state.alert.alertLevel} text={this.state.alert.alertText} /> : undefined
        }
      >
        <DisableStateBox disabled={this.props.isLoadingMeeting || this.props.isUpdating}>
          {this.props.isLoadingMeeting && <SpinnerOverlay />}
          {/* eslint-disable-next-line react/no-children-prop */}
          <Tabs<SchedulerTabs> children={tabs} active={this.state.tab} onTabChange={this.onTabChange} />
          <ButtonBar
            buttonOk={<FormattedMessage {...messages.save} />}
            buttonCancel={<FormattedMessage {...messages.cancel} />}
            onOk={this.onSave}
            onCancel={this.props.hideScheduleMeeting}
            okDisabled={!this.canSave}
          />
        </DisableStateBox>
      </ModalDialogContentWrapper>
    );
  }
}

export default injectIntl(withRouter(MeetingScheduler));
