import {
  createProfile as createNewProfile,
  deleteMeeting as deleteMeetingApi,
  deleteSession,
  deleteSessionRecording as deleteSessionRecordingApi,
  getConcurrentSession,
  getHistory,
  getInvitation,
  getInvitationUrl,
  getMeeting,
  getMyMeetings,
  getProfile,
  getProfileIdSuggestion as getProfileIdSuggestionApi,
  getSession,
  CreateMeetingResponse,
  updateMeeting as updateMeetingApi,
  updateProfile as updateProfileById,
  getTranscriptShare,
  createTranscriptShare,
  getElasticSearchResults,
  fetchRecordingShare,
  getAttendeesInfo,
  ElasticSearchPayload,
  getSmartNotes,
  getTranscriptions,
  getMeetingAnalytics,
  updateMeetingSessionData,
  getChats
} from '../../../../models/Meeting';
import { ConcurrentMeetingError } from '../../../../models/Errors';
import * as meetingService from '../../../services/MeetingService';
import { apiErrorThunk, category, meetingConcurrentError, meetingCreateError } from '../core/errors/errorActions';
import logger from '../../../services/LoggerService';
import * as Selector from '../../selectors';
import { ActionsUnion, Thunk, createAction } from '../../type-helpers';
import {
  itemTypeHasMeetingInformation,
  DiagnosticsSession,
  Note,
  SessionUpdates,
  ChatListItem
} from './meetingsReducer';
import uuid from 'uuid';
import { OptionalPagingAttributes } from '../../../../types/pulse-web';
import { MeetingType } from '../scheduler/schedulerReducer';
import { showToastNotification } from '../core/notifications/notificationsActions';
import {
  InvitationTypeInvalid,
  MeetingRoomReady,
  EditButtonText
} from '../../../components/Notifications/Notifications';
import { InvitationTypes } from '../../../components/Meetings/MeetingDetails/InvitationSelector';
import { EMeetingAssetTypes } from '../../../components/Meetings/MeetingsHistory/MeetingAssetsDownload/MeetingAssetsDownload';
import { ElasticSearchTab } from '../../../components/GlobalSearch/GlobalSearchDetailsView';
import { ImpromptuMeeting, isImpromptuMeeting, Meeting, Profile, SelectableItemType, Session } from './MeetingsTypes';
import { showEditMeeting } from '../scheduler/schedulerActions';
import { SchedulerTabs } from '../../../components/Meetings/MeetingScheduler/MeetingScheduler';
import { extractPublicChat } from './meetingsOperations';

export enum ActionTypes {
  SHOW_MEETING_IN_SESSION_ERROR = 'SHOW_MEETING_IN_SESSION_ERROR',
  HIDE_MEETING_IN_SESSION_ERROR = 'HIDE_MEETING_IN_SESSION_ERROR',

  PROFILE_FETCHING = 'PROFILE_FETCHING',
  PROFILE_FETCHED = 'PROFILE_FETCHED',
  PROFILE_FETCH_ERROR = 'PROFILE_FETCH_ERROR',

  MEETINGS_UPDATING = 'MEETINGS_UPDATING',
  MEETINGS_UPDATED = 'MEETINGS_UPDATED',
  MEETINGS_UPDATE_ERROR = 'MEETINGS_UPDATE_ERROR',

  MEETING_CREATED = 'MEETING_CREATED',
  MEETING_UPDATED = 'MEETING_UPDATED',
  MEETING_INVITATION_CHANGED = 'MEETING_INVITATION_CHANGED',

  MEETINGS_HISTORY_UPDATING = 'MEETINGS_HISTORY_UPDATING',
  MEETINGS_HISTORY_UPDATED = 'MEETINGS_HISTORY_UPDATED',
  MEETINGS_HISTORY_ERROR = 'MEETINGS_HISTORY_ERROR',

  SESSION_UPDATED = 'SESSION_UPDATED',

  JOIN_MEETING = 'JOIN_MEETING',
  START_MEETING = 'START_MEETING',
  MEET_NOW = 'MEET_NOW',
  MEETING_SESSION_DELETED = 'MEETING_SESSION_DELETED',
  INVITATION_COPIED = 'INVITATION_COPIED',

  FETCHING_INVITATION = 'FETCHING_INVITATION',
  FETCHED_INVITATION = 'FETCHED_INVITATION',
  FETCHING_INVITATION_ERROR = 'FETCHING_INVITATION_ERROR',
  REMOVE_INVITATIONS = 'REMOVE_INVITATIONS',

  MEETING_SELECTED = 'MEETING_SELECTED',

  MEETING_SCHEDULE_HIDE = 'MEETING_SCHEDULE_HIDE',

  SHOW_DELETE_MEETING = 'SHOW_DELETE_MEETING',
  HIDE_DELETE_MEETING = 'HIDE_DELETE_MEETING',
  MEETING_DELETED = 'MEETING_DELETED',

  SHOW_PERSONALIZE_MEETING = 'SHOW_PERSONALIZE_MEETING',
  HIDE_PERSONALIZE_MEETING = 'HIDE_PERSONALIZE_MEETING',

  SHOW_PROFILE_PREVIEW = 'SHOW_PROFILE_PREVIEW',
  HIDE_PROFILE_PREVIEW = 'HIDE_PROFILE_PREVIEW',

  PROFILE_ID_SUGGESTING = 'PROFILE_ID_SUGGESTING',
  PROFILE_ID_SUGGESTED = 'PROFILE_ID_SUGGESTED',
  PROFILE_ID_SUGGEST_ERROR = 'PROFILE_ID_SUGGEST_ERROR',

  SHOW_CREATE_PERSONAL_MEETING = 'SHOW_CREATE_PERSONAL_MEETING',
  HIDE_CREATE_PERSONAL_MEETING = 'HIDE_CREATE_PERSONAL_MEETING',

  PROFILE_UPDATING = 'PROFILE_UPDATING',
  PROFILE_UPDATED = 'PROFILE_UPDATED',
  PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR',

  PROFILE_CREATING = 'PROFILE_CREATING',
  PROFILE_CREATED = 'PROFILE_CREATED',
  PROFILE_CREATE_ERROR = 'PROFILE_CREATE_ERROR',

  SHOW_ERROR_WINDOW = 'SHOW_ERROR_WINDOW',
  HIDE_ERROR_WINDOW = 'HIDE_ERROR_WINDOW',

  RECORDING_SESSION_OPENED = 'RECORDING_SESSION_OPENED',
  RECORDING_SESSION_DOWNLOADED = 'RECORDING_SESSION_DOWNLOADED',
  RECORDING_SESSION_DELETED = 'RECORDING_SESSION_DELETED',
  STANDALONE_PLAYER_URL_COPIED = 'STANDALONE_PLAYER_URL_COPIED',

  DIAGNOSTICS_OPENED = 'DIAGNOSTICS_OPENED',

  TRANSCRIPT_SHARE_UPDATED = 'TRANSCRIPT_SHARE_UPDATED',
  FETCHING_TRANSCRIPTS_SHARE = 'FETCHING_TRANSCRIPTS_SHARE',

  FETCH_TRANSCRIPT_SEARCH_LIST = 'FETCH_TRANSCRIPT_SEARCH_LIST',
  UPDATE_TRANSCRIPT_SEARCH_LIST = 'UPDATE_TRANSCRIPT_SEARCH_LIST',
  ERROR_FETCHING_ELASTIC_SEARCH_LIST = 'ERROR_FETCHING_ELASTIC_SEARCH_LIST',
  FETCHING_ELASTIC_SEARCH_LIST = 'FETCHING_ELASTIC_SEARCH_LIST',

  OPEN_RECORDING_ELASTIC_SEARCH = 'OPEN_RECORDING_ELASTIC_SEARCH',
  ELASTIC_SEARCH_USED = 'ELASTIC_SEARCH_USED',
  UPDATE_ELASTIC_SEARCH_SESSION = 'UPDATE_ELASTIC_SEARCH_SESSION',
  FETCH_ELASTIC_SEARCH_SESSION = 'FETCH_ELASTIC_SEARCH_SESSION',

  FETCH_ATTENDEE_SESSION_LIST = 'FETCH_ATTENDEE_SESSION_LIST',
  UPDATE_ATTENDEE_SESSION_LIST = 'UPDATE_ATTENDEE_SESSION_LIST',
  ATTENDEE_TAB_COUNT = 'ATTENDEE_TAB_COUNT',
  SELECTED_GLOBAL_SEARCH_TAB = 'SELECTED_GLOBAL_SEARCH_TAB',

  FETCH_SMART_NOTES = 'FETCH_SMART_NOTES',
  FETCH_MEETING_TRANSCRIPTIONS = 'FETCH_MEETING_TRANSCRIPTIONS',
  FETCHING_SMART_NOTES = 'FETCHING_SMART_NOTES',
  FETCHING_MEETING_TRANSCRIPTIONS = 'FETCHING_MEETING_TRANSCRIPTIONS',
  FETCHING_MEETING_ANALYTICS = 'FETCHING_MEETING_ANALYTICS',
  FETCH_MEETING_ANALYTICS = 'FETCH_MEETING_ANALYTICS',
  ELASTIC_SEARCH_TELEMETRY = 'ELASTIC_SEARCH_TELEMETRY',
  SESSION_DELETE_IN_PROCESS = 'SESSION_DELETE_IN_PROCESS',
  DOWNLOAD_MEETING_ASSETS = 'DOWNLOAD_MEETING_ASSETS',
  SEARCH_SELECTED_TAB = 'SEARCH_SELECTED_TAB',
  UPDATING_MEETING_SESSION_DATA = 'UPDATING_MEETING_SESSION_DATA',
  UPDATE_MEETING_SESSION_DATA = 'UPDATE_MEETING_SESSION_DATA',
  UPDATING_MEETING_SESSION_DATA_ERROR = 'UPDATING_MEETING_SESSION_DATA_ERROR',
  MEETING_TITLE_EDITED = 'MEETING_TITLE_EDITED',
  FETCHING_CHATS = 'FETCHING_CHATS',
  FETCH_CHATS = 'FETCH_CHATS'
}

const actions = {
  showMeetingInSessionError: (meetingId: string, action: 'edit' | 'delete') =>
    createAction(ActionTypes.SHOW_MEETING_IN_SESSION_ERROR, { meetingId, action }),
  hideMeetingInSessionError: () => createAction(ActionTypes.HIDE_MEETING_IN_SESSION_ERROR),

  // The profile actions handle getting the information for the G2MM/ Personal Meeting Room Card
  profileFetching: () => createAction(ActionTypes.PROFILE_FETCHING),
  profileFetched: (profile?: Profile) => createAction(ActionTypes.PROFILE_FETCHED, { profile }),
  profileFetchError: () => createAction(ActionTypes.PROFILE_FETCH_ERROR),

  meetingsUpdating: () => createAction(ActionTypes.MEETINGS_UPDATING),
  meetingsUpdated: (meetings: Omit<Meeting, 'inSession'>[]) => createAction(ActionTypes.MEETINGS_UPDATED, { meetings }),
  meetingsUpdateError: () => createAction(ActionTypes.MEETINGS_UPDATE_ERROR),

  meetingInvitationChanged: (meetingId: string, invitationType: string) =>
    createAction(ActionTypes.MEETING_INVITATION_CHANGED, { meetingId, invitationType }),

  meetingsHistoryUpdating: (append = false) => createAction(ActionTypes.MEETINGS_HISTORY_UPDATING, { append }),
  meetingsHistoryUpdated: (
    history: { sessions: Session[] } & OptionalPagingAttributes,
    append = false,
    flush = false
  ) => createAction(ActionTypes.MEETINGS_HISTORY_UPDATED, { history, append, flush }),
  meetingsHistoryError: () => createAction(ActionTypes.MEETINGS_HISTORY_ERROR),

  sessionUpdated: (session: Session) => createAction(ActionTypes.SESSION_UPDATED, { session }),

  joinMeeting: (meetingId: string, sessionTrackingId: string) =>
    createAction(ActionTypes.JOIN_MEETING, { meetingId, sessionTrackingId }),
  startMeeting: (meetingId: string, meetingType: SelectableItemType, sessionTrackingId: string) =>
    createAction(ActionTypes.START_MEETING, { meetingId, meetingType, sessionTrackingId }),
  meetNow: (sessionTrackingId: string) => createAction(ActionTypes.MEET_NOW, { sessionTrackingId }),
  meetingSessionDeleted: (meetingId: string, removeMeeting?: boolean) =>
    createAction(ActionTypes.MEETING_SESSION_DELETED, { meetingId, removeMeeting }),
  invitationCopied: () => createAction(ActionTypes.INVITATION_COPIED),

  fetchingInvitation: (meetingId: string) => createAction(ActionTypes.FETCHING_INVITATION, { meetingId }),
  fetchedInvitation: (meetingId: string, invitationHtml: string, invitationText: string, invitationUrl: string) =>
    createAction(ActionTypes.FETCHED_INVITATION, { meetingId, invitationHtml, invitationText, invitationUrl }),
  fetchingInvitationError: (meetingId: string) => createAction(ActionTypes.FETCHING_INVITATION_ERROR, { meetingId }),
  removeAllCachedInvitations: () => createAction(ActionTypes.REMOVE_INVITATIONS),

  hideScheduleMeeting: () => createAction(ActionTypes.MEETING_SCHEDULE_HIDE),

  meetingCreated: (meeting: Meeting | Omit<ImpromptuMeeting, 'organizer'>) =>
    createAction(ActionTypes.MEETING_CREATED, { meeting }),
  meetingUpdated: (meeting: Meeting) => createAction(ActionTypes.MEETING_UPDATED, { meeting }),

  showDeleteMeeting: (meetingId: string, canDelete: boolean) =>
    createAction(ActionTypes.SHOW_DELETE_MEETING, { meetingId, canDelete }),
  hideDeleteMeeting: () => createAction(ActionTypes.HIDE_DELETE_MEETING),
  meetingDeleted: (meetingId: string, itemType?: SelectableItemType.IMPROMPTU) =>
    createAction(ActionTypes.MEETING_DELETED, { meetingId, itemType }),

  showPersonalizeMeeting: () => createAction(ActionTypes.SHOW_PERSONALIZE_MEETING),
  hidePersonalizeMeeting: () => createAction(ActionTypes.HIDE_PERSONALIZE_MEETING),
  profileUpdating: () => createAction(ActionTypes.PROFILE_UPDATING),
  profileUpdated: () => createAction(ActionTypes.PROFILE_UPDATED),
  profileUpdateError: (errorCode?: number, errorDescription?: string) =>
    createAction(ActionTypes.PROFILE_UPDATE_ERROR, { errorCode, errorDescription }),

  profileCreating: () => createAction(ActionTypes.PROFILE_CREATING),
  profileCreated: () => createAction(ActionTypes.PROFILE_CREATED),
  profileCreateError: (errorCode?: number, errorDescription?: string) =>
    createAction(ActionTypes.PROFILE_CREATE_ERROR, { errorCode, errorDescription }),

  showErrorWindow: (errorCode?: number, errorDescription?: string | { code: string; field: string; value: string }) =>
    createAction(ActionTypes.SHOW_ERROR_WINDOW, { errorCode, errorDescription }),
  hideErrorWindow: () => createAction(ActionTypes.HIDE_ERROR_WINDOW),

  showProfilePreview: (theme: string, roomDisplayName?: string) =>
    createAction(ActionTypes.SHOW_PROFILE_PREVIEW, { theme, roomDisplayName }),
  hideProfilePreview: () => createAction(ActionTypes.HIDE_PROFILE_PREVIEW),

  profileIdSuggesting: () => createAction(ActionTypes.PROFILE_ID_SUGGESTING),
  profileIdSuggested: (profileId: string) => createAction(ActionTypes.PROFILE_ID_SUGGESTED, profileId),
  profileIdSuggestError: () => createAction(ActionTypes.PROFILE_ID_SUGGEST_ERROR),

  showCreatePersonalMeeting: () => createAction(ActionTypes.SHOW_CREATE_PERSONAL_MEETING),
  hideCreatePersonalMeeting: () => createAction(ActionTypes.HIDE_CREATE_PERSONAL_MEETING),

  recordingSessionOpened: () => createAction(ActionTypes.RECORDING_SESSION_OPENED),
  recordingSessionDownloaded: (meetingId: string, recordingId: string) =>
    createAction(ActionTypes.RECORDING_SESSION_DOWNLOADED, { meetingId, recordingId }),
  recordingSessionDeleted: (sessionId: string, meetingId: string, recordingId: string) =>
    createAction(ActionTypes.RECORDING_SESSION_DELETED, { sessionId, meetingId, recordingId }),
  standalonePlayerUrlCopied: (meetingId: string, recordingId: string) =>
    createAction(ActionTypes.STANDALONE_PLAYER_URL_COPIED, { meetingId, recordingId }),
  meetingSelected: (itemType: SelectableItemType, id: string | null = null) =>
    createAction(ActionTypes.MEETING_SELECTED, { itemType, id }),

  diagnosticsOpened: (type: 'live' | 'historical') => createAction(ActionTypes.DIAGNOSTICS_OPENED, type),
  fetchingTranscriptShare: (flag: boolean) => createAction(ActionTypes.FETCHING_TRANSCRIPTS_SHARE, { flag }),

  transcriptShareUpdated: (
    sessionId: string,
    {
      isPublic,
      url,
      linkExpired,
      shareKey
    }: { isPublic: boolean; url: string; linkExpired: boolean; shareKey: string },
    error: boolean
  ) => createAction(ActionTypes.TRANSCRIPT_SHARE_UPDATED, { sessionId, isPublic, url, error, linkExpired, shareKey }),

  fetchingElasticSearch: () => createAction(ActionTypes.FETCHING_ELASTIC_SEARCH_LIST),
  errorInFetchingElasticSearch: () => createAction(ActionTypes.ERROR_FETCHING_ELASTIC_SEARCH_LIST),
  fetchTranscriptSearchList: (elasticSearchResult: any, tab: string, retain: any) =>
    createAction(ActionTypes.FETCH_TRANSCRIPT_SEARCH_LIST, { elasticSearchResult, tab, retain }),
  updateTranscriptSearchList: (elasticSearchResult: any, tab: string) =>
    createAction(ActionTypes.UPDATE_TRANSCRIPT_SEARCH_LIST, { elasticSearchResult, tab }),

  fetchAttendeeSessionList: (elasticSearchResult: any, tab: string, attendeeName: string) =>
    createAction(ActionTypes.FETCH_ATTENDEE_SESSION_LIST, { elasticSearchResult, tab, attendeeName }),
  updateAttendeeSessionList: (elasticSearchResult: any, tab: string, attendeeName: string) =>
    createAction(ActionTypes.UPDATE_ATTENDEE_SESSION_LIST, { elasticSearchResult, tab, attendeeName }),

  openElasticResultRecording: (sessionId: string, criteria: string, searchText: string) =>
    createAction(ActionTypes.OPEN_RECORDING_ELASTIC_SEARCH, { sessionId, criteria, searchText }),
  elasticSearchUsed: (searchText: string, criteria: string) =>
    createAction(ActionTypes.ELASTIC_SEARCH_USED, { searchText, criteria }),
  updateElasticSearchSession: (session: any) => createAction(ActionTypes.UPDATE_ELASTIC_SEARCH_SESSION, session),
  fetchingElasticSearchSession: (fetching: boolean) => createAction(ActionTypes.FETCH_ELASTIC_SEARCH_SESSION, fetching),
  trackAttendeeCount: (attendeeCount: boolean) => createAction(ActionTypes.ATTENDEE_TAB_COUNT, { attendeeCount }),
  selectedGlobalSearchTab: (tab: string, attendeeName?: string) =>
    createAction(ActionTypes.SELECTED_GLOBAL_SEARCH_TAB, { tab, attendeeName }),

  smartNotesUpdate: (notes: Note[]) => createAction(ActionTypes.FETCH_SMART_NOTES, notes),
  meetingTranscriptions: (transcriptions: any) =>
    createAction(ActionTypes.FETCH_MEETING_TRANSCRIPTIONS, transcriptions),
  fetchingSmartNotes: (flag: boolean) => createAction(ActionTypes.FETCHING_SMART_NOTES, { flag }),
  fetchingChats: (flag: boolean) => createAction(ActionTypes.FETCHING_CHATS, { flag }),
  fetchChatsUpdate: (chats: ChatListItem[]) => createAction(ActionTypes.FETCH_CHATS, chats),

  fetchingMeetingTranscripts: (flag: boolean) => createAction(ActionTypes.FETCHING_MEETING_TRANSCRIPTIONS, { flag }),
  fetchingMeetingAnalytics: (analytics: boolean) => createAction(ActionTypes.FETCHING_MEETING_ANALYTICS, { analytics }),
  meetingAnalytics: (analytics: any) => createAction(ActionTypes.FETCH_MEETING_ANALYTICS, { analytics }),
  telemetryElasticSearch: (sa_state: string) => createAction(ActionTypes.ELASTIC_SEARCH_TELEMETRY, { sa_state }),
  sessionDeleteInProcess: (deleteStatus: boolean) =>
    createAction(ActionTypes.SESSION_DELETE_IN_PROCESS, { deleteStatus }),
  telemetryDownloadAssets: (sa_state: string, assetName: EMeetingAssetTypes[keyof EMeetingAssetTypes]) =>
    createAction(ActionTypes.DOWNLOAD_MEETING_ASSETS, { sa_state, assetName }),
  telemetryElasticSearchTab: (sa_state: string, tabName: ElasticSearchTab[keyof ElasticSearchTab]) =>
    createAction(ActionTypes.SEARCH_SELECTED_TAB, { sa_state, tabName }),
  updatingMeetingSessionData: (sessionUpdateStatus: boolean) =>
    createAction(ActionTypes.UPDATING_MEETING_SESSION_DATA, { sessionUpdateStatus }),
  updateMeetingSessionData: (sessionId: string, updatedSession: SessionUpdates) =>
    createAction(ActionTypes.UPDATE_MEETING_SESSION_DATA, { sessionId, updatedSession }),
  updatingMeetingSessionDataError: (error: boolean) =>
    createAction(ActionTypes.UPDATING_MEETING_SESSION_DATA_ERROR, { error }),
  meetingTitleEdited: (view: string) => createAction(ActionTypes.MEETING_TITLE_EDITED, { view })
};

export default actions;
export type Actions = ActionsUnion<typeof actions>;

export const getNewSessionTrackingId = () => {
  return `g2mhub-${uuid.v4()}`;
};

const openBrowserTabForMeeting = (meetingId: string, windowsCreated?: Window | null) => {
  if (!windowsCreated) {
    return window.open('about:blank', `Meeting-${meetingId}`);
  }
  return windowsCreated;
};

export const selectMeeting = (
  itemType: SelectableItemType,
  id: string | null = null,
  meetingCardSelected?: boolean
): Thunk => async (dispatch, getState) => {
  dispatch(actions.meetingSelected(itemType, id));
  if (id !== null) {
    if (itemTypeHasMeetingInformation(itemType)) {
      /* fetch meeting updates */
      try {
        let meeting: any = await getMeeting(id, true);
        meeting = {
          ...meeting,
          invitationType: meeting.invitationType || InvitationTypes.DEFAULT
        };
        dispatch(actions.meetingUpdated(meeting));
      } catch (err) {
        if (itemType === SelectableItemType.IMPROMPTU && err.status === 404) {
          dispatch(actions.meetingDeleted(id, SelectableItemType.IMPROMPTU));
          dispatch(actions.meetingSelected(itemType, null));
          return;
        }
      }
      /* fetch meeting invitation */
      const state = getState();
      const meetingInvitation = Selector.getInvitationForMeetingId(state);
      if (!meetingInvitation) {
        dispatch(fetchMeetingInvitation(id));
      }
    } else if (itemType === SelectableItemType.SESSION) {
      dispatch(fetchSession(id, meetingCardSelected));
    }
  }
};

export const changeInvitation = (meetingId: string, invitation: string): Thunk => async (dispatch) => {
  dispatch(actions.fetchingInvitation(meetingId));
  dispatch(actions.meetingInvitationChanged(meetingId, invitation));
  try {
    await updateMeetingApi(meetingId, {
      invitationType: invitation
    });
  } catch (e) {
    if (e.status === 400) {
      dispatch(
        showToastNotification(InvitationTypeInvalid(), {
          type: 'danger',
          timeout: 10000, //ms,
          hideOkButton: true,
          buttons: [
            {
              text: EditButtonText(),
              action: () => dispatch(showEditMeeting(meetingId, SchedulerTabs.audio))
            }
          ]
        })
      );
    } else {
      dispatch(actions.showErrorWindow(e.status, e.message));
    }
    dispatch(actions.meetingInvitationChanged(meetingId, InvitationTypes.DEFAULT));
  }
  return dispatch(fetchMeetingInvitation(meetingId));
};

export const fetchSession = (sessionId: string, meetingCardSelected?: boolean): Thunk => async (dispatch, getState) => {
  const session = getState().meetings.history.sessions.byId[sessionId];
  if (!meetingCardSelected) {
    return;
  }
  let attendee;

  try {
    attendee = await getAttendeesInfo(session.sessionId);
  } catch (e) {
    attendee = undefined;
  }

  if (session && session.recording && session.recording.stored && !session.recording.downloadUrl) {
    const sessionWithDownloadLink: Session = await getSession(sessionId);
    const updatedSession = {
      ...sessionWithDownloadLink,
      shareRecording: {
        scope: [],
        sharedResources: []
      },
      attendees: getAttendeesWithLocation(attendee, sessionWithDownloadLink)
    };
    return dispatch(actions.sessionUpdated(updatedSession));
  }
  let shareRecording;
  if (session && session.recording) {
    try {
      shareRecording = await fetchRecordingShare(sessionId);
    } catch (e) {
      shareRecording = {
        shareRecording: undefined,
        sharedResources: [],
        expires: null
      };
    }
  }
  const sessionData = {
    ...session,
    shareRecording: shareRecording
      ? {
          scope: shareRecording.scope,
          sharedResources: shareRecording.sharedResources,
          expires: shareRecording.expires
        }
      : undefined,
    attendees: getAttendeesWithLocation(attendee, session)
  };
  return dispatch(actions.sessionUpdated(sessionData));
};

export const getAttendeesWithLocation = (meetingDiagnosticsData: DiagnosticsSession, session: Session) => {
  if (session && session.attendees && session.attendees.length > 0) {
    return session.attendees.map((item) => {
      const participant = meetingDiagnosticsData?.participants?.find(
        (attendee) => attendee.name === item.name && item.email === attendee.email
      );
      if (!participant) {
        return item;
      }
      return {
        ...item,
        location: participant.locations,
        participantId: participant.participantId
      };
    });
  }
  return [];
};

export const fetchSingleSession = (sessionId: string): Thunk => async (dispatch, getState) => {
  dispatch(actions.fetchingElasticSearchSession(true));
  const session = getState().meetings.history.sessions.byId[sessionId];

  let attendee;
  let shareRecording;
  let sessionData;

  try {
    attendee = await getAttendeesInfo(sessionId);
  } catch {
    attendee = undefined;
  }

  try {
    shareRecording = await fetchRecordingShare(sessionId);
  } catch {
    shareRecording = {};
  }

  if (session) {
    sessionData = {
      ...session,
      attendees: getAttendeesWithLocation(attendee, session),
      shareRecording: {
        scope: shareRecording.scope,
        sharedResources: shareRecording.sharedResources,
        expires: shareRecording.expires
      }
    };
    dispatch(actions.fetchingElasticSearchSession(false));
    return dispatch(actions.updateElasticSearchSession(sessionData));
  }

  try {
    const sessionDetails: Session = await getSession(sessionId);
    sessionData = {
      ...sessionDetails,
      attendees: getAttendeesWithLocation(attendee, sessionDetails),
      shareRecording: {
        scope: shareRecording.scope,
        sharedResources: shareRecording.sharedResources,
        expires: shareRecording.expires
      }
    };
    dispatch(actions.fetchingElasticSearchSession(false));
    return dispatch(actions.updateElasticSearchSession(sessionData));
  } catch {
    dispatch(actions.fetchingElasticSearchSession(false));
    return dispatch(actions.updateElasticSearchSession(null));
  }
};

export const fetchProfile = (): Thunk => async (dispatch, getState) => {
  dispatch(actions.profileFetching());
  try {
    const profile = await getProfile();
    if (profile) {
      const { selectedId, selectedItemType } = getState().meetings;
      if (!selectedId && selectedItemType === SelectableItemType.PERSONAL) {
        dispatch(selectMeeting(SelectableItemType.PERSONAL, profile.meetingId));
      }
      return dispatch(actions.profileFetched(profile));
    }
    return dispatch(actions.profileFetched());
  } catch (err) {
    logger.error('fetchProfile.error', 'err=', err);
    dispatch(actions.profileFetchError());
    return dispatch(
      apiErrorThunk({
        name: 'profile-updated-error',
        error: err,
        category: category.profile
      })
    );
  }
};

export const fetchMyMeetings = (): Thunk => async (dispatch) => {
  try {
    dispatch(actions.meetingsUpdating());
    const meetings = await getMyMeetings();
    return dispatch(actions.meetingsUpdated(meetings as Omit<Meeting, 'inSession'>[]));
  } catch (err) {
    logger.error('fetchMyMeetings', 'err=', err);
    dispatch(actions.meetingsUpdateError());
    dispatch(
      apiErrorThunk({
        name: 'meeting-list-error',
        error: err,
        category: category.meeting
      })
    );

    return null;
  }
};

export const resetElasticFetchResults = (retain?: any, calendarSelected?: boolean): Thunk => (dispatch) => {
  const resetData = {
    payload: [],
    fromTime: 0,
    size: 0,
    totalHits: -1,
    resultsFetched: 'fetching'
  };
  const retainData = {
    query: '',
    interval: {
      toTime: retain ? retain?.interval?.toTime : undefined,
      fromTime: retain ? retain?.interval?.fromTime : undefined
    },
    tab: retain ? retain.tab : '',
    dateSelected: calendarSelected
  };
  dispatch(actions.fetchTranscriptSearchList(resetData, 'transcriptList', retainData));
  dispatch(actions.fetchTranscriptSearchList(resetData, 'attendeeList', retainData));
  dispatch(actions.fetchTranscriptSearchList(resetData, 'titleList', retainData)); // resetting the data before every new search
};

export const fetchAttendeeTabResults = async (
  payload: ElasticSearchPayload,
  offset: number,
  dispatch: any,
  textSearch: string,
  selectedTab: string,
  attendeeName: string | undefined,
  attendeeOffsetValue: number | undefined,
  lastOneMonthMeetings: boolean
) => {
  const size = selectedTab === 'ATTENDEE' && attendeeName && !attendeeOffsetValue ? 30 : 0;
  const offsetValue = attendeeOffsetValue ? 0 : offset;
  const splitAttendeeKeyword = textSearch.replace(/  +/g, ' ').split(' ');

  const attendeeList: string[] = [];
  const keywordsList: string[] = [];
  splitAttendeeKeyword.forEach((item) => {
    if (item.indexOf('@') === -1) {
      keywordsList.push(item);
    } else {
      attendeeList.push(item + '*');
    }
  });
  let joined;
  if (keywordsList.length) {
    joined = keywordsList.join(' ') + '*';
  }

  const search = joined ? [...attendeeList, joined] : attendeeList;
  if (attendeeName) {
    search.push('@' + attendeeName);
  }
  const load = {
    ...payload,
    textSearch: {
      ['ATTENDEE']: search
    }
  };

  if (!attendeeName) {
    load.aggregations = [
      {
        fieldName: 'participantName',
        size: (attendeeOffsetValue || 0) + 31
      }
    ];
  }
  try {
    const response = await getElasticSearchResults(load, offsetValue, size);
    let attendeeTabResults;
    if (!attendeeName) {
      let participants;
      const participantsLength =
        response.aggregations.participants.participantName.buckets.length - (attendeeOffsetValue || 0);
      if (participantsLength <= 30) {
        participants = response.aggregations.participants.participantName.buckets;
        dispatch(actions.trackAttendeeCount(false));
      } else {
        response.aggregations.participants.participantName.buckets.pop();
        participants = response.aggregations.participants.participantName.buckets;
        dispatch(actions.trackAttendeeCount(true));
      }
      attendeeTabResults = {
        payload: participants.splice(attendeeOffsetValue || 0, participants.length),
        from: response.from,
        size: response.size,
        totalHits: response.totalHits,
        hasNext: response.totalHits,
        resultsFetched: 'done'
      };
    }
    const retain = {
      query: textSearch,
      interval: { fromTime: payload.fromTime, toTime: payload.toTime },
      tab: selectedTab,
      dateSelected: lastOneMonthMeetings
    };
    if (offset) {
      if (attendeeName) {
        dispatch(actions.updateAttendeeSessionList(response, 'attendeeList', attendeeName));
      } else {
        dispatch(actions.updateTranscriptSearchList(attendeeTabResults, 'attendeeList'));
      }
    } else {
      if (attendeeName) {
        dispatch(actions.fetchAttendeeSessionList(response, 'attendeeList', attendeeName));
      } else {
        dispatch(actions.fetchTranscriptSearchList(attendeeTabResults, 'attendeeList', retain));
      }
    }
  } catch {
    dispatch(actions.errorInFetchingElasticSearch());
  }
};

export const fetchTranscriptTabResults = async (
  payload: ElasticSearchPayload,
  offset: number,
  dispatch: any,
  textSearch: string,
  selectedTab: string,
  lastOneMonthMeetings: boolean
) => {
  const size = selectedTab === 'TRANSCRIPT' ? 30 : 0;
  const load = {
    ...payload,
    textSearch: {
      ['TRANSCRIPT']: [textSearch + '*']
    }
  };
  try {
    const transcriptTabResults = await getElasticSearchResults(load, offset, size);
    transcriptTabResults.resultsFetched = 'done';
    const retain = {
      query: textSearch,
      interval: { fromTime: payload.fromTime, toTime: payload.toTime },
      tab: selectedTab,
      dateSelected: lastOneMonthMeetings
    };
    if (offset) {
      dispatch(actions.updateTranscriptSearchList(transcriptTabResults, 'transcriptList'));
    } else {
      dispatch(actions.fetchTranscriptSearchList(transcriptTabResults, 'transcriptList', retain));
    }
  } catch {
    dispatch(actions.errorInFetchingElasticSearch());
  }
};

export const fetchTitleTabResults = async (
  payload: ElasticSearchPayload,
  offset: number,
  dispatch: any,
  textSearch: string,
  selectedTab: string,
  lastOneMonthMeetings: boolean
) => {
  const size = selectedTab === 'TITLE' ? 30 : 0;
  const load = {
    ...payload,
    textSearch: {
      ['TITLE']: [textSearch + '*']
    }
  };
  try {
    const titleTabResults = await getElasticSearchResults(load, offset, size);
    titleTabResults.resultsFetched = 'done';
    const retain = {
      query: textSearch,
      interval: { fromTime: payload.fromTime, toTime: payload.toTime },
      tab: selectedTab,
      dateSelected: lastOneMonthMeetings
    };
    if (offset) {
      dispatch(actions.updateTranscriptSearchList(titleTabResults, 'titleList'));
    } else {
      dispatch(actions.fetchTranscriptSearchList(titleTabResults, 'titleList', retain));
    }
  } catch {
    dispatch(actions.errorInFetchingElasticSearch());
  }
};

export const fetchElasticSearchResults = (
  textSearch: string,
  fromTime: number,
  selectedTab: string,
  offset: number = 0,
  toTime: number,
  lastOneMonthMeetings: boolean,
  attendeeName?: string,
  attendeeOffsetValue?: number
): Thunk => async (dispatch, getState) => {
  const user = getState().core.user;
  try {
    if (user.hasLoaded) {
      const payload = {
        textSearch: {
          [selectedTab]: [textSearch + '*']
        },
        userKey: user.key,
        accountKey: user.accountKey,
        product: 'g2m',
        fromTime,
        toTime,
        isCbr: true
      };

      dispatch(actions.elasticSearchUsed(textSearch, selectedTab));
      dispatch(actions.fetchingElasticSearch());
      if (!offset && !attendeeName) {
        dispatch(resetElasticFetchResults());
        dispatch(actions.fetchingElasticSearch());
        await Promise.all([
          fetchTitleTabResults(payload, offset, dispatch, textSearch, selectedTab, lastOneMonthMeetings),
          fetchTranscriptTabResults(payload, offset, dispatch, textSearch, selectedTab, lastOneMonthMeetings),
          fetchAttendeeTabResults(payload, offset, dispatch, textSearch, selectedTab, '', 0, lastOneMonthMeetings)
        ]);
      } else if (!offset && attendeeName) {
        await fetchAttendeeTabResults(
          payload,
          offset,
          dispatch,
          textSearch,
          selectedTab,
          attendeeName,
          0,
          lastOneMonthMeetings
        );
      } else if (selectedTab === 'TITLE') {
        await fetchTitleTabResults(payload, offset, dispatch, textSearch, selectedTab, lastOneMonthMeetings);
      } else if (selectedTab === 'TRANSCRIPT') {
        await fetchTranscriptTabResults(payload, offset, dispatch, textSearch, selectedTab, lastOneMonthMeetings);
      } else if (selectedTab === 'ATTENDEE') {
        await fetchAttendeeTabResults(
          payload,
          offset,
          dispatch,
          textSearch,
          selectedTab,
          attendeeName,
          attendeeOffsetValue,
          lastOneMonthMeetings
        );
      }
    }
  } catch (err) {
    dispatch(actions.errorInFetchingElasticSearch());
  }
};

export const triggerTelemetryElasticSearch = (
  saState: string,
  assetName?: EMeetingAssetTypes[keyof EMeetingAssetTypes],
  tabName?: ElasticSearchTab[keyof ElasticSearchTab]
): Thunk => (dispatch) => {
  if (assetName) {
    dispatch(actions.telemetryDownloadAssets(saState, assetName));
  } else if (tabName) {
    dispatch(actions.telemetryElasticSearchTab(saState, tabName));
  } else {
    dispatch(actions.telemetryElasticSearch(saState));
  }
};

export const fetchMeetingHistory = (
  page = 0,
  from?: Date,
  to?: Date,
  options: { append?: boolean; flush?: boolean } = {}
): Thunk => async (dispatch, getState) => {
  try {
    dispatch(actions.meetingsHistoryUpdating(options.append));
    const history = await getHistory({ page, size: 200, from, to });
    dispatch(actions.meetingsHistoryUpdated(history, options.append, options.flush));
    const { selectedId, selectedItemType } = getState().meetings;
    if (selectedId && selectedItemType === SelectableItemType.SESSION) {
      /* fetches download link, if session has recording */
      dispatch(fetchSession(selectedId));
    }
  } catch (err) {
    logger.error('fetchMySessions.error', 'err=', err);
    dispatch(actions.meetingsHistoryError());

    return dispatch(
      apiErrorThunk({
        name: 'meetings-history-error',
        error: err,
        category: category.meeting
      })
    );
  }
};

const isMeetingInSession = (meetingId: string): Thunk<Promise<boolean>> => async (dispatch) => {
  try {
    const meeting = await getMeeting(meetingId);
    return !!meeting.screenSharing;
  } catch (err) {
    logger.error('getMeeting', 'err=', err);
    dispatch(
      apiErrorThunk({
        name: 'meeting-in-session-error',
        error: err,
        category: category.meeting
      })
    );

    return false;
  }
};

export const join = (meetingId: string, winCreated?: Window | null): Thunk => {
  const win = openBrowserTabForMeeting(meetingId, winCreated);

  return (dispatch) => {
    const sessionTrackingId = getNewSessionTrackingId();
    dispatch(actions.joinMeeting(meetingId, sessionTrackingId));

    meetingService.joinMeeting(meetingId, { window: win, sessionTrackingId });
  };
};

const start = (meetingId: string, meetingType: SelectableItemType, winCreated?: Window | null): Thunk => {
  const win = openBrowserTabForMeeting(meetingId, winCreated);

  return (dispatch) => {
    const sessionTrackingId = getNewSessionTrackingId();

    dispatch(actions.startMeeting(meetingId, meetingType, sessionTrackingId));
    meetingService.joinMeeting(meetingId, { window: win, sessionTrackingId });
  };
};

export const startMeeting = (meetingId: string, meetingType: SelectableItemType): Thunk<Promise<void>> => {
  const win = openBrowserTabForMeeting(meetingId);

  return async (dispatch) => {
    let runningSession;
    try {
      runningSession = await getConcurrentSession();
    } catch (_error) {
      // do nothing, catches 404 that is returned when no meeting is running
    }
    if (runningSession != undefined && runningSession.meetingId != meetingId) {
      if (win?.close) {
        win.close();
      }

      dispatch(fetchMyMeetings());
      dispatch(meetingConcurrentError(new ConcurrentMeetingError()));
      return Promise.reject();
    }
    if (meetingId && (await dispatch(isMeetingInSession(meetingId)))) {
      await dispatch(fetchMyMeetings());
      dispatch(join(meetingId, win));
    } else {
      dispatch(start(meetingId, meetingType, win));
    }
    return Promise.resolve();
  };
};

export const meetNow = (): Thunk<Promise<CreateMeetingResponse>> => {
  // we need to open the window in the new tab before any async
  // otherwise browsers like Firefox and IE will block (as pop up)
  // the reference will be used later to set the correct meeting join url
  const newWindow = window.open('about:blank', 'meetnow');

  return async (dispatch) => {
    const sessionTrackingId = getNewSessionTrackingId();

    dispatch(actions.meetNow(sessionTrackingId));

    try {
      const meeting = await meetingService.meetNow({ win: newWindow, sessionTrackingId });
      dispatch(
        actions.meetingCreated({
          ...meeting,
          inSession: true,
          meetingType: MeetingType.impromptu
        })
      );
      return meeting;
    } catch (err) {
      if (newWindow) {
        newWindow.close();
      }

      dispatch(fetchMyMeetings());

      if (err instanceof ConcurrentMeetingError) {
        dispatch(meetingConcurrentError(err));
      } else {
        // err is always an instance of CreateMeetingError
        dispatch(meetingCreateError(err));
      }
      return Promise.reject();
    }
  };
};

export const fetchMeetingInvitation = (meetingId: string): Thunk => {
  return async (dispatch, getState) => {
    dispatch(actions.fetchingInvitation(meetingId));
    try {
      const state = getState();
      const localePrefix = Selector.getUserLocalPrefix(state);
      const { html, text } = await getInvitation(meetingId, localePrefix);
      const invitationUrl = getInvitationUrl(meetingId);

      return dispatch(actions.fetchedInvitation(meetingId, html, text, invitationUrl));
    } catch (error) {
      return dispatch(actions.fetchingInvitationError(meetingId));
    }
  };
};

export const showDeleteMeeting = (meetingId: string): Thunk => async (dispatch) => {
  await dispatch(actions.showDeleteMeeting(meetingId, false));

  if (meetingId && (await dispatch(isMeetingInSession(meetingId)))) {
    dispatch(actions.hideDeleteMeeting());
    return dispatch(actions.showMeetingInSessionError(meetingId, 'delete'));
  }

  return dispatch(actions.showDeleteMeeting(meetingId, true));
};

export const deleteMeeting = (meetingId: string): Thunk => {
  return async (dispatch) => {
    try {
      await deleteMeetingApi(meetingId);

      return dispatch(actions.meetingDeleted(meetingId));
    } catch (err) {
      if (err.status === 404) {
        return dispatch(actions.meetingDeleted(meetingId));
      }

      logger.error('deleteMeeting', 'err=', err);
      return dispatch(
        apiErrorThunk({
          name: 'meeting-delete-error',
          error: err,
          category: category.meeting
        })
      );
    }
  };
};

export const updateProfile = (profileId: string, profile: Profile): Thunk => async (dispatch) => {
  dispatch(actions.profileUpdating());
  try {
    const response = await updateProfileById(profileId, profile);
    if (response.status === 204) {
      dispatch(actions.hidePersonalizeMeeting());
      dispatch(actions.removeAllCachedInvitations());
      dispatch(fetchMeetingInvitation(profile.meetingId));
      dispatch(fetchProfile());
      dispatch(actions.profileUpdated());
      return dispatch(fetchMyMeetings());
    }

    return dispatch(actions.profileUpdateError(response.status, response.message || 'error'));
  } catch (e) {
    return dispatch(actions.profileUpdateError(e.status || 503, e.message || 'Service Unavailable'));
  }
};

export const createProfile = (profile: Profile, subject?: string): Thunk => async (dispatch) => {
  dispatch(actions.profileCreating());
  try {
    const response = await createNewProfile(profile);
    if (response.status === 201) {
      if (subject) {
        // for now, this is second call - in the future, this will migrated into the create profile call
        const createdProfile = response.body as Profile;
        try {
          await updateMeetingApi(createdProfile.meetingId, { subject });
        } catch (e) {
          // don't care - the user was created and the subject could be set manually later
          logger.log('createProfileSetSubjectError', 'err=', e);
        }
      }
      dispatch(actions.hidePersonalizeMeeting());
      dispatch(showToastNotification(MeetingRoomReady()));
      dispatch(fetchMyMeetings());
      dispatch(fetchProfile());
      return dispatch(actions.profileCreated());
    }

    return dispatch(actions.profileCreateError(response.status, response.message || 'error'));
  } catch (e) {
    return dispatch(actions.profileCreateError(e.status || 503, e.message || 'Service Unavailable'));
  }
};

export const getProfileIdSuggestion = (params: { firstName: string; lastName: string }): Thunk => async (dispatch) => {
  dispatch(actions.profileIdSuggesting());

  try {
    const suggestion = await getProfileIdSuggestionApi(params);
    return dispatch(actions.profileIdSuggested(suggestion));
  } catch (e) {
    return dispatch(actions.profileIdSuggestError());
  }
};

export const deleteSessionRecording = (sessionId: string, meetingId: string, recordingId: string): Thunk => {
  return async (dispatch) => {
    try {
      await deleteSessionRecordingApi(sessionId);
      logger.log(
        'deleteRecordingSession',
        'sessionId=',
        sessionId,
        'message=',
        'Delete session recording with session id'
      );
      dispatch(triggerTelemetryElasticSearch('recordingDeleted'));
      return dispatch(actions.recordingSessionDeleted(sessionId, meetingId, recordingId));
    } catch (err) {
      dispatch(actions.sessionDeleteInProcess(false));
      if (err.status === 404) {
        return dispatch(actions.recordingSessionDeleted(sessionId, meetingId, recordingId));
      }

      logger.error('deleteSessionRecordingError', 'err=', err);
      return dispatch(
        apiErrorThunk({
          name: 'recording-session-delete-error',
          error: err,
          category: category.meeting
        })
      );
    }
  };
};

export const endMeeting = (meeting: Meeting): Thunk => async (dispatch) => {
  const removeMeeting = isImpromptuMeeting(meeting);
  try {
    await deleteSession(meeting);
    return dispatch(actions.meetingSessionDeleted(meeting.meetingId, removeMeeting));
  } catch (error) {
    if (error.status === 404) {
      return dispatch(actions.meetingSessionDeleted(meeting.meetingId, removeMeeting));
    }

    logger.error('deleteMeetingSession', 'err=', error);
    return dispatch(
      apiErrorThunk({
        name: 'meeting-session-delete-error',
        error,
        category: category.meeting
      })
    );
  }
};

export const fetchTranscriptShare = (sessionId: string): Thunk => async (dispatch) => {
  let share;
  try {
    dispatch(actions.fetchingTranscriptShare(true));
    share = await getTranscriptShare(sessionId);
    dispatch(actions.fetchingTranscriptShare(false));
    return dispatch(actions.transcriptShareUpdated(sessionId, share, false));
  } catch (err) {
    dispatch(actions.fetchingTranscriptShare(false));
    return dispatch(
      actions.transcriptShareUpdated(sessionId, { isPublic: false, url: '', linkExpired: false, shareKey: '' }, true)
    );
  }
};

export const updateTranscriptShare = (
  sessionId: string,
  user: {
    accountKey: string;
    key: string;
  },
  isPublic: boolean
): Thunk => async (dispatch) => {
  try {
    const share = await createTranscriptShare(sessionId, user, isPublic);
    return dispatch(actions.transcriptShareUpdated(sessionId, share, false));
  } catch (err) {
    return dispatch(
      actions.transcriptShareUpdated(sessionId, { isPublic: false, url: '', linkExpired: false, shareKey: '' }, true)
    );
  }
};

export const fetchSmartNotes = (shareKey: string): Thunk => async (dispatch) => {
  try {
    dispatch(actions.fetchingSmartNotes(true));
    const smartNotes = await getSmartNotes(shareKey);
    return dispatch(actions.smartNotesUpdate(smartNotes.notes));
  } catch {
    return dispatch(actions.smartNotesUpdate([]));
  }
};

export const fetchChats = (chatUrl: string): Thunk => async (dispatch) => {
  try {
    const chats = await getChats(chatUrl);
    const parsedChatdata = extractPublicChat(chats);
    return dispatch(actions.fetchChatsUpdate(parsedChatdata));
  } catch (err) {
    return dispatch(actions.fetchChatsUpdate([]));
  }
};

export const fetchMeetingTranscription = (transcriptURL: string): Thunk => async (dispatch) => {
  try {
    dispatch(actions.fetchingMeetingTranscripts(true));
    const transcriptions = await getTranscriptions(transcriptURL);
    return dispatch(actions.meetingTranscriptions(transcriptions));
  } catch {
    return dispatch(actions.meetingTranscriptions({}));
  }
};

export const fetchMeetingAnalytics = (shareKey: string): Thunk => async (dispatch) => {
  try {
    dispatch(actions.fetchingMeetingAnalytics(true));
    const meetingAnalytics = await getMeetingAnalytics(shareKey);
    return dispatch(actions.meetingAnalytics(meetingAnalytics));
  } catch {
    return dispatch(actions.meetingAnalytics(null));
  }
};

export const updateMeetingSessionDetails = (sessionId: string, updatedSession: SessionUpdates): Thunk => async (
  dispatch,
  getState
) => {
  try {
    const user = getState().core.user;
    if (user.hasLoaded) {
      const userKey = user.key;
      const accountKey = user.accountKey;
      dispatch(actions.updatingMeetingSessionData(true));
      const requestPayload = { ...updatedSession, accountKey };
      await updateMeetingSessionData(sessionId, userKey, requestPayload);
      return dispatch(actions.updateMeetingSessionData(sessionId, updatedSession));
    }
    return dispatch(actions.updatingMeetingSessionData(false));
  } catch {
    return dispatch(actions.updatingMeetingSessionDataError(true));
  }
};
