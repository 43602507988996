import { ActionTypes, Actions } from './uploadsActions';
import { Request } from 'superagent';
import { Reducer } from 'redux';

export interface FileUploadEntry {
  id: number;
  file: File;
  percent?: number;
  request: Request;
  error?: any;
}

type State = ReadonlyArray<FileUploadEntry>;

export const defaultState: State = [];

const reducer: Reducer<State, Actions> = (state: State = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_CLEAR:
    case ActionTypes.UPLOAD_CANCEL:
    case ActionTypes.UPLOAD_SUCCESS:
      return state.filter((upload) => upload.id !== action.payload.id);
    case ActionTypes.UPLOAD_ERROR:
      return state.map((upload) => {
        if (upload.id === action.payload.id) {
          return {
            ...upload,
            error: action.payload.error,
            percent: 100
          };
        }
        return upload;
      });
    case ActionTypes.UPLOAD_PENDING:
      return [...state, { ...action.payload, percent: 0 }];
    case ActionTypes.UPLOAD_PROGRESS:
      return state.map((upload) => {
        if (upload.id === action.payload.id) {
          return { ...upload, percent: action.payload.percent };
        }
        return upload;
      });
  }
  return state;
};

export default reducer;
