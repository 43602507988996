import * as React from 'react';
import Select from 'react-select/async';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { components } from 'react-select';
import { ActionMeta, CommonProps } from 'react-select/src/types';
import styled from '../../../styled-components';
import { InputFieldset } from '../../Common/Toolkit/Input/InputFieldset';
import { getCoorganizers } from '../../../../models/Meeting';

const Menu = styled(components.Menu)`
  && {
    margin-left: -10px;
    width: calc(100% + 20px);
    margin-top: 12px;
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.2);
    color: #000;
  }

  .react-select__option--is-focused {
    background-color: #f0f0f0;
  }
  .react-select__option--is-selected {
    font-weight: bold;
    background-color: #f58f3b;
    color: #fff;

    &.react-select__option--is-focused {
      background-color: #e18039;
    }
  }
`;

const StyledSelect = (styled(Select)`
  .react-select__control {
    min-height: auto;
    box-shadow: none;
    border: none;
  }

  .react-select__value-container {
    padding: 0;
    margin-bottom: -4px;
    & > div {
      margin: 0 4px 4px 0;
      padding: 0;
    }
  }

  .react-select__menu {
    margin-left: -10px;
    width: calc(100% + 20px);
    margin-top: 12px;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    margin: 0;
    padding: 0;
  }

  .react-select__multi-value {
    color: #007eff;
    border: 1px solid rgba(0, 126, 255, 0.24);
    background-color: rgba(0, 126, 255, 0.08);
  }

  .react-select__multi-value__label {
    padding: 3px 6px 3px 3px;
  }

  .react-select__multi-value__remove {
    order: -1;
    border-radius: 2px 0 0 2px;
    border-right: 1px solid rgba(0, 126, 255, 0.24);
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 113, 230, 0.08);
      color: #0071e6;
    }
  }

  .react-select__control.react-select__control--is-disabled {
    background-color: #f9f9f9;
  }

  &.react-select--is-disabled .react-select__multi-value__remove {
    display: none;
  }

  &.react-select--is-disabled .react-select__multi-value {
    color: #333;
    border: 1px solid #e3e3e3;
    background-color: #fcfcfc;
  }

  &.react-select--is-disabled .react-select__multi-value__label {
    padding: 3px 6px;
  }
` as any) as typeof Select;

const messages = defineMessages({
  coOrganizers: {
    id: 'meetingDashboard.meetingScheduler.coOrganizers',
    defaultMessage: 'Co-organizers'
  },
  noOptions: {
    id: 'meetingDashboard.meetingScheduler.noOptions',
    defaultMessage: 'No options'
  },
  startTyping: {
    id: 'meetingDashboard.meetingScheduler.startTyping',
    defaultMessage: 'Type a name or email'
  }
});

export interface CoOrganizerOption {
  key: string;
  email: string;
  firstName?: string;
  lastName?: string;
  value?: string;
  label?: string;
}

interface Props {
  onChange: (options: CoOrganizerOption[], action: ActionMeta) => void;
  value: CoOrganizerOption[];
  disabled?: boolean;
}

const renderMenu = (menuProps: any) => <Menu {...menuProps} />;

const renderNoOptionsMessage = (props: CommonProps<CoOrganizerOption>) => {
  const NoOptionsMessage = components.NoOptionsMessage as any;
  if (props.selectProps.inputValue && props.selectProps.inputValue.length >= 2) {
    if (props.selectProps.options && !props.selectProps.options.length) {
      return (
        <NoOptionsMessage {...props}>
          <FormattedMessage {...messages.noOptions} />
        </NoOptionsMessage>
      );
    }
  }
  return (
    <NoOptionsMessage {...props}>
      <FormattedMessage {...messages.startTyping} />
    </NoOptionsMessage>
  );
};

const renderSelectContainer = (props: any) => {
  const ownProps = {
    ...props,
    innerProps: { ...props.innerProps, 'data-testid': props.innerProps.id }
  };
  const Container = components.SelectContainer as React.ComponentType;
  return <Container {...ownProps} />;
};

export const CoOrganizerSelect = ({ onChange, value, disabled }: Props) => {
  const { formatMessage: f } = useIntl();

  const loadOptions = async (inputValue: string) => {
    if (inputValue.length < 2) {
      return [];
    }

    const coOrganizers = await getCoorganizers(inputValue, []);
    return coOrganizers.coorganizers.map((organizer) => ({
      ...organizer,
      label: `${organizer.firstName} ${organizer.lastName}`,
      key: organizer.key,
      value: organizer.key
    }));
  };

  const formatLabel = (option: CoOrganizerOption) => {
    const name = [option.firstName, option.lastName].filter((i) => !!i).join(' ');
    return name || option.email;
  };

  return (
    <InputFieldset disabled={disabled} legend={<FormattedMessage {...messages.coOrganizers} />} hideMessage={true}>
      <StyledSelect
        isMulti
        id="coorganizer-select"
        classNamePrefix="react-select"
        loadOptions={loadOptions}
        placeholder=""
        formatOptionLabel={formatLabel}
        isDisabled={disabled}
        components={{
          Menu: renderMenu,
          DropdownIndicator: null,
          NoOptionsMessage: renderNoOptionsMessage,
          SelectContainer: renderSelectContainer
        }}
        styles={{
          menuPortal: (base: Record<string, any>) => ({ ...base, zIndex: 100 })
        }}
        value={value}
        menuPortalTarget={document.getElementById('app')}
        onChange={(options: any, action) => onChange(options, action)}
        aria-label={f(messages.coOrganizers)}
      />
    </InputFieldset>
  );
};
