import { Logger } from '@getgo/logger';
import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import Sidebar from 'react-sidebar';
import { User } from '../../types/pulse-web';
import { isMobile } from '../services/ClientService';
import ChatContainer from '../containers/ChatContainer';
import RoomsContainer from '../containers/RoomsContainer';
import { State as RootState } from '../view-model/types';
import { useMatchesMediaQuery } from './Common/MediaQueryFeatureSelector';

interface Props {
  app: RootState['core']['app'];
  chatConnectionStatus?: boolean;
  logger: Logger;
  user?: User;
  clearChatError: () => void;
}

const Application: React.FC<WrappedComponentProps & Props> = React.forwardRef(
  ({ app, chatConnectionStatus, clearChatError, user }, _) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const sidebarDocked = useMatchesMediaQuery('(min-width: 700px)');

    useEffect(() => {
      ReactTooltip.hide();
      ReactTooltip.rebuild();
    });

    useEffect(() => {
      clearChatError();
    }, [chatConnectionStatus, clearChatError]);

    useEffect(() => {
      if (!sidebarDocked && sidebarOpen) {
        document.body.focus(); // reset input focus
      }
    }, [sidebarDocked, sidebarOpen]);

    if (!user || !app.features.includes('chat')) {
      return null;
    }

    const sidebarContent = (
      <div className="side-bar" data-testid="chat-sidebar" hidden={!sidebarDocked && !sidebarOpen}>
        <RoomsContainer onSelect={() => setSidebarOpen(false)} />
      </div>
    );

    // styles override for the react-sidebar component
    // we need to set overflowY to hidden, to prevent scrollbars on
    // windows due to the use if 100vh to make sidebar full height
    const styles = {
      sidebar: {
        overflowY: 'hidden'
      },
      content: {
        overflowY: 'auto'
      }
    };

    return (
      <Sidebar
        sidebar={sidebarContent}
        styles={styles}
        open={sidebarOpen}
        docked={sidebarDocked}
        transitions={!sidebarDocked}
        onSetOpen={setSidebarOpen}
        shadow={false}
      >
        <div className="main" data-testid="chat-main-content">
          <ChatContainer
            user={user}
            sidebarDocked={sidebarDocked}
            onSetSidebarOpen={() => setSidebarOpen(true)}
            screenShareId={'screen-sharing-viewer'}
            autoFocus={!isMobile && (sidebarDocked || !sidebarOpen)}
          />
        </div>
      </Sidebar>
    );
  }
);

Application.displayName = 'Hub';
export default injectIntl(Application, { forwardRef: true });
