import { delay } from '../../../lib/async';

export const slidesRecordingsDownload = (arrayOfUrls: Array<{ url: string; title: string }>) => {
  initiateDownload(arrayOfUrls).then(() => Promise.resolve()); // handling promise to fix IE issue
};

const download = async (url: string, name: string) => {
  const a = document.createElement('a');

  a.download = name;
  a.href = url;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  // Chrome requires the timeout
  await delay(1000);
  a.parentNode?.removeChild(a);
};

const initiateDownload = async (arrayOfUrls: Array<{ url: string; title: string }>) => {
  for (let index = 0; index < arrayOfUrls.length; index++) {
    try {
      await delay(index * 1000);
      await download(arrayOfUrls[index].url, arrayOfUrls[index].title);
    } catch (e) {
      // failed silently
    }
  }
};
