import * as React from 'react';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

interface Props {
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  wait?: number;
}

export const DelayedInput = (props: Props) => {
  const [value, setValue] = useState(props.value);
  const [debounced] = useState(() => debounce((fun: () => void) => fun(), props.wait || 300));
  const debouncedChange = (data: string) => debounced(() => props.onChange(data));

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedChange(e.target.value);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    debouncedChange(e.target.value);
    debounced.flush();
  };
  return <input {...props} value={value} type="text" onBlur={onBlur} onChange={onChange} />;
};
