import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import notificationsActions from '../../../view-model/modules/core/notifications/notificationsActions';
import { PreferencesBlock } from '../../Common/Preferences/PreferencesBlock';
import { PreferencesItemBool } from '../../Common/Preferences/PreferencesItemBool';
import { SyncPreferencesPage } from '../../Common/Preferences/PreferencesPage';
import { Dispatch, State } from '../../../view-model/types';
import './NotificationPreferences.css';
import { UnstyledButton } from '../../Common/Toolkit/Button/Button';
import styled from '../../../styled-components';

const messages = defineMessages({
  notificationsInformationText: {
    id: 'notificationPreferences.notificationsInformationText',
    defaultMessage:
      'Your browser currently cannot display notifications. Click {notificationsInformationButton} to enable notifications for this site.'
  },
  notificationsInformationTextDenied: {
    id: 'notificationPreferences.notificationsInformationTextDenied',
    defaultMessage:
      'Notifications are blocked for this site. To view notifications, you must enable them in your browser settings and then reload this page.'
  },
  notificationsInformationTextLink: {
    id: 'notificationPreferences.notificationsInformationTextLink',
    defaultMessage: 'here'
  },
  notificationsDoNotDisturb: {
    id: 'notificationPreferences.notificationsDoNotDisturb',
    defaultMessage: 'Do not disturb'
  },
  notificationsDisable: {
    id: 'notificationPreferences.notificationsDisable',
    defaultMessage: 'Disable notifications'
  },
  notificationsDisplay: {
    id: 'notificationPreferences.notificationsDisplay',
    defaultMessage: 'Notification display'
  }
});

const NotificationsButton = styled(UnstyledButton)`
  font-weight: bold;
  color: #309ddc;
  padding: 0px 2px;

  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  notificationPermission: NotificationPermission;
  requestBrowserNotificationPermissions: () => void;
}

const NotificationPreferences = (props: Props) => {
  const { notificationPermission, requestBrowserNotificationPermissions } = props;

  return (
    <SyncPreferencesPage group="notifications">
      {({ settings, onChange }) => (
        <div className="settings">
          {notificationPermission !== 'granted' && (
            <span className="notifications-info">
              <i className="togo-icon togo-icon-info togo-icon-lg" />
              {notificationPermission === 'default' && (
                <span className="notifications-info-default">
                  <FormattedMessage
                    {...messages.notificationsInformationText}
                    values={{
                      notificationsInformationButton: (
                        <NotificationsButton onClick={requestBrowserNotificationPermissions}>
                          <FormattedMessage {...messages.notificationsInformationTextLink} />
                        </NotificationsButton>
                      )
                    }}
                  />
                </span>
              )}
              {notificationPermission === 'denied' && (
                <span className="notifications-info-denied">
                  <FormattedMessage {...messages.notificationsInformationTextDenied} />
                </span>
              )}
            </span>
          )}
          <PreferencesBlock
            titleMessage={messages.notificationsDoNotDisturb}
            disabled={notificationPermission !== 'granted'}
          >
            <PreferencesItemBool
              id="do-not-disturb"
              type="checkbox"
              checked={settings.doNotDisturb}
              onChange={(enabled) => onChange('doNotDisturb', enabled)}
              disabled={notificationPermission !== 'granted'}
              labelMessage={messages.notificationsDisable}
            />
          </PreferencesBlock>
        </div>
      )}
    </SyncPreferencesPage>
  );
};

export default connect(
  (state: State) => ({
    notificationPermission: state.core.notifications.notificationPermission || 'default'
  }),
  (dispatch: Dispatch) => ({
    requestBrowserNotificationPermissions() {
      dispatch(notificationsActions.acceptDoubleBanner(Date.now()));
    }
  })
)(NotificationPreferences);
