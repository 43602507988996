import * as t from 'io-ts';
import config from 'config';

// taken from http://www.olioapps.com/blog/checking-types-real-world-typescript/
// see https://github.com/gcanti/io-ts/issues/140 for built-in support
export function optional<RT extends t.Any>(
  type: RT,
  name = `${type.name} | undefined`
): t.UnionType<[RT, t.UndefinedType], t.TypeOf<RT> | undefined, t.OutputOf<RT> | undefined, t.InputOf<RT> | undefined> {
  return t.union<[RT, t.UndefinedType]>([type, t.undefined], name);
}

export function validate<T>(val: any, type: t.Type<T>, msg?: string): T {
  if (!type.is(val)) {
    if (config.env !== 'prod') {
      const reporter = require('io-ts/lib/PathReporter').PathReporter;

      // eslint-disable-next-line no-console
      console.error(reporter.report(type.decode(val)));
    }
    throw new Error(msg || 'Type Validation failed');
  }

  return val;
}
