import { FilesMessage, MessagesEntity } from '@getgo/caps-redux';
import { ActionsUnion, Thunk, createAction } from '../../../type-helpers';

export enum ActionTypes {
  NAVIGATE_IMAGE_PREVIEW = 'NAVIGATE_IMAGE_PREVIEW',
  OPEN_IMAGE_PREVIEW = 'OPEN_IMAGE_PREVIEW',
  CLOSE_IMAGE_PREVIEW = 'CLOSE_IMAGE_PREVIEW'
}

const actions = {
  navigateImagePreview: (delta: number) => createAction(ActionTypes.NAVIGATE_IMAGE_PREVIEW, delta),
  openPreview: (message: FilesMessage, messages: MessagesEntity[]) =>
    createAction(ActionTypes.OPEN_IMAGE_PREVIEW, { message, messages }),
  closePreview: () => createAction(ActionTypes.CLOSE_IMAGE_PREVIEW)
};

export default actions;

export type Actions = ActionsUnion<typeof actions>;

export const openImagePreview = (message: FilesMessage): Thunk => {
  return (dispatch, getState) => {
    // need to pass the messages array to the image preview so it can cycle through them
    const state = getState();
    const roomId = state.messaging.chat.currentRoomId;
    const room = state.messaging.chat.rooms[roomId];
    const messages = room?.messages || [];

    return dispatch(actions.openPreview(message, messages));
  };
};
