import classNames from 'classnames';
import { EditorState } from 'draft-js';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import scrollparent from 'scrollparent';
import { createState, getExcessCharacters, MessageInput, stateToPulseXMPPFormat } from '../MessageInputField';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { MessagesEntity, Selectors } from '@getgo/caps-redux';
import { State as RootState } from '../../../view-model/types';
import { Avatar } from '../../Common/Avatar/Avatar';
import { isHoverSupported } from '../../../services/ClientService';
import { buildEmojiUrl } from '../../../services/MessageFormatService';
import { formatTime, getFormattedDayAndTime, getTodayDate } from '../../../../lib/date';
import styled from '../../../styled-components';

const messages = defineMessages({
  editMessageTooltip: {
    id: 'chat.messageHistory.editMessage.tooltip',
    defaultMessage: 'Edit message'
  },
  editMessageBlockedTooltip: {
    id: 'chat.messageHistory.editMessage.blockedTooltip',
    defaultMessage: 'Sorry, editing is disabled. Messages can be edited for 24 hours, up to three times max.'
  },
  acceptEditButton: {
    id: 'chat.messageHistory.editMessage.acceptEditButton',
    defaultMessage: 'Save'
  },
  cancelEditButton: {
    id: 'chat.messageHistory.editMessage.cancelEditButton',
    defaultMessage: 'Cancel'
  },
  editedLabel: {
    id: 'chat.messageHistory.editMessage.editedLabel',
    defaultMessage: 'edited'
  }
});

export const MessageHistoryAvatar = styled(Avatar)`
  float: left;
  margin-top: 3px;
`;

interface Props {
  app?: RootState['core']['app'];
  avatarUrl?: string;
  children: React.ReactElement<any>;
  hideEditedLabel?: boolean;
  initialEditMode?: boolean;
  isEditableMessageType?: boolean;
  isMessageFromExternalUser?: boolean;
  isMine: boolean;
  isOnline?: boolean;
  maxLength?: number;
  message?: MessagesEntity;
  name: string | null;
  onNewReplaceMessage?: (message: any, xmppMessage: any, options: {}) => void;
  squash?: boolean;
  status?: string;
}

interface State {
  editMode: boolean;
  editorState: EditorState;
}

class MessageHistoryEntry extends React.Component<Props & WrappedComponentProps, State> {
  private container: HTMLElement | null = null;
  private input: MessageInput | null = null;
  private scrollParent: HTMLElement | null = null;

  constructor(props: Props & WrappedComponentProps) {
    super(props);
    const { message = { text: undefined } } = this.props;
    this.state = {
      editMode: !!this.props.initialEditMode,
      editorState: createState(message.text)
    };
  }

  componentDidMount() {
    if (this.container) {
      this.scrollParent = scrollparent(this.container) || null;
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { message = { editId: undefined } } = this.props;
    if (!prevState.editMode && this.state.editMode) {
      // hide outdated tooltip for edit button
      ReactTooltip.hide();
      if (this.input) {
        this.input.focus();
      }
    }

    if (
      (prevState.editMode && !this.state.editMode) ||
      (!(prevProps.message && prevProps.message.editId) && message.editId)
    ) {
      // update the "Edited" tooltip that could have been added by displaying the message again, or by a message from
      // someone else that was just edited
      ReactTooltip.rebuild();
    }
  }

  turnEditModeOn = () => {
    const { message = { text: undefined } } = this.props;
    this.setStateRetainScrollBottom({
      editMode: true,
      editorState: createState(message.text)
    });
  };

  handleInputChange = (newState: EditorState) => {
    this.setState({
      editorState: newState
    });
  };

  handleCancelEdit = () => {
    const { message = { text: undefined } } = this.props;
    this.setStateRetainScrollBottom({
      editMode: false,
      editorState: createState(message.text)
    });
  };

  handleAcceptEdit = () => {
    this.handleSubmit();
  };

  handleSubmit = () => {
    const { message = { text: '' } } = this.props;
    if (!this.shouldEnableAcceptEdit()) {
      return;
    }

    const { xmppMessage, options = {} } = stateToPulseXMPPFormat(this.state.editorState);

    if (this.props.onNewReplaceMessage) {
      this.props.onNewReplaceMessage(message, xmppMessage, options);
    }

    this.setStateRetainScrollBottom({
      editMode: false
    });
  };

  setStateRetainScrollBottom = <T extends keyof State>(stateUpdates: Pick<State, T>, callback?: () => {}) => {
    // retains scroll distance to bottom of message history so that messages below this message changes don't
    // jump around but the ones above do. Works by scrolling down if content gets larger, or up if content shrinks
    // need to account for scrollTop changes by browser, too
    const prevHeight = this.container ? this.container.clientHeight : 0;
    const prevScrollTop = this.scrollParent ? this.scrollParent.scrollTop : 0;

    this.setState(stateUpdates, () => {
      const dHeight = this.container ? this.container.clientHeight - prevHeight : 0;
      const dScrollTop = this.scrollParent ? this.scrollParent.scrollTop - prevScrollTop : 0;
      if (this.scrollParent) {
        this.scrollParent.scrollTop += dHeight - dScrollTop;
      }
      if (callback) {
        callback();
      }
    });
  };

  shouldEnableAcceptEdit = () => {
    const { maxLength = 10000 } = this.props;

    const { xmppMessage } = stateToPulseXMPPFormat(this.state.editorState);

    // empty
    if (!xmppMessage) {
      return false;
    }
    // no change
    if (this.props.message && xmppMessage === this.props.message.text) {
      return false;
    }
    if (!this.getCanEdit() || !this.props.isOnline) {
      return false;
    }
    if (getExcessCharacters(this.state.editorState, maxLength) > 0) {
      return false;
    }

    return true;
  };

  getCanEdit = () => {
    const { app = { features: [] }, isEditableMessageType = false, message = { text: undefined } } = this.props;
    return (
      this.props.isMine &&
      Selectors.isReplaceable(message) &&
      (app.features as Readonly<string[]>).includes('messageEditing') &&
      isEditableMessageType
    );
  };

  render() {
    const {
      message = {
        editDate: undefined,
        id: undefined,
        text: undefined,
        from: undefined,
        date: undefined,
        editId: undefined
      },
      hideEditedLabel = false
    } = this.props;
    const canEdit = this.getCanEdit();
    let replaceLimitReached = true;
    if (canEdit) {
      replaceLimitReached = Selectors.isReplaceLimitReached(message);
    }

    const canCurrentlyEdit = canEdit && this.props.isOnline;

    const rootClass = classNames('message-entry', {
      'message-own': this.props.isMine,
      squash: this.props.squash,
      pending: this.props.status === 'pending',
      error: this.props.status === 'error',
      external: this.props.isMessageFromExternalUser
    });
    const messageBodyClasses = classNames('message-body', {
      'message-editable': canEdit
    });
    const { formatMessage } = this.props.intl;
    const fromName = this.props.name || message.from;

    return (
      <li className={rootClass} ref={(el) => (this.container = el)} data-testid="message-history-entry">
        {this.props.squash ? null : <MessageHistoryAvatar name={fromName} img={this.props.avatarUrl} />}
        <div className="message-entry-header">
          <div className="message-from">{fromName}</div>
          {message.date && <div className="message-date">{formatTime(this.props.intl, message.date)}</div>}
        </div>
        <div className={messageBodyClasses}>
          {this.state.editMode ? (
            <div className="editable-message-input">
              <MessageInput
                key={message.id}
                ref={(el) => (this.input = el)}
                state={this.state.editorState}
                onChange={this.handleInputChange}
                onEscape={this.handleCancelEdit}
                readOnly={!this.props.isOnline}
                onSubmit={this.handleSubmit}
                enableMentions={false}
                showEmojiButton
                submitOnEnter
                buildEmojiUrl={buildEmojiUrl}
                formatMessage={() => ''}
                noSubmitButton
                spellCheck
                placeholder={formatMessage(messages.editMessageTooltip)}
              />
              <div className="message-input-buttons">
                <button
                  className="btn btn-primary accept-edit-button"
                  disabled={!this.shouldEnableAcceptEdit()}
                  onClick={this.handleAcceptEdit}
                >
                  <FormattedMessage {...messages.acceptEditButton} />
                </button>
                <button className="btn btn-secondary cancel-edit-button" onClick={this.handleCancelEdit}>
                  <FormattedMessage {...messages.cancelEditButton} />
                </button>
              </div>
            </div>
          ) : (
            <span>
              {canCurrentlyEdit &&
                (replaceLimitReached ? (
                  <span
                    className="message-edit-button-wrapper"
                    data-tip={isHoverSupported ? formatMessage(messages.editMessageBlockedTooltip) : null}
                    data-delay-show="500"
                    data-place="left"
                  >
                    <button className="btn-edit message-edit-button edit-not-allowed" disabled>
                      <i className="togo-icon togo-icon-edit togo-icon-lg" />
                    </button>
                  </span>
                ) : (
                  <span
                    className="message-edit-button-wrapper"
                    data-tip={isHoverSupported ? formatMessage(messages.editMessageTooltip) : null}
                    data-delay-show="500"
                    data-place="top"
                  >
                    <button className="btn-edit message-edit-button" onClick={this.turnEditModeOn}>
                      <i className="togo-icon togo-icon-edit togo-icon-lg" />
                    </button>
                  </span>
                ))}
              {this.props.children}
              {!hideEditedLabel && message.editId ? (
                <span
                  className="label label-default edited-label"
                  data-tip={
                    isHoverSupported && message.editDate
                      ? getFormattedDayAndTime(this.props.intl, message.editDate, getTodayDate())
                      : null
                  }
                  data-delay-show="500"
                  data-place="top"
                >
                  <FormattedMessage {...messages.editedLabel} />
                </span>
              ) : null}
            </span>
          )}
        </div>
      </li>
    );
  }
}

export default injectIntl(MessageHistoryEntry, { forwardRef: true });
