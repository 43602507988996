import { GroupContact } from '@getgo/caps-redux';
import { keyBy } from 'lodash';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { User } from '../../../types/pulse-web';
import GoToMeetingContainer from '../../containers/GoToMeetingContainer';
import { getAvatarTooltipText } from '../../services/TooltipHelper';
import { State } from '../../view-model/types';
import { Avatar } from '../Common/Avatar/Avatar';
import { ButtonTypes } from './GoToMeeting/GoToMeeting';
import GroupChatSetting from './GroupChatSetting';
import GroupMemberList from './GroupMemberList';
import { GroupName } from './GroupName';
import { LockClosed, PeopleFilled } from '../Common/Toolkit/Icon/ReactIcons';
import styled from '../../styled-components';
import device from '../Common/Toolkit/devices';

const messages = defineMessages({
  externalMembers: {
    id: 'chat.chatHeaderGroup.externalMembers',
    defaultMessage: 'Contains external members'
  }
});

const AlignedPeopleFilled = styled(PeopleFilled)`
  vertical-align: middle;
`;

const AlignedLockClosed = styled(LockClosed)`
  vertical-align: middle;
`;

export const ChatHeaderGroupAvatar = styled(Avatar)`
  margin: 0 10px 0 7px;
  min-width: 35px;
  @media ${device.mobile} {
    display: none;
  }
`;

export interface Props {
  app?: State['core']['app'];
  contact: GroupContact;
  isOnline?: boolean;
  onSearchGroup: (query: string, index?: number) => void;
  onUpdateGroupName: (jid: string, name: string) => void;
  publicGroupExists?: boolean;
  sidebarOpenButton?: React.ReactElement<any>;
  user?: User;
}

export const ChatHeaderGroup: React.FC<Props> = (props) => {
  const index = keyBy(props.contact.resources, 'resource');
  const members = props.contact.members.map((m) => {
    return Object.assign(
      {
        status: index[m.jid] ? 'online' : 'offline'
      },
      m
    );
  });
  const intl = useIntl();

  const groupIcon = props.contact.isPublic ? AlignedPeopleFilled : AlignedLockClosed;
  const hasExternal = props.contact.hasExternalMembers;

  return (
    <header className="chat-header-group">
      {props.sidebarOpenButton}
      <ChatHeaderGroupAvatar
        name={props.contact.name}
        icon={groupIcon}
        img={props.contact.avatarUrl}
        transparent={hasExternal}
        backgroundColor={'transparent'}
        color={'#444444'}
        tooltip={getAvatarTooltipText(intl.formatMessage, props.contact.isPublic, false)}
      />
      <h4 className="contact-details">
        <GroupName
          name={props.contact.name}
          intl={intl}
          isPublic={props.contact.isPublic}
          publicGroupExists={props.publicGroupExists}
          onSearchGroup={props.onSearchGroup}
          isEditable={props.contact.affiliation === 'owner' && props.isOnline}
          handleUpdate={(name: string) => props.onUpdateGroupName(props.contact.jid, name)}
        />
        {hasExternal && (
          <span className="external-members-tag">
            <FormattedMessage {...messages.externalMembers} />
          </span>
        )}
      </h4>
      <div className="chat-header-flex-dummy" />
      {props.app?.features && props.app.features.includes('gotomeeting') && (
        <GoToMeetingContainer
          buttonType={ButtonTypes.MEET_NOW_BUTTON}
          contact={props.contact}
          isOnline={props.isOnline}
        />
      )}
      <GroupChatSetting {...props} />
      <GroupMemberList members={members} {...props} />
    </header>
  );
};
