import * as React from 'react';
import { Box, Item } from '../Common/Toolkit/Box/Box';
import { formatTime, getRoundedDurationInMinutes } from '../../../lib/date';
import {
  MeetingsTabContainer,
  MeetingsTabId,
  MeetingsTabSubject,
  MeetingsTabTime,
  MeetingsTabTitle
} from './MeetingComponents';
import device from '../Common/Toolkit/devices';
import styled from '../../styled-components';
import { MediaQueryFeatureSelector } from '../Common/MediaQueryFeatureSelector';
import { useIntl } from 'react-intl';

export const MeetingDetailsHeaderContainer = styled(MeetingsTabContainer)`
  @media ${device.laptop} {
    padding: 24px;
  }

  @media ${device.mobile} {
    padding: 0;
  }
`;

export const MeetingDetailsHeaderTitle = styled(MeetingsTabTitle)`
  font-size: 24px;
  line-height: 28px; /* workaround so emojis don't break the line */
  margin-top: -4px;
  padding-top: 4px;
  font-family: Lato-Semibold;
  font-weight: 600;

  @media ${device.laptop} {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const MeetingDetailsHeaderTime = styled(MeetingsTabTime)`
  padding: 0 32px 0 0;

  @media ${device.laptop} {
    padding: 0 24px 0 0;
  }
`;

const MobileTitle = styled.div`
  margin-bottom: 16px;
  color: #878787;
`;

export interface Props {
  subject: string;
  subtitle: string;
  beginDate?: Date;
  endDate?: Date;
}

export const MeetingDetailsHeader: React.FC<Props> = (props) => {
  const intl = useIntl();
  const { subject, subtitle, beginDate, endDate } = props;
  const duration = beginDate && endDate ? getRoundedDurationInMinutes(beginDate, endDate) : null;
  const formattedBeginDate = beginDate ? formatTime(intl, beginDate) : null;

  const desktopView = (
    <MeetingDetailsHeaderContainer>
      <Box flexDirection="row">
        {beginDate && (
          <MeetingDetailsHeaderTime>
            <MeetingDetailsHeaderTitle data-testid="upcoming-meeting-date">
              {formattedBeginDate}
            </MeetingDetailsHeaderTitle>
            {duration && <Item data-testid="upcoming-meeting-duration">{duration} min</Item>}
          </MeetingDetailsHeaderTime>
        )}
        <MeetingsTabSubject>
          <MeetingDetailsHeaderTitle data-testid="upcoming-meeting-subject">{subject}</MeetingDetailsHeaderTitle>
          <MeetingsTabId data-testid="upcoming-meeting-id">{subtitle}</MeetingsTabId>
        </MeetingsTabSubject>
      </Box>
    </MeetingDetailsHeaderContainer>
  );

  const mobileView = (
    <MeetingDetailsHeaderContainer>
      <MobileTitle>{subject}</MobileTitle>
    </MeetingDetailsHeaderContainer>
  );

  return <MediaQueryFeatureSelector query={device.mobile} queryMatched={mobileView} queryNotMatched={desktopView} />;
};
