import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import outlookIcon from '../../../../assets/images/icons/outlook.svg';
import externalLink from '../../../../assets/images/icons/external-link.svg';
import googleCalendarIcon from '../../../../assets/images/icons/google-calendar.svg';
import './CalendarSyncPreferences.css';
import config from 'config';

const messages = defineMessages({
  heading: {
    id: 'calendarSyncPreferences.title',
    defaultMessage: 'Sync your calendar with GoToMeeting:'
  },
  subtitle: {
    id: 'calendarSyncPreferences.subtitle',
    defaultMessage: 'Schedule and manage GoToMeeting events right from your calendar.'
  },
  subtitlePoint1: {
    id: 'calendarSyncPreferences.subtitlePoint1',
    defaultMessage: 'Add a GoToMeeting with one click.'
  },
  subtitlePoint2: {
    id: 'calendarSyncPreferences.subtitlePoint2',
    defaultMessage: 'Invite attendees from your email contacts.'
  },
  subtitlePoint3: {
    id: 'calendarSyncPreferences.subtitlePoint3',
    defaultMessage: 'Keep your meetings in sync, even if you edit them.'
  },
  outlookTitle: {
    id: 'calendarSyncPreferences.outlookTitle',
    defaultMessage: 'Outlook®'
  },
  outlookDetail: {
    id: 'calendarSyncPreferences.outlookDetail',
    defaultMessage: 'Download the GoToMeeting Scheduler'
  },
  googleCalendarTitle: {
    id: 'calendarSyncPreferences.googleCalendarTitle',
    defaultMessage: 'Google Calendar™'
  },
  googleCalendarDetail: {
    id: 'calendarSyncPreferences.googleCalendarDetail',
    defaultMessage: 'Download the Google Calendar plug-in'
  }
});

interface Props {
  outlookCalendarClick: () => void;
  googleCalendarClick: () => void;
}

export const CalendarSyncPreferences: React.FC<Props> = (props) => {
  return (
    <div className="calendar-integrations">
      <h4>
        <FormattedMessage {...messages.heading} />
      </h4>
      <FormattedMessage {...messages.subtitle} />
      <ul>
        <li>
          <FormattedMessage {...messages.subtitlePoint1} />
        </li>
        <li>
          <FormattedMessage {...messages.subtitlePoint2} />
        </li>
        <li>
          <FormattedMessage {...messages.subtitlePoint3} />
        </li>
      </ul>
      <div className="outlook-links">
        <img alt="" src={outlookIcon} />

        <FormattedMessage {...messages.outlookTitle} />
        <div>
          <a
            data-testid="outlook-link"
            onClick={props.outlookCalendarClick}
            target="_blank"
            rel="noopener noreferrer"
            href={config.externalLinks.calendarSync}
          >
            <img alt="" src={externalLink} />
            <FormattedMessage {...messages.outlookDetail} />
          </a>
        </div>
      </div>

      <div className="google-calendar-links">
        <img alt="" src={googleCalendarIcon} />
        <FormattedMessage {...messages.googleCalendarTitle} />
        <div>
          <a
            data-testid="google-calendar-link"
            onClick={props.googleCalendarClick}
            target="_blank"
            rel="noopener noreferrer"
            href={config.externalLinks.calendarSync}
          >
            <img alt="" src={externalLink} />
            <FormattedMessage {...messages.googleCalendarDetail} />
          </a>
        </div>
      </div>
    </div>
  );
};
