import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from '../../../styled-components';
import logo from '../../../../assets/images/logo.svg';
import { PrimaryButton } from '../Toolkit/Button/PrimaryButton';
import { LinkButton } from '../Toolkit/Button/LinkButton';

const messages = defineMessages({
  errorCode: {
    id: 'errorPage.errorCode',
    defaultMessage: 'Error code'
  }
});

const Body = styled.main`
  flex: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  margin-bottom: 85px;
  width: 400px;
`;

const Title = styled.h3`
  font-weight: bold;
  font-size: 32px;
  color: #444;
  text-align: center;
  margin: 0 0 14px 0;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #8d8d8d;
  margin: 0;
`;

const Footer = styled.footer`
  flex: none;
  color: #8d8d8d;
  font-size: 14px;
`;

const RetryButton = styled(PrimaryButton)`
  margin-top: 32px;
`;

const SecondaryButton = styled(LinkButton)`
  margin-top: 16px;
  font-size: 14px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-flow: column nowrap;
  padding: 15px 50px;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 800px;
`;

interface Props {
  title: React.ReactNode;
  description: React.ReactNode;
  errorCode?: string;
  footer?: React.ReactNode;
  button?: {
    onClick: () => void;
    label: React.ReactNode;
  };
  secondaryButton?: {
    onClick: () => void;
    label: React.ReactNode;
  };
}

export const ErrorPage = (props: Props) => (
  <Container>
    <Body>
      <Logo src={logo} />
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
      {props.button && <RetryButton onClick={props.button.onClick}>{props.button.label}</RetryButton>}
      {props.secondaryButton && (
        <SecondaryButton onClick={props.secondaryButton.onClick}>{props.secondaryButton.label}</SecondaryButton>
      )}
    </Body>
    {props.errorCode && (
      <Footer>
        <FormattedMessage {...messages.errorCode} />
        {`: ${props.errorCode}`}
      </Footer>
    )}
    {props.footer && <Footer>{props.footer}</Footer>}
  </Container>
);
