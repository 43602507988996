import * as React from 'react';
import ReactDatePicker from 'react-datepicker';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import styled from '../../../styled-components';
import ReactDOM from 'react-dom';
import { getFnsLocale } from '../../../../lib/date';
import { themeGet } from '@styled-system/theme-get';

type onChangeCallback = (date: Date, event?: React.SyntheticEvent<any, Event> | undefined) => void;

const StyledContainer = styled.div`
  & input {
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;

    &:disabled {
      background-color: #fff;
      color: #ccc;
    }
  }

  .react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
  }
`;

const StyledPopperContainer = styled.div`
  .react-datepicker {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 5px 8px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    padding: 10px;
    margin: 12px 0 0 -12px;
    padding: 0;
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__header {
    display: none;
  }

  .react-datepicker__time-list {
    list-style: none;
    margin: 0;
    padding: 4px 0;
    width: 100%;
    box-sizing: content-box;
    max-height: 300px;
    overflow-y: scroll;
  }

  .react-datepicker__time-list-item {
    padding: 8px 12px;
  }

  .react-datepicker__time-list-item:hover {
    background-color: #f0f0f0;
  }

  .react-datepicker__time-list-item--selected {
    background-color: ${themeGet('colors.primary')};
    color: ${themeGet('colors.primaryOn')};
    font-weight: bold;

    &:hover {
      background-color: ${themeGet('colors.hover.primary')};
    }
  }
`;

const CalendarContainer = ({ children }: { children: React.ReactNode[] }) => {
  const element = document.getElementById('app');
  if (element) {
    return ReactDOM.createPortal(<StyledPopperContainer>{children}</StyledPopperContainer>, element);
  }
  return null;
};

interface Props {
  onChange: onChangeCallback;
  value?: Date | null | undefined;
  disabled?: boolean;
  customInput?: React.ReactNode;
}

class TimePicker extends React.Component<Props & WrappedComponentProps> {
  render() {
    const { disabled, value, onChange, intl, customInput } = this.props;
    const locale = getFnsLocale(intl);
    const format = locale.formatLong.time({ width: 'short' });

    return (
      <StyledContainer>
        <ReactDatePicker
          disabled={disabled}
          selected={value}
          onChange={onChange}
          showTimeSelect
          showTimeSelectOnly
          locale={this.props.intl.locale}
          dateFormat={format}
          timeFormat={format}
          popperContainer={CalendarContainer}
          customInput={customInput}
        />
      </StyledContainer>
    );
  }

  static app?: React.Component;
  public static setAppElement(app: React.Component) {
    TimePicker.app = app;
  }
}

export default injectIntl(TimePicker);
