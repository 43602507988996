import { MessagesEntity } from '@getgo/caps-redux';
import { ActionsUnion, Thunk, createAction } from '../../../type-helpers';

// Error priority: show the higher priority error, for the same priority last in wins.
export enum priority {
  highest = 5,
  vhigh = 4,
  high = 3,
  medium = 2,
  low = 1
}

export enum AlertLevel {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger'
}

// Categorizing errors so as to be able to clear the relevant error from the corresponding action.
export enum category {
  network = 'network',
  chat = 'chat',
  profile = 'profile',
  meeting = 'meeting',
  meetNow = 'meetNow',
  socialGraph = 'socialGraph',
  discover = 'discover'
}

export interface Error {
  actionText?: string;
  category?: category;
  disableForComponents?: any[];
  enableForComponents?: any[];
  error?: string;
  meta?: Error;
  name?: string;
  priority?: priority;
  retryCallback?: () => void;
  text?: string;
}

// PRESENCE_ERROR, GENERIC_ERROR from caps-redux
export enum ActionTypes {
  API_ERROR = 'API_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
  CLEAR_NETWORK_ERROR = 'CLEAR_NETWORK_ERROR',
  CLEAR_CHAT_ERROR = 'CLEAR_CHAT_ERROR',
  CLEAR_MEETING_ERROR = 'CLEAR_MEETING_ERROR',
  CLEAR_SOCIAL_GRAPH_ERROR = 'CLEAR_SOCIAL_GRAPH_ERROR',
  EXTERNAL_CONTACT_ERROR = 'EXTERNAL_CONTACT_ERROR',
  GENERIC_ERROR = 'GENERIC_ERROR',
  GROUP_CANT_JOIN = 'GROUP_CANT_JOIN',
  GROUP_CANT_CREATE = 'GROUP_CANT_CREATE',
  GROUP_CANT_LEAVE = 'GROUP_CANT_LEAVE',
  GROUP_MEMBERS_CANT_REMOVE = 'GROUP_MEMBERS_CANT_REMOVE',
  MEETING_CONCURRENT_ERROR = 'MEETING_CONCURRENT_ERROR',
  MEETING_ERROR_CREATING = 'MEETING_ERROR_CREATING',
  NETWORK_ERROR = 'NETWORK_ERROR',
  PRESENCE_ERROR = 'PRESENCE_ERROR',
  XMPP_AUTH_ERROR = 'XMPP_AUTH_ERROR',
  SETTINGS_UPDATING_ERROR = 'SETTINGS_UPDATING_ERROR',
  SETTINGS_FETCHING_ERROR = 'SETTINGS_FETCHING_ERROR',
  DISCOVER_FETCHING_ERROR = 'DISCOVER_FETCHING_ERROR'
}

const actions = {
  clearError: () => createAction(ActionTypes.CLEAR_ERROR),
  clearNetworkError: () => createAction(ActionTypes.CLEAR_NETWORK_ERROR),
  clearChatError: () => createAction(ActionTypes.CLEAR_CHAT_ERROR),
  clearMeetingError: () => createAction(ActionTypes.CLEAR_MEETING_ERROR),
  clearSocialGraphError: () => createAction(ActionTypes.CLEAR_SOCIAL_GRAPH_ERROR),
  genericError: (error: Error, context: any) => createAction(ActionTypes.GENERIC_ERROR, { error, context }),
  presenceError: (msg: MessagesEntity) => createAction(ActionTypes.GENERIC_ERROR, { msg }),
  initChatError: (error: Error = {}, retryFn: () => void = () => {}) =>
    createAction(ActionTypes.NETWORK_ERROR, {
      action: retryFn,
      actionText: 'Try again',
      error,
      priority: priority.highest,
      category: category.network,
      level: AlertLevel.DANGER,
      text: 'Network error...',
      name: 'init-chat-error'
    }),
  xmppAuthError: (error: Error = {}, retryFn: () => void = () => {}) =>
    createAction(ActionTypes.XMPP_AUTH_ERROR, {
      action: retryFn,
      actionText: 'Try again',
      error,
      priority: priority.vhigh,
      category: category.chat,
      level: AlertLevel.DANGER,
      text: 'Network error...',
      name: 'xmpp-auth-error'
    }),
  entitlementError: (text = '', error: Error = {}, retryFn: () => void = () => {}) =>
    createAction(ActionTypes.XMPP_AUTH_ERROR, {
      level: AlertLevel.DANGER,
      action: retryFn,
      actionText: 'Ok, take me there.',
      text,
      error,
      priority: priority.vhigh,
      category: category.chat,
      name: 'entitlement-error'
    }),
  groupCantJoinError: (text = '', error: Error = {}, action: () => void) =>
    createAction(ActionTypes.GROUP_CANT_JOIN, {
      level: AlertLevel.DANGER,
      text,
      error,
      priority: priority.medium,
      category: category.chat,
      actionText: 'Ok',
      action,
      name: 'join-group-error'
    }),
  groupCantCreateError: (text = '', error: Error = {}, action: () => void) =>
    createAction(ActionTypes.GROUP_CANT_CREATE, {
      level: AlertLevel.DANGER,
      text,
      error,
      priority: priority.medium,
      category: category.chat,
      actionText: 'Ok',
      action,
      name: 'create-group-error'
    }),
  groupCantLeaveError: (text = '', error: Error = {}, action: () => void) =>
    createAction(ActionTypes.GROUP_CANT_LEAVE, {
      level: AlertLevel.DANGER,
      text,
      error,
      priority: priority.medium,
      category: category.chat,
      actionText: 'Ok',
      action,
      name: 'leave-group-error'
    }),
  groupMembersCantRemoveError: (text = '', error: Error = {}, action: () => void) =>
    createAction(ActionTypes.GROUP_MEMBERS_CANT_REMOVE, {
      level: AlertLevel.DANGER,
      text,
      error,
      priority: priority.medium,
      category: category.chat,
      actionText: 'Ok',
      action,
      name: 'remove-group-member-error'
    }),
  meetingConcurrentError: (error: Error = {}, action: () => void) =>
    createAction(ActionTypes.MEETING_CONCURRENT_ERROR, {
      action,
      actionText: 'Ok',
      error,
      priority: priority.high,
      category: category.meetNow,
      level: AlertLevel.DANGER,
      name: 'concurrent-meeting-error'
    }),
  meetingErrorCreating: (error: Error = {}, action: () => void) =>
    createAction(ActionTypes.MEETING_ERROR_CREATING, {
      action,
      actionText: 'Ok',
      error,
      priority: priority.high,
      category: category.meetNow,
      level: AlertLevel.DANGER,
      name: 'create-meeting-error'
    }),
  inviteExternalContactError: (text = '', error: Error = {}, action: () => void) =>
    createAction(ActionTypes.EXTERNAL_CONTACT_ERROR, {
      action,
      actionText: 'Ok',
      text,
      error,
      priority: priority.high,
      category: category.chat,
      level: AlertLevel.DANGER,
      name: 'external-contact-error'
    }),
  apiError: (error: Error = {}, action: () => void) => {
    const actionText = error.retryCallback ? 'Retry' : 'OK';
    return createAction(ActionTypes.API_ERROR, {
      name: error.name,
      error: error.error,
      category: error.category,
      level: AlertLevel.DANGER,
      priority: priority.high,
      actionText,
      action
    });
  },
  networkError: (action: () => void) => {
    return createAction(ActionTypes.NETWORK_ERROR, {
      name: 'offline-warning',
      error: 'offline',
      category: category.network,
      level: AlertLevel.WARNING,
      priority: priority.highest,
      actionText: 'OK',
      action,
      meta: {
        disableForComponents: ['chat', 'profile', 'settings', 'privacy']
      }
    });
  },
  settingsUpdatingError: (dismiss: () => void) => {
    return createAction(ActionTypes.SETTINGS_UPDATING_ERROR, {
      name: 'error-updating-settings',
      level: AlertLevel.DANGER,
      priority: priority.highest,
      dismiss
    });
  },
  settingsFetchingError: (dismiss: () => void) => {
    return createAction(ActionTypes.SETTINGS_FETCHING_ERROR, {
      name: 'error-fetching-settings',
      level: AlertLevel.DANGER,
      priority: priority.highest,
      dismiss
    });
  },
  discoverFetchingError: (error: Error = {}, action: () => void) => {
    return createAction(ActionTypes.DISCOVER_FETCHING_ERROR, {
      name: 'discover-fetching-error',
      error,
      category: category.discover,
      level: AlertLevel.WARNING,
      priority: priority.medium,
      actionText: 'OK',
      action,
      meta: {
        enableForComponents: ['discover']
      }
    });
  }
};

export default actions;
export type Actions = ActionsUnion<typeof actions>;

export const joinGroupError = (text = '', error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(actions.groupCantJoinError(text, error, () => dispatch(actions.clearError())));
};

export const createGroupError = (text = '', error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(actions.groupCantCreateError(text, error, () => dispatch(actions.clearError())));
};

export const leaveGroupError = (text = '', error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(actions.groupCantLeaveError(text, error, () => dispatch(actions.clearError())));
};

export const removeGroupMemberError = (text = '', error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(actions.groupMembersCantRemoveError(text, error, () => dispatch(actions.clearError())));
};

export const meetingConcurrentError = (error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(actions.meetingConcurrentError(error, () => dispatch(actions.clearError())));
};

export const meetingCreateError = (error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(actions.meetingErrorCreating(error, () => dispatch(actions.clearError())));
};

export const inviteExternalContactError = (text = '', error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(actions.inviteExternalContactError(text, error, () => dispatch(actions.clearError())));
};

export const updateSettingsError = (): Thunk => async (dispatch) => {
  return dispatch(actions.settingsUpdatingError(() => dispatch(actions.clearError())));
};

export const fetchSettingsError = (): Thunk => async (dispatch) => {
  return dispatch(actions.settingsFetchingError(() => dispatch(actions.clearError())));
};

export const fetchDiscoverError = (error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(actions.discoverFetchingError(error, () => dispatch(actions.clearError())));
};

export const apiErrorThunk = (error: Error = {}): Thunk => async (dispatch) => {
  return dispatch(
    actions.apiError(error, () => {
      dispatch(actions.clearError());
      if (error.retryCallback) {
        error.retryCallback();
      }
    })
  );
};

export const offlineWarning = (): Thunk => async (dispatch) => {
  return dispatch(
    actions.networkError(() => {
      dispatch(actions.clearNetworkError());
    })
  );
};
