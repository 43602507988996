import localConfig from './local';

const config: EnvConfig = {
  ...localConfig,
  env: 'stage',
  oauth: {
    client_id: '259f5a16-3601-4e14-8c87-3da1b6603493',
    url: 'https://authenticationstage.logmeininc.com'
  },
  externalLinks: {
    ...localConfig.externalLinks,
    globalUrl: 'https://globalstage.gotomeeting.com',
    g2mCreateProfile: 'https://globalstage.gotomeeting.com/#setup',
    g2mTranscripts: 'https://transcriptsstage.gotomeeting.com',
    g2a: {
      urlFrontEnd: 'https://start.stage-gotoassist.com',
      urlMarketing: 'https://www.gotoassist.com'
    },
    g2t: {
      ...localConfig.externalLinks.g2t,
      urlFrontEnd: 'https://globalstage.gototraining.com'
    },
    g2w: {
      ...localConfig.externalLinks.g2w,
      urlFrontEnd: 'https://globalstage.gotowebinar.com'
    },
    ov: {
      urlFrontEnd: 'https://globalstage.openvoice.com',
      urlMarketing: 'http://www.openvoice.com'
    },
    adminCenter: 'https://adminstage.logmeininc.com',
    g2mm: 'https://meet.stage.goto.com',
    unifiedLaunchUrl: 'https://meet.stage.goto.com',
    secureServiceMyAccount: 'https://myaccountstage.logmeininc.com',
    profileInfo: 'https://profilestage.servers.getgo.com/personal-info',
    meetingDiagnostics: 'https://stage.diagnostics.gotomeeting.com',
    gotoAppDownloadLink: 'https://globalstage.gotomeeting.com/install/gotoapp'
  },
  rest: {
    ...localConfig.rest,
    ejabberdUrl: 'https://xmpp-stage.servers.getgo.com',
    meetingService: 'https://apiglobalstage.gotomeeting.com',
    brandingService: 'https://branding.services-stage.goto.com',
    meetingRoomService: 'https://apistage-g2m-external.devplatform-prod.expertcity.com/meeting-rooms',
    meetingLauncher: 'https://meet.stage.goto.com',
    login: 'https://authenticationstage.logmeininc.com',
    accounts: 'https://iamstage.servers.getgo.com',
    externalAdmin: 'https://adminstage.logmeininc.com',
    invitationService: 'https://invitation-stage.service.gotomeeting.com',
    insights: 'https://apistage.insights.gotomeeting.com',
    chatSessionState: 'https://csssstage.services.gotomeeting.com/csss',
    hubRolloutApi: 'https://stage.hub.gotomeeting.com/rest/hub',
    searchOrchestrationService: 'https://apistage.globalsearch.logmeininc.com/globalsearch/sos',
    sessionAnalytics: 'https://apistage.gotoinfraanalytics.logmeininc.com/xqmanalytics/api'
  },
  boldChat: {
    ...localConfig.boldChat,
    boldChatFloatId: '739126313755994970'
  },
  xmpp: {
    ...localConfig.xmpp,
    baseUrl: 'xmpp-stage.servers.getgo.com',
    url: 'wss://xmpp-stage.servers.getgo.com:443/websocket',
    fallback: 'https://xmpp-stage.servers.getgo.com:443/http-bind'
  },
  telemetry: {
    ...localConfig.telemetry,
    url: 'https://telemetrystage.servers.getgo.com'
  },
  logging: {
    ...localConfig.logging,
    url: 'https://loggingstage.getgo.com'
  },
  launchDarkly: {
    clientSideId: '5d382ae30a66b407bae007b7'
  },
  socialGraph: {
    url: 'https://socialgraphstage.servers.getgo.com'
  },
  upload: {
    url: 'https://apistage.services.gotomeeting.com/fileupload/tenants/pulse/path',
    limit: 52428800
  },
  preview: {
    url: 'https://thumbnail.gotomeeting.com/',
    profile: 'https://stage.gotomeet.me/_preview'
  },
  notifications: {
    ...localConfig.notifications,
    url: 'https://apistage.services.gotomeeting.com',
    vapidPublicKey: 'BNK37D-zxuFRNPjzjsf_c9hSjm0l08W9ZcnrVcphGV4wmvrUDuDvQn8tdqgDP3RsclmU_1L0OTI3L2Xu9VbsfP4='
  },
  emojis: {
    url: 'https://stage.messenger.gotomeeting.com/emojione/${code}.svg'
  },
  engagementEngine: {
    discoverUrl: 'https://g2m-hub.stage.ee.getgo.com'
  },
  avatarServiceUrl: 'https://avatarsstage.servers.getgo.com'
};

export default config;
