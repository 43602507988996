import * as React from 'react';
import styled from '../../../../styled-components';
import { UnstyledButton } from '../Button/Button';
import { themeGet } from '@styled-system/theme-get';
import { CloseButton } from '../Button/CloseButton';

const hoverEffect = () => `
  background: #d8f6ff;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

const Card = styled((props) =>
  props.onClick ? (
    <UnstyledButton
      className={props.className}
      onClick={props.onClick}
      data-testid="meeting-card"
      aria-label={props.ariaLabel}
    >
      {props.children}
    </UnstyledButton>
  ) : (
    <div className={props.className} data-testid="meeting-card">
      {props.children}
    </div>
  )
)`
  width: 100%;
  outline: none;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  cursor: ${(props) => !props.hasHoverEffect && 'default'};
  position: relative;
  color: ${themeGet('colors.surfaceOn')};
  text-align: left;

  ${(props) =>
    props.disabled &&
    `
    filter: grayscale(100%);
    pointer-events: none;
    cursor: default;
    opacity: 0.8;
  `}

  ${(props) => props.isSelected && hoverEffect}

  &:hover,
  &:focus {
    ${(props) => props.hasHoverEffect && !props.disabled && hoverEffect};
  }

  &:focus:enabled {
    box-shadow: 0 0 0 1px ${themeGet('colors.surface')}, 0 0 0 3px ${themeGet('colors.primary')};
  }
`;

const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

interface Props {
  onClick?: () => void;
  isSelected?: boolean;
  children: React.ReactNode;
  disabled?: boolean;
  hasHoverEffect?: boolean;
  onClose?: () => void;
  ariaLabel?: string;
}

export const SelectableCard: React.FC<Props> = ({
  hasHoverEffect = true,
  onClick,
  isSelected,
  onClose,
  children,
  ariaLabel,
  ...props
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <Card onClick={onClick} isSelected={isSelected} hasHoverEffect={hasHoverEffect} ariaLabel={ariaLabel} {...props}>
        {children}
      </Card>
      {onClose && <PositionedCloseButton onClick={onClose} size={16} dataTestId="card-close" color="#9aa8bb" />}
    </div>
  );
};
