import { History } from 'history';
import { generatePath } from 'react-router-dom';
import { getRouteFor } from '../app/services/NavService';

export const tryRedirectToNextChatConversation = (nextJid: string | null | undefined, history: History) => {
  if (nextJid) {
    const path = generatePath(getRouteFor('chatConversation'), { roomId: nextJid });
    history.replace(path);
    return true;
  }
  return false;
};
