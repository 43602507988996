import React from 'react';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import Mousetrap from 'mousetrap';
import MultiSelectContactSearchInput, {
  Props as MultiContactSelectorProps
} from '../../Common/MultiContactSelector/MultiContactSelector';
import { Actions, Body, Header, Page } from '../../Common/PageTemplate';
import './CreateGroup.css';
import { Contact } from '../../../../types/pulse-web';
import { bindKey } from '../../../../lib/keybindings';
import { KeyNames } from '../../../../lib/keycodes';

const messages = defineMessages({
  title: {
    id: 'createGroup.title',
    defaultMessage: 'Create a new group'
  },
  groupTitle: {
    id: 'createGroup.groupTitle',
    defaultMessage: 'Group name'
  },
  groupPlaceholder: {
    id: 'createGroup.groupPlaceholder',
    defaultMessage: 'e.g. Team Awesome'
  },
  membersTitle: {
    id: 'createGroup.membersTitle',
    defaultMessage: 'Group members'
  },
  membersPlaceholder: {
    id: 'createGroup.membersPlaceholder',
    defaultMessage: 'Search by name or email'
  },
  typeTitle: {
    id: 'createGroup.typeTitle',
    defaultMessage: 'Group type'
  },
  typePrivate: {
    id: 'createGroup.typePrivate',
    defaultMessage: 'Private'
  },
  typePublic: {
    id: 'createGroup.typePublic',
    defaultMessage: 'Public'
  },
  typePrivateDescription: {
    id: 'createGroup.typePrivateDescription',
    defaultMessage: 'New group members can only join after receiving an invite from existing members.'
  },
  typePublicDescription: {
    id: 'createGroup.typePublicDescription',
    defaultMessage: 'Anyone on your team can search for and join this group.'
  },
  cancelButtonText: {
    id: 'createGroup.cancelButtonText',
    defaultMessage: 'Cancel'
  },
  createButtonText: {
    id: 'createGroup.createButtonText',
    defaultMessage: 'Create group'
  },
  groupExists: {
    id: 'createGroup.groupExists',
    defaultMessage: 'A public group with that name already exists. Choose a different name.'
  }
});

interface Props extends MultiContactSelectorProps {
  onCancel?: () => void;
  onCreateGroup?: (name: string, contacts: Contact[], isPublic: boolean) => void;
  clearQuery?: () => void;
  enableCancel?: boolean;
  initialGroupName?: string;
  isExistingPublicGroup?: boolean;
  isFetching?: boolean;
  onQueryGroup?: (query: string, index?: number) => void;
  maxInputLength?: number;
}

interface State {
  isPublic: boolean;
  groupQuery: string;
  selectedContacts: Contact[];
}

class CreateGroup extends React.Component<Props & WrappedComponentProps, State> {
  createGroupButton: any;
  unbindEscape: () => void = () => {};

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = {
      isPublic: true,
      groupQuery: props.initialGroupName || '',
      selectedContacts: []
    };
  }

  componentDidMount() {
    const { onCancel = () => {}, onQueryGroup = () => {} } = this.props;
    this.unbindEscape = bindKey(onCancel as () => void, KeyNames.ESCAPE);

    if (this.state.isPublic) {
      onQueryGroup(this.state.groupQuery);
    }
  }

  componentWillUnmount() {
    const { clearQuery = () => {} } = this.props;
    this.unbindEscape();
    clearQuery();
  }

  handleChangeInGroupQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onQueryGroup = () => {} } = this.props;

    const groupQuery = e.target.value;
    this.setState({ groupQuery });

    if (this.state.isPublic) {
      onQueryGroup(e.target.value);
    }
  };

  handleCreateGroup = () => {
    const { onCreateGroup = () => {} } = this.props;
    // disable button, prevents multiple clicks creating multiple groups
    if (this.createGroupButton) {
      this.createGroupButton.disabled = true;
    }
    onCreateGroup(this.state.groupQuery.trim(), this.state.selectedContacts, this.state.isPublic);
  };

  handleSelectedContactsChange = (selectedContacts: Contact[]) => {
    this.setState({ selectedContacts });
  };

  handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { onQueryGroup = () => {} } = this.props;

    const isPublic = e.target.value === 'public';

    this.setState({
      isPublic
    });

    if (isPublic) {
      onQueryGroup(this.state.groupQuery);
    }
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    Mousetrap.trigger(e.key);
  };

  render() {
    const { enableCancel = true, maxInputLength = 30, onCancel = () => {} } = this.props;
    const { formatMessage } = this.props.intl;
    const createGroupDisabled =
      this.props.isFetching ||
      !this.state.groupQuery.trim().length ||
      (this.props.isExistingPublicGroup && this.state.isPublic);
    const groupInputProps = {
      placeholder: formatMessage(messages.groupPlaceholder),
      onChange: this.handleChangeInGroupQuery,
      onKeyDown: this.handleKeyDown,
      value: this.state.groupQuery || ''
    };

    return (
      <Page className="create-group">
        <Header enableCancel={enableCancel} onCancel={onCancel}>
          <FormattedMessage {...messages.title} />
        </Header>

        <Body>
          <>
            <div className="add-group">
              <FormattedMessage tagName={'p'} {...messages.groupTitle} />
              <div className="create-group-input">
                <input autoFocus {...groupInputProps} maxLength={maxInputLength} />
              </div>
            </div>

            {this.props.isExistingPublicGroup && this.state.isPublic && (
              <p className="group-exists-error">{formatMessage(messages.groupExists)}</p>
            )}

            <div className="add-group-members">
              <FormattedMessage tagName={'p'} {...messages.membersTitle} />
              <MultiSelectContactSearchInput
                {...this.props}
                onSelectedContactsChange={this.handleSelectedContactsChange}
              />
            </div>

            <div className="select-type">
              <FormattedMessage tagName={'p'} {...messages.typeTitle} />

              <div className="radio-group">
                <div className="radio">
                  <input
                    type="radio"
                    id="radio-group-type-private"
                    value="private"
                    checked={!this.state.isPublic}
                    onChange={this.handleTypeChange}
                  />
                  <label className="primary-label" htmlFor="radio-group-type-private">
                    <FormattedMessage {...messages.typePrivate} />
                  </label>
                  <label className="description" htmlFor="radio-group-type-private">
                    <FormattedMessage {...messages.typePrivateDescription} />
                  </label>
                </div>

                <div className="radio">
                  <input
                    type="radio"
                    id="radio-group-type-public"
                    value="public"
                    checked={this.state.isPublic}
                    onChange={this.handleTypeChange}
                  />
                  <label className="primary-label" htmlFor="radio-group-type-public">
                    <FormattedMessage {...messages.typePublic} />
                  </label>
                  <label className="description" htmlFor="radio-group-type-public">
                    <FormattedMessage {...messages.typePublicDescription} />
                  </label>
                </div>
              </div>
            </div>

            <Actions>
              <button className="btn btn-secondary" onClick={onCancel}>
                {formatMessage(messages.cancelButtonText)}
              </button>
              <button
                ref={(button) => {
                  this.createGroupButton = button;
                }}
                className="btn btn-primary create-group-button"
                onClick={this.handleCreateGroup}
                disabled={createGroupDisabled}
              >
                {formatMessage(messages.createButtonText)}
              </button>
            </Actions>
          </>
        </Body>
      </Page>
    );
  }
}

export default injectIntl(CreateGroup, { forwardRef: true });
