import React, { useEffect } from 'react';
import styled from '../../styled-components';
import config from 'config';
import { VideoInteractions } from '../../../models/EngagementEngine';

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0;
`;

const VideoPlayer = styled((props) => (
  <div className={`wistia_embed wistia_async_${props.videoId} ${props.className}`} />
))`
  height: 350px;
  width: 620px;

  @media (max-width: 1200px) {
    width: 400px;
    height: 225px;
  }

  @media (max-width: 900px) {
    width: 284px;
    height: 160px;
  }
`;

interface Video {
  remove: () => void;
  bind: (...args: any[]) => void;
  unbind: (...args: any[]) => void;
}

interface Props {
  videoId: string;
  handleVideoInteraction: (interactionType: VideoInteractions) => void;
}

const DiscoverVideoPlayer: React.FC<Props> = (props) => {
  let handle: Video;
  const embedOptions = {
    playerColor: '#006AFF',
    controlsVisibleOnLoad: true,
    volumeControl: true,
    fullscreenButton: true,
    playButton: true,
    smallPlayButton: true,
    settingsControl: true
  };

  const { videoId, handleVideoInteraction } = props;

  const onPlay = () => {
    handleVideoInteraction(VideoInteractions.play);
  };

  const onPause = () => {
    handleVideoInteraction(VideoInteractions.pause);
  };

  const onEnd = () => {
    handleVideoInteraction(VideoInteractions.playbackComplete);
  };

  const onReadyCallback = (video: Video) => {
    if (!handle) {
      handle = video;
      handle.bind('play', onPlay);
      handle.bind('pause', onPause);
      handle.bind('end', onEnd);
    }
  };

  useEffect(() => {
    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = config.wistiaScriptUrl;
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }

    window._wq = window._wq || [];
    window._wq.push({
      id: videoId,
      options: embedOptions,
      onReady: onReadyCallback
    });
  }, []);

  useEffect(
    () => () => {
      handle?.remove();
    },
    []
  );

  return (
    <VideoWrapper>
      <VideoPlayer videoId={videoId} />
    </VideoWrapper>
  );
};

export default DiscoverVideoPlayer;
