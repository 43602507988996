import React, { useEffect, useState } from 'react';
import styled from '../../../styled-components';
import { ElasticSearchPage, SessionFromElasticSearch } from '../../../view-model/modules/meetings/meetingsReducer';
import { Box } from '../../Common/Toolkit/Box/Box';
import classNames from 'classnames';
import { UnstyledButton } from '../../Common/Toolkit/Button/Button';
import { TranscriptsTabContainer } from '../GlobalSearchDetailsView';
import { AttendeeSessions } from './AttendeeSessions';
import { LoadMoreButtonTranscript } from './TitleTab';
import { Avatar, generateRandomColors } from '../GlobalSearchComponents';

const SessionContainer = styled.div`
  margin-top: 16px;
`;

const ExpandButton = styled(
  (props: React.ImgHTMLAttributes<HTMLImageElement> & { onClick: () => void; expanded?: boolean }) => {
    const { onClick } = props;
    return (
      <UnstyledButton onClick={onClick}>
        <i
          className={classNames('togo-icon togo-icon-chevron togo-icon-2x', {
            'togo-icon-rotate-270': !props.expanded
          })}
          data-testid="expand-button"
          style={{ color: !props.expanded ? '#9AA9BB' : '#878E99' }}
        />
      </UnstyledButton>
    );
  }
)`
  vertical-align: text-bottom;
  z-index: 10;
`;

const ListItems = styled.li`
  margin-bottom: 16px;
  border-radius: 3px;
  border: solid 1px #eeeeee;
  &:hover {
    border: solid 1px rgba(76, 83, 93, 0.24);
  }
`;

const AttendeesTextContainer = styled.h3<{ color?: string; textAlign?: string; whiteSpace?: string }>`
  font-size: 20px;
  margin: -4px 0 5px 0;
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
  em {
    background: #fff7d9;
    font-style: normal;
  }
`;

const AttendeeDetails = styled.div`
  margin-left: 14px;
  flex: 1;
  display: flex;
  flex-direction: column;
  display: inline-grid;
  word-break: break-all;
  padding-top: 3px;
  margin-right: 24px;
`;

interface Props {
  selectedKey: string;
  sessionList: {
    session: SessionFromElasticSearch[];
    elasticSearchPage: ElasticSearchPage;
  };
  handleRecording: (session: SessionFromElasticSearch) => void;
  loadMore: (attendeeName?: string) => void;
  fetchAttendeeSessions: (attendeeName?: string) => void;
  disableOnClickOutside: () => void;
}

export const AttendeeDetailsRow: React.FC<Props> = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const attendeeSessions: Array<React.ReactElement<any>> = [];

  const onClickExpand = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (expanded) {
      props.fetchAttendeeSessions(props.selectedKey);
    }
  }, [props.selectedKey, expanded]);

  const removeTagsFromString = (input: string) => {
    return input ? input.replace(/(<([^>]+)>)/gi, '') : '';
  };

  props.sessionList &&
    props.sessionList?.session &&
    props.sessionList?.session.length &&
    props.sessionList?.session.map((item: any) => {
      attendeeSessions.push(
        <AttendeeSessions
          loadMore={props.loadMore}
          handleRecording={props.handleRecording}
          session={item}
          disableOnClickOutside={props.disableOnClickOutside}
          selectedKey={props.selectedKey}
          key={'attendee' + item.session.id}
          selectedAttendee={props.selectedKey}
        />
      );
    });

  if (attendeeSessions && attendeeSessions.length && props.sessionList.elasticSearchPage.hasNext > 0) {
    attendeeSessions.push(
      <div key="loadMore-attendee-session-button">
        <LoadMoreButtonTranscript
          className={'loadMore-attendee-session-class'}
          id="loadMore-attendee-session"
          onClick={() => props.loadMore(props.selectedKey)}
          isLoading={false}
        />
      </div>
    );
  }

  const attendeeName = removeTagsFromString(props.selectedKey)?.substring(0, 2);
  const participantContainer = (
    <ListItems>
      <TranscriptsTabContainer>
        <Box flexDirection={'column'}>
          <Box justifyContent={'space-between'} alignItems={'center'}>
            <Box alignItems={'center'}>
              <Avatar background={generateRandomColors(attendeeName)}>{attendeeName}</Avatar>
              <AttendeeDetails>
                <AttendeesTextContainer>{props.selectedKey}</AttendeesTextContainer>
              </AttendeeDetails>
            </Box>
            <ExpandButton expanded={expanded} onClick={onClickExpand} />
          </Box>
          {expanded && <>{attendeeSessions}</>}
        </Box>
      </TranscriptsTabContainer>
    </ListItems>
  );

  return <SessionContainer>{participantContainer}</SessionContainer>;
};
