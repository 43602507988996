import { REHYDRATE } from 'redux-persist/constants';
import { purgeStoredState } from 'redux-persist';
import localForage from 'localforage';
import { Middleware } from '../types';

function shouldSkipValidation(level1: any, level2: any) {
  // skip validation for reducers that have their own hydration logic and version check
  if (level1 === 'core' && level2 === 'preferences') {
    return true;
  }

  return false;
}

function validate(state: any, value: any) {
  return state && value && typeof state === typeof value && Object.keys(state).every((key) => key in value);
}

const blackListStateKeys = ['reduxStateVersion'];

const validateRehydrateMiddleware: Middleware = (store) => (next) => (action) => {
  switch (action.type) {
    case REHYDRATE:
      if (action.payload && typeof action.payload === 'object') {
        const newPayload: any = {};
        const state = store.getState() as any;

        if (
          !action.payload ||
          !action.payload.reduxStateVersion ||
          state.reduxStateVersion !== action.payload.reduxStateVersion
        ) {
          purgeStoredState({
            storage: localForage as any
          });
          return null;
        }

        /** Iterates through state (existing redux data) and action.payload (rehydrated redux data).
         * If there is any difference on the 2nd level properties, it invalidates the whole subtree by
         * not copying the payload in the newPayload which is sent to reducers for rehydrating.
         *
         * You can invalidate the subtree by adding a property like this in your reducer/default state:
         * existing state:
         * messaging.contacts: {
         *   ...
         *   v2: true
         * }
         * new state:
         * messaging.contacts: {
         *   ...
         *   v3: true
         * }
         *
         * In this case messaging.contacts will be dropped and not rehydrated
         */
        Object.keys(action.payload).forEach((level1Key) => {
          if (!blackListStateKeys.includes(level1Key) && state[level1Key]) {
            Object.keys(state[level1Key]).forEach((level2Key) => {
              if (!action.payload[level1Key][level2Key]) {
                return;
              }

              const persistedState = { ...action.payload[level1Key][level2Key] };
              const existingState = state[level1Key][level2Key];

              // inject undefined values, since they get dropped during JSON serialization with redux-persist
              Object.keys(existingState).forEach((key) => {
                if (existingState[key] === undefined && !(key in persistedState)) {
                  persistedState[key] = undefined;
                }
              });

              if (shouldSkipValidation(level1Key, level2Key) || validate(existingState, persistedState)) {
                if (!newPayload[level1Key]) {
                  newPayload[level1Key] = {};
                }
                newPayload[level1Key][level2Key] = persistedState;
              }
            });
          }
        });

        action.payload = newPayload;
      }
      break;
  }

  return next(action);
};

export default validateRehydrateMiddleware;
