import { Member } from '@getgo/caps-redux';

/* eslint-disable no-useless-escape */
const CURSOR_BEFORE_REGEX_WITH_AT = /(^|\s)(@\S*)$/;
const CURSOR_AFTER_REGEX = /^[^\s]+/;
const ALL_MENTIONS_REGEX = /((^|\s)@\S*)/;
const PUNCTUATION_REGEX = /^[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]*$/;

const getFirstMatchInString = (completeString: string) => {
  const matchingStrings = completeString.match(CURSOR_BEFORE_REGEX_WITH_AT);

  return matchingStrings ? matchingStrings[0] : '';
};

const getLastMatchInString = (completeString: string) => {
  const matchingStrings = completeString.match(CURSOR_AFTER_REGEX);

  return matchingStrings ? matchingStrings[matchingStrings.length - 1] : '';
};

const isSearchStringAtTheBeginningOfNameOrEmail = (mention: { name: string; email: string }, searchString: string) => {
  const queryText = searchString.toLowerCase();
  const mentionNames = mention.name.split(' ');
  const EMAIL_SEPARATOR = /\-|\.|@|\+|\_/;
  const emailSplit = mention.email.split(EMAIL_SEPARATOR);

  return (
    mentionNames.some((name) => name.toLowerCase()[0] === queryText) ||
    emailSplit.some((emailPart) => emailPart.toLowerCase()[0] === queryText)
  );
};

const getMentionList = (groupMembers: Member[]) => {
  return groupMembers.filter((member) => {
    return member.jid !== member.name;
  });
};

export const getCursorStrings = (value: string, cursorPosition: number) => {
  const stringBeforeCursor = getFirstMatchInString(value.substring(0, cursorPosition));

  const stringAfterCursor = getLastMatchInString(value.substring(cursorPosition));

  return { stringBeforeCursor, stringAfterCursor };
};

export const cutStartingAtSymbol = (message: string) => {
  const index = message.indexOf('@');
  if (index === -1 || (index !== 0 && index !== 1)) {
    return message;
  }
  return message.substring(index + 1);
};

export const extractAndMarkMentionsAtSend = (
  originalMessage: string,
  originalMentionedList: Array<{ mentionText: string; jid: string }>
) => {
  let message = '';
  const actualMentions: Array<{
    displayName: string;
    jid: string;
    startIndex: number;
    endIndex: number;
  }> = [];
  const mentionedListWorkingCopy = originalMentionedList.slice();
  const textParts = originalMessage.split(ALL_MENTIONS_REGEX);
  textParts.forEach((part) => {
    const trimmed = part.trim();
    if (trimmed === '') {
      return;
    }
    const indexInMentionedList = mentionedListWorkingCopy.findIndex((mentioned) => {
      return !!(
        trimmed.startsWith(mentioned.mentionText) &&
        trimmed.slice(mentioned.mentionText.length).match(PUNCTUATION_REGEX)
      );
    });
    if (indexInMentionedList < 0) {
      message += part;
    } else {
      const startIndex = message.length;
      const currentMention = mentionedListWorkingCopy[indexInMentionedList];
      message += ` **${currentMention.mentionText}**`;
      actualMentions.push({
        displayName: currentMention.mentionText,
        jid: currentMention.jid,
        startIndex,
        endIndex: message.length - 1
      });

      message += trimmed.slice(currentMention.mentionText.length);
      mentionedListWorkingCopy.splice(indexInMentionedList, 1);
    }
  });

  return { message, actualMentions };
};

export const createFilteredMentionList = (groupMembers: Member[], searchString: string) => {
  const mentionList = getMentionList(groupMembers);

  if (searchString.length === 0) {
    return mentionList;
  }

  return mentionList.filter((mention) => {
    if (searchString.length === 1) {
      return isSearchStringAtTheBeginningOfNameOrEmail(mention, searchString);
    }

    const name = mention.name.replace(' ', '').toLowerCase();
    const queryText = searchString.toLowerCase();

    return mention.email.indexOf(queryText) !== -1 || name.indexOf(queryText) !== -1;
  });
};

export const removeWhiteSpace = (displayName: string) => {
  return displayName.replace(/\s/g, '');
};
