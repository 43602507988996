import React from 'react';
import { defineMessages, FormattedMessage, MessageDescriptor } from 'react-intl';
import Select, { components } from 'react-select';
import { isHoverSupported } from '../../../services/ClientService';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { PreferencesBadge } from './PreferencesBadge/PreferencesBadge';
import styled from '../../../styled-components';
import { Checkbox, Radio } from '../Toolkit/Input/Selectable';
import { GhostButton } from '../Toolkit/Button/GhostButton';
import { Block, Description, ErrorMessageInline } from '../Toolkit/FormElements/TextElements';
import { themeGet } from '@styled-system/theme-get';
import { StarEmpty, StarFull } from '../Toolkit/Icon/ReactIcons';
import ReactTooltip from 'react-tooltip';

const sortListItems = (items: ListItem[]) => {
  return items.sort((a, b) => a.label.localeCompare(b.label));
};

const renderMenu = (menuProps: any) => <Menu {...menuProps} />;

const messages = defineMessages({
  noResultText: {
    id: 'preferences.searchList.noResultText',
    defaultMessage: 'No results found'
  },
  searchInputFieldPlaceholder: {
    id: 'preferences.searchList.searchInputFieldPlaceholder',
    defaultMessage: 'Type to search'
  },
  addAllLabel: {
    id: 'preferences.searchList.addAllLabel',
    defaultMessage: 'Add all'
  },
  clearAll: {
    id: 'preferences.searchList.clearAll',
    defaultMessage: 'Clear all'
  }
});

const AddAllButton = styled(GhostButton)`
  font-family: 'lato-regular';
  font-weight: normal;
  text-transform: none;
  color: ${themeGet('link.colors.primary')};

  &,
  .ghost-button-label {
    padding-left: 0;
  }
`;

const Menu = styled(components.Menu)`
  && {
    box-shadow: 0 5px 8px 3px rgba(0, 0, 0, 0.2);
    color: #000;
    margin-top: 4px;
  }

  .react-select__option--is-focused {
    background-color: #f0f0f0;
  }
`;

const StyledSelect = styled(Select)`
  .react-select__value-container {
    padding: 2px 4px;
  }

  .react-select__multi-value {
    color: #007eff;
    border: 1px solid rgba(0, 126, 255, 0.24);
    background-color: rgba(0, 126, 255, 0.08);
  }

  .react-select__multi-value__label {
    padding: 3px 6px 3px 3px;
  }

  .react-select__multi-value__remove {
    order: -1;
    border-radius: 2px 0 0 2px;
    border-right: 1px solid rgba(0, 126, 255, 0.24);
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 113, 230, 0.08);
      color: #0071e6;
    }
  }

  .react-select__control.react-select__control--is-disabled {
    background-color: #f9f9f9;
  }

  &.react-select--is-disabled .react-select__multi-value__remove {
    display: none;
  }

  &.react-select--is-disabled .react-select__multi-value {
    color: #333;
    border: 1px solid #e3e3e3;
    background-color: #fcfcfc;
  }

  &.react-select--is-disabled .react-select__multi-value__label {
    padding: 3px 6px;
  }
`;
StyledSelect.displayName = 'StyledSelect';

const MultiValueOption = styled.div`
  cursor: pointer;
  padding: 3px 6px 3px 3px;
  color: rgb(51, 51, 51);
  font-size: 85%;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
  border-radius: 0 2px 2px 0;
  overflow: hidden;
  display: flex;
  align-items: center;

  svg {
    margin-left: 2px;

    path {
      fill: rgb(107, 107, 107);
    }
  }

  .full-star {
    display: none;
  }

  &.preferred-option {
    color: #004fbd;
    cursor: default;
    svg path {
      fill: #004fbd;
    }
  }

  &:hover:not(.preferred-option) {
    background-color: rgba(0, 113, 230, 0.17);

    .full-star {
      display: block;
    }
    .empty-star {
      display: none;
    }
  }

  .react-select--is-disabled & {
    color: rgb(51, 51, 51);

    svg {
      path {
        fill: rgb(107, 107, 107);
      }
    }
  }
`;

const PreferredLabel = styled.div`
  margin-top: 8px;
`;

interface ListItem {
  value: string;
  label: string;
}

interface MultiValue {
  children: React.ReactElement<any> | null;
  data: ListItem;
}

interface Props {
  id: string; // for automation
  type: 'checkbox' | 'radio';
  badge?: 'new' | 'beta';
  checked: boolean;
  onCheckboxChange: (value: boolean) => void;
  onInputFieldChange: (values: string[]) => void;
  selectedOptions: ListItem[];
  disabled: boolean;
  allowedSearchValues: ListItem[];
  isLoading?: boolean;
  labelMessage: MessageDescriptor;
  labelMessageValues?: { [key: string]: string };
  errorMessage?: MessageDescriptor;
  descriptionMessage?: MessageDescriptor;
  disabledTooltip?: MessageDescriptor;
  enabledTooltip?: MessageDescriptor;
  preferredMessage?: MessageDescriptor;
  enablePreferred?: boolean;
  preferredOptionTooltip?: MessageDescriptor;
  unselectedPreferredOptionTooltip?: MessageDescriptor;
}

class PreferencesItemCheckedSearchList extends React.PureComponent<Props & WrappedComponentProps> {
  componentDidUpdate() {
    if (this.props.enablePreferred) {
      ReactTooltip.rebuild();
      ReactTooltip.hide();
    }
  }

  handleCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onCheckboxChange(evt.target.checked);
  };

  handleInputFieldChange = (selectedOptions: ListItem[]) => {
    this.props.onInputFieldChange(selectedOptions ? selectedOptions.map((option) => option.value) : []);
  };

  handleAddAll = () => {
    if (!this.props.disabled || this.props.checked) {
      const firstOptionValue = this.props.selectedOptions?.[0]?.value;
      const allowedSearchValues = this.props.allowedSearchValues.map((option) => option.value);

      this.props.onInputFieldChange(
        firstOptionValue && this.props.enablePreferred
          ? [firstOptionValue, ...allowedSearchValues.filter((value) => value !== firstOptionValue)]
          : allowedSearchValues
      );
    }
  };

  renderClearIndicator = (props: any) => {
    const innerProps = {
      ...props,
      innerProps: {
        ...props.innerProps,
        'aria-label': this.props.intl.formatMessage(messages.clearAll)
      }
    };

    const ClearIndicator = components.ClearIndicator as React.ComponentType;
    return <ClearIndicator {...innerProps} />;
  };

  renderSelectContainer = (props: any) => {
    const ownProps = {
      ...props,
      innerProps: { ...props.innerProps, 'data-testid': props.innerProps.id }
    };
    const Container = components.SelectContainer as React.ComponentType;
    return <Container {...ownProps} />;
  };

  renderMultiValuePreferred = ({ children, data }: MultiValue) => {
    const firstOptionValue = this.props.selectedOptions?.[0]?.value;
    const isPreferred = firstOptionValue === data.value;
    const tooltipMessage = isPreferred
      ? this.props.preferredOptionTooltip
      : this.props.unselectedPreferredOptionTooltip;

    const updatePreferredOrder = (e: React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();

      const reorderedOptions = [data, ...this.props.selectedOptions.filter((option) => option.value !== data.value)];

      this.props.onInputFieldChange(reorderedOptions.map((option) => option.value));
    };

    return (
      <MultiValueOption
        onMouseDown={updatePreferredOrder}
        data-tip={tooltipMessage ? this.props.intl.formatMessage(tooltipMessage) : null}
        data-delay-show="300"
        data-place="top"
        className={`react-select__multi-value__label ${isPreferred ? 'preferred-option' : ''}`}
      >
        {children}
        {isPreferred ? (
          <StarFull />
        ) : (
          <>
            <StarFull className="full-star" />
            <StarEmpty className="empty-star" />
          </>
        )}
      </MultiValueOption>
    );
  };

  render() {
    const {
      id,
      type,
      labelMessage,
      labelMessageValues,
      descriptionMessage,
      checked,
      disabled,
      badge,
      errorMessage,
      disabledTooltip,
      intl
    } = this.props;
    const { formatMessage } = intl;

    const Element = type === 'checkbox' ? Checkbox : Radio;
    const firstOptionLabel = this.props.selectedOptions?.[0]?.label;

    return (
      <Block>
        <Element
          id={id}
          disabled={disabled}
          labelProps={{
            'data-tip': isHoverSupported && disabled && disabledTooltip ? intl.formatMessage(disabledTooltip) : null,
            'data-delay-show': '500',
            'data-place': 'right'
          }}
          onChange={this.handleCheckboxChange}
          checked={checked}
        >
          <FormattedMessage {...labelMessage} values={labelMessageValues} />

          {badge && <PreferencesBadge badge="new" marginleft="12px" />}
          {errorMessage && (
            <ErrorMessageInline data-testid={`${id}-error`}>
              <FormattedMessage {...errorMessage} />
            </ErrorMessageInline>
          )}
          {descriptionMessage && (
            <Description>
              <FormattedMessage {...descriptionMessage} />
            </Description>
          )}
        </Element>
        <StyledSelect
          id={`${id}-select`}
          name="select-countries"
          aria-label={formatMessage(labelMessage, labelMessageValues)}
          isMulti
          isLoading={this.props.isLoading || false}
          placeholder={formatMessage(messages.searchInputFieldPlaceholder)}
          noResultsText={formatMessage(messages.noResultText)}
          isDisabled={disabled || !this.props.checked}
          value={this.props.selectedOptions}
          options={sortListItems(this.props.allowedSearchValues)}
          onChange={this.handleInputFieldChange as any}
          classNamePrefix="react-select"
          components={{
            Menu: renderMenu,
            ClearIndicator: this.renderClearIndicator,
            SelectContainer: this.renderSelectContainer,
            MultiValueLabel: this.props.enablePreferred ? this.renderMultiValuePreferred : components.MultiValueLabel
          }}
          styles={{
            menuPortal: (base: Record<string, any>) => ({ ...base, zIndex: 100 })
          }}
          menuPortalTarget={document.getElementById('app')}
        />
        {this.props.enablePreferred && this.props.preferredMessage && firstOptionLabel && (
          <PreferredLabel>
            <FormattedMessage {...this.props.preferredMessage} values={{ label: firstOptionLabel }} />
          </PreferredLabel>
        )}
        <AddAllButton
          disabled={this.props.disabled || !this.props.checked}
          onClick={this.handleAddAll}
          data-testid={`${id}-add-all`}
        >
          <strong>+ </strong>
          <FormattedMessage {...messages.addAllLabel} />
        </AddAllButton>
      </Block>
    );
  }
}

export default injectIntl(PreferencesItemCheckedSearchList);
