import { getTodayDate } from '../../lib/date';

export const startTodayDateUpdater = (
  callback: (dateMillis: Date) => void,
  options = { precision: 15 * 60 * 1000 }
) => {
  callback(getTodayDate());
  setInterval(() => {
    callback(getTodayDate());
  }, options.precision);
};
