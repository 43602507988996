import React from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import styled from '../../../styled-components';
import Scrollbars from 'react-custom-scrollbars';
import device from '../../Common/Toolkit/devices';
import { ElasticSearchSpinner, ErrorMessage, MessagePlaceHolder } from './TranscriptsTab';
import { GlobalSearchMessages as messages } from '../GlobalSearchMessages';
import {
  ElasticSearchPage,
  ElasticSearchResult,
  SessionFromElasticSearch
} from '../../../view-model/modules/meetings/meetingsReducer';
import { formatTime, getRoundedDurationInMinutes } from '../../../../lib/date';
import { MediaQueryFeatureSelector } from '../../Common/MediaQueryFeatureSelector';
import { Button } from '../../Common/Toolkit/Button/Button';
import { Spinner } from '../../Common/Toolkit/Spinner/Spinner';
import { themeGet } from '@styled-system/theme-get';
import { TitleTabRow } from './TitleTabRow';

export const UnorderedList = styled((props: { className?: string; children?: React.ReactNode }) => {
  const list = <ol className={props.className}>{props.children}</ol>;

  const scrollableList = (
    <Scrollbars autoHide style={{ flex: '1 1 0%' }}>
      {list}
    </Scrollbars>
  );

  return <MediaQueryFeatureSelector query={device.mobile} queryMatched={list} queryNotMatched={scrollableList} />;
})`
  list-style: none;
  margin: 0;
  padding: 16px 12px 0px 0px;
  position: relative;

  @media ${device.mobile} {
    padding: 8px 8px 0px;
  }
`;

interface Props {
  search: ElasticSearchResult[] | [];
  openRecording: (sessionId: string) => void;
  searchText: string | undefined;
  pageInfo: ElasticSearchPage;
  fetching: boolean;
  loadMore: (name?: '') => void;
  disableOnClickOutside: () => void;
}

export const SearchSpinner = styled((props: any) => (
  <div className={props.className}>
    <Spinner />
  </div>
))`
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${(props) => (props.fetching ? 'visible' : 'hidden')};

  .spinnerIcon {
    height: ${(props) => (props.fetching ? '40px' : '20px')};
    width: ${(props) => (props.fetching ? '40px' : '20px')};
  }
`;

export const LoadMoreButtonTranscript = styled(
  (props: { className?: string; onClick: (name?: string) => void; isLoading: boolean; id?: string }) => (
    <div className={props.className}>
      <Button onClick={props.onClick} data-testid={props.id}>
        <FormattedMessage {...messages.loadMore} />
      </Button>
      <SearchSpinner fetching={props.isLoading} />
    </div>
  )
)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  visibility: ${(props) => (props.isLoading ? 'hidden' : 'visible')};
  button {
    font-weight: bold;
    background-color: rgba(128, 181, 255, 0.12);
    width: 170px;
    height: 36px;
    justify-content: center;
    align-items: center;
    padding: 0px;

    &:hover {
      background-color: ${themeGet('colors.primary')};
      color: ${themeGet('colors.primaryOn')};
    }
  }
`;

export const TitleTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const titleList: Array<React.ReactElement<any>> = [];

  const handleRecording = (session: SessionFromElasticSearch) => {
    props.openRecording(session.id);
  };
  const placeholder = <ErrorMessage {...props} />;

  props.search?.forEach((result) => {
    const { isCbr, id, participants, startTime, endTime } = result?.session;
    const title = result?.highlight?.title?.length ? result.highlight.title[0] : result.session.title;
    if (result && result.highlight && result.highlight.title && result.highlight.title.length) {
      const meetingStartTime = startTime ? formatTime(intl, startTime) : '';
      const duration = endTime && getRoundedDurationInMinutes(new Date(startTime), new Date(endTime));
      const month = <FormattedDate value={new Date(startTime)} month="short" />;
      const date = startTime ? new Date(startTime).getDate() : '';
      titleList.push(
        <TitleTabRow
          disableOnClickOutside={props.disableOnClickOutside}
          isCbr={isCbr}
          participants={participants}
          title={title}
          meetingStartTime={meetingStartTime}
          duration={duration}
          month={month}
          date={date}
          handleRecording={handleRecording}
          session={result.session}
          key={id}
          sessionId={id}
          startTime={startTime}
        />
      );
    }
  });

  if (titleList.length && props.pageInfo.hasNext > 0) {
    titleList.push(
      <li key="loadMore-title-button">
        <LoadMoreButtonTranscript
          className={'loadMore-title-tab-class'}
          id="loadMore-title-tab"
          onClick={() => props.loadMore('')}
          isLoading={props.fetching}
        />
      </li>
    );
  }

  return props.fetching && !titleList.length ? (
    <ElasticSearchSpinner />
  ) : titleList.length ? (
    <UnorderedList>{titleList}</UnorderedList>
  ) : (
    <MessagePlaceHolder message={placeholder} />
  );
};
