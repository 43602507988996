import classNames from 'classnames';
import React, { Key } from 'react';
import { formatToSend } from '../../../services/MessageFormatService';
import { PlainPersistedMessageInput } from '../../../view-model/modules/messaging/messageInputs/messageInputsReducer';
import { MentionList } from './MentionList';
import {
  createFilteredMentionList,
  cutStartingAtSymbol,
  extractAndMarkMentionsAtSend,
  getCursorStrings,
  removeWhiteSpace
} from '../../../services/MentionService';
import { KeyCodes, KeyNames } from '../../../../lib/keycodes';
import UploadButton from '../../Common/Upload/UploadButton/UploadButton';
import sendIconUrl from './main.send.svg';
import './MessageInputPlain.css';
import { State } from '../../../view-model/types';
import { Contact, Member } from '@getgo/caps-redux';
import TextareaAutosize from 'react-autosize-textarea';

const extractOptionsFromMentionedList = (
  mentionedList: Array<{ displayName: string; jid: string; startIndex: number; endIndex: number }>
) => {
  const sendOptions: { references: Array<{ type: string; uri: string }> } = { references: [] };
  if (mentionedList && mentionedList.length > 0) {
    const mentionReferences: Array<{ type: string; uri: string }> = [];
    mentionedList.forEach((mention) => {
      mentionReferences.push({
        type: 'mention',
        uri: mention.jid
      });
    });
    sendOptions.references = mentionReferences;
  }
  return sendOptions;
};

interface Props {
  app?: State['core']['app'];
  autoFocus?: boolean;
  changeMessageInputHeight: (roomId: string, height: number) => void;
  contact?: Contact;
  groupMembers?: Member[];
  isOnline?: boolean;
  maxLength: number;
  messageInputHeight?: number;
  placeholder: string;
  persistedMessageInput: PlainPersistedMessageInput;
  persistPlainMessageInput: (roomId: string, persistPlainMessageInput: PlainPersistedMessageInput) => void;
  onNewMessage: (text: string, option: any) => void;
  roomId: string;
  shouldShowMentionList?: boolean;
  spellCheck: boolean;
  submitOnEnter?: boolean;
  uploadFiles: (files: File[]) => void;
}

interface ClassState {
  shouldShowMentionList: boolean;
  mentionSearchString: string;
  mentionedList: any[];
}

export class MessageInputPlain extends React.Component<Props, ClassState> {
  private shouldShowMentionList: boolean;
  private textareaAutoSize: TextareaAutosize;

  constructor(props: Props) {
    super(props);

    this.getInputValues = this.getInputValues.bind(this);

    const { shouldShowMentionList = false } = this.props;

    this.shouldShowMentionList = shouldShowMentionList;
    this.textareaAutoSize = new TextareaAutosize(this.props);

    this.state = {
      shouldShowMentionList: this.shouldShowMentionList,
      mentionSearchString: '',
      mentionedList: this.props.persistedMessageInput.mentionList ? this.props.persistedMessageInput.mentionList : []
    };
  }

  componentDidMount() {
    this.textareaAutoSize.textarea.maxLength = this.props.maxLength ? this.props.maxLength : 0;
    this.textareaAutoSize.textarea.value = this.props.persistedMessageInput.message
      ? this.props.persistedMessageInput.message
      : '';
    this.focusInput();
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    this.textareaAutoSize.textarea.maxLength = newProps.maxLength;

    if (this.props.roomId !== newProps.roomId) {
      this.props.persistPlainMessageInput(this.props.roomId, {
        message: this.textareaAutoSize.textarea.value,
        mentionList: this.state.mentionedList || []
      });
      this.resetInput(newProps.persistedMessageInput);
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: ClassState) {
    const { isOnline = true, submitOnEnter = false, spellCheck = false, groupMembers = [] } = this.props;
    return (
      this.state.shouldShowMentionList !== nextState.shouldShowMentionList ||
      this.state.mentionSearchString !== nextState.mentionSearchString ||
      this.state.mentionedList !== nextState.mentionedList ||
      nextProps.isOnline !== isOnline ||
      nextProps.roomId !== this.props.roomId ||
      nextProps.groupMembers !== groupMembers ||
      nextProps.submitOnEnter !== submitOnEnter ||
      nextProps.spellCheck !== spellCheck
    );
  }

  componentDidUpdate() {
    this.focusInput();
  }

  componentWillUnmount() {
    this.props.persistPlainMessageInput(this.props.roomId, {
      message: this.textareaAutoSize.textarea.value,
      mentionList: this.state.mentionedList || []
    });
  }

  getInputValues = () => {
    const inputValue = this.textareaAutoSize.textarea.value;
    const cursorPosition = this.textareaAutoSize.textarea.selectionStart;
    const { stringBeforeCursor, stringAfterCursor } = getCursorStrings(inputValue, cursorPosition);
    return { inputValue, cursorPosition, stringBeforeCursor, stringAfterCursor };
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { submitOnEnter = false } = this.props;
    // eslint-disable-next-line no-prototype-builtins
    if (KeyCodes.hasOwnProperty(event.key)) {
      if (this.isKeyRelevantForMentionSelection(event.key) && !event.shiftKey) {
        event.preventDefault();
      } else if (
        event.key === KeyNames.ENTER &&
        submitOnEnter &&
        !event.shiftKey &&
        !this.state.shouldShowMentionList
      ) {
        event.preventDefault();
        this.handleMessageChange();
        this.handleSubmit(event);
      }
    }
  };

  isKeyRelevantForMentionSelection(key: Key) {
    return (
      (key === KeyNames.ENTER || key === KeyNames.ARROW_UP || key === KeyNames.ARROW_DOWN || key === KeyNames.ESCAPE) &&
      this.state.shouldShowMentionList
    );
  }

  focusInput = () => {
    const { autoFocus = true } = this.props;
    if (!autoFocus) {
      return;
    }
    const selection = window.getSelection();
    if (this.textareaAutoSize && !selection) {
      this.textareaAutoSize.textarea.focus();
    }
  };

  handleSelect = () => {
    this.checkMentionTrigger();
  };

  resetInput = (persistedMessageInput?: PlainPersistedMessageInput) => {
    this.textareaAutoSize.textarea.value = persistedMessageInput
      ? persistedMessageInput.message
        ? persistedMessageInput.message
        : ''
      : '';
    this.textareaAutoSize.dispatchEvent('autosize:update');
    this.setState({
      mentionedList: persistedMessageInput
        ? persistedMessageInput.mentionList
          ? persistedMessageInput.mentionList
          : []
        : []
    });
  };

  handleMessageChange = () => {
    this.checkMentionTrigger();
    const { changeMessageInputHeight = () => {} } = this.props;

    if (this.textareaAutoSize != null) {
      const newInputHeight = this.textareaAutoSize.textarea.clientHeight;
      if (this.props.messageInputHeight !== newInputHeight) {
        changeMessageInputHeight(this.props.roomId, newInputHeight);
      }
    }
  };

  isGroupChat = () => {
    return !this.props.contact || this.props.contact.isGroup;
  };

  checkMentionTrigger = () => {
    if (this.isGroupChat()) {
      if (this.state.shouldShowMentionList) {
        this.handleMentionSearch();
      } else {
        this.mentionTriggerValidation();
      }
    }
  };

  mentionTriggerValidation = () => {
    const { groupMembers = [] } = this.props;
    const { stringBeforeCursor, stringAfterCursor } = this.getInputValues();

    if (stringBeforeCursor) {
      if (stringAfterCursor.length + stringBeforeCursor.length > 0) {
        this.handleMentionSearch();
      }
      const mentionList = createFilteredMentionList(groupMembers, this.state.mentionSearchString);

      if (mentionList && mentionList.length > 0) {
        this.showMentionList();
      }
    }
  };

  handleMentionSearch = () => {
    const { stringBeforeCursor, stringAfterCursor } = this.getInputValues();

    if (!stringBeforeCursor) {
      this.setState({
        shouldShowMentionList: false,
        mentionSearchString: cutStartingAtSymbol(stringBeforeCursor) + stringAfterCursor
      });
    } else {
      this.setState({
        mentionSearchString: cutStartingAtSymbol(stringBeforeCursor) + stringAfterCursor
      });
    }
  };

  handleSubmit = (event: React.KeyboardEvent<HTMLTextAreaElement> | React.FormEvent<HTMLFormElement>) => {
    const { isOnline = true } = this.props;
    event.preventDefault();

    if (!isOnline) {
      return;
    }

    const { message, actualMentions } = extractAndMarkMentionsAtSend(
      this.textareaAutoSize.textarea.value,
      this.state.mentionedList
    );

    if (!message) {
      return;
    }

    this.props.onNewMessage(formatToSend(message), extractOptionsFromMentionedList(actualMentions));
    this.resetInput();
  };

  handleMentionCommit = (jid: string, displayName: string) => {
    const { inputValue, cursorPosition, stringBeforeCursor } = this.getInputValues();
    const startReplacementIndex = cursorPosition - stringBeforeCursor.length;
    const mentionText = `@${removeWhiteSpace(displayName)}`;
    const inputWithDisplayName = `${inputValue.substring(
      0,
      startReplacementIndex
    )} ${mentionText} ${inputValue.substring(cursorPosition, inputValue.length)}`;

    this.setState((currentState) => ({ mentionedList: [...currentState.mentionedList, { mentionText, jid }] }));
    this.hideMentionList();
    this.textareaAutoSize.textarea.value = inputWithDisplayName;
  };

  showMentionList = () => {
    this.setState({ shouldShowMentionList: true });
  };

  hideMentionList = () => {
    this.setState({ shouldShowMentionList: false });
  };

  render() {
    const { isOnline = true, placeholder = '', autoFocus = true, spellCheck = false, groupMembers = [] } = this.props;
    const uploadsEnabled = this.props.app ? this.props.app.features.includes('file_sharing') : [];
    const rootClass = classNames('message-input-plain', {
      disabled: !isOnline
    });

    const mentionList = this.isGroupChat()
      ? createFilteredMentionList(groupMembers, this.state.mentionSearchString.replace(' ', ''))
      : [];
    const isMentionListNotEmpty = mentionList?.length > 0;

    return (
      <form className={rootClass} onSubmit={this.handleSubmit} data-testid="message-input-plain">
        {this.state.shouldShowMentionList && isMentionListNotEmpty ? (
          <MentionList
            {...this.props}
            isOpen={this.state.shouldShowMentionList}
            mentions={mentionList}
            queryText={this.state.mentionSearchString}
            onAddMention={this.handleMentionCommit}
            hideMentionList={this.hideMentionList}
            outerActionHandler={this.hideMentionList}
          />
        ) : (
          <div />
        )}
        {uploadsEnabled && <UploadButton disabled={!isOnline} uploadFiles={this.props.uploadFiles} />}
        <div className="input">
          <TextareaAutosize
            className="allow-shortcuts"
            innerRef={(c: HTMLTextAreaElement) => (this.textareaAutoSize.textarea = c)}
            placeholder={placeholder}
            onKeyDown={this.onKeyDown}
            onChange={this.handleMessageChange}
            onSelect={this.handleSelect}
            tabIndex={1}
            disabled={!isOnline}
            autoComplete="off"
            autoFocus={autoFocus}
            spellCheck={spellCheck}
          />
        </div>
        <button
          className="btn btn-icon submit-button"
          disabled={!isOnline}
          onClick={this.focusInput}
          type="submit"
          data-testid="button-submit-message"
        >
          <img src={sendIconUrl} width="18" height="19" alt="" />
        </button>
      </form>
    );
  }
}
