import React from 'react';
import { ModalDialog } from '../../Common/ModalDialog/ModalDialog';
import PersonalizeMeeting from './PersonalizeMeeting';
import { PersonalizeMeetingProps } from '../../../view-model/modules/meetings/meetingsReducer';
import { State as UserState } from '../../../view-model/modules/core/user/userReducer';
import { Profile } from '../../../view-model/modules/meetings/MeetingsTypes';

interface Props extends PersonalizeMeetingProps {
  profile?: Profile;
  hidePersonalizeMeeting: () => void;
  showProfilePreview: (theme: string, roomDisplayName?: string) => void;
  updateProfile: (profileId: string, profile: Profile) => void;
  createProfile: (profile: Profile, subject?: string) => void;
  suggestProfileId: (params: { firstName: string; lastName: string }) => void;
  user: UserState;
  brandingEnabled: boolean;
}

export const PersonalizeMeetingModal: React.FC<Props> = (props: Props) => (
  <ModalDialog contentLabel="SchedulerModal" shouldCloseOnOverlayClick onClose={props.hidePersonalizeMeeting}>
    <PersonalizeMeeting {...props} />
  </ModalDialog>
);
