/* eslint-disable react/no-unused-prop-types */
import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { FileIcon } from '../../FileIcon/FileIcon';
import { Description } from './Description/Description';
import './UploadProgressItem.css';
import { FileUploadEntry } from '../../../../view-model/modules/messaging/uploads/uploadsReducer';

const messages = defineMessages({
  cancel: {
    id: 'common.upload.uploadProgress.cancel',
    defaultMessage: 'Cancel'
  },
  clear: {
    id: 'common.upload.uploadProgress.clear',
    defaultMessage: 'Clear'
  }
});

interface Props {
  onCancel: (upload: FileUploadEntry) => void;
  onClear: (upload: FileUploadEntry) => void;
  upload: FileUploadEntry;
}

export class UploadProgressItem extends React.PureComponent<Props, {}> {
  handleCancel = () => this.props.onCancel(this.props.upload);
  handleClear = () => this.props.onClear(this.props.upload);

  render() {
    const { upload } = this.props;
    const { error, file, percent = 0 } = upload;
    const canCancel = !error && upload.request && percent < 100 && !!this.props.onCancel;
    const canClear = error && !!this.props.onClear;
    const style = {
      width: `${percent}%`
    };
    const progressClass = classNames('progress-bar', {
      'progress-bar-success': !error && percent === 100,
      'progress-bar-danger': !!error
    });

    return (
      <div className="upload-progress-item">
        <div className="files-message">
          <span className="file-icon">
            <FileIcon type={file.type} />
          </span>
          <Description file={file} error={error} />
          <div className="controls">
            {canCancel ? (
              <button className="btn btn-link cancel-button" onClick={this.handleCancel}>
                <FormattedMessage {...messages.cancel} />
              </button>
            ) : null}
            {canClear ? (
              <button className="btn btn-link clear-button" onClick={this.handleClear}>
                <FormattedMessage {...messages.clear} />
              </button>
            ) : null}
          </div>
        </div>
        <div className="progress">
          <div
            className={progressClass}
            role="progressbar"
            aria-valuenow={60}
            aria-valuemin={0}
            aria-valuemax={100}
            style={style}
          >
            <span className="sr-only">{percent}%</span>
          </div>
        </div>
      </div>
    );
  }
}
