import { Admin } from '../types/pulse-web';
import { Organizer } from '../app/view-model/modules/meetings/MeetingsTypes';

export interface Person {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const getAvatarInitials = (fullName: string): string => {
  const trimmedName = fullName.trim();
  if (trimmedName.length <= 2) {
    return trimmedName;
  }

  if (trimmedName.includes('@')) {
    const emailParts = trimmedName.split('@')[0].split(/[-.]/);
    return getInitialsFromArray(emailParts);
  }

  const nameParts = trimmedName.split(' ');
  return getInitialsFromArray(nameParts);
};

const getInitialsFromArray = (parts: string[]): string => {
  if (parts.length >= 2) {
    return `${parts[0][0]}${parts[1][0]}`;
  }
  return parts[0].substr(0, 2);
};

export const getFullName = (person?: Partial<Organizer>, useEmailFallback?: boolean) => {
  if (!person) {
    return '';
  }
  const { firstName, lastName, email } = person;
  const nameComponents: string[] = [];
  if (firstName) {
    nameComponents.push(firstName);
  }
  if (lastName) {
    nameComponents.push(lastName);
  }
  if (useEmailFallback && nameComponents.length === 0 && email) {
    nameComponents.push(email);
  }
  return nameComponents.join(' ');
};

export const formatCoorganizersNames = (corgis?: ReadonlyArray<Partial<Organizer>>) => {
  let ret = '';
  if (corgis?.length) {
    ret = corgis
      .map((corgi) => getFullName(corgi))
      .filter((result) => !!result)
      .sort()
      .join(', ');
  }
  return ret;
};

export const getDisplayName = (person?: Person) => {
  return getFullName(person, true);
};

export const getSuggestionTitle = (person?: Person): string => {
  if (!person) {
    return '';
  }

  const fullName = getFullName(person, false);
  if (!fullName) {
    return person.email || '';
  }
  return person.email ? `${fullName} (${person.email})` : fullName;
};

export const buildAdminInfo = (admin?: Admin) => {
  if (!admin) {
    return '';
  }
  return `${admin.firstName} ${admin.lastName} - ${admin.email}`;
};
