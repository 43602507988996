import * as React from 'react';
import { ModalDialogContentWrapper } from '../../../Common/ModalDialog/ModalDialogContentWrapper';
import styled from '../../../../styled-components';
import { Checkbox, ElementTitle } from '../../../Common/Toolkit/Input/Selectable';
import { useEffect, useState, useCallback } from 'react';
import { ButtonBar } from '../../../Common/Toolkit/Button/ButtonBar';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../../view-model/types';
import { meetingInfoDownload } from '../../../../services/DownloadMeetingAssets/meetingInfoDownload';
import meetingActions, {
  fetchMeetingAnalytics,
  fetchMeetingTranscription,
  fetchSmartNotes,
  triggerTelemetryElasticSearch,
  fetchChats
} from '../../../../view-model/modules/meetings/meetingsActions';
import { smartNotesDownload } from '../../../../services/DownloadMeetingAssets/smartNotesDownload';
import { chatsDownload } from '../../../../services/DownloadMeetingAssets/chatsDownload';
import { slidesRecordingsDownload } from '../../../../services/DownloadMeetingAssets/slidesRecordingsDownload';
import { defineMessages, useIntl } from 'react-intl';
import { Spinner } from '../../../Common/Toolkit/Spinner/Spinner';
import { delay } from 'lodash';
import { transcriptsDownload } from '../../../../services/DownloadMeetingAssets/transcriptsDownload';
import { isEmpty } from 'lodash';

const messages = defineMessages({
  downloadHeader: {
    id: 'meetingAssetsDwonload.messages.downloadHeader',
    defaultMessage: 'Download meeting content'
  },
  downloadSubHeader: {
    id: 'meetingAssetsDwonload.messages.downloadSubHeader',
    defaultMessage: 'Select the items you want to download.'
  },
  video: {
    id: 'meetingAssetsDwonload.messages.video',
    defaultMessage: 'Video Recording (.mp4)'
  },
  transcript: {
    id: 'meetingAssetsDwonload.messages.transcript',
    defaultMessage: 'Transcript (.doc)'
  },
  note: {
    id: 'meetingAssetsDwonload.messages.note',
    defaultMessage: 'Notes (.doc)'
  },
  slide: {
    id: 'meetingAssetsDwonload.messages.slide',
    defaultMessage: 'Slides (.pdf)'
  },
  info: {
    id: 'meetingAssetsDwonload.messages.info',
    defaultMessage: 'Info (.doc)'
  },
  chat: {
    id: 'meetingAssetsDwonload.messages.chat',
    defaultMessage: 'Chat (.doc)'
  },
  download: {
    id: 'meetingAssetsDwonload.messages.download',
    defaultMessage: 'Download'
  },
  cancel: {
    id: 'meetingAssetsDwonload.messages.cancel',
    defaultMessage: 'cancel'
  }
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubHeader = styled.div`
  margin-bottom: 20px;
  font-size: 16px;
  margin-top: -15px;
`;

const AssetsCheckbox = styled(Checkbox)`
  margin-bottom: 8px;
`;

const SpinnerCentered = styled(Spinner)`
  margin: auto;
`;

const CustomElementTitle = styled(ElementTitle)`
  font-weight: normal;
  font-family: lato;
`;

export enum EMeetingAssetTypes {
  notes = 'notes',
  slides = 'slides',
  transcript = 'transcript',
  info = 'info',
  video = 'video',
  chat = 'chat'
}

export interface MeetingAssetTypes {
  notes: boolean;
  slides: boolean;
  transcript: boolean;
  info: boolean;
  video: boolean;
  chat: boolean;
  [index: string]: boolean;
}

interface Props {
  hideRecordingAssetModal: () => void;
  session?: any;
}

export const MeetingAssetsDownload: React.FC<Props> = (props: Props) => {
  const [transcriptionURL, setTranscriptionURL] = useState('');
  const [recordingDownloadURL, setRecordingDownloadURL] = useState('');
  const [pdfURL, setPdfURL] = useState('');

  const [assets, setAssets] = useState<{ [index in keyof MeetingAssetTypes]: boolean }>({
    notes: false,
    slides: false,
    transcript: false,
    info: true,
    video: false,
    chat: false
  });

  const { formatMessage: f } = useIntl();
  const intl = useIntl();
  const dispatch = useDispatch();

  const meetingStore = useSelector((state: State) => state.meetings);
  const session = props.session || meetingStore.elasticSearchSession;
  const shareTranscript = meetingStore?.transcriptSharesById?.[session?.sessionId];
  let transcriptData = meetingStore?.meetingTranscriptions;
  let smartNotes = meetingStore?.smartNotes;
  const fetchingNotes = meetingStore.fetchSmartNotes;
  let meetingAnalytics = meetingStore?.meetingAnalytics;
  const fetchingMeetingAnalytics = meetingStore.fetchMeetingAnalytics;
  const chats = meetingStore?.chats;
  const fetchingChats = meetingStore.fetchChats;
  const fetchMeetingTranscripts = meetingStore?.fetchMeetingTranscripts;
  const fetchTranscriptsShare = meetingStore?.fetchTranscriptsShare;

  useEffect(() => {
    return () => {
      dispatch(meetingActions.fetchChatsUpdate([]));
    };
  }, []);

  const onChangeAssets = useCallback(
    (checked: boolean, value: string) => {
      setAssets({
        ...assets,
        [value]: checked
      });
    },
    [assets]
  );

  useEffect(() => {
    transcriptData = null;
    meetingAnalytics = null;
    smartNotes = []; // reset smartNotes array whenever there is a change in session id
    session?.recording?.recordingArtifacts.forEach((artifacts: { artifactType: string; downloadUrl: string }) => {
      if (artifacts.artifactType === 'PARSED_TRANSCRIPTION') {
        setTranscriptionURL(artifacts.downloadUrl);
      }
    });
    if (session?.recording?.downloadUrl) {
      setRecordingDownloadURL(session.recording.downloadUrl);
      onChangeAssets(true, EMeetingAssetTypes.video);
    }
  }, [session?.sessionId]);

  useEffect(() => {
    if (shareTranscript && shareTranscript.shareKey && !fetchTranscriptsShare) {
      dispatch(fetchSmartNotes(shareTranscript.shareKey));
      dispatch(fetchMeetingAnalytics(shareTranscript.shareKey));
      dispatch(meetingActions.fetchingChats(true));
    }
  }, [shareTranscript, fetchTranscriptsShare, dispatch]);

  useEffect(() => {
    if (!fetchingNotes && smartNotes.length && !assets[EMeetingAssetTypes.notes]) {
      onChangeAssets(true, EMeetingAssetTypes.notes);
    }
  }, [fetchingNotes]);

  useEffect(() => {
    if (!fetchingChats) {
      if (chats.length && !assets[EMeetingAssetTypes.chat]) {
        onChangeAssets(true, EMeetingAssetTypes.chat);
      } else if (!chats.length && assets[EMeetingAssetTypes.chat]) {
        onChangeAssets(false, EMeetingAssetTypes.chat);
      }
    }
  }, [chats, fetchingChats]);

  useEffect(() => {
    if (!fetchingMeetingAnalytics && meetingAnalytics) {
      if (meetingAnalytics?.chatURL) {
        dispatch(fetchChats(meetingAnalytics?.chatURL));
      } else {
        dispatch(meetingActions.fetchingChats(false));
      }
    }
    if (!fetchingMeetingAnalytics && meetingAnalytics && meetingAnalytics?.pdfURL) {
      setPdfURL(meetingAnalytics?.pdfURL);
      onChangeAssets(true, EMeetingAssetTypes.slides);
    }
  }, [meetingAnalytics, fetchingMeetingAnalytics]);

  useEffect(() => {
    if (transcriptionURL) {
      dispatch(fetchMeetingTranscription(transcriptionURL));
    }
  }, [transcriptionURL]);

  useEffect(() => {
    if (transcriptData && !isEmpty(transcriptData)) {
      onChangeAssets(true, EMeetingAssetTypes.transcript);
    }
  }, [transcriptData]);

  const onModalClose = () => {
    props.hideRecordingAssetModal();
  };

  const formattedSessionValues = () => {
    let startDate = '';
    let endDate = '';
    let startTime = '';
    let expiry = '';
    if (props.session?.startTime) {
      startTime = intl.formatDate(new Date(props.session?.startTime), { hour: 'numeric', minute: 'numeric' });
      startDate = intl.formatDate(new Date(props.session?.startTime), {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    }
    if (props.session?.endTime) {
      endDate = intl.formatDate(new Date(props.session?.endTime), { month: 'short', day: 'numeric', year: 'numeric' });
    }
    if (props.session?.shareRecording?.expires) {
      expiry = intl.formatDate(new Date(props.session.shareRecording.expires), {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    }
    return { startDate, endDate, startTime, expiry };
  };

  const downloadAssets = () => {
    const arrayOfUrls: Array<{ url: string; title: string }> = [];
    const dateTimeFormat = formattedSessionValues();

    if (assets[EMeetingAssetTypes.chat]) {
      chatsDownload(chats, session, dateTimeFormat, intl, f);
    }

    if (assets[EMeetingAssetTypes.transcript]) {
      transcriptsDownload(transcriptData, session, dateTimeFormat);
    }
    if (assets[EMeetingAssetTypes.slides]) {
      arrayOfUrls.push({ url: pdfURL, title: session?.subject });
    }
    if (assets[EMeetingAssetTypes.video]) {
      arrayOfUrls.push({ url: recordingDownloadURL, title: session?.subject });
    }
    if (assets[EMeetingAssetTypes.notes]) {
      smartNotesDownload(smartNotes, session, meetingAnalytics?.recordingTimes);
    }
    if (assets[EMeetingAssetTypes.info]) {
      meetingInfoDownload(session, dateTimeFormat, meetingAnalytics);
    }

    if (arrayOfUrls && arrayOfUrls.length > 0) {
      slidesRecordingsDownload(arrayOfUrls);
    }

    const listOfAssets = Object.keys(assets).filter(
      (item: EMeetingAssetTypes[keyof EMeetingAssetTypes] | any) => assets[item]
    );
    listOfAssets.forEach((item: EMeetingAssetTypes[keyof EMeetingAssetTypes]) => {
      delay(() => dispatch(triggerTelemetryElasticSearch('assetsDownloaded', item)), 200); // events not getting triggered without delay
    });
    props.hideRecordingAssetModal();
  };

  const assetsSelected = () => Object.keys(assets).some((assetType: string) => assets[assetType] === true);

  const buttons = (
    <ButtonBar
      buttonOk={f(messages.download)}
      buttonCancel={f(messages.cancel)}
      onOk={downloadAssets}
      onCancel={props.hideRecordingAssetModal}
      okDisabled={!assetsSelected()}
    />
  );

  return (
    <ModalDialogContentWrapper
      title={f(messages.downloadHeader)}
      onClose={onModalClose}
      testId={'recording-asset-modal'}
    >
      {fetchingNotes ||
      fetchingMeetingAnalytics ||
      fetchingChats ||
      fetchMeetingTranscripts ||
      fetchTranscriptsShare ? (
        <SpinnerCentered />
      ) : (
        <Container data-testid="recording-share-modal">
          <SubHeader>{f(messages.downloadSubHeader)}</SubHeader>
          <AssetsCheckbox
            id="recording-checkbox-video"
            value="video"
            checked={assets[EMeetingAssetTypes.video]}
            disabled={recordingDownloadURL.length === 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeAssets(e.target.checked, e.target.value)}
          >
            <CustomElementTitle>{f(messages.video)}</CustomElementTitle>
          </AssetsCheckbox>

          <AssetsCheckbox
            id="recording-checkbox-transcript"
            value="transcript"
            checked={assets[EMeetingAssetTypes.transcript]}
            disabled={!transcriptionURL || isEmpty(transcriptData)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeAssets(e.target.checked, e.target.value)}
          >
            <CustomElementTitle>{f(messages.transcript)}</CustomElementTitle>
          </AssetsCheckbox>
          <AssetsCheckbox
            id="recording-checkbox-notes"
            value="notes"
            checked={assets[EMeetingAssetTypes.notes]}
            disabled={smartNotes.length === 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeAssets(e.target.checked, e.target.value)}
          >
            <CustomElementTitle>{f(messages.note)}</CustomElementTitle>
          </AssetsCheckbox>
          <AssetsCheckbox
            id="recording-checkbox-slides"
            value="slides"
            checked={assets[EMeetingAssetTypes.slides]}
            disabled={pdfURL.length === 0}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeAssets(e.target.checked, e.target.value)}
          >
            <CustomElementTitle>{f(messages.slide)}</CustomElementTitle>
          </AssetsCheckbox>

          <AssetsCheckbox
            id="recording-checkbox-meeting-chat"
            value="chat"
            disabled={!chats.length}
            checked={assets[EMeetingAssetTypes.chat]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeAssets(e.target.checked, e.target.value)}
          >
            <CustomElementTitle>{f(messages.chat)}</CustomElementTitle>
          </AssetsCheckbox>

          <AssetsCheckbox
            id="recording-checkbox-meeting-info"
            value="info"
            checked={assets[EMeetingAssetTypes.info]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeAssets(e.target.checked, e.target.value)}
          >
            <CustomElementTitle>{f(messages.info)}</CustomElementTitle>
          </AssetsCheckbox>
          {buttons}
        </Container>
      )}
    </ModalDialogContentWrapper>
  );
};
