import { IntlShape, defineMessages } from 'react-intl';
import moment from 'moment-timezone';
import esES from 'date-fns/locale/es';
import deDE from 'date-fns/locale/de';
import enUS from 'date-fns/locale/en-US';
import frFR from 'date-fns/locale/fr';
import itIT from 'date-fns/locale/it';
import ptBR from 'date-fns/locale/pt-BR';
import { registerLocale } from 'react-datepicker';

type Dateable = Date | string | number;

export const milliesOfMinute = 60000;
export const milliesOfHour = milliesOfMinute * 60;
export const milliesOfDay = milliesOfHour * 24;

export const dateMessages = defineMessages({
  dateShort: {
    id: 'lib.date.dateShort',
    defaultMessage:
      '{daysAgo, selectordinal, =-1 {Tomorrow} =0 {Today} =1 {Yesterday} other {{val, date, customShort}}}'
  },
  dateLong: {
    id: 'lib.date.dateLong',
    defaultMessage: '{daysAgo, selectordinal, =-1 {Tomorrow} =0 {Today} =1 {Yesterday} other {{val, date, customLong}}}'
  },
  dateTime: {
    id: 'lib.date.dateTime',
    defaultMessage: '{formattedDate} at {val, time, short}'
  }
});

export const getFormattedDay = (intl: IntlShape, date: Dateable, today: Dateable) => {
  const dateObject = new Date(date);
  const dateObjectToday = new Date(today);
  const dateDiff = getDiffInDays(dateObject, today);
  return dateObject.getFullYear() < dateObjectToday.getFullYear()
    ? intl.formatMessage(dateMessages.dateLong, { val: dateObject, daysAgo: dateDiff })
    : intl.formatMessage(dateMessages.dateShort, { val: dateObject, daysAgo: dateDiff });
};

export const getFormattedDayAndTime = (intl: IntlShape, date: Dateable, today: Dateable) => {
  const dateObject = new Date(date);
  const formattedDate = getFormattedDay(intl, dateObject, today);
  return intl.formatMessage(dateMessages.dateTime, { val: dateObject, formattedDate });
};

export const getStartOfDay = (dateTime: Dateable) => {
  const midnight = new Date(dateTime);
  midnight.setHours(0, 0, 0, 0);
  return midnight;
};

export const getEndOfDay = (dateTime: Dateable) => {
  const endOfDay = new Date(dateTime);
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
};

/**
 * getTodayDate() returns a Date objects for the current date;
 * Note: Date.toDateString() is not standard
 */
export function getTodayDate() {
  const now = new Date();
  return getStartOfDay(now);
}

export const isDateInTheFuture = (date: Date) => {
  const now = new Date();
  return date.getTime() - now.getTime() >= 0;
};

/**
 * Note: Comparing different date objects will return false even if their value are the same date;
 * This function abstracts date comparison for any date-ish format.
 */
export function isEqualDate(left: Dateable, right: Dateable) {
  return new Date(left).getTime() === new Date(right).getTime();
}

export const getDiffInDays = (dateTime1: Dateable, dateTime2: Dateable) => {
  const date1 = new Date(dateTime1);
  const date2 = new Date(dateTime2);
  const dateUtc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  const dateUtc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

  return Math.floor((dateUtc2 - dateUtc1) / milliesOfDay);
};

export const addMinutesToDate = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * milliesOfMinute);
};

export const getDurationInMinutes = (startTime: Date, endTime: Date) => {
  return Math.floor((endTime.getTime() - startTime.getTime()) / 60000);
};

export const getRoundedDurationInMinutes = (startTime: Date, endTime: Date) => {
  return Math.ceil((endTime.getTime() - startTime.getTime()) / 60000);
};

export const getDateString = (date: Date): string =>
  `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

export const getTimeString = (date: Date): string =>
  `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(
    date.getSeconds()
  ).padStart(2, '0')}`;

export const getDateTimeInUtc = (date: string, time: string, timezone: string | undefined) => {
  let dateTime: string = '';
  if (timezone) {
    dateTime = moment
      .tz(`${date} ${time}`, timezone)
      .utc()
      .format();
  } else {
    dateTime = moment(`${date} ${time}`)
      .utc()
      .format();
  }
  return new Date(dateTime);
};

export const getNextStartMeetingTime = (date: Date, minDuration: number, roundingInterval: number) =>
  new Date(Math.ceil((date.getTime() + minDuration) / roundingInterval) * roundingInterval);

export const compareDate = (a: Date, b: Date) =>
  a.getUTCDate() === b.getUTCDate() && a.getUTCMonth() === b.getUTCMonth() && a.getUTCFullYear() === b.getUTCFullYear();

export const compareTime = (a: Date, b: Date) =>
  a.getUTCSeconds() === b.getUTCSeconds() &&
  a.getUTCMinutes() === b.getUTCMinutes() &&
  a.getUTCHours() === b.getUTCHours();

export const fnsLocales: { [key: string]: any } = {
  'de-DE': deDE,
  'en-US': enUS,
  'es-ES': esES,
  'fr-FR': frFR,
  'it-IT': itIT,
  'pt-BR': ptBR
};

export const getFnsLocale = (intl: IntlShape) => {
  return fnsLocales[intl.locale] || enUS;
};

export const initializeDatePickerLocales = () => {
  for (const it in fnsLocales) {
    // eslint-disable-next-line no-prototype-builtins
    if (fnsLocales.hasOwnProperty(it)) {
      registerLocale(it, fnsLocales[it]);
    }
  }
};

export const getBackdatedValue = (current = new Date(), days: number, months: number, years: number) => {
  return new Date(
    current.getFullYear() + years,
    current.getMonth() + months,
    Math.min(
      current.getDate() + days,
      new Date(current.getFullYear() + years, current.getMonth() + months + 1, 0).getDate() + days
    )
  );
};

export const formatTime = (intl: IntlShape, date: Date | string | number) => intl.formatTime(date);

export const formatToHoursMinutesSeconds = (num: number, receiveTimeInMinutes?: boolean) => {
  const seconds = receiveTimeInMinutes ? num : Number(num) / 1000;
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);
  // give padding if needed
  const hh = `0${h}:`.slice(-3);
  const mm = `0${m}:`.slice(-3);
  const ss = `0${s}`.slice(-2);

  const HH = h > 0 ? hh : '';

  return `${HH}${mm}${ss}`;
};

export const formatToMinutes = (minutes: number) => {
  const m = Math.floor(minutes);

  if (m < 10) {
    return `0${m}`;
  }

  return `${m}`;
};
