import { ChatActions, GroupActions, MessageActions, RosterActions, Selectors } from '@getgo/caps-redux';
import { ActionTypes as MeetingActionTypes } from '../modules/meetings/meetingsActions';
import { ActionTypes as SchedulerActionTypes } from '../modules/scheduler/schedulerActions';
import { ActionTypes as AppActionTypes } from '../modules/core/app/appActions';
import { ActionTypes as MessagingActionTypes } from '../modules/messaging/messagingActions';
import { ActionTypes as UserActionTypes } from '../modules/core/user/userActions';
import { ActionTypes as PreferencesActions } from '../modules/core/preferences/preferencesActions';
import { ActionTypes as SharedActionTypes } from '../sharedActions';
import { ActionTypes as DiscoverActionTypes } from '../modules/discover/discoverActions';
import Events from '../EventConstants';
import telemetry from '../../services/TelemetryService';
import { Middleware, State } from '../types';
import { buildFeatureUseData } from '../../../lib/user-data-utility';
import { getUser } from '../selectors';
import { User } from '../modules/core/user/userReducer';
import { viewContentEvent } from '../../services/GoogleTagManagerService';

const buildBaseTelemetryData = (state: State) => {
  return buildFeatureUseData(getUser(state) as User);
};

enum ChatType {
  GROUP = 'group',
  DIRECT = 'direct'
}

function getChatTypeFromJid(state: State, jid: string) {
  const contact = Selectors.findContact(state, jid);
  return contact.isGroup ? ChatType.GROUP : ChatType.DIRECT;
}

export const telemetryMiddleware: Middleware = (store) => (next) => (action) => {
  switch (action.type) {
    case MessageActions.messageSendSuccess.toString():
      /* eslint-disable no-case-declarations */
      const saType = action.payload.message.type === 'groupchat' ? 'group' : 'direct';
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: saType,
        sa_state: 'sendMessage'
      });
      break;
    case RosterActions.contactAdded.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'contact',
        sa_state: 'addedContact'
      });
      break;
    case RosterActions.contactRemoved.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'contact',
        sa_state: 'removedContact'
      });
      break;
    case RosterActions.contactInvited.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'contact',
        sa_state: 'inviteExternal'
      });
      break;
    case MessagingActionTypes.SENDING_MEET_NOW_INVITE:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'meeting',
        sa_state: 'start',
        sa_chatType: getChatTypeFromJid(store.getState(), action.payload.roomId),
        ga_id_sessiontracking: action.payload.sessionTrackingId
      });
      break;
    case MessagingActionTypes.OPEN_GROUP_MEMBERS:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'group',
        sa_state: 'showMembers'
      });
      break;
    case MeetingActionTypes.MEET_NOW:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'meetingDashboard',
        sa_state: 'startMeetNow',
        ga_id_sessiontracking: action.payload.sessionTrackingId
      });
      break;
    case MeetingActionTypes.START_MEETING:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'meetingDashboard',
        sa_state: 'startMeeting',
        sa_meetingType: action.payload.meetingType,
        ga_id_sessiontracking: action.payload.sessionTrackingId
      });
      break;
    case MeetingActionTypes.INVITATION_COPIED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'meetingDashboard',
        sa_state: 'invitationCopied'
      });
      break;
    case MeetingActionTypes.ELASTIC_SEARCH_USED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'elasticSearchUsed',
        sa_state: 'searchPerformed',
        sa_criteria: action.payload.criteria,
        sa_searchText: action.payload.searchText
      });
      break;
    case MeetingActionTypes.ELASTIC_SEARCH_TELEMETRY:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'elasticSearchUsed',
        sa_state: action.payload.sa_state
      });
      break;
    case MeetingActionTypes.DOWNLOAD_MEETING_ASSETS:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'downloadMeetingAssets',
        sa_state: action.payload.assetName
      });
      break;
    case MeetingActionTypes.SEARCH_SELECTED_TAB:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'elasticSearchUsed',
        sa_state: action.payload.sa_state,
        sa_criteria: action.payload.tabName
      });
      break;
    case MeetingActionTypes.OPEN_RECORDING_ELASTIC_SEARCH:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'elasticSearchUsed',
        sa_state: 'openRecording',
        sa_sessionId: action.payload.sessionId,
        sa_criteria: action.payload.criteria,
        sa_searchText: action.payload.searchText
      });
      break;
    case MeetingActionTypes.JOIN_MEETING:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'meetingDashboard',
        sa_state: 'joinMeeting',
        ga_id_sessiontracking: action.payload.sessionTrackingId
      });
      break;
    case MeetingActionTypes.MEETING_DELETED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'meetingDashboard',
        sa_state: 'meetingDeleted'
      });
      break;
    case MeetingActionTypes.RECORDING_SESSION_OPENED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'record',
        sa_state: 'open'
      });
      break;
    case MeetingActionTypes.RECORDING_SESSION_DOWNLOADED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'record',
        sa_state: 'download',
        sa_source: action.payload.recordingId,
        ga_meetingId: action.payload.meetingId
      });
      break;
    case MeetingActionTypes.STANDALONE_PLAYER_URL_COPIED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'record',
        sa_state: 'share',
        sa_source: action.payload.recordingId,
        ga_meetingId: action.payload.meetingId
      });
      break;
    case MeetingActionTypes.RECORDING_SESSION_DELETED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'record',
        sa_state: 'delete',
        sa_source: action.payload.recordingId,
        ga_meetingId: action.payload.meetingId
      });
      break;
    case MeetingActionTypes.MEETING_TITLE_EDITED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'meetingTitleEdit',
        sa_state: action.payload.view
      });
      break;
    case Events.CONTACT_SELECTED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: getChatTypeFromJid(store.getState(), action.data.roomId),
        sa_state: 'openChat'
      });
      break;
    case GroupActions.groupCreated.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'contact',
        sa_state: 'createGroup'
      });
      break;
    case GroupActions.groupDeleted.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'contact',
        sa_state: 'deleteGroup'
      });
      break;
    case GroupActions.groupRemoved.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'contact',
        sa_state: 'leaveGroup'
      });
      break;
    case GroupActions.groupJoined.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'contact',
        sa_state: 'joinGroup'
      });
      break;
    case GroupActions.groupMembersInvited.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'group',
        sa_state: 'addMember'
      });
      break;
    case SharedActionTypes.WEB_PAGE_OPENED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'navigation',
        sa_state: action.payload.linkTargetType
      });
      break;
    case SharedActionTypes.NEW_EXPERIENCE_OPT_OUT:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'newExperienceOptOut',
        sa_state: 'optOut'
      });
      break;
    case SchedulerActionTypes.SCHEDULER_ENDPOINT_ACCESS:
      // for native endpoint direct url access: /#schedule
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'schedulerEndpointAccess'
      });
      break;
    case SchedulerActionTypes.SCHEDULER_MEETING_CREATE_SHOW:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'scheduler',
        sa_state: 'openCreate'
      });
      break;
    case MeetingActionTypes.MEETING_CREATED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'scheduler',
        sa_state: 'createSuccess',
        sa_meetingType: action.payload.meeting.meetingType,
        sa_appState: action.payload.meeting.meetingUrl ? 'personalized' : 'notPersonalized'
      });
      break;
    case SchedulerActionTypes.SCHEDULER_MEETING_EDIT_SHOW:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'scheduler',
        sa_state: 'openEdit'
      });
      break;
    case SchedulerActionTypes.SCHEDULER_MEETING_UPDATED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'scheduler',
        sa_state: 'updateSuccess',
        sa_meetingType: action.payload.meeting.meetingType,
        sa_appState: action.payload.meeting.meetingUrl ? 'personalized' : 'notPersonalized'
      });
      break;
    case SchedulerActionTypes.MEETING_SCHEDULE_HIDE:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'scheduler',
        sa_state: 'cancel'
      });
      break;
    case MeetingActionTypes.SHOW_CREATE_PERSONAL_MEETING:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'personalMeeting',
        sa_state: 'reserve'
      });
      break;
    case SchedulerActionTypes.SCHEDULER_CHANGE_PROPERTY:
      if (action.payload.property === 'roomName') {
        telemetry.event('featureUse', {
          ...buildBaseTelemetryData(store.getState()),
          sa_type: 'scheduler',
          sa_state: 'accessPersonalizeTab'
        });
      }
      break;
    case MeetingActionTypes.PROFILE_CREATED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'personalMeeting',
        sa_state: 'savedSuccessfully'
      });
      break;
    case AppActionTypes.APP_LOADED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'session',
        sa_state: 'launched'
      });
      break;
    case UserActionTypes.START_LOGOUT:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'session',
        sa_state: 'signOut'
      });
      break;
    case ChatActions.chatConnected.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'access',
        sa_state: 'connect'
      });
      break;
    case ChatActions.setPresence.toString():
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'user',
        sa_state: 'changePresenceState'
      });
      break;
    case SharedActionTypes.PAGE_CHANGED:
      if (action.payload === '/chats/add-contact') {
        telemetry.event('featureUse', {
          ...buildBaseTelemetryData(store.getState()),
          sa_type: 'contact',
          sa_state: 'tapAddContact'
        });
      } else if (action.payload === '/chats/groups/search') {
        telemetry.event('featureUse', {
          ...buildBaseTelemetryData(store.getState()),
          sa_type: 'contact',
          sa_state: 'tapAddJoinGroup'
        });
      }
      viewContentEvent(action.payload);
      break;
    case PreferencesActions.SETTING_GROUP_UPDATE_STARTED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'settings',
        sa_state: action.payload
      });
      break;
    case SharedActionTypes.OUTLOOK_CALENDAR_LINK_CLICKED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'calendarIntegration',
        sa_state: 'outlookCalendar'
      });
      break;
    case SharedActionTypes.GOOGLE_CALENDAR_LINK_CLICKED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'calendarIntegration',
        sa_state: 'googleCalendar'
      });
      break;
    case PreferencesActions.CHANGE_SETTING_UX2019_ENABLED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'settings',
        sa_state: action.payload ? 'newUIOn' : 'newUIOff'
      });
      break;
    case SharedActionTypes.UX2019_ENABLE_NOTIFICATION_CLICKED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'toastImpression',
        sa_state: 'newUIClick'
      });
      break;
    case SharedActionTypes.UX2019_ENABLE_NOTIFICATION_SHOWN:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'toastImpression',
        sa_state: 'toastNewUI'
      });
      break;
    case SharedActionTypes.NAVIGATION_BY_CLICK:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'navigationByClick',
        sa_state: action.payload.destination
      });
      break;
    case MeetingActionTypes.DIAGNOSTICS_OPENED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'diagnosticsOpened',
        sa_state: action.payload
      });
      break;
    case DiscoverActionTypes.DISCOVER_ITEM_CTA_CLICKED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'discover',
        sa_state: 'ctaClicked',
        sa_discoverId: action.payload.entry.engagementId
      });
      break;
    case DiscoverActionTypes.DISCOVER_ITEM_DISMISSED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'discover',
        sa_state: 'dismissed',
        sa_discoverId: action.payload.entry.engagementId
      });
      break;
    case DiscoverActionTypes.DISCOVER_ITEM_SELECTED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'discover',
        sa_state: 'selected',
        sa_discoverId: action.payload.entry.engagementId
      });
      break;
    case DiscoverActionTypes.DISCOVER_ITEM_VIDEO_INTERACTION:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'discover',
        sa_state: `${action.payload.interactionType}Video`,
        sa_discoverId: action.payload.entry.engagementId
      });
      break;
    case PreferencesActions.CUSTOM_BACKGROUND_PREFERENCES_ACCESSED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'virtualBackground',
        sa_state: 'access'
      });
      break;
    case SharedActionTypes.CHROMA_CAM_DOWNLOADED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'virtualBackground',
        sa_state: 'download'
      });
      break;
    case PreferencesActions.GOTO_APP_EAP_PREFERENCES_ACCESSED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'gotoAppEap',
        sa_state: 'access'
      });
      break;
    case SharedActionTypes.GOTO_APP_EAP_DOWNLOADED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'gotoAppEap',
        sa_state: 'download'
      });
      break;
    case PreferencesActions.CHANGE_SETTING_GOTO_APP_ENABLED:
      telemetry.event('featureUse', {
        ...buildBaseTelemetryData(store.getState()),
        sa_type: 'settings',
        sa_state: action.payload ? 'gotoAppOn' : 'gotoAppOff'
      });
  }

  return next(action);
};
