import * as React from 'react';
import { useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CoOrganizerSelect } from '../CoOrganizerSelect';
import { SchedulerTabProps } from '../MeetingScheduler';
import { Checkbox, ElementDescription, ElementTitle } from '../../../Common/Toolkit/Input/Selectable';
import styled from '../../../../styled-components';
import { Link } from '../../../Common/Toolkit/Link/Link';
import { messages as preferencesHeaderMessages } from '../../../Common/Preferences/PreferencesHeader';
import config from 'config';
import { AlertLevel } from '../../../../view-model/modules/core/errors/errorActions';
import { Card } from '../../../Common/Toolkit/Card/Card';
import { CheckboxAlertContainer } from '../../../Common/Toolkit/CheckboxAlertTextContainer/CheckboxAlertContainer';
import { themeGet } from '@styled-system/theme-get';

const MoreTabContainer = styled.div`
  padding: 24px 16px 8px 4px;
`;

const CoOrganizersBox = styled.div`
  margin-bottom: 24px;

  ${(props) =>
    props['aria-disabled'] &&
    `
    ${ElementTitle},
    ${ElementDescription} {
      color: #ccc;
    }
  `}
`;

const BrowserInfoLink = styled.a`
  color: ${themeGet('colors.primary')};
  text-decoration: underline;
  font-weight: bold;
`;

const messages = defineMessages({
  addCoOrganizers: {
    id: 'meetingDashboard.meetingScheduler.addCoOrganizers',
    defaultMessage: 'Add co-organizers'
  },
  addCoOrganizersDescription: {
    id: 'meetingDashboard.meetingScheduler.addCoOrganizersDescription',
    defaultMessage:
      'Co-organizers can start and run meetings on your behalf. Only members of your account can be co-organizers.'
  },
  allowAttendeesToStartMeeting: {
    id: 'meetingDashboard.meetingScheduler.allowAttendeesToStartMeeting',
    defaultMessage: 'Allow attendees to start the meeting'
  },
  allowAttendeesToStartMeetingDescription: {
    id: 'meetingDashboard.meetingScheduler.allowAttendeesToStartMeetingDescription',
    defaultMessage: 'Your attendees can start things off if you need to join later.'
  },
  allowAttendeesToStartMeetingDisabledExplanation: {
    id: 'meetingDashboard.meetingScheduler.allowAttendeesToStartMeetingRestrictionExplanation',
    defaultMessage: "Attendees can't start a meeting that requires a password."
  },
  requirePassword: {
    id: 'meetingDashboard.meetingScheduler.requirePassword',
    defaultMessage: 'Require a meeting password'
  },
  requirePasswordDescription: {
    id: 'meetingDashboard.meetingScheduler.requirePasswordDescription',
    defaultMessage: 'When you start your next meeting, you can create a password and share it with attendees.'
  },
  requirePasswordDisabledBecauseOfBreakout: {
    id: 'meetingDashboard.meetingScheduler.requirePasswordDisabledBecauseOfBreakout',
    defaultMessage: "You can't set a password for a meeting with breakout rooms."
  },
  requirePasswordDisabledBecauseOfOpenMeeting: {
    id: 'meetingDashboard.meetingScheduler.requirePasswordDisabledBecauseOfOpenMeeting',
    defaultMessage: "You can't set a password for a meeting that you allow attendees to start."
  },
  enableBreakoutRooms: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRooms',
    defaultMessage: 'Use breakout rooms'
  },
  enableBreakoutRoomsDescription: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRoomsDescription',
    defaultMessage: 'You can put people into smaller groups for separate discussions.'
  },
  enableBreakoutRoomsDisabledBecauseOfMissingVoip: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRoomsDisabledBecauseOfMissingVoip',
    defaultMessage: 'Your admin will have to turn on computer audio (VoIP) for you to use breakout rooms.'
  },
  enableBreakoutRoomsDisabledBecauseOfPassword: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRoomsDisabledBecauseOfPassword',
    defaultMessage: "You can't use breakout rooms in a meeting that requires a password."
  },
  enableBreakoutRoomsWarningHeadline: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRoomsWarningHeadline',
    defaultMessage: 'If you turn this on, keep in mind:'
  },
  enableBreakoutRoomsWarningMessage1: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRoomsWarningMessage1',
    defaultMessage: 'Everyone must use a {ChromeLink} or {EdgeLink} browser.'
  },
  chromeName: {
    id: 'meetingDashboard.meetingScheduler.chromeName',
    defaultMessage: 'Chrome'
  },
  edgeName: {
    id: 'meetingDashboard.meetingScheduler.edgeName',
    defaultMessage: 'Edge'
  },
  enableBreakoutRoomsWarningMessage2: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRoomsWarningMessage2',
    defaultMessage: 'Meeting lock will be disabled.'
  },
  enableBreakoutRoomsWarningMessage3: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRoomsWarningMessage3',
    defaultMessage: 'Only computer audio (VoIP) will be available.'
  },
  enableBreakoutRoomsInfoAlert: {
    id: 'meetingDashboard.meetingScheduler.enableBreakoutRoomsInfoBanner',
    defaultMessage:
      'The audio options for phone numbers and your own conference call service are disabled when using breakout rooms'
  }
});

export const MoreTab = (props: SchedulerTabProps) => {
  const [breakoutWarningOpen, setBreakoutWarningOpen] = useState(false);
  const { formatMessage: f } = useIntl();
  const isPersonalMeeting = !!props.profile && props.profile.meetingId === props.meetingId;

  return (
    <MoreTabContainer>
      {!!props.settings?.isCoorganizerFeatureEnabled && (
        <CoOrganizersBox>
          <ElementTitle>{f(messages.addCoOrganizers)}</ElementTitle>
          <ElementDescription>{f(messages.addCoOrganizersDescription)}</ElementDescription>
          <CoOrganizerSelect
            value={props.meeting.update.coorganizerKeys || []}
            onChange={(options) => props.onPropertyChange('coorganizerKeys', options)}
          />
        </CoOrganizersBox>
      )}

      {!!props.settings?.breakoutsAllowed && (
        <>
          <Checkbox
            id="allow-breakoutrooms"
            disabled={!props.meeting.update.voipAllowed || props.meeting.update.passwordRequired}
            checked={(props.meeting.update as any).breakoutsAllowed || false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const checked = e.target.checked;
              if (checked) {
                setBreakoutWarningOpen(true);
              } else {
                props.onPropertyChange('breakoutsAllowed', false);
              }
            }}
          >
            <ElementTitle>{f(messages.enableBreakoutRooms)}</ElementTitle>
            {!breakoutWarningOpen && (
              <>
                <ElementDescription>{f(messages.enableBreakoutRoomsDescription)}</ElementDescription>
                {!props.meeting.update.voipAllowed && (
                  <ElementDescription>{f(messages.enableBreakoutRoomsDisabledBecauseOfMissingVoip)}</ElementDescription>
                )}
                {props.meeting.update.voipAllowed && props.meeting.update.passwordRequired && (
                  <ElementDescription>{f(messages.enableBreakoutRoomsDisabledBecauseOfPassword)}</ElementDescription>
                )}
              </>
            )}
          </Checkbox>

          {breakoutWarningOpen && (
            <Card>
              <CheckboxAlertContainer
                onSave={() => {
                  setBreakoutWarningOpen(false);
                  const audioOptionOtherThanVoipSelected =
                    props.meeting.update.ownConferenceEnabled ||
                    props.meeting.update.longDistanceEnabled ||
                    props.meeting.update.tollFreeEnabled ||
                    props.meeting.update.callMeEnabled;

                  if (audioOptionOtherThanVoipSelected) {
                    props.onPropertyChange('ownConferenceEnabled', false);
                    props.onPropertyChange('longDistanceEnabled', false);
                    props.onPropertyChange('tollFreeEnabled', false);
                    props.onPropertyChange('callMeEnabled', false);
                    if (props.meeting.update.voipAllowed) props.onPropertyChange('voipEnabled', true);

                    props.showAlert({
                      alertLevel: AlertLevel.INFO,
                      alertText: f(messages.enableBreakoutRoomsInfoAlert),
                      closeOnTabChange: true
                    });
                  }
                  props.onPropertyChange('breakoutsAllowed', true);
                }}
                onCancel={() => {
                  setBreakoutWarningOpen(false);
                }}
              >
                <h3>{f(messages.enableBreakoutRoomsWarningHeadline)}</h3>
                <ul>
                  <li>
                    <FormattedMessage
                      {...messages.enableBreakoutRoomsWarningMessage1}
                      values={{
                        ChromeLink: (
                          <BrowserInfoLink
                            href={config.externalLinks.googleChromeHomepage}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {f(messages.chromeName)}
                          </BrowserInfoLink>
                        ),
                        EdgeLink: (
                          <BrowserInfoLink
                            href={config.externalLinks.microsoftEdgeHomepage}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {f(messages.edgeName)}
                          </BrowserInfoLink>
                        )
                      }}
                    />
                  </li>
                  <li>{f(messages.enableBreakoutRoomsWarningMessage2)}</li>
                  <li>{f(messages.enableBreakoutRoomsWarningMessage3)}</li>
                </ul>
              </CheckboxAlertContainer>
            </Card>
          )}
        </>
      )}
      {!isPersonalMeeting && !!props.settings?.isOpenMeetingsProvisioned && !!props.settings?.isOpenMeetingsEntitled && (
        <Checkbox
          id="allow-attendees-to-start-meeting"
          disabled={!props.settings.isOpenMeetingsEntitled || props.meeting.update.passwordRequired}
          checked={props.meeting.update.startWithoutOrganizerSupported || false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            props.onPropertyChange('startWithoutOrganizerSupported', e.target.checked)
          }
        >
          <ElementTitle>
            <FormattedMessage {...messages.allowAttendeesToStartMeeting} />
          </ElementTitle>
          <ElementDescription>
            <FormattedMessage {...messages.allowAttendeesToStartMeetingDescription} />
          </ElementDescription>
          {props.settings.isOpenMeetingsEntitled && props.meeting.update.passwordRequired && (
            <ElementDescription>
              <FormattedMessage {...messages.allowAttendeesToStartMeetingDisabledExplanation} />
            </ElementDescription>
          )}
        </Checkbox>
      )}

      <Checkbox
        id="require-password"
        disabled={props.meeting.update.startWithoutOrganizerSupported || props.meeting.update.breakoutsAllowed}
        checked={props.meeting.update.passwordRequired || false}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.onPropertyChange('passwordRequired', e.target.checked)
        }
      >
        <ElementTitle>
          <FormattedMessage {...messages.requirePassword} />
        </ElementTitle>
        <ElementDescription>
          <FormattedMessage {...messages.requirePasswordDescription} />
          <Link target="_blank" rel="noopener noreferrer" href={config.externalLinks.meetingPasswordLearnMoreLink}>
            {` ${f(preferencesHeaderMessages.learnMore)}`}
          </Link>
        </ElementDescription>
        {props.meeting.update.startWithoutOrganizerSupported && (
          <ElementDescription>
            <FormattedMessage {...messages.requirePasswordDisabledBecauseOfOpenMeeting} />
          </ElementDescription>
        )}
        {props.meeting.update.breakoutsAllowed && (
          <ElementDescription>
            <FormattedMessage {...messages.requirePasswordDisabledBecauseOfBreakout} />
          </ElementDescription>
        )}
      </Checkbox>
    </MoreTabContainer>
  );
};
