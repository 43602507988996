/* eslint-disable react/no-find-dom-node */
import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
  children: React.ReactElement<any> | null;
  handleClickOutside: () => void;
  isDisabled?: boolean;
}

export class OnClickOutside extends React.Component<Props, {}> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  findNode = (currentNode: Node) => {
    const componentNode = ReactDOM.findDOMNode(this);
    return componentNode === currentNode;
  };

  handleClick = (event: MouseEvent) => {
    if (this.props.isDisabled) {
      return;
    }
    let currentNode: Node = event.target as Node;
    let found = false;

    while (currentNode.parentNode) {
      found = this.findNode(currentNode);
      if (found) {
        return;
      }
      currentNode = currentNode.parentNode as HTMLElement;
    }

    this.props.handleClickOutside();
  };

  render() {
    return <div className="on-click-outside">{this.props.children}</div>;
  }
}
