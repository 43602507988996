import * as t from 'io-ts';
import api from '../lib/rest';
import config from 'config';
import { optional } from '../lib/io-ts-helpers';

interface Options {
  nextPageKey?: number;
  count?: number;
  internalOnly?: boolean;
}

const socialGraphQueryType = t.type({
  from: t.number,
  to: t.number,
  total: t.number,
  network: t.number,
  users: t.array(
    t.type({
      id: t.string,
      firstName: optional(t.string),
      lastName: optional(t.string),
      email: t.string
    })
  )
});

export const search = async (prefix: string, options: Options = {}) => {
  const index = options.internalOnly ? '_account' : 'pulse';
  const url = `${config.socialGraph.url}/users/me/indexes/${index}`;
  const query = {
    typeahead: prefix,
    lower: options.nextPageKey && options.nextPageKey > 0 ? options.nextPageKey : 0,
    limit: options.count || 10
  };

  const data = await api.getValidated(socialGraphQueryType, url, query);

  return {
    from: data.from,
    to: data.to,
    total: data.total,
    network: data.network,
    people: data.users.map((person) => ({
      ...person,
      jid: `${person.id}@chat.platform.getgo.com`
    }))
  };
};
