import * as React from 'react';
import * as Emojione from 'emojione';
import { ContentBlock, ContentState, EditorState, EntityInstance, Modifier, SelectionState } from 'draft-js';
import { asciiStrategy, shortNameStrategy, unicodeStrategy } from './emojiStrategy';

export type ContentChild = React.ReactElement<{
  block: ContentBlock;
  key: string | undefined;
  isLast: boolean;
  forceSelection: boolean;
  offsetKey: string;
  start: number;
  text: string;
  styleSet: object[];
  selection: any;
  customStyleFn: () => void;
  customStyleMap: any;
}>;

(Emojione as any).ascii = true;

export const getEditorStateContentProps = (editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent();
  const currentSelectedState = editorState.getSelection();
  const endPos = currentSelectedState.getAnchorOffset();
  const blockKey = currentSelectedState.getAnchorKey();
  const blockSize = currentContent.getBlockForKey(blockKey).getLength();

  return { currentContent, endPos, blockSize };
};

export const extendChildProps = (child: ContentChild) => {
  const childPropKey = child.props.block.getKey();

  return { childPropKey, ...child.props };
};

export const getContentStateWithEmojiEntity = (
  currentContent: ContentState,
  selectionState: SelectionState,
  emojiText: string,
  emojiShortname: string
) => {
  const contentStateWithEntity = createEmojiEntityState(currentContent, emojiShortname);

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  return Modifier.replaceText(currentContent, selectionState, emojiText, undefined, entityKey) as ContentState;
};

export const createEmojiEntityState = (contentState: ContentState, shortname: string) => {
  return contentState.createEntity('emoji', 'IMMUTABLE', { emojiShortname: shortname });
};

const pushEmojiEditorState = (editorState: EditorState, contentState: ContentState) => {
  const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');

  return EditorState.forceSelection(newEditorState, contentState.getSelectionAfter());
};

export const addEmojiEntityToState = (editorState: EditorState, shortName: string) => {
  const emojiUnicode = Emojione.shortnameToUnicode(shortName);
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = createEmojiEntityState(contentState, shortName);
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const currentSelectionState = editorState.getSelection();

  let emojiAddedContent;

  // in case text is selected it is removed and then the emoji is added
  const afterRemovalContentState = Modifier.removeRange(contentState, currentSelectionState, 'backward');

  // deciding on the position to insert emoji
  const targetSelection = afterRemovalContentState.getSelectionAfter();

  // eslint-disable-next-line prefer-const
  emojiAddedContent = Modifier.insertText(
    afterRemovalContentState,
    targetSelection,
    emojiUnicode,
    undefined,
    entityKey
  );

  return pushEmojiEditorState(editorState, emojiAddedContent);
};

function isCaretAndWithinText(userSelection: SelectionState, textRange: SelectionState) {
  return (
    userSelection.isCollapsed() &&
    textRange.hasEdgeWithin(
      userSelection.getFocusKey(),
      userSelection.getFocusOffset(),
      userSelection.getAnchorOffset()
    )
  );
}

function getEmojiShortAndUnicode(emojiText: string, entity: EntityInstance | null) {
  let emojiUnicode = Emojione.unifyUnicode(emojiText);
  let emojiShortname = Emojione.toShort(emojiUnicode);

  if (entity && entity.getType() === 'IMAGE') {
    // inserted emoji images have a fallback text (:camera: in unicode)
    // and their original emoji in the entity's data (src for url, alt for unicode)
    const data = entity.getData();
    if (data.alt) {
      const e = Emojione.toShort(data.alt);
      if (e !== data.alt) {
        emojiUnicode = data.alt;
        emojiShortname = e;
      }
    }
  }

  return { emojiUnicode, emojiShortname };
}

function getSortedEmojiPositions(block: ContentBlock) {
  let positions: Array<{ start: number; end: number }> = [];
  const savePosition = (start: number, end: number) => positions.push({ start, end });

  shortNameStrategy(block, savePosition);
  asciiStrategy(block, savePosition);
  unicodeStrategy(block, savePosition);

  return (positions = positions.sort((a, b) => a.start - b.start));
}

/*
 * Attaches Immutable DraftJS Entities to the Emoji text.
 *
 * This is necessary as emojis consist of 2 characters (unicode). By making them
 * immutable the whole Emoji is removed when hitting backspace.
 */
export const attachImmutableEntitiesToEmojis = (editorState: EditorState): EditorState => {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlockMap();
  let newContentState = contentState;
  let newSelection = null;

  blocks.forEach((block?: ContentBlock) => {
    if (!block) {
      return;
    }

    // keep track of offset caused by text replacements for subsequent iterations
    let offset = 0;

    const plainText = block.getText();

    const addEntityToEmoji = (start: number, end: number) => {
      const existingEntityKey = block.getEntityAt(start);
      const entity = existingEntityKey ? newContentState.getEntity(existingEntityKey) : null;

      if (entity && entity.getType() === 'emoji') {
        return;
      }

      let emojiText = plainText.substring(start, end);
      const hasLeadingWhitespace = emojiText[0] === ' ';
      if (hasLeadingWhitespace) {
        emojiText = emojiText.slice(1);
      }

      const textRange = SelectionState.createEmpty(block.getKey())
        .set('anchorOffset', start + offset + (hasLeadingWhitespace ? 1 : 0))
        .set('focusOffset', end + offset) as SelectionState;

      const { emojiUnicode, emojiShortname } = getEmojiShortAndUnicode(emojiText, entity);

      if (!(emojiShortname in Emojione.emojioneList)) {
        return;
      }

      const contentStateWithEntity = createEmojiEntityState(newContentState, emojiShortname);
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

      newContentState = Modifier.replaceText(newContentState, textRange, emojiUnicode, undefined, entityKey);

      // if user selection is a caret within the replaced text (e.g. while typing),
      // move the caret outside
      const curSelection = editorState.getSelection();
      if (isCaretAndWithinText(curSelection, textRange)) {
        newSelection = newContentState.getSelectionAfter();
      }

      offset += emojiUnicode.length - emojiText.length;
    };

    const positions = getSortedEmojiPositions(block);
    positions.forEach(({ start, end }) => addEntityToEmoji(start, end));
  });

  if (!newContentState.equals(contentState)) {
    let newEditorState = EditorState.set(editorState, { currentContent: newContentState });
    if (newSelection) {
      newEditorState = EditorState.set(newEditorState, { selection: newSelection });
    }
    return newEditorState;
  }

  return editorState;
};
