import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from '../../../styled-components';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import config from 'config';
import { encodeObjectIntoURI } from '../../../../lib/url';
import device from '../../Common/Toolkit/devices';
import { CloseButton } from '../../Common/Toolkit/Button/CloseButton';

const ProfilePreviewWindow = styled.div`
  padding: 0 24px 24px 24px;
  max-width: calc(100vw - 48px);
  width: 1300px;
  height: 900px;
  max-height: calc(100vh - 100px);
  @media ${device.tablet} {
    width: 800px;
    height: 500px;
  }
  overflow: hidden;
  box-sizing: border-box;
  border-top: 6px solid #f68d2e;
  position: relative;
`;

const Title = styled.h2`
  color: #333;
`;

const PreviewIframe = styled.iframe`
  pointer-events: none;
  width: 100%;
  height: calc(100% - 67px);

  @media ${device.tablet} {
    width: 200%;
    height: calc(200% - 135px);
    transform: scale(0.5);
    transform-origin: 0 0;
  }

  outline: none;
  border: none;
`;

export const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  right: 20px;
  top: 22px;
  transform: translateY(-50%);
  height: 14px;
`;

const messages = defineMessages({
  personalizeTitle: {
    id: 'meetingDashboard.profilePreview.title',
    defaultMessage: 'Preview'
  }
});

interface Props {
  hideProfilePreview: () => void;
  theme: string;
  userKey: string;
  roomDisplayName?: string;
}

class ProfilePreview extends React.Component<Props & WrappedComponentProps> {
  private buildQueryParams = () => {
    return encodeObjectIntoURI({
      userKey: this.props.userKey,
      theme: this.props.theme,
      roomDisplayName: this.props.roomDisplayName
    });
  };

  render() {
    return (
      <ProfilePreviewWindow>
        <PositionedCloseButton
          onClick={this.props.hideProfilePreview}
          size={30}
          dataTestId="profile-preview-close-button"
        />
        <Title>
          <FormattedMessage {...messages.personalizeTitle} />
        </Title>

        <PreviewIframe src={`${config.preview.profile}?${this.buildQueryParams()}`} />
      </ProfilePreviewWindow>
    );
  }
}

export default injectIntl(ProfilePreview);
