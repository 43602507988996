import { Selectors } from '@getgo/caps-redux';
import { search } from '../../../../../models/SocialGraph';
import { CoreContact } from '../../../../../types/pulse-web';
import loggerService from '../../../../services/LoggerService';
import * as ErrorActions from '../../core/errors/errorActions';
import rest from '../../../../../lib/rest';
import { ActionsUnion, Thunk, createAction } from '../../../type-helpers';

const logger = loggerService.create('PeopleActions');
export const pageSize = 50;

export enum ActionTypes {
  PEOPLE_REQUEST = 'PEOPLE_REQUEST',
  PEOPLE_RECEIVE = 'PEOPLE_RECEIVE',
  PEOPLE_CLEAR = 'PEOPLE_CLEAR'
}

export const actions = {
  queryPeoplePending: (query: string, page?: number) => createAction(ActionTypes.PEOPLE_REQUEST, { query, page }),
  queryPeopleSuccess: (payload: { from: number; to: number; total: number; network: number; people: CoreContact[] }) =>
    createAction(ActionTypes.PEOPLE_RECEIVE, payload),
  clearQuerySuccess: () => createAction(ActionTypes.PEOPLE_CLEAR)
};

export type Actions = ActionsUnion<typeof actions>;

/**
 * Makes a query to the Social Graph API.
 * @param {string} prefix - prefix to query for
 * @param {number} page - zero-based page # the retrieve from the result set
 * @param {array} filter - jid's to filter out of the result set
 * @param {object} options - Options object
 * @param {boolean} options.preventUIError - prevent showing any error to the user
 */
export const queryPeople = (
  prefix = '',
  page = 0,
  filter: any[] = [],
  options: { preventUiError?: boolean } = {}
): Thunk => (dispatch, getState) => {
  const query = prefix.replace(/^\s+/g, '');
  const { app } = getState().core;
  const apiOptions = {
    count: pageSize,
    nextPageKey: page,
    filter,
    internalOnly: !app.features.includes('external_contacts')
  };

  dispatch(actions.queryPeoplePending(query, page));

  return search(query, apiOptions)
    .then((suggestions) => dispatch(actions.queryPeopleSuccess(suggestions)))
    .catch((err) => {
      if (options.preventUiError || rest.isAborted(err)) {
        return null;
      }

      const status = Number(err.status);
      const errorPayload = { error: err, category: ErrorActions.category.socialGraph };

      if (status === 400) {
        logger.error('queryPeople', 'error=', err, 'params=', { prefix, options });
        return dispatch(ErrorActions.apiErrorThunk({ ...errorPayload, name: 'search-params-error' }));
      }

      if (status === 403) {
        return dispatch(ErrorActions.apiErrorThunk({ ...errorPayload, name: 'api-auth-error' }));
      }

      return dispatch(ErrorActions.apiErrorThunk({ ...errorPayload, name: 'search-service-error' }));
    });
};

export const queryAllPeople = (prefix = '', page = 0): Thunk => (dispatch, getState) => {
  const { core } = getState();
  const { user } = core;
  return dispatch(queryPeople(prefix, page, user.hasLoaded ? [user.jid] : [undefined]));
};

export const queryNewPeople = (prefix = '', page = 0): Thunk => (dispatch, getState) => {
  const state = getState();
  const { user } = state.core;
  const contacts = Selectors.getContacts(state);
  const currentContactJids = contacts.items.map((c) => c.jid);
  return dispatch(queryPeople(prefix, page, [...currentContactJids, user.hasLoaded ? user.jid : undefined]));
};

export const clearQuery = (options: { preventUiError?: boolean } = {}): Thunk => (dispatch) => {
  dispatch<any>(actions.clearQuerySuccess());
  // Show users social graph by default
  return dispatch(queryPeople('', undefined, undefined, options));
};
