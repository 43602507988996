import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import AlertsContainer from '../../containers/AlertsContainer';
import { setTitleUnreadCount } from '../../services/TitleService';
import ToastsContainer from '../../containers/ToastsContainer';
import { ElectronDecomModal } from './ElectronDecomModal/ElectronDecomModal';
import { AppErrorBoundary } from '../Common/ErrorPages/AppErrorBoundary';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getRouteFor } from '../../services/NavService';
import MeetingsDashboard from '../../containers/MeetingsDashboardContainer';
import { Preferences } from '../Preferences/Preferences';
import { DiscoverDashboard } from '../Discover/DiscoverDashboard';
import EntitlementContainer from '../../containers/EntitlementContainer';
import ContactSearch from '../../containers/ContactSearchContainer';
import CreateGroup from '../../containers/CreateGroupContainer';
import GroupSearch from '../../containers/GroupSearchContainer';
import AddMembers from '../../containers/AddMembersContainer';
import { ChatRoute } from '../Routing/ChatRoute';
import { ChatAppRoute } from '../Routing/ChatAppRoute';
import { ChatConversationRoute } from '../Routing/ChatConversationRoute';
import { NotFoundRedirect } from '../Routing/NotFoundRedirect';
import styled from '../../styled-components';
import { useSelector } from 'react-redux';
import { Selectors } from '@getgo/caps-redux';
import { State } from '../../view-model/types';
import { AppMenu } from './AppMenu/AppMenu';

const ShellRoutes = () => (
  <>
    <Route exact path={getRouteFor('meetings')} render={() => <Redirect to={getRouteFor('anytimeMeetings')} />} />
    <Route exact path={getRouteFor('settings')} render={() => <Redirect to={getRouteFor('meetingPreferences')} />} />
    <Switch>
      <Route path={getRouteFor('meetings')} component={MeetingsDashboard} />
      <Route path="/schedule" component={MeetingsDashboard} />
      <Route path={getRouteFor('settings')} component={Preferences} />
      <Route exact path={getRouteFor('discover')} component={DiscoverDashboard} />
      <Route path={getRouteFor('disabledChat')} component={EntitlementContainer} />
      <ChatRoute path={getRouteFor('addContact')} component={ContactSearch} />
      <ChatRoute path={getRouteFor('createGroup')} component={CreateGroup} />
      <ChatRoute path={getRouteFor('groupSearch')} component={GroupSearch} />
      <ChatRoute path={getRouteFor('addMembers')} component={AddMembers} />
      <ChatRoute exact path={getRouteFor('chat')} component={ChatAppRoute} />
      <ChatRoute exact path={getRouteFor('chatConversation')} component={ChatConversationRoute} />
      <Route component={NotFoundRedirect} />
    </Switch>
  </>
);

const ShellWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
`;

export const Shell: React.FC = () => {
  const chatUnreadCount = useSelector((state: State) => Selectors.getUnreadMessageCounts(state).total);

  useEffect(() => {
    ReactTooltip.rebuild();
    setTitleUnreadCount(chatUnreadCount);
  });

  return (
    <AppErrorBoundary>
      <ShellWrapper>
        <AlertsContainer />
        <ToastsContainer />
        <AppMenu chatUnreadCount={chatUnreadCount}>
          <ShellRoutes />
        </AppMenu>
        <ReactTooltip effect="solid" type="dark" />
        <ElectronDecomModal />
      </ShellWrapper>
    </AppErrorBoundary>
  );
};
