import React, { createElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../../view-model/types';
import { getRouteFor } from '../../services/NavService';

export const ChatRoute: React.FC<RouteProps> = ({ component, ...rest }) => {
  const isChatEnabled = useSelector((state: State) => state.core.app.features).includes('chat');
  return (
    <Route
      {...rest}
      render={() =>
        isChatEnabled && component ? createElement(component, {}) : <Redirect to={getRouteFor('disabledChat')} />
      }
    />
  );
};
