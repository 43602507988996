export interface AudioSettingItems {
  ownConferenceEnabled: boolean;
  ownConferenceAllowed: boolean;
  ownConferenceMessage: string;
  voipAllowed: boolean;
  voipEnabled: boolean;
  longDistanceEnabled: boolean;
  longDistanceAllowed: boolean;
  allowedLongDistanceCountries: string[];
  longDistanceCountries: string[];
  tollFreeAllowed: boolean;
  tollFreeEnabled: boolean;
  tollFreeCountries: string[];
  allowedTollFreeCountries: string[];
  callMeEnabled: boolean;
  callMeAllowed: boolean;
  callMeCountries: string[];
  allowedCallMeCountries: string[];
}

export enum AudioValidationErrors {
  AUDIO_NO_BUILT_IN_OPTION_SELECTED = 'AUDIO_NO_BUILT_IN_OPTION_SELECTED',
  AUDIO_EMPTY_LONG_DISTANCE_COUNTRY_LIST = 'AUDIO_EMPTY_LONG_DISTANCE_COUNTRY_LIST',
  AUDIO_EMPTY_TOLL_FREE_COUNTRY_LIST = 'AUDIO_EMPTY_TOLL_FREE_COUNTRY_LIST',
  AUDIO_EMPTY_CALLME_COUNTRY_LIST = 'AUDIO_EMPTY_CALLME_COUNTRY_LIST',
  AUDIO_EMPTY_CUSTOM_MESSAGE = 'AUDIO_EMPTY_CUSTOM_MESSAGE'
}

export const getAudioValidationErrors = (audioSettings: AudioSettingItems): AudioValidationErrors[] => {
  const errors = [];

  // no validation errors when ownConference is selected
  if (!audioSettings.ownConferenceEnabled) {
    const atLeastOneAudioOption =
      audioSettings.voipEnabled ||
      audioSettings.longDistanceEnabled ||
      audioSettings.tollFreeEnabled ||
      audioSettings.callMeEnabled;
    if (!atLeastOneAudioOption) {
      errors.push(AudioValidationErrors.AUDIO_NO_BUILT_IN_OPTION_SELECTED);
    }
    if (
      audioSettings.longDistanceAllowed &&
      audioSettings.longDistanceEnabled &&
      audioSettings.longDistanceCountries.length === 0
    ) {
      errors.push(AudioValidationErrors.AUDIO_EMPTY_LONG_DISTANCE_COUNTRY_LIST);
    }
    if (
      audioSettings.tollFreeAllowed &&
      audioSettings.tollFreeEnabled &&
      audioSettings.tollFreeCountries.length === 0
    ) {
      errors.push(AudioValidationErrors.AUDIO_EMPTY_TOLL_FREE_COUNTRY_LIST);
    }
    if (audioSettings.callMeAllowed && audioSettings.callMeEnabled && audioSettings.callMeCountries.length === 0) {
      errors.push(AudioValidationErrors.AUDIO_EMPTY_CALLME_COUNTRY_LIST);
    }
  }

  if (
    audioSettings.ownConferenceAllowed &&
    audioSettings.ownConferenceEnabled &&
    audioSettings.ownConferenceMessage?.trim() === ''
  ) {
    errors.push(AudioValidationErrors.AUDIO_EMPTY_CUSTOM_MESSAGE);
  }
  return errors;
};
