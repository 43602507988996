import * as React from 'react';
import styled from '../../../styled-components';
import Select from 'react-select';
import { styleSelect } from '../../Common/Toolkit/Input/SelectStyles';
import { defineMessages, useIntl } from 'react-intl';
import { themeGet } from '@styled-system/theme-get';
import device from '../../Common/Toolkit/devices';

export enum InvitationTypes {
  DEFAULT = '',
  AUDIO_ONLY = 'audioonly'
}

const messages = defineMessages({
  defaultType: {
    id: 'meetingHub.invitationSelector.defaultType',
    defaultMessage: 'Default Invitation'
  },
  audioOnlyType: {
    id: 'meetingHub.invitationSelector.audioOnlyType',
    defaultMessage: 'Phone Only Invitation '
  },
  label: {
    id: 'meetingHub.invitationSelector.label',
    defaultMessage: 'Select invitation type'
  }
});

const StyledSelect = styled(styleSelect(Select))`
  .react-select__control {
    height: 36px;
    padding: 0 20px;
    @media ${device.mobile} {
      padding: 0 26px;
    }

    &--is-focused {
      box-shadow: 0 0 0 2px ${themeGet('colors.primary')};
    }

    &:hover {
      background-color: ${themeGet('colors.neutralScale.100')};
    }

    svg {
      fill: ${themeGet('colors.primary')};
    }

    &--is-disabled {
      filter: grayscale(100%);
    }
  }
  .react-select__single-value {
    color: ${themeGet('colors.primary')};
    font-family: lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: bold;

    @media ${device.laptop} {
      font-size: 12px;
    }
  }

  .react-select__menu {
    & {
      box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.2) !important;
    }
    ::before {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      border-top: 0;
      border-right: 6px solid transparent;
      border-bottom: 5px solid #fff;
      border-left: 6px solid transparent;
      right: 32px;

      @media ${device.mobile} {
        border-top: 5px solid #fff;
        border-bottom: 0;
        top: auto;
        bottom: -5px;
      }
    }
  }
`;

const InvitationWrapper = styled.div`
  margin-left: auto;
  width: 280px;

  @media ${device.tablet} {
    max-width: 100%;
    margin-left: 0;
    margin-right: auto;
  }
`;

export interface Props {
  invitationType: string;
  onChange: (template: string) => void;
  isFetching?: boolean;
}

export const InvitationSelector = (props: Props) => {
  const intl = useIntl();
  const options = [
    { value: InvitationTypes.DEFAULT, label: intl.formatMessage(messages.defaultType) },
    { value: InvitationTypes.AUDIO_ONLY, label: intl.formatMessage(messages.audioOnlyType) }
  ];
  return (
    <InvitationWrapper data-testid={'invitation-select-wrapper'}>
      <StyledSelect
        classNamePrefix="react-select"
        isDisabled={!!props.isFetching}
        isLoading={!!props.isFetching}
        aria-label={intl.formatMessage(messages.label)}
        value={options.find((item) => item.value == props.invitationType)}
        options={options}
        menuPlacement={'auto'}
        onChange={({ value }: { value: string }) => {
          if (value !== props.invitationType) {
            props.onChange(value);
          }
        }}
      />
    </InvitationWrapper>
  );
};
