import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useIntl } from 'react-intl';
import { PreferencesBadge } from './PreferencesBadge/PreferencesBadge';
import { isHoverSupported } from '../../../services/ClientService';
import { Checkbox, Radio } from '../Toolkit/Input/Selectable';
import styled from '../../../styled-components';
import { ErrorMessageInline, Description, Block } from '../Toolkit/FormElements/TextElements';

const Textarea = styled.textarea`
  width: 100%;
  height: 60px;
`;

interface Props {
  id: string;
  badge?: 'new' | 'beta';
  type?: 'radio' | 'checkbox';
  checked: boolean;
  onCheckboxChange: (value: boolean) => void;
  onTextChange: (value: string) => void;
  disabled: boolean;
  isLoading?: boolean;
  text?: string;
  labelMessage: MessageDescriptor;
  labelMessageValues?: { [key: string]: string };
  errorMessage?: MessageDescriptor;
  descriptionMessage?: MessageDescriptor;
  disabledTooltip?: MessageDescriptor;
  enabledTooltip?: MessageDescriptor;
}

export const PreferencesItemCheckedTextArea: React.FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();
  const handleCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    props.onCheckboxChange(evt.target.checked);
  };

  const handleInputFieldChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onTextChange(event.target.value);
  };

  const {
    id,
    labelMessage,
    labelMessageValues,
    descriptionMessage,
    checked,
    disabled,
    badge,
    errorMessage,
    disabledTooltip,
    enabledTooltip,
    type
  } = props;

  const Element = type === 'checkbox' ? Checkbox : Radio;

  return (
    <Block>
      <Element
        id={id}
        checked={checked}
        disabled={disabled}
        labelProps={{
          'data-delay-show': '500',
          'data-place': 'right',
          'data-tip': !isHoverSupported
            ? null
            : disabled
            ? disabledTooltip && f(disabledTooltip)
            : enabledTooltip && f(enabledTooltip)
        }}
        onChange={handleCheckboxChange}
      >
        <FormattedMessage {...labelMessage} values={labelMessageValues} />

        {badge && <PreferencesBadge badge="new" marginleft="8px" />}
        {errorMessage && (
          <ErrorMessageInline data-testid={`${id}-error`}>
            <FormattedMessage {...errorMessage} />
          </ErrorMessageInline>
        )}
        {descriptionMessage && (
          <Description>
            <FormattedMessage {...descriptionMessage} />
          </Description>
        )}

        <Textarea
          disabled={disabled || !props.checked}
          onChange={handleInputFieldChange}
          value={props.text}
          data-testid={`${id}-textarea`}
        />
      </Element>
    </Block>
  );
};
