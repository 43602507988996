import { connect } from 'react-redux';
import sharedActions from '../view-model/sharedActions';
import { AdditionalItems } from '../components/Shell/AppMenu/AdditionalItems/AdditionalItems';
import { Dispatch, State } from '../view-model/types';
import { isFreeUser } from '../view-model/selectors';

export const mapStateToProps = (state: State) => ({
  isFreeUser: isFreeUser(state),
  user: state.core.user
});

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  openLink(linkTargetType: string) {
    dispatch(sharedActions.webPageOpened(linkTargetType));
  },
  upgradeAccount() {
    dispatch(sharedActions.upgradeLinkClicked());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalItems);
