import * as React from 'react';
import * as clipboard from 'clipboard-polyfill';
import config from 'config';
import Scrollbars from 'react-custom-scrollbars';
import ReactTooltip from 'react-tooltip';
import { Invitation, SessionUpdates } from '../../../view-model/modules/meetings/meetingsReducer';
import { Card } from '../../Common/Toolkit/Card/Card';
import styled from '../../../styled-components';
import device from '../../Common/Toolkit/devices';
import MeetingDetails from './MeetingDetails';
import OneTimeMeetingDetails from './OneTimeMeetingDetails';
import { OnClickOutside } from '../../Common/OnClickOutside';
import { MediaQueryFeatureSelector } from '../../Common/MediaQueryFeatureSelector';
import { ModalDialog } from '../../Common/ModalDialog/ModalDialog';
import { RecordingDelete } from '../MeetingsHistory/RecordingDelete';
import { User } from '../../../view-model/modules/core/user/userReducer';
import { CreateMeetingResponse } from '../../../../models/Meeting';
import { DashboardContentPane } from '../../Common/Dashboard/DashboardContentPane';
import { CloseButton } from '../../Common/Toolkit/Button/CloseButton';
import { GlobalSearchContainer } from '../../GlobalSearch/GlobalSearchContainer';
import { SelectedSearchResultView } from '../../GlobalSearch/SearchView/SelectedSearchResultView';
import { MeetingAssetsDownload } from '../MeetingsHistory/MeetingAssetsDownload/MeetingAssetsDownload';
import {
  isImpromptuMeeting,
  isRecurringMeeting,
  isScheduledMeeting,
  isSession,
  Meeting,
  SelectableItemType,
  Session
} from '../../../view-model/modules/meetings/MeetingsTypes';
import { MeetingDetailsErrorBoundary } from '../../Common/ErrorBoundary/MeetingDetailsErrorBoundary';

const CardContainer = styled(Card)`
  @media ${device.mobile} {
    border-radius: 8px 8px 0 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: stretch;
    margin: 0;
  }
`;

export const ButtonsRow = styled((props: any) => <div className={props.className}>{props.children}</div>)`
  display: flex;
  flex-wrap: wrap;
  margin: 0 3px 0 -3px;

  @media ${device.tablet} {
    margin: 0;
  }

  @media ${device.mobile} {
    margin: 0;
    flex-direction: column;
  }
`;

export const ButtonWrapper = styled.div`
  margin: 3px;
  display: flex;

  @media ${device.tablet} {
    flex: 1 1 auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin: 0 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f7f8fa;
  justify-content: stretch;

  ${ButtonsRow}:last-child:nth-child(n+2) {
    margin-left: 16px;
  }

  @media ${device.laptop} {
    margin: 0 24px;
  }

  @media ${device.tablet} {
    margin: 0 16px;
    flex-direction: column;

    ${ButtonsRow}:last-child:nth-child(n+2) {
      margin: 0;
    }
  }

  @media ${device.mobile} {
    margin: 0;
    padding: 0;
    border: 0;
  }
`;

export const Description = styled.div.attrs({
  'data-testid': 'meeting-details-description'
})<{ hideOnMobile?: boolean; mobilePadding?: string }>`
  padding: 24px 32px;

  @media ${device.laptop} {
    padding: 24px;
  }

  @media ${device.tablet} {
    padding: 16px;
  }

  @media ${device.mobile} {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'block')};
    padding: ${(props) => props.mobilePadding || '0'};
  }
`;

export const DownloadLink = styled.a`
  display: inline-flex;
  text-decoration: none;
  flex-grow: 1;
`;

const MobileViewOverlay = styled.div`
  background: rgba(37, 40, 45, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  top: 12px;
  right: 12px;
`;
interface ConditionalProps {
  sessionId?: string;
}

interface Props {
  meeting: (Meeting & ConditionalProps) | Session | null;
  selectedItemType: SelectableItemType;
  user: User;
  selectMeeting: (itemType: SelectableItemType, id: string | null) => void;
  joinMeeting: (meetingId: string) => void;
  showEditMeeting: (meetingId: string) => void;
  changeInvitation: (meetingId: string, invitation: string) => void;
  showDeleteMeeting: (meetingId: string) => void;
  startMeeting: (meetingId: string, meetingType: SelectableItemType) => Promise<void>;
  invitationCopied: () => void;
  showPersonalizeMeeting: () => void;
  hidePersonalizeMeeting: () => void;
  meetNow: () => Promise<CreateMeetingResponse>;
  endMeeting: (meeting: Meeting) => void;

  invitation: Invitation | null;

  deleteSessionRecording: (sessionId: string, meetingId: string, recordingId: string) => void;
  recordingSessionOpened: () => void;
  downloadSessionRecording: (meetingId: string, recordingId: string) => void;
  copyStandalonePlayerUrl: (meetingId: string, recordingId: string) => void;
  elasticSearchSession: null | Session;
  updateMeetingSessionDetails: (sessionId: string, updatedSession: SessionUpdates) => void;
  meetingTitleEdited: (view: string) => void;
  updateMeetingTitleEditError: (error: boolean) => void;
}

interface State {
  showDeleteRecordingConfirmationModal: boolean;
  showElasticSearchDateRangePicker: boolean;
  showRecordingAssetModal: boolean;
  showMeetingTitleEditModal: boolean;
}

export class MeetingDetailsWrapper extends React.Component<Props, State> {
  state = {
    showDeleteRecordingConfirmationModal: false,
    showElasticSearchDateRangePicker: false,
    showRecordingAssetModal: false,
    showMeetingTitleEditModal: false
  };

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  private openTranscript = (sessionId: string) => {
    this.props.recordingSessionOpened();
    window.open(`${config.externalLinks.g2mTranscripts}/#/sessionId/${sessionId}`, '_blank');
  };

  private showDeleteRecordingConfirmationModal = () => {
    this.setState({
      showDeleteRecordingConfirmationModal: true
    });
  };

  private hideRecordingDeleteConfirmationModal = () => {
    this.setState({
      showDeleteRecordingConfirmationModal: false
    });
  };

  private showElasticSearchDateRangePicker = () => {
    this.setState({
      showElasticSearchDateRangePicker: true
    });
  };

  private hideElasticSearchDateRangePicker = () => {
    this.setState({
      showElasticSearchDateRangePicker: false
    });
  };

  private hideRecordingAssetModal = () => {
    this.setState({
      showRecordingAssetModal: false
    });
  };

  private showRecordingAssetModal = () => {
    this.setState({
      showRecordingAssetModal: true
    });
  };

  private hideMeetingTitleEditModal = () => {
    this.setState({
      showMeetingTitleEditModal: false
    });
  };

  private showMeetingTitleEditModal = () => {
    this.setState({
      showMeetingTitleEditModal: true
    });
  };

  handleStartMeeting = () => {
    switch (this.props.selectedItemType) {
      case SelectableItemType.PERSONAL:
      case SelectableItemType.RECURRING:
      case SelectableItemType.SCHEDULED:
        if (this.props.meeting) {
          return this.props.startMeeting(this.props.meeting.meetingId, this.props.selectedItemType);
        }
        return Promise.reject();
      case SelectableItemType.IMPROMPTU:
        if (this.props.meeting) {
          return this.props.startMeeting(this.props.meeting.meetingId, this.props.selectedItemType);
        }
        return this.props.meetNow();
      default:
        return Promise.reject();
    }
  };

  handleOpenRecording = () => {
    if (this.props.meeting && isSession(this.props.meeting)) {
      this.openTranscript(this.props.meeting.sessionId);
    } else if (this.props.elasticSearchSession && isSession(this.props.elasticSearchSession)) {
      this.openTranscript(this.props.elasticSearchSession?.sessionId);
    }
  };

  handleDownloadRecording = () => {
    if (this.props.meeting && isSession(this.props.meeting)) {
      this.props.downloadSessionRecording(this.props.meeting.sessionId, this.props.meeting.recording!.recordingId);
    } else if (this.props.elasticSearchSession && isSession(this.props.elasticSearchSession)) {
      this.props.downloadSessionRecording(
        this.props.elasticSearchSession?.sessionId,
        this.props.elasticSearchSession?.recording!.recordingId
      );
    }
  };

  handleDeleteRecording = () => {
    if (this.props.meeting && isSession(this.props.meeting)) {
      this.props.deleteSessionRecording(
        this.props.meeting.sessionId,
        this.props.meeting.meetingId,
        this.props.meeting.recording!.recordingId
      );
    } else if (this.props.elasticSearchSession && isSession(this.props.elasticSearchSession)) {
      this.props.deleteSessionRecording(
        this.props.elasticSearchSession?.sessionId,
        this.props.elasticSearchSession?.meetingId,
        this.props.elasticSearchSession?.recording!.recordingId
      );
    }
  };

  handleCopyInvitation = (): Promise<{ copied: boolean; error: boolean }> => {
    this.props.invitationCopied();
    const invitation = this.props.invitation;
    if (!invitation || !invitation.invitationHtml || !invitation.invitationText) {
      return Promise.resolve({ copied: false, error: true });
    }

    const dt = new clipboard.DT();
    dt.setData('text/plain', invitation.invitationText);
    dt.setData('text/html', invitation.invitationHtml);

    return new Promise((resolve) => {
      clipboard.write(dt).then(
        () => {
          resolve({ copied: true, error: false });
        },
        () => {
          resolve({ copied: false, error: true });
        }
      );
    });
  };

  handleClickOutside = () => this.props.selectMeeting(SelectableItemType.NONE, null);

  render() {
    const { meeting, selectedItemType } = this.props;

    const disableClickOutside =
      this.state.showRecordingAssetModal ||
      this.state.showDeleteRecordingConfirmationModal ||
      this.state.showElasticSearchDateRangePicker ||
      this.state.showMeetingTitleEditModal;

    let containerContent = null;

    if (selectedItemType === SelectableItemType.NONE) {
      return <DashboardContentPane />;
    }

    switch (selectedItemType) {
      case SelectableItemType.PERSONAL:
      case SelectableItemType.RECURRING:
        if (meeting !== null && !isSession(meeting) && isRecurringMeeting(meeting)) {
          containerContent = (
            <MeetingDetails
              {...this.props}
              meeting={meeting}
              startMeeting={this.handleStartMeeting}
              copyInvitation={this.handleCopyInvitation}
            />
          );
        }
        break;
      case SelectableItemType.SCHEDULED:
        if (meeting !== null && !isSession(meeting) && isScheduledMeeting(meeting)) {
          containerContent = (
            <MeetingDetails
              {...this.props}
              meeting={meeting}
              copyInvitation={this.handleCopyInvitation}
              startMeeting={this.handleStartMeeting}
            />
          );
        }
        break;
      case SelectableItemType.IMPROMPTU:
        if (!meeting) {
          containerContent = (
            <OneTimeMeetingDetails startMeeting={this.handleStartMeeting} selectMeeting={this.props.selectMeeting} />
          );
        } else if (!isSession(meeting) && isImpromptuMeeting(meeting)) {
          containerContent = (
            <MeetingDetails
              {...this.props}
              meeting={meeting}
              copyInvitation={this.handleCopyInvitation}
              startMeeting={this.handleStartMeeting}
            />
          );
        }
        break;
      case SelectableItemType.SESSION:
        if (meeting !== null && isSession(meeting)) {
          containerContent = (
            <MeetingDetailsErrorBoundary>
              <SelectedSearchResultView
                session={meeting}
                userSettings={this.props.user.settings}
                handleOpenRecording={this.handleOpenRecording}
                onDownloadRecording={this.handleDownloadRecording}
                showDeleteRecordingConfirmationModal={this.showDeleteRecordingConfirmationModal}
                copyStandalonePlayerUrl={this.props.copyStandalonePlayerUrl}
                enableOnClickOutside={this.hideElasticSearchDateRangePicker}
                showRecordingAssetModal={this.showRecordingAssetModal}
                editMeetingTitle={this.props.updateMeetingSessionDetails}
                meetingTitleEdited={this.props.meetingTitleEdited}
                showMeetingTitleEditModal={this.showMeetingTitleEditModal}
                hideMeetingTitleEditModal={this.hideMeetingTitleEditModal}
                updateMeetingTitleEditError={this.props.updateMeetingTitleEditError}
              />
            </MeetingDetailsErrorBoundary>
          );
        }
        break;
      case SelectableItemType.ELASTICSEARCH:
      case SelectableItemType.ELASTICSEARCHRESULTSELECTED:
        containerContent = (
          <GlobalSearchContainer
            showElasticSearchDateRangePicker={this.showElasticSearchDateRangePicker}
            hideElasticSearchDateRangePicker={this.hideElasticSearchDateRangePicker}
            userSettings={this.props.user.settings}
            handleOpenRecording={this.handleOpenRecording}
            onDownloadRecording={this.handleDownloadRecording}
            copyStandalonePlayerUrl={this.props.copyStandalonePlayerUrl}
            showDeleteRecordingConfirmationModal={this.showDeleteRecordingConfirmationModal}
            showRecordingAssetModal={this.showRecordingAssetModal}
            editMeetingTitle={this.props.updateMeetingSessionDetails}
            meetingTitleEdited={this.props.meetingTitleEdited}
            showMeetingTitleEditModal={this.showMeetingTitleEditModal}
            hideMeetingTitleEditModal={this.hideMeetingTitleEditModal}
            updateMeetingTitleEditError={this.props.updateMeetingTitleEditError}
          />
        );
        break;
    }
    const recordingModals = (
      <>
        {this.state.showRecordingAssetModal && (
          <ModalDialog contentLabel="RecordingDownload" onClose={this.hideRecordingAssetModal}>
            <MeetingAssetsDownload session={meeting} hideRecordingAssetModal={this.hideRecordingAssetModal} />
          </ModalDialog>
        )}
        {this.state.showDeleteRecordingConfirmationModal && (
          <ModalDialog contentLabel="DeleteRecording" onClose={this.hideRecordingDeleteConfirmationModal}>
            <RecordingDelete
              deleteRecording={this.handleDeleteRecording}
              hideDeleteRecording={this.hideRecordingDeleteConfirmationModal}
            />
          </ModalDialog>
        )}
      </>
    );

    const desktopView = (
      <Scrollbars autoHide>
        <DashboardContentPane>
          <CardContainer>{containerContent}</CardContainer>
        </DashboardContentPane>
      </Scrollbars>
    );

    const mobileView =
      containerContent != null ? (
        <MobileViewOverlay>
          <OnClickOutside handleClickOutside={this.handleClickOutside} isDisabled={disableClickOutside}>
            <CardContainer>
              <PositionedCloseButton onClick={this.handleClickOutside} size={22} color={'#4c535d'} />
              {containerContent}
            </CardContainer>
          </OnClickOutside>
        </MobileViewOverlay>
      ) : null;

    return (
      <>
        {recordingModals}
        <MediaQueryFeatureSelector query={device.mobile} queryMatched={mobileView} queryNotMatched={desktopView} />
      </>
    );
  }
}
