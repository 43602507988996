import React, { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { State } from '../../view-model/types';
import { Selectors } from '@getgo/caps-redux';
import { getNextContactJidWithHistory } from '../../view-model/selectors';
import { getRouteFor } from '../../services/NavService';
import Application from '../../containers/ApplicationContainer';
import config from 'config';
import logger from '../../services/LoggerService';
import { tryRedirectToNextChatConversation } from '../../../lib/chat';

export const ChatAppRoute: React.FC = () => {
  const history = useHistory();
  const currentRoomId = useSelector((state: State) => Selectors.getCurrentRoomId(state));
  const currentContact = currentRoomId && useSelector((state: State) => Selectors.findContact(state, currentRoomId));
  const nextJid = (!currentContact && useSelector((state: State) => getNextContactJidWithHistory(state, ''))) || null;
  useEffect(() => {
    if (currentContact) {
      const path = generatePath(getRouteFor('chatConversation'), { roomId: currentRoomId });
      history.replace(path);
    } else {
      tryRedirectToNextChatConversation(nextJid, history);
    }
  }, []);
  return <Application environment={config.env} logger={logger} />;
};
