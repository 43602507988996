import config from 'config';
import { v4 } from 'uuid';
import { User } from '../view-model/modules/core/user/userReducer';
import logger from './LoggerService';

const splitLocaleInformation = (locale?: string) => {
  return {
    country_code: locale?.split('_')[1]?.toLowerCase() || 'zz',
    language_code: locale?.split('_')[0] || 'zz'
  };
};

let locale = 'en_US';
let currentPage = '';
export const initializeGoogleTagManager = (user: User) => {
  try {
    currentPage = '';
    const nonce = v4();
    const meta = document.querySelector("meta[http-equiv='Content-Security-Policy']") as HTMLMetaElement;
    if (meta) {
      const content = (meta.getAttribute('content') as string).replace('script-src ', `script-src 'nonce-${nonce}' `);
      meta.setAttribute('content', content);
    }

    locale = user.locale || 'en_US';
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const firstScriptElement = document.getElementsByTagName('script')[0] as HTMLScriptElement;
    const script = document.createElement('script') as HTMLScriptElement;
    script.async = true;
    script.src = config.googleTagManager.url.replace('${key}', config.googleTagManager.key);
    script.setAttribute('nonce', nonce);
    firstScriptElement.parentNode!.insertBefore(script, firstScriptElement);

    loginEvent();
    userInformationEvent(user.accountKey, user.key, user.licenseType || 'paid');
  } catch (e) {
    logger.info('Failed to initialize GoogleTagManager: ', e);
  }
};

const event = (name: string, data: { [key: string]: string | number | undefined }) => {
  window.dataLayer.push({
    event: name,
    ...data
  });
};

const loginEvent = () => {
  event('login', {
    platform_product: 'g2m'
  });
};

const userInformationEvent = (accountId: string, userKey: string, licenceType: 'trial' | 'paid' | 'free') => {
  event('user_information', {
    account_id: accountId,
    user_role: undefined,
    user_id: userKey,
    plan: undefined,
    user_type: licenceType
  });
};

export const startBuyFlowEvent = (accountKey: string) => {
  event('start_buy_flow', {
    platform_product: 'g2m',
    platform_environment: config.googleTagManager.env,
    page_type: 'hub',
    lmi_account_id: `g2m${accountKey}`,
    account_id: accountKey
  });
};

export const viewContentEvent = (page: string) => {
  try {
    if (page === currentPage || page === '/meetings') {
      return;
    }
    // chat url's not send because they could contain PII
    if (/^\/chats\//.test(page)) {
      return;
    }

    const localeInformation = splitLocaleInformation(locale);
    currentPage = page;
    event('view_content', {
      platform_product: 'g2m',
      platform_environment: config.googleTagManager.env,
      platform_type: 'product',
      platform_locale: `${locale}_${localeInformation.country_code}`,
      platform_country: localeInformation.country_code,
      platform_language: navigator.language,
      page_type: 'hub'
    });
  } catch (e) {
    //fail silent
  }
};
