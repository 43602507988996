import * as React from 'react';
import styled from '../../../styled-components';
import { Box } from '../../Common/Toolkit/Box/Box';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { ErrorWindowProps } from '../../../view-model/modules/meetings/meetingsReducer';
import { PrimaryButton } from '../../Common/Toolkit/Button/PrimaryButton';
import { Icons } from '../../Common/Toolkit/ICONS';
import { Icon } from '../../Common/Toolkit/Icon/Icon';
import globalMessages from '../../../../lib/messages';
import { CloseButton } from '../../Common/Toolkit/Button/CloseButton';

const StyledErrorWindow = styled.div`
  border-top: 6px solid #f68d2e;
  padding: 48px 24px 24px 24px;
  color: #000;
  position: relative;
  width: 350px;
  max-width: calc(100% - 48px);
`;

const ErrorIconBox = styled.div`
  margin-right: 20px;
`;

const ErrorTitle = styled.h2`
  margin: 0;
  padding: 0;
`;

const ButtonBar = styled.div`
  margin-top: 40px;
  text-align: right;
`;

const ErrorListLabel = styled.dt`
  font-weight: bold;
  font-family: 'lato-bold';
  margin: 0;
  padding: 0;
`;

const ErrorListDescription = styled.dd`
  margin: 0;
  padding: 0;
`;

const ShowAdvancedInfoLink = styled.a`
  color: #21a0eb;
  cursor: pointer;
`;

export const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  right: 20px;
  top: 22px;
  transform: translateY(-50%);
  height: 14px;
  cursor: pointer;
`;

const messages = defineMessages({
  gotoErrorCode: {
    id: 'errorWindow.gotoErrorCode',
    defaultMessage: 'GoToMeeting Error Code'
  },
  invalidScheduleTime: {
    id: 'errorWindow.error.invalidScheduleTime',
    defaultMessage: 'Invalid schedule time or duration'
  },
  unknown: {
    id: 'errorWindow.error.unknown',
    defaultMessage: 'Unknown error'
  },
  ok: {
    id: 'errorWindow.ok',
    defaultMessage: 'Ok'
  }
});

interface Props extends ErrorWindowProps {
  onClose: () => void;
}

interface State {
  showAdvancedInfo: boolean;
}

class ErrorWindow extends React.Component<Props & WrappedComponentProps, State> {
  state = {
    showAdvancedInfo: false
  };

  private onShowAdvancedInfoClick = () => {
    this.setState({
      showAdvancedInfo: true
    });
  };

  private formatErrorDescription = (description: string | { code: string; field: string; value: string }) => {
    if (typeof description === 'string') {
      return description;
    }

    if (description.code === 'invalid.data.type') {
      switch (description.field) {
        case 'createMeeting.webRequest.scheduledStartTime':
        case 'createMeeting.webRequest.scheduledEndTime':
          return this.props.intl.formatMessage(messages.invalidScheduleTime);
      }
    }

    return `${this.props.intl.formatMessage(messages.unknown)}: ${description.value}`;
  };

  render() {
    return (
      <StyledErrorWindow>
        <PositionedCloseButton onClick={this.props.onClose} dataTestId="error-window-close-button" size={25} />
        <Box flexDirection="row">
          <ErrorIconBox>
            <Icon color="#FE9500" icon={Icons.alert} size={30} />
          </ErrorIconBox>
          <div>
            <ErrorTitle>
              {this.props.title ? this.props.title : <FormattedMessage {...globalMessages.genericError} />}
            </ErrorTitle>
            <p>
              {this.props.message ? this.props.message : <FormattedMessage {...globalMessages.genericErrorMessage} />}
            </p>
            {!this.state.showAdvancedInfo ? (
              <ShowAdvancedInfoLink data-testid="advanced-info-link" onClick={this.onShowAdvancedInfoClick}>
                <FormattedMessage {...globalMessages.advancedInfo} />
              </ShowAdvancedInfoLink>
            ) : (
              <dl>
                {this.props.errorCode && this.props.errorDescription && (
                  <>
                    <ErrorListLabel>
                      <FormattedMessage {...messages.gotoErrorCode} />
                    </ErrorListLabel>
                    <ErrorListDescription data-testid="error-description">
                      {this.props.errorCode} - {this.formatErrorDescription(this.props.errorDescription)}
                    </ErrorListDescription>
                  </>
                )}
              </dl>
            )}
          </div>
        </Box>
        <ButtonBar>
          <PrimaryButton onClick={this.props.onClose}>
            <FormattedMessage {...messages.ok} />
          </PrimaryButton>
        </ButtonBar>
      </StyledErrorWindow>
    );
  }
}

export default injectIntl(ErrorWindow);
