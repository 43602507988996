import React, { RefObject } from 'react';
import classNames from 'classnames';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import Mousetrap from 'mousetrap';
import { Avatar } from '../../Common/Avatar/Avatar';
import { SuggestionsList } from '../../Common/SuggestionsList';
import { Body, Header, Page } from '../../Common/PageTemplate';
import { getAvatarTooltipText } from '../../../services/TooltipHelper';
import './AddGroup.css';
import { clamp } from '../../../../lib/numbers-utility';
import { GroupSuggestion, Suggestion } from '@getgo/caps-redux';
import { State as RootState } from '../../../view-model/types';
import { bindKey } from '../../../../lib/keybindings';
import { debounce } from 'lodash';
import { LockClosed, PeopleFilled } from '../../Common/Toolkit/Icon/ReactIcons';
import styled from '../../../styled-components';
import { KeyNames } from '../../../../lib/keycodes';
import { CenteredSpinner } from '../../Common/Toolkit/Spinner/Spinner';

const messages = defineMessages({
  title: {
    id: 'addGroup.title',
    defaultMessage: 'Find a group'
  },
  placeholder: {
    id: 'addGroup.placeholder',
    defaultMessage: 'Search groups'
  },
  createGroup: {
    id: 'addGroup.createGroup',
    defaultMessage: 'create this group'
  },
  buttonText: {
    id: 'addGroup.buttonText',
    defaultMessage: 'New group'
  },
  noPublicGroup: {
    id: 'addGroup.noPublicGroup',
    defaultMessage: 'No group found.'
  },
  member: {
    id: 'addGroup.member',
    defaultMessage: 'Member'
  },
  joiningText: {
    id: 'addGroup.joiningText',
    defaultMessage: 'Joining group...'
  },
  emptySearch: {
    id: 'addGroup.emptySearch',
    defaultMessage: "There is not a group matching the name of '{groupName}'. Do you want to {createGroup}?"
  }
});

const AlignedPeopleFilled = styled(PeopleFilled)`
  vertical-align: middle;
`;

const AlignedLockClosed = styled(LockClosed)`
  vertical-align: middle;
`;

interface Props {
  connectedAndReady?: boolean;
  onQuery?: (query: string, index?: number) => void;
  onCancel?: () => void;
  group: RootState['messaging']['group'];
  onAddGroup?: (jid: string, name: string, isPublic: boolean) => void;
  onShowCreateGroup?: (name: string) => void;
  maxInputLength?: number;
}

interface State {
  jid: string;
  query: string;
  highlight: number;
}

class AddGroup extends React.Component<Props & WrappedComponentProps, State> {
  private suggestionsListRef: RefObject<SuggestionsList>;

  unbindEscape: () => void = () => {};

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = {
      jid: '',
      query: '',
      highlight: -1
    };

    this.suggestionsListRef = React.createRef();
  }

  componentDidMount() {
    const { onQuery = () => {}, onCancel = () => {} } = this.props;
    Mousetrap.bind([KeyNames.ARROW_UP], this.selectUp);
    Mousetrap.bind([KeyNames.ARROW_DOWN], this.selectDown);
    Mousetrap.bind([KeyNames.ENTER], this.commitSelection);
    this.unbindEscape = bindKey(onCancel, KeyNames.ESCAPE);
    if (this.props.connectedAndReady) {
      onQuery('');
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { onQuery = () => {} } = this.props;
    if (!this.props.connectedAndReady && nextProps.connectedAndReady) {
      onQuery('');
    }
  }

  componentWillUnmount() {
    Mousetrap.unbind([KeyNames.ARROW_UP, KeyNames.ARROW_DOWN, KeyNames.ENTER]);
    this.unbindEscape();
  }

  selectUp = () => {
    if (!this.props.group.suggestions.length) {
      return;
    }

    this.setState({
      highlight: clamp(this.state.highlight - 1, 0, this.props.group.suggestions.length - 1)
    });
  };

  selectDown = () => {
    if (!this.props.group.suggestions.length) {
      return;
    }

    this.setState({
      highlight: clamp(this.state.highlight + 1, 0, this.props.group.suggestions.length - 1)
    });
  };

  commitSelection = () => {
    const selection = this.props.group.suggestions[this.state.highlight];

    if (selection) {
      this.onSuggestionSelected(selection);
    }
  };

  onQueryDebounce = debounce(
    (query: string) => {
      const { onQuery = () => {} } = this.props;
      onQuery(query);
    },
    400,
    { leading: true }
  );

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    this.setState({
      query,
      highlight: -1
    });
    this.onQueryDebounce(query.trim());

    if (this.suggestionsListRef.current) {
      this.suggestionsListRef.current.resetScrollToTop();
    }
  };

  handleAdd = (group: Suggestion) => {
    const { onAddGroup = () => {} } = this.props;
    group = group as GroupSuggestion;
    onAddGroup(group.jid, group.name, group.isPublic);
  };

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    Mousetrap.trigger(e.key);
  };

  handleShowCreateGroup = () => {
    const { onShowCreateGroup = () => {} } = this.props;
    onShowCreateGroup(this.state.query.trim());
  };

  onSuggestionSelected = (group: Suggestion) => {
    this.setState({
      jid: group.jid,
      query: group.name,
      highlight: -1
    });

    this.handleAdd(group);
  };

  onMouseOverSuggestion = (arrayIndex: number) => {
    this.setState({
      highlight: arrayIndex
    });
  };

  render() {
    const { maxInputLength = 30, onCancel = () => {} } = this.props;
    const { formatMessage } = this.props.intl;
    const groupButtonText = formatMessage(messages.buttonText);

    let emptyMessage: string | JSX.Element;
    if (this.state.query.trim().length === 0) {
      if (this.props.group.suggestions && !this.props.group.suggestions.length) {
        emptyMessage = formatMessage(messages.noPublicGroup);
      }
    } else if (this.props.group.suggestions && !this.props.group.suggestions.length) {
      emptyMessage = (
        <FormattedMessage
          {...messages.emptySearch}
          values={{
            groupName: <strong>{this.state.query}</strong>,
            createGroup: (
              <button className="btn-link" onClick={this.handleShowCreateGroup}>
                {formatMessage(messages.createGroup)}
              </button>
            )
          }}
        />
      );
    }

    const renderSuggestionTitle = (s: Suggestion) => {
      let suggestionTitle = `${s.name}`;
      if ((s as GroupSuggestion).member) {
        suggestionTitle += ` (${formatMessage(messages.member)})`;
      }
      return <div>{suggestionTitle}</div>;
    };

    const suggestionsList = () => {
      return (
        <div className="suggestions-area-wrapper">
          <SuggestionsList
            suggestions={this.props.group.suggestions}
            onSelect={this.onSuggestionSelected}
            suggestionTitle={renderSuggestionTitle}
            suggestionAvatar={(s: Suggestion, i: number) => {
              s = s as GroupSuggestion;
              return (
                <Avatar
                  icon={s.isPublic ? AlignedPeopleFilled : AlignedLockClosed}
                  transparent={s.hasExternalMembers}
                  color={
                    (s.hasExternalMembers && i === this.state.highlight) || !s.hasExternalMembers ? '#fff' : '#444444'
                  }
                  tooltip={getAvatarTooltipText(formatMessage, s.isPublic, s.hasExternalMembers)}
                />
              );
            }}
            emptyMessage={emptyMessage}
            ref={this.suggestionsListRef}
            showInviteWithEmail={false}
            highlightIndex={this.state.highlight}
            onMouseOverSuggestion={this.onMouseOverSuggestion}
          />
        </div>
      );
    };

    const addGroupHeader = (
      <div className="add-group-header">
        <h5>
          <FormattedMessage {...messages.title} />
        </h5>
        <button
          type="button"
          className={classNames('btn btn-primary create-group-button', { disabled: this.props.group.isJoining })}
          onClick={this.handleShowCreateGroup}
        >
          {groupButtonText}
        </button>
      </div>
    );

    const inputProps = {
      placeholder: formatMessage(messages.placeholder),
      disabled: this.props.group.isJoining,
      className: classNames({ disabled: this.props.group.isJoining }),
      value: this.state.query,
      onChange: this.handleChange,
      onKeyDown: this.handleKeyDown
    };

    const showSpinner =
      (this.props.group.isFetching && this.props.group.suggestions.length === 0) || !this.props.connectedAndReady;
    const showJoining = this.props.group.isJoining;
    const showSuggestions = !showJoining && !showSpinner;

    const addGroupContent = (
      <div>
        <div>
          <input autoFocus {...inputProps} maxLength={maxInputLength} />
        </div>
        {showSpinner && <CenteredSpinner />}
        {showJoining && (
          <span className="joining-text">
            <FormattedMessage {...messages.joiningText} />
          </span>
        )}
        {showSuggestions && suggestionsList()}
      </div>
    );

    return (
      <Page className="add-group">
        <Header enableCancel={true} onCancel={onCancel}>
          {addGroupHeader}
        </Header>
        <Body>{addGroupContent}</Body>
      </Page>
    );
  }
}

export default injectIntl(AddGroup, { forwardRef: true });
