import Mousetrap from 'mousetrap';
import { KeyNames } from './keycodes';

const subscribers: Map<string, Array<() => void>> = new Map();

/**
 * Binds a key to callback functions, allows for multiple callbacks and calls all of them upon keypress.
 * Calling the returned function will unbind the callback.
 * @param onKeypress
 * @param key
 */
export const bindKey = (onKeypress: () => void, key: KeyNames) => {
  let callbacks = subscribers.get(key);
  if (!callbacks) {
    callbacks = [];
    subscribers.set(key, callbacks);
  }

  if (callbacks.includes(onKeypress)) {
    throw new Error('Callback already added on key');
  }

  callbacks.push(onKeypress);
  if (callbacks.length === 1) {
    Mousetrap.bind([key], () => {
      callbacks = subscribers.get(key) || [];
      callbacks.forEach((cb: () => void) => cb());
    });
  }

  return () => {
    callbacks = subscribers.get(key) || [];
    subscribers.set(
      key,
      callbacks.filter((cb: () => void) => cb !== onKeypress)
    );
    if (subscribers.get(key)!.length === 0) {
      Mousetrap.unbind([key]);
    }
  };
};
