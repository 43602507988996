import { InterstitialModal } from '../../../components/Meetings/MeetingScheduler/Interstitial/InterstitialModal';
import { connect } from 'react-redux';
import { State, Dispatch } from '../../../view-model/types';
import schedulerActions, { interstitialCopyInvitation } from '../../../view-model/modules/scheduler/schedulerActions';
import { SchedulerFullMeeting } from '../../../view-model/modules/scheduler/schedulerReducer';
import { getRooms } from '../../../view-model/selectors';
import { Meeting, Profile } from '../../../view-model/modules/meetings/MeetingsTypes';

const mapStateToProps = (state: State) => {
  return {
    rooms: getRooms(state),
    profile: state.meetings.profile,
    isFetchingInvitation: state.scheduler ? state.scheduler.interstitial.isFetchingInvitation : false
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    hideWindow(showScheduler = false, hideAlways = false) {
      dispatch(schedulerActions.schedulerHideInterstitial(showScheduler, hideAlways));
    },

    copyInvitation(meeting: Meeting, profile?: Profile, dontShowAgain?: boolean) {
      dispatch(interstitialCopyInvitation(meeting, profile, dontShowAgain || false));
    },

    onPropertyChange(property: keyof SchedulerFullMeeting, value: any) {
      dispatch(schedulerActions.schedulerChangeProperty(property, value));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InterstitialModal);
