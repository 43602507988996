import React from 'react';
import { Info } from '../Toolkit/Icon/ReactIcons';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { PreferencesBadge } from './PreferencesBadge/PreferencesBadge';
import { isHoverSupported } from '../../../services/ClientService';
import { useIntl } from 'react-intl';
import { Checkbox, Radio } from '../Toolkit/Input/Selectable';
import { Note, Description, ErrorMessage, Block } from '../Toolkit/FormElements/TextElements';
import ReactTooltip from 'react-tooltip';
import styled from '../../../styled-components';

const Content = styled.div`
  margin-left: 24px;
`;

interface Props {
  id: string;
  type: 'checkbox' | 'radio';
  badge?: 'new' | 'beta';
  checked: boolean;
  onChange: (value: boolean) => void;
  disabled: boolean;
  children?: React.ReactNode;
  labelMessage: MessageDescriptor;
  labelMessageValues?: { [key: string]: string };
  errorMessage?: MessageDescriptor;
  descriptionMessage?: MessageDescriptor;
  noteMessage?: MessageDescriptor;
  description?: React.ReactNode;
  disabledTooltip?: MessageDescriptor;
  enabledTooltip?: MessageDescriptor;
  infoMessage?: MessageDescriptor;
  className?: string;
}

const InfoIcon = styled(Info)`
  font-size: 21px;
  margin: -6px 0 -4px 2px;
  display: inline-block;

  path {
    fill: #485164;
  }
`;

const TooltipInner = styled.div`
  max-width: 300px;
`;

export const PreferencesItemBool: React.FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(evt.target.checked);
  };

  const {
    id,
    type,
    labelMessage,
    labelMessageValues,
    descriptionMessage,
    description,
    infoMessage,
    noteMessage,
    checked,
    disabled,
    badge,
    disabledTooltip,
    errorMessage,
    children,
    className
  } = props;

  const Element = type === 'checkbox' ? Checkbox : Radio;

  return (
    <Block className={className}>
      <Element
        id={id}
        labelProps={{
          'data-tip': isHoverSupported && disabledTooltip ? f(disabledTooltip) : null,
          'data-delay': '500',
          'data-place': 'right'
        }}
        disabled={disabled}
        checked={checked}
        onChange={handleChange}
      >
        <FormattedMessage {...labelMessage} values={labelMessageValues} />
        {badge && <PreferencesBadge badge="new" marginleft="8px" />}
        {errorMessage && (
          <ErrorMessage data-testid={`${id}-error`}>
            <FormattedMessage {...errorMessage} />
          </ErrorMessage>
        )}
        {infoMessage && (
          <>
            <span data-tip data-for={`${id}-info-tooltip`}>
              <InfoIcon />
            </span>
            <ReactTooltip id={`${id}-info-tooltip`} place="right" effect="solid">
              <TooltipInner data-testid={`${id}-info-tooltip-inner`}>
                <FormattedMessage {...infoMessage} />
              </TooltipInner>
            </ReactTooltip>
          </>
        )}

        {descriptionMessage && (
          <Description>
            <FormattedMessage {...descriptionMessage} />
          </Description>
        )}
        {description && <Description>{description}</Description>}

        {noteMessage && (
          <Note>
            <FormattedMessage {...noteMessage} />
          </Note>
        )}
      </Element>
      <Content>{children}</Content>
    </Block>
  );
};
