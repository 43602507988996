export default function createEmojisFromStrategy(strategy: any) {
  const emojis: any = {};

  // categorise and nest emoji
  // sort ensures that modifiers appear unmodified keys
  const keys = Object.keys(strategy);
  for (const key of keys) {
    const value = strategy[key];
    const emojiArray = [value];

    // skip unknown categories
    if (value.category !== 'modifier' && !value.diversity) {
      if (!emojis[value.category]) {
        emojis[value.category] = {};
      }

      if (value.diversities?.length > 0) {
        value.diversities.forEach((toneKey: string) => {
          emojiArray.push(strategy[toneKey]);
        });

        emojis[value.category][value.shortname] = emojiArray;
      } else {
        // ensure the shortname is included as a keyword
        if (!value.keywords.includes(value.shortname)) {
          emojiArray[0].keywords.push(value.shortname);
        }

        emojis[value.category][value.shortname] = [value];
      }
    }
  }

  return emojis;
}
