import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { SpinnerInline } from '../../../Common/SpinnerInline/SpinnerInline';
import './LoadMoreEntry.css';

const localized = defineMessages({
  loadMore: {
    id: 'chat.messageHistory.loadMoreEntry.loadMore',
    defaultMessage: 'Load older messages'
  }
});

interface Props {
  isPartialLoading: boolean;
  onLoadMore: () => void;
}

export const LoadMoreEntry: React.FC<Props> = (props) => {
  let content;

  if (props.isPartialLoading) {
    content = <SpinnerInline />;
  } else {
    content = (
      <button className="btn btn-link" onClick={props.onLoadMore}>
        <FormattedMessage {...localized.loadMore} />
      </button>
    );
  }

  return (
    <div key="load-more" className="load-more-entry">
      {content}
    </div>
  );
};
