import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Props, SchedulerTabs } from '../MeetingScheduler';
import { Checkbox, ElementDescription, ElementTitle } from '../../../Common/Toolkit/Input/Selectable';
import styled from '../../../../styled-components';
import { MeetingLinkInput } from '../MeetingLinkInput';
import ThemeSelector from '../ThemeSelector';
import { DisableStateBox } from '../../../Common/Toolkit/Box/DisableStateBox';
import { NameError, validateRoomName } from '../../../../services/MeetingService';
import { BrandTheme, getRoomNameSuggestion } from '../../../../../models/Meeting';
import config from 'config';
import schedulerMessages from '../Messages';
import { ensureSubject } from '../../../../../models/UpdateMeeting';

const IndentBox = styled.div`
  padding-left: 24px;
`;

const PersonalizeCheckbox = styled(Checkbox)`
  margin-bottom: 8px;
`;

const PersonalizeTabContainer = styled.div`
  padding: 24px 16px 8px 4px;
`;

const messages = defineMessages({
  meetingLink: {
    id: 'meetingDashboard.meetingScheduler.meetingLink',
    defaultMessage: 'Meeting link'
  },
  personalizeMeeting: {
    id: 'meetingDashboard.meetingScheduler.personalizeMeeting',
    defaultMessage: 'Personalize your meeting'
  },
  personalizeMeetingDescription: {
    id: 'meetingDashboard.meetingScheduler.personalizeMeetingDescription',
    defaultMessage: 'Create a memorable link and a personalized start page for your meeting.'
  },
  meetingUrlAlreadyExists: {
    id: 'meetingDashboard.meetingScheduler.meetingUrlAlreadyExists',
    defaultMessage: "You're already using this link for another meeting."
  }
});

interface PersonalizeTabProps extends Props {
  themes: BrandTheme[];
}

export const PersonalizeTab = (props: PersonalizeTabProps) => {
  const intl = useIntl();
  const [fetchingRoomName, setFetchingRoomName] = useState(false);

  useEffect(() => {
    (async () => {
      if (
        !props.meeting.initial.roomName &&
        (!props.meeting.update.roomName || !props.meeting.update.personalizeMeeting) &&
        props.profile &&
        props.profile.profileId
      ) {
        setFetchingRoomName(true);
        const roomName = await getRoomNameSuggestion(props.profile.profileId, props.meeting.update.subject);
        props.onPropertyChange('roomName', roomName);
        setFetchingRoomName(false);
      }
    })();
  }, []);

  const validateInput = async (value: string) => {
    if (!props.profile) {
      return { message: <FormattedMessage {...schedulerMessages.missingProfile} /> };
    }

    if (!props.user.hasLoaded) {
      return { message: <FormattedMessage {...schedulerMessages.serverError} /> };
    }

    const result = await validateRoomName(props.user.key, value);
    if (result !== true) {
      if (result === NameError.alreadyExists) {
        return {
          message: <FormattedMessage {...messages.meetingUrlAlreadyExists} />
        };
      }
      return {
        message: <FormattedMessage {...schedulerMessages[result]} />
      };
    }
    return true;
  };

  const onRoomNameError = () => {
    if (
      props.meeting.update.personalizeMeeting &&
      (!props.propertyState.roomName || props.propertyState.roomName.state !== 'error')
    ) {
      props.onValidationError('roomName', SchedulerTabs.personalize);
    }
  };

  const onRoomNameSuccess = () => {
    props.onValidationSuccess('roomName', SchedulerTabs.personalize);
  };

  const onChangePersonalizeMeeting = (checked: boolean) => {
    props.onPropertyChange('personalizeMeeting', checked);
    if (!checked) {
      props.onValidationSuccess('roomName', SchedulerTabs.personalize);
    }
  };

  const onShowMeetingPreview = (theme: string) => {
    const meetingSubject = ensureSubject(props.meeting.update.subject, intl);
    props.showProfilePreview(theme, meetingSubject);
  };

  return (
    <PersonalizeTabContainer>
      <PersonalizeCheckbox
        checked={props.meeting.update.personalizeMeeting && !!props.profile}
        id="personalize-meeting-checkbox"
        disabled={!props.profile}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangePersonalizeMeeting(e.target.checked)}
      >
        <ElementTitle>
          <FormattedMessage {...messages.personalizeMeeting} />
        </ElementTitle>
        <ElementDescription>
          <FormattedMessage {...messages.personalizeMeetingDescription} />
        </ElementDescription>
      </PersonalizeCheckbox>
      <DisableStateBox disabled={!props.meeting.update.personalizeMeeting}>
        <IndentBox>
          <MeetingLinkInput
            label={<FormattedMessage {...messages.meetingLink} />}
            prefix={`${config.externalLinks.g2mm}/${(props.profile && props.profile.profileId) || ''}`}
            onChange={(value) => props.onPropertyChange('roomName', value)}
            isLoading={fetchingRoomName}
            disabled={fetchingRoomName || !props.meeting.update.personalizeMeeting || !props.profile}
            value={props.meeting.update.roomName || ''}
            initial={props.meeting.initial.roomName || ''}
            validateInput={validateInput}
            onValidationError={onRoomNameError}
            onValidationSuccess={onRoomNameSuccess}
            sanitize={true}
          />

          <ThemeSelector
            disabled={!props.profile}
            onChange={(theme) => props.onPropertyChange('theme', theme)}
            theme={props.meeting.update.theme || 'default'}
            themes={props.themes}
            showProfilePreview={onShowMeetingPreview}
            useNewSelector={props.brandingEnabled}
          />
        </IndentBox>
      </DisableStateBox>
    </PersonalizeTabContainer>
  );
};
