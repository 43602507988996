import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../view-model/types';
import { RosterActions, Selectors } from '@getgo/caps-redux';
import { getNextContactJidWithHistory } from '../../view-model/selectors';
import Events from '../../view-model/EventConstants';
import { getRouteFor } from '../../services/NavService';
import Application from '../../containers/ApplicationContainer';
import config from 'config';
import logger from '../../services/LoggerService';
import { tryRedirectToNextChatConversation } from '../../../lib/chat';

export const ChatConversationRoute: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { roomId } = useParams<{ roomId: string }>();
  const currentContact = useSelector((state: State) => Selectors.findContact(state, roomId));
  const nextJid = roomId && useSelector((state: State) => getNextContactJidWithHistory(state, roomId));

  useEffect(() => {
    if (currentContact) {
      dispatch(RosterActions.selectContact(roomId));
      dispatch({ type: Events.CONTACT_SELECTED, data: { roomId } });
      dispatch(RosterActions.joinRoom(roomId));
    } else {
      const redirected = tryRedirectToNextChatConversation(nextJid, history);
      if (!redirected) {
        history.replace(getRouteFor('chat'));
      }
    }
  }, [roomId]);
  return <Application environment={config.env} logger={logger} />;
};
