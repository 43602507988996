import { GroupContact } from '@getgo/caps-redux';
import React from 'react';
import { keyBy } from 'lodash';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { getSuggestionTitle } from '../../../../lib/person';
import MultiContactSelector from '../../Common/MultiContactSelector/MultiContactSelector';
import { Actions, Body, Header, Page } from '../../Common/PageTemplate';
import { Contact, CoreContact } from '../../../../types/pulse-web';
import { State as RootState } from '../../../view-model/types';
import { bindKey } from '../../../../lib/keybindings';
import styled from '../../../styled-components';
import { KeyNames } from '../../../../lib/keycodes';

const MemberLabel = styled.span`
  text-transform: uppercase;
  float: right;
  color: #8997ad;
  font-size: 12px;
`;

interface State {
  selectedContacts: Contact[];
  membersMap: any;
}

const ButtonActions = styled(Actions)`
  padding-top: 20px;
`;

const messages = defineMessages({
  title: {
    id: 'addMembers.title',
    defaultMessage: 'Add members to group'
  },
  cancelButtonText: {
    id: 'addMembers.cancelButtonText',
    defaultMessage: 'Cancel'
  },
  doneButtonText: {
    id: 'addMembers.doneButtonText',
    defaultMessage: 'Done'
  },
  memberLabel: {
    id: 'addMembers.memberLabel',
    defaultMessage: 'Member'
  }
});

interface Props {
  onAddMembers: (groupJid: string, members: Contact[]) => void;
  onCancel: () => void;
  clearQuery: () => void;
  onQuery: (query?: string, nextPageKey?: string) => void;
  enableCancel?: boolean;
  contact: GroupContact;
  people: RootState['messaging']['people'];
}

class AddMembers extends React.Component<Props & WrappedComponentProps, State> {
  unbindEscape: () => void = () => {};

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.state = {
      selectedContacts: [],
      membersMap: keyBy(this.props.contact.members || [], 'jid')
    };
  }

  componentDidMount() {
    this.unbindEscape = bindKey(this.props.onCancel as () => void, KeyNames.ESCAPE);
  }

  componentWillUnmount() {
    const { clearQuery = () => {} } = this.props;
    clearQuery();
    this.unbindEscape();
  }

  onOk = () => {
    this.props.onAddMembers(this.props.contact.jid, this.state.selectedContacts);
  };

  handleSelectedContactChange = (selectedContacts: Contact[]) => {
    this.setState({ selectedContacts });
  };

  render() {
    const { enableCancel = true } = this.props;

    const renderSuggestion = (contact: CoreContact) => {
      const name = getSuggestionTitle(contact);

      return (
        <span className="suggestion">
          <span className="member-name">{name}</span>
          {this.state.membersMap[contact.jid] ? (
            <MemberLabel>
              <FormattedMessage {...messages.memberLabel} />
            </MemberLabel>
          ) : null}
        </span>
      );
    };

    return (
      <Page className="add-members">
        <Header enableCancel={enableCancel} onCancel={this.props.onCancel}>
          <FormattedMessage {...messages.title} /> <strong>{this.props.contact.name}</strong>
        </Header>

        <Body>
          <MultiContactSelector
            autoFocus
            members={this.props.contact.members}
            onQuery={this.props.onQuery}
            onSelectedContactsChange={this.handleSelectedContactChange}
            people={this.props.people}
            renderSuggestion={renderSuggestion}
            onCancel={this.props.onCancel}
          />

          <ButtonActions>
            <button className="btn btn-secondary" onClick={this.props.onCancel}>
              <FormattedMessage {...messages.cancelButtonText} />
            </button>
            <button className="btn btn-primary" onClick={this.onOk} disabled={!this.state.selectedContacts.length}>
              <FormattedMessage {...messages.doneButtonText} />
            </button>
          </ButtonActions>
        </Body>
      </Page>
    );
  }
}

export default injectIntl(AddMembers, { forwardRef: true });
