import { ActionsUnion, Thunk, UnwrapPromise, createAction } from '../../../type-helpers';
import logger from '../../../../services/LoggerService';
import {
  AsyncSettingGroupName,
  AsyncSettingGroupValues,
  SettingGroupName,
  SettingGroupValues,
  SettingName,
  SettingValue,
  SyncSettingGroupName,
  getChangedSettings,
  hasPreferencesChanges,
  UX2019Settings,
  GoToAppEapSettings
} from './preferencesReducer';
import { getMember, setHighContrast } from '../../../../../models/User';
import {
  fetchG2mSettings,
  fetchLogoSetting,
  removeLogoSetting,
  updateG2MSettings,
  updateLogoSetting,
  validateAndParseMeetingSettings
} from '../../../../../models/Meeting';
import { getPreferences, getRemainingTrialDays } from '../../../selectors';
import { fetchSettingsError, updateSettingsError } from '../errors/errorActions';
import { initializePendo } from '../../../../../lib/pendo';
export enum ActionTypes {
  CHANGE_SETTING = 'CHANGE_SETTING',
  ROLLBACK_SETTING_GROUP = 'ROLLBACK_SETTING_GROUP',
  SAVE_SETTING_GROUP = 'SAVE_SETTING_GROUP',

  INITIAL_SETTINGS_FETCHED = 'INITIAL_SETTINGS_FETCHED',
  INITIAL_SETTINGS_FAILED = 'INITIAL_SETTINGS_FAILED',

  SETTING_GROUP_FETCH_STARTED = 'SETTING_GROUP_FETCH_STARTED',
  SETTING_GROUP_FETCH_SUCCEEDED = 'SETTING_GROUP_FETCH_SUCCEEDED',
  SETTING_GROUP_FETCH_FAILED = 'SETTING_GROUP_FETCH_FAILED',

  SETTING_GROUP_UPDATE_STARTED = 'SETTING_GROUP_UPDATE_STARTED',
  SETTING_GROUP_UPDATE_SUCCEEDED = 'SETTING_GROUP_UPDATE_SUCCEEDED',
  SETTING_GROUP_UPDATE_FAILED = 'SETTING_GROUP_UPDATE_FAILED',

  CHANGE_SETTING_UX2019_ENABLED = 'CHANGE_SETTING_UX2019_ENABLED',
  CUSTOM_BACKGROUND_PREFERENCES_ACCESSED = 'CUSTOM_BACKGROUND_PREFERENCES_ACCESSED',

  CHANGE_SETTING_GOTO_APP_ENABLED = 'CHANGE_SETTING_GOTO_APP_ENABLED',
  GOTO_APP_EAP_PREFERENCES_ACCESSED = 'GOTO_APP_EAP_PREFERENCES_ACCESSED'
}

const actions = {
  changeSetting: <G extends SettingGroupName, S extends SettingName<G>>(
    group: G,
    setting: S,
    value: SettingValue<G, S>
  ) => createAction(ActionTypes.CHANGE_SETTING, { group, setting, value }),
  rollbackSettingGroup: (group: SettingGroupName) => createAction(ActionTypes.ROLLBACK_SETTING_GROUP, group),
  saveSettingGroup: (group: SyncSettingGroupName) => createAction(ActionTypes.SAVE_SETTING_GROUP, group),

  initialSettingsFetched: (settings: { [group in SettingGroupName]?: SettingGroupValues<group> }) =>
    createAction(ActionTypes.INITIAL_SETTINGS_FETCHED, settings),
  initialSettingsFailed: (error?: any) => createAction(ActionTypes.INITIAL_SETTINGS_FAILED, error),

  settingGroupFetchStarted: (group: AsyncSettingGroupName) =>
    createAction(ActionTypes.SETTING_GROUP_FETCH_STARTED, group),
  settingGroupFetchSucceeded: <G extends AsyncSettingGroupName>(
    group: G,
    values: AsyncSettingGroupValues<G>,
    resetUpdates: boolean
  ) => createAction(ActionTypes.SETTING_GROUP_FETCH_SUCCEEDED, { group, values, resetUpdates }),
  settingGroupFetchFailed: (group: AsyncSettingGroupName) =>
    createAction(ActionTypes.SETTING_GROUP_FETCH_FAILED, group),

  settingGroupUpdateStarted: (group: AsyncSettingGroupName) =>
    createAction(ActionTypes.SETTING_GROUP_UPDATE_STARTED, group),
  settingGroupUpdateSucceeded: <G extends AsyncSettingGroupName>(
    group: G,
    values: Partial<AsyncSettingGroupValues<G>>
  ) => createAction(ActionTypes.SETTING_GROUP_UPDATE_SUCCEEDED, { group, values }),
  settingGroupUpdateFailed: (group: AsyncSettingGroupName) =>
    createAction(ActionTypes.SETTING_GROUP_UPDATE_FAILED, group),
  changeSettingUX2019Enabled: (value: boolean) => createAction(ActionTypes.CHANGE_SETTING_UX2019_ENABLED, value),
  customBackgroundPreferencesAccessed: () => createAction(ActionTypes.CUSTOM_BACKGROUND_PREFERENCES_ACCESSED),

  settingGotoAppEnabledChanged: (value: boolean) => createAction(ActionTypes.CHANGE_SETTING_GOTO_APP_ENABLED, value),
  gotoAppEapPreferencesAccessed: () => createAction(ActionTypes.GOTO_APP_EAP_PREFERENCES_ACCESSED)
};

export default actions;

export type Actions = ActionsUnion<typeof actions>;

export const fetchInitialSettings = (): Thunk => {
  // theme settings are already included in initial me call
  return async (dispatch, getState) => {
    // fetch meetings
    let settings: UnwrapPromise<ReturnType<typeof fetchG2mSettings>>;
    try {
      settings = await fetchG2mSettings();
      const state = getState();

      const meetingsInitialPreferences = validateAndParseMeetingSettings(settings);
      const ux2019SettingsInitialPreferences = validateAndParseUX2019Settings(settings);
      const gotoAppEapSettingsInitialPreferences = validateAndParseGotoAppEapSettings(settings);

      if (state.core.user.hasLoaded) {
        const ipdUserBaseData: IpdUserBaseData = {
          hub_locale: state.core.user.locale || 'unavailable',
          hub_service_type: state.core.user.licenseType || 'unavailable',
          hub_trial_end_date: state.core.user.trialInformation?.endDate || 'unavailable',
          hub_trial_days_left: getRemainingTrialDays(state),
          hub_ux2019_controllable_by_user: ux2019SettingsInitialPreferences.ux2019ControllableByUser,
          hub_ux2019_enabled: ux2019SettingsInitialPreferences.ux2019Enabled,
          hub_meeting_hub_controllable_by_user: ux2019SettingsInitialPreferences.meetingHubControllableByUser,
          hub_cbr_enabled: meetingsInitialPreferences.cbrEnabled,
          hub_transcripts_enabled: meetingsInitialPreferences.transcriptsEnabled,
          hub_video_insights_enabled: meetingsInitialPreferences.videoInsightsEnabled,
          hub_is_standalone_audio_enabled: meetingsInitialPreferences.isStandaloneAudioEnabled,
          hub_chroma_cam_enabled: meetingsInitialPreferences.chromaCamEnabled,
          hub_chroma_cam_provisioned: meetingsInitialPreferences.chromaCamProvisioned,
          hub_breakoutrooms_allowed: meetingsInitialPreferences.breakoutsAllowed,
          hub_eap_goto_app_allowed:
            gotoAppEapSettingsInitialPreferences.gotoAppProvisioned &&
            gotoAppEapSettingsInitialPreferences.gotoAppEntitled,
          hub_eap_goto_app_enabled: gotoAppEapSettingsInitialPreferences.gotoAppEnabled
        };

        initializePendo({
          visitor: {
            id: state.core.user.key,
            ...ipdUserBaseData
          },
          account: {
            id: state.core.user.accountKey
          }
        });
      }

      dispatch(
        actions.initialSettingsFetched({
          meetings: {
            ...meetingsInitialPreferences,
            logo: undefined
          },
          ux2019Settings: ux2019SettingsInitialPreferences,
          gotoAppEapSettings: gotoAppEapSettingsInitialPreferences
        })
      );
    } catch (err) {
      logger.error('fetchG2mSettings error', err);
      dispatch(actions.initialSettingsFailed(err));
      return;
    }
  };
};

const fetchSettingGroupMap: { [group in AsyncSettingGroupName]: () => Promise<AsyncSettingGroupValues<group>> } = {
  theme: async () => {
    const me = await getMember();
    if (typeof me.accessibility !== 'boolean') {
      throw new Error('ejabberdMe.accessibility is not a boolean');
    }

    return { useHighContrast: me.accessibility };
  },
  meetings: async () => {
    const [settings, logoDataUrl] = (await Promise.all([fetchG2mSettings(), fetchLogoSetting()])) as [
      UnwrapPromise<ReturnType<typeof fetchG2mSettings>>,
      UnwrapPromise<ReturnType<typeof fetchLogoSetting>>
    ];

    return {
      ...validateAndParseMeetingSettings(settings),
      logo:
        logoDataUrl == null
          ? null
          : {
              dataUrl: logoDataUrl,
              allowDeletion: settings.allowLogoDeletion != null ? settings.allowLogoDeletion : true
            }
    };
  },
  ux2019Settings: async () => {
    const settings = await fetchG2mSettings();
    return validateAndParseUX2019Settings(settings);
  },
  gotoAppEapSettings: async () => {
    const settings = await fetchG2mSettings();
    return validateAndParseGotoAppEapSettings(settings);
  }
};

export const validateAndParseUX2019Settings = (
  settings: UnwrapPromise<ReturnType<typeof fetchG2mSettings>>
): UX2019Settings => {
  return {
    ux2019ControllableByUser: settings.ux2019ControllableByUser || false,
    ux2019Enabled: settings.ux2019Enabled || false,
    meetingHubEnabled: true,
    meetingHubControllableByUser: settings.meetingHubControllableByUser ?? true //defaults to true if flag is undefined, so users can still opt-out
  };
};

export const validateAndParseGotoAppEapSettings = (
  settings: UnwrapPromise<ReturnType<typeof fetchG2mSettings>>
): GoToAppEapSettings => {
  return {
    gotoAppProvisioned: settings.gotoAppProvisioned || false,
    gotoAppEntitled: settings.gotoAppEntitled || false,
    gotoAppEnabled: settings.gotoAppEnabled || false
  };
};

const updateSettingGroupMap: {
  [group in AsyncSettingGroupName]: (
    values: Partial<AsyncSettingGroupValues<group>>,
    allUpdates: Partial<AsyncSettingGroupValues<group>>
  ) => Promise<void>;
} = {
  theme: (values) => (values.useHighContrast != null ? setHighContrast(values.useHighContrast) : Promise.resolve()),
  meetings: async (values, allUpdates) => {
    const { logo, ...settingsWithoutLogo } = values;
    const promises = []; // for updating in parallel

    if (logo === null) {
      promises.push(removeLogoSetting());
    } else if (typeof logo !== 'undefined') {
      promises.push(updateLogoSetting(logo.dataUrl));
    }

    if (Object.keys(settingsWithoutLogo).length >= 1) {
      promises.push(updateG2MSettings(allUpdates));
    }

    await Promise.all(promises);
  },
  ux2019Settings: (values) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { meetingHubEnabled, ...settingsWithoutMeetingHubEnabled } = values;

    if (Object.keys(settingsWithoutMeetingHubEnabled).length === 0) {
      return Promise.resolve();
    }

    return updateG2MSettings(settingsWithoutMeetingHubEnabled);
  },
  gotoAppEapSettings: (values) => {
    const { gotoAppEnabled } = values;
    return updateG2MSettings({ gotoAppEnabled });
  }
};

export const fetchSettingGroup = (group: AsyncSettingGroupName, resetUpdates = false): Thunk => async (
  dispatch,
  getState
) => {
  if (getPreferences(getState())[group].isFetching) {
    return;
  }

  dispatch(actions.settingGroupFetchStarted(group));

  let values: AsyncSettingGroupValues<typeof group>;
  try {
    values = await fetchSettingGroupMap[group]();
  } catch (err) {
    logger.error('fetchSettingGroup', 'group=', group, 'err=', err);
    dispatch(actions.settingGroupFetchFailed(group));
    dispatch(fetchSettingsError());
    return;
  }

  dispatch(actions.settingGroupFetchSucceeded(group, values, resetUpdates));
};

export const saveSettingGroup = (group: AsyncSettingGroupName): Thunk => async (dispatch, getState) => {
  if (getPreferences(getState())[group].isUpdating) {
    logger.error('saveSettingGroup', 'concurrent update');
    return;
  }

  if (!hasPreferencesChanges(getState(), group)) {
    return;
  }

  const values = getChangedSettings(getState(), group);

  dispatch(actions.settingGroupUpdateStarted(group));
  try {
    await (updateSettingGroupMap[group] as any)(values, getPreferences(getState())[group].update);
  } catch (err) {
    logger.error('saveSettingGroup', 'err=', err);
    dispatch(actions.settingGroupUpdateFailed(group));
    dispatch(updateSettingsError());
    return;
  }

  dispatch(actions.settingGroupUpdateSucceeded(group, values));
};
