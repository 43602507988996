import React, { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../view-model/types';
import { MeetingsTabSpinner } from '../MeetingComponents';
import {
  fetchMeetingHistory,
  selectMeeting,
  triggerTelemetryElasticSearch
} from '../../../view-model/modules/meetings/meetingsActions';
import { getIsOnline } from '../../../view-model/selectors';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled from '../../../styled-components';
import { Button } from '../../Common/Toolkit/Button/Button';
import { EmptyStatePlaceholder } from '../../Common/Toolkit/Placeholder/EmptyStatePlaceholder';
import ConnectCalendar from '../../../../assets/images/main.connect-calendar.png';
import ConnectCalendar2x from '../../../../assets/images/main.connect-calendar@2x.png';
import ConnectCalendar3x from '../../../../assets/images/main.connect-calendar@3x.png';
import { getEndOfDay, getStartOfDay, isEqualDate } from '../../../../lib/date';
import { MeetingsDateSeparator } from '../../Common/Toolkit/MeetingsDateSeparator/MeetingsDateSeparator';
import { SelectableCard } from '../../Common/Toolkit/SelectableCard/SelectableCard';
import { HistoryMeetingItem } from './HistoryMeetingItem';
import { CardList } from '../../Common/Dashboard/CardList';
import DatePicker from '../../Common/DateTime/DatePicker';
import { themeGet } from '@styled-system/theme-get';
import { Checkbox } from '../../Common/Toolkit/Input/Selectable';
import { Icon } from '../../Common/Toolkit/Icon/Icon';
import { Icons } from '../../Common/Toolkit/ICONS';
import device from '../../Common/Toolkit/devices';
import GlobalSearchInput from '../../GlobalSearch/GlobalSearchInput';
import { SelectableItemType, Session } from '../../../view-model/modules/meetings/MeetingsTypes';

const messages = defineMessages({
  recordedLabel: {
    id: 'meetingHub.historyMeetings.recordedLabel',
    defaultMessage: 'Recorded'
  },
  emptyLabel: {
    id: 'meetingHub.historyMeetings.emptyLabel',
    defaultMessage: "There weren't any meetings between those dates."
  },
  loadMoreButtonLabel: {
    id: 'meetingHub.historyMeetings.loadMoreButtonLabel',
    defaultMessage: 'load more'
  }
});

const FilterContainer = styled.div`
  display: flex;
  padding: 8px 16px 0px 16px;
`;

const RecordedCheckbox = styled(Checkbox)`
  label {
    font-size: 14px !important;
  }
`;

const RecordedFilter = styled(
  (props: { className?: string; checked: boolean; onFilterRecordedToggle: (checked: boolean) => void }) => (
    <RecordedCheckbox
      id="recorded-checkbox"
      className={props.className}
      data-testid="recorded-checkbox"
      type="checkbox"
      checked={props.checked}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onFilterRecordedToggle(e.target.checked)}
    >
      <FormattedMessage {...messages.recordedLabel} />
    </RecordedCheckbox>
  )
)`
  color: #25282d;
  font-family: Lato-regular;
  font-size: 16px;
  cursor: pointer;
  margin-left: auto;
  display: flex;
  align-items: center;

  input {
    cursor: inherit;
    margin: 0 3px 0 0;
  }
`;

const LoadMoreButton = styled((props: { className?: string; onClick: () => void; isLoading: boolean }) => (
  <div className={props.className}>
    {props.isLoading ? (
      <MeetingsTabSpinner />
    ) : (
      <Button onClick={props.onClick} data-testid="load-more-sessions-button">
        <FormattedMessage {...messages.loadMoreButtonLabel} />
      </Button>
    )}
  </div>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;

  button {
    padding: 12px 24px;
  }
`;

export const shouldIncludeDaySeparator = (currentMeeting: Session, previousMeeting?: Session) => {
  if (previousMeeting === undefined) {
    return true;
  }

  const previousMeetingDate = previousMeeting.startTime ? previousMeeting.startTime : new Date();
  const currentMeetingDate = currentMeeting.startTime ? currentMeeting.startTime : new Date();

  const previousMeetingStartOfDay = getStartOfDay(previousMeetingDate);
  const currentMeetingStartOfDay = getStartOfDay(currentMeetingDate);

  return !isEqualDate(previousMeetingStartOfDay, currentMeetingStartOfDay);
};

const SmallButton = styled(Button)`
  color: ${themeGet('colors.neutralScale.500')};
  height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  border: 1px solid transparent;
  &:hover {
    background-color: rgba(128, 181, 255, 0.12);
  }
  &:hover &:focus {
    border-radius: 4px;
    border: 1px solid rgb(179, 191, 207);
    outline: none;
  }
  &:disabled {
    color: ${themeGet('colors.neutralScale.300')};
    background-color: transparent;
  }
  @media ${device.laptop} {
    padding: 8px 8px;
  }
`;

const HistoryMeetingDateSeperator = styled(MeetingsDateSeparator)`
  &:first-of-type {
    padding-top: 0px;
  }
`;

// @ts-ignore-next-line
const DatePickerButton = forwardRef(function datePickerButton(props: any, ref: any /*eslint-disable-line*/) {
  const { onClick, disabled, value, ...rest } = props;
  return (
    <SmallButton onClick={onClick} disabled={disabled} {...rest}>
      {value}
      <Icon icon={Icons.chevron} size={12} color={disabled ? '#9aa8bb' : '#4c535d'} />
    </SmallButton>
  );
});

const getLastYearDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date;
};

export const HistoryMeetings: React.FC = () => {
  const { formatMessage: f } = useIntl();
  const [filterRecorded, setFilterRecorded] = useState(false);
  const [startDate, setStartDate] = useState(getLastYearDate());
  const [endDate, setEndDate] = useState(new Date());
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const history = useSelector((state: State) => state.meetings.history);
  const isOnline = useSelector(getIsOnline);
  const selectedId = useSelector((state: State) => state.meetings.selectedId);
  const selectedItemType = useSelector((state: State) => state.meetings.selectedItemType);
  const cbrProvisionedAndEntitled = useSelector(
    (state: State) =>
      state.core.preferences.meetings.current.cbrProvisioned && state.core.preferences.meetings.current.cbrEntitled
  );
  const contentSearchEnabledViaAccountFlag = useSelector((state: State) =>
    state.core.app.features.includes('cbrContentSearch')
  );
  const showElasticSearchInput = useSelector((state: State) =>
    state.core.app.features.includes('show-elastic-search-view')
  );
  const showElasticSearchAccountLevel = useSelector((state: State) =>
    state.core.app.features.includes('show-elastic-search-account')
  );
  const dispatch = useDispatch();

  const fetchHistory = (page: number, from?: Date, to?: Date, options: { append?: boolean; flush?: boolean } = {}) => {
    const fromStartOfDay = from && getStartOfDay(from);
    const toEndOfDay = to && getEndOfDay(to);

    dispatch(fetchMeetingHistory(page, fromStartOfDay, toEndOfDay, options));
  };

  useEffect(() => {
    if (isOnline) {
      fetchHistory(0, startDate, endDate, { flush: true });
    }
  }, [isOnline]);

  useEffect(() => {
    if (!history.isFetching && isLoadingMore) {
      setIsLoadingMore(false);
    }
  }, [history.isFetching]);

  const hasMoreSessions = history.next !== undefined;

  let sortedSessions = history.sessions.allIds.map((sessionId) => history.sessions.byId[sessionId]);
  if (filterRecorded) {
    sortedSessions = sortedSessions.filter((session) => session.recording);
  }

  const handleFilterRecordedToggle = (checked: boolean) => {
    setFilterRecorded(checked);
  };

  const handleLoadMoreClick = () => {
    if (hasMoreSessions && history.next) {
      setIsLoadingMore(true);
      fetchHistory(history.next, startDate, endDate, { append: true });
    }
  };

  const handleSessionCardClick = (session: any) => {
    if (selectedId !== session.sessionId) {
      dispatch(triggerTelemetryElasticSearch('sessionPreviewFromMeetingHistory'));
      dispatch(selectMeeting(SelectableItemType.SESSION, session.sessionId, true));
    }
  };

  const onStartDateChange = (date: Date) => {
    setStartDate(date);
    fetchHistory(0, date, endDate, { flush: true });
  };

  const onEndDateChange = (date: Date) => {
    setEndDate(date);
    fetchHistory(0, startDate, date, { flush: true });
  };

  const meetingListItems: Array<React.ReactElement<any>> = [];

  sortedSessions.forEach((session, index) => {
    if (shouldIncludeDaySeparator(session, sortedSessions[index - 1])) {
      meetingListItems.push(
        <HistoryMeetingDateSeperator date={session.startTime} key={`dateSeperator_${session.sessionId}`} />
      );
    }

    let isSelected = false;
    if (selectedId && selectedItemType === SelectableItemType.SESSION) {
      if (session.sessionId === selectedId) {
        isSelected = true;
      }
    }

    meetingListItems.push(
      <li key={session.sessionId}>
        <SelectableCard onClick={() => handleSessionCardClick(session)} isSelected={isSelected}>
          <HistoryMeetingItem session={session} />
        </SelectableCard>
      </li>
    );
  });

  if (hasMoreSessions) {
    meetingListItems.push(
      <li key="loadMore">
        <LoadMoreButton onClick={handleLoadMoreClick} isLoading={history.isFetching} />
      </li>
    );
  }

  return (
    <>
      {showElasticSearchInput || (contentSearchEnabledViaAccountFlag && showElasticSearchAccountLevel) ? (
        <GlobalSearchInput />
      ) : null}
      <FilterContainer>
        <DatePicker
          disabled={history.isFetching}
          onChange={(date) => {
            onStartDateChange(date);
          }}
          value={startDate}
          customInput={<DatePickerButton />}
          minDate={getLastYearDate()}
          maxDate={endDate}
        />
        <DatePicker
          disabled={history.isFetching}
          onChange={(date) => {
            onEndDateChange(date);
          }}
          value={endDate}
          customInput={<DatePickerButton />}
          minDate={startDate}
          maxDate={new Date()}
        />
        {cbrProvisionedAndEntitled && (
          <RecordedFilter
            key={'recordedFilter'}
            checked={filterRecorded}
            onFilterRecordedToggle={handleFilterRecordedToggle}
          />
        )}
      </FilterContainer>
      {history.isFetching && !isLoadingMore ? (
        <MeetingsTabSpinner />
      ) : !sortedSessions.length ? (
        <EmptyStatePlaceholder
          data-testid="empty-placeholder"
          label={f(messages.emptyLabel)}
          image={
            <img
              src={ConnectCalendar}
              alt={messages.emptyLabel.defaultMessage}
              sizes="(max-width: 767px) 320px, (max-width: 1439px) 200px, 270px"
              srcSet={`${ConnectCalendar} 394w, ${ConnectCalendar2x} 788w, ${ConnectCalendar3x} 1182w,`}
            />
          }
        />
      ) : (
        <CardList>{meetingListItems}</CardList>
      )}
    </>
  );
};
