import * as React from 'react';
import styled from '../../../../styled-components';
import { PrimaryButton } from '../Button/PrimaryButton';
import { MeetingIdInput } from './MeetingIdInput';
import { toMeetingId } from '../../../../services/MeetingService';
import { getMeeting } from '../../../../../models/Meeting';

export const JoinForm = styled.form`
  display: flex;
  align-items: center;

  input {
    flex: 1 1 0%;
  }
`;

export const JoinButton = styled(PrimaryButton)`
  margin-left: 8px;
`;

interface Props {
  joinMeetingPlaceholder: string;
  joinMeetingLabel: string;
  meetingId: string;
  joinMeeting: (meetingId: string) => void;
  g2mmUrlPrefix: string;
  unifiedLaunchUrlPrefix: string;
  joinDisabled: boolean;
  meetingIdInputDisabled: boolean;
}

interface State {
  meetingId: string;
  hasError: boolean;
  isLoading: boolean;
}

export class JoinMeetingForm extends React.Component<Props, State> {
  state = {
    meetingId: this.props.meetingId,
    hasError: false,
    isLoading: false
  };

  private onChange = (value: string) => {
    this.setState({
      meetingId: value,
      hasError: false
    });
  };

  private onJoin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let meetingId = this.state.meetingId.replace(/^(\d{3})-(\d{3})-(\d{3})$/, '$1$2$3');
    this.setState({ isLoading: true });
    try {
      meetingId = await toMeetingId(meetingId);
      await getMeeting(meetingId, false, false);
      this.props.joinMeeting(meetingId);
      this.setState({ meetingId: '', isLoading: false });
    } catch (e) {
      this.setState({ hasError: true, isLoading: false });
    }
  };

  render() {
    return (
      <JoinForm onSubmit={this.onJoin}>
        <MeetingIdInput
          value={this.state.meetingId}
          disabled={this.props.meetingIdInputDisabled}
          onChange={this.onChange}
          placeholder={this.props.joinMeetingPlaceholder}
          g2mmUrlPrefix={this.props.g2mmUrlPrefix}
          unifiedLaunchUrlPrefix={this.props.unifiedLaunchUrlPrefix}
          hasError={this.state.hasError}
        />
        <JoinButton
          data-testid="meeting-id-form-join-button"
          type="submit"
          disabled={!this.state.meetingId || this.props.joinDisabled || this.state.isLoading}
          loading={this.state.isLoading}
        >
          {this.props.joinMeetingLabel}
        </JoinButton>
      </JoinForm>
    );
  }
}
