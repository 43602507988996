export const parseDataUrl = (dataUrl: string): { mime: string; data: string } => {
  const arr = dataUrl.split(',');
  if (arr.length !== 2) {
    throw new Error('Data URL invalid, can not parse');
  }
  const match = arr[0].match(/:(.*?);/);
  if (!match || match.length !== 2) {
    throw new Error('Data URL invalid, can not parse mime type');
  }

  return {
    mime: match[1],
    data: arr[1]
  };
};

export const dataUrlToBlob = (dataUrl: string) => {
  const { mime, data } = parseDataUrl(dataUrl);
  const bstr = atob(data);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () => resolve(fr.result as string);
    fr.onerror = () => reject(fr.error || new Error('Unable to convert blob'));
    fr.readAsDataURL(blob);
  });
};
