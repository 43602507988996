import emoji from 'emojione';
import hljs from 'highlight.js';
import Remarkable from 'remarkable';
import { unescape } from 'lodash';
import config from 'config';
import marklinks from '../../lib/marklinks';

emoji.ascii = true;
emoji.emojiSize = '64';

// perf: memoize static results used by emoji.unifyUnicode
const unicodeCharRegexResult = emoji.unicodeCharRegex();
emoji.unicodeCharRegex = () => unicodeCharRegexResult;
const shortnameConversionMapResult = emoji.shortnameConversionMap();
emoji.shortnameConversionMap = () => shortnameConversionMapResult;

const unicodeRegexString = emoji.escapeRegExp(emoji.unicodeCharRegex());
const unicodeRegex = new RegExp(unicodeRegexString, 'gi');

// linkTarget is missing from the ts library
const md = new Remarkable({
  breaks: true,
  linkTarget: '_blank',
  highlight: (str: string, lang: string) => {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value;
      } catch (err) {} // eslint-disable-line
    }

    try {
      return hljs.highlightAuto(str).value;
    } catch (err) {} // eslint-disable-line

    return '';
  }
} as any);

md.block.ruler.disable(['heading', 'hr', 'list', 'table']);
md.inline.ruler.enable(['ins']); // enable underline
md.inline.ruler.disable(['entity']);

export function markdownFlattenQuote(message: string) {
  return message.replace(/^\s*>(>|\s)*/gm, '> ');
}

export function markdownInlineCodeToBlock(message: string) {
  return message.replace(/(`{3,})(?![a-z]*[ \t]*\n)([\s\S]+?)\1/g, '\n```\n$2\n```\n');
}

export function containsMarkdown(message: string) {
  const html = md.render(message);
  const noP = html.replace(/<\/?p>/gi, '');
  return noP.indexOf('<') > -1;
}

export function stripHtml(html: string) {
  return html.replace(/<[^>]*>/g, '');
}

export function decodeHtmlEntities(html: string) {
  return unescape(html);
}

export function stripMarkdown(message: string) {
  return decodeHtmlEntities(stripHtml(md.render(message))).trim();
}

export function containsEmoji(message: string) {
  return /:[a-z0-9_]+:/.test(message);
}

function messageContainsText(message: string) {
  return (
    emoji
      .shortnameToUnicode(message)
      .replace(unicodeRegex, '')
      .trim().length > 0
  );
}

function countEmojis(message: string) {
  return (message.match(/title=":[a-z0-9_]+:"/g) || []).length;
}

function wrapClass(html: string, cssClass: string) {
  return `<span class=${cssClass}>${html}</span>`;
}

function adjustEmojiSize(html: string, msgContainsText: boolean) {
  if (msgContainsText) {
    return html;
  }
  switch (countEmojis(html)) {
    case 1:
      return wrapClass(html, 'emoji-large');
    case 2:
    case 3:
      return wrapClass(html, 'emoji-medium');
    default:
      return html;
  }
}

export function buildEmojiUrl(code: string) {
  return config.emojis.url.replace('${code}', code); // eslint-disable-line no-template-curly-in-string
}

function emojiToImage(str: string) {
  // only render unicode emojis (clients replace other representations before sending)
  return str.replace(unicodeRegex, (unicode: string) => {
    const shortname = emoji.toShort(unicode);
    if (!shortname || !(shortname in emoji.emojioneList)) {
      return unicode;
    }

    const code = emoji.emojioneList[shortname].uc_base;

    // wrap in span for IE11 svg compatibility
    return `<span class="emojione-container"><img class="emojione" alt="${unicode}" title="${shortname}" src="${buildEmojiUrl(
      code
    )}"></span>`;
  });
}

export function formatToHtml(message: string) {
  return [
    markdownFlattenQuote,
    markdownInlineCodeToBlock,
    marklinks,
    (str: string) => md.render(str),
    emojiToImage,
    (html: string) => adjustEmojiSize(html, messageContainsText(message))
  ].reduce((str, fn) => fn(str), message);
}

export function formatToNotification(message: string) {
  return emoji.shortnameToUnicode(stripMarkdown(message));
}

export function formatToSend(message: string) {
  return [(str: string) => str.trim(), emoji.shortnameToUnicode].reduce((str, fn) => fn(str), message);
}
