import { ContentBlock } from 'draft-js';

export const findWithRegex = (
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  extraCheckCallback?: (search: string, pre: string) => boolean
) => {
  const text = contentBlock.getText();
  let matchArr: RegExpExecArray;
  let start;

  // Go through all matches in the text and return the indices to the callback
  while ((matchArr = regex.exec(text) as RegExpExecArray) !== null) {
    // eslint-disable-line
    start = matchArr.index;
    if (!extraCheckCallback || extraCheckCallback(matchArr[0], text.slice(0, start))) {
      callback(start, start + matchArr[0].length);
    }
  }

  // reset lastIndex so we can reuse the same regex instance
  regex.lastIndex = 0;
};
