import { connect } from 'react-redux';
import { fetchMyMeetings, selectMeeting } from '../view-model/modules/meetings/meetingsActions';
import { AnytimeMeetings } from '../components/Meetings/AnytimeMeetings/AnytimeMeetings';
import errorActions from '../view-model/modules/core/errors/errorActions';
import {
  getIsOnline,
  getProfileFromMeetingList,
  getRecurringMeetings,
  getRunningImpromptuMeeting
} from '../view-model/selectors';
import { Dispatch, State } from '../view-model/types';
import schedulerActions, { showCreateMeeting } from '../view-model/modules/scheduler/schedulerActions';
import { User } from '../view-model/modules/core/user/userReducer';
import { SelectableItemType } from '../view-model/modules/meetings/MeetingsTypes';

const mapStateToProps = (state: State) => {
  return {
    isOnline: getIsOnline(state),
    isFetching: state.meetings.isFetching,
    recurringMeetings: getRecurringMeetings(state),
    runningImpromptuMeeting: getRunningImpromptuMeeting(state),
    profileMeeting: getProfileFromMeetingList(state),
    selectedItemType: state.meetings.selectedItemType,
    selectedId: state.meetings.selectedId,
    user: state.core.user as User
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchMyMeetings() {
      dispatch(errorActions.clearMeetingError());
      dispatch(fetchMyMeetings());
    },
    selectMeeting(itemType: SelectableItemType, id?: string) {
      dispatch(selectMeeting(itemType, id));
    },
    openScheduler(user: User) {
      dispatch(showCreateMeeting(user));
    },
    reportSchedulerEndpointAccess() {
      dispatch(schedulerActions.schedulerEndpointAccess());
    }
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(AnytimeMeetings);

export default Container;
