import React from 'react';
import { FormattedMessage } from 'react-intl';
import config from 'config';
import { Box } from '../Common/Toolkit/Box/Box';
import DiscoverPlaceholder from '../../../assets/images/main.discover-placeholder.png';
import DiscoverPlaceholder2x from '../../../assets/images/main.discover-placeholder@2x.png';
import { messages } from '../Shell/AppMenu/AdditionalItems/AdditionalItems';
import styled from '../../styled-components';
import { Link } from '../Common/Toolkit/Link/Link';
import { themeGet } from '@styled-system/theme-get';

const LinkContainer = styled(Box)`
  margin-top: 60px;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  max-width: 400px;
`;

const DarkerLink = styled(Link)`
  font-family: 'lato-bold';
  color: ${themeGet('colors.primaryScale.600')};

  &:hover {
    color: ${themeGet('colors.primaryScale.500')};
    text-decoration: underline;
  }
`;

export const DiscoverDashboardPlaceholder = () => {
  return (
    <Box flexDirection={'column'} flex={'1 1 0%'} alignSelf={'center'} justifyContent={'center'} padding={16}>
      <Box alignSelf={'center'} maxWidth={380} flexDirection={'column'}>
        <img
          data-testid="discover-content-placeholder"
          src={DiscoverPlaceholder}
          style={{ width: '100%', alignSelf: 'center' }}
          srcSet={`${DiscoverPlaceholder}, ${DiscoverPlaceholder2x} 2x`}
          alt=""
        />
        <LinkContainer flexDirection={'row'}>
          <DarkerLink href={config.externalLinks.freeProductTraining} target="_blank">
            <FormattedMessage {...messages.freeProductTrainingText} />
          </DarkerLink>
          <DarkerLink href={config.externalLinks.support} target="_blank">
            <FormattedMessage {...messages.helpAndSupportText} />
          </DarkerLink>
        </LinkContainer>
      </Box>
    </Box>
  );
};
