import { SingleContact } from '@getgo/caps-redux';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AvatarOnlineStatus } from '../Common/AvatarOnlineStatus';
import { ContactActions } from './ContactActions';
import styled from '../../styled-components';

const messages = defineMessages({
  externalMember: {
    id: 'chat.chatHeaderContact.externalMember',
    defaultMessage: 'External member'
  }
});

export const ChatHeaderContactAvatar = styled(AvatarOnlineStatus)`
  margin: 0 10px 0 7px;
  min-width: 35px;
  font-size: 120%;
  font-weight: 700;
`;

export interface Props {
  chatEnabled: boolean;
  contact: SingleContact;
  sidebarOpenButton?: React.ReactElement<any>;
}

export const ChatHeaderContact: React.FC<Props> = (props) => {
  const isExternal = props.contact.isExternal;

  return (
    <header className="chat-header-contact">
      {props.sidebarOpenButton}
      <ChatHeaderContactAvatar
        name={props.contact.name}
        img={props.contact.avatarUrl}
        isExternal={isExternal}
        userStatus={props.contact.status === 'online' ? props.contact.availability : 'offline'}
        chatEnabled={false}
      />
      <h4 className="contact-details">
        <div className="name">{props.contact.name}</div>
        {isExternal && (
          <span className="external-members-tag">
            <FormattedMessage {...messages.externalMember} />
          </span>
        )}
      </h4>
      <div className="chat-header-flex-dummy" />
      <ContactActions {...props} />
    </header>
  );
};
