export const getTitle = () => {
  return document.title;
};

export const setTitle = (title: string) => {
  document.title = title;
};

export const setTitleUnreadCount = (unreadCount: number) => {
  let title = getTitle().replace(/^\(\d+\)\s/, '');

  if (unreadCount > 0) {
    title = `(${unreadCount}) ${title}`;
  }

  setTitle(title);
};

export const updateTitle = (title: string) => {
  const newTitle = getTitle().replace(/^(\(\d+\))?\s?.*$/, (_, unread) => {
    return unread ? `${unread} ${title}` : title;
  });

  setTitle(newTitle);
};
