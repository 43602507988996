import * as React from 'react';
import { forwardRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { InputFieldset } from '../../../Common/Toolkit/Input/InputFieldset';
import { ElementDescription, ElementTitle, Radio } from '../../../Common/Toolkit/Input/Selectable';
import { Box } from '../../../Common/Toolkit/Box/Box';
import DatePicker from '../../../Common/DateTime/DatePicker';
import TimePicker from '../../../Common/DateTime/TimePicker';
import TimeZonePicker from '../../../Common/DateTime/TimeZonePicker';
import DurationPicker from '../../../Common/DateTime/DurationPicker';
import styled from '../../../../styled-components';
import PersonalizeImage from '../../../../../assets/images/meeting-scheduler/theme/main.default.small.png';
import moment from 'moment-timezone';
import { getDateString, getDateTimeInUtc, getFnsLocale, getTimeString } from '../../../../../lib/date';
import device from '../../../Common/Toolkit/devices';
import { Icon } from '../../../Common/Toolkit/Icon/Icon';
import { Icons } from '../../../Common/Toolkit/ICONS';
import { SchedulerTabProps, SchedulerTabs } from '../MeetingScheduler';
import { DelayedInput } from '../../../Common/Toolkit/Input/DelayedInput';
import { MoreTab } from './MoreTab';
import { MeetingType, SchedulerFullMeeting } from '../../../../view-model/modules/scheduler/schedulerReducer';
import { DisableStateBox } from '../../../Common/Toolkit/Box/DisableStateBox';
import { format } from 'date-fns';
import logger from '../../../../services/LoggerService';

const messages = defineMessages({
  meetingTitle: {
    id: 'meetingDashboard.meetingScheduler.meetingTitle',
    defaultMessage: 'Meeting title'
  },
  oneTimeMeeting: {
    id: 'meetingDashboard.meetingScheduler.oneTimeMeeting',
    defaultMessage: 'One-time meeting'
  },
  oneTimeMeetingDescription: {
    id: 'meetingDashboard.meetingScheduler.oneTimeMeetingDescription',
    defaultMessage: 'Meet once and then the link expires.'
  },
  anytimeMeeting: {
    id: 'meetingDashboard.meetingScheduler.anytimeMeeting',
    defaultMessage: 'Online meeting room'
  },
  anytimeMeetingDescription: {
    id: 'meetingDashboard.meetingScheduler.anytimeMeetingDescription',
    defaultMessage: 'Meet whenever you like. The link never expires.'
  },
  when: {
    id: 'meetingDashboard.meetingScheduler.when',
    defaultMessage: 'When'
  },
  starts: {
    id: 'meetingDashboard.meetingScheduler.starts',
    defaultMessage: 'Starts'
  },
  duration: {
    id: 'meetingDashboard.meetingScheduler.duration',
    defaultMessage: 'Duration'
  },
  timezone: {
    id: 'meetingDashboard.meetingScheduler.timezone',
    defaultMessage: 'Time Zone'
  },
  personalizeButtonTitle: {
    id: 'meetingDashboard.meetingScheduler.personalizeButtonTitle',
    defaultMessage: 'Tip: personalize your meeting'
  },
  personalizeButtonDescription: {
    id: 'meetingDashboard.meetingScheduler.personalizeButtonDescription',
    defaultMessage: 'Create a memorable link and a personalized start page for your meeting.'
  },
  validationMessageStartDateInPast: {
    id: 'meetingDashboard.meetingScheduler.validationMessageStartDateInPast',
    defaultMessage: 'That date is in the past. Try again.'
  },
  displayDifferentTimezone: {
    id: 'meetingDashboard.meetingScheduler.displayDifferentTimezone',
    defaultMessage: "The event starts at {time} in your computer's time zone."
  }
});

const MeetingNameInputFieldset = styled(InputFieldset)`
  margin-bottom: 10px;
`;

const MeetingNameInput = styled(DelayedInput)`
  font-size: 16px;
`;

const MeetingTypeBox = styled(Box)`
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
`;

const MeetingTypeRadio = styled(Radio)`
  width: 50%;
  @media ${device.mobile} {
    width: 100%;
  }
`;

const ValidationStartDateTimeErrorMessageContainer = ({ children, ...rest }: { children: React.ReactNode }) => (
  <div {...rest} data-testid="validation-start-date-error">
    {children}
  </div>
);

const ValidationStartDateTimeErrorMessage = styled(ValidationStartDateTimeErrorMessageContainer)`
  color: #f56880;
  font-size: 11px;
  padding: 0;
  margin: -9px 0 10px 1px;
`;

const DateBox = styled(Box)`
  width: 100%;

  ${InputFieldset} {
    width: 27.5%;
    position: relative;

    @media ${device.mobile} {
      width: calc(50% - 8px);
    }
  }

  @media ${device.mobile} {
    flex-wrap: wrap;
  }
`;

const WhenInputFieldset = styled(InputFieldset)`
  margin-right: 16px;

  && {
    width: 45%;

    @media ${device.mobile} {
      margin-right: 0;
      margin-bottom: 8px;
      width: 100%;
    }
  }
`;

const StartsInputFieldset = styled(InputFieldset)`
  margin-right: 16px;
  width: 20%;
`;

const DifferentTimezoneBox = styled.div`
  background-color: #d9e9ff;
  border-radius: 4px;
  font-size: 14px;
  padding: 4px 16px;
  margin: 8px 0;
`;

const ShadowContainer = styled.div`
  padding: 0 3px;
`;

const PersonalizeButton = styled.button`
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  border: none;
  background-color: #fff;
  display: block;
  width: 100%;
  text-align: left;
  margin-top: 28px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  @media ${device.mobile} {
    text-align: center;
  }
`;

const PersonalizeButtonBox = styled(Box)`
  @media ${device.mobile} {
    flex-wrap: wrap;
  }
`;

const PersonalizeButtonImage = styled.div`
  margin: 17px 12px 0 14px;
  @media ${device.mobile} {
    margin: 17px 0 0 0;
    width: 100%;
  }
`;

const PersonalizeButtonContent = styled.div`
  @media ${device.mobile} {
    width: 100%;
  }
`;

const PersonalizeButtonTitle = styled.h4`
  font-family: 'lato-bold';
  font-size: 16px;
  font-weight: 500;
  color: #444;
  margin: 21px 0 7px 0;
`;

const PersonalizeButtonDescription = styled.p`
  font-size: 12px;
  color: #6b7582;
  margin: 0 0 26px 0;
`;

const InputIcon = styled(Icon)`
  position: absolute;
  right: 12px;
  top: 20px;
  pointer-events: none;
`;

const MeetingTabContainer = styled.div`
  padding: 24px 16px 8px 4px;
`;

// @ts-ignore-next-line
const CustomDatePickerInput = forwardRef(function customDatePickerInput(props: any, ref: any /*eslint-disable-line*/) {
  const { ariaLabel, ...rest } = props;
  return <input aria-label={ariaLabel} {...rest} />;
});

interface Props extends SchedulerTabProps {
  onPersonalizeButtonClick: () => void;
}

export const MeetingTab = (props: Props) => {
  const {
    onPropertyChange,
    meeting,
    onPersonalizeButtonClick,
    profile,
    onValidationError,
    onValidationSuccess,
    isLoadingMeeting
  } = props;

  const state = meeting.update as SchedulerFullMeeting;

  const intl = useIntl();
  const { formatMessage: f } = intl;

  const isPersonalMeeting = !!props.profile && props.profile.meetingId === props.meetingId;
  const [startDateTimeError, setStartDateTimeError] = React.useState(false);
  const startDateTimeErrorAndNotDisabled = startDateTimeError && state.meetingType === MeetingType.scheduled;

  const onChangeTimezone = (newTimezone: string) => {
    const time = moment.tz(`${getDateString(state.when)} ${getTimeString(state.starts)}`, state.displayTimeZone);
    const newTime = time.clone().tz(newTimezone);
    onPropertyChange('displayTimeZone', newTimezone);

    if (newTime.hour() !== time.hour() || newTime.minute() !== time.minute()) {
      onPropertyChange('starts', new Date(1990, 0, 1, newTime.hour(), newTime.minute()));
    }
    if (newTime.year() !== time.year() || newTime.month() !== time.month() || newTime.date() !== time.date()) {
      onPropertyChange('when', new Date(newTime.year(), newTime.month(), newTime.date()));
    }
  };

  React.useEffect(() => {
    if (state.meetingType !== MeetingType.scheduled) {
      onValidationSuccess('starts', SchedulerTabs.meeting);
      return;
    }

    const startTime = getDateTimeInUtc(getDateString(state.when), getTimeString(state.starts), state.displayTimeZone);
    if (startTime.getTime() <= Date.now()) {
      setStartDateTimeError(true);
      onValidationError('starts', SchedulerTabs.meeting);
    } else {
      setStartDateTimeError(false);
      onValidationSuccess('starts', SchedulerTabs.meeting);
    }
  }, [state.starts, state.when, state.meetingType]);

  let dateInMyTimezone;
  let formattedTime = null;
  let shouldShowTimezoneWarning = false;
  try {
    dateInMyTimezone = getDateTimeInUtc(getDateString(state.when), getTimeString(state.starts), undefined);
    const dateInSelectedTimezone = getDateTimeInUtc(
      getDateString(state.when),
      getTimeString(state.starts),
      state.displayTimeZone
    );
    shouldShowTimezoneWarning =
      state.meetingType === 'scheduled' &&
      Math.round(dateInMyTimezone.getTime() / 1000 / 60) !== Math.round(dateInSelectedTimezone.getTime() / 1000 / 60);
    if (shouldShowTimezoneWarning) {
      formattedTime = format(dateInSelectedTimezone, getFnsLocale(intl).formatLong.time({ width: 'short' }));
    }
  } catch (e) {
    logger.error('Invalid browser timezone ', Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  return (
    <MeetingTabContainer>
      <MeetingNameInputFieldset testId="meeting-subject" legend={f(messages.meetingTitle)}>
        <MeetingNameInput
          value={state.subject}
          onChange={(value) => onPropertyChange('subject', value)}
          aria-label={f(messages.meetingTitle)}
        />
      </MeetingNameInputFieldset>

      {!isPersonalMeeting ? (
        <>
          <MeetingTypeBox margin="0 0 12px 0">
            <MeetingTypeRadio
              id="anytime-radio"
              checked={!isLoadingMeeting && state.meetingType === MeetingType.recurring}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPropertyChange('meetingType', e.target.value)}
              value={MeetingType.recurring}
            >
              <ElementTitle>{f(messages.anytimeMeeting)}</ElementTitle>
              <ElementDescription>{f(messages.anytimeMeetingDescription)}</ElementDescription>
            </MeetingTypeRadio>

            <MeetingTypeRadio
              id="onetime-radio"
              checked={!isLoadingMeeting && state.meetingType === MeetingType.scheduled}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onPropertyChange('meetingType', e.target.value)}
              value={MeetingType.scheduled}
            >
              <ElementTitle>{f(messages.oneTimeMeeting)}</ElementTitle>
              <ElementDescription>{f(messages.oneTimeMeetingDescription)}</ElementDescription>
            </MeetingTypeRadio>
          </MeetingTypeBox>

          <DisableStateBox disabled={state.meetingType !== MeetingType.scheduled} hideOnMobile={true}>
            <DateBox margin="0 0 10px 0">
              <WhenInputFieldset
                testId="meeting-when"
                legend={f(messages.when)}
                hideMessage={true}
                messageType={startDateTimeErrorAndNotDisabled ? 'error' : undefined}
              >
                <DatePicker
                  value={!isLoadingMeeting && state.meetingType === MeetingType.scheduled ? state.when : null}
                  onChange={(date) => onPropertyChange('when', date || new Date())}
                  disabled={state.meetingType !== MeetingType.scheduled}
                  minDate={new Date(new Date().getTime() - 86400000)}
                  customInput={<CustomDatePickerInput ariaLabel={f(messages.when)} />}
                  isSchedulerDatePicker
                />
                <InputIcon icon={Icons.calendarInactive} size={20} color="#8b8d99" />
              </WhenInputFieldset>

              <StartsInputFieldset
                testId="meeting-starts"
                legend={f(messages.starts)}
                hideMessage={true}
                messageType={startDateTimeErrorAndNotDisabled ? 'error' : undefined}
              >
                <TimePicker
                  value={!isLoadingMeeting && state.meetingType === MeetingType.scheduled ? state.starts : null}
                  onChange={(date) => onPropertyChange('starts', date || new Date())}
                  disabled={state.meetingType !== MeetingType.scheduled}
                  customInput={<CustomDatePickerInput ariaLabel={f(messages.starts)} />}
                />
                <InputIcon icon={Icons.chevron} size={20} color="#8b8d99" />
              </StartsInputFieldset>

              <InputFieldset testId="meeting-duration" legend={f(messages.duration)} hideMessage={true}>
                <DurationPicker
                  value={!isLoadingMeeting && state.meetingType === MeetingType.scheduled ? state.duration : 0}
                  onChange={(duration) => onPropertyChange('duration', duration)}
                  disabled={state.meetingType !== MeetingType.scheduled}
                  ariaLabel={f(messages.duration)}
                />
                <InputIcon icon={Icons.chevron} size={20} color="#8b8d99" />
              </InputFieldset>
            </DateBox>

            {startDateTimeErrorAndNotDisabled && (
              <ValidationStartDateTimeErrorMessage>
                {f(messages.validationMessageStartDateInPast)}
              </ValidationStartDateTimeErrorMessage>
            )}

            {shouldShowTimezoneWarning && !isLoadingMeeting && (
              <DifferentTimezoneBox data-testid="display-different-timezone">
                {f(messages.displayDifferentTimezone, { time: formattedTime })}
              </DifferentTimezoneBox>
            )}

            {isLoadingMeeting ? (
              <div>&nbsp;</div>
            ) : (
              <TimeZonePicker
                label={f(messages.timezone)}
                value={state.meetingType === MeetingType.scheduled ? state.displayTimeZone : ''}
                onChange={onChangeTimezone}
                disabled={state.meetingType !== MeetingType.scheduled}
              />
            )}
          </DisableStateBox>

          {!state.personalizeMeeting && profile && (
            <ShadowContainer>
              <PersonalizeButton onClick={onPersonalizeButtonClick} data-testid="personalize-button">
                <PersonalizeButtonBox>
                  <PersonalizeButtonImage>
                    <img src={PersonalizeImage} alt="" />
                  </PersonalizeButtonImage>
                  <PersonalizeButtonContent>
                    <PersonalizeButtonTitle>{f(messages.personalizeButtonTitle)}</PersonalizeButtonTitle>
                    <PersonalizeButtonDescription>
                      {f(messages.personalizeButtonDescription)}
                    </PersonalizeButtonDescription>
                  </PersonalizeButtonContent>
                </PersonalizeButtonBox>
              </PersonalizeButton>
            </ShadowContainer>
          )}
        </>
      ) : (
        <MoreTab {...props} />
      )}
    </MeetingTabContainer>
  );
};
