import {
  ContentState,
  EditorState,
  Modifier,
  RawDraftEntity,
  SelectionState,
  convertFromRaw,
  convertToRaw
} from 'draft-js';
import { draftToMarkdown } from 'markdown-draft-js';

export interface XMPPMention {
  type: string;
  uri: string;
}

const MENTION_TYPE = 'mention';

export function createState(text = ''): EditorState {
  return EditorState.createWithContent(ContentState.createFromText(text));
}

export function stateToJs(state: EditorState): Draft.RawDraftContentState {
  return convertToRaw(state.getCurrentContent());
}

export function stateFromJs(jsState: Draft.RawDraftContentState): EditorState {
  return EditorState.createWithContent(convertFromRaw(jsState));
}

export function stateToPulseXMPPFormat(editorState: EditorState) {
  const entities: RawDraftEntity[] = [];

  // generate text message with inline styles and entities rendered as markdown
  const rawContent = convertToRaw(editorState.getCurrentContent());
  let xmppMessage = draftToMarkdown(rawContent, {
    entityItems: {
      [MENTION_TYPE]: {
        open: (entity: RawDraftEntity) => {
          // XXX: there's no clean way to get all entities from draftjs state, so collect them here
          entities.push(entity);
          return '**';
        },
        close: () => '**'
      }
    },
    styleItems: {
      UNDERLINE: {
        open: () => '++',
        close: () => '++'
      },
      BOLD: {
        open: () => '**',
        close: () => '**'
      },
      ITALIC: {
        open: () => '*',
        close: () => '*'
      },
      CODE: {
        open: () => '`',
        close: () => '`'
      }
    }
  });

  // fix markdown conversion of inserting two new lines between blocks
  xmppMessage = xmppMessage.replace(/\n\n/g, '\n');

  // map entities to xmpp references
  const references: XMPPMention[] = entities
    .map((e) => {
      return { type: MENTION_TYPE, uri: e.data.mention.get('id') };
    })
    .filter((mention, _, allMentions) => {
      // filter duplicates
      return allMentions.find((m) => m.uri === mention.uri) === mention;
    });

  return { xmppMessage: xmppMessage.trim(), options: { references } };
}

export function stateToPlainText(state: EditorState): string {
  return state.getCurrentContent().getPlainText();
}

export function removeDecorator(state: EditorState): EditorState {
  return EditorState.set(state, { decorator: null });
}

export function clearText(state: EditorState): EditorState {
  let contentState = state.getCurrentContent();
  const firstBlock = contentState.getFirstBlock();
  const lastBlock = contentState.getLastBlock();
  const allSelected = new SelectionState({
    anchorKey: firstBlock.getKey(),
    anchorOffset: 0,
    focusKey: lastBlock.getKey(),
    focusOffset: lastBlock.getLength(),
    hasFocus: true
  });
  contentState = Modifier.removeRange(contentState, allSelected, 'backward');
  let editorState = EditorState.push(state, contentState, 'remove-range');
  editorState = EditorState.forceSelection(editorState, contentState.getSelectionAfter());
  return editorState;
}

export function addTextToCurrentPosition(state: EditorState, text: string, addWhiteSpace?: boolean): EditorState {
  const contentState = state.getCurrentContent();
  const targetSelection = contentState.getSelectionAfter();
  const addedText = addWhiteSpace ? `${text} ` : text;

  const addedContent = Modifier.insertText(contentState, targetSelection, addedText, undefined, undefined);

  let newEditorState = EditorState.push(state, addedContent, 'insert-characters');
  newEditorState = EditorState.forceSelection(newEditorState, contentState.getSelectionAfter());

  return newEditorState;
}

export function hasText(state: EditorState): boolean {
  return state.getCurrentContent().hasText();
}

export function getExcessCharacters(state: EditorState, maxLength: number): number {
  const newLength = state.getCurrentContent().getPlainText().length;
  return Math.max(0, newLength - maxLength);
}
