import * as React from 'react';
import Creatable from 'react-select/creatable';
import Select, { components } from 'react-select';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { styleSelect, styledSelectMenu } from '../Toolkit/Input/SelectStyles';
import { KeyNames } from '../../../../lib/keycodes';

const StyledCreatable = styleSelect(Creatable);
const Menu = styledSelectMenu(components.Menu);

const messages = defineMessages({
  minFormat: {
    id: 'durationPicker.option.minFormat',
    defaultMessage: '{min} min'
  },
  hrFormat: {
    id: 'durationPicker.option.hrFormat',
    defaultMessage: '{h} h'
  }
});

interface DurationOptionType {
  label?: string;
  value: number;
  isNew?: boolean;
}

export interface Props {
  value?: number;
  onChange: (duration: number) => void;
  ariaLabel: string;
  disabled?: boolean;
}

interface State {
  inputValue: string;
  value: DurationOptionType;
  values: DurationOptionType[];
}

class DurationPicker extends React.Component<Props & WrappedComponentProps, State> {
  private select: null | Select<DurationOptionType> = null;

  private selectValue = false;

  state = {
    value: DurationPicker.durations[0],
    inputValue: '',
    values: []
  };

  private updateValue = (value: number) => {
    const index = DurationPicker.durations.findIndex((item) => item.value === value);
    let option: DurationOptionType;
    if (index !== -1) {
      option = DurationPicker.durations[index];
    } else {
      option = {
        value,
        label: '',
        isNew: true
      };
    }

    this.setState({
      value: option
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.value && this.props.value !== prevProps.value) {
      this.updateValue(this.props.value);
    }
  }

  componentDidMount(): void {
    if (typeof this.props.value === 'number') {
      this.updateValue(this.props.value);
    }
  }

  private onChange = (value: DurationOptionType) => {
    this.setState({ value });
    this.props.onChange(value.value);
  };

  private filterCustom = (item: { label: string; value: string; data: DurationOptionType }, rawInput: string) => {
    const label = this.formatLabel(item.data);
    if (!String(label).startsWith(rawInput)) {
      return false;
    }
    return !item.data.isNew;
  };

  private onInputChange = (input: string) => {
    const match = /[0-9]+h?/.exec(input);
    const inputValue = match ? match[0] : '';
    this.setState({ inputValue });
  };

  private onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { inputValue } = this.state;
    if (!inputValue) {
      return;
    }

    const value =
      inputValue.substr(-1) === 'h'
        ? parseInt(inputValue.substr(0, inputValue.length - 1), 10) * 60
        : parseInt(inputValue, 10);

    switch (e.key) {
      case KeyNames.ARROW_DOWN:
      case KeyNames.ARROW_UP:
        this.selectValue = true;
        break;

      case KeyNames.ENTER:
      case KeyNames.TAB:
        if (this.selectValue) {
          if (this.select?.select) {
            const innerSelect = this.select.select as any;
            if (innerSelect.select.hasOptions()) {
              return;
            }
          }
        }

        this.setState({
          inputValue: '',
          value: {
            label: '',
            value,
            isNew: true
          }
        });
        if (this.select) {
          this.select.onMenuClose();
        }
        this.props.onChange(value);
        e.preventDefault();
        break;

      default:
        this.selectValue = false;
    }
  };

  private renderMenu = (props: any) => <Menu {...props} />;

  private static get durations(): DurationOptionType[] {
    return [
      { value: 15 },
      { value: 30 },
      { value: 45 },
      { value: 60 },
      { value: 90 },
      { value: 120 },
      { value: 150 },
      { value: 180 },
      { value: 240 },
      { value: 300 },
      { value: 360 },
      { value: 420 },
      { value: 480 }
    ];
  }

  private formatLabel = (option: DurationOptionType) => {
    if (option.label) {
      return option.label;
    }

    const hr = option.value / 60;
    const fr = hr - Math.floor(hr);
    if (hr > 1 && (fr === 0 || fr === 0.5)) {
      return this.props.intl.formatMessage(messages.hrFormat, { min: option.value, h: hr });
    }
    return this.props.intl.formatMessage(messages.minFormat, { min: option.value });
  };

  render() {
    const value = DurationPicker.durations.find((item) => item.value === this.state.value.value) || this.state.value;
    return (
      <StyledCreatable
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        onKeyDown={this.onKeyDown}
        value={this.props.disabled ? '' : value}
        placeholder=""
        options={[...DurationPicker.durations]}
        classNamePrefix="react-select"
        inputValue={this.state.inputValue}
        filterOption={this.filterCustom}
        formatOptionLabel={this.formatLabel}
        isDisabled={this.props.disabled}
        ref={(select: Select<DurationOptionType>) => (this.select = select)}
        openMenuOnFocus={true}
        menuPlacement="auto"
        noOptionsMessage={(data: { inputValue: string }) => (
          <FormattedMessage {...messages.minFormat} values={{ min: data.inputValue }} />
        )}
        components={{
          Menu: this.renderMenu,
          DropdownIndicator: null
        }}
        styles={{
          menuPortal: (base: Record<string, any>) => ({ ...base, zIndex: 100 })
        }}
        menuPortalTarget={document.getElementById('app')}
        aria-label={this.props.ariaLabel}
      />
    );
  }
}

export default injectIntl(DurationPicker);
