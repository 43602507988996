import { Feature, MessagesEntity } from '@getgo/caps-redux';
import Events from '../../EventConstants';
import { getFeatureByNS } from '../../../../config/featuresHelper';

export default {
  handle(message: MessagesEntity, _: object) {
    const isCapsDiscoInfo = (msg: MessagesEntity) =>
      msg.type !== 'result' ||
      !msg.query ||
      msg.query.xmlns !== 'http://jabber.org/protocol/disco#info' ||
      !msg.query.feature;

    if (isCapsDiscoInfo(message)) {
      return null;
    }

    const { from, query } = message;
    const resource = from && from.split('/').pop();
    const jid = from && from.split('/').shift();

    // process features; query.feature is an array of features or a single feature object
    const features: Array<Feature | string> = ([] as Feature[])
      // TODO: Get TS to recognize checked query correctly
      // Check is done in isCapsDiscoInfo
      .concat(query!.feature)
      .map((feature: Feature) => feature.var)
      .map((xmlNS: string) => {
        const feature = getFeatureByNS(xmlNS);
        return feature.length > 0 ? feature[0].name : xmlNS;
      });

    return {
      type: Events.CONTACT_CAPABILITIES_UPDATED,
      data: {
        jid,
        resource,
        features
      }
    };
  }
};
