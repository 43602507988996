import localForage from 'localforage';
import { createTransform } from 'redux-persist';
import { hydrateState as hydratePreferencesState } from './modules/core/preferences/preferencesReducer';
import { State } from './types';

const CACHE_MESSAGE_COUNT = 25;

// incomplete
export type PersistedState = Partial<{
  core: {
    user: State['core']['user'];
  };
}>;

const coreSubsetTransform = createTransform(
  (state: any) => {
    return {
      notifications: { ...state.notifications },
      preferences: hydratePreferencesState(state.preferences),
      user: { ...state.user }
    };
  },
  (state) => state,
  { whitelist: ['core'] }
);

const chatHistoryAndContactsTransform = createTransform(
  (state: any) => {
    const messagesToCache = Object.keys(state.chat.rooms).reduce((obj: Record<string, any>, roomId) => {
      const messages = state.chat.rooms[roomId].messages;
      obj[roomId] = {
        messages: messages.slice(-CACHE_MESSAGE_COUNT),
        index: {}
      };
      return obj;
    }, {});

    const contactItems = state.contacts.items.map((c: any) => {
      if (c.isGroup) {
        return {
          ...c,
          status: undefined,
          availability: undefined,
          resources: [],
          typingList: []
        };
      }
      return {
        ...c,
        status: undefined,
        availability: undefined,
        resources: [],
        isTyping: false
      };
    });

    return {
      chat: {
        ...state.chat,
        connectionStatus: {
          xmppConnected: false,
          reconnectionDelay: 0,
          reconnecting: false
        },
        rooms: messagesToCache
      },
      contacts: {
        ...state.contacts,
        items: contactItems
      },
      unreadMessages: state.unreadMessages
    };
  },
  (state) => state,
  { whitelist: ['messaging'] }
);

const schedulerTransform = createTransform(
  (state: any) => ({
    settings: state.settings
  }),
  (state) => state,
  { whitelist: ['scheduler'] }
);

export const persistorConfig = {
  storage: localForage,
  whitelist: ['core', 'messaging', 'scheduler', 'reduxStateVersion'],
  transforms: [coreSubsetTransform, chatHistoryAndContactsTransform, schedulerTransform]
};
