import config from 'config';
import logger from './LoggerService';
import { ConcurrentMeetingError, CreateMeetingError } from '../../models/Errors';
import {
  createImpromptuMeeting,
  getConcurrentSession,
  getStartUrl,
  CreateMeetingResponse,
  meetingIdFromProfileName,
  meetingIdFromRoomName,
  searchProfileById,
  searchRoomByUserKeyAndName
} from '../../models/Meeting';

export enum NameError {
  invalidId = 'invalidId',
  notOnlyDigits = 'notOnlyDigits',
  invalidChar = 'invalidChar',
  alreadyExists = 'alreadyExists',
  serverError = 'serverError'
}

export const meetingUrl = async (meetingId: string, options: { sessionTrackingId: string }): Promise<string> => {
  let id = meetingId;

  // make sure we have only the meeting ID and not the join URL
  if (/^http/.test(id)) {
    const search = /[0-9]{9}$/.exec(meetingId);
    if (!search) {
      throw new Error(`Unable to get meeting id from URL ${meetingId}`);
    }

    id = search[0];
  }

  const trackingId = options.sessionTrackingId;
  const startUrl = await getStartUrl(id);
  return `${startUrl.hostURL}&sessionTrackingId=${trackingId}`;
};

/**
 * is9DigitProfileId
 * Checks, if the given profileId is a 9 digit profile id
 * @param profileId
 */
export const is9DigitProfileId = (profileId: string): boolean => {
  return !!/^(?:[0-9]{9}|[0-9]{3}-[0-9]{3}-[0-9]{3})$/.exec(profileId);
};

/**
 * isValidProfileId
 * Validates, if the given profile id dont contain any invalid char and isn't empty
 * @param profileId
 * @return boolean
 */
export const isValidProfileId = (profileId: string): boolean => {
  if (profileId.length === 0) {
    return false;
  }
  if (is9DigitProfileId(profileId)) {
    return false;
  }
  return !!/^[\u00C0-\u1FFF\u2C00-\uD7FF\w0-9_-]{1,50}$/i.exec(profileId);
};

export const validateProfileId = async (newProfileId: string, oldProfileId: string): Promise<NameError | true> => {
  if (is9DigitProfileId(newProfileId)) {
    return NameError.invalidId;
  }

  if (/^\d+$/.test(newProfileId)) {
    return NameError.notOnlyDigits;
  }

  if (!isValidProfileId(newProfileId)) {
    return NameError.invalidChar;
  }
  try {
    const newProfile = await searchProfileById(newProfileId);
    if (newProfile && newProfile.profileId !== oldProfileId) {
      return NameError.alreadyExists;
    }
    return true;
  } catch (e) {
    if (e.status === 500) {
      // duplicate profile id - show a alreadyExists error
      return NameError.alreadyExists;
    }

    // general server error (down/csp/network/...)
    return NameError.serverError;
  }
};

export const validateRoomName = async (userKey: string, roomName: string) => {
  if (!isValidProfileId(roomName)) {
    return NameError.invalidChar;
  }

  try {
    if ((await searchRoomByUserKeyAndName(userKey, roomName)).length) {
      return NameError.alreadyExists;
    }

    return true;
  } catch (e) {
    if (e.status === 404) {
      // duplicate profile id - show a alreadyExists error
      return true;
    }

    // general server error (down/csp/network/...)
    return NameError.serverError;
  }
};

/**
 * Returns the meeting ID for the supplied information. If the first parameter is
 * supplied as 9-digit-number, it will be returned as the meeting ID. Otherwise,
 * the meeting service will be called to retrieve the meeting ID by interpreting the
 * first parameter as profile name and the second as room name if supplied.
 */
export const toMeetingId = async (profileNameOrMeetingId: string): Promise<string> => {
  // numeric meeting ids are 9 digits always
  if (/\d{9}/.test(profileNameOrMeetingId)) {
    return profileNameOrMeetingId;
  }

  const parts = profileNameOrMeetingId.split('/');
  if (parts.length === 2) {
    return await meetingIdFromRoomName(parts[0], parts[1]);
  }

  return await meetingIdFromProfileName(profileNameOrMeetingId);
};

/**
 * Join a GoToMeeting meeting by opening the native endpoint through the launcher flow.
 * @param {string} meetingId - 9-digit meeting Id or profile name
 * @param options session tracking id and window reference to reuse for redirect
 */
export const joinMeeting = async (
  meetingId: string,
  options: { window?: Window | null; sessionTrackingId: string }
): Promise<void> => {
  let url;
  try {
    // Make sure we have a meetingId and not a profileName
    const numericMeetingId = await toMeetingId(meetingId);
    url = await meetingUrl(numericMeetingId, options);
  } catch (e) {
    url = `${config.externalLinks.g2mm}/${meetingId}`;
  }

  if (options.window) {
    options.window.location.replace(url);
  } else {
    window.open(url, `Meeting-${meetingId}`, 'noopener noreferrer');
  }
};

/**
 * Provision a MeetNow meeting and launch it through a new Window.
 */
export const meetNow = async (options: {
  win: Window | null | undefined;
  sessionTrackingId: string;
}): Promise<CreateMeetingResponse> => {
  let concurrentMeeting;

  try {
    concurrentMeeting = await getConcurrentSession();
  } catch (err) {
    if (!err.status) {
      // not an HTTP error, treat it as a generic error
      logger.error('meetNow.error', 'error=', err, 'sessionTrackingId=', options.sessionTrackingId);
      throw new CreateMeetingError(err);
    }
    if (err.status !== 404) {
      // anything but 404 (Not Found) is assumed concurrent meeting exists
      logger.error('concurrentMeetNow.error', 'error=', err, 'sessionTrackingId=', options.sessionTrackingId);
      throw new ConcurrentMeetingError(err);
    }
  }

  if (concurrentMeeting?.meetingId) {
    logger.error(
      'meetNow.alreadyInUse',
      'concurrentMeetingId=',
      concurrentMeeting.meetingId,
      'sessionTrackingId=',
      options.sessionTrackingId
    );
    if (options.win) {
      options.win.close();
    }

    throw new ConcurrentMeetingError();
  }

  let meeting;

  try {
    meeting = await createImpromptuMeeting();
  } catch (err) {
    logger.error('meetNow.error', 'error=', err, 'sessionTrackingId=', options.sessionTrackingId);
    throw new CreateMeetingError(err);
  }

  // now we have a meeting... we can join it and pass the window reference
  await joinMeeting(meeting.meetingId, {
    window: options.win,
    sessionTrackingId: options.sessionTrackingId
  });

  return meeting;
};
