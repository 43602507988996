import React from 'react';
import styled from '../../../styled-components';
import { NavLink } from 'react-router-dom';
import { UnreadCount } from '../../Common/UnreadCount/UnreadCount';
import device from '../../Common/Toolkit/devices';

export const AppMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  overflow: auto;
  align-items: stretch;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

export const AppMenuDiv = styled.div`
  z-index: 3;
  background-color: #39404d;
  display: flex;
  transform: translate3d(0, 0, 0); /* Required for Safari popover bug */
`;

export const AppMenuItemContainer = styled.div`
  flex-direction: column;
  width: 80px;
  display: flex;
  flex: 1 0 auto;
  overflow-y: auto;

  @media ${device.mobile} {
    flex-direction: row;
    height: 60px;
    width: 100%;
  }
`;

export const AppMenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 10 1 auto;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    flex-direction: row;
  }
`;

export const AppMenuItem = styled.li`
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: initial;

  @media ${device.mobile} {
    height: 60px;
  }
`;

export const ImageBadgeFrame = styled.div`
  position: relative;
  display: inline-flex;
`;

export const MenuNavLink = styled((props) => (
  <NavLink {...props} activeStyle={{ backgroundColor: '#262a34', color: '#fff' }} />
))`
  text-decoration: none;
  color: #eaeaea;
  font-size: 10px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 16px 0;
  margin: 0;
  line-height: 12px;

  &:hover,
  &:active {
    background-color: #262a34;
    color: #fff;
  }

  @media ${device.mobile} {
    padding: 0 16px 0 16px;
    margin-right: 0;
  }
`;

export const LinkItemText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  margin: 4px 0 0;

  @media ${device.mobile} {
    display: none;
  }
`;

export const AdditionalItemsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;

  @media ${device.mobile} {
    padding-bottom: 0;
  }
`;

export const AppMenuUnreadCount = styled(UnreadCount)`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
`;

export const DiscoverMenuItem = styled(AppMenuItem)`
  @media ${device.mobile} {
    display: none;
  }
`;

export const MainContent = styled.div`
  flex: 1;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;
