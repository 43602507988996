import React from 'react';
import styled from '../../../../styled-components';
import { themeGet } from '@styled-system/theme-get';

type onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => void;

export const ElementTitle = styled.span`
  display: block;
  font-weight: bold;
  font-family: 'lato-bold';
  font-size: 16px;
  color: ${themeGet('colors.backgroundOn')};
`;

export const ElementText = styled.span`
  display: block;
  font-size: 14px;
  color: ${themeGet('colors.backgroundOn')};
`;

export const ElementDescription = styled.span`
  display: block;
  font-family: 'lato-light';
  font-weight: 300;
  margin-top: 8px;
  font-size: 11px;
  line-height: 16px;
`;

const Label = styled.label`
  line-height: 24px;
`;

interface Props {
  children?: React.ReactNode;
  className?: string;
  id: string;
  onChange?: onChangeEvent;
  checked: boolean;
  value?: string;
  disabled?: boolean;
  labelProps?: {
    [s: string]: any;
  };
  inputProps?: {
    [s: string]: any;
  };
  [s: string]: any;
}

const Selectable = ({
  id, // also used for data-testid
  className,
  children,
  onChange,
  checked,
  disabled,
  type,
  value,
  labelProps,
  inputProps,
  ...rest
}: Props & { type?: string }) => (
  <div className={className} {...rest}>
    <input
      id={id}
      data-testid={id}
      type={type}
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      value={value}
      {...inputProps}
    />
    <Label htmlFor={id} data-testid={`${id}-label`} {...labelProps}>
      {children}
    </Label>
  </div>
);

export const Checkbox = styled(Selectable).attrs(() => ({ type: 'checkbox' }))`
  padding-left: 25px;
  position: relative;

  input[type='checkbox'] {
    opacity: 0;
    position: absolute;

    & + label {
      cursor: pointer;
      display: inline-block;
      position: relative;
      font-size: 16px;
      color: ${themeGet('colors.backgroundOn')};
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        left: -24px;
        top: 3px;
        border: 2px solid #ccc;
        border-radius: 2px;
        background-color: #fff;
      }
    }

    &:checked + label {
      &::after {
        background-color: #21a1eb;
        border: none;
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        width: 4px;
        height: 9px;
        left: -19px;
        top: 4px;
        z-index: 1;
        border: 2px solid #fff;
        transform: rotate(45deg);
        border-left: none;
        border-top: none;
      }
    }

    &:focus + label::after {
      box-shadow: 0 0 0 3px #3fb4f355;
    }
    &:focus:not(:checked) + label::after {
      border-color: #0f9fee;
    }

    // workaround for Edge
    &[disabled] + label,
    &:disabled + label {
      cursor: not-allowed;
      opacity: 0.5;
      filter: grayscale(100%);
    }

    &:checked:disabled + label::after {
      background-color: #ccc;
    }
  }
`;

export const Radio = styled(Selectable).attrs(() => ({ type: 'radio' }))`
  padding-left: 25px;
  position: relative;

  input[type='radio'] {
    opacity: 0;
    position: absolute;

    & + label {
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      color: ${themeGet('colors.backgroundOn')};
      &::before {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 10px;
        background-color: #fff;
        border: 1px solid #b5b4b5;
        display: block;
        position: absolute;
        margin-left: -24px;
        box-sizing: border-box;
        margin-top: 3px;
      }
    }

    // workaround for Edge
    &[disabled] + label,
    &:disabled + label {
      cursor: not-allowed;
      opacity: 0.5;
      filter: grayscale(100%);
    }

    &:checked + label::before {
      border-color: #0f9fee;
      border-width: 4px;
    }

    &:focus + label::before {
      box-shadow: 0 0 0 3px #3fb4f355;
    }
  }
`;
