import { ActionsUnion, createAction } from './type-helpers';
import { User } from './modules/core/user/userReducer';

export enum ActionTypes {
  UPDATE_USER = 'UPDATE_USER',
  UPGRADE_LINK_CLICKED = 'UPGRADE_LINK_CLICKED',
  OUTLOOK_CALENDAR_LINK_CLICKED = 'OUTLOOK_CALENDAR_LINK_CLICKED',
  GOOGLE_CALENDAR_LINK_CLICKED = 'GOOGLE_CALENDAR_LINK_CLICKED',
  NEW_EXPERIENCE_OPT_OUT = 'NEW_EXPERIENCE_OPT_OUT',
  PAGE_CHANGED = 'PAGE_CHANGED',
  WEB_PAGE_OPENED = 'WEB_PAGE_OPENED',
  SEND_MESSAGE_FEATURES_USED = 'SEND_MESSAGE_FEATURES_USED',
  UX2019_ENABLE_NOTIFICATION_CLICKED = 'UX2019_ENABLE_NOTIFICATION_CLICKED',
  UX2019_ENABLE_NOTIFICATION_SHOWN = 'UX2019_ENABLE_NOTIFICATION_SHOWN',
  NAVIGATION_BY_CLICK = 'NAVIGATION_BY_CLICK',
  CHROMA_CAM_DOWNLOADED = 'CHROMA_CAM_DOWNLOADED',
  GOTO_APP_EAP_DOWNLOADED = 'GOTO_APP_EAP_DOWNLOADED'
}

export interface UserWithPreferences extends User {
  accessibility?: boolean;
}

const actions = {
  updateUser: (user: UserWithPreferences) => createAction(ActionTypes.UPDATE_USER, user),
  upgradeLinkClicked: () => createAction(ActionTypes.UPGRADE_LINK_CLICKED),
  outlookCalendarLinkClicked: () => createAction(ActionTypes.OUTLOOK_CALENDAR_LINK_CLICKED),
  googleCalendarLinkClicked: () => createAction(ActionTypes.GOOGLE_CALENDAR_LINK_CLICKED),
  pageChanged: (page: string) => createAction(ActionTypes.PAGE_CHANGED, page),
  webPageOpened: (linkTargetType: string) => createAction(ActionTypes.WEB_PAGE_OPENED, { linkTargetType }),
  newExperienceOptOut: () => createAction(ActionTypes.NEW_EXPERIENCE_OPT_OUT),
  ux2019EnableNotificationClicked: () => createAction(ActionTypes.UX2019_ENABLE_NOTIFICATION_CLICKED),
  ux2019EnableNotificationShown: () => createAction(ActionTypes.UX2019_ENABLE_NOTIFICATION_SHOWN),
  navigationByClick: (destination: string) => createAction(ActionTypes.NAVIGATION_BY_CLICK, { destination }),
  chromaCamDownloaded: () => createAction(ActionTypes.CHROMA_CAM_DOWNLOADED),
  gotoAppEapDownloaded: () => createAction(ActionTypes.GOTO_APP_EAP_DOWNLOADED)
};

export default actions;

export type Actions = ActionsUnion<typeof actions>;
