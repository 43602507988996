import * as React from 'react';
import classNames from 'classnames';
import AutosizeInput, { AutosizeInputProps } from 'react-input-autosize';
import './EditableText.css';

interface Props extends AutosizeInputProps {
  className?: string;
  inputRef?: any;
  ariaLabel?: string;
}

export const EditableText: React.FC<Props> = ({ ariaLabel, ...props }) => (
  <span className={classNames('editable-text', props.className || '')}>
    <AutosizeInput spellCheck={false} {...props} className="" aria-label={ariaLabel} />
    <i className="togo-icon togo-icon-edit" />
  </span>
);
