import styled, { css } from '../../../../styled-components';

const menuStyles = css`
  margin-left: -10px;
  width: calc(100% + 20px);
  margin-top: 12px !important;
  box-shadow: 0px 5px 8px 3px rgba(0, 0, 0, 0.2) !important;
  color: #000;

  .react-select__option--is-focused {
    background-color: #f0f0f0;
  }
  .react-select__option--is-selected {
    font-weight: bold;
    background-color: #006aff;
    color: #fff;
    &.react-select__option--is-focused {
      background-color: #004fbd;
    }
  }
`;

export const styledSelectMenu = (menu: any) => styled(menu)`
  & {
    ${menuStyles}
  }
`;

export const styleSelect = (select: any) => styled(select)`
  .react-select__control {
    min-height: auto;
    box-shadow: none;
    border: none;
    cursor: pointer;
  }

  .react-select__control--is-disabled {
    background-color: #fff;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    margin: 0;
    padding: 0;
  }

  .react-select__value-container {
    padding: 0;
    & > div {
      margin: 0;
      padding: 0;
    }
  }

  .react-select__menu {
    ${menuStyles}
  }
`;
