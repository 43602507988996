import { connect } from 'react-redux';
import { Entitlement } from '../components/Shell/Entitlement/Entitlement';
import { logout } from '../view-model/modules/core/user/userActions';
import { Dispatch, State } from '../view-model/types';
import { hasLicense } from '../view-model/selectors';

const mapStateToProps = (state: State) => {
  return {
    hasLicense: hasLicense(state)
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  logout: () => {
    dispatch(logout());
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Entitlement);
