import { MessagesEntity, Selectors } from '@getgo/caps-redux';
import { AnyTODO } from '../../../../types/pulse-web';
import { selectNextContactWithHistory } from '../../capsActions';
import { Dispatch } from '../../types';

export default {
  handle(message: MessagesEntity, _: object) {
    const shouldProcess = (msg: MessagesEntity) => {
      return (
        msg.isFromGroup &&
        msg.type === 'unavailable' &&
        msg.group.statusCodes.includes('321') &&
        msg.group.affiliation === 'none' &&
        msg.group.role === 'none' &&
        msg.to &&
        msg.to.split('/').shift() === msg.group.memberJid.bare
      );
    };

    if (!shouldProcess(message)) {
      return null;
    }
    return async (dispatch: Dispatch, getState: AnyTODO) => {
      const groupJid = message.from.split('/').shift();
      const room = Selectors.findContact(getState(), groupJid);
      if (room?.selected) {
        return dispatch(selectNextContactWithHistory(room.jid));
      }

      return null;
    };
  }
};
