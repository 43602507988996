import styled from '../../styled-components';
import { Card } from '../Common/Toolkit/Card/Card';
import React from 'react';
import { PrimaryButton } from '../Common/Toolkit/Button/PrimaryButton';
import {
  EngagementEntry,
  isImageBlogTemplate,
  isVideoBlogTemplate,
  VideoInteractions
} from '../../../models/EngagementEngine';
import { useDispatch } from 'react-redux';
import {
  clickCtaOnDiscoverItem,
  videoInteractionOnDiscoverItem
} from '../../view-model/modules/discover/discoverActions';
import ReactMarkdown from 'react-markdown';
import DiscoverVideoPlayer from './DiscoverVideoPlayer';

const Title = styled.h3`
  font-family: lato-semibold;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
`;

const CardHeader = styled.div`
  margin: 24px 32px 0px 32px;
  padding-bottom: 24px;
  border-bottom: 1px solid #f0f2f5;
`;

const ArticleContainer = styled.div`
  padding: 24px 32px;
`;

const ImageContainer = styled.div`
  margin: 32px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const DiscoverButton = styled(PrimaryButton)`
  text-transform: none;
`;

interface Props {
  engagementEntry: EngagementEntry;
}

export const DiscoverContentCard: React.FC<Props> = (props) => {
  if (
    !isVideoBlogTemplate(props.engagementEntry.engagementMessage) &&
    !isImageBlogTemplate(props.engagementEntry.engagementMessage)
  ) {
    return <h1>not yet implemented</h1>;
  }
  const dispatch = useDispatch();

  const {
    blogTitle,
    blogDescription,
    blogArticle,
    url,
    alt = 'Discovery card main image',
    ctaLinkText,
    ctaURL
  } = props.engagementEntry.engagementMessage;

  const handleCtaClick = () => {
    window.open(ctaURL, '_blank', 'noopener');
    dispatch(clickCtaOnDiscoverItem(props.engagementEntry));
  };

  const handleVideoInteraction = (interactionType: VideoInteractions) => {
    dispatch(videoInteractionOnDiscoverItem(props.engagementEntry, interactionType));
  };

  return (
    <Card>
      <CardHeader>
        <Title>{blogTitle}</Title>
        <span>{blogDescription}</span>
      </CardHeader>
      <ArticleContainer>
        <ReactMarkdown>{blogArticle}</ReactMarkdown>
        {isImageBlogTemplate(props.engagementEntry.engagementMessage) && (
          <ImageContainer>
            <img src={url} alt={alt} style={{ maxWidth: '100%' }} />
          </ImageContainer>
        )}
        {isVideoBlogTemplate(props.engagementEntry.engagementMessage) && (
          <DiscoverVideoPlayer key={url} videoId={url} handleVideoInteraction={handleVideoInteraction} />
        )}
        {ctaLinkText && (
          <ButtonContainer>
            <DiscoverButton onClick={handleCtaClick}>{ctaLinkText}</DiscoverButton>
          </ButtonContainer>
        )}
      </ArticleContainer>
    </Card>
  );
};
