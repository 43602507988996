import uuid from 'uuid';

function get(key: string) {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return undefined;
  }
}

function set(key: string, value: string) {
  try {
    return localStorage.setItem(key, value);
  } catch (error) {
    return undefined;
  }
}

function getMachineId(): string {
  const key = 'machineId';
  const id = get(key) || uuid.v4();
  set(key, id);

  return id;
}

export default {
  id: getMachineId()
};
