import React from 'react';
import emojione from 'emojione';
import store from 'store';
import each from 'lodash/each';
import map from 'lodash/map';
import strategy from 'emojione/emoji.json';
import { Emoji } from './Emoji';
import { Categories } from './Categories';
import createRowsSelector from './utils/createRowsSelector';
import createEmojisFromStrategy from './utils/createEmojisFromStrategy';
import { defaultCategories } from './constants';

/* eslint-disable @typescript-eslint/no-unused-vars */
interface Props {
  emojione?: {
    imageType: string;
    sprites: boolean;
    useNativeArt: boolean;
    imagePathSVGSprites: string;
  };
  search?: boolean | string;
  searchPlaceholder?: string;
  className?: string;
  onChange: (event: any) => void;
  categories?: any;
  buildEmojiUrl?: (hexCode: string, pngSize: string) => string;
  nonce?: any;
}

interface State {
  modifier: string;
  category: any;
  term: any;
  emojis: any;
}

export class EmojiPicker extends React.Component<Props, State> {
  private rowsSelector: any;
  private categories: any;
  private search: any;

  state = {
    modifier: store.get('emoji-modifier') || '0',
    category: false,
    term: this.props.search !== true ? this.props.search : '',
    emojis: undefined
  };

  UNSAFE_componentWillMount() {
    this.rowsSelector = createRowsSelector();

    each(this.props.emojione, (value, key) => {
      (emojione as any)[key] = value;
    });
    this.setState({ emojis: createEmojisFromStrategy(strategy) });
  }

  componentDidMount() {
    this.setState({ category: this.categories.getActiveCategory() }); // eslint-disable-line
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.search !== nextProps.search) {
      this.setState({ term: this.props.search });
    }
  }

  setFocus = (ev: any) => {
    if (ev.target.id === 'flags') {
      // @ts-ignore
      this.categories[this.state.category].children[0].focus();
    }
  };

  private renderHeaderCategories = () => {
    const { categories = defaultCategories } = this.props;
    return map(categories, (details, key) => (
      // @ts-ignore
      <li key={key} className={this.state.category === key ? 'active' : undefined}>
        <Emoji
          id={key}
          role="menuitem"
          ariaLabel={`${key} category`}
          shortname={`:${details.emoji}:`}
          onSelect={() => {
            this.categories.jumpToCategory(key);
          }}
          buildEmojiUrl={this.props.buildEmojiUrl}
        />
      </li>
    ));
  };

  private renderCategories = () => {
    const { categories = defaultCategories, search = '' } = this.props;
    const rows = this.rowsSelector(categories, this.state.emojis, this.state.modifier, search, this.state.term);

    return (
      <div className="emoji-categories-wrapper">
        <Categories
          nonce={this.props.nonce}
          ref={this.setCategoriesRef}
          rows={rows}
          modifier={this.state.modifier}
          onActiveCategoryChange={this.onActiveCategoryChange}
          onChange={this.props.onChange}
          onModifierChange={this.onModifierChange}
          buildEmojiUrl={this.props.buildEmojiUrl}
        />
      </div>
    );
  };

  private setCategoriesRef = (categories: any) => {
    this.categories = categories;
  };

  private setSearchRef = (ref: any) => {
    this.search = ref;
  };

  private onActiveCategoryChange = (category: any) => {
    if (category !== this.state.category) {
      this.setState({ category });
    }
  };

  private onModifierChange = (modifier: any) => {
    this.setState({ modifier });
    store.set('emoji-modifier', modifier);
  };

  private renderSearchInput = () => {
    const { searchPlaceholder = 'Search…' } = this.props;
    if (this.props.search === true) {
      return (
        <div className="emoji-search-wrapper">
          <input
            className="emoji-search"
            type="search"
            placeholder={searchPlaceholder}
            ref={this.setSearchRef}
            onChange={this.updateSearchTerm}
            autoFocus
          />
        </div>
      );
    }

    return null;
  };

  private updateSearchTerm = () => {
    this.setState({ term: this.search.value });
  };

  render() {
    const {
      emojione: _,
      search,
      searchPlaceholder,
      className,
      onChange,
      categories,
      buildEmojiUrl,
      ...rest
    } = this.props;
    let classes = 'emoji-dialog';
    if (this.props.search === true) {
      classes += ' with-search';
    }
    if (this.props.className) {
      classes += ` ${this.props.className}`;
    }

    return (
      <div className={classes} role="dialog" {...rest}>
        <header className="emoji-dialog-header" role="menu">
          <ul onBlur={this.setFocus}>{this.renderHeaderCategories()}</ul>
        </header>
        {this.renderSearchInput()}
        {this.renderCategories()}
      </div>
    );
  }
}
