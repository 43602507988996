import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SelectableCard } from '../../Common/Toolkit/SelectableCard/SelectableCard';
import { getStartOfDay, isEqualDate } from '../../../../lib/date';
import { MeetingsDateSeparator } from '../../Common/Toolkit/MeetingsDateSeparator/MeetingsDateSeparator';
import { UpcomingMeetingItem } from './UpcomingMeetingItem';
import ConnectCalendar from '../../../../assets/images/main.connect-calendar.png';
import ConnectCalendar2x from '../../../../assets/images/main.connect-calendar@2x.png';
import ConnectCalendar3x from '../../../../assets/images/main.connect-calendar@3x.png';
import { EmptyStatePlaceholder } from '../../Common/Toolkit/Placeholder/EmptyStatePlaceholder';
import { State as UserState } from '../../../view-model/modules/core/user/userReducer';
import { sortScheduledMeetings } from '../../../../lib/meeting';
import { CardList } from '../../Common/Dashboard/CardList';
import { ScheduledMeeting, SelectableItemType } from '../../../view-model/modules/meetings/MeetingsTypes';

const messages = defineMessages({
  emptyLabel: {
    id: 'meetingHub.upcomingMeetingsList.emptyLabel',
    defaultMessage: 'No upcoming meetings'
  }
});

const shouldIncludeDaySeparator = (currentMeeting: ScheduledMeeting, previousMeeting?: ScheduledMeeting) => {
  if (previousMeeting === undefined) {
    return true;
  }

  const previousMeetingDate = previousMeeting.scheduledStartTime ? previousMeeting.scheduledStartTime : new Date();
  const currentMeetingDate = currentMeeting.scheduledStartTime ? currentMeeting.scheduledStartTime : new Date();

  const previousMeetingStartOfDay = getStartOfDay(previousMeetingDate);
  const currentMeetingStartOfDay = getStartOfDay(currentMeetingDate);

  return !isEqualDate(previousMeetingStartOfDay, currentMeetingStartOfDay);
};

interface Props {
  isOnline: boolean;
  upcomingMeetings: ReadonlyArray<ScheduledMeeting>;
  selectedId: string | null;
  selectedItemType: SelectableItemType;
  selectMeeting?: (itemType: SelectableItemType, id?: string) => void;
  user: UserState;
}

export const UpcomingMeetingsList: React.FC<Props> = (props) => {
  const { selectMeeting = () => {}, selectedId, selectedItemType } = props;
  const { formatMessage: f } = useIntl();
  const sortedUpcomingMeetings = sortScheduledMeetings(props.upcomingMeetings);

  if (!sortedUpcomingMeetings.length) {
    return (
      <EmptyStatePlaceholder
        label={f(messages.emptyLabel)}
        image={
          <img
            src={ConnectCalendar}
            alt={messages.emptyLabel.defaultMessage}
            sizes="(max-width: 767px) 320px, (max-width: 1439px) 200px, 270px"
            srcSet={`${ConnectCalendar} 394w, ${ConnectCalendar2x} 788w, ${ConnectCalendar3x} 1182w,`}
          />
        }
      />
    );
  }

  const meetingListItems: Array<React.ReactElement<any>> = [];

  sortedUpcomingMeetings.forEach((meeting, index) => {
    if (shouldIncludeDaySeparator(sortedUpcomingMeetings[index], sortedUpcomingMeetings[index - 1])) {
      meetingListItems.push(
        <MeetingsDateSeparator
          date={meeting.scheduledStartTime?.toString() || ''}
          key={`dateSeperator_${meeting.meetingId}`}
        />
      );
    }

    let isSelected = false;
    if (selectedId && selectedItemType === SelectableItemType.SCHEDULED) {
      if (meeting.meetingId === selectedId) {
        isSelected = true;
      }
    }

    meetingListItems.push(
      <li key={meeting.meetingId}>
        <SelectableCard
          onClick={() => selectMeeting(SelectableItemType.SCHEDULED, meeting.meetingId)}
          isSelected={isSelected}
          ariaLabel={meeting?.subject}
        >
          <UpcomingMeetingItem meeting={meeting} />
        </SelectableCard>
      </li>
    );
  });

  return <CardList>{meetingListItems}</CardList>;
};
