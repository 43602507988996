import { Reducer } from 'redux';
import { ActionTypes as AppActionTypes, Actions as AppActions } from './appActions';
import { getTodayDate, isEqualDate } from '../../../../../lib/date';
import featureService from '../../../../services/FeatureService';
import { displaysChat } from '../../../../services/NavService';
import { ActionTypes as SharedActionTypes, Actions as SharedActions } from '../../../sharedActions';
import { GroupActions } from '@getgo/caps-redux';

export const defaultFeatures = featureService.enabled;

type State = Readonly<{
  connectionStatus: Readonly<{
    networkOnline: boolean;
  }>;
  features: ReadonlyArray<string>;
  hasFocus: boolean;
  loaded: number;
  lastGroupQuery?: string;
  page: string;
  today: Date;
  isChatVisible?: boolean;
  updateReady?: boolean;
  showingGroupSettings?: boolean;
}>;

export const defaultState = Object.freeze({
  connectionStatus: {
    networkOnline: true
  },
  features: defaultFeatures,
  hasFocus: true,
  loaded: Date.now(),
  page: '',
  today: getTodayDate()
});

const reducer: Reducer<State, AppActions | SharedActions> = (state = defaultState, action) => {
  switch ((action as any).type) {
    case GroupActions.groupRequest.toString():
      return {
        ...state,
        lastGroupQuery: (action as any).payload.query
      };
  }

  switch (action.type) {
    case SharedActionTypes.PAGE_CHANGED:
      return {
        ...state,
        page: action.payload,
        isChatVisible: displaysChat(action.payload)
      };

    case AppActionTypes.APP_PAGE_VISIBILITY_CHANGE:
      return { ...state, hasFocus: action.payload };
    case AppActionTypes.SET_ONLINE_STATUS:
      return {
        ...state,
        connectionStatus: { ...state.connectionStatus, networkOnline: action.payload }
      };
    case AppActionTypes.APP_UPDATE_READY:
      return { ...state, updateReady: true };
    case AppActionTypes.APP_UPDATE_FEATURES:
      return { ...state, features: action.payload };
    case AppActionTypes.SET_TODAY_DATE:
      const isToday = isEqualDate(state.today, action.payload);
      if (isToday) {
        return state;
      }
      return { ...state, today: action.payload };
  }

  return state;
};

export default reducer;
