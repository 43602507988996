import { Document, HeadingLevel, Packer, Paragraph, TextRun } from 'docx';
import FileSaver from 'file-saver';
import { ChatListItem } from '../../view-model/modules/meetings/meetingsReducer';
import { IntlShape, IntlFormatters, defineMessages } from 'react-intl';
import { Session } from '../../view-model/modules/meetings/MeetingsTypes';

interface DateTimeFormat {
  startDate: string;
  endDate: string;
  startTime: string;
  expiry: string;
}

enum Colors {
  light_grey = '#8B939D'
}

enum Font {
  default = 'Calibri'
}

enum Unicode {
  midDot = '\u00b7'
}

const messages = defineMessages({
  subHeading: {
    id: 'chatsDownloadDoc.subHeading',
    defaultMessage: 'Chat'
  },
  sendTimePrefix: {
    id: 'chatsDownloadDoc.sendtimePrefix',
    defaultMessage: 'sent a chat'
  }
});

const defaultStyles = {
  heading1: {
    run: {
      size: 52,
      bold: true,
      font: {
        name: Font.default
      }
    }
  },
  heading2: {
    run: {
      size: 40,
      bold: true,
      font: {
        name: Font.default
      }
    }
  },
  heading3: {
    run: {
      size: 26,
      font: {
        name: Font.default
      }
    }
  }
};

const textStyles = {
  authorNameStyle: {
    size: 24,
    bold: true
  },
  timeStyle: {
    size: 24,
    color: Colors.light_grey
  },
  messageStyle: {
    size: 24
  }
};

const fontTextRun = (input: string, properties = {}) => {
  return new TextRun({
    size: 27,
    text: input,
    ...properties,
    font: {
      name: Font.default
    }
  });
};

export const chatsDownload = (
  chatList: ChatListItem[],
  session: Session,
  dateTimeFormat: DateTimeFormat,
  intl: IntlShape,
  formatMessage: IntlFormatters['formatMessage']
) => {
  const { subject, meetingId } = session;
  const { startTime, startDate } = dateTimeFormat;

  const doc = new Document({
    styles: {
      default: defaultStyles
    }
  });

  const renderHeaderSection = () => [
    new Paragraph({
      text: subject,
      heading: HeadingLevel.HEADING_1
    }),
    new Paragraph({
      text: `${startDate} ${Unicode.midDot} ${startTime} ${Unicode.midDot} ID: ${meetingId}`,
      heading: HeadingLevel.HEADING_3
    })
  ];

  const renderSubHeader = () => [
    new Paragraph({
      text: formatMessage(messages.subHeading),
      heading: HeadingLevel.HEADING_2,
      spacing: {
        before: 300,
        after: 200
      }
    })
  ];

  const renderChatList = () => {
    if (chatList && chatList.length) {
      return chatList.map((item: ChatListItem) => {
        const sendTime: string = intl.formatDate(new Date(item.sendTime), { hour: 'numeric', minute: 'numeric' });
        return new Paragraph({
          spacing: {
            after: 150
          },
          children: [
            fontTextRun(item.authorName, { ...textStyles['authorNameStyle'] }),
            fontTextRun(` ${formatMessage(messages.sendTimePrefix)} ${Unicode.midDot} `, {
              ...textStyles['timeStyle']
            }),
            fontTextRun(sendTime, {
              ...textStyles['timeStyle']
            }),
            fontTextRun(item.message, { ...textStyles['messageStyle'], break: 1 })
          ]
        });
      });
    }
    return [];
  };

  const renderSection = () => ({
    children: [...renderHeaderSection(), ...renderSubHeader(), ...renderChatList()]
  });

  doc.addSection(renderSection());
  try {
    Packer.toBlob(doc)
      .then((blob) => {
        FileSaver.saveAs(blob, `${subject} - Meeting Chat.docx`, { autoBom: true });
      })
      .catch(() => {
        // fail silently
      });
  } catch (e) {
    // fail silently
  }
};
