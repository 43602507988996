import sa, { SuperAgentRequest, Response as SuperagentResponse } from 'superagent';
import * as t from 'io-ts';
import config from 'config';
import LoggerService from '../app/services/LoggerService';
import { validate } from './io-ts-helpers';
import { blobToDataURL, dataUrlToBlob } from './data-urls';
import { AuthService } from '../app/services/auth';

interface Response extends SuperagentResponse {
  message?: string;
}

interface BareOptions {
  tokenType?: string;
  token?: string;
  clientName?: boolean;
  isNotJson?: boolean;
}

const validateResponse = <T>(val: any, type: t.Type<T>): T => {
  return validate(val, type, 'Response Validation failed');
};

type Options = BareOptions & { returnFullResponse?: boolean; isNotJson?: boolean };

const logger = LoggerService.create('Rest lib');

class Rest {
  private auth?: AuthService;

  setAuth(auth: AuthService) {
    this.auth = auth;
  }

  private async buildRequest(request: SuperAgentRequest, options: Options = {}): Promise<any | Response> {
    let curRequest = request.timeout(config.rest.timeout);

    if (!options.isNotJson) {
      curRequest = curRequest.set('Content-Type', 'application/json').set('Accept', 'application/json');
    }

    const authToken = this.auth?.getToken();
    const tokenType = options.tokenType || authToken?.token_type;
    const token = options.token || authToken?.access_token;
    const tokenAvailable = tokenType && token;

    if (tokenAvailable) {
      curRequest.set('Authorization', `${tokenType} ${token}`);
    }

    if (options.clientName) {
      curRequest.set('ClientName', config.name.web);
    }

    let wasAborted = false;
    curRequest.on('abort', () => {
      wasAborted = true;
    });

    try {
      const response = await curRequest;
      return options.returnFullResponse ? response : response.body;
    } catch (error) {
      if (wasAborted) {
        const abortError: any = new Error();
        abortError.code = 'ABORTED';
        throw abortError;
      } else if (error.status === 401) {
        this.auth?.retrySignIn();
        // special handling for meeting service when no token is sent
      } else if (error.status === 403 && !tokenAvailable) {
        this.auth?.retrySignIn();
      } else {
        const { method, url } = request;
        logger.error('buildRequest', 'request=', { method, url }, 'options=', options, 'error=', error);
      }
      throw error;
    }
  }

  private get(url: string, params: {} | string = {}, options?: Options) {
    return this.buildRequest(sa.get(url).query(params), options);
  }

  private post(url: string, data?: {} | string, options?: Options, params: {} | string = {}) {
    return this.buildRequest(
      sa
        .post(url)
        .query(params)
        .send(data),
      options
    );
  }

  private put(url: string, data?: {} | string, options?: Options) {
    return this.buildRequest(sa.put(url).send(data), options);
  }

  private delete(url: string, options?: Options) {
    return this.buildRequest(sa.del(url), options);
  }

  async getImage(url: string, options?: Options) {
    const res = await this.buildRequest(sa.get(url).responseType('blob'), options);
    return blobToDataURL(new Blob([res], { type: 'image/*' }));
  }

  async postImage(url: string, dataUrl: string, options: Options = {}) {
    const request = sa.post(url).attach('file', dataUrlToBlob(dataUrl));
    options.isNotJson = true;
    return this.buildRequest(request, options);
  }

  async getValidated<T>(type: t.Type<T>, url: string, params?: {} | string, options?: BareOptions): Promise<T> {
    const res = await this.get(url, params, options);
    return validateResponse(res, type);
  }

  async postValidated<T>(
    type: t.Type<T>,
    url: string,
    data?: {} | string,
    options?: BareOptions,
    params?: {} | string
  ): Promise<T> {
    const res = await this.post(url, data, options, params);
    return validateResponse(res, type);
  }

  async putValidated<T>(type: t.Type<T>, url: string, data?: {} | string, options?: BareOptions): Promise<T> {
    const res = await this.put(url, data, options);
    return validateResponse(res, type);
  }

  async deleteValidated<T>(type: t.Type<T>, url: string, options?: BareOptions): Promise<T> {
    const res = await this.delete(url, options);
    return validateResponse(res, type);
  }

  async getRaw(url: string, params?: {} | string, options?: BareOptions): Promise<Response> {
    return this.get(url, params, { ...options, returnFullResponse: true });
  }

  async putRaw(url: string, params?: {} | string, options?: BareOptions): Promise<Response> {
    return this.put(url, params, { ...options, returnFullResponse: true });
  }

  async postRaw(url: string, params?: {} | string, options?: BareOptions): Promise<Response> {
    return this.post(url, params, { ...options, returnFullResponse: true });
  }

  async deleteRaw(url: string, options?: BareOptions): Promise<Response> {
    return this.delete(url, { ...options, returnFullResponse: true });
  }

  isAborted(responseError: Error & { code?: string }) {
    return responseError.code === 'ABORTED';
  }
}

export default new Rest();
