import React from 'react';
import { formatToHtml } from '../../../../services/MessageFormatService';

interface Props {
  message: {
    text: string;
  };
}

export class TextMessageEntry extends React.Component<Props> {
  shouldComponentUpdate({ message }: Props) {
    return this.props.message.text !== message.text;
  }

  render() {
    const { text } = this.props.message;
    const html = {
      __html: formatToHtml(text)
    };
    return <span className="text-message" dangerouslySetInnerHTML={html} />;
  }
}
