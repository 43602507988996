import hash from '../../lib/hash';
import defaultIconUrl from '../../assets/images/favicon-128.png';

interface V9NotificationOptions {
  body?: string;
  icon?: string;
  tag?: string;
  id?: string;
  roomId?: string;
}

export type SwPayload =
  | {
      type: 'mention';
      senderJid: string;
      senderName: string;
      message: string;
      messageId: string;
      groupJid: string;
      groupName: string;
    }
  | {
      type: 'unread';
      senderJid: string;
      senderName: string;
      message: string;
      messageId: string;
    };

function getKey(title: string, options: V9NotificationOptions) {
  return hash(`${options.tag}-${title}-${options.id}`);
}

export class NotificationService {
  private Notification: typeof window.Notification;
  private history: {
    [key: string]: Notification;
  };
  private documentContext: Document;
  private swRegistration: null | ServiceWorkerRegistration;

  constructor(documentContext = document, instance = window.Notification, navigatorContext = window.navigator) {
    this.Notification = instance;
    this.history = {};
    this.documentContext = documentContext;

    this.swRegistration = null;
    if ('serviceWorker' in navigatorContext) {
      navigatorContext.serviceWorker.ready.then((reg) => (this.swRegistration = reg));
    }
  }

  isSupported() {
    return 'Notification' in window;
  }

  clear() {
    Object.keys(this.history).forEach((key) => {
      this.history[key].close();
      delete this.history[key];
    });
  }

  async notify(
    swPayload: SwPayload | null,
    title: string,
    options: V9NotificationOptions = {},
    clickHandler = () => {}
  ) {
    if (this.documentContext.hasFocus() && !this.documentContext.hidden) {
      return { reason: 'focus' };
    }

    if (!this.isSupported()) {
      return { reason: 'unsupported' };
    }

    if (swPayload && this.swRegistration && this.swRegistration.active) {
      const perm = await this.requestPermission();
      if (perm !== 'granted') {
        return { reason: 'denied' };
      }

      this.notifySW(swPayload);
      return { reason: 'handled' };
    }

    const key = getKey(title, options);

    if (key in this.history) {
      return this.history[key];
    }

    if (!options.icon) {
      options.icon = defaultIconUrl;
    }

    const permission = await this.requestPermission();
    if (permission !== 'granted') {
      return { reason: 'denied' };
    }

    const n = new this.Notification(title, options);
    n.onclick = () => {
      clickHandler();
      window.focus();
      n.close();
      delete this.history[key];
    };
    this.history[key] = n;
    return n;
  }

  getPermission() {
    return this.Notification.permission;
  }

  requestPermission(): Promise<NotificationPermission> {
    return new Promise((resolve) => {
      this.Notification.requestPermission((permission) => {
        resolve(permission);
      });
    });
  }

  notifySW(payload: SwPayload) {
    if (!this.swRegistration || !this.swRegistration.active) {
      return;
    }

    this.swRegistration.active.postMessage({
      type: 'notification',
      payload
    });
  }
}
