import classNames from 'classnames';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { SessionUpdates } from '../../../../view-model/modules/meetings/meetingsReducer';
import { SelectableItemType, Session } from '../../../../view-model/modules/meetings/MeetingsTypes';
import styled from '../../../../styled-components';
import { Spinner } from '../../../Common/Toolkit/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { State } from '../../../../view-model/types';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ModalDialogContentWrapper } from '../../../Common/ModalDialog/ModalDialogContentWrapper';
import { ModalDialog } from '../../../Common/ModalDialog/ModalDialog';
import { PrimaryButton } from '../../../Common/Toolkit/Button/PrimaryButton';

const messages = defineMessages({
  longMeetingTitle: {
    id: 'meetingHub.meetingDetails.longMeetingTitle',
    defaultMessage: 'Meeting title can not be longer than 100 characters.'
  },
  noMeetingTitle: {
    id: 'meetingHub.meetingDetails.noMeetingTitle',
    defaultMessage: 'Meeting title cannot be blank'
  },
  ok: {
    id: 'meetingHub.meetingDetails.ok',
    defaultMessage: 'Ok'
  },
  errorContentFirst: {
    id: 'meetingHub.meetingDetails.errorContentFirst',
    defaultMessage: 'There was an error editing your meeting title.'
  },
  errorContentSecond: {
    id: 'meetingHub.meetingDetails.errorContentSecond',
    defaultMessage: 'Please try again'
  },
  errorHeader: {
    id: 'meetingHub.meetingDetails.errorHeader',
    defaultMessage: 'Could not edit meeting title'
  }
});

export interface MeetingTitleProps {
  clearMeetingTitleError?: () => void;
  isMeetingOwner?: boolean;
  meetingTitle: string;
  meetingTitleUpdateError?: boolean;
  meetingTitleUpdatePending?: boolean;
  session: Session;
  updateMeetingTitle: (sessionId: string, updatedSession: SessionUpdates) => void;
  meetingTitleEdited: (view: string) => void;
  showMeetingTitleEditModal: () => void;
  hideMeetingTitleEditModal: () => void;
  updateMeetingTitleEditError: (error: boolean) => void;
}

const SpinnerContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
`;

const ErrorContent = styled.div`
  margin-bottom: 6px;
`;

const Actions = styled.div`
  box-sizing: border-box;
  min-height: 3em;
  padding: 20px;
  text-align: right;
`;

const ErrorMessage = styled.span`
  height: 17px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #e02d52;
  margin-top: 6px;
`;

const MeetingTitleContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  i {
    position: absolute;
    top: 10px;
    right: 6px;
    color: #e02d52;
  }

  .lengthError {
    &:focus {
      border: 1px solid #e02d52;
      box-shadow: none;
    }
  }
`;

const MeetingTitleInput = styled.input`
  border: none;
  font-size: 28px;
  font-weight: bold;
  height: 100%;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 24px;
  font-family: Lato-bold;
  white-space: nowrap;
  margin: -4px 0 5px 0;
  border-radius: 4px;
  box-sizing: border-box;

  em {
    background: #fff7d9;
    font-style: normal;
  }

  &::placeholder {
    color: rgba(37, 40, 45, 0.36);
    opacity: 1;
  }

  &:focus {
    border: 1px solid #00c2ff;
    box-shadow: 0 0 3px 2px rgba(64, 144, 255, 0.24);
    outline: none;
    padding-left: 12px;
    padding-right: 30px;
  }

  &:hover:not(:focus) {
    border: 1px solid rgba(76, 83, 93, 0.48);
    text-overflow: ellipsis;
    border-radius: 4px;
    margin: -5px 0 4px;
    padding-left: 12px;
    padding-right: 30px;
  }

  &::selection {
    background-color: #23caff;
  }
`;

export const MeetingTitle: React.FC<MeetingTitleProps> = (props) => {
  const inputBox = useRef((null as any) as HTMLInputElement);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [emptyMeetingTitle, setEmptyMeetingTitle] = useState(false);
  const [exceedMeetingTitleLength, setExceedMeetingTitleLength] = useState(false);
  const [error, setError] = useState(false);

  const updateInProcess = useSelector((state: State) => state.meetings.updatingMeetingData);
  const errorInMeetingTitleUpdate = useSelector((state: State) => state.meetings.updateMeetingDataError);
  const userView = useSelector((state: State) => state.meetings.selectedItemType);

  useEffect(() => {
    setMeetingTitle(props.meetingTitle);
  }, [props.meetingTitle]);

  useEffect(() => {
    if (errorInMeetingTitleUpdate) {
      setMeetingTitle(props.meetingTitle);
      setError(true);
      props.showMeetingTitleEditModal();
    } else {
      setError(false);
    }
  }, [errorInMeetingTitleUpdate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMeetingTitle(e.target.value);
    if (e.target.value === '') {
      setEmptyMeetingTitle(true);
    } else {
      setEmptyMeetingTitle(false);
    }

    if (e.target.value.length > 100) {
      setExceedMeetingTitleLength(true);
    } else {
      setExceedMeetingTitleLength(false);
    }
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    if (updateInProcess) {
      setIsEditing(false);
    } else {
      setIsEditing(false);
      setMeetingTitle(props.meetingTitle);
    }

    setExceedMeetingTitleLength(false);
    setEmptyMeetingTitle(false);
  };

  const handleKeyDown = (event: { key: string; target: any }) => {
    const inputValue = event.target.value.trim();
    if (
      event.key === 'Enter' &&
      inputValue.length > 0 &&
      inputValue.length <= 100 &&
      inputValue !== props.meetingTitle
    ) {
      save();
      // Set timeout allows redux to update the props first before running the blur function
      setTimeout(() => inputBox?.current?.blur(), 0);
    }

    if (event.key === 'Escape') {
      event.target.blur();
    }
  };

  const save = () => {
    if (userView === SelectableItemType.ELASTICSEARCHRESULTSELECTED) {
      props.meetingTitleEdited('Global Search View');
    } else {
      props.meetingTitleEdited('Meeting History View');
    }
    props.updateMeetingTitle(props.session.sessionId, { title: meetingTitle.trim() });
  };

  const closeModalDialog = () => {
    setError(false);
    setTimeout(() => props.hideMeetingTitleEditModal(), 500);
    props.updateMeetingTitleEditError(false);
  };

  return (
    <>
      <MeetingTitleContainer className={classNames(isEditing && 'isEditing')}>
        {error && errorInMeetingTitleUpdate && (
          <ModalDialog contentLabel="MeetingTitleEditModal" shouldCloseOnOverlayClick onClose={closeModalDialog}>
            <ModalDialogContentWrapper
              title={<FormattedMessage {...messages.errorHeader} />}
              onClose={closeModalDialog}
              testId={'meeting-error-modal'}
            >
              <ErrorContent>
                <FormattedMessage {...messages.errorContentFirst} />
              </ErrorContent>

              <ErrorContent>
                <FormattedMessage {...messages.errorContentSecond} />
              </ErrorContent>
              <Actions>
                <PrimaryButton onClick={closeModalDialog}>
                  <FormattedMessage {...messages.ok} />
                </PrimaryButton>
              </Actions>
            </ModalDialogContentWrapper>
          </ModalDialog>
        )}

        <MeetingTitleInput
          ref={inputBox}
          className={classNames(
            'editableTitle',
            !isEditing && 'editable',
            (emptyMeetingTitle || exceedMeetingTitleLength) && 'lengthError'
          )}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          type="text"
          value={meetingTitle}
          placeholder={'Meeting Title'}
          data-testid={'meeting-title-input'}
          aria-label={'meeting-title'}
        />
        {updateInProcess && (
          <SpinnerContainer>
            <Spinner width={'16px'} height={'16px'} lineWidth={'2px'} />
          </SpinnerContainer>
        )}
        {(exceedMeetingTitleLength || emptyMeetingTitle) && <i className="togo-icon togo-icon-alert togo-icon-lg" />}
      </MeetingTitleContainer>
      {emptyMeetingTitle && (
        <ErrorMessage>
          <FormattedMessage {...messages.noMeetingTitle} />
        </ErrorMessage>
      )}
      {exceedMeetingTitleLength && (
        <ErrorMessage>
          <FormattedMessage {...messages.longMeetingTitle} />
        </ErrorMessage>
      )}
    </>
  );
};
