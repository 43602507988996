import React from 'react';
import styled from '../../../styled-components';
import {
  AttendeeBucket,
  ElasticSearchPage,
  SessionFromElasticSearch
} from '../../../view-model/modules/meetings/meetingsReducer';
import { LoadMoreButtonTranscript, UnorderedList } from './TitleTab';
import { Spinner } from '../../Common/Toolkit/Spinner/Spinner';
import { AttendeeDetailsRow } from './AttendeeDetailsRow';
import { ErrorMessage, MessagePlaceHolder } from './TranscriptsTab';
export const ElasticSearchSpinner = styled((props: any) => (
  <div className={props.className}>
    <Spinner />
  </div>
))`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

interface Props {
  search: AttendeeBucket[] | [];
  openRecording: (sessionId: string) => void;
  searchText: string | undefined;
  pageInfo: ElasticSearchPage;
  fetching: boolean;
  loadMore: (attendeeName?: string) => void;
  fetchAttendeeSessions: (attendeeName?: string) => void;
  attendeeCountFlag: boolean;
  disableOnClickOutside: () => void;
}

export const AttendeesTab: React.FC<Props> = (props: Props) => {
  const attendeeList: Array<React.ReactElement<any>> = [];

  const placeholder = <ErrorMessage {...props} />;

  const handleRecording = (session: SessionFromElasticSearch) => {
    props.openRecording(session.id);
  };

  props.search.forEach((result) => {
    attendeeList.push(
      <AttendeeDetailsRow
        disableOnClickOutside={props.disableOnClickOutside}
        handleRecording={handleRecording}
        selectedKey={result.key}
        sessionList={result.sessions}
        loadMore={props.loadMore}
        key={result.key}
        fetchAttendeeSessions={props.fetchAttendeeSessions}
      />
    );
  });

  if (attendeeList.length && props.attendeeCountFlag) {
    attendeeList.push(
      <li key="loadMore">
        <LoadMoreButtonTranscript onClick={() => props.loadMore('')} isLoading={props.fetching} />
      </li>
    );
  }

  return props.fetching && !attendeeList.length ? (
    <ElasticSearchSpinner />
  ) : attendeeList.length ? (
    <UnorderedList>{attendeeList}</UnorderedList>
  ) : (
    <MessagePlaceHolder message={placeholder} />
  );
};
