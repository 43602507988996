import * as React from 'react';
import {
  usePopoverState,
  Popover as ReakitPopover,
  PopoverDisclosure,
  PopoverArrow as ReakitPopoverArrow
} from 'reakit/Popover';
import styled from '../../../styled-components';

export interface Props {
  disclosure: React.ReactElement<any>;
  hideArrow?: boolean;
  placement?:
    | 'bottom-start'
    | 'auto-start'
    | 'auto'
    | 'auto-end'
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-end'
    | 'bottom'
    | 'left-end'
    | 'left'
    | 'left-start';
  className?: string;
  ariaLabel?: string;
  children?: React.ReactNode;
  id: string; // Required because when an id is not present a random id is generated and breaks snapshots
}

const PopoverContainer = styled(ReakitPopover)`
  background-color: #fff;
  opacity: 1;
  box-shadow: 3px 3px 4px 0 rgba(57, 64, 77, 0.32);
  border-radius: 2px;
  z-index: 100;

  &:focus {
    outline: 0;
  }
`;

const PopoverArrow = styled(ReakitPopoverArrow)`
  font-size: 20px !important;
  path {
    fill: white;
  }
`;

const PopoverContent = styled.div`
  padding: 10px 0;
  font-size: 14px;
  color: #555;
  text-align: left;
`;

export interface PopoverMethods {
  hide: () => void;
}

/**
 * A generic Popover component structured and styled with the app's UI in mind.
 */
export const Popover = React.forwardRef<PopoverMethods, Props>((props, ref) => {
  const { children, hideArrow, placement = 'bottom', disclosure, className, ariaLabel, id } = props;
  const popover = usePopoverState({ placement, baseId: id });

  React.useImperativeHandle(ref, () => ({
    hide: () => {
      popover.hide();
    }
  }));

  return (
    <>
      {disclosure && (
        <PopoverDisclosure {...disclosure.props} {...popover}>
          {(disclosureProps) => React.cloneElement(disclosure, disclosureProps)}
        </PopoverDisclosure>
      )}
      <PopoverContainer {...popover} tabIndex={0} aria-label={ariaLabel}>
        {!hideArrow && <PopoverArrow {...popover} />}
        <PopoverContent className={className}>{children}</PopoverContent>
      </PopoverContainer>
    </>
  );
});
