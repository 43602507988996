import React from 'react';
import Draft from 'draft-js';
import config from 'config';
import { defaultPathPNG, emojioneList } from 'emojione';
import './Emoji.css';

/* eslint-disable @typescript-eslint/no-unused-vars */
export interface Props {
  buildEmojiUrl?: (hexCode: string, pngSize: string) => string;
  emojiSizePNG: string;
  className: string;
  entityKey?: string;
  contentState: Draft.ContentState;
  dir: any;
  offsetKey: string;
  decoratedText: string;
  useNativeArt: boolean;
  getEditorState: PluginFunctions['getEditorState'];
  setEditorState: PluginFunctions['setEditorState'];
  children: any;
  store: {
    getEditorState: PluginFunctions['getEditorState'];
    setEditorState: PluginFunctions['setEditorState'];
  };
}

export interface DefaultProps {
  buildEmojiUrl: (hexCode: string, pngSize: string) => string;
}

export class Emoji extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    buildEmojiUrl: (hexCode, pngSize) => `${defaultPathPNG}${pngSize}/${hexCode}.png`
  };

  render() {
    const {
      className,
      decoratedText,
      useNativeArt,
      children,
      emojiSizePNG,
      buildEmojiUrl,
      store,
      dir,
      entityKey,
      getEditorState,
      offsetKey,
      setEditorState,
      contentState,
      ...otherProps
    } = this.props as Props & DefaultProps;

    const entity = entityKey && contentState.getEntity(entityKey);
    const shortname = entity && entity.getData() && entity.getData().emojiShortname;
    if (!(shortname && shortname in emojioneList)) {
      if (config.env !== 'prod') {
        console.error(
          'Warning (react-message-input-field): ' + `Attempted to render emoji ${shortname} which is not supported.`
        );
      }

      return <span {...otherProps}>{children}</span>;
    }

    if (useNativeArt) {
      return (
        <span className={'message-input-emoji-native'} title={shortname} {...otherProps}>
          {children}
        </span>
      );
    }

    const unicodeForImage = emojioneList[shortname].uc_base;
    const backgroundImage = `url(${buildEmojiUrl(unicodeForImage, emojiSizePNG)})`;

    return (
      <span className={'message-input-emoji'} title={shortname} style={{ backgroundImage }} {...otherProps}>
        {children}
      </span>
    );
  }
}
