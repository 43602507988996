import { Meeting, ScheduledMeeting } from '../app/view-model/modules/meetings/MeetingsTypes';

export const sortScheduledMeetings = (meetings: ReadonlyArray<ScheduledMeeting>) => {
  const sortedMeetings = [...meetings];
  return sortedMeetings.sort((a, b) => {
    if (a.inSession === b.inSession) {
      return new Date(a.scheduledStartTime).getTime() - new Date(b.scheduledStartTime).getTime();
    }
    return a.inSession ? -1 : 1;
  });
};

export const sortRecurringMeetings = (meetings: ReadonlyArray<Meeting>) => {
  const sortedMeetings = [...meetings];
  return sortedMeetings.sort((a, b) => {
    if (a.inSession === b.inSession) {
      return a.subject.localeCompare(b.subject);
    }
    return a.inSession ? -1 : 1;
  });
};

export const getNumberOfRunningMeetings = (sortedMeetings: ReadonlyArray<Meeting>) => {
  let i = 0;
  while (sortedMeetings[i]?.inSession) {
    i++;
  }
  return i;
};
