import * as t from 'io-ts';
import { tMeeting, tOrganizer, tPastSession, tProfile } from '../../../../models/Meeting';

export type BasicMeeting = t.TypeOf<typeof tMeeting>;
export type Profile = t.TypeOf<typeof tProfile>;
export type Organizer = t.TypeOf<typeof tOrganizer>;
export type ImpromptuMeeting = CoreMeeting;
export type RecurringMeeting = CoreMeeting;
export type Meeting = ScheduledMeeting | ImpromptuMeeting | RecurringMeeting;

export type Session = t.TypeOf<typeof tPastSession>;

type CoreMeeting = BasicMeeting & {
  inSession: boolean;
  isOrganizer?: boolean;
};

export type ScheduledMeeting = CoreMeeting & {
  scheduledStartTime: string;
  scheduledEndTime: string;
};

export enum SelectableItemType {
  SCHEDULED = 'scheduled',
  RECURRING = 'recurring',
  PERSONAL = 'personal',
  IMPROMPTU = 'impromptu',
  SESSION = 'session',
  ELASTICSEARCH = 'elasticSearch',
  NONE = 'none',
  ELASTICSEARCHRESULTSELECTED = 'elasticSearchResultSelected'
}

export const isSession = (meeting: Meeting | Session): meeting is Session => {
  return (meeting as Session).sessionId !== undefined;
};

export const isScheduledMeeting = (meeting: Meeting): meeting is ScheduledMeeting => {
  return meeting?.meetingType === 'scheduled';
};

export const isRecurringMeeting = (meeting: Meeting): meeting is RecurringMeeting => {
  return meeting?.meetingType === 'recurring';
};

export const isImpromptuMeeting = (meeting: Meeting): meeting is ImpromptuMeeting => {
  return meeting?.meetingType === 'impromptu';
};
