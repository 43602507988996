import React, { useState, useEffect } from 'react';
import * as clipboard from 'clipboard-polyfill';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTranscriptShare } from '../../../view-model/modules/meetings/meetingsActions';
import { State } from '../../../view-model/types';
import styled from '../../../../app/styled-components';
import { Spinner } from '../../Common/Toolkit/Spinner/Spinner';
import { themeGet } from '@styled-system/theme-get';
import device from '../../Common/Toolkit/devices';
import { Icon } from '../../Common/Toolkit/Icon/Icon';
import { Icons } from '../../Common/Toolkit/ICONS';
import { IconButton } from '../MeetingComponents';
import { showToastNotification } from '../../../view-model/modules/core/notifications/notificationsActions';
import { ShareRecordingLink, ShareRecordingLinkError } from '../../Notifications/Notifications';
import { Session } from 'src/app/view-model/modules/meetings/MeetingsTypes';

const CustomButtonWrapper = styled.div`
  margin-top: 0px 3px 3px 3px;
  display: flex;

  .button__toolTip {
    max-width: 210;
  }

  @media ${device.tablet} {
    flex: 1 1 auto;
  }
`;

const StyledIconButton = styled(IconButton)<{ expired: boolean | number }>`
  &:disabled {
    color: ${(props) => (props.expired ? 'rgba(37, 40, 45, 0.36)' : `${themeGet('colors.primary')}`)};
  }

  :hover {
    background-color: ${(props) => (props.expired ? 'transparent' : 'rgba(128, 181, 255, 0.12)')} !important;
  }
`;

interface Props {
  recordingIsProcessing: boolean | undefined;
  copyStandalonePlayerUrl: (meetingId: string, recordingId: string) => void;
  session: Session;
  deleteInProcess?: boolean;
}

const messages = defineMessages({
  copyToClipboard: {
    id: 'meetingDashboard.meetingHistory.copyToClipboard',
    defaultMessage: 'Copy to Clipboard'
  },
  title: {
    id: 'meetingDashboard.meetingHistory.title',
    defaultMessage: 'Share meeting recording'
  },
  titleHint: {
    id: 'meetingDashboard.meetingHistory.titleHint',
    defaultMessage: 'Copy & paste this info.'
  },
  close: {
    id: 'meetingDashboard.meetingHistory.close',
    defaultMessage: 'Close'
  },
  successMsg: {
    id: 'meetingDashboard.meetingHistory.successMsg',
    defaultMessage: 'Copied!'
  },
  errorMsg: {
    id: 'meetingDashboard.meetingHistory.errorMsg',
    defaultMessage: 'Oops, there was a problem copying your meeting recording info. Try copying it manually instead.'
  },
  ok: {
    id: 'meetingDashboard.meetingHistory.ok',
    defaultMessage: 'OK'
  },
  toggleSharing: {
    id: 'meetingDashboard.meetingHistory.toggleSharing',
    defaultMessage: 'Sharing'
  },
  share: {
    id: 'meetingHub.meetingHistory.share',
    defaultMessage: 'share'
  },
  contentCopied: {
    id: 'meetingHub.meetingHistory.contentCopy',
    defaultMessage: 'Content URL Copied'
  },
  contentCopy: {
    id: 'meetingHub.meetingHistory.contentCopied',
    defaultMessage: 'Copy content URL'
  },
  contentSharingDisabled: {
    id: 'meetingHub.meetingHistory.contentSharingDisabled',
    defaultMessage: 'Sharing is not enabled. To enable, please open content and change settings on share panel.'
  }
});

export const MeetingRecordingShare: React.FC<Props> = (props) => {
  const [fetching, setFetching] = useState(false);
  const [standalonePlayerUrl, setStandalonePlayerUrl] = useState('');
  const dispatch = useDispatch();
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const shareTranscript = useSelector(
    (state: State) => state.meetings?.transcriptSharesById?.[props.session.sessionId]
  );

  useEffect(() => {
    setFetching(true);
    resetFetching();
    dispatch(fetchTranscriptShare(props.session.sessionId));
  }, [props.session.sessionId]);

  const resetFetching = () => {
    setTimeout(() => setFetching(false), 5000);
  };

  useEffect(() => {
    if (shareTranscript) {
      setFetching(false);
      setStandalonePlayerUrl(shareTranscript.url);
    }
  }, [shareTranscript?.isPublic, shareTranscript?.url, shareTranscript?.error]);

  const onCopyToClipboard = () => {
    const recordingId = props.session.recording?.recordingId || '';
    props.copyStandalonePlayerUrl(props.session.meetingId, recordingId);
    const dt = new clipboard.DT();
    dt.setData('text/plain', standalonePlayerUrl);
    clipboard
      .write(dt)
      .then(() => {
        dispatch(showToastNotification(ShareRecordingLink(), { hideOkButton: true }));
      })
      .catch(() => {
        dispatch(showToastNotification(ShareRecordingLinkError(), { hideOkButton: true, type: 'warning' }));
      });
  };
  const shareLinkDisabled =
    !shareTranscript?.isPublic ||
    shareTranscript?.linkExpired ||
    !!props.recordingIsProcessing ||
    props.deleteInProcess;

  return (
    <CustomButtonWrapper
      data-class="button__toolTip"
      data-testid="click-copy-share-button"
      data-tip={
        !props.recordingIsProcessing
          ? shareLinkDisabled
            ? f(messages.contentSharingDisabled)
            : f(messages.contentCopy)
          : null
      }
    >
      <StyledIconButton
        expired={shareLinkDisabled ? 1 : 0}
        onClick={onCopyToClipboard}
        disabled={shareLinkDisabled}
        data-testid="share-button"
        icon={
          fetching ? (
            <Spinner width={'14px'} height={'14px'} lineWidth={'2px'} />
          ) : (
            <Icon color={shareLinkDisabled ? '#9aa8bb' : '#006aff'} icon={Icons.share} size={30} />
          )
        }
      >
        <FormattedMessage {...messages.share} />
      </StyledIconButton>
    </CustomButtonWrapper>
  );
};
