import {
  ErrorMessage,
  FilesMessage,
  MeetNowCSSSMessage,
  MeetNowInvitationMessage,
  MessagesEntity
} from '@getgo/caps-redux';
import { keyBy } from 'lodash';
import { getStartOfDay, isEqualDate } from '../../lib/date';
import { AnyTODO, User } from '../../types/pulse-web';
import { getFullName } from '../../lib/person';

export function hydrateGroupChatNames(messages: MessagesEntity[], contact: AnyTODO = {}) {
  let index: { [jid: string]: AnyTODO } = {};
  if (contact.isGroup) {
    index = keyBy(contact.members.concat(contact.oldMembers), 'jid');
  }

  return messages.reduce((prev: MessagesEntity[], m: MessagesEntity) => {
    if (m.type === 'chat') {
      prev.push(m);
      return prev;
    }

    const senderJid = m.from;
    const senderContact = index[senderJid] || {};

    prev.push(
      Object.assign({}, m, {
        from: senderJid,
        fromName: senderContact.name || senderJid,
        fromAvatarUrl: senderContact.avatarUrl
      })
    );

    return prev;
  }, []);
}

export function isMeetNowInvitationMessage(msg: MessagesEntity): msg is MeetNowInvitationMessage {
  return !!(
    msg.invitation &&
    msg.invitation.type === 'meetnow' &&
    msg.invitation.action === 'initiate' &&
    msg.invitation.meetnow &&
    msg.invitation.meetnow.href
  );
}

export function isErrorMessage(msg: MessagesEntity): msg is ErrorMessage {
  return Object.prototype.hasOwnProperty.call(msg, 'error') && (msg as ErrorMessage).error !== undefined;
}

export function isMeetNowCSSSMessage(msg: MessagesEntity): msg is MeetNowCSSSMessage {
  return !!msg.meeting && msg.meeting.xmlns === 'urn:xmpp:getgo:meeting:0';
}

export function shouldSquashMessage(msg: MessagesEntity) {
  return !!(isMeetNowInvitationMessage(msg) || isMeetNowCSSSMessage(msg));
}

export function isFilesMessage(msg: MessagesEntity): msg is FilesMessage {
  return msg && Object.prototype.hasOwnProperty.call(msg, 'files');
}

export function isPreviewableFile(file: { name: string }) {
  return !!file && /\.(png|jpe?g|gif|bmp|pdf|docx?|psd|pptx?|xlsx?|odp|odt|tiff|ai|svg|txt|html)$/i.test(file.name);
}

export function shouldIncludeDaySeparator(msg: MessagesEntity, prevMsg: MessagesEntity) {
  // Fallback: If the prev message does not have a date, use "now".
  const msgDate = msg?.date ? msg.date : new Date();
  const prevMsgDate = prevMsg?.date ? prevMsg.date : new Date();

  const prevStartOfDay = getStartOfDay(prevMsgDate);
  const curStartOfDay = getStartOfDay(msgDate);
  return !isEqualDate(curStartOfDay, prevStartOfDay);
}

export function computeMsgProps(msg: MessagesEntity, prevMsg: MessagesEntity | undefined, user: User, contact: any) {
  const isMine = user.jid === msg.from;
  const dateDiff = (m1: MessagesEntity, m2: MessagesEntity) =>
    new Date(m1.date).getTime() - new Date(m2.date).getTime();
  const isSameSender = (m1: MessagesEntity, m2: MessagesEntity) =>
    m1.type === 'groupchat' ? m1.fromName === m2.fromName : m1.from === m2.from;

  const threshold = 10 * 60 * 1000;
  const fromName = msg.from === msg.fromName || !msg.fromName ? null : msg.fromName || msg.from;
  const squash =
    !!prevMsg &&
    isSameSender(prevMsg, msg) &&
    !shouldSquashMessage(prevMsg) &&
    !shouldSquashMessage(msg) &&
    dateDiff(msg, prevMsg) < threshold;
  const name = isMine ? getFullName(user) : fromName;
  const status = msg.status;
  let avatarUrl;

  if (msg.type === 'groupchat') {
    avatarUrl = isMine ? user.avatarUrl : msg.fromAvatarUrl;
  } else {
    avatarUrl = isMine ? user.avatarUrl : contact.avatarUrl;
  }

  let isAnExternalUser = false;
  if (!isMine) {
    if (contact.isGroup) {
      const fromMember = contact.members.find((member: AnyTODO) => member.jid === msg.from);
      isAnExternalUser = fromMember && fromMember.isExternal;
    } else if (msg.from === contact.jid) {
      isAnExternalUser = contact.isExternal;
    }
  }

  return {
    isMine,
    message: msg,
    squash,
    name,
    status,
    avatarUrl,
    isMessageFromExternalUser: isAnExternalUser
  };
}
