import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { ErrorPage } from '../../Common/ErrorPages/ErrorPage';
import config from 'config';
import { messages as additionalItemsMessages } from '../AppMenu/AdditionalItems/AdditionalItems';

const messages = defineMessages({
  disabledChatTitle: {
    id: 'entitlement.disabledChatTitle',
    defaultMessage: 'Business Messaging is not enabled'
  },
  disabledChatText: {
    id: 'entitlement.disabledChatText',
    defaultMessage: 'Please contact your GoToMeeting account administrator to enable this feature'
  },
  noLicenseTitle: {
    id: 'entitlement.noLicenseTitle',
    defaultMessage: "You don't have a GoToMeeting License"
  },
  noLicenseText: {
    id: 'entitlement.noLicenseText',
    defaultMessage: 'Please contact your GoToMeeting account administrator to assign you a seat'
  },
  logout: {
    id: 'entitlement.logout',
    defaultMessage: 'Sign Out'
  }
});

interface Props {
  logout: () => void;
  hasLicense: boolean;
}

export const Entitlement: React.FC<Props> = ({ logout = () => {}, hasLicense }) => {
  const { formatMessage: f } = useIntl();

  const header = !hasLicense ? messages.noLicenseTitle : messages.disabledChatTitle;
  const text = !hasLicense ? messages.noLicenseText : messages.disabledChatText;
  const myAccountButton = {
    onClick: () => {
      window.location.replace(config.externalLinks.secureServiceMyAccount);
    },
    label: f(additionalItemsMessages.myAccountText)
  };
  const signOutButton = { onClick: logout, label: f(messages.logout) };

  return <ErrorPage title={f(header)} description={f(text)} button={myAccountButton} secondaryButton={signOutButton} />;
};
