import styled from '../../../../styled-components';
import device from '../devices';

interface Props {
  disabled?: boolean;
  hideOnMobile?: boolean;
}

export const DisableStateBox = styled.div.attrs<Props>(
  (props) =>
    props.disabled && {
      'aria-disabled': true
    }
)<Props>`
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
    filter: grayscale(100%);
  `}
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: stretch;

  ${(props) =>
    props.hideOnMobile &&
    props.disabled &&
    `
    @media ${device.mobile} {
      display: none;
    }
  `}
`;
