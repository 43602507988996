import * as React from 'react';
import { ModalDialog } from '../../Common/ModalDialog/ModalDialog';
import { MeetingInSession, Props as MeetingInSessionProps } from './MeetingInSession';

type Props = MeetingInSessionProps & {
  hideMeetingInSessionError: () => void;
  fetchMyMeetings: () => void;
};

export class MeetingInSessionModal extends React.Component<Props> {
  private fetchMyMeetingsAndHide = () => {
    this.props.fetchMyMeetings();
    this.props.hideMeetingInSessionError();
  };

  render() {
    return (
      <ModalDialog contentLabel="MeetingInSessionModal" onClose={this.fetchMyMeetingsAndHide}>
        <MeetingInSession {...this.props} />
      </ModalDialog>
    );
  }
}
