export interface ProcessedMeetingId {
  g2mmUrlPrefix?: string;
  unifiedLaunchUrlPrefix?: string;
  meetingId?: string;
  formattedMeetingId?: string;
  profileName?: string;
  roomName?: string;
  completeUrl?: string;
  completeUrlWithoutHttp?: string;
}

export function cleanValueForInputField(value: string, g2mmUrlPrefix?: string, unifiedLaunchUrlPrefix?: string) {
  let cleanInput = value.trim().replace(/^(https?:\/\/)?(www\.)?/gi, '');
  if (g2mmUrlPrefix && cleanInput.substr(0, g2mmUrlPrefix.length) === g2mmUrlPrefix) {
    cleanInput = cleanInput.substr(g2mmUrlPrefix.length);
  } else if (unifiedLaunchUrlPrefix && cleanInput.substr(0, unifiedLaunchUrlPrefix.length) === unifiedLaunchUrlPrefix) {
    cleanInput = cleanInput.substr(unifiedLaunchUrlPrefix.length);
  }

  return (
    cleanInput
      .replace(/[^\u00C0-\u1FFF\u2C00-\uD7FF\w0-9_\/-]/g, '') // remove invalid chars
      .replace(/^\/*(.*)/, '$1') // don't start with slash
      // TODO: .replace(/(?<=\/.*)\//g, ''); positive lookbehind is sadly not supported by our old electron version
      .replace('/', '#')
      .replace(/\//g, '')
      .replace('#', '/')
  );
}

export function formatForInputField(
  value: string,
  g2mmUrlPrefix?: string,
  unifiedLaunchUrlPrefix?: string,
  showPrefix: boolean = false
) {
  const cleanInput = cleanValueForInputField(value, g2mmUrlPrefix, unifiedLaunchUrlPrefix);

  // add "–" chars. ATTENTION: this aren't minus signs! U+2013
  let formattedValue = cleanInput
    .replace(/^(\d{3})(\d{3})(\d{1,3})$/, '$1–$2–$3')
    .replace(/^(\d{3})(\d{1,3})$/, '$1–$2');

  if (!/^\d{1,9}$/.test(cleanInput) && unifiedLaunchUrlPrefix && cleanInput.length) {
    formattedValue = `${showPrefix ? unifiedLaunchUrlPrefix : ''}${formattedValue}`;
  }
  return formattedValue;
}
