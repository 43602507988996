import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { ModalDialogContentWrapper } from '../../Common/ModalDialog/ModalDialogContentWrapper';
import { PrimaryButton } from '../../Common/Toolkit/Button/PrimaryButton';
import styled from '../../../styled-components';

const ButtonBarActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export interface Props {
  action: 'edit' | 'delete';
  hideMeetingInSessionError: () => void;
  fetchMyMeetings: () => void;
}

const messages = defineMessages({
  information: {
    id: 'meetingDashboard.meetingInSession.information',
    defaultMessage: 'The meeting is currently in session.'
  },
  ok: {
    id: 'meetingDashboard.meetingInSession.ok',
    defaultMessage: 'OK'
  },
  start: {
    id: 'meetingDashboard.meetingInSession.start',
    defaultMessage: 'Start'
  },
  edit: {
    id: 'meetingDashboard.meetingInSession.edit',
    defaultMessage: 'Edit'
  },
  delete: {
    id: 'meetingDashboard.meetingInSession.delete',
    defaultMessage: 'Delete'
  },
  editOperationProhibited: {
    id: 'meetingDashboard.meetingInSession.editOperationProhibited',
    defaultMessage: 'You cannot edit the meeting right now. Please try again later.'
  },
  deleteOperationProhibited: {
    id: 'meetingDashboard.meetingInSession.deleteOperationProhibited',
    defaultMessage: 'You cannot delete the meeting right now. Please try again later.'
  }
});

export const MeetingInSession: React.FC<Props> = ({ fetchMyMeetings, hideMeetingInSessionError, action }) => {
  const fetchMyMeetingsAndHide = () => {
    fetchMyMeetings();
    hideMeetingInSessionError();
  };

  return (
    <ModalDialogContentWrapper title={<FormattedMessage {...messages.information} />} showClose={false}>
      {action === 'edit' ? (
        <FormattedMessage {...messages.editOperationProhibited} />
      ) : action === 'delete' ? (
        <FormattedMessage {...messages.deleteOperationProhibited} />
      ) : null}
      <ButtonBarActions>
        <PrimaryButton onClick={fetchMyMeetingsAndHide} data-testid="button-ok">
          <FormattedMessage {...messages.ok} />
        </PrimaryButton>
      </ButtonBarActions>
    </ModalDialogContentWrapper>
  );
};
