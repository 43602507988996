import * as React from 'react';
import { Avatar, Props as AvatarProps } from './Avatar/Avatar';
import { OnlineStatus, Props as OnlineStatusProps } from './OnlineStatus/OnlineStatus';
import styled from '../../styled-components';

const PositionedOnlineStatus = styled(OnlineStatus)`
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
`;

interface Props extends AvatarProps, OnlineStatusProps {
  chatEnabled: boolean;
}

export const AvatarOnlineStatus: React.FC<Props> = (props) => {
  return <Avatar {...props}>{props.chatEnabled ? <PositionedOnlineStatus {...props} /> : null}</Avatar>;
};
