import * as React from 'react';
import { defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { isHoverSupported } from '../../../services/ClientService';
import status from '../../../../lib/availabilityStatuses';
import styled from '../../../styled-components';

export interface Props {
  isExternal: boolean;
  userStatus: 'available' | 'chat' | 'dnd' | 'away' | 'offline';
  className?: string;
}

const messages = defineMessages({
  statusAvailable: {
    id: 'common.onlineStatus.available',
    defaultMessage: 'Available'
  },
  statusDnd: {
    id: 'common.onlineStatus.dnd',
    defaultMessage: 'Hard at work'
  },
  statusAway: {
    id: 'common.onlineStatus.away',
    defaultMessage: 'Away'
  },
  statusOffline: {
    id: 'common.onlineStatus.offline',
    defaultMessage: 'Offline'
  },
  externalMember: {
    id: 'common.onlineStatus.externalMember',
    defaultMessage: 'External Member'
  }
});

export const statusColorMapping = {
  available: '#01B17B',
  chat: '#01B17B',
  dnd: '#cc2a0b',
  away: '#fc9402',
  offline: '#CACED3'
};

const OnlineStatusLayout: React.FC<Props & WrappedComponentProps> = ({ isExternal, userStatus, intl, className }) => {
  const { formatMessage } = intl;

  const getOnlineStatusText = () => {
    switch (userStatus) {
      case status.available:
        return formatMessage(messages.statusAvailable);
      case status.chat:
        return formatMessage(messages.statusAvailable);
      case status.dnd:
        return formatMessage(messages.statusDnd);
      case status.away:
        return formatMessage(messages.statusAway);
      default:
        return formatMessage(messages.statusOffline);
    }
  };

  const getDisplayStatus = () => {
    return isExternal ? `${getOnlineStatusText()} / ${formatMessage(messages.externalMember)}` : getOnlineStatusText();
  };

  return (
    <span
      className={className}
      data-delay-show="500"
      data-place="right"
      data-tip={isHoverSupported ? getDisplayStatus() : null}
      data-testid="online-status"
    />
  );
};

const OnlineStatusLayoutWithIntl = injectIntl(OnlineStatusLayout);

export const OnlineStatus = styled(OnlineStatusLayoutWithIntl)<Props>`
  box-sizing: border-box;
  display: inline-block;
  width: 12px;
  height: 12px;
  line-height: 10px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isExternal || props.userStatus === 'offline' ? 'transparent' : statusColorMapping[props.userStatus]};
  border: ${(props) =>
    props.isExternal || props.userStatus === 'offline' ? `solid 2px ${statusColorMapping[props.userStatus]}` : null};
`;
