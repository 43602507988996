import { initialize } from 'launchdarkly-js-client-sdk';
import localforage from 'localforage';
import * as t from 'io-ts';
import config from 'config';
import { validate } from '../../lib/io-ts-helpers';
import LoggerService from './LoggerService';
import { FeatureUseData, OsUserData } from '../../lib/user-data-utility';

// all flags are optional, so they can be removed from LD without causing errors
// note: we can only use boolean flags due to @getgo/featureflip limitations
const TFlags = t.partial({
  'meeting-hub-enabled': t.boolean,
  'show-scheduler-interstitial': t.boolean,
  'show-live-diagnostics-button': t.boolean,
  'show-discover-menu-button': t.boolean,
  'show-elastic-search-view': t.boolean,
  'show-elastic-search-account': t.boolean,
  'meeting-title-edit-enabled': t.boolean,
  'hub-branding-account-themes-enabled': t.boolean
});

export type LaunchDarklyFlags = t.TypeOf<typeof TFlags>;

const logger = LoggerService.create('LaunchDarklyService');

const storage = localforage.createInstance({
  driver: localforage.LOCALSTORAGE,
  name: '@@LaunchDarkly'
});

export const initLaunchDarklyService = async ({
  userKey,
  userEmail,
  userData,
  onChange
}: {
  userKey: string;
  userEmail: string;
  userData: { accountKey: string; g2m_locale: string } & FeatureUseData & OsUserData;
  onChange: (flags: LaunchDarklyFlags) => void;
}): Promise<LaunchDarklyFlags | null> => {
  // use custom localStorage persistence via localForage instead of the one provided by Launch Darkly client
  // otherwise, the user would have to reload two times to get latest flags, or we would have to use a
  // on('change') listener, which also enables real-time streaming of feature flags
  const ldClient = initialize(config.launchDarkly.clientSideId, {
    key: userKey,
    email: userEmail,
    privateAttributeNames: ['email'],
    custom: {
      ...userData
    }
  });

  ldClient.on('ready', async () => {
    const allFlags = ldClient.allFlags();
    let validatedFlags: LaunchDarklyFlags;
    try {
      validatedFlags = validate(allFlags, TFlags, 'Launch Darkly flag validation failed');
    } catch (err) {
      logger.error('onReady', 'err=', err);
      throw err;
    }
    await storage.setItem('flags', validatedFlags);
    onChange(validatedFlags);
  });

  return storage.getItem<LaunchDarklyFlags>('flags');
};
