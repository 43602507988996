import React from 'react';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import { isHoverSupported } from '../../../services/ClientService';
import './GoToMeeting.css';
import { Contact } from '@getgo/caps-redux';
import { State } from '../../../view-model/types';

const messages = defineMessages({
  g2mMeetNow: {
    id: 'goToMeeting.g2mmeetnow',
    defaultMessage: 'Meet Now'
  },
  g2mStartMeeting: {
    id: 'goToMeeting.g2mstartmeeting',
    defaultMessage: 'Start GoToMeeting'
  },
  g2mOfflineTooltip: {
    id: 'goToMeeting.g2mOfflineTooltip',
    defaultMessage: 'Contact is offline'
  }
});

export enum ButtonTypes {
  MEET_NOW_BUTTON = 'MEET_NOW_BUTTON',
  START_GOTOMEETING_BUTTON = 'START_GOTOMEETING_BUTTON'
}

interface Props {
  app: State['core']['app'];
  buttonType: string;
  contact: Contact;
  isOnline?: boolean;
  meeting?: {
    enabled: boolean;
  };
  onMeetNow: (contact: Contact) => void;
}

const GoToMeeting: React.FC<Props & WrappedComponentProps> = ({
  intl,
  app,
  buttonType,
  contact,
  isOnline = true,
  meeting = { enabled: true },
  onMeetNow = () => {}
}) => {
  const { formatMessage } = intl;
  const isGotomeetingEnabled = app.features.includes('gotomeeting');

  if (!isGotomeetingEnabled) {
    return null;
  }

  const g2mButtonDisabled = (meeting.enabled && !isOnline) || contact.status === 'offline';

  let className = 'btn meet-now-button';
  let text = (
    <span className="meet-now-button-text">
      <FormattedMessage {...messages.g2mMeetNow} />
    </span>
  );
  const icon = <i className="togo-icon togo-icon-gotomeeting togo-icon-lg" />;

  if (buttonType === ButtonTypes.START_GOTOMEETING_BUTTON) {
    className = 'btn start-g2m-button';
    text = <FormattedMessage {...messages.g2mStartMeeting} />;
  }

  const gotomeetingButton = (
    <button className={className} onClick={onMeetNow.bind(null, contact)} disabled={g2mButtonDisabled}>
      {icon}
      {text}
    </button>
  );

  const content =
    contact.status === 'offline' && buttonType === ButtonTypes.MEET_NOW_BUTTON ? (
      <span
        className="btnContainer"
        data-tip={isHoverSupported ? formatMessage(messages.g2mOfflineTooltip) : null}
        data-place="left"
      >
        {gotomeetingButton}
      </span>
    ) : (
      gotomeetingButton
    );
  return content;
};

export default injectIntl(GoToMeeting);
