import { RosterActions, Selectors } from '@getgo/caps-redux';
import { connect } from 'react-redux';
import { Rooms } from '../components/Chat/Rooms/Rooms';
import { selectNextContactWithHistory } from '../view-model/capsActions';
import { Dispatch, State } from '../view-model/types';

const mapStateToProps = (state: State) => {
  const unreadPerRoom = Selectors.getUnreadMessageCounts(state);

  const connectionStatus = Selectors.getConnectionStatus(state).xmppConnected;
  const { networkOnline } = state.core.app.connectionStatus;

  return {
    isOnline: networkOnline && connectionStatus,
    isRosterLoaded: state.messaging.contacts.initialized,
    rooms: Selectors.getContacts(state).items,
    unreadPerRoom
  };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: { onSelect: (jid?: string) => void }) => {
  return {
    onRemove: (jid: string, selected: boolean) => {
      if (selected) {
        dispatch(selectNextContactWithHistory(jid));
      }
      dispatch(RosterActions.removeContact(jid));
    },
    onSelect: (jid: string) => {
      if (typeof ownProps.onSelect === 'function') {
        ownProps.onSelect(jid);
      }
    }
  };
};

const ContactsContainer = connect(mapStateToProps, mapDispatchToProps)(Rooms);

export default ContactsContainer;
