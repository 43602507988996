import styled from '../../../../styled-components';

interface Props {
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'stretch';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  alignSelf?: 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  display?: 'block' | 'inline-block' | 'inline' | 'flex' | 'inline-flex';
  flex?: string;
  flexBasis?: number | string;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  flexGrow?: number;
  flexShrink?: number;
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  height?: number;
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
  margin?: number | string;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  order?: number;
  overflow?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
  overflowX?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
  overflowY?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'initial' | 'inherit';
  padding?: number;
  width?: number;
}

export const Box = styled.div<Props>`
  display: ${(props) => props.display || 'flex'};
  margin: ${(props) =>
    props.margin !== undefined ? (typeof props.margin === 'number' ? props.margin + 'px' : props.margin) : '0px'};
  padding: ${(props) => (props.padding !== undefined ? props.padding : 0)}px;
  width: ${(props) => (props.width ? props.width + 'px' : '')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + 'px' : '')};
  min-width: ${(props) => (props.minWidth ? props.minWidth + 'px' : '')};
  height: ${(props) => (props.height ? props.height + 'px' : '')};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight + 'px' : '')};
  flex-direction: ${(props) => props.flexDirection};
  flex-wrap: ${(props) => props.flexWrap};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  align-content: ${(props) => props.alignContent};
  order: ${(props) => props.order};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
  flex-basis: ${(props) => props.flexBasis};
  flex: ${(props) => props.flex};
  align-self: ${(props) => props.alignSelf};
  overflow: ${(props) => props.overflow};
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
`;

export const Item = styled(Box)`
  display: block;
`;
