/**
 * Normalizes a url making sure the right amount of slashes are included
 * @param {string} url
 */
export const normalize = (url: string) =>
  url
    .replace(/:\//g, '://')
    .replace(/([^:\s\%\3\A])\/+/g, '$1/')
    .replace(/\/(\?|&|#[^!])/g, '$1');

/**
 * Creates a url from the parameters
 * @param {strings} parts all parts to be joined
 */
export const join = (...parts: string[]) => normalize(parts.join('/'));

/**
 * Creates the get param part of an uri from a object containing strings
 * @param object
 */
export const encodeObjectIntoURI = (object: { [s: string]: string | undefined }) => {
  const args = [];
  for (const it in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(it)) {
      const value = object[it];
      if (value !== undefined) {
        args.push(`${it}=${encodeURIComponent(value)}`);
      }
    }
  }
  return args.join('&');
};

export const removeProtocol = (url: string) => {
  const parts = url.split('://');
  return parts.length === 1 ? parts[0] : parts[1];
};

export const removeProtocolAndWww = (url: string) => {
  const urlWithoutProtocol = removeProtocol(url);
  const parts = urlWithoutProtocol.split('www.');
  return parts.length === 1 ? parts[0] : parts[1];
};

export const isURLSameDomainAsWindow = (url: string) => {
  try {
    const urlHostname = new URL(url).hostname;
    const regex = new RegExp(urlHostname, 'i');

    return regex.test(window.location.hostname);
  } catch (e) {
    return false;
  }
};
