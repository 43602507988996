import styled from '../../../../styled-components';

interface Props {
  centered?: boolean;
  width?: string;
  height?: string;
  lineWidth?: string;
}

export const Spinner = styled('div').attrs({ 'data-testid': 'spinner', className: 'spinnerIcon' })<Props>`
  border: ${(props) => `${props.lineWidth || '5px'} solid rgba(218, 221, 224, 0.4)`};
  border-top: ${(props) => `${props.lineWidth || '5px'} solid #dadde0`};
  border-radius: 50%;
  width: ${(props) => props.width || '50px'};
  height: ${(props) => props.height || '50px'};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: ${(props) => (props.centered ? 'translate(-50%, -50%) ' : '')} rotate(0deg);
    }
    100% {
      transform: ${(props) => (props.centered ? 'translate(-50%, -50%) ' : '')} rotate(360deg);
    }
  }
`;

export const CenteredSpinner = styled(Spinner)`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 99;

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
