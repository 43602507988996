const config: EnvConfig = {
  env: 'development',
  webComponentsUrl: 'https://pulseed.col.test.expertcity.com/ios/',
  name: {
    web: 'pulse-web'
  },
  externalLinks: {
    globalUrl: 'https://globaled1.g2m.test.expertcity.com',
    g2mCreateProfile: 'https://globaled1.g2m.test.expertcity.com/#setup',
    g2mTranscripts: 'https://transcriptsed.g2m.test.expertcity.com',
    goto: 'https://link.goto.com/family',
    g2a: {
      urlFrontEnd: 'https://start.dev-gotoassist.com',
      urlMarketing: 'https://www.gotoassist.com'
    },
    g2t: {
      urlFrontEnd: 'https://globaled1.g2t.test.expertcity.com',
      urlMarketing: 'https://www.gototraining.com'
    },
    g2w: {
      urlFrontEnd: 'https://globaled1.g2w.test.expertcity.com',
      urlMarketing: 'https://www.gotowebinar.com'
    },
    ov: {
      urlFrontEnd: 'https://globaled1.openvoice.com',
      urlMarketing: 'http://www.openvoice.com'
    },
    adminCenter: 'https://admined1.lmiinc.test.expertcity.com',
    g2mm: 'https://meet.ed.goto.com',
    unifiedLaunchUrl: 'https://meet.ed.goto.com',
    termsOfService: 'https://link.goto.com/tos',
    privacyPolicy: 'https://link.goto.com/privacy-policy',
    plansPricing: 'https://www.gotomeeting.com/meeting/pricing',
    support: 'https://link.gotomeeting.com/plogin',
    secureServiceMyAccount: 'https://myaccounted.serversdev.getgo.com/',
    profileInfo: 'https://profileed.serversdev.getgo.com/personal-info',
    calendarSync: 'https://www.gotomeeting.com/features/quick-one-click-meetings',
    forceAttendeeWebMoreLink: 'https://link.gotomeeting.com/web-app-attendee-guide',
    forceAttendeeWebVideoLink: 'https://link.gotomeeting.com/video-web-app',
    meetingDiagnostics: 'https://ed.diagnostics.g2m.test.expertcity.com',
    hubFeedback: 'https://logmein.co1.qualtrics.com/jfe/form/SV_9mA6r0XXPditSBL',
    hubOptOutSurvey: 'https://logmein.co1.qualtrics.com/jfe/form/SV_74b1RRs0QOEZXLL',
    buyNowMarketingPage: 'https://www.gotomeeting.com/buy/upgrade',
    shareSessionHelpLink: 'https://support.goto.com/meeting/help/manage-and-share-session-recordings-g2m040024',
    electronDecommissioning:
      'https://support.logmeininc.com/gotomeeting/help/gotomeeting-messenger-g2m090152?c_prod=g2m&c_name=desktop',
    organizerPinLearnMoreLink: 'https://link.gotomeeting.com/help-start-meeting-phone',
    freeProductTraining: 'http://link.gotomeeting.com/gtm-hub-training',
    enableUX2019Link: 'https://support.logmeininc.com/gotomeeting/help/the-new-gotomeeting',
    meetingPasswordLearnMoreLink: 'https://link.gotomeeting.com/help-mtg-password',
    chromaCamDownloadLinkWindows: 'https://personifyinc.com/download-chromacam-g2m-win',
    chromaCamDownloadLinkMac: 'https://personifyinc.com/download-chromacam-g2m-mac',
    chromaCamLearnMoreLink: 'https://link.gotomeeting.com/help-customize-background',
    v9uninstallSupportPageUrl: 'https://link.gotomeeting.com/help-uninstall-v9',
    desktopSupportPageUrl: 'http://link.gotomeeting.com/desktop',
    g2mTwitterUrl: 'http://twitter.com/gotomeeting',
    microsoftEdgeHomepage: 'https://www.microsoft.com/edge',
    googleChromeHomepage: 'https://www.google.com/chrome',
    gotoAppDownloadLink: 'https://globaled1.g2m.test.expertcity.com/install/gotoapp',
    gotoAppFeedbackLink: 'https://logmeinvoc.iad1.qualtrics.com/jfe/form/SV_39obCEnQHAKSR7g',
    gotoAppLearnMoreLink: 'https://link.goto.com/meeting-guide'
  },
  oauth: {
    client_id: '73f512ba-51d2-48ec-b964-4cd2efe08b75',
    url: 'https://authenticationed1.lmiinc.test.expertcity.com'
  },
  rest: {
    url: 'https://chat-ed.serversdev.getgo.com',
    ejabberdUrl: 'https://xmpp-ed.serversdev.getgo.com',
    meetingService: 'https://apiglobaled1.g2m.test.expertcity.com',
    brandingService: 'https://branding.services-ed.goto.com',
    meetingRoomService: 'https://apied-g2m-external.devplatform-dev.expertcity.com/meeting-rooms',
    meetingLauncher: 'https://meet.ed.goto.com',
    accounts: 'https://iamed1.serversdev.getgo.com',
    externalAdmin: 'https://admined1.lmiinc.test.expertcity.com',
    login: 'https://authenticationed1.lmiinc.test.expertcity.com',
    invitationService: 'https://invitation-ed-aor.col.test.expertcity.com',
    insights: 'https://apied1.insights.g2m.test.expertcity.com',
    chatSessionState: 'https://csssed1.services.g2m.test.expertcity.com/csss',
    hubRolloutApi: 'https://ed1.hub.g2m.test.expertcity.com/rest/hub',
    searchOrchestrationService: 'https://apied.globalsearch.lmiinc.test.expertcity.com/globalsearch/sos',
    sessionAnalytics: 'https://apied.gotoinfraanalytics.lmiinc.test.expertcity.com/xqmanalytics/api',
    timeout: 15000
  },
  boldChat: {
    accountId: '744269138093162628',
    websiteId: '676368770079036104',
    invitationId: '2092119616541153336',
    boldChatFloatId: '739012165172076017'
  },
  wistiaScriptUrl: 'https://fast.wistia.com/assets/external/E-v1.js',
  xmpp: {
    baseUrl: 'xmpp-ed.serversdev.getgo.com',
    port: '5222',
    wssPort: '443',
    url: 'wss://xmpp-ed.serversdev.getgo.com:443/websocket',
    fallback: 'https://xmpp-ed.serversdev.getgo.com:443/http-bind',
    conference: 'conference.chat.platform.getgo.com'
  },
  telemetry: {
    url: 'https://lb0-telemetry-ed.dev.citrixsaassbe.net',
    ev_namespace: 'com.getgo.pulse',
    ev_source: {
      web: 'getgo.pulse.web',
      ios: 'getgo.pulse.ios'
    }
  },
  logging: {
    source: {
      web: 'getgo.pulse.web',
      ios: 'getgo.pulse.ios'
    },
    url: 'https://logginged1.getgo.test.expertcity.com'
  },
  launchDarkly: {
    clientSideId: '5aa07cbd4031a1201a05f840'
  },
  downloads: {
    linux: 'https://updatepulse.gotomeeting.com/download/latest/linux',
    mac: 'https://builds.cdn.getgo.com/builds/g2mv9/GoToMeeting%20v9.dmg',
    windows: 'https://builds.cdn.getgo.com/builds/g2mv9/GoToMeeting%20v9%20Setup.exe'
  },
  socialGraph: {
    url: 'https://socialgraph.serversdev.getgo.com'
  },
  upload: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com/fileupload/tenants/Pulse/path',
    limit: 52428800
  },
  preview: {
    url: 'https://thumbnail.serversdev.getgo.com/',
    profile: 'https://meet.ed.goto.com/_preview'
  },
  notifications: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com',
    applicationId: 'com.getgo.gotomeeting-pulse',
    vapidPublicKey: 'BKECtyDnob90OQWXb4Pjk8a268G2ug0Ztc-uKADqVW6qr55yFz3XsLNsLuq3OkVMs3DuBWHGX8Ru1YL4Q0Kv8ak',
    browserApiTimeout: 15000
  },
  emojis: {
    url: 'https://pulseed.col.test.expertcity.com/emojione/${code}.svg'
  },
  messaging: {
    maxReplacements: 3,
    maxReplacementInterval: 86400000
  },
  engagementEngine: {
    discoverUrl: 'https://g2m-hub.dev.ee.getgo.com'
  },
  googleTagManager: {
    url: 'https://www.googletagmanager.com/gtm.js?id=${key}',
    key: 'GTM-KP8F3CX',
    env: 'g2m-dev'
  },
  avatarServiceUrl: 'https://avatarsed1.serversdev.getgo.com',
  pendo: {
    apiKey: 'f1830314-8b16-409a-5e13-8626e3847ae1',
    resourceUrl: 'https://content.ipd.goto.com/agent/static',
    resourceName: 'pendo.js'
  }
};

export default config;
