import { ActionsUnion, createAction, Thunk } from '../../type-helpers';
import {
  EngagementEntry,
  getEngagementEngineMessages,
  markCtaClicked,
  markMessageAsDismissed,
  markMessageAsRead,
  sendVideoInteraction,
  VideoInteractions
} from '../../../../models/EngagementEngine';
import { apiErrorThunk, category, fetchDiscoverError } from '../core/errors/errorActions';
import logger from '../../../services/LoggerService';

export enum ActionTypes {
  DISCOVER_FETCHING = 'DISCOVER_FETCHING',
  DISCOVER_FETCHED = 'DISCOVER_FETCHED',
  DISCOVER_FETCH_ERROR = 'DISCOVER_FETCH_ERROR',

  DISCOVER_ITEM_DISMISSED = 'DISCOVER_ITEM_DISMISSED',
  DISCOVER_ITEM_SELECTED = 'DISCOVER_ITEM_SELECTED',
  DISCOVER_ITEM_CTA_CLICKED = 'DISCOVER_ITEM_CTA_CLICKED',
  DISCOVER_ITEM_VIDEO_INTERACTION = 'DISCOVER_ITEM_VIDEO_INTERACTION'
}

export const actions = {
  discoverFetching: () => createAction(ActionTypes.DISCOVER_FETCHING),
  discoverFetched: (entries: EngagementEntry[]) => createAction(ActionTypes.DISCOVER_FETCHED, { entries }),
  discoverFetchError: () => createAction(ActionTypes.DISCOVER_FETCH_ERROR),

  discoverItemDismissed: (entry: EngagementEntry) => createAction(ActionTypes.DISCOVER_ITEM_DISMISSED, { entry }),
  discoverItemSelected: (entry: EngagementEntry) => createAction(ActionTypes.DISCOVER_ITEM_SELECTED, { entry }),
  discoverItemCtaClicked: (entry: EngagementEntry) => createAction(ActionTypes.DISCOVER_ITEM_CTA_CLICKED, { entry }),
  discoverItemVideoInteraction: (entry: EngagementEntry, interactionType: VideoInteractions) =>
    createAction(ActionTypes.DISCOVER_ITEM_VIDEO_INTERACTION, { entry, interactionType })
};

export type Actions = ActionsUnion<typeof actions>;

const productName = 'g2m';

export const fetchDiscover = (): Thunk => async (dispatch, getState) => {
  dispatch(actions.discoverFetching());
  const user = getState().core.user;
  if (user.hasLoaded) {
    try {
      const entries = await getEngagementEngineMessages(user.key, user.accountKey, {
        product: productName,
        locale: user.locale || 'en_US'
      });
      dispatch(actions.discoverFetched(entries));
    } catch (err) {
      dispatch(fetchDiscoverError(err));
      dispatch(actions.discoverFetchError());
    }
  } else {
    dispatch(actions.discoverFetchError());
  }
};

export const selectDiscoverItem = (entry: EngagementEntry): Thunk => async (dispatch, getState) => {
  const user = getState().core.user;
  dispatch(actions.discoverItemSelected(entry));

  try {
    if (user.hasLoaded) {
      await markMessageAsRead(user.key, user.accountKey, entry, {
        product: productName,
        locale: user.locale || 'en_US'
      });
    }
  } catch (error) {
    return dispatch(
      apiErrorThunk({
        name: 'discover-item-update-error',
        error,
        category: category.discover
      })
    );
  }
};

export const dismissDiscoverItem = (entry: EngagementEntry): Thunk => async (dispatch, getState) => {
  const user = getState().core.user;
  try {
    if (user.hasLoaded) {
      dispatch(actions.discoverItemDismissed(entry));
      await markMessageAsDismissed(user.key, user.accountKey, entry, {
        product: productName,
        locale: user.locale || 'en_US'
      });
    }
  } catch (error) {
    return dispatch(
      apiErrorThunk({
        name: 'discover-item-update-error',
        error,
        category: category.discover
      })
    );
  }
};

export const clickCtaOnDiscoverItem = (entry: EngagementEntry): Thunk => async (dispatch, getState) => {
  const user = getState().core.user;

  try {
    if (user.hasLoaded) {
      dispatch(actions.discoverItemCtaClicked(entry));
      await markCtaClicked(user.key, user.accountKey, entry, {
        product: productName,
        locale: user.locale
      });
    }
  } catch (error) {
    return dispatch(
      apiErrorThunk({
        name: 'discover-item-update-error',
        error,
        category: category.discover
      })
    );
  }
};

export const videoInteractionOnDiscoverItem = (
  entry: EngagementEntry,
  interactionType: VideoInteractions
): Thunk => async (dispatch, getState) => {
  const user = getState().core.user;

  try {
    if (user.hasLoaded) {
      dispatch(actions.discoverItemVideoInteraction(entry, interactionType));
      await sendVideoInteraction(user.key, user.accountKey, entry, interactionType, {
        product: productName,
        locale: user.locale
      });
    }
  } catch (error) {
    logger.error('sendVideoInteraction', 'error=', error);
  }
};
