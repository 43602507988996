import React from 'react';
import { defineMessages, WrappedComponentProps } from 'react-intl';
import { EditableText } from '../Common/EditableText/EditableText';
import { SpinnerInline } from '../Common/SpinnerInline/SpinnerInline';

const messages = defineMessages({
  editGroupExists: {
    id: 'chat.GroupName.editGroupExists',
    defaultMessage: 'A public group with that name already exists'
  },
  label: {
    id: 'chat.GroupName.label',
    defaultMessage: 'Group name'
  }
});

interface Props {
  handleUpdate: (name: string) => void;
  isEditable?: boolean;
  isPublic?: boolean;
  name: string;
  onSearchGroup: (query: string, index?: number) => void;
  publicGroupExists?: boolean;
}

interface State {
  editName: string;
  isLoading: boolean;
  showPublicGroupExistsError: boolean;
}

export class GroupName extends React.Component<Props & WrappedComponentProps, State> {
  private isComponentMounted = false;
  private isUpdating = false;
  private inputElement: HTMLInputElement | undefined;

  constructor(props: Props & WrappedComponentProps) {
    super(props);
    this.state = {
      editName: props.name,
      isLoading: false,
      showPublicGroupExistsError: false
    };
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  UNSAFE_componentWillReceiveProps({ name }: Props) {
    if (name !== this.props.name) {
      this.setState({ editName: name, showPublicGroupExistsError: false });
    }
  }

  componentWillUnmount() {
    this.props.onSearchGroup('');
    this.isComponentMounted = false;
  }

  updateName = async () => {
    if (this.isUpdating) {
      return;
    }

    this.isUpdating = true;

    try {
      const name = this.state.editName.trim();
      if (name === this.props.name) {
        this.props.onSearchGroup('');
        return;
      }

      if (!name) {
        this.setState({ editName: this.props.name, showPublicGroupExistsError: false });
      } else {
        if (this.props.isPublic) {
          this.setState({ isLoading: true });
          await this.props.onSearchGroup(name);
          if (!this.isComponentMounted) {
            return;
          }
          this.setState({ isLoading: false });
        }
        if (this.props.publicGroupExists) {
          this.setState({ editName: this.props.name, showPublicGroupExistsError: true });
        } else {
          this.props.handleUpdate(name);
          this.setState({ editName: name, showPublicGroupExistsError: false });
        }
      }
    } finally {
      this.isUpdating = false;
    }
  };

  handleSubmit = (event: React.FormEvent) => {
    this.updateName();
    if (this.inputElement) {
      this.inputElement.blur();
    }
    event.preventDefault();
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      editName: event.target.value
    });
  };

  render() {
    const { formatMessage } = this.props.intl;

    const editableField = (
      <form onSubmit={this.handleSubmit}>
        <EditableText
          value={this.state.editName}
          onChange={this.handleChange}
          onBlur={this.updateName}
          inputRef={(inputElement: HTMLInputElement) => (this.inputElement = inputElement)}
          ariaLabel={formatMessage(messages.label)}
        />
        {this.state.isLoading ? <SpinnerInline addClassName="spinner-group-name-fetch" /> : null}
        {this.state.showPublicGroupExistsError && this.props.isPublic && (
          <p className="group-exists-error">{formatMessage(messages.editGroupExists)}</p>
        )}
      </form>
    );

    return <div className="group-name">{this.props.isEditable ? editableField : <span>{this.props.name}</span>}</div>;
  }
}
