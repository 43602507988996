import React from 'react';
import { MessageDescriptor, IntlShape, defineMessages, useIntl } from 'react-intl';
import { Error, category } from '../../../view-model/modules/core/errors/errorActions';
import { Alert } from '../../Common/Alert/Alert';
import { ReconnectionMessage } from '../../Common/ReconnectionMessage';
import { ConnectionStatus } from '../../../../types/pulse-web';
import styled from '../../../styled-components';

const TWO_DAYS = 2 * 24 * 3600 * 1000;

interface Messages {
  [key: string]: MessageDescriptor;
}

const errorMessages: Messages = defineMessages({
  'concurrent-meeting-error': {
    id: 'alerts.errorConcurrentMeetingError',
    defaultMessage:
      'Whoops, there was a problem creating your meeting because a session is currently in progress. Please try again later.'
  },
  'create-meeting-error': {
    id: 'alerts.errorCreateMeetingError',
    defaultMessage: 'Sorry, there was a problem creating your meeting. Please try again.'
  },
  'search-service-error': {
    id: 'alerts.errorServiceError',
    defaultMessage: 'Whoops, we were not able to get the search results. Please try again in a few minutes.'
  },
  'search-params-error': {
    id: 'alerts.errorSearchParamsError',
    defaultMessage: 'Sorry, the search cannot be completed. Contact Support if the problem persists.'
  },
  'api-auth-error': {
    id: 'alerts.errorApiAuthError',
    defaultMessage: 'Whoops, you do not have permissions to complete this operation.'
  },
  'meeting-list-error': {
    id: 'alerts.errorMeetingListError',
    defaultMessage: 'Unable to retrieve the list of meetings. Please try again later.'
  },
  'meeting-delete-error': {
    id: 'alerts.errorMeetingDeleteError',
    defaultMessage: 'Unable to delete the meeting. Please try again later.'
  },
  'recording-session-delete-error': {
    id: 'alerts.recordingSessionDeleteError',
    defaultMessage: 'Unable to delete the recording session. Please try again later.'
  },
  'meetings-history-error': {
    id: 'alerts.errorMeetingHistoryError',
    defaultMessage: 'Unable to access the meeting history. Please try again later.'
  },
  'meeting-in-session-error': {
    id: 'alerts.errorMeetingInSessionerror',
    defaultMessage: 'Unable to access the meeting. Please try again later.'
  },
  'meeting-session-delete-error': {
    id: 'alerts.errorMeetingSessionDeleteError',
    defaultMessage: 'Unable to end the meeting. Please try again later.'
  },
  'offline-warning': {
    id: 'alerts.errorOfflineWarning',
    defaultMessage: 'Not connected to the Internet'
  },
  'error-fetching-settings': {
    id: 'alerts.errorFetchingSettings',
    defaultMessage: "Couldn't load settings"
  },
  'error-updating-settings': {
    id: 'alerts.errorUpdatingSettings',
    defaultMessage: "Can't be saved"
  },
  'discover-fetching-error': {
    id: 'alerts.errorFetchingDiscover',
    defaultMessage: "Your Discover content isn't loading quite right. Please try again later."
  },
  'discover-item-update-error': {
    id: 'alerts.errorUpdateDiscoverItem',
    defaultMessage: 'Cannot save Discovery content. Please try again later.'
  }
});

const actionTexts: Messages = defineMessages({
  Ok: {
    id: 'alerts.actionOk',
    defaultMessage: 'OK'
  },
  'Ok, take me there.': {
    id: 'alerts.actionTakeMeThere',
    defaultMessage: 'OK, take me there.'
  },
  'Try again': {
    id: 'alerts.actionTryAgain',
    defaultMessage: 'Try again'
  },
  doubleBanner: {
    id: 'alerts.actionDoubleBanner',
    defaultMessage: 'Never miss messages again.'
  },
  update: {
    id: 'alerts.actionUpdate',
    defaultMessage: 'Update'
  },
  newVersion: {
    id: 'alerts.actionNewVersion',
    defaultMessage: 'A new version is available'
  },
  notifications: {
    id: 'alerts.actionNotifications',
    defaultMessage: 'Turn notifications on'
  }
});

const AlertsWrapper = styled.div`
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  line-height: normal;
  letter-spacing: normal;
`;

export const getLocalizedError = (error: Error, intl: IntlShape) => ({
  ...error,
  text: (error.name && errorMessages[error.name] && intl.formatMessage(errorMessages[error.name])) || error.text,
  actionText:
    (error.actionText && actionTexts[error.actionText] && intl.formatMessage(actionTexts[error.actionText])) ||
    error.actionText
});

interface Props {
  hideMeetingErrors?: boolean;
  applyUpdate?: () => void;
  connectionStatus: ConnectionStatus;
  error: Error;
  onReconnectNow: () => void;
  updateReady?: boolean;
  isChatEnabled: boolean;
  onDownload?: () => void;
  acceptDoubleBanner: () => void;
  rejectDoubleBanner: () => void;
  doubleBannerAccepted: boolean;
  lastDoubleBannerTimeMs: number;
  notificationPermission: NotificationPermission | null;
}

export const Alerts: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const shouldHideError = props.hideMeetingErrors && props.error.category === category.meeting;
  const shouldShowDoubleBanner =
    props.isChatEnabled &&
    !props.doubleBannerAccepted &&
    props.notificationPermission === 'default' &&
    Date.now() > props.lastDoubleBannerTimeMs + TWO_DAYS;

  return (
    <AlertsWrapper>
      {props.updateReady && (
        <Alert action={props.applyUpdate} actionText={f(actionTexts.update)} text={f(actionTexts.newVersion)} />
      )}
      {shouldShowDoubleBanner && (
        <Alert
          text={f(actionTexts.doubleBanner)}
          actionText={f(actionTexts.notifications)}
          action={props.acceptDoubleBanner}
          dismiss={props.rejectDoubleBanner}
        />
      )}
      {!shouldHideError && <Alert {...getLocalizedError(props.error, intl)} />}
      <ReconnectionMessage {...props} connectionStatus={props.connectionStatus} />
    </AlertsWrapper>
  );
};
