import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { setNewExperience } from '../../../../models/User';
import { State as UserState } from '../../../view-model/modules/core/user/userReducer';
import { AsyncPreferencesPage } from '../../Common/Preferences/PreferencesPage';
import { AsyncSettingGroupValues } from '../../../view-model/modules/core/preferences/preferencesReducer';
import { PreferencesItemBool } from '../../Common/Preferences/PreferencesItemBool';
import { ToastOptions } from '../../../view-model/modules/core/notifications/notificationsReducer';
import { SimpleNotification } from '../../Notifications/Notifications';
import config from 'config';
import { PreferencesBlock } from '../../Common/Preferences/PreferencesBlock';
import { Link } from '../../Common/Toolkit/Link/Link';

const messages = defineMessages({
  header: {
    id: 'newExperiencePreferences.header',
    defaultMessage: 'Discover the New GoToMeeting'
  },
  description: {
    id: 'newExperiencePreferences.description',
    defaultMessage:
      'A streamlined dashboard and refreshed meeting experience make the New GoToMeeting faster, simpler, and more reliable than ever.'
  },
  hubSwitchLabel: {
    id: 'newExperiencePreferences.hubSwitchLabel',
    defaultMessage: 'Switch to the new account experience'
  },
  hubSwitchDescription: {
    id: 'newExperiencePreferences.hubSwitchDescription',
    defaultMessage: 'Plan, share, and start meetings from one convenient online location with the New GoToMeeting Hub.'
  },
  updatedMeetingLabel: {
    id: 'newExperiencePreferences.updatedMeetingLabel',
    defaultMessage: 'Switch to the new meeting experience'
  },
  updatedMeetingDescription: {
    id: 'newExperiencePreferences.updatedMeetingDescription',
    defaultMessage:
      'Meet and collaborate with attendees using the {newGoToMeeting}. Attendees will also use the new design.'
  },
  entitlementMessage: {
    id: 'newExperiencePreferences.entitlementMessage',
    defaultMessage: 'Your administrator has set this value for you. Please contact your administrator to make changes.'
  },
  hubDisabledWarningTitle: {
    id: 'newExperiencePreferences.hubDisabledWarningTitle',
    defaultMessage: 'Heads up!'
  },
  hubDisabledWarningText: {
    id: 'newExperiencePreferences.hubDisabledWarningText',
    defaultMessage:
      'To switch meeting designs in the future, make sure the Hub is enabled. After saving, you can enable the Hub at global.gotomeeting.com.'
  },
  newGoToMeeting: {
    id: 'newExperiencePreferences.newGoToMeeting',
    defaultMessage: 'redesigned meeting experience'
  }
});

const WarningNotification = () =>
  SimpleNotification({
    title: <FormattedMessage {...messages.hubDisabledWarningTitle} />,
    content: <FormattedMessage {...messages.hubDisabledWarningText} />
  });

interface Props {
  user: UserState;
  current: AsyncSettingGroupValues<'ux2019Settings'>;
  update: AsyncSettingGroupValues<'ux2019Settings'>;
  newExperienceOptOut: () => void;
  showWarningNotification: (content: React.ReactNode, options?: ToastOptions) => void;
  changeSettingUX2019Enabled: (value: boolean) => void;
}

export class NewExperiencePreferences extends React.Component<Props> {
  revertExperience = (user: UserState) => {
    if (user?.hasLoaded) {
      setNewExperience(false).then(() => {
        this.props.newExperienceOptOut(); // for telemetry purposes only
        window.open(`${config.externalLinks.hubOptOutSurvey}?email=${user.email}`);
        window.location.reload();
      });
    }
  };

  showOptOutWarning = () => {
    if (
      this.props.update.meetingHubEnabled === false &&
      this.props.update.ux2019Enabled === true &&
      this.props.current.ux2019ControllableByUser === true
    ) {
      this.props.showWarningNotification(WarningNotification(), { type: 'warning' });
    }
  };

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.update.meetingHubEnabled !== this.props.update.meetingHubEnabled ||
      prevProps.update.ux2019Enabled !== this.props.update.ux2019Enabled
    ) {
      this.showOptOutWarning();
    }

    if (this.props.current.ux2019Enabled !== prevProps.current.ux2019Enabled) {
      this.props.changeSettingUX2019Enabled(this.props.current.ux2019Enabled);
    }

    if (
      this.props.current.meetingHubEnabled !== prevProps.current.meetingHubEnabled &&
      !this.props.current.meetingHubEnabled
    ) {
      this.revertExperience(this.props.user);
    }
  }

  render() {
    const meetingHubEnabledVisible = !this.props.current.meetingHubEnabled;
    const ux2019EnabledVisible = !this.props.current.ux2019Enabled;
    return (
      <AsyncPreferencesPage group="ux2019Settings">
        {({ settings, onChange, isDisabled }) => (
          <>
            <PreferencesBlock titleMessage={messages.header} descriptionMessage={messages.description}>
              {meetingHubEnabledVisible && (
                <PreferencesItemBool
                  id="meeting-enabled-hub"
                  type="checkbox"
                  checked={settings.meetingHubEnabled}
                  onChange={(isDefault) => onChange('meetingHubEnabled', isDefault)}
                  disabled={isDisabled || !settings.meetingHubControllableByUser}
                  disabledTooltip={!settings.meetingHubControllableByUser ? messages.entitlementMessage : undefined}
                  labelMessage={messages.hubSwitchLabel}
                  description={<FormattedMessage {...messages.hubSwitchDescription} />}
                />
              )}
              {ux2019EnabledVisible && (
                <PreferencesItemBool
                  id="desktop-enabled"
                  type="checkbox"
                  checked={settings.ux2019Enabled}
                  onChange={(isDefault) => onChange('ux2019Enabled', isDefault)}
                  disabled={isDisabled || !settings.ux2019ControllableByUser}
                  disabledTooltip={!settings.ux2019ControllableByUser ? messages.entitlementMessage : undefined}
                  labelMessage={messages.updatedMeetingLabel}
                  description={
                    <FormattedMessage
                      {...messages.updatedMeetingDescription}
                      values={{
                        newGoToMeeting: (
                          <Link href={config.externalLinks.enableUX2019Link} target="_blank" rel="noopener noreferrer">
                            <FormattedMessage {...messages.newGoToMeeting} />
                          </Link>
                        )
                      }}
                    />
                  }
                  infoMessage={settings.ux2019ControllableByUser ? messages.hubDisabledWarningText : undefined}
                />
              )}
            </PreferencesBlock>
          </>
        )}
      </AsyncPreferencesPage>
    );
  }
}
