import * as React from 'react';
import styled, { css, keyframes } from '../../../../styled-components';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ToastProps, ToastOptions } from '../../../../view-model/modules/core/notifications/notificationsReducer';
import { FormattedMessage, defineMessages } from 'react-intl';
import { GhostButton } from '../Button/GhostButton';
import { themeGet } from '@styled-system/theme-get';
import { CloseButton } from '../Button/CloseButton';

const ANIMATION_DURATION = 1000;
const TOAST_REMOVE_AFTER = ANIMATION_DURATION + 500;
const TOAST_TIMEOUT = 5000;

const messages = defineMessages({
  ok: {
    id: 'toasts.button.ok',
    defaultMessage: 'OK'
  }
});

const hideAnimation = keyframes`50% { opacity: 0; } 100% { opacity: 0; height: 0; }`;
const showAnimation = keyframes`from { opacity: 0; } to { opacity: 1; }`;

const ToastInner = styled.div<{ type: ToastOptions['type'] }>`
  background-color: #fff;
  margin-bottom: 16px;
  border-left: 8px solid
    ${(props) => themeGet(`toast.colors.${props.type}.primary`, themeGet('toast.colors.success.primary'))(props)};
  padding: 16px 32px;
  border-radius: 4px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  pointer-events: all;
`;

const ToastContainer = styled.div`
  position: fixed;
  right: 0;
  z-index: 15;
  width: 400px;
  max-width: calc(100% - 32px);
  padding: 16px;
  pointer-events: none;
`;

const ToastContainerBottom = styled(ToastContainer)`
  bottom: 0;
`;

const ButtonContainer = styled.div`
  margin-bottom: -8px;
`;

export const PositionedCloseButton = styled(CloseButton)`
  position: absolute;
  right: 12px;
  top: 12px;
  height: 14px;
`;

const ActionText = styled(GhostButton)`
  font-size: 12px;
  line-height: 25px;
  &:first-child {
    margin-left: -16px;
  }
`;

const ToastAnimationContainer = ({ className, children, ...rest }: any, ref: any) => (
  <div data-testid="toast-notification" className={className} {...rest} ref={ref}>
    {children}
  </div>
);

interface ToastAnimationContainerProps {
  height?: number;
  hidden?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const Toast = styled(React.forwardRef<ToastAnimationContainerProps>(ToastAnimationContainer) as any)`
  display: block;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  animation: ${showAnimation} 500ms ease-in;

  ${(props) => props.height && `height: ${props.height}px;`}

  ${(props) =>
    props.hidden &&
    css`
      animation: ${hideAnimation} ${ANIMATION_DURATION}ms ease-out;
      animation-fill-mode: forwards;
    `}
`;

const ToastWrapper = ({
  content,
  className,
  hideToastNotification,
  id,
  options
}: { className?: string; hideToastNotification: (id: string) => void } & ToastProps) => {
  const [hidden, setHidden] = useState(false);
  const [height, setHeight] = useState(0);
  const [timer, setTimer] = useState(0 as any);
  const ref = useRef((null as any) as HTMLDivElement);

  const hide = useCallback(() => {
    setHeight(ref.current ? ref.current.clientHeight : 0);
    setHidden(true);
    setTimeout(() => hideToastNotification(id), TOAST_REMOVE_AFTER);
  }, [hideToastNotification, id]);

  const startTimeout = useCallback(() => {
    if (!hidden) {
      setTimer(setTimeout(hide, options.timeout ? options.timeout : TOAST_TIMEOUT) as any);
    }
  }, [hide, options.timeout, hidden]);

  const stopTimeout = () => {
    clearTimeout(timer);
  };

  const hideImmediately = () => {
    stopTimeout();
    hide();
  };

  useEffect(() => {
    startTimeout();
  }, [id, startTimeout]);

  useEffect(() => () => stopTimeout(), [timer]);

  const hideButtonContainer: boolean = !!options.hideOkButton && !options.buttons;

  return (
    <Toast
      ref={ref}
      height={height}
      hidden={hidden}
      className={className}
      onMouseEnter={stopTimeout}
      onMouseLeave={startTimeout}
    >
      <ToastInner type={options.type}>
        <PositionedCloseButton onClick={hideImmediately} dataTestId="toast-close-button" color={'#9aa8bb'} size={18} />
        {content}
        {!hideButtonContainer && (
          <ButtonContainer data-testid="toast-button-container">
            {!options.hideOkButton && (
              <ActionText onClick={hideImmediately}>
                <FormattedMessage {...messages.ok} />
              </ActionText>
            )}
            {options.buttons &&
              options.buttons.map((button, i) => (
                <ActionText
                  key={`action-${i}`}
                  onClick={() => {
                    hideImmediately();
                    button.action();
                  }}
                >
                  {button.text}
                </ActionText>
              ))}
          </ButtonContainer>
        )}
      </ToastInner>
    </Toast>
  );
};

interface Props {
  hideToastNotification: (id: string) => void;
  toasts: ToastProps[];
  position?: 'top' | 'bottom';
}

export const Toasts = ({ hideToastNotification, toasts, position }: Props) => {
  const Container = position === 'bottom' ? ToastContainerBottom : ToastContainer;
  const items = position === 'bottom' ? [...toasts].reverse() : toasts;

  return (
    <Container>
      {items.map((item) => (
        <ToastWrapper key={item.id} hideToastNotification={hideToastNotification} {...item} />
      ))}
    </Container>
  );
};
