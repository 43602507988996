import styled, { keyframes } from '../../../../styled-components';
import { Icons } from '../ICONS';
import { Icon, Props as IconProps } from './Icon';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const RotatingIcon = styled(Icon)<IconProps & { speed?: number; icon: Icons }>`
  display: inline-block;
  animation: ${rotate360} ${(props) => props.speed || 0.8}s linear infinite;
`;
