const lightSchemeIcons = document.querySelectorAll('link.favicon-light');
const darkSchemeIcons = document.querySelectorAll('link.favicon-dark');

export const setCorrectFavicon = () => {
  const matcher = window.matchMedia('(prefers-color-scheme: dark)');
  const update = () => {
    if (matcher.matches) {
      lightSchemeIcons.forEach?.((icon) => icon.remove());
      darkSchemeIcons.forEach?.((icon) => document.head.append(icon));
    } else {
      darkSchemeIcons.forEach?.((icon) => icon.remove());
      lightSchemeIcons.forEach?.((icon) => document.head.append(icon));
    }
  };

  matcher.addEventListener?.('change', update);
  update();
};
