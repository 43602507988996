import { connect } from 'react-redux';
import { FilesMessage, Selectors } from '@getgo/caps-redux';
import imagePreviewActions from '../view-model/modules/messaging/imagePreview/imagePreviewActions';
import { ImagePreview } from '../components/Common/ImagePreview/ImagePreview';
import { computeMsgProps, hydrateGroupChatNames } from '../services/ChatHistoryFormatService';
import { Dispatch, State } from '../view-model/types';

const mapStateToProps = (state: State) => {
  // these lines are needed to obtain the avatar url. reuse an existing function
  // at the cost of some verbosity
  if (state.messaging.imagePreview.isOpen) {
    const rawMessage = state.messaging.imagePreview.messages[state.messaging.imagePreview.index];
    const from = rawMessage.roomId;
    const contact = Selectors.findContact(state, from);
    const message = hydrateGroupChatNames([rawMessage], contact)[0];
    const msgProps = computeMsgProps(message, undefined, state.core.user, contact);

    return {
      avatarUrl: msgProps.avatarUrl,
      name: msgProps.name,
      message: message as FilesMessage
    };
  }
  return {
    avatarUrl: undefined,
    name: undefined,
    message: undefined
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onNavigation: (delta: -1 | 1) => {
      dispatch(imagePreviewActions.navigateImagePreview(delta));
    },
    onPreviewClose: () => {
      dispatch(imagePreviewActions.closePreview());
    }
  };
};

const ImagePreviewContainer = connect(mapStateToProps, mapDispatchToProps)(ImagePreview);

export default ImagePreviewContainer;
