import React from 'react';
import { ModalDialog } from '../../Common/ModalDialog/ModalDialog';
import MeetingScheduler, { Props } from './MeetingScheduler';

export const MeetingSchedulerModal = (props: Props) => {
  const onClose = () => {
    if (
      !document.getElementsByClassName('react-datepicker').length &&
      !document.getElementsByClassName('react-select__control--menu-is-open').length
    ) {
      props.hideScheduleMeeting();
    }
  };
  return (
    <ModalDialog contentLabel="SchedulerModal" shouldCloseOnOverlayClick onClose={onClose}>
      <MeetingScheduler {...props} />
    </ModalDialog>
  );
};
