import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { defineMessages, useIntl } from 'react-intl';
import styled from '../../../styled-components';
import ReactDOM from 'react-dom';
import { compareDate, getBackdatedValue, getDiffInDays, getFnsLocale } from '../../../../lib/date';
import { themeGet } from '@styled-system/theme-get';
import { Button } from '../Toolkit/Button/Button';
import device from '../Toolkit/devices';
import { isMobile } from '../../../services/ClientService';
import { isLeapYear } from 'date-fns';

const StyledContainer = styled.div`
  & input {
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;

    &:disabled {
      background-color: #fff;
      color: #ccc;
    }
  }

  .react-datepicker-wrapper {
    display: inline-block;
    width: 100%;
  }
`;

const StyledPopperContainer = styled.div`
  .react-datepicker {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 5px 8px 3px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    display: flex;
    position: relative;
    max-height: 313px;
    max-width: 686px;
    margin: 12px 0 0 -12px;
    padding: 20px 10px 10px 0px;

    @media ${device.mobile} {
      flex-direction: column;
      padding: 5px 2px 2px;
      max-height: 510px;
      overflow: scroll;
    }
  }

  .react-datepicker .navButtonsContainer {
    order: -1;
    padding-top: 20px;
  }

  .nav-pills {
    align-items: flex-start;
  }

  .react-datepicker.react-datepicker--time-only {
    overflow-y: scroll;
    padding: 0;
  }

  .react-datepicker-popper {
    z-index: 100;
    @media (max-width: 420px) {
      left: -30px !important;
    }
    @media (max-width: 360px) {
      left: -50px !important;
    }
  }

  .react-datepicker__header {
    text-align: center;
    position: relative;
  }

  .react-datepicker__current-month {
    font-weight: bold;
    font-size: 16px;
    text-transform: capitalize;
    padding-bottom: 10px;

    margin-top: -10px;
    padding-top: 10px;
  }
  .react-datepicker__month-container {
    margin-right: 15px;
  }

  .react-datepicker__navigation {
    background: none;
    cursor: pointer;
    position: absolute;
    border: none;
    z-index: 1;
    height: 20px;
    width: 20px;
    text-indent: -999em;
    overflow: hidden;
    outline: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 5px;
      top: 5px;
      width: 8px;
      height: 8px;
      border: 2px solid #868b92;
      transform: rotate(45deg);
      border-top: none;
      border-right: none;
    }

    :focus,
    :active {
      border-radius: 3px;
      box-shadow: 0 0 0 3px ${themeGet('colors.primary')}!important;
    }

    &:hover::after {
      border-color: #b3b3b3;
    }
  }

  .react-datepicker__navigation--previous {
    left: 150px;

    @media ${device.mobile} {
      left: 14px;
      top: 228px;
    }
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
    pointer-events: none;
  }

  .react-datepicker__day--in-range {
    background-color: #e1edff !important;
  }

  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-end {
    border-radius: 3px;
    background-color: ${themeGet('colors.primary')} !important;
    color: ${themeGet('colors.primaryOn')} !important;
  }

  .react-datepicker__day--range-start:hover {
    background-color: ${themeGet('colors.hover.primary')} !important;
  }

  .react-datepicker__navigation.react-datepicker__navigation--next {
    right: 22px;
    &::after {
      transform: rotate(225deg);
    }
    @media ${device.mobile} {
      top: 228px;
    }
  }

  .react-datepicker__month {
    margin: 0.4rem;
    text-align: center;
  }

  .react-datepicker__day-names {
    font-size: 12px;
    font-weight: 600;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    outline: none;
    color: #000;
    display: inline-block;
    width: 16px;
    line-height: 16px;
    text-align: center;
    margin: 2px;
    padding: 7px;
    color: rgba(37, 40, 45, 0.6);
  }

  .react-datepicker__day {
    color: #000000;
    cursor: pointer;
  }

  .react-datepicker__day--weekend {
    color: #25282d;
  }

  .react-datepicker__day--outside-month {
    color: #ccc;
  }

  && .react-datepicker__day--disabled,
  && .react-datepicker__day--disabled:hover {
    color: #eee;
    background-color: transparent;
    cursor: not-allowed;
  }

  .react-datepicker__day:hover {
    border-radius: 3px;
    background-color: #e1edff;
  }
  .react-datepicker__day--today {
    border-radius: 3px;
    background-color: #e1edff;
  }

  .react-datepicker__day--selected {
    border-radius: 3px;
    background-color: ${themeGet('colors.primary')};
    color: ${themeGet('colors.primaryOn')};
  }

  .react-datepicker__day--selected:hover {
    background-color: ${themeGet('colors.hover.primary')};
  }

  .react-datepicker__day--keyboard-selected {
    border-radius: 3px;
    background-color: ${themeGet('colors.primary')};
    color: ${themeGet('colors.primaryOn')};
  }

  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${themeGet('colors.hover.primary')};
  }
`;

const SchedulerCalendarContainer = ({ children }: { children: React.ReactNode[] }) => {
  const element = document.getElementById('app');
  if (element) {
    return ReactDOM.createPortal(<StyledPopperContainer>{children}</StyledPopperContainer>, element);
  }
  return null;
};

interface Props {
  value?: Date | null;
  disabled?: boolean;
  minDate: Date;
  maxDate?: Date | null | undefined;
  customInput?: React.ReactNode;
  isSchedulerDatePicker?: boolean;
  monthsShown?: number;
  showPreviousMonths?: boolean;
  endDate?: Date | null | undefined;
  startDate?: Date | null | undefined;
  selectsEnd?: boolean;
  hideDatePicker?: boolean;
  setRef?: any;
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
  leftExtremeCalendarValue: Date;
  showElasticSearchDateRangePicker: () => void;
  hideElasticSearchDateRangePicker: () => void;
  updateRetainFlag?: () => void;
  selectedDates: (startDate: Date | undefined, endDate: Date | undefined, selectionComplete: boolean) => void;
  retainStartDate?: Date;
  retainEndDate?: Date;
}

const messages = defineMessages({
  lastSevenDays: {
    id: 'dateRangePicker.option.lastSevenDays',
    defaultMessage: 'Last 7 Days'
  },
  lastFourteenDays: {
    id: 'dateRangePicker.option.lastFourteenDays',
    defaultMessage: 'Last 14 Days'
  },
  lastThirtyDays: {
    id: 'dateRangePicker.option.lastThirtyDays',
    defaultMessage: 'Last 30 Days'
  },
  lastNintyDays: {
    id: 'dateRangePicker.option.lastNintyDays',
    defaultMessage: 'Last 90 Days'
  },
  lastOneYear: {
    id: 'dateRangePicker.option.lastOneYear',
    defaultMessage: 'Last 12 Months'
  }
});

const NavigationPills = styled.div<{ disabled: boolean }>`
  width: 100%;
  color: ${(props) => (props.disabled ? '#ccc' : '#006aff')};
  height: 32px;
  padding-top: 8px;

  .navButtons {
    color: inherit;
    padding-top: 5px;
    padding-bottom: 5px;
    outline: none;
  }
  &:hover {
    background-color: ${(props) => (props.disabled ? '#c4c9cd' : '#006aff')};
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    color: #ffffff;
  }
`;

export const DateRangePicker: React.FC<Props> = (props) => {
  const [resetStartDate, setResetStartDate] = useState<Date>(props.leftExtremeCalendarValue);
  const [resetEndDate, setResetEndDate] = useState<Date>(new Date());
  const [startDate, setStartDate] = useState<Date | undefined>(
    props.retainStartDate ? new Date(props.retainStartDate) : undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    props.retainEndDate ? new Date(props.retainEndDate) : undefined
  );
  const [selectionComplete, toggleSelectionComplete] = useState(!!(props.retainEndDate && props.retainStartDate));

  const intl = useIntl();

  const { disabled, customInput, monthsShown, showPreviousMonths, setRef } = props;
  const locale = getFnsLocale(intl);
  const format = locale.formatLong.date({ width: 'short' });
  const numberOfMonthsOnScreen = !isMobile ? monthsShown : 1;

  const applyCustomisedDateFilter = (interval: number) => {
    const initialDate = getBackdatedValue(new Date(), -interval, 0, 0);
    props.showElasticSearchDateRangePicker();

    setStartDate(initialDate);
    setEndDate(new Date());
    toggleSelectionComplete(true);
  };

  const getPredefinedDateRangeButtons = () => {
    const { formatMessage: f } = intl;
    const minimumDate = props.minDate.getTime();
    const numberOfDays = isLeapYear(new Date()) ? 365 : 364;
    const predefinedDatesArray = [
      { id: 6, label: f(messages.lastSevenDays) },
      { id: 13, label: f(messages.lastFourteenDays) },
      { id: 29, label: f(messages.lastThirtyDays) },
      { id: 89, label: f(messages.lastNintyDays) },
      { id: numberOfDays, label: f(messages.lastOneYear) }
    ];
    return predefinedDatesArray.map((item) => {
      const predefinedDate = getBackdatedValue(new Date(), -item.id, 0, 0).getTime();
      const disablePredefinedDate = minimumDate > predefinedDate;
      return (
        <NavigationPills key={item.id} disabled={disablePredefinedDate}>
          <Button
            key={item.id}
            className="navButtons"
            onClick={() => applyCustomisedDateFilter(item.id)}
            color="link"
            data-testid={item.label}
            disabled={disablePredefinedDate}
          >
            {item.label}
          </Button>
        </NavigationPills>
      );
    });
  };

  const onCalendarClose = () => {
    // reset the calendar values if user selects only single date and closes the calendar
    if (startDate && !endDate) {
      setStartDate(resetStartDate);
      setEndDate(resetEndDate);
      toggleSelectionComplete(true);
    }

    if (startDate && endDate) {
      setResetStartDate(startDate);
      setResetEndDate(endDate);
    }
    setTimeout(() => props.hideElasticSearchDateRangePicker(), 500); // updating flag after 0.5 sec delay otherwise pane closes instantly
  };

  useEffect(() => {
    if (selectionComplete && startDate && endDate) {
      props.selectedDates(startDate, endDate, selectionComplete);
    }
  }, [selectionComplete, startDate, endDate]);

  const handleDateChange = (date: Date) => {
    if (selectionComplete && startDate && endDate) {
      setStartDate(date);
      setEndDate(undefined);
      toggleSelectionComplete(false);
    }

    if (!selectionComplete && !startDate) {
      setStartDate(date);
      return;
    }

    if (!selectionComplete && startDate && !endDate) {
      const difference = getDiffInDays(startDate, date);
      if (difference < 0) {
        setEndDate(startDate);
        setStartDate(date);
      } else {
        setEndDate(date);
      }
      toggleSelectionComplete(true);
    }
  };

  const handleSelect = (date: Date) => {
    if (!selectionComplete && startDate && !endDate && compareDate(date, startDate)) {
      handleDateChange(date);
    }
  };

  const onCalendarOpen = () => {
    if (props.updateRetainFlag) props.updateRetainFlag();
    props.showElasticSearchDateRangePicker();
  };

  return (
    <StyledContainer>
      <ReactDatePicker
        minDate={props.leftExtremeCalendarValue}
        maxDate={new Date()}
        selectsEnd={Boolean(endDate)}
        startDate={startDate}
        endDate={endDate}
        selected={startDate}
        disabled={disabled}
        locale={intl.locale}
        onChange={handleDateChange}
        onSelect={handleSelect}
        customInput={customInput}
        showPreviousMonths={showPreviousMonths}
        monthsShown={numberOfMonthsOnScreen}
        dateFormat={format}
        popperContainer={SchedulerCalendarContainer}
        shouldCloseOnSelect={false}
        popperPlacement="auto"
        useWeekdaysShort={true}
        ref={setRef}
        allowSameDay={true}
        onCalendarClose={onCalendarClose}
        onCalendarOpen={onCalendarOpen}
        popperModifiers={{
          offset: {
            enabled: true,
            offset: '15px, 20px'
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
            boundariesElement: 'viewport'
          }
        }}
      >
        <div className="navButtonsContainer">
          <div
            className="nav flex-column nav-pills navItems"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            {getPredefinedDateRangeButtons()}
          </div>
        </div>
      </ReactDatePicker>
    </StyledContainer>
  );
};
