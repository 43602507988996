import { Reducer, Store, StoreEnhancer, applyMiddleware, compose, createStore, PreloadedState } from 'redux';
import { autoRehydrate, OnComplete, persistStore } from 'redux-persist';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware, { ThunkMiddleware } from 'redux-thunk';
import errorHandlingMiddleware from './middleware/error';
import featureServiceMiddleware from './middleware/features';
import { telemetryMiddleware } from './middleware/telemetry';
import validateRehydrateMiddleware from './middleware/validateRehydrate';
import reducers from './reducers';
import { Actions, State } from './types';
import { persistorConfig } from './persistorConfig';

const middleware = [
  errorHandlingMiddleware,
  validateRehydrateMiddleware,
  telemetryMiddleware,
  promiseMiddleware,
  thunkMiddleware as ThunkMiddleware<State, Actions>,
  featureServiceMiddleware
];

const createStoreWithMiddleWare = (
  allReducers: Reducer,
  initialState: PreloadedState<State>,
  enhancers: StoreEnhancer
) => {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return createStore<State, Actions, any, any>(
    allReducers,
    initialState,
    composeEnhancers(applyMiddleware(...middleware), enhancers)
  );
};

export default function createAppStore(
  initialState: PreloadedState<State>,
  rehydrationCallback: OnComplete<Partial<State>>
) {
  const store = createStoreWithMiddleWare(reducers, initialState, autoRehydrate());
  const persistor = persistStore(store, persistorConfig as any, rehydrationCallback);

  Object.assign(store, { persistor });

  return store;
}

export function createAppStoreAsync(initialState: PreloadedState<State>, extraMiddleware: any[] = []): Promise<Store> {
  middleware.push(...extraMiddleware);

  return new Promise((resolve) => {
    const store = createAppStore(initialState, () => {
      resolve(store);
    });
  });
}
