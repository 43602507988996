import { ButtonsRow } from '../../Meetings/MeetingDetails/MeetingDetailsWrapper';
import { DeleteButton, DownloadButton, ProcessingRecordingButton } from '../../Meetings/MeetingComponents';
import * as React from 'react';
import { User } from '../../../view-model/modules/core/user/userReducer';
import styled from 'styled-components';
import device from '../../Common/Toolkit/devices';
import { Props as GhostButtonProps } from '../../Common/Toolkit/Button/GhostButton';
import { FormattedMessage } from 'react-intl';
import { Props as ButtonProps } from '../../Common/Toolkit/Button/Button';
import { PrimaryButton } from '../../Common/Toolkit/Button/PrimaryButton';
import { GlobalSearchMessages as messages } from '../GlobalSearchMessages';
import { Start } from '../../Common/Toolkit/Icon/ReactIcons';
import { MeetingRecordingShare } from '../../Meetings/MeetingsHistory/MeetingRecordingShare';
import { triggerTelemetryElasticSearch } from '../../../view-model/modules/meetings/meetingsActions';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../../view-model/types';
import { SelectableItemType, Session } from '../../../view-model/modules/meetings/MeetingsTypes';

interface Props {
  userSettings: User['settings'];
  handleOpenRecording: () => void;
  onDownloadRecording: () => void;
  showDeleteRecordingConfirmationModal: () => void;
  session: Session;
  copyStandalonePlayerUrl: (meetingId: string, recordingId: string) => void;
  showRecordingAssetModal: () => void;
}

const CustomButtonContainer = styled.div`
  border-radius: 4px;
  border: solid 1px rgba(76, 83, 93, 0.24);
  height: 36px;

  @media ${device.mobile} {
    display: flex;
    flex: 1 1 auto;

    button {
      justify-content: center;
      margin-top: 0px;
    }
  }
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  margin: 0 32px;
  padding-bottom: 16px;
  margin-top: 34px;
  justify-content: stretch;
  margin-left: 0px;

  ${ButtonsRow}:last-child:nth-child(n+2) {
    margin-left: 2px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    ${ButtonsRow}:last-child:nth-child(n+2) {
      margin: 0;
    }
  }

  @media ${device.mobile} {
    margin: 0px;
    margin-top: 30px;
    padding: 0;
    border: 0;
  }
`;

const BasicPrimaryButton = styled((props: ButtonProps) => (
  <PrimaryButton {...props} iconSize={12}>
    {props.children}
  </PrimaryButton>
))`
  padding: 0 24px;

  @media ${device.laptop} {
    font-size: 12px;
  }

  @media ${device.tablet} {
    flex: 1 1 auto;
    justify-content: center;
    margin-bottom: 16px;
  }

  @media ${device.mobile} {
    padding: 0;
    margin: 0;
  }
`;

const RecordButtonContainer = styled.div`
  display: flex;
`;

const ShareButtonContainer = styled.div`
  display: flex;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  margin: 3px;
  display: flex;

  button {
    height: 36px;
  }

  @media ${device.mobile} {
    flex: 1 1 0;
  }
`;

const RecordingIcon = styled((props: { className?: string }) => {
  return <Start className={props.className} color={'#ffffff'} data-testid="recording-icon" size={20} unit={'px'} />;
})`
  margin-right: 5px;
`;

export const OpenRecordingButton = (props: GhostButtonProps) => (
  <BasicPrimaryButton data-testid="open-recording-button" onClick={props.onClick} disabled={props.disabled}>
    <RecordingIcon />
    <FormattedMessage {...messages.openRecording} />
  </BasicPrimaryButton>
);

export const ActionButtonsView: React.FC<Props> = (props: Props) => {
  const { session, userSettings } = props;
  const recordingIsProcessing = session.recording && (!session.recording.stored || !session.recording.downloadUrl);
  const meetingsState = useSelector((state: State) => state.meetings);
  const selectedItemType = meetingsState?.selectedItemType;
  const deleteInProcess = meetingsState?.deleteInProcess;

  const dispatch = useDispatch();
  const openDownloadModal = () => {
    dispatch(triggerTelemetryElasticSearch('downloadButtonClicked'));
    props.showRecordingAssetModal();
  };
  const openDeleteRecording = () => {
    props.showDeleteRecordingConfirmationModal();
    dispatch(triggerTelemetryElasticSearch('deleteRecordingOpen'));
  };

  const openRecording = () => {
    props.handleOpenRecording();

    if (selectedItemType === SelectableItemType.ELASTICSEARCHRESULTSELECTED) {
      dispatch(triggerTelemetryElasticSearch('openRecordingFromGlobalSearchPreview'));
    } else {
      dispatch(triggerTelemetryElasticSearch('openRecordingFromMeetingHistoryPreview'));
    }
  };

  return (
    <ActionButtonsContainer>
      <ShareButtonContainer>
        <RecordButtonContainer>
          <ButtonWrapper>
            {recordingIsProcessing ? (
              <ProcessingRecordingButton />
            ) : (
              <OpenRecordingButton disabled={deleteInProcess} onClick={openRecording} />
            )}
          </ButtonWrapper>

          {!recordingIsProcessing && userSettings?.downloadrecordingentitled && (
            <ButtonWrapper>
              <CustomButtonContainer>
                <DownloadButton disabled={deleteInProcess} onClick={openDownloadModal} />
              </CustomButtonContainer>
            </ButtonWrapper>
          )}
        </RecordButtonContainer>
        <RecordButtonContainer>
          <ButtonWrapper>
            <CustomButtonContainer>
              <MeetingRecordingShare
                recordingIsProcessing={recordingIsProcessing}
                session={session}
                copyStandalonePlayerUrl={props.copyStandalonePlayerUrl}
                deleteInProcess={deleteInProcess}
              />
            </CustomButtonContainer>
          </ButtonWrapper>

          {!recordingIsProcessing && userSettings?.deleterecordingentitled && (
            <ButtonWrapper>
              <CustomButtonContainer>
                <DeleteButton onClick={openDeleteRecording} />
              </CustomButtonContainer>
            </ButtonWrapper>
          )}
        </RecordButtonContainer>
      </ShareButtonContainer>
    </ActionButtonsContainer>
  );
};
