import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  text: {
    id: 'rooms.emptyContacts.text',
    defaultMessage: 'You have no contacts yet.'
  }
});

export const EmptyContacts: React.FC<{}> = () => {
  return (
    <div className="empty-contact-list">
      <div className="arrow" />
      <div className="content">
        <FormattedMessage {...messages.text} />
      </div>
    </div>
  );
};
