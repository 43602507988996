import styled from '../../../styled-components';
import * as React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { MediaQueryFeatureSelector } from '../MediaQueryFeatureSelector';
import device from '../Toolkit/devices';

export const CardList = styled((props: { className?: string; children?: React.ReactNode }) => {
  const list = <ol className={props.className}>{props.children}</ol>;

  const scrollableList = (
    <Scrollbars autoHide style={{ flex: '1 1 0%' }}>
      {list}
    </Scrollbars>
  );

  return <MediaQueryFeatureSelector query={device.mobile} queryMatched={list} queryNotMatched={scrollableList} />;
})`
  list-style: none;
  margin: 0;
  padding: 8px 16px 0px;
  position: relative;

  li {
    margin-bottom: 8px;
  }

  @media ${device.mobile} {
    padding: 8px 8px 0px;
  }
`;
