import * as React from 'react';
import './PageTemplate.css';
import { CloseButton } from '../Toolkit/Button/CloseButton';

interface Props {
  enableCancel?: boolean;
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Header: React.FC<Props> = ({ enableCancel = false, onCancel, children }) => {
  const title = React.Children.count(children) > 0 && <div className="pulse-title">{children}</div>;
  const isCloseDisabled = !onCancel;
  const handleCancel = onCancel || (() => {});
  const actions = enableCancel && (
    <CloseButton onClick={handleCancel} disabled={isCloseDisabled} dataTestId={'header-close-button'} size={24} />
  );

  // page-header-wrapper is added to center the header content
  // but align the header actions at the header content flext start (UX)
  if (title || actions) {
    return (
      <div className="pulse-header">
        {title}
        {actions}
      </div>
    );
  }
  return null;
};
