import { SearchListContent, TranscriptsTabContainer } from '../GlobalSearchDetailsView';
import { Box, Item } from '../../Common/Toolkit/Box/Box';
import React, { useState } from 'react';
import { SessionFromElasticSearch } from '../../../view-model/modules/meetings/meetingsReducer';
import styled from '../../../styled-components';
import {
  evaluateMeetingYear,
  OpenRecordingContainer,
  OpenSearchRecordingResultButton
} from '../GlobalSearchComponents';
import {
  fetchSingleSession,
  selectMeeting,
  triggerTelemetryElasticSearch
} from '../../../view-model/modules/meetings/meetingsActions';
import { useDispatch } from 'react-redux';
import { SelectableItemType } from '../../../view-model/modules/meetings/MeetingsTypes';

const MeetingDetails = styled.span`
  color: rgba(37, 40, 45, 0.6);
  font-size: 14px;
`;

const TranscriptTitle = styled.h3<{ color?: string; textAlign?: string; whiteSpace?: string }>`
  margin: -4px 8px 5px 0;
  padding-top: 4px;
  font-size: 20px;
  line-height: 24px; /* work around so that emojis don't break the line */
  font-family: Lato-bold;
  font-weight: bold;
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TranscriptsSubject = styled(Item)`
  padding-top: 3px;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
`;

const TranscriptsTextContainer = styled.div`
  em {
    background: #fff7d9;
    font-style: normal;
  }
`;
const TranscriptTimeDetails = styled.div`
  display: flex;
`;

const ListItems = styled.li<{ recorded: boolean }>`
  margin-bottom: 16px;
  position: relative;
  border-radius: 3px;
  border: solid 1px #eeeeee;
  cursor: ${(props) => (props.recorded ? 'pointer' : 'default')};
  pointer-events: ${(props) => (props.recorded ? 'auto' : 'none')};
  &:hover {
    border: solid 1px rgba(76, 83, 93, 0.24);
  }
`;

export const DotSeparator = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-25%);
  margin: 0px 4px;
}`;

interface Props {
  isCbr: boolean;
  key: string;
  title: string;
  meetingStartTime: string;
  startTime: number;
  handleRecording: (session: SessionFromElasticSearch) => void;
  session: SessionFromElasticSearch;
  content: string;
  sessionId: string;
  disableOnClickOutside: () => void;
}

export const TranscriptsTabRow: React.FC<Props> = (props: Props) => {
  const { sessionId, meetingStartTime, startTime, isCbr, handleRecording, title, content } = props;
  const meetingDate = evaluateMeetingYear(startTime);

  const dispatch = useDispatch();
  const [viewOpenButton, setViewOpenButton] = useState(false);

  const handleUserMouseEnter = () => {
    setViewOpenButton(true);
  };

  const handleUserMouseLeave = () => {
    setViewOpenButton(false);
  };

  const openSearchResultView = () => {
    const id = sessionId.split('-')[1];
    props.disableOnClickOutside();
    if (id) {
      dispatch(fetchSingleSession(id));
      dispatch(triggerTelemetryElasticSearch('sessionPreviewFromGlobalSearch'));
      dispatch(selectMeeting(SelectableItemType.ELASTICSEARCHRESULTSELECTED, ''));
    }
  };

  return (
    <ListItems
      recorded={isCbr}
      onMouseEnter={handleUserMouseEnter}
      onMouseLeave={handleUserMouseLeave}
      data-testid="list-container"
    >
      <SearchListContent onClick={openSearchResultView} data-testid="search-list">
        <TranscriptsTabContainer>
          <Box flexDirection="column">
            <TranscriptsSubject>
              <TranscriptTitle dangerouslySetInnerHTML={{ __html: title }} />
              <TranscriptTimeDetails>
                <MeetingDetails>{meetingDate}</MeetingDetails>
                {meetingStartTime && <DotSeparator>.</DotSeparator>}
                <MeetingDetails>{meetingStartTime}</MeetingDetails>
              </TranscriptTimeDetails>
            </TranscriptsSubject>
            <TranscriptsTextContainer dangerouslySetInnerHTML={{ __html: `"${content}"` }} />
          </Box>
        </TranscriptsTabContainer>
      </SearchListContent>
      {viewOpenButton && (
        <OpenRecordingContainer>
          <OpenSearchRecordingResultButton onClick={() => handleRecording(props.session)} />
        </OpenRecordingContainer>
      )}
    </ListItems>
  );
};
