import Cookies from 'js-cookie';

const instance = Cookies.withConverter({
  read: (encodedValue, _) => {
    return atob(encodedValue);
  },
  write: (value, _) => {
    return btoa(value as string);
  }
});

export default instance;
