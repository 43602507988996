import styled from '../../../styled-components';
import { FormattedMessage } from 'react-intl';
import { WrappedComponentProps, injectIntl, defineMessages } from 'react-intl';
import device from '../../Common/Toolkit/devices';
import * as React from 'react';
import { FC, memo } from 'react';

const messages = defineMessages({
  organizerLabel: {
    id: 'meetingHub.organizerList.organizerLabel',
    defaultMessage: 'Organizer'
  },
  coOrganizerLabel: {
    id: 'meetingHub.organizerList.coOrganizerLabel',
    defaultMessage: 'Co-organizers'
  }
});

interface Props {
  className?: string;
  organizer: string;
  coorganizers?: string;
}

const OrganizerListLayout: FC<Props & WrappedComponentProps> = (props) => {
  return props.organizer && props.coorganizers ? (
    <div className={props.className}>
      <div>
        <div>
          <FormattedMessage {...messages.organizerLabel} />: {props.organizer}
        </div>
        <div>
          <FormattedMessage {...messages.coOrganizerLabel} />: {props.coorganizers}
        </div>
      </div>
    </div>
  ) : null;
};

export const OrganizerListStyled = styled(memo(OrganizerListLayout))<Props & WrappedComponentProps>`
  padding-bottom: 24px;

  @media ${device.tablet} {
    padding-bottom: 16px;
  }

  > div {
    border-bottom: 1px solid #f0f2f5;
    padding-bottom: 24px;

    @media ${device.tablet} {
      padding-bottom: 16px;
    }
  }
`;

export const OrganizerList = injectIntl(OrganizerListStyled);
