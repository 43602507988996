import audio from './icons/main.audio.svg';
import excel from './icons/main.excel.svg';
import image from './icons/main.image.svg';
import pdf from './icons/main.pdf.svg';
import powerpoint from './icons/main.powerpoint.svg';
import video from './icons/main.video.svg';
import word from './icons/main.word.svg';
import zip from './icons/main.zip.svg';

const imageMap: { [key: string]: string } = {
  'application/msword': word,
  'application/pdf': pdf,
  'application/vnd.ms-excel': excel,
  'application/vnd.ms-powerpoint': powerpoint,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': powerpoint,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': excel,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': word,
  'application/x-compressed': zip,
  'application/x-zip-compressed': zip,
  'application/zip': zip,
  'audio/aac': audio,
  'audio/mp4': audio,
  'audio/mpeg': audio,
  'audio/ogg': audio,
  'audio/wav': audio,
  'audio/webm': audio,
  'audio/x-wav': audio,
  'image/gif': image,
  'image/jpeg': image,
  'image/png': image,
  'video/mp4': video,
  'video/ogg': video,
  'video/webm': video,
  'video/x-ms-wmv': video,
  'video/x-msvideo': video
};

export default imageMap;
