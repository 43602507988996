import { RosterActions, Selectors } from '@getgo/caps-redux';
import Events from './EventConstants';
import { navigateTo } from '../services/NavService';
import { getNextContactJid, getNextContactJidWithHistory } from './selectors';
import { Thunk } from './type-helpers';
import { AnyTODO } from '../../types/pulse-web';

export const selectContact = (roomId: string): Thunk => (dispatch) => {
  // ensure active contact is switched immediately, not just after navigation (async)
  dispatch(RosterActions.selectContact(roomId));
  navigateTo('chatConversation', { roomId });
};

export const selectInitialContact = (roomId: string | null): Thunk => (dispatch) => {
  dispatch(RosterActions.selectContact(roomId));

  dispatch({
    type: Events.INITIAL_CONTACT_SELECTED,
    data: { roomId }
  } as AnyTODO);

  return dispatch(RosterActions.joinRoom(roomId));
};

export const selectFirstContact = (exceptJid?: string): Thunk => (dispatch, getState) => {
  const contacts = Selectors.getContacts(getState()).items;
  if (contacts.length) {
    let toSelect: string | null = contacts[0].jid;

    if (toSelect === exceptJid) {
      toSelect = contacts.length > 1 ? contacts[1].jid : null;
    }

    return dispatch(selectInitialContact(toSelect));
  }

  return null;
};

export const restoreSelectedContact = (): Thunk => (dispatch, getState) => {
  const selectedContact = Selectors.getSelectedContact(getState());
  if (selectedContact) {
    return dispatch(selectInitialContact(selectedContact.jid));
  }

  return dispatch(selectFirstContact());
};

export const selectNextContact = (jid: string): Thunk => (dispatch, getState) => {
  const nextContactJid = getNextContactJid(getState(), jid);
  if (!nextContactJid) {
    navigateTo('chat');
    return null;
  }

  return dispatch(selectContact(nextContactJid));
};

export const selectNextContactWithHistory = (jid: string): Thunk => (dispatch, getState) => {
  const nextContactJid = getNextContactJidWithHistory(getState(), jid);
  if (!nextContactJid) {
    navigateTo('chat');
    return null;
  }

  return dispatch(selectContact(nextContactJid));
};
