import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import config from 'config';
import styled from '../../../styled-components';
import { themeGet } from '@styled-system/theme-get';
import device from '../../Common/Toolkit/devices';
import { isMac } from '../../../services/ClientService';
import { PreferencesBlock } from '../../Common/Preferences/PreferencesBlock';
import sharedActions from '../../../view-model/sharedActions';
import { Link } from '../../Common/Toolkit/Link/Link';

const messages = defineMessages({
  heading: {
    id: 'customBackgroundPreferences.title',
    defaultMessage: 'Customize your background with ChromaCam'
  },
  description: {
    id: 'customBackgroundPreferences.description',
    defaultMessage: 'ChromaCam is an app that lets you blur or replace your background in GoTo Meeting.'
  },
  learnMore: {
    id: 'customBackgroundPreferences.learnMore',
    defaultMessage: 'Learn more about ChromaCam'
  },
  getStarted: {
    id: 'customBackgroundPreferences.getStarted',
    defaultMessage: 'To get started with ChromaCam:'
  },
  getStartedStep1: {
    id: 'customBackgroundPreferences.getStartedStep1',
    defaultMessage: 'Close GoTo Meeting.'
  },
  getStartedStep2: {
    id: 'customBackgroundPreferences.getStartedStep2',
    defaultMessage:
      'Download and install the ChromaCam app (Note: you must have administrative rights on your computer to install)'
  },
  getStartedStep3: {
    id: 'customBackgroundPreferences.getStartedStep3',
    defaultMessage:
      'When prompted, sign in to ChromaCam using your GoTo Meeting username and password to activate the app.'
  },
  getStartedStep4: {
    id: 'customBackgroundPreferences.getStartedStep4',
    defaultMessage: 'Open GoTo Meeting and start a new session.'
  },
  getStartedStep5: {
    id: 'customBackgroundPreferences.getStartedStep5',
    defaultMessage: 'In camera settings, select ChromaCam from the webcam list and then choose your custom background.'
  },
  getStartedHowToTurnOff: {
    id: 'customBackgroundPreferences.getStartedHowToTurnOff',
    defaultMessage:
      'To turn off ChromaCam, or if the app slows down your computer, select another camera from the webcam list.'
  },
  systemRequirements: {
    id: 'customBackgroundPreferences.systemRequirements',
    defaultMessage: 'Minimum requirements for using ChromaCam:'
  },
  systemRequirementsOs: {
    id: 'customBackgroundPreferences.systemRequirementsOs',
    defaultMessage: 'Windows 8, 10, 11 or macOS Mojave (10.14) or newer'
  },
  systemRequirementsCpu: {
    id: 'customBackgroundPreferences.systemRequirementsCpu',
    defaultMessage: 'Intel 6th gen i5 quad-core or higher processor running at 3.3GHz or higher'
  },
  systemRequirementsRam: {
    id: 'customBackgroundPreferences.systemRequirementsRam',
    defaultMessage: '4GB + RAM'
  },
  systemRequirementsOpenCl: {
    id: 'customBackgroundPreferences.systemRequirementsOpenCl',
    defaultMessage: 'OpenCL 1.2'
  },
  chromaCamInfo: {
    id: 'customBackgroundPreferences.chromaCamInfo',
    defaultMessage: 'ChromaCam is a 3rd-party application and subject to the terms and conditions of Personify, Inc.'
  },
  download: {
    id: 'customBackgroundPreferences.download',
    defaultMessage: 'Download ChromaCam'
  }
});

const PreferencesPage = styled.div`
  color: ${themeGet('colors.neutralScale.400')};
  max-width: 640px;
  margin: 0 0 30px 4px;
  display: inline-block;
`;

const CustomDescription = styled.p`
  margin-top: 5px;
  color: ${themeGet('colors.neutralScale.400')};
`;

const StyledLink = styled(Link)`
  margin-left: 4px;
`;

const GetStartedSection = styled.div`
  p {
    margin: 25px 0 0 0;
  }

  ol {
    margin-top: 0;
    padding-left: 0;
    list-style: inside decimal;
  }
`;

const SystemRequirementsSection = styled.div`
  p {
    margin: 20px 0 0 0;
  }

  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 1em;
  }

  ul > li:before {
    display: inline-block;
    content: '-';
    width: 1em;
    margin-left: -1em;
  }
`;

const ChromaCamInfo = styled.p`
  margin: 24px 0;
`;

const DownloadButton = styled.a`
  display: inline-flex;
  text-decoration: none;
  flex-grow: 1;
  background: ${themeGet('colors.primary')};
  border-radius: 4px;
  color: ${themeGet('colors.primaryOn')};
  fill: ${themeGet('colors.primaryOn')};
  white-space: nowrap;
  height: 36px;
  line-height: 32px;
  padding: 0 16px;
  outline: none;
  align-items: center;

  &:focus {
    box-shadow: 0 0 0 1px ${themeGet('colors.surface')}, 0 0 0 3px ${themeGet('colors.primary')};
  }

  &:hover {
    background: ${themeGet('colors.hover.primary')};
  }

  @media ${device.mobile} {
    height: 32px;
    line-height: 28px;
  }
`;

export const CustomBackgroundPreferences: React.FC = () => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();

  return (
    <PreferencesPage>
      <PreferencesBlock titleMessage={messages.heading} badge="beta">
        <CustomDescription>
          {f(messages.description)}
          <StyledLink href={config.externalLinks.chromaCamLearnMoreLink} target="_blank" rel="noopener noreferer">
            {f(messages.learnMore)}
          </StyledLink>
        </CustomDescription>
        <GetStartedSection>
          <p>{f(messages.getStarted)}</p>
          <ol>
            <li>{f(messages.getStartedStep1)}</li>
            <li>{f(messages.getStartedStep2)}</li>
            <li>{f(messages.getStartedStep3)}</li>
            <li>{f(messages.getStartedStep4)}</li>
            <li>{f(messages.getStartedStep5)}</li>
          </ol>
          <p>{f(messages.getStartedHowToTurnOff)}</p>
        </GetStartedSection>
        <SystemRequirementsSection>
          <p>{f(messages.systemRequirements)}</p>
          <ul>
            <li>{f(messages.systemRequirementsOs)}</li>
            <li>{f(messages.systemRequirementsCpu)}</li>
            <li>{f(messages.systemRequirementsOpenCl)}</li>
            <li>{f(messages.systemRequirementsRam)}</li>
          </ul>
        </SystemRequirementsSection>
        <ChromaCamInfo>{f(messages.chromaCamInfo)}</ChromaCamInfo>
        <DownloadButton
          data-testid="chroma-cam-download-link"
          href={
            isMac ? config.externalLinks.chromaCamDownloadLinkMac : config.externalLinks.chromaCamDownloadLinkWindows
          }
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => dispatch(sharedActions.chromaCamDownloaded())}
        >
          {f(messages.download)}
        </DownloadButton>
      </PreferencesBlock>
    </PreferencesPage>
  );
};
