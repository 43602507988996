import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '../../Common/Avatar/Avatar';
import { OnlineStatus } from '../../Common/OnlineStatus/OnlineStatus';
import { UnreadCount } from '../../Common/UnreadCount/UnreadCount';
import { getRouteFor } from '../../../services/NavService';
import { MediaQueryFeatureSelector } from '../../Common/MediaQueryFeatureSelector';
import { getAvatarTooltipText } from '../../../services/TooltipHelper';
import { State } from '../../../view-model/types';
import styled from '../../../styled-components';
import { LockClosed, PeopleFilled } from '../../Common/Toolkit/Icon/ReactIcons';
import { useIntl } from 'react-intl';

export const PositionedOnlineStatus = styled(OnlineStatus)`
  margin: 0 10px 0 0;
`;

export const AlignedPeopleFilled = styled(PeopleFilled)`
  vertical-align: middle;
`;

export const AlignedLockClosed = styled(LockClosed)`
  vertical-align: middle;
`;

export const GroupAvatar = styled(Avatar)`
  margin-left: 0;
`;

interface Props {
  isOnline?: boolean;
  isSelected?: boolean;
  onRemove: (jid: string, selected: boolean) => void;
  onSelect: (jid: string) => void;
  room: State['messaging']['contacts']['items'][0];
  unreadCount?: number;
  userStatus?: 'available' | 'chat' | 'dnd' | 'away';
}

export const RoomRow: React.FC<Props> = (props) => {
  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const { jid, selected } = props.room;
    props.onRemove(jid, selected);
  };

  const handleSelect = () => {
    props.onSelect(props.room.jid);
  };

  const { isSelected = false, isOnline = true, unreadCount = 0, userStatus } = props;
  const { formatMessage: f } = useIntl();
  const onlineStatus = isOnline && props.room.status === 'online';
  const status = onlineStatus ? 'online' : 'offline';
  const rootClass = classNames('room', status, {
    group: props.room.isGroup,
    'old-group': props.room.isGroup && props.room.error,
    selected: isSelected,
    'has-unread': unreadCount > 0
  });
  const title = props.room.isGroup ? props.room.title : '';

  let removeButton;
  if (isOnline) {
    removeButton = props.room.isGroup ? null : (
      <button data-testid="room-remove-button" className="btn-icon remove-button" onClick={handleRemove}>
        <i className="togo-icon togo-icon-closes" />
      </button>
    );
  }

  const isAnExternalUser = !props.room.isGroup && props.room.isExternal;
  const isAGroupThatHasExternalUsers = props.room.isGroup && props.room.hasExternalMembers;

  let groupIcon;
  if (props.room.isGroup) {
    const icon = props.room.isPublic ? AlignedPeopleFilled : AlignedLockClosed;
    groupIcon = (
      <GroupAvatar
        icon={icon}
        size="small"
        backgroundColor={'transparent'}
        color={'#444444'}
        transparent={isAGroupThatHasExternalUsers}
        tooltip={getAvatarTooltipText(f, props.room.isPublic, props.room.hasExternalMembers)}
      />
    );
  }

  return (
    <li>
      <Link
        to={getRouteFor('chatConversation', { roomId: props.room.jid })}
        onClick={handleSelect}
        className={rootClass}
        title={title}
      >
        {!props.room.isGroup && (
          <PositionedOnlineStatus
            isExternal={isAnExternalUser}
            userStatus={onlineStatus && userStatus ? userStatus : 'offline'}
          />
        )}
        {groupIcon}
        <span
          data-testid="room-name"
          className={classNames('name', { external: isAnExternalUser || isAGroupThatHasExternalUsers })}
        >
          {props.room.name}
        </span>
        <UnreadCount count={unreadCount} testId="unread-count" />
        <MediaQueryFeatureSelector query="(hover:none)" queryMatched={null} queryNotMatched={removeButton} />
      </Link>
    </li>
  );
};
