import * as t from 'io-ts';
import { Response } from 'superagent';
import rest from '../lib/rest';
import config from 'config';
import { ConcurrentMeetingError } from './Errors';
import { AsyncSettingGroupValues } from '../app/view-model/modules/core/preferences/preferencesReducer';
import { encodeObjectIntoURI } from '../lib/url';
import { UnwrapPromise } from '../app/view-model/type-helpers';
import { AudioSettingItems } from '../lib/audio';
import logger from '../app/services/LoggerService';
import { Profile } from '../app/view-model/modules/meetings/MeetingsTypes';
import { MeetingRequest } from '../app/view-model/modules/scheduler/schedulerReducer';
import { SessionUpdates } from '../app/view-model/modules/meetings/meetingsReducer';

export const tMeetingType = t.union([t.literal('scheduled'), t.literal('impromptu'), t.literal('recurring')]);

// TODO GTMTWO-59612
const tBrandTheme = t.intersection([
  t.type({
    themeId: t.string,
    title: t.string,
    availabilityLevel: t.string
  }),
  t.partial({
    publishMode: t.union([t.string, t.null])
  })
]);

const tRecordingArtifactType = t.intersection([
  t.type({
    artifactType: t.string
  }),
  t.partial({
    downloadUrl: t.string
  })
]);

const tAudioType = t.union([
  t.literal('voipAndPstn'),
  t.literal('voip'),
  t.literal('pstn'),
  t.literal('private'),
  t.literal('none')
]);

export const tOrganizer = t.intersection([
  t.type({
    key: t.string,
    organizerKey: t.string
  }),
  t.partial({
    firstName: t.string,
    lastName: t.string,
    email: t.string
  })
]);

const tScreenSharing = t.intersection([
  t.type({
    sessionId: t.string,
    attendeeToken: t.string,
    sessionSecret: t.string,
    commProtocolVersion: t.number,
    nativeEPVersion: t.number,
    stripeMembers: t.array(
      t.type({
        participantId: t.number,
        servers: t.array(
          t.intersection([
            t.type({
              name: t.string,
              ipAddress: t.string,
              ports: t.array(t.number)
            }),
            t.partial({
              dnsName: t.string
            })
          ])
        )
      })
    )
  }),
  t.partial({
    organizerToken: t.string,
    recorderToken: t.string,
    delegationToken: t.string
  })
]);

export const tBasicMeeting = t.intersection([
  t.type({
    meetingId: t.string,
    subject: t.string,
    meetingType: tMeetingType,
    organizer: tOrganizer
  }),
  t.partial({
    startWithoutOrganizerSupported: t.boolean,
    scheduledStartTime: t.string,
    scheduledEndTime: t.string,
    displayTimeZone: t.string,
    passwordRequired: t.boolean,
    breakoutsAllowed: t.boolean,
    inSession: t.boolean,
    screenSharing: t.union([tScreenSharing, t.null]),
    invitationType: t.string,
    audio: t.intersection([
      t.type({
        audioType: tAudioType
      }),
      t.partial({
        privateMessage: t.string,
        tollCountries: t.array(t.string),
        tollFreeCountries: t.array(t.string),
        dialOutCountries: t.array(t.string),
        isDialOut: t.boolean,
        phoneNumbers: t.array(
          t.type({
            country: t.string,
            displayNumber: t.string,
            e164Number: t.string,
            tollFree: t.boolean
          })
        ),
        dialOutInfo: t.array(
          t.type({
            country: t.string
          })
        )
      })
    ]),
    coorganizers: t.array(tOrganizer)
  })
]);

export const tMeeting = t.intersection([
  tBasicMeeting,
  t.partial({
    meetingUrl: t.intersection([
      t.type({
        meetingUrl: t.string,
        profileId: t.string,
        roomName: t.string
      }),
      t.partial({
        theme: t.string
      }),
      t.partial({
        sessionId: t.string
      })
    ])
  })
]);

export const tMeetings = t.array(
  t.intersection([
    tBasicMeeting,
    t.partial({
      meetingUrl: t.string
    })
  ])
);

const tMeetingCreated = t.type({
  meetingId: t.string,
  meetingType: tMeetingType,
  subject: t.string,
  passwordRequired: t.boolean,
  startWithoutOrganizerSupported: t.boolean,
  audio: t.type({
    audioType: tAudioType
  })
});

export type CreateMeetingResponse = t.TypeOf<typeof tMeetingCreated>;

const tScheduledMeetingCreated = t.intersection([
  tMeetingCreated,
  t.type({
    scheduledStartTime: t.string,
    scheduledEndTime: t.string
  })
]);

export const tPastSession = t.intersection([
  t.type({
    sessionId: t.string,
    meetingId: t.string,
    subject: t.string,
    meetingType: tMeetingType,
    startTime: t.string,
    shareRecording: t.any,
    attendees: t.array(
      t.partial({
        name: t.string,
        email: t.string,
        joinTime: t.string,
        leaveTime: t.string,
        build: t.string
      })
    ),
    organizer: t.intersection([
      t.type({
        organizerKey: t.string
      }),
      t.partial({
        firstName: t.string,
        lastName: t.string
      })
    ])
  }),
  t.partial({
    diagnosticsProcessingStatus: t.union([t.literal('available'), t.literal('unavailable'), t.literal('processing')]),
    endTime: t.string,
    recording: t.intersection([
      t.type({
        recordingId: t.string,
        stored: t.boolean,
        transcriptEnabled: t.boolean,
        recordingArtifacts: t.array(tRecordingArtifactType)
      }),
      t.partial({
        downloadUrl: t.string
      })
    ])
  })
]);

export const tProfile = t.intersection([
  t.type({
    profileId: t.string,
    meetingId: t.string,
    theme: t.string
  }),
  t.partial({
    created: t.string,
    avatarUrl: t.string,
    html5: t.boolean,
    html5Present: t.boolean,
    location: t.string,
    name: t.string,
    profileUrl: t.string,
    title: t.string
  })
]);

const tRoom = t.intersection([
  t.type({
    userKey: t.string,
    meetingId: t.string,
    theme: t.string,
    profile: tProfile
  }),
  t.partial({
    name: t.string,
    created: t.string
  })
]);

const tStartUrl = t.type({
  hostURL: t.string
});

/* The following backend convention/naming applies
allowed => enabled/disabled in the UI (grayed out)
default => checked/unchecked in the UI (usually checkboxes)
 */
const tAudio = t.partial({
  allowedModes: t.array(t.string),
  defaultModes: t.array(t.string),
  allowedTollCountries: t.array(t.string),
  defaultTollCountries: t.array(t.string),
  preferredTollCountry: t.string,
  allowedTollFreeCountries: t.array(t.string),
  defaultTollFreeCountries: t.array(t.string),
  preferredTollFreeCountry: t.string,
  allowedDialOutCountries: t.array(t.string),
  defaultDialOutCountries: t.array(t.string),
  defaultModeratorPrivateMessage: t.string
});

export enum AudioModeTypes {
  VOIP = 'VOIP',
  TOLL = 'TOLL',
  TOLLFREE = 'TOLLFREE',
  DIALOUT = 'DIALOUT',
  PRIVATE = 'PRIVATE'
}

export type MeetingSettings = ReturnType<typeof validateAndParseMeetingSettings>;

export type BrandTheme = t.TypeOf<typeof tBrandTheme>;

interface MeetingSettingsUpdate {
  transcriptsEnabled: boolean;
  shareRecordingContentEnabled: boolean;
  videoInsightsEnabled: boolean;
  webViewerDefault: boolean;
  presenterMode: 'open' | 'moderated';
  defaultModes: AudioModeTypes[];
  defaultTollCountries: string[];
  preferredTollCountry: string;
  defaultTollFreeCountries: string[];
  preferredTollFreeCountry: string;
  defaultDialOutCountries: string[];
  defaultModeratorPrivateMessage: string;
  isStandaloneAudioPinEnforced: boolean;
  shareRecordingLinkExpirationInDays: number;
}

export interface ElasticSearchPayload {
  accountKey: string;
  product: string;
  textSearch: {
    [key: string]: string[];
  };
  userKey: string;
  fromTime: number;
  toTime: number;
  isCbr: boolean;
  aggregations?: {
    fieldName: string;
    size: number;
  }[];
}

export const responseTypes = {
  // Chat session state service: https://confluence.ops.expertcity.com/display/collaboration/Chat+Session+State+Service+-+API
  createMeetingCSSS: t.type({
    meetingId: t.union([t.string, t.number])
  }),

  // Meeting Service: https://confluence.ops.expertcity.com/display/collaboration/Meeting+Service+REST+API+2.0
  createMeeting: t.union([tMeetingCreated, tScheduledMeetingCreated]),
  getSessionDetails: t.partial({
    delegationToken: t.union([t.string, t.null])
  }),
  getCurrentSession: t.type({
    meetingId: t.union([t.string, t.number])
  }),
  getMeeting: tMeeting,
  getMeetings: tMeetings,
  getMeetingsHistory: t.intersection([
    t.type({
      sessions: t.array(tPastSession),
      size: t.number,
      total: t.number
    }),
    t.partial({
      page: t.number,
      next: t.number,
      previous: t.number,
      from: t.string,
      to: t.string
    })
  ]),
  getSession: tPastSession,
  getRoom: t.intersection([
    t.type({
      name: t.string,
      userKey: t.string,
      meetingId: t.string,
      theme: t.string,
      profile: tProfile
    }),
    t.partial({
      created: t.string
    })
  ]),
  getSettings: t.partial({
    webViewerAllowed: t.boolean,
    webViewerDefault: t.boolean,
    presenterMode: t.union([t.literal('open'), t.literal('moderated')]),
    allowLogoDeletion: t.boolean,
    audio: tAudio,
    isDialOutEnabled: t.boolean,
    isDialOutDefault: t.boolean,
    cbrProvisioned: t.boolean,
    cbrEntitled: t.boolean,
    cbrEnabled: t.boolean,
    transcriptsProvisioned: t.boolean,
    transcriptsEntitled: t.boolean,
    transcriptsEnabled: t.boolean,
    shareRecordingContentEnabled: t.boolean,
    videoInsightsProvisioned: t.boolean,
    videoInsightsEntitled: t.boolean,
    videoInsightsEnabled: t.boolean,
    conferenceModeratorPin: t.string,
    isStandaloneAudioProvisioned: t.boolean,
    isStandaloneAudioEntitled: t.boolean,
    isStandaloneAudioEnabled: t.boolean,
    isStandaloneAudioPinEnforced: t.boolean,
    isCoorganizerFeatureEnabled: t.boolean,
    isOpenMeetingsProvisioned: t.boolean,
    isOpenMeetingsEntitled: t.boolean,
    isOpenMeetingsDefault: t.boolean,
    ux2019ControllableByUser: t.boolean,
    ux2019Enabled: t.boolean,
    meetingHubControllableByUser: t.boolean,
    isBrandingProvisioned: t.boolean,
    isBrandingEntitled: t.boolean,
    chromaCamEnabled: t.boolean,
    chromaCamProvisioned: t.boolean,
    shareRecordingLinkExpirationInDays: t.number,
    breakoutsAllowed: t.boolean,
    gotoAppProvisioned: t.boolean,
    gotoAppEntitled: t.boolean,
    gotoAppEnabled: t.boolean
  }),
  getAvailableThemes: t.array(tBrandTheme),
  getCoOrganizers: t.type({
    coorganizers: t.array(tOrganizer)
  }),

  // Meeting Service (Profile API): https://confluence.ops.expertcity.com/display/collaboration/Profiles+and+Meeting+Room+APIs
  // searchProfile: t.union([t.undefined, t.null, tProfile])
  searchProfile: t.union([tProfile, t.undefined, t.null]),
  getProfile: tProfile,
  searchProfileById: t.union([tProfile, t.undefined, t.null]),
  searchRoomByUserKeyAndName: t.array(t.any),

  // Sharing Service: https://confluence.ops.expertcity.com/pages/viewpage.action?pageId=117585217
  createShare: t.intersection([
    t.type({
      created: t.number,
      expires: t.number,
      scope: t.array(
        t.type({
          type: t.string
        })
      ),
      shareKey: t.string
    }),
    t.partial({
      accountKey: t.string,
      sessionKey: t.string,
      userKey: t.string
    })
  ]),
  // Invitation Service: https://confluence.ops.expertcity.com/display/collaboration/Invitation+Service
  getInvitationUrl: t.string,
  getAlternatePhoneNumbers: t.partial({
    audioType: t.union([tAudioType]),
    accessCode: t.string,
    phoneNumbers: t.array(
      t.intersection([
        t.type({
          country: t.string,
          displayNumber: t.string,
          e164Number: t.string,
          tollFree: t.boolean
        })
      ])
    )
  })
};

export type AlternatePhoneNumbers = t.TypeOf<typeof responseTypes.getAlternatePhoneNumbers>;

export const getShareUrl = (shareKey: string) => `${config.externalLinks.g2mTranscripts}/#/s/${shareKey}`;

export const isScopePublic = (scope: Array<{ type: string }> = []) =>
  scope.some(({ type }) => type === 'PUBLIC' || type === 'EMAIL');

export const isRecordingShareAvailable = (scope: Array<any> = []) =>
  scope.some(({ type, value }) => type === 'PUBLIC' || (type === 'EMAIL' && value));

export const isRecordingLinkExpired = (recordingExpiry: number) => {
  if (recordingExpiry === 0) {
    return false;
  }
  return recordingExpiry < new Date().getTime();
};

const createImpromptuSettings = () => ({
  meetingType: 'impromptu',
  subject: 'Meet Now',
  video: {
    protocolVersion: '4'
  }
});

export const createImpromptuMeeting = () => {
  const meetingSettings = createImpromptuSettings();
  const url = `${config.rest.meetingService}/rest/2/meetings`;
  return rest.postValidated(responseTypes.createMeeting, url, meetingSettings, { clientName: true });
};

export const createCSSSMeeting = async (targetJid: string): Promise<string> => {
  const url = `${config.rest.chatSessionState}/v1/session`;
  try {
    const response = await rest.postValidated(responseTypes.createMeetingCSSS, url, { targetJid });
    return `${response.meetingId}`;
  } catch (err) {
    if (err?.status === 409) {
      throw new ConcurrentMeetingError();
    }

    throw err;
  }
};

export const deleteSession = async (meeting: {
  meetingId: string;
  screenSharing?: null | { delegationToken?: string };
}) => {
  const { meetingId, screenSharing } = meeting;
  let delegationToken: string | null | undefined = screenSharing?.delegationToken;

  if (!delegationToken) {
    const sessionDetails = await getSessionDetails(meetingId);
    delegationToken = sessionDetails?.delegationToken;

    if (!delegationToken) {
      throw new Error('Could not get delegation token.');
    }
  }

  const url = `${config.rest.meetingService}/rest/2/meetings/${meetingId}/session`;
  await rest.deleteValidated(t.any, url, {
    tokenType: 'Delegation',
    token: delegationToken,
    clientName: true
  });
};

export const getElasticSearchResults = async (payload: ElasticSearchPayload, offset: number, size: number) => {
  const baseUrl = `${config.rest.searchOrchestrationService}/api/v1/sessions/search?size=${size}`;
  const url = !offset ? `${baseUrl}&offset=0` : `${baseUrl}&offset=${offset}`;
  return rest.postValidated(t.any, url, payload);
};

export const getAttendeesInfo = async (sessionId: string) => {
  const baseUrl = `${config.rest.sessionAnalytics}/v1/sessions/${sessionId}/details`;
  return rest.getValidated(t.any, baseUrl, { clientName: true });
};

export const deleteSessionRecording = async (sessionId: string) => {
  await rest.deleteValidated(t.any, `${config.rest.meetingService}/rest/sessions/${sessionId}/recording`, {
    clientName: true
  });
};

export const deleteMeeting = async (meetingId: string) => {
  await rest.deleteValidated(t.any, `${config.rest.meetingService}/rest/2/meetings/${meetingId}`, { clientName: true });
};

export const getSessionDetails = async (meetingId: string) => {
  const url = `${config.rest.meetingService}/rest/2/meetings/${meetingId}/session/screensharing`;
  return rest.getValidated(responseTypes.getSessionDetails, url, {}, { clientName: true });
};

export const getConcurrentSession = () => {
  const url = `${config.rest.meetingService}/rest/2/currentSession`;
  return rest.getValidated(responseTypes.getCurrentSession, url, {}, { clientName: true });
};

const getMeetingUrlFromUrl = (url: string) => {
  const match = /(?:\/([^\\.\/]+))?\/([^\/]+)$/.exec(url);
  if (!match) {
    logger.log('invalid meeting url: ', url);
    return {};
  }

  return {
    meetingUrl: url,
    roomName: match[1] ? match[2] : '',
    profileId: match[1] ? match[1] : match[2]
  };
};

export const getMyMeetings = async () => {
  const url = `${config.rest.meetingService}/rest/2/meetings?includeMeetingUrl=true`;
  const meetings = await rest.getValidated(responseTypes.getMeetings, url, {}, { clientName: true });
  return meetings.map((meeting) => {
    return {
      ...meeting,
      meetingUrl: meeting.meetingUrl
        ? {
            ...getMeetingUrlFromUrl(meeting.meetingUrl)
          }
        : undefined
    } as UnwrapPromise<ReturnType<typeof getMeeting>>;
  });
};

export const getHistory = (
  options: { page?: number; from?: Date; to?: Date; size?: number; order?: 'desc' | 'asc' } = {}
) => {
  const fromTimeStamp = options.from?.getTime();
  const toTimeStamp = options.to?.getTime();
  const url = `${config.rest.meetingService}/rest/2/history/sessions`;
  const opt = {
    from: fromTimeStamp || '000000000000',
    to: toTimeStamp,
    size: options.size || 50,
    page: options.page || 0,
    order: options.order || 'desc'
  };

  return rest.getValidated(responseTypes.getMeetingsHistory, url, opt, { clientName: true });
};

export const getCoorganizers = (query: string, excludes: string[]) => {
  const params = {
    query,
    excludes: excludes.join(',')
  };
  const url = `${config.rest.meetingService}/rest/2/coorganizers?${encodeObjectIntoURI(params)}`;
  return rest.getValidated(responseTypes.getCoOrganizers, url, {}, { clientName: true });
};

export const getMeeting = async (meetingId: string, fetchAll = false, connectionDetails = true) => {
  let url = `${config.rest.meetingService}/rest/2/meetings/${meetingId}`;
  const searchParams: Record<string, string> = {};
  if (connectionDetails) {
    searchParams.connectionDetails = 'true';
    searchParams.clientCapabilities = 'outOfSession';
  }
  if (fetchAll) {
    searchParams.includeMeetingUrl = 'true';
    searchParams.includeCoorganizers = 'true';
  }
  if (Object.keys(searchParams).length > 0) {
    url = `${url}?${Object.entries(searchParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`;
  }
  return rest.getValidated(responseTypes.getMeeting, url, {}, { clientName: true });
};

export const getSession = (sessionId: string) => {
  const url = `${config.rest.meetingService}/rest/2/history/sessions/${sessionId}`;
  return rest.getValidated(responseTypes.getSession, url, {}, { clientName: true });
};

export const getTranscriptShare = async (sessionKey: string) => {
  const url = `${config.rest.insights}/v1/sharing/session/${sessionKey}`;

  const res = await rest.getValidated(responseTypes.createShare, url);

  return {
    isPublic: isRecordingShareAvailable(res.scope),
    shareKey: res.shareKey,
    url: getShareUrl(res.shareKey),
    linkExpired: isRecordingLinkExpired(res.expires)
  };
};

export const fetchRecordingShare = async (sessionKey: string) => {
  const url = `${config.rest.insights}/v1/sharing/session/${sessionKey}`;
  return rest.getValidated(t.any, url);
};

export const createTranscriptShare = async (
  sessionKey: string,
  { accountKey, key: userKey }: { accountKey: string; key: string },
  isPublic = false
) => {
  const url = `${config.rest.insights}/v1/sharing/share`;

  const res = await rest.postValidated(responseTypes.createShare, url, {
    accountKey,
    scope: [
      {
        type: isPublic ? 'PUBLIC' : 'PRIVATE'
      }
    ],
    sessionKey,
    userKey
  });

  return {
    isPublic: isScopePublic(res.scope),
    shareKey: res.shareKey,
    url: getShareUrl(res.shareKey),
    linkExpired: isRecordingLinkExpired(res.expires)
  };
};

export const getProfile = () => {
  const url = `${config.rest.meetingService}/rest/2/search/profile`;
  return rest.getValidated(responseTypes.searchProfile, url, {}, { clientName: true });
};

export const getProfileById = async (profileId: string) => {
  const url = `${config.rest.meetingService}/rest/2/profiles/${profileId}`;
  return rest.getValidated(responseTypes.getProfile, url, {}, { clientName: true });
};

export const getProfileIdSuggestion = async (params: { firstName: string; lastName: string }): Promise<string> => {
  const url = `${config.rest.meetingRoomService}/proposal/profiles/profileId?${encodeObjectIntoURI(params)}`;
  return rest.getRaw(url, {}, { isNotJson: true }).then((response) => {
    return Promise.resolve(response.text);
  });
};

export const getRoomNameSuggestion = async (profileId: string, subject: string): Promise<string> => {
  const url = `${config.rest.meetingRoomService}/proposal/profiles/${profileId}/rooms/roomName?${encodeObjectIntoURI({
    subject
  })}`;
  return rest.getRaw(url, {}, { isNotJson: true }).then((response) => {
    return Promise.resolve(response.text);
  });
};

// for no existing profile, returns a empty object
export const searchProfileById = async (profileId: string) => {
  const url = `${config.rest.meetingService}/rest/2/search/profiles/${profileId}`;
  return rest.getValidated(responseTypes.searchProfileById, url, {}, { clientName: true });
};

export const updateProfile = async (profileId: string, profile: Profile) => {
  const url = `${config.rest.meetingService}/rest/2/profiles/${profileId}`;
  return rest.putRaw(url, profile, { clientName: true });
};

export const createProfile = async (profile: Profile) => {
  const url = `${config.rest.meetingService}/rest/2/profiles`;
  return rest.postRaw(
    url,
    {
      profileId: profile.profileId,
      theme: profile.theme
    },
    { clientName: true }
  );
};

export const updateMeeting = async (meetingId: string, meeting: Partial<MeetingRequest>) => {
  const url = `${config.rest.meetingService}/rest/2/meetings/${meetingId}`;
  return rest.putRaw(url, meeting, { clientName: true });
};

export const createMeeting = async (meeting: Partial<MeetingRequest>) => {
  const url = `${config.rest.meetingService}/rest/2/meetings`;
  return rest.postValidated(responseTypes.createMeeting, url, meeting, { clientName: true });
};

export const deleteCoorganizers = async (meetingId: string) => {
  const url = `${config.rest.meetingService}/rest/2/meetings/${meetingId}/coorganizers`;
  return rest.deleteRaw(url, { clientName: true });
};

export const searchRoomByUserKeyAndName = async (userKey: string, roomName: string) => {
  const url = `${config.rest.meetingRoomService}/search/room?userKey=${userKey}&roomName=${roomName}`;
  return rest.getValidated(responseTypes.searchRoomByUserKeyAndName, url, {}, { clientName: true });
};

export const getRoomByName = async (profileId: string, roomName: string) => {
  const url = `${config.rest.meetingService}/rest/2/profiles/${profileId}/rooms/${roomName}`;
  return rest.getValidated(responseTypes.getRoom, url, {}, { clientName: true });
};

export const getInvitationUrl = (meetingId: string) => {
  return `${config.rest.invitationService}/invitation/${meetingId}`;
};

export const getInvitation = async (meetingId: string, localePrefix: string) => {
  const mapText = (resp: Response) => {
    try {
      const json = JSON.parse(resp.text);
      if (Number(json.statusCode) !== 200) {
        return Promise.reject(json);
      }
    } catch (e) {
      // do nothing... the response is text
    }

    return Promise.resolve(resp.text);
  };

  const baseUrl = `${config.rest.invitationService}/invitation/${meetingId}`;
  const [html, text] = await Promise.all([
    rest.getRaw(`${baseUrl}?locale=${localePrefix}&template=hub&format=html&style=inline`).then(mapText),
    rest.getRaw(`${baseUrl}?locale=${localePrefix}&template=hub&format=txt`).then(mapText)
  ]);

  return {
    html,
    text
  };
};

export const fetchG2mSettings = () => {
  const url = `${config.rest.meetingService}/rest/2/settings`;
  return rest.getValidated(responseTypes.getSettings, url, {}, { clientName: true });
};

export const fetchAvailableThemes = async (): Promise<BrandTheme[] | undefined> => {
  const url = `${config.rest.brandingService}/api/themes/?include=available`;
  try {
    return await rest.getValidated(responseTypes.getAvailableThemes, url, {}, { clientName: true });
  } catch (err) {
    // fail silently, which results in showing old theme selector
    return undefined;
  }
};

export const fetchMeetingSettings = async () => {
  return validateAndParseMeetingSettings(await fetchG2mSettings());
};

export const updateG2MSettings = async (
  settings:
    | Partial<AsyncSettingGroupValues<'meetings'>>
    | Partial<AsyncSettingGroupValues<'ux2019Settings'>>
    | Partial<AsyncSettingGroupValues<'gotoAppEapSettings'>>
) => {
  const url = `${config.rest.meetingService}/rest/2/settings`;

  if ('ux2019Enabled' in settings) {
    // implicit type guard => settings is of type NewExperienceSettings
    await rest.putValidated(t.any, url, { ux2019Enabled: settings.ux2019Enabled }, { clientName: true });
  } else if ('gotoAppEnabled' in settings) {
    await rest.putValidated(t.any, url, { gotoAppEnabled: settings.gotoAppEnabled }, { clientName: true });
  } else {
    const updatedMeetingSettings = mapMeetingSettings(settings as Partial<AsyncSettingGroupValues<'meetings'>>);
    await rest.putValidated(t.any, url, updatedMeetingSettings, { clientName: true });
  }
};

function addAdditionalSettings(
  settings: Partial<AsyncSettingGroupValues<'meetings'>>,
  updatedSettings: Partial<MeetingSettingsUpdate>
) {
  // implicit disable if an empty array is sent => avoid invalid states
  if (settings.longDistanceAllowed && settings.longDistanceEnabled && settings.longDistanceCountries) {
    if (settings.longDistanceCountries && settings.longDistanceCountries.length !== 0) {
      (updatedSettings as any).defaultTollCountries = [...settings.longDistanceCountries];
      (updatedSettings as any).preferredTollCountry = settings.longDistanceCountries[0];
    } else {
      (updatedSettings as any).longDistanceEnabled = false;
    }
  }

  if (settings.tollFreeAllowed && settings.tollFreeEnabled) {
    if (settings.tollFreeCountries && settings.tollFreeCountries.length !== 0) {
      (updatedSettings as any).defaultTollFreeCountries = [...settings.tollFreeCountries];
      (updatedSettings as any).preferredTollFreeCountry = settings.tollFreeCountries[0];
    } else {
      (updatedSettings as any).tollFreeEnabled = false;
    }
  }

  if (settings.callMeAllowed && settings.callMeEnabled && settings.callMeCountries) {
    if (settings.longDistanceCountries && settings.longDistanceCountries.length !== 0) {
      (updatedSettings as any).defaultDialOutCountries = [...settings.callMeCountries];
    } else {
      (updatedSettings as any).defaultDialOutCountries = false;
    }
    (updatedSettings as any).defaultDialOutCountries = [...settings.callMeCountries];
  }
}

/* Note that the update call does not have the same for as the get call
 *  https://confluence.ops.expertcity.com/display/collaboration/Meeting+Service+REST+API+2.0
 * */
export const mapMeetingSettings = (
  settings: Partial<AsyncSettingGroupValues<'meetings'>>
): Partial<MeetingSettingsUpdate> => {
  const defaultModes = [];
  // default modes needs to have ONLY Private in the array Error "exactly 1 default mode expected"
  if (settings.ownConferenceAllowed && settings.ownConferenceEnabled) {
    defaultModes.push(AudioModeTypes.PRIVATE);
  } else {
    if (settings.voipAllowed && settings.voipEnabled) {
      defaultModes.push(AudioModeTypes.VOIP);
    }
    if (settings.longDistanceAllowed && settings.longDistanceEnabled) {
      defaultModes.push(AudioModeTypes.TOLL);
    }
    if (settings.tollFreeAllowed && settings.tollFreeEnabled) {
      defaultModes.push(AudioModeTypes.TOLLFREE);
    }
    if (settings.callMeAllowed && settings.callMeEnabled) {
      defaultModes.push(AudioModeTypes.DIALOUT);
    }
  }

  const updatedSettings: Partial<MeetingSettingsUpdate> = {
    transcriptsEnabled: !!settings.transcriptsEnabled,
    shareRecordingContentEnabled: !!settings.shareRecordingContentEnabled,
    videoInsightsEnabled: !!settings.videoInsightsEnabled,
    webViewerDefault: !!settings.webViewerDefault,
    presenterMode: settings.presenterMode || 'open',
    shareRecordingLinkExpirationInDays: settings.shareRecordingLinkExpirationInDays,
    defaultModes
  };

  if (
    settings.isStandaloneAudioEnabled &&
    settings.isStandaloneAudioEntitled &&
    settings.isStandaloneAudioProvisioned &&
    settings.conferenceModeratorPin &&
    settings.conferenceModeratorPin.length !== 0
  ) {
    (updatedSettings as any).conferenceModeratorPin = settings.conferenceModeratorPin;
    (updatedSettings as any).isStandaloneAudioPinEnforced = settings.conferenceModeratorPin.length > 0; // Once an organizerPIN has been set, the flag isStandaloneAudioPinEnforced will be set to true
  }

  if (settings.ownConferenceAllowed && settings.ownConferenceEnabled && settings.ownConferenceMessage) {
    (updatedSettings as any).defaultModeratorPrivateMessage = settings.ownConferenceMessage;
    // skip the other settings to avoid backend errors
    return updatedSettings;
  }
  addAdditionalSettings(settings, updatedSettings);
  return updatedSettings;
};

export const validateAndParseMeetingSettings = (settings: UnwrapPromise<ReturnType<typeof fetchG2mSettings>>) => {
  if (!settings?.presenterMode || !settings?.audio) {
    throw new Error('fetchG2MSettings returned a null or undefined property');
  }

  const voipAllowed = !!settings.audio.allowedModes?.includes('VOIP');
  const voipEnabled = voipAllowed && !!settings.audio.defaultModes?.includes('VOIP');

  const longDistanceAllowed = !!settings.audio.allowedModes?.includes('TOLL');
  const longDistanceEnabled = longDistanceAllowed && !!settings.audio.defaultModes?.includes('TOLL');
  const longDistanceCountries = settings.audio.defaultTollCountries || [];
  /* workaround for https://jira.ops.expertcity.com/browse/G2ME-682
  can be removed after settings revamp */
  if (settings.audio.preferredTollCountry) {
    const indexPosition = longDistanceCountries.indexOf(settings.audio.preferredTollCountry);
    // -1 preferred country is not in list, 0 it's already sorted
    if (indexPosition !== -1 && indexPosition !== 0) {
      longDistanceCountries.splice(indexPosition, 1);
      longDistanceCountries.unshift(settings.audio.preferredTollCountry);
    }
  }

  const tollFreeAllowed = !!settings.audio.allowedModes?.includes('TOLLFREE');
  const tollFreeEnabled = tollFreeAllowed && !!settings.audio.defaultModes?.includes('TOLLFREE');
  const tollFreeCountries = settings.audio.defaultTollFreeCountries || [];
  if (settings.audio.preferredTollFreeCountry) {
    const indexPosition = tollFreeCountries.indexOf(settings.audio.preferredTollFreeCountry);
    // -1 preferred country is not in list, 0 it's already sorted
    if (indexPosition !== -1 && indexPosition !== 0) {
      tollFreeCountries.splice(indexPosition, 1);
      tollFreeCountries.unshift(settings.audio.preferredTollFreeCountry);
    }
  }

  const ownConferenceAllowed = !!settings.audio.allowedModes?.includes('PRIVATE');
  const ownConferenceEnabled = ownConferenceAllowed && !!settings.audio.defaultModes?.includes('PRIVATE');

  return {
    webViewerAllowed: settings.webViewerAllowed != null ? settings.webViewerAllowed : true,
    webViewerDefault: !!settings.webViewerDefault,
    presenterMode: settings.presenterMode,
    voipEnabled,
    voipAllowed,
    longDistanceEnabled,
    longDistanceAllowed,
    longDistanceCountries,
    tollFreeEnabled,
    tollFreeAllowed,
    tollFreeCountries,
    callMeEnabled: !!(settings.isDialOutDefault && settings.isDialOutEnabled),
    callMeAllowed: !!settings.isDialOutEnabled,
    callMeCountries: settings.audio.defaultDialOutCountries || [],
    ownConferenceEnabled,
    ownConferenceAllowed,
    ownConferenceMessage: settings.audio.defaultModeratorPrivateMessage || '',
    allowedLongDistanceCountries: settings.audio.allowedTollCountries || [],
    allowedTollFreeCountries: settings.audio.allowedTollFreeCountries || [],
    allowedCallMeCountries: settings.audio.allowedDialOutCountries || [],
    cbrProvisioned: !!settings.cbrProvisioned,
    cbrEntitled: !!settings.cbrEntitled,
    cbrEnabled: !!settings.cbrEnabled,
    transcriptsProvisioned: !!settings.transcriptsProvisioned,
    transcriptsEntitled: !!settings.transcriptsEntitled,
    transcriptsEnabled: !!settings.transcriptsEnabled,
    shareRecordingContentEnabled: !!settings.shareRecordingContentEnabled,
    videoInsightsEntitled: !!settings.videoInsightsEntitled,
    videoInsightsEnabled: !!settings.videoInsightsEnabled,
    videoInsightsProvisioned: !!settings.videoInsightsProvisioned,
    conferenceModeratorPin: settings.conferenceModeratorPin || '',
    isStandaloneAudioProvisioned: !!settings.isStandaloneAudioProvisioned,
    isStandaloneAudioEntitled: !!settings.isStandaloneAudioEntitled,
    isStandaloneAudioEnabled: !!settings.isStandaloneAudioEnabled,
    isStandaloneAudioPinEnforced: !!settings.isStandaloneAudioPinEnforced,
    isCoorganizerFeatureEnabled: !!settings.isCoorganizerFeatureEnabled,
    isOpenMeetingsProvisioned: !!settings.isOpenMeetingsProvisioned,
    isOpenMeetingsEntitled: !!settings.isOpenMeetingsEntitled,
    isOpenMeetingsDefault: !!settings.isOpenMeetingsDefault,
    isBrandingProvisioned: settings.isBrandingProvisioned || false,
    isBrandingEntitled: settings.isBrandingEntitled || false,
    chromaCamEnabled: settings.chromaCamEnabled || false,
    chromaCamProvisioned: settings.chromaCamProvisioned || false,
    shareRecordingLinkExpirationInDays: settings.shareRecordingLinkExpirationInDays || 7,
    breakoutsAllowed: settings.breakoutsAllowed || false
  };
};

export const createAudioSettingItemsFromSettings = (settings: MeetingSettings): AudioSettingItems => ({
  ownConferenceEnabled: settings.ownConferenceEnabled,
  ownConferenceAllowed: settings.ownConferenceAllowed,
  ownConferenceMessage: settings.ownConferenceMessage,
  voipAllowed: settings.voipAllowed,
  voipEnabled: settings.voipEnabled,
  longDistanceEnabled: settings.longDistanceEnabled,
  longDistanceAllowed: settings.longDistanceAllowed,
  allowedLongDistanceCountries: settings.allowedLongDistanceCountries,
  longDistanceCountries: settings.longDistanceCountries,
  tollFreeAllowed: settings.tollFreeAllowed,
  tollFreeEnabled: settings.tollFreeEnabled,
  tollFreeCountries: settings.tollFreeCountries,
  allowedTollFreeCountries: settings.allowedTollFreeCountries,
  callMeEnabled: settings.callMeEnabled,
  callMeAllowed: settings.callMeAllowed,
  callMeCountries: settings.callMeCountries,
  allowedCallMeCountries: settings.allowedCallMeCountries
});

export const createMeetingSettingsItemsFromSettings = (settings: MeetingSettings): any => ({
  startWithoutOrganizerSupported:
    settings.isOpenMeetingsProvisioned && settings.isOpenMeetingsEntitled && settings.isOpenMeetingsDefault
});

export const fetchLogoSetting = async () => {
  const url = `${config.rest.meetingService}/rest/2/settings/logo`;
  try {
    return await rest.getImage(url, { clientName: true });
  } catch (err) {
    if (err.status === 500 || err.status === 404) {
      // 500 happens when logo is not found (should be 404..)
      return null;
    }
    throw err;
  }
};

export const fetchJson = async (url: string, options: any = {}) => {
  const response = await fetch(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return response
    .json()
    .then((res) => res)
    .catch(() => {});
};

export const removeLogoSetting = () => {
  const url = `${config.rest.meetingService}/rest/2/settings/logo`;
  return rest.deleteValidated(t.any, url, { clientName: true });
};

export const updateLogoSetting = (dataUrl: string) => {
  const url = `${config.rest.meetingService}/rest/2/settings/logo`;
  return rest.postImage(url, dataUrl, { clientName: true });
};

export const meetingIdFromProfileName = (profileName: string): Promise<string> => {
  const url = `${config.rest.meetingService}/rest/2/profiles/${profileName}`;
  return rest.getValidated(tProfile, url, {}, { clientName: true }).then((resp) => resp.meetingId);
};

export const meetingIdFromRoomName = (profileName: string, roomName: string): Promise<string> => {
  const url = `${config.rest.meetingService}/rest/2/profiles/${profileName}/rooms/${roomName}`;
  return rest.getValidated(tRoom, url, {}, { clientName: true }).then((resp) => resp.meetingId);
};

export const getStartUrl = (meetingId: string) => {
  const url = `${config.rest.meetingService}/rest/2/meetings/${meetingId}/startUrl`;
  return rest.getValidated(tStartUrl, url, {}, { clientName: true });
};

export const getAlternatePhoneNumbers = (meetingId: string) => {
  const url = `${config.rest.meetingService}/rest/2/meetings/${meetingId}/audio?alternate=true`;
  return rest.getValidated(responseTypes.getAlternatePhoneNumbers, url, {}, { clientName: true });
};

export const getSmartNotes = async (shareKey: string) => {
  const url = `${config.notifications.url}/v1/notes/product/g2m/shareKey/${shareKey}`;
  return rest.getValidated(t.any, url, {}, { clientName: true });
};

export const getChats = (chatUrl: string) => fetchJson(chatUrl);

export const getTranscriptions = async (transcriptURL: string) => {
  return fetchJson(transcriptURL);
};

export const getMeetingAnalytics = (shareKey: string) => {
  const url = `${config.rest.insights}/v2/products/g2m/${shareKey}/analytics`;
  return rest.getValidated(t.any, url, {}, { clientName: true });
};

export const updateMeetingSessionData = (sessionKey: string, userKey: string, sessionUpdate: SessionUpdates) => {
  const url = `${config.rest.insights}/v2/users/${userKey}/sessions/${sessionKey}/meetings`;
  return rest.putValidated(t.any, url, sessionUpdate);
};
