import scrollIntoView from 'scroll-into-view-if-needed';

export function isScrolledIntoViewPosition(container: HTMLElement, el: HTMLElement) {
  const { height, top: offset } = container.getBoundingClientRect();
  const { bottom, top } = el.getBoundingClientRect();

  if (top - offset >= height) {
    return -1;
  } else if (bottom - offset <= 0) {
    return 1;
  }

  return 0;
}

export const scrollIntoViewIfNeeded = scrollIntoView;
