// TODO remove later
export default class Paging {
  public nextPageKey: number;

  constructor(nextPageKey = 0) {
    this.nextPageKey = nextPageKey;
  }

  get hasPages() {
    return this.nextPageKey > 0;
  }
}
