import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from '../../../../styled-components';
import { themeGet } from '@styled-system/theme-get';

const messages = defineMessages({
  new: {
    id: 'common.badge.new',
    defaultMessage: 'New'
  },
  beta: {
    id: 'common.badge.beta',
    defaultMessage: 'Beta'
  }
});

interface Props {
  badge: 'new' | 'beta';
  marginleft?: string;
}

export const PreferencesBadge = styled(({ badge, ...rest }: Props) => {
  const { formatMessage: f } = useIntl();
  return (
    <span data-testid="badge" {...rest}>
      {f(messages[badge])}
    </span>
  );
})`
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
  color: #fff;
  background-color: ${themeGet('colors.primaryScale.500')};
  border-radius: 10px;
  ${(props) => props.marginleft && `margin-left: ${props.marginleft};`}
  top: -2px;
  position: relative;
`;
