import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  generic: {
    id: 'chat.messageHistory.errorMessageEntry.genericError',
    defaultMessage: 'There was an error sending the message; it may not be delivered.'
  },
  textError: {
    id: 'chat.messageHistory.errorMessageEntry.textError',
    defaultMessage: '{text}'
  }
});

interface Props {
  error: {
    code: string;
    text: {
      '#text'?: string;
    };
  };
}

const ErrorMessageHistoryEntry: React.FC<Props> = (props, _) => {
  const { formatMessage: f } = useIntl();
  const values = {
    code: props.error.code,
    text: props.error.text['#text']
  };

  const errorMsg = props.error.text ? messages.textError : messages.generic;

  return (
    <li className="message-entry msg-error">
      <span>{f(errorMsg, values)}</span>
    </li>
  );
};
// @ts-expect-error
export default React.forwardRef(ErrorMessageHistoryEntry);
