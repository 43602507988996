import { REHYDRATE } from 'redux-persist/constants';

export default {
  REHYDRATE,

  NETWORK_ERROR: 'NETWORK_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',
  CLEAR_NETWORK_ERROR: 'CLEAR_NETWORK_ERROR',
  CLEAR_CHAT_ERROR: 'CLEAR_CHAT_ERROR',
  CLEAR_MEETING_ERROR: 'CLEAR_MEETING_ERROR',
  CLEAR_SOCIAL_GRAPH_ERROR: 'CLEAR_SOCIAL_GRAPH_ERROR',
  PRESENCE_ERROR: 'PRESENCE_ERROR',
  GENERIC_ERROR: 'GENERIC_ERROR',
  XMPP_AUTH_ERROR: 'XMPP_AUTH_ERROR',
  EXTERNAL_CONTACT_ERROR: 'EXTERNAL_CONTACT_ERROR',
  API_ERROR: 'API_ERROR',

  SUGGESTIONS_UPDATED: 'SUGGESTIONS_UPDATED',

  CONTACTS_REQUEST: 'CONTACTS_REQUEST',
  CONTACTS_UPDATED: 'CONTACTS_UPDATED',
  CONTACTS_AVATAR_UPDATED: 'CONTACTS_AVATAR_UPDATED',

  CONTACT_INVITING: 'CONTACT_INVITING',
  CONTACT_INVITED: 'CONTACT_INVITED',
  CONTACT_ADDING: 'CONTACT_ADDING',
  CONTACT_ADDED: 'CONTACT_ADDED',
  CONTACT_REMOVED: 'CONTACT_REMOVED',
  CONTACT_SUBSCRIBED: 'CONTACT_SUBSCRIBED',
  CONTACT_SELECTED: 'CONTACT_SELECTED',
  INITIAL_CONTACT_SELECTED: 'INITIAL_CONTACT_SELECTED',
  CONTACT_STATUS_UPDATE: 'CONTACT_STATUS_UPDATE',
  CONTACT_UPDATED: 'CONTACT_UPDATED',
  CONTACT_CAPABILITIES_UPDATED: 'CONTACT_CAPABILITIES_UPDATED',
  SUBSCRIPTION_UPDATED: 'SUBSCRIPTION_UPDATED',

  PENDING_CONTACT_NAME_UPDATE: 'PENDING_CONTACT_NAME_UPDATE',

  CHAT_CONNECTED: 'CHAT_CONNECTED',
  CHAT_RECONNECT: 'CHAT_RECONNECT',
  CHAT_DISCONNECT: 'CHAT_DISCONNECT',
  CHAT_HISTORY_UPDATING: 'CHAT_HISTORY_UPDATING',
  CHAT_HISTORY_UPDATED: 'CHAT_HISTORY_UPDATED',
  CHAT_PARTIAL_HISTORY_UPDATING: 'CHAT_PARTIAL_HISTORY_UPDATING',
  CHAT_PARTIAL_HISTORY_UPDATED: 'CHAT_PARTIAL_HISTORY_UPDATED',

  CHAT_ROOM_MESSAGES_READ: 'CHAT_ROOM_MESSAGES_READ',
  MESSAGE_SEND: 'MESSAGE_SEND',
  MESSAGE_SEND_SUCCESS: 'MESSAGE_SEND_SUCCESS',
  MESSAGE_SEND_ERROR: 'MESSAGE_SEND_ERROR',
  MESSAGE_SEND_FEATURES: 'MESSAGE_SEND_FEATURES',
  MESSAGE_SEND_LENGTH: 'MESSAGE_SEND_LENGTH',
  MESSAGE_RECEIVED: 'MESSAGE_RECEIVED',
  CHAT_PERSIST_MESSAGE: 'CHAT_PERSIST_MESSAGE',

  MEETING_PROVISIONING: 'MEETING_PROVISIONING',
  MEETING_PROVISIONED: 'MEETING_PROVISIONED',
  MEETING_ERROR_CREATING: 'MEETING_ERROR_CREATING',
  SESSION_ERROR_DELETING: 'SESSION_ERROR_DELETING',
  MEETING_CANCELLING_MEET_NOW: 'MEETING_CANCELLING_MEET_NOW',
  MEETING_CONCURRENT_ERROR: 'MEETING_CONCURRENT_ERROR',
  MEETINGS_UPDATING: 'MEETINGS_UPDATING',
  MEETINGS_UPDATED: 'MEETINGS_UPDATED',
  MEETINGS_UPDATE_ERROR: 'MEETINGS_UPDATE_ERROR',
  MEETINGS_START_MEETING: 'MEETINGS_START_MEETING',
  MEETINGS_JOIN_MEETING: 'MEETINGS_JOIN_MEETING',
  MEETINGS_MEET_NOW: 'MEETINGS_MEET_NOW',
  MEETINGS_SHOWING_SHARE_MEETING: 'MEETINGS_SHOWING_SHARE_MEETING',
  MEETINGS_SHOW_SHARE_MEETING: 'MEETINGS_SHOW_SHARE_MEETING',
  MEETINGS_HIDE_SHARE_MEETING: 'MEETINGS_HIDE_SHARE_MEETING',
  MEETINGS_SHOW_SHARE_MEETING_ERROR: 'MEETINGS_SHOW_SHARE_MEETING_ERROR',
  MEETINGS_SHOW_SCHEDULE_MEETING: 'MEETINGS_SHOW_SCHEDULE_MEETING',
  MEETINGS_HIDE_SCHEDULE_MEETING: 'MEETINGS_HIDE_SCHEDULE_MEETING',
  MEETINGS_DELETED: 'MEETINGS_DELETED',
  MEETINGS_SHOW_DELETE_MEETING: 'MEETINGS_SHOW_DELETE_MEETING',
  MEETINGS_HIDE_DELETE_MEETING: 'MEETINGS_HIDE_DELETE_MEETING',
  MEETINGS_SHOW_MEETING_IN_SESSION_ERROR: 'MEETINGS_SHOW_MEETING_IN_SESSION_ERROR',
  MEETINGS_HIDE_MEETING_IN_SESSION_ERROR: 'MEETINGS_HIDE_MEETING_IN_SESSION_ERROR',
  MEETINGS_SESSION_DELETED: 'MEETINGS_SESSION_DELETED',

  RECORDING_SESSION_PLAYED: 'RECORDING_SESSION_PLAYED',
  RECORDING_SESSION_DELETED: 'RECORDING_SESSION_DELETED',
  RECORDING_SESSION_DOWNLOADED: 'RECORDING_SESSION_DOWNLOADED',
  STANDALONE_PLAYER_URL_COPIED: 'STANDALONE_PLAYER_URL_COPIED',

  MEETINGS_HISTORY_UPDATING: 'MEETINGS_HISTORY_UPDATING',
  MEETINGS_HISTORY_UPDATED: 'MEETINGS_HISTORY_UPDATED',
  MEETINGS_HISTORY_FETCH_ERROR: 'MEETINGS_HISTORY_FETCH_ERROR',

  JOIN_MEETING_REQUEST: 'JOIN_MEETING_REQUEST',
  JOIN_MEETING_REQUEST_ACCEPTED: 'JOIN_MEETING_REQUEST_ACCEPTED',
  JOIN_MEETING_REQUEST_DECLINED: 'JOIN_MEETING_REQUEST_DECLINED',
  MEETING_REQUEST_ACCEPTED: 'MEETING_REQUEST_ACCEPTED',
  MEETING_REQUEST_DECLINED: 'MEETING_REQUEST_DECLINED',
  MEETING_REQUEST_DISMISSED: 'MEETING_REQUEST_DISMISSED',
  ROOM_SUBSCRIBE_REQUEST: 'ROOM_SUBSCRIBE_REQUEST',
  MEETING_DECLINED_ACK: 'MEETING_DECLINED_ACK',

  PEOPLE_REQUEST: 'PEOPLE_REQUEST',
  PEOPLE_RECEIVE: 'PEOPLE_RECEIVE',

  GROUP_REQUEST: 'GROUP_REQUEST',
  GROUPS_UPDATED: 'GROUPS_UPDATED',
  GROUP_RECEIVE: 'GROUP_RECEIVE',
  GROUP_CLEAR: 'GROUP_CLEAR',
  GROUP_CACHE_CLEARED: 'GROUP_CACHE_CLEARED',
  GROUP_CREATED: 'GROUP_CREATED',
  GROUP_UPDATED: 'GROUP_UPDATED',
  GROUP_REMOVED: 'GROUP_REMOVED',
  GROUP_REVOKED: 'GROUP_REVOKED',
  GROUP_DELETED: 'GROUP_DELETED',
  GROUP_JOINED: 'GROUP_JOINED',
  GROUP_ENTERING: 'GROUP_ENTERING',
  GROUP_ENTERED: 'GROUP_ENTERED',
  GROUP_CANT_JOIN: 'GROUP_CANT_JOIN',
  GROUP_CANT_CREATE: 'GROUP_CANT_CREATE',
  GROUP_CANT_LEAVE: 'GROUP_CANT_LEAVE',
  GROUP_MEMBERS_INVITED: 'GROUP_MEMBERS_INVITED',
  GROUP_MEMBERS_UPDATED: 'GROUP_MEMBERS_UPDATED',
  GROUP_MEMBERS_REMOVED: 'GROUP_MEMBERS_REMOVED',
  GROUP_MEMBERS_CANT_REMOVE: 'GROUP_MEMBERS_CANT_REMOVE',
  GROUP_OCCUPANT_UPDATED: 'GROUP_OCCUPANT_UPDATED',
  GROUP_OCCUPANT_REMOVED: 'GROUP_OCCUPANT_REMOVED',
  GROUP_OLD_MEMBERS_UPDATED: 'GROUP_OLD_MEMBERS_UPDATED',
  GROUP_JOINING_NEW: 'GROUP_JOINING_NEW',
  GROUP_JOINING_EXISTING: 'GROUP_JOINING_EXISTING',

  NOTIFICATION_DELIVERED: 'NOTIFICATION_DELIVERED',

  CAPABILITIES_BROADCASTED: 'CAPABILITIES_BROADCASTED',
  CHAT_CONNECTED_CHANGE: 'CHAT_CONNECTED_CHANGE',
  CHAT_WILLRECONNECT: 'CHAT_WILLRECONNECT',
  CHAT_RECONNECTING: 'CHAT_RECONNECTING',
  CHAT_RECONNECTION_REQUEST: 'CHAT_RECONNECTION_REQUEST',

  POTENTIAL_CAPABILITIES_SET: 'POTENTIAL_CAPABILITIES_SET',
  POTENTIAL_CAPABILITIES_RESET: 'POTENTIAL_CAPABILITIES_RESET'
};
