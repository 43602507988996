import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Dispatch, State } from '../../../view-model/types';
import { getIsOnline, getPreferences } from '../../../view-model/selectors';
import {
  AsyncSettingGroupName,
  State as PreferencesState,
  SettingGroupName,
  SettingName,
  SettingValue,
  SyncSettingGroupName,
  getValidationErrors,
  hasPreferencesChanges,
  isAsyncGroup
} from '../../../view-model/modules/core/preferences/preferencesReducer';
import actions, {
  fetchSettingGroup,
  saveSettingGroup
} from '../../../view-model/modules/core/preferences/preferencesActions';
import { PreferencesPageLayout } from './PreferencesPageLayout';

interface OwnProps<G extends SettingGroupName> {
  children: (opts: {
    settings: PreferencesState[G]['update'];
    onChange: <S extends SettingName<G>>(name: S, value: SettingValue<G, S>) => void;
    onSave: () => void;
    isDisabled: boolean;
    validationErrors: string[];
  }) => React.ReactNode;
  group: G;
  hideButtons?: boolean;
}

interface Props<G extends SettingGroupName> extends OwnProps<G> {
  settings: PreferencesState[G];
  hasChanges: boolean;
  changeSetting: <S extends SettingName<G>>(name: S, value: SettingValue<G, S>) => void;
  saveChanges: () => void;
  rollbackChanges: () => void;
  isOnline?: boolean;
  fetchSettings?: () => void;
  validationErrors: string[];
}

class PreferencesPage<G extends SettingGroupName> extends Component<Props<G>> {
  componentDidMount() {
    if (this.props.fetchSettings) {
      this.props.fetchSettings();
    }
  }

  componentDidUpdate(prevProps: Props<G>) {
    if (this.props.fetchSettings && this.props.group !== prevProps.group) {
      this.props.fetchSettings();
    }
  }

  render() {
    const {
      settings,
      isOnline,
      validationErrors = [],
      hasChanges,
      hideButtons = false,
      changeSetting,
      rollbackChanges,
      saveChanges,
      children
    } = this.props;

    if (isAsyncGroup(settings)) {
      return (
        <PreferencesPageLayout
          onCancel={rollbackChanges}
          onSave={saveChanges}
          disableSaveButton={!isOnline || validationErrors.length > 0}
          isFetching={settings.isFetching}
          hasChanges={hasChanges}
          hideButtons={hideButtons}
          isUpdating={settings.isUpdating}
        >
          {children({
            settings: settings.update,
            onChange: changeSetting,
            onSave: saveChanges,
            isDisabled: !isOnline || settings.isFetching || settings.isUpdating,
            validationErrors
          })}
        </PreferencesPageLayout>
      );
    }

    return (
      <PreferencesPageLayout
        onCancel={rollbackChanges}
        onSave={saveChanges}
        disableSaveButton={validationErrors.length > 0}
        isFetching={false}
        hasChanges={hasChanges}
        hideButtons={hideButtons}
        isUpdating={false}
      >
        {children({
          settings: settings.update,
          onChange: changeSetting,
          onSave: saveChanges,
          isDisabled: false,
          validationErrors
        })}
      </PreferencesPageLayout>
    );
  }
}

export const AsyncPreferencesPage = (connect(
  <G extends AsyncSettingGroupName>(state: State, ownProps: OwnProps<G>) => ({
    isOnline: getIsOnline(state),
    settings: getPreferences(state)[ownProps.group],
    hasChanges: hasPreferencesChanges(state, ownProps.group),
    validationErrors: getValidationErrors(state, ownProps.group)
  }),
  <G extends AsyncSettingGroupName>(dispatch: Dispatch, ownProps: OwnProps<G>) => ({
    fetchSettings: () => {
      dispatch(fetchSettingGroup(ownProps.group, true));
    },
    changeSetting: (name: any, value: any) => {
      dispatch(actions.changeSetting(ownProps.group, name, value));
    },
    saveChanges: () => {
      dispatch(saveSettingGroup(ownProps.group));
    },
    rollbackChanges: () => {
      dispatch(actions.rollbackSettingGroup(ownProps.group));
    }
  })
)(PreferencesPage) as any) as <G extends AsyncSettingGroupName>(props: OwnProps<G>) => ReactElement<any> | null;

export const SyncPreferencesPage = (connect(
  <G extends SyncSettingGroupName>(state: State, ownProps: OwnProps<G>) => ({
    settings: getPreferences(state)[ownProps.group],
    hasChanges: hasPreferencesChanges(state, ownProps.group)
  }),
  <G extends SyncSettingGroupName>(dispatch: Dispatch, ownProps: OwnProps<G>) => ({
    changeSetting: (name: any, value: any) => {
      dispatch(actions.changeSetting(ownProps.group, name, value));
    },
    saveChanges: () => {
      dispatch(actions.saveSettingGroup(ownProps.group));
    },
    rollbackChanges: () => {
      dispatch(actions.rollbackSettingGroup(ownProps.group));
    }
  })
)(PreferencesPage) as any) as <G extends SyncSettingGroupName>(props: OwnProps<G>) => ReactElement<any> | null;
