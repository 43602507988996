import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import './SearchMessage.css';
import { useIntl } from 'react-intl';
import { GlobalSearchMessages as messages } from '../GlobalSearchMessages';
import { isMobile } from '../../../services/ClientService';

const hideHeadsUpMessageStorage = 'hideHeadsUpMessage';

const MessageContainer = styled.div`
  border-radius: 4px;
  border: solid 1px #006aff;
  background-color: rgba(64, 144, 255, 0.08);
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: normal;
  color: #25282d;
  height: auto;
  border-radius: 3px;
  display: flex;
  padding: 13px;
  padding-right: 4px;
  position: relative;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const CloseIcon = styled.i`
  color: #25282d;
  font-size: 25px;
  margin-right: 13px;
  cursor: pointer;
`;

const InfoIcon = styled.i`
  color: #006aff;
  font-size: 25px;
  margin-right: 13px;
`;

const QuestionIcon = styled.i`
  color: #9aa8bb;
  font-size: 25px;
  margin-right: 13px;
`;

const InfoContainer = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: flex-end;
  visibility: ${(props) => (props.active ? 'hidden' : 'visible')};
`;

const Container = styled.div`
  display: flex;
`;

export const SearchMessage: React.FC = () => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const [active, setActive] = useState(false);
  const toggleActiveCb = useCallback(() => {
    localStorage.setItem(hideHeadsUpMessageStorage, 'true');
    setActive(!active);
  }, [active]);

  useEffect(() => {
    const showHeadsUpMessage = localStorage.getItem(hideHeadsUpMessageStorage) !== 'true';
    setActive(showHeadsUpMessage);
  }, []);

  return (
    <div>
      {active && (
        <MessageContainer>
          <Container>
            <InfoIcon className="togo-icon togo-icon-info" />
            <span>{f(messages.searchCriteriaMessage)}</span>
          </Container>
          <CloseIcon className="togo-icon togo-icon-closes" onClick={toggleActiveCb} />
        </MessageContainer>
      )}
      <InfoContainer active={active}>
        <QuestionIcon
          className="togo-icon togo-icon-question-circle"
          data-tip={f(messages.searchCriteriaMessage)}
          data-class="icon-tooltip"
          data-place={isMobile ? 'bottom' : 'left'}
        />
      </InfoContainer>
    </div>
  );
};
