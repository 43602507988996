import * as React from 'react';
import { AppErrorPage } from './ErrorPages';

interface State {
  error: any;
  info: any;
  hasError: boolean;
}

export class AppErrorBoundary extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null
    };
  }

  componentDidCatch(error: any, info: any) {
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
  }

  render() {
    if (this.state.hasError) {
      return <AppErrorPage error={this.state.error} />;
    }
    return this.props.children;
  }
}
