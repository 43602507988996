import * as React from 'react';
import Modal from 'react-modal';

interface Props {
  onClose?: () => void;
  contentLabel?: string;
  shouldCloseOnOverlayClick?: boolean;
  className?: string;
}

export const ModalDialog: React.FC<Props> = (props) => {
  const modalStyles: Modal.Styles = {
    overlay: {
      zIndex: 10,
      background: 'rgba(72, 81, 100, .7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    content: {
      borderRadius: 4,
      padding: 0,
      boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.5)',
      position: 'static',
      border: 'none',
      overflow: 'visible'
    }
  };

  const onClose = props.onClose || (() => {});

  return (
    <Modal style={modalStyles} shouldCloseOnOverlayClick onRequestClose={onClose} isOpen {...props}>
      {props.children}
    </Modal>
  );
};
