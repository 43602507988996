import { Contact, Member } from '@getgo/caps-redux';
import React from 'react';
import { Flip } from '@getgo/featureflip-react';
import { WrappedComponentProps, defineMessages, injectIntl } from 'react-intl';
import {
  DraftJSPersistedMessageInput,
  PlainPersistedMessageInput
} from '../../../view-model/modules/messaging/messageInputs/messageInputsReducer';
import MessageInputRTE from '../MessageInputRTE/MessageInputRTE';
import { MessageInputPlain } from '../MessageInputPlain/MessageInputPlain';
import { isAndroid, isIOS } from '../../../services/ClientService';
import { IsTypingIndicator } from '../IsTypingIndicator';
import { State as RootState } from '../../../view-model/types';

const messages = defineMessages({
  placeholder: {
    id: 'chat.messageInput.placeholder',
    defaultMessage: 'Type your message here'
  }
});

const isAndroidOrIOS = isAndroid || isIOS;

export interface Props {
  addTypingIndicator: (roomId: string, isGroup: boolean) => void;
  app?: RootState['core']['app'];
  autoFocus?: boolean;
  changeMessageInputHeight: (roomId: string, height: number) => void;
  contact: Contact;
  groupMembers?: Member[];
  isOnline?: boolean;
  maxLength?: number;
  messageInputHeight?: number;
  onNewMessage: (text: string, option: any) => void;
  persistDraftJSMessageInput?: (roomId: string, persistedMessageInput: DraftJSPersistedMessageInput) => void;
  persistedMessageInput: DraftJSPersistedMessageInput | PlainPersistedMessageInput;
  persistPlainMessageInput: (roomId: string, persistPlainMessageInput: PlainPersistedMessageInput) => void;
  removeTypingIndicator: (roomId: string, isGroup: boolean) => void;
  roomId: string;
  shouldShowMentionList?: boolean;
  showEmojiButton?: boolean;
  spellCheck?: boolean;
  submitOnEnter?: boolean;
  uploadFiles: (files: File[] | FileList) => void;
}

interface State {
  isMobileView: boolean;
}

class MessageInput extends React.Component<Props & WrappedComponentProps, State> {
  private mql: MediaQueryList;

  constructor(props: Props & WrappedComponentProps) {
    super(props);

    this.onMediaQueryChanged = this.onMediaQueryChanged.bind(this);
    this.shouldSubmitOnEnter = this.shouldSubmitOnEnter.bind(this);

    this.mql = window.matchMedia('(min-width: 700px)');
    this.mql.addListener(this.onMediaQueryChanged);

    this.state = {
      isMobileView: !this.mql.matches
    };
  }

  componentWillUnmount() {
    this.mql.removeListener(this.onMediaQueryChanged);
  }

  onMediaQueryChanged() {
    this.setState({
      isMobileView: !this.mql.matches
    });
  }

  shouldSubmitOnEnter() {
    // ideally, we'd detect soft/touch keyboard which doesn't have an (easy) way to use shift-enter
    return !isAndroidOrIOS || !this.state.isMobileView;
  }

  render() {
    const extraProps = {
      placeholder: this.props.intl.formatMessage(messages.placeholder),
      submitOnEnter: this.shouldSubmitOnEnter(),
      showEmojiButton: !isAndroidOrIOS,
      spellCheck: true
    };

    const propsWithDefault = Object.assign({ maxLength: 10000 }, this.props);

    let isTyping;

    if (this.props.contact.isGroup) {
      isTyping = this.props.contact.typingList?.length > 0;
    } else {
      isTyping = this.props.contact.isTyping;
    }

    return (
      <div>
        <Flip name="richTextInput">
          <MessageInputRTE
            {...propsWithDefault}
            {...extraProps}
            persistedMessageInput={propsWithDefault.persistedMessageInput as DraftJSPersistedMessageInput}
          />
        </Flip>
        <Flip not name="richTextInput">
          <MessageInputPlain
            {...propsWithDefault}
            {...extraProps}
            persistedMessageInput={propsWithDefault.persistedMessageInput as PlainPersistedMessageInput}
          />
        </Flip>
        <div className="is-typing-indicator-input-wrapper">{isTyping && <IsTypingIndicator {...this.props} />}</div>
      </div>
    );
  }
}

export default injectIntl(MessageInput);
