export const defaultCategories = {
  people: {
    title: 'People',
    emoji: 'smile'
  },
  nature: {
    title: 'Nature',
    emoji: 'hamster'
  },
  food: {
    title: 'Food & Drink',
    emoji: 'pizza'
  },
  activity: {
    title: 'Activity',
    emoji: 'soccer'
  },
  travel: {
    title: 'Travel & Places',
    emoji: 'earth_americas'
  },
  objects: {
    title: 'Objects',
    emoji: 'bulb'
  },
  symbols: {
    title: 'Symbols',
    emoji: 'clock9'
  },
  flags: {
    title: 'Flags',
    emoji: 'flag_gb'
  }
};
