import React from 'react';
import { SelectedSearchResultView } from './SearchView/SelectedSearchResultView';
import GlobalSearchDetailsView from './GlobalSearchDetailsView';
import { SearchResultsNavigation } from './SearchView/SearchResultsNavigation';
import { useSelector } from 'react-redux';
import { State } from '../../view-model/types';
import styled from 'styled-components';
import { User } from '../../view-model/modules/core/user/userReducer';
import { SelectableItemType } from '../../view-model/modules/meetings/MeetingsTypes';
import { MeetingDetailsErrorBoundary } from '../Common/ErrorBoundary/MeetingDetailsErrorBoundary';
import { SessionUpdates } from '../../view-model/modules/meetings/meetingsReducer';

const Container = styled.div`
  position: relative;
`;

interface Props {
  showElasticSearchDateRangePicker: () => void;
  hideElasticSearchDateRangePicker: () => void;
  userSettings: User['settings'];
  handleOpenRecording: () => void;
  onDownloadRecording: () => void;
  showDeleteRecordingConfirmationModal: () => void;
  copyStandalonePlayerUrl: (meetingId: string, recordingId: string) => void;
  showRecordingAssetModal: () => void;
  editMeetingTitle: (sessionId: string, updatedSession: SessionUpdates) => void;
  meetingTitleEdited: (view: string) => void;
  showMeetingTitleEditModal: () => void;
  hideMeetingTitleEditModal: () => void;
  updateMeetingTitleEditError: (error: boolean) => void;
}

export const GlobalSearchContainer: React.FC<Props> = (props) => {
  const selectedItemType = useSelector((state: State) => state.meetings.selectedItemType);
  return (
    <Container data-testid="global-search-container">
      {selectedItemType === SelectableItemType.ELASTICSEARCHRESULTSELECTED && (
        <SearchResultsNavigation disableOnClickOutside={props.showElasticSearchDateRangePicker} />
      )}
      {selectedItemType === SelectableItemType.ELASTICSEARCH && (
        <MeetingDetailsErrorBoundary>
          <GlobalSearchDetailsView
            showElasticSearchDateRangePicker={props.showElasticSearchDateRangePicker}
            hideElasticSearchDateRangePicker={props.hideElasticSearchDateRangePicker}
          />
        </MeetingDetailsErrorBoundary>
      )}
      {selectedItemType === SelectableItemType.ELASTICSEARCHRESULTSELECTED && (
        <MeetingDetailsErrorBoundary>
          <SelectedSearchResultView
            showRecordingAssetModal={props.showRecordingAssetModal}
            userSettings={props.userSettings}
            handleOpenRecording={props.handleOpenRecording}
            onDownloadRecording={props.onDownloadRecording}
            showDeleteRecordingConfirmationModal={props.showDeleteRecordingConfirmationModal}
            enableOnClickOutside={props.hideElasticSearchDateRangePicker}
            copyStandalonePlayerUrl={props.copyStandalonePlayerUrl}
            editMeetingTitle={props.editMeetingTitle}
            meetingTitleEdited={props.meetingTitleEdited}
            showMeetingTitleEditModal={props.showMeetingTitleEditModal}
            hideMeetingTitleEditModal={props.hideMeetingTitleEditModal}
            updateMeetingTitleEditError={props.updateMeetingTitleEditError}
          />
        </MeetingDetailsErrorBoundary>
      )}
    </Container>
  );
};
