import { findWithRegex } from '../../utils';
import { ContentBlock } from 'draft-js';
import Emojione from 'emojione';

const UNICODE_REGEX_STRING = Emojione.escapeRegExp(Emojione.unicodeCharRegex());
const shortNameRegex = new RegExp(Emojione.shortnames, 'gi');
const unicodeRegex = new RegExp(UNICODE_REGEX_STRING, 'gi');
const asciiRegex = new RegExp(Emojione.asciiRegexp, 'gi');
const endsWithSpaceRegex = new RegExp('\\s$');
const endsWithUnicodeEmojiRegex = new RegExp(`(${UNICODE_REGEX_STRING})$`, 'i');

const checkEmojiPosition = (_: string, pre: string) => {
  // only convert emoji when either
  //  1) at the start of the text
  //  2) preceded by a space
  //  3) preceded by an emoji
  // this prevents conversion of ":/" inside "https://foo.com", for example

  if (pre.length < 1) {
    return true;
  }

  if (endsWithSpaceRegex.test(pre)) {
    return true;
  }

  return endsWithUnicodeEmojiRegex.test(pre);
};

const verifyShortname = (searchString: string, pre: string) => {
  if (!(searchString in Emojione.emojioneList)) {
    return false;
  }

  return checkEmojiPosition(searchString, pre);
};

const veryifyAscii = (searchString: string, pre: string) => {
  if (!(searchString in Emojione.asciiList)) {
    return false;
  }

  return checkEmojiPosition(searchString, pre);
};

export const shortNameStrategy = (contentBlock: ContentBlock, callback: (start: number, end: number) => void) => {
  findWithRegex(shortNameRegex, contentBlock, callback, verifyShortname);
};

export const asciiStrategy = (contentBlock: ContentBlock, callback: (start: number, end: number) => void) => {
  findWithRegex(asciiRegex, contentBlock, callback, veryifyAscii);
};

export const unicodeStrategy = (contentBlock: ContentBlock, callback: (start: number, end: number) => void) => {
  findWithRegex(unicodeRegex, contentBlock, callback);
};
