import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { User } from '../../../types/pulse-web';
import { OnlineStatus } from '../Common/OnlineStatus/OnlineStatus';
import { Popover } from '../Common/Popover/Popover';
import './GroupMemberList.css';
import { GroupContact, Member } from '@getgo/caps-redux';

const messages = defineMessages({
  groupMembers: {
    id: 'chat.groupMemberList.header',
    defaultMessage: 'Group members'
  },
  externalGroupMembers: {
    id: 'chat.groupMemberList.headerExternal',
    defaultMessage: 'External members'
  },
  addMember: {
    id: 'chat.groupMemberList.add',
    defaultMessage: 'Add Member'
  },
  selfLabel: {
    id: 'chat.groupMemberList.selfLabel',
    defaultMessage: '{fullName} (you)'
  }
});

interface Props {
  contact: GroupContact;
  isOnline?: boolean;
  members?: Member[];
  onAddMember?: (id: string) => void;
  onGroupMemberPopoverOpen?: () => void;
  onRemoveMember?: (groupjid: string, memberjid: string) => void;
  user?: User;
}

class GroupMemberList extends React.Component<Props & WrappedComponentProps> {
  onAddMember = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (this.props.onAddMember) {
      this.props.onAddMember(this.props.contact.jid);
    }
  };

  onRemoveMember = (member: Member) => {
    if (this.props.onRemoveMember) {
      this.props.onRemoveMember(this.props.contact.jid, member.jid);
    }
  };

  onPopoverOpen = () => {
    if (this.props.onGroupMemberPopoverOpen) {
      this.props.onGroupMemberPopoverOpen();
    }
  };

  render() {
    const {
      members = [],
      user = {
        firstName: '',
        lastName: '',
        jid: ''
      }
    } = this.props;

    const nameIsKnown = (member: Member) => member.jid !== member.name;
    const validMembers = members.filter(nameIsKnown);
    const groupSize = validMembers.length;
    const internalMembers = validMembers.filter((member) => !member.isExternal);
    const externalMembers = validMembers.filter((member) => !!member.isExternal);

    const isMe = (member: Member) => member.jid === user.jid;
    const isOwner = (member: Member) => member.affiliation === 'owner';

    const renderRemoveButton = (member: Member) => {
      const removeMember = this.onRemoveMember.bind(this, member);
      if (this.props.contact && this.props.contact.affiliation === 'owner') {
        return (
          <button
            className={classNames('btn-icon', 'remove-member-button', { allow: !isOwner(member) })}
            onClick={removeMember}
            data-testid="button-remove-member"
          >
            <i className="togo-icon togo-icon-closes" />
          </button>
        );
      }

      return null;
    };

    const renderMember = (member: Member) => {
      return (
        <li className={classNames('member', { me: isMe(member), owner: isOwner(member) })} key={member.jid}>
          <OnlineStatus
            isExternal={member.isExternal}
            userStatus={member.status === 'online' && member.availability ? member.availability : 'offline'}
          />
          <span className="name">
            {isMe(member) ? (
              <FormattedMessage {...messages.selfLabel} values={{ fullName: member.name }} />
            ) : (
              member.name
            )}
          </span>
          {renderRemoveButton(member)}
        </li>
      );
    };

    const memberListExternal = <ul className="member-list external">{externalMembers.map(renderMember)}</ul>;

    const memberListInternal = <ul className="member-list">{internalMembers.map(renderMember)}</ul>;

    const disclosure = (
      <button
        className="group-member-list btn btn-link"
        disabled={!this.props.isOnline}
        data-testid="button-group-member-list"
      >
        <i className="togo-icon togo-icon-person togo-icon-lg" />
        <span>{groupSize}</span>
      </button>
    );

    return (
      <Popover
        className="group-member-list-popover"
        disclosure={disclosure}
        ariaLabel={this.props.intl.formatMessage(messages.groupMembers)}
        id="group-member-list-popover"
      >
        <div className="member-content">
          {externalMembers.length > 0 && (
            <div>
              <div className="member-list-header">
                <FormattedMessage {...messages.externalGroupMembers} />
              </div>
              {memberListExternal}
            </div>
          )}
          {internalMembers.length > 0 && (
            <div>
              <div className="member-list-header">
                <FormattedMessage {...messages.groupMembers} />
              </div>
              {memberListInternal}
            </div>
          )}
          <button className="btn btn-link add-member" onClick={this.onAddMember}>
            <FormattedMessage {...messages.addMember} />
          </button>
        </div>
      </Popover>
    );
  }
}

export default injectIntl(GroupMemberList);
