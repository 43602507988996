import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { scrollIntoViewIfNeeded } from '../../../lib/scrolling';
import { Avatar } from './Avatar/Avatar';
import { isEmail } from '../../../lib/email';
import { Suggestion } from '@getgo/caps-redux';
import ReactDOM from 'react-dom';
import { pageSize } from '../../view-model/modules/messaging/people/peopleActions';

const messages = defineMessages({
  inviteExternal: {
    id: 'common.suggestionsList.inviteExternal',
    defaultMessage: 'Send an invite to {email}'
  },
  couldNotFindUser: {
    id: 'common.suggestionsList.couldNotFindUser',
    defaultMessage: "We couldn't find {email}"
  },
  emptySearch: {
    id: 'common.suggestionsList.emptySearch',
    defaultMessage: "Sorry, we couldn't find anyone with that name."
  },
  fullEmailSuggestion: {
    id: 'common.suggestionsList.fullEmailSuggestion',
    defaultMessage: 'To invite someone new to your conversation, type their full email address.'
  },
  inviteExternalDisabled: {
    id: 'common.suggestionsList.inviteExternalDisabled',
    defaultMessage: 'Inviting of external users is disabled by the administrator.'
  },
  moreSuggestionsWarning: {
    id: 'common.suggestionsList.moreSuggestionsWarning',
    defaultMessage: 'Only the first 50 results are listed. To see more, please refine your search.'
  }
});

const defaultQueryText = 'No suggestions.';

interface Props {
  className?: string;
  onSelect?: (suggestion: Suggestion) => void;
  onScroll?: () => void;
  suggestions: Suggestion[];
  queryText?: string;
  highlightIndex?: number;
  avatarName?: (suggestion: Suggestion) => string;
  suggestionTitle?: (suggestion: Suggestion) => JSX.Element | undefined;
  suggestionAvatar?: (suggestion: Suggestion, i: number) => JSX.Element;
  onInviteExternal?: (queryText: string) => void;
  allowInviteExternal?: boolean;
  noEmptyMessage?: boolean;
  apiError?: boolean;
  onMouseOverSuggestion?: (arrayIndex: number) => void;
  allowScrollIntoView?: boolean;
  showInviteWithEmail?: boolean;
  emptyMessage?: string | object;
}

export class SuggestionsList extends React.PureComponent<Props, {}> {
  lastMouseMoveIndex: number | null = null;
  hl: JSX.Element | null = null;
  listItems: Array<HTMLLIElement | null> = [];

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  handleScroll = () => {
    const { onScroll = () => {} } = this.props;
    onScroll();
  };

  componentDidUpdate(prevProps: Props) {
    const { highlightIndex = -1, allowScrollIntoView = true } = this.props;
    if (allowScrollIntoView && highlightIndex >= 0 && this.listItems[highlightIndex]) {
      const node = this.listItems[highlightIndex];
      if (node) {
        scrollIntoViewIfNeeded(node, { block: 'nearest' });
      }
    }

    if (prevProps.suggestions !== this.props.suggestions) {
      this.lastMouseMoveIndex = null;
    }

    if (prevProps.queryText != this.props.queryText) {
      this.resetScrollToTop();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

  resetScrollToTop() {
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this);
    if (node?.parentElement?.scrollTop) node.parentElement.scrollTop = 0;
  }

  handleClick(suggestedPerson: Suggestion) {
    if (typeof this.props.onSelect === 'function') {
      this.props.onSelect(suggestedPerson);
    }
  }

  handleMouseMove(arrayIndex: number) {
    if (typeof this.props.onMouseOverSuggestion === 'function') {
      if (this.lastMouseMoveIndex !== arrayIndex) {
        this.props.onMouseOverSuggestion(arrayIndex);
        this.lastMouseMoveIndex = arrayIndex;
      }
    }
  }

  handleInviteExternal = () => {
    if (typeof this.props.onInviteExternal === 'function') {
      const { queryText = defaultQueryText } = this.props;
      this.props.onInviteExternal(queryText);
    }
  };

  render() {
    const { queryText = defaultQueryText, showInviteWithEmail = true, suggestionTitle, suggestionAvatar } = this.props;

    let suggestionsEmptyText;
    if (queryText.length) {
      suggestionsEmptyText = (
        <div>
          <FormattedMessage
            tagName="div"
            {...messages.inviteExternal}
            values={{ email: <strong>{queryText}</strong> }}
          />
          <span className="hint-text">
            <FormattedMessage {...messages.couldNotFindUser} values={{ email: queryText }} />
          </span>
        </div>
      );
    }

    const suggestions = this.props.suggestions.map((suggestedContact: Suggestion, i: number) => {
      const avatarName = this.props.avatarName ? this.props.avatarName(suggestedContact) : '';
      const isHighlighted = i === this.props.highlightIndex;
      const rootClass = classNames({ highlight: isHighlighted });

      const el = suggestionTitle ? (
        <li
          ref={(ref) => {
            this.listItems[i] = ref;
          }}
          key={suggestedContact.jid}
          className={rootClass}
          onClick={this.handleClick.bind(this, suggestedContact)}
          onMouseMove={this.handleMouseMove.bind(this, i)}
        >
          {suggestionAvatar ? suggestionAvatar(suggestedContact, i) : <Avatar name={avatarName} />}
          {suggestionTitle(suggestedContact)}
        </li>
      ) : null;

      this.hl = isHighlighted ? el : null;

      return el;
    });

    const moreSuggestionsWarning =
      suggestions.length >= pageSize ? (
        <div className="more-suggestions-warning">
          <FormattedMessage {...messages.moreSuggestionsWarning} />
        </div>
      ) : null;

    let content;
    if (suggestions.length) {
      content = (
        <>
          <ul>{suggestions}</ul>
          {moreSuggestionsWarning}
        </>
      );
    } else if (this.props.allowInviteExternal && isEmail(queryText)) {
      content = (
        <button className="suggestions-invite-external" onClick={this.handleInviteExternal}>
          <Avatar name="Invite" />
          {suggestionsEmptyText}
        </button>
      );
    } else if (!this.props.allowInviteExternal && isEmail(queryText)) {
      content = (
        <div className="suggestions-external-disabled">
          <div>
            <FormattedMessage {...messages.inviteExternalDisabled} />
            <div className="suggestions-external-disabled-icon-container">
              <i className="togo-icon togo-icon-info" />
            </div>
          </div>
        </div>
      );
    } else {
      content = !this.props.noEmptyMessage && (
        <div className="suggestions-empty-text">
          {!this.props.apiError && (
            <div>{this.props.emptyMessage || <FormattedMessage {...messages.emptySearch} />}</div>
          )}
          {showInviteWithEmail && (
            <div className="suggestions-invite-with-email">
              <FormattedMessage {...messages.fullEmailSuggestion} />
              <div className="suggestions-invite-with-email-icon-container">
                <i className="togo-icon togo-icon-feedback" />
              </div>
            </div>
          )}
        </div>
      );
    }

    return <div className={this.props.className || 'suggestions'}>{content}</div>;
  }
}
