import * as React from 'react';
import styled from '../../../../styled-components';
import { PrimaryButton } from './PrimaryButton';
import { GhostButton } from './GhostButton';
import device from '../devices';

interface ButtonBarContainerProps {
  showConfirm: boolean;
}

const ButtonBarContainer = styled.div<ButtonBarContainerProps>`
  ${(props) =>
    props.showConfirm
      ? `
    margin: 20px 2px 0 2px;
    padding: 20px;
    border-radius: 2px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.8);
  `
      : `
    margin-top: 20px;
    align-self: flex-end;
    padding: 4px;
    @media ${device.mobile} {
      align-self: center;
    }
  `}
`;

const ButtonBarConfirmMessage = styled.div`
  margin-bottom: 20px;
`;

const ButtonBarButtons = styled.div`
  text-align: right;
  @media ${device.mobile} {
    text-align: center;
  }
`;

const OkPrimaryButton = styled(PrimaryButton)`
  margin-left: 12px;
`;

export interface ButtonBarProps {
  shouldConfirm?: boolean;
  confirmMessage?: null | undefined | React.ReactChild;

  buttonOk: React.ReactChild;
  buttonCancel: React.ReactChild;

  buttonOkConfirm?: React.ReactChild;
  buttonCancelConfirm?: React.ReactChild;

  okDisabled?: boolean;

  onOk: () => void;
  onCancel: () => void;
  onShowConfirm?: () => void;
}

interface State {
  showConfirm: boolean;
}

export class ButtonBar extends React.Component<ButtonBarProps, State> {
  state: State = {
    showConfirm: false
  };

  private onOk = () => {
    if (!this.props.shouldConfirm || this.state.showConfirm) {
      this.props.onOk();
      return;
    }

    if (this.props.onShowConfirm) {
      this.props.onShowConfirm();
    }

    this.setState({
      showConfirm: true
    });
  };

  render() {
    return (
      <ButtonBarContainer showConfirm={this.state.showConfirm}>
        {this.state.showConfirm && this.props.confirmMessage && (
          <ButtonBarConfirmMessage>{this.props.confirmMessage}</ButtonBarConfirmMessage>
        )}
        <ButtonBarButtons>
          <GhostButton data-testid="button-cancel" onClick={this.props.onCancel}>
            {this.state.showConfirm && this.props.buttonCancelConfirm
              ? this.props.buttonCancelConfirm
              : this.props.buttonCancel}
          </GhostButton>
          <OkPrimaryButton data-testid="button-ok" onClick={this.onOk} disabled={this.props.okDisabled}>
            {this.state.showConfirm && this.props.buttonOkConfirm ? this.props.buttonOkConfirm : this.props.buttonOk}
          </OkPrimaryButton>
        </ButtonBarButtons>
      </ButtonBarContainer>
    );
  }
}
