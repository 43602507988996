import styled from '../../../../styled-components';
import { themeGet } from '@styled-system/theme-get';

export const LinkButton = styled.button`
  color: ${themeGet('link.colors.primary')};
  display: inline-flex;
  text-decoration: none;
  padding: 0;
  margin: 0;
  background: 0;
  border: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
