import React, { Component } from 'react';
import map from 'lodash/map';
import { Modifier } from './Modifier';

interface Props {
  onChange: (type: string) => void;
  modifiers?: { [key: number]: string };
  active?: string;
}

export class Modifiers extends Component<Props> {
  static defaultProps = {
    active: 0,
    modifiers: {
      0: '#FFDE5C',
      1: '#FFE1BB',
      2: '#FFD0A9',
      3: '#D7A579',
      4: '#B57D52',
      5: '#8B6858'
    }
  };

  private handleModifierClick = (_: any, type: string) => {
    this.props.onChange(type);
  };

  render() {
    return (
      <ol className="modifiers">
        {map(this.props.modifiers, (hex, type) => (
          <li data-testid="modifier-item" key={type}>
            <Modifier hex={hex} type={type} active={this.props.active === type} onClick={this.handleModifierClick} />
          </li>
        ))}
      </ol>
    );
  }
}
