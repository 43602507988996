import config from 'config';

const getOutputFormat = (url: string) => (/\.gif$/.test(url) ? '' : 'output=format:jpg/');

export const getPreviewUrl = (url: string, height: string | number) => {
  const output = getOutputFormat(url);
  return `${config.preview.url}${output}/rotate=deg:exif/resize=height:${height},fit:max/quality=value:70/compress/${url}`;
};

export const getThumbnailUrl = (url: string, height: string | number) => {
  const output = getOutputFormat(url);
  return `${config.preview.url}${output}/rotate=deg:exif/resize=height:${height}/quality=value:70/compress/${url}`;
};
