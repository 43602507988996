import styled from '../../../styled-components';
import { Box } from '../Toolkit/Box/Box';
import device from '../Toolkit/devices';

export const DashboardHeader = styled(Box)`
  flex-shrink: 0;
  flex-direction: row;
  border-bottom: 1px solid #e0e4ea;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;
