import * as React from 'react';
import { Box } from '../../Common/Toolkit/Box/Box';
import {
  AnytimeIcon,
  AnytimeTabIconContainer,
  MeetingsTabContainer,
  MeetingsId,
  MeetingsTabSubject,
  MeetingsTabTitle,
  MeetingRunningBadge
} from '../MeetingComponents';
import { Meeting } from '../../../view-model/modules/meetings/MeetingsTypes';

export interface Props {
  className?: string;
  meeting: Meeting;
}

export const AnytimeMeetingItem: React.FC<Props> = (props) => {
  return (
    <MeetingsTabContainer className={props.className}>
      <Box flexDirection="row">
        <AnytimeTabIconContainer>
          <AnytimeIcon />
        </AnytimeTabIconContainer>
        <MeetingsTabSubject>
          <MeetingsTabTitle data-testid="meeting-subject">{props.meeting.subject}</MeetingsTabTitle>
          <MeetingsId meeting={props.meeting} />
        </MeetingsTabSubject>
      </Box>
      {props.meeting.inSession && <MeetingRunningBadge />}
    </MeetingsTabContainer>
  );
};
