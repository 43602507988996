import meetingActions, { fetchMeetingInvitation, selectMeeting } from '../meetings/meetingsActions';
import { ActionsUnion, Thunk, UnwrapPromise, createAction } from '../../type-helpers';
import {
  MeetingSettings,
  createMeeting as createMeetingApi,
  deleteCoorganizers as deleteCoorganizersApi,
  fetchMeetingSettings as fetchMeetingSettingsApi,
  getMeeting as getMeetingApi,
  updateMeeting as updateMeetingApi
} from '../../../../models/Meeting';
import moment from 'moment-timezone';
import { User } from '../core/user/userReducer';
import { showToastNotification } from '../core/notifications/notificationsActions';
import { MeetingRequest, MeetingType, SchedulerFullMeeting, SchedulerMeeting } from './schedulerReducer';
import { SchedulerTabs } from '../../../components/Meetings/MeetingScheduler/MeetingScheduler';
import { getNextStartMeetingTime } from '../../../../lib/date';
import { CopyInvitation, MeetingCreated } from '../../../components/Notifications/Notifications';
import logger from '../../../services/LoggerService';
import * as clipboard from 'clipboard-polyfill';
import { Meeting, Profile, SelectableItemType } from '../meetings/MeetingsTypes';
import { createSchedulerMeetingFromMeetingResponseAndSettings } from '../../../../models/UpdateMeeting';

export enum ActionTypes {
  SCHEDULER_HIDE_INTERSTITIAL = 'SCHEDULER_HIDE_INTERSTITIAL',
  SCHEDULER_FETCH_INVITATION = 'SCHEDULER_FETCH_INVITATION',

  SCHEDULER_MEETING_CREATE_SHOW = 'SCHEDULER_MEETING_CREATE_SHOW',
  SCHEDULER_MEETING_EDIT_SHOW = 'SCHEDULER_MEETING_EDIT_SHOW',
  MEETING_SCHEDULE_HIDE = 'MEETING_SCHEDULE_HIDE',

  SCHEDULER_CHANGE_PROPERTY = 'SCHEDULER_CHANGE_PROPERTY',
  SCHEDULER_VALIDATION_ERROR = 'SCHEDULER_VALIDATION_ERROR',
  SCHEDULER_VALIDATION_SUCCESS = 'SCHEDULER_VALIDATION_SUCCESS',

  FETCH_MEETING = 'FETCH_MEETING',
  INITIALIZE_MEETING = 'INITIALIZE_MEETING',
  INITIALIZE_MEETING_ERROR = 'INITIALIZE_MEETING_ERROR',

  FETCH_MEETING_SETTINGS = 'FETCH_MEETING_SETTINGS',
  INITIALIZE_MEETING_SETTINGS = 'INITIALIZE_MEETING_SETTINGS',
  INITIALIZE_MEETING_SETTINGS_ERROR = 'INITIALIZE_MEETING_SETTINGS_ERROR',

  SCHEDULER_MEETING_CREATING = 'SCHEDULER_MEETING_CREATING',
  SCHEDULER_MEETING_CREATED = 'SCHEDULER_MEETING_CREATED',
  SCHEDULER_MEETING_CREATE_ERROR = 'SCHEDULER_MEETING_CREATE_ERROR',

  SCHEDULER_MEETING_UPDATING = 'SCHEDULER_MEETING_UPDATING',
  SCHEDULER_MEETING_UPDATED = 'SCHEDULER_MEETING_UPDATED',
  SCHEDULER_MEETING_UPDATE_ERROR = 'SCHEDULER_MEETING_UPDATE_ERROR',

  SCHEDULER_ENDPOINT_ACCESS = 'SCHEDULER_ENDPOINT_ACCESS'
}

const actions = {
  schedulerHideInterstitial: (showScheduler: boolean, hideAlways = false) =>
    createAction(ActionTypes.SCHEDULER_HIDE_INTERSTITIAL, { showScheduler, hideAlways }),
  schedulerFetchInvitation: () => createAction(ActionTypes.SCHEDULER_FETCH_INVITATION),

  schedulerMeetingCreateShow: (defaultMeeting: SchedulerMeeting) =>
    createAction(ActionTypes.SCHEDULER_MEETING_CREATE_SHOW, { meeting: defaultMeeting }),
  schedulerMeetingEditShow: (
    meetingId: string,
    meeting: SchedulerMeeting,
    tab: SchedulerTabs = SchedulerTabs.meeting
  ) => createAction(ActionTypes.SCHEDULER_MEETING_EDIT_SHOW, { meetingId, meeting, tab }),

  meetingScheduleHide: () => createAction(ActionTypes.MEETING_SCHEDULE_HIDE),

  schedulerChangeProperty: (property: keyof SchedulerFullMeeting, value: any) =>
    createAction(ActionTypes.SCHEDULER_CHANGE_PROPERTY, { property, value }),
  schedulerValidationError: (property: keyof SchedulerFullMeeting, tab: SchedulerTabs) =>
    createAction(ActionTypes.SCHEDULER_VALIDATION_ERROR, { property, tab }),
  schedulerValidationSuccess: (property: keyof SchedulerFullMeeting, tab: SchedulerTabs) =>
    createAction(ActionTypes.SCHEDULER_VALIDATION_SUCCESS, { property, tab }),

  fetchMeeting: () => createAction(ActionTypes.FETCH_MEETING),
  initializeMeeting: (meeting: SchedulerMeeting, settings: MeetingSettings) =>
    createAction(ActionTypes.INITIALIZE_MEETING, { meeting, settings }),
  initializeMeetingError: () => createAction(ActionTypes.INITIALIZE_MEETING_ERROR),

  fetchMeetingSettings: () => createAction(ActionTypes.FETCH_MEETING_SETTINGS),
  initializeMeetingSettings: (settings: MeetingSettings) =>
    createAction(ActionTypes.INITIALIZE_MEETING_SETTINGS, { settings }),
  initializeMeetingSettingsError: () => createAction(ActionTypes.INITIALIZE_MEETING_SETTINGS_ERROR),

  schedulerMeetingCreating: (meeting: MeetingRequest) =>
    createAction(ActionTypes.SCHEDULER_MEETING_CREATING, { meeting }),
  schedulerMeetingCreated: (meetingId: string) => createAction(ActionTypes.SCHEDULER_MEETING_CREATED, { meetingId }),
  schedulerMeetingCreateError: () => createAction(ActionTypes.SCHEDULER_MEETING_CREATE_ERROR),

  schedulerMeetingUpdating: (meetingId: string, meeting: MeetingRequest) =>
    createAction(ActionTypes.SCHEDULER_MEETING_UPDATING, { meetingId, meeting }),
  schedulerMeetingUpdated: (meeting: Meeting) => createAction(ActionTypes.SCHEDULER_MEETING_UPDATED, { meeting }),
  schedulerMeetingUpdateError: (meetingId: string) =>
    createAction(ActionTypes.SCHEDULER_MEETING_UPDATE_ERROR, { meetingId }),

  schedulerEndpointAccess: () => createAction(ActionTypes.SCHEDULER_ENDPOINT_ACCESS)
};

export type Actions = ActionsUnion<typeof actions>;
export default actions;

const DEFAULT_DURATION = 30;
const MIN_INTERVAL_MS = 30 * 60 * 1000;
const START_TIME_ROUNDING_MS = 60 * 60 * 1000;

/* Used to handle account with invalid timezone settings (like 'EST')
As per database query - this is the most used value */
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const interstitialCopyInvitation = (
  meeting: Meeting,
  profile?: Profile,
  dontShowAgain?: boolean
): Thunk => async (dispatch, getState) => {
  let type: SelectableItemType =
    meeting.meetingType === MeetingType.recurring ? SelectableItemType.RECURRING : SelectableItemType.SCHEDULED;
  if (profile && meeting.meetingId === profile.meetingId) {
    type = SelectableItemType.PERSONAL;
  }

  let invitation = getState().meetings.invitationsById[meeting.meetingId] as any;
  if (!invitation || !invitation.invitationHtml) {
    await dispatch(actions.schedulerFetchInvitation());
    await dispatch(fetchMeetingInvitation(meeting.meetingId));
    invitation = getState().meetings.invitationsById[meeting.meetingId] as any;
  }
  dispatch(selectMeeting(type, meeting.meetingId));

  const dt = new clipboard.DT();
  dt.setData('text/plain', invitation.invitationText);
  dt.setData('text/html', invitation.invitationHtml);
  await clipboard.write(dt);
  dispatch(showToastNotification(CopyInvitation()));
  await dispatch(actions.schedulerHideInterstitial(false, dontShowAgain));
};

export const showEditMeeting = (meetingId: string, tab: SchedulerTabs = SchedulerTabs.meeting): Thunk => async (
  dispatch
) => {
  dispatch(
    actions.schedulerMeetingEditShow(
      meetingId,
      {
        subject: '',
        meetingType: MeetingType.scheduled,
        when: new Date(),
        starts: new Date(),
        displayTimeZone: DEFAULT_TIMEZONE,
        duration: 30,
        personalizeMeeting: false,
        theme: 'default'
      },
      tab
    )
  );

  dispatch(actions.fetchMeetingSettings());
  dispatch(actions.fetchMeeting());
  try {
    const settingsPromise = fetchMeetingSettingsApi();
    const schedulerMeetingPromise = getMeetingApi(meetingId, true);
    const [settings, schedulerMeeting] = await Promise.all<UnwrapPromise<typeof settingsPromise>, any>([
      settingsPromise,
      schedulerMeetingPromise
    ]);
    let meeting = createSchedulerMeetingFromMeetingResponseAndSettings(schedulerMeeting, settings) as any;

    // in case, someone wants to change from recurring, we want to set valid default values
    if (meeting.meetingType === 'recurring') {
      const time = getNextStartMeetingTime(
        moment
          .tz()
          .zone(meeting.displayTimeZone)
          .toDate(),
        MIN_INTERVAL_MS,
        START_TIME_ROUNDING_MS
      );
      meeting = {
        ...meeting,
        starts: time,
        when: time,
        duration: DEFAULT_DURATION,
        displayTimeZone: meeting.displayTimeZone
      };
    }
    return dispatch(actions.initializeMeeting(meeting, settings));
  } catch (e) {
    logger.error('showEditMeeting ', String(e));
    dispatch(meetingActions.showErrorWindow(500, e.message || 'unknown'));
    return dispatch(actions.initializeMeetingError());
  }
};

export const showCreateMeeting = (user: User): Thunk => async (dispatch) => {
  // always round to next full hour, having at least 30min to schedule the meeting
  let dateWithValidTimezone;
  if (!user.timeZone) {
    logger.error('Null timezone');
    user.timeZone = DEFAULT_TIMEZONE;
  }

  if (moment.tz.zone(user.timeZone) !== null) {
    dateWithValidTimezone = moment()
      .tz(user.timeZone)
      .toDate();
  } else {
    // fallback for non IANA compliant timezone invalid provisioned accounts (for example 'EST')
    dateWithValidTimezone = moment()
      .tz(DEFAULT_TIMEZONE)
      .toDate();
    logger.error('Invalid timezone ', user.timeZone);
    user.timeZone = DEFAULT_TIMEZONE;
  }

  const time = getNextStartMeetingTime(dateWithValidTimezone, MIN_INTERVAL_MS, START_TIME_ROUNDING_MS);

  dispatch(
    actions.schedulerMeetingCreateShow({
      subject: '',
      meetingType: MeetingType.scheduled,
      when: time,
      starts: time,
      displayTimeZone: user.timeZone,
      duration: DEFAULT_DURATION,
      roomName: '',
      personalizeMeeting: false,
      theme: 'default'
    })
  );

  dispatch(actions.fetchMeetingSettings());
  try {
    const meetingSettings = await fetchMeetingSettingsApi();
    return dispatch(actions.initializeMeetingSettings(meetingSettings));
  } catch (e) {
    return dispatch(actions.initializeMeetingSettingsError());
  }
};

export const createMeeting = (meeting: MeetingRequest): Thunk => async (dispatch) => {
  dispatch(actions.schedulerMeetingCreating(meeting));

  let code = 500;
  let error = 'unknown';
  try {
    const response: Meeting = (await createMeetingApi(meeting)) as any;
    dispatch(meetingActions.meetingCreated(response));
    dispatch(actions.schedulerMeetingCreated(String(response.meetingId)));
    dispatch(showToastNotification(MeetingCreated()));
    return dispatch(
      selectMeeting(
        meeting.meetingType === 'scheduled' ? SelectableItemType.SCHEDULED : SelectableItemType.RECURRING,
        response.meetingId
      )
    );
  } catch (e) {
    code = e.status;
    if (e.response.body.incidentId) {
      code = e.response.body.incidentId;
      if (e.response.body.errors?.length) {
        error = e.response.body.errors[0];
      }
    }
  }
  dispatch(meetingActions.showErrorWindow(code, error));
  return dispatch(actions.schedulerMeetingCreateError());
};

export const updateMeeting = (
  meetingId: string,
  meeting: MeetingRequest,
  updatedMeeting: MeetingRequest,
  isPersonalMeeting: boolean
): Thunk => async (dispatch) => {
  dispatch(actions.schedulerMeetingUpdating(meetingId, meeting));
  let code = 500;
  let error = 'unknown';

  try {
    const response = await updateMeetingApi(meetingId, meeting);
    if (response.status === 204) {
      if (meeting.coorganizerKeys?.length === 0) {
        await deleteCoorganizersApi(meetingId);
      }

      dispatch(
        meetingActions.meetingUpdated({
          meetingId,
          ...meeting,
          coorganizers: updatedMeeting?.coorganizerKeys || []
        } as any)
      );
      dispatch(fetchMeetingInvitation(meetingId));
      let itemType;
      if (isPersonalMeeting) {
        itemType = SelectableItemType.PERSONAL;
      } else if (meeting.meetingType === 'scheduled') {
        itemType = SelectableItemType.SCHEDULED;
      } else {
        itemType = SelectableItemType.RECURRING;
      }
      dispatch(selectMeeting(itemType, meetingId));
      return dispatch(actions.schedulerMeetingUpdated({ meetingId, ...meeting } as any));
    }
  } catch (e) {
    code = e.status;
    if (e.response.body.incidentId) {
      code = e.response.body.incidentId;
      if (e.response.body.errors?.length) {
        error = e.response.body.errors[0];
      }
    }
  }
  dispatch(meetingActions.showErrorWindow(code, error));
  return dispatch(actions.schedulerMeetingUpdateError(meetingId));
};
