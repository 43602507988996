import * as React from 'react';
import Dropzone, { DropzoneRootProps } from 'react-dropzone';
import { Overlay } from './Overlay';
import './FileDropZone.css';

const dragEventContainsFiles = (event: DragEvent) => {
  if (!event.dataTransfer) {
    return false;
  }

  // firefox, chrome and normal browsers
  if (event.dataTransfer.types?.includes && event.dataTransfer.types.includes('Files')) {
    return true;
  }
  // IE has an object instead
  if (event.dataTransfer.types[0] === 'Files') {
    return true;
  }
  return false;
};

export interface Props {
  className: string;
  contactName?: string;
  children?: React.ReactElement<any>;
  uploadFiles: (acceptedFiles: File[]) => void;
  isUploadEnabled: boolean;
}

interface State {
  isDragActive: boolean;
  isDropActive: boolean;
}

export class FileDropZone extends React.Component<Props, State> {
  dropTargets: any[] = [];
  timer: number | null = null;
  timeout = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      isDragActive: false,
      isDropActive: false
    };
    this.dropTargets = [];
    this.timer = null;
  }

  componentDidMount() {
    // Firefox on Windows exhibits a defect (probably regression)
    // where the 'dragleave' event is often not emitted if the mouse exits the browser window
    // straight from the drop zone. Try it on https://jsfiddle.net/qas495cz/
    // This causes the dropzone to appear stuck since the callback is never called with isDragActive set to false.
    // An issue had already been filed: https://github.com/okonet/react-dropzone/issues/265
    // This workaround adds an additional state to bail out from the stuck drop zone when the user releases the mouse.

    // drag source events...
    document.addEventListener('dragend', this.handleDragEnd, false);

    // drop target events...
    document.addEventListener('dragenter', this.handleDragEnter, false);
    document.addEventListener('dragover', this.handleDragOver, false);
    document.addEventListener('dragleave', this.handleDragLeave, false); // this is the flaky event!
    document.addEventListener('drop', this.handleDrop, false);
  }

  componentWillUnmount() {
    document.removeEventListener('dragend', this.handleDragEnd);
    document.removeEventListener('dragenter', this.handleDragEnter);
    document.removeEventListener('dragover', this.handleDragOver);
    document.removeEventListener('dragleave', this.handleDragLeave);
    document.removeEventListener('drop', this.handleDrop);

    // release any references/resources held (unlikely)
    if (this.timer !== null) {
      clearInterval(this.timer);
    }
    this.dropTargets.length = 0;
  }

  handleDragEnd = () => {
    this.setState({ isDragActive: false });
  };

  handleDragEnter = (event: DragEvent) => {
    if (!dragEventContainsFiles(event)) {
      return;
    }

    // occasionally as the mouse crosses elements on the page
    // the new target drag enter comes before the old target drag leave
    // which can be wrongly interpreted as no drop target
    // unless drop targets are maintained as a set
    this.dropTargets = this.dropTargets.filter((target) => target !== event.target).concat([event.target]);

    if (!this.state.isDropActive) {
      this.setState({ isDropActive: true });
      this.timer = window.setInterval(this.handleTimerTick, 1000);
    }
  };

  handleTimerTick = () => {
    if (this.timeout <= Date.now()) {
      this.resetDropActive();
    }
  };

  resetDropActive = () => {
    if (this.timer !== null) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.dropTargets = [];
    this.setState({ isDropActive: false });
  };

  handleDragOver = () => {
    // dragover is fired every few hundred milliseconds while a drop is active
    // if dragover is NOT received for about a second, we may consider dragleave was lost
    this.timeout = Date.now() + 1000;
  };

  handleDragLeave = (event: DragEvent) => {
    this.dropTargets = this.dropTargets.filter((target) => target !== event.target);
    const isDropActive = this.dropTargets.length > 0;
    if (this.state.isDropActive && !isDropActive) {
      this.resetDropActive();
    }
  };

  handleDrop = () => {
    this.resetDropActive();
  };

  uploadFiles = (acceptedFiles: File[]) => {
    if (this.props.isUploadEnabled && acceptedFiles.length > 0) {
      this.props.uploadFiles(acceptedFiles);
    }
  };

  // workaround: https://github.com/okonet/react-dropzone/pull/364
  // just remove the className line once next version of react-dropzone is available
  // currently there are bunch of warnings shown in console
  render() {
    return (
      <Dropzone onDrop={this.uploadFiles} noClick noKeyboard noDragEventsBubbling>
        {({ isDragActive, getRootProps }: { isDragActive: boolean; getRootProps: () => DropzoneRootProps }) => {
          return (
            <div className="drop-zone" {...getRootProps()}>
              <Overlay
                className={this.props.className}
                contactName={this.props.contactName}
                isDragActive={isDragActive && (this.state.isDragActive || this.state.isDropActive)}
                isUploadEnabled={this.props.isUploadEnabled}
              >
                {this.props.children}
              </Overlay>
            </div>
          );
        }}
      </Dropzone>
    );
  }
}
