import { Reducer } from 'redux';
import { REHYDRATE } from 'redux-persist/constants';
import { RosterActions } from '@getgo/caps-redux';
import { ActionTypes as SharedActionTypes, Actions as SharedActions } from '../../../sharedActions';

const version = 'v3';

// https://confluence.ops.expertcity.com/display/SOA/SCIM+Identity+Service#SCIMIdentityService-GetUser
export type Entitlement =
  | 'g2ars'
  | 'g2t'
  | 'g2w'
  | 'g2m'
  | 'openvoice'
  | 'acctadmin'
  | 'orgadmin'
  | 'goview'
  | 'prompt'
  | 'g2aseeit';

export type State =
  | ({
      [version]: true;
      hasLoaded: true;
    } & User)
  | {
      [version]: true;
      hasLoaded: false;
    };

const defaultState = {
  [version]: true as true,
  hasLoaded: false as false
};

export interface TrialInformation {
  endDate: string;
  active: boolean;
}

export interface User {
  id: string;
  licenseType?: 'free' | 'trial' | 'paid';
  firstName?: string;
  lastName?: string;
  locale?: string;
  key: string;
  jid: string;
  fileSharing: boolean;
  externalContacts: boolean;
  email: string;
  chat: boolean;
  meetNow: boolean;
  avatarUrl?: string;
  accountKey: string;
  token?: Token;
  entitlements: Entitlement[];
  settings?: Settings;
  timeZone: string;
  tier?: number;
  licenseName?: string;
  trialInformation?: TrialInformation;
  emailVerified: boolean;
  tcAgreement: boolean;
}

export interface Settings {
  downloadrecordingentitled: boolean;
  deleterecordingentitled: boolean;
  transcriptsenabled: boolean;
  transcriptsentitled: boolean;
  transcriptsprovisioned: boolean;
  contentsearchenabled: boolean;
  contentsearchentitled: boolean;
  contentsearchprovisioned: boolean;
}

export interface Token {
  token_type: string;
  access_token?: string;
  expires?: number;
  issued?: number;
  scope?: string;
}

const reducer: Reducer<State, SharedActions> = (state = defaultState, action) => {
  const anyAction = action as any;
  switch (anyAction.type) {
    case REHYDRATE:
      if (anyAction.payload.core?.user?.key) {
        return { ...anyAction.payload.core.user, hasLoaded: true };
      }
      return state;
    case RosterActions.userAvatarUpdated.toString():
      if (!state.hasLoaded) {
        return state;
      }
      return { ...state, avatarUrl: anyAction.payload };
  }

  switch (action.type) {
    case SharedActionTypes.UPDATE_USER:
      // remove extra properties
      /* eslint-disable no-case-declarations */
      const newUser = { ...action.payload };
      delete newUser.accessibility;

      if (state.hasLoaded && action.payload.jid !== state.jid) {
        // Do not merge state if updating to a different user (edge case)
        return { ...newUser, hasLoaded: true };
      }
      return { ...state, ...newUser, hasLoaded: true };
    default:
      return state;
  }
};

export default reducer;
