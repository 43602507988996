import { defineMessages } from 'react-intl';

export const GlobalSearchMessages = defineMessages({
  searching: {
    id: 'transcriptSearchInput.searching',
    defaultMessage: 'Searching...'
  },
  search: {
    id: 'transcriptSearchInput.search',
    defaultMessage: 'Search'
  },
  title: {
    id: 'transcriptDetailsView.title',
    defaultMessage: 'Title'
  },
  transcripts: {
    id: 'transcriptDetailsView.transcripts',
    defaultMessage: 'Transcripts'
  },
  attendees: {
    id: 'transcriptDetailsView.attendees',
    defaultMessage: 'Attendees'
  },
  recordedMeetings: {
    id: 'transcriptDetailsView.recordedMeetings',
    defaultMessage: 'You can only search transcripts for recorded meetings.'
  },
  oneMonthRecords: {
    id: 'transcriptDetailsView.oneMonthRecords',
    defaultMessage:
      "Currently search is limited to the last {days} days, but we're backporting data to allow you to search further back."
  },
  oneMonthButton: {
    id: 'transcriptDetailsView.oneMonthButton',
    defaultMessage: 'Last 1 Month'
  },
  recorded: {
    id: 'transcriptDetailsView.recorded',
    defaultMessage: 'Recorded'
  },
  loadMore: {
    id: 'transcriptDetailsView.loadMore',
    defaultMessage: 'Load More'
  },
  min: {
    id: 'transcriptDetailsView.min',
    defaultMessage: 'min'
  },
  searchMessage: {
    id: 'transcriptDetailsView.searchMessage',
    defaultMessage: 'Search for something and the results will show here.'
  },
  noResultMessage: {
    id: 'transcriptDetailsView.noResultMessage',
    defaultMessage: 'There are no results for the search term {search}'
  },
  clear: {
    id: 'transcriptDetailsView.clear',
    defaultMessage: 'Clear'
  },
  searchMessageOnLoadTop: {
    id: 'transcriptDetailsView.searchMessageOnLoadTop',
    defaultMessage: 'Search for recorded meetings by title, transcripts and attendees'
  },
  searchMessageOnLoadBottom: {
    id: 'transcriptDetailsView.searchMessageOnLoadBottom',
    defaultMessage: 'Search results will appear here.'
  },
  searchCriteriaMessage: {
    id: 'transcriptDetailsView.searchCriteriaMessage',
    defaultMessage:
      'Find keywords and terms in the Title and Transcripts tabs. Use the @ symbol to search for attendees.'
  },
  navigationBack: {
    id: 'transcriptDetailsView.navigationBack',
    defaultMessage: 'Back to search results'
  },
  navigationPrevious: {
    id: 'transcriptDetailsView.navigationPrevious',
    defaultMessage: 'Previous'
  },
  navigationNext: {
    id: 'transcriptDetailsView.navigationNext',
    defaultMessage: 'Next'
  },
  openRecording: {
    id: 'transcriptDetailsView.openRecording',
    defaultMessage: 'Open'
  },
  attendee: {
    id: 'transcriptDetailsView.attendee',
    defaultMessage: 'Attendee'
  },
  joinLeaveTime: {
    id: 'transcriptDetailsView.joinLeaveTime',
    defaultMessage: 'Join & leave times'
  },
  location: {
    id: 'transcriptDetailsView.location',
    defaultMessage: 'Location'
  },
  shareStatus: {
    id: 'transcriptDetailsView.shareStatus',
    defaultMessage: 'Share Status'
  },
  access: {
    id: 'transcriptDetailsView.access',
    defaultMessage: 'Access'
  },
  expires: {
    id: 'transcriptDetailsView.expires',
    defaultMessage: 'Expires'
  },
  notApplicable: {
    id: 'transcriptDetailsView.notApplicable',
    defaultMessage: 'N/A'
  }
});
