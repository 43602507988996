import { Reducer } from 'redux';
import { ActionTypes, Actions } from './notificationsActions';
import { REHYDRATE } from 'redux-persist/constants';
import * as React from 'react';

const version = 'v1';

export interface PushNotificationsRegistration {
  endpoint: string;
  referenceId: string;
  userKey: string;
}

export interface ToastOptions {
  timeout?: number;
  hideOkButton?: boolean;
  type?: 'success' | 'warning' | 'danger';
  buttons?: Array<{
    text: React.ReactNode;
    action: () => void;
  }>;
}

export interface ToastProps {
  id: string;
  content: React.ReactNode;
  options: ToastOptions;
}

type State = Readonly<{
  pushRegistration: Readonly<PushNotificationsRegistration> | null;
  doubleBannerAccepted: boolean;
  lastDoubleBannerTimeMs: number;
  notificationPermission: NotificationPermission | null;
  [version]: true;
  toasts: ToastProps[];
}>;

export const defaultState = {
  pushRegistration: null,
  doubleBannerAccepted: false,
  lastDoubleBannerTimeMs: 0,
  notificationPermission: null,
  toasts: [],
  [version]: true as true
};

const reducer: Reducer<State, Actions> = (state = defaultState, action) => {
  const anyAction = action as any;
  switch (anyAction.type) {
    case REHYDRATE:
      if (anyAction.payload.core?.notifications) {
        return {
          ...anyAction.payload.core.notifications,
          toasts: []
        };
      }
      return state;
  }

  switch (action.type) {
    case ActionTypes.PUSH_REGISTRATION_UPDATED:
      return {
        ...state,
        pushRegistration: {
          referenceId: action.payload.referenceId, // from backend notificationservice
          endpoint: action.payload.endpoint, // from browser push subscription
          userKey: action.payload.userKey
        }
      };
    case ActionTypes.PUSH_REGISTRATION_REMOVED:
      return { ...state, pushRegistration: null };
    case ActionTypes.ACCEPT_DOUBLE_BANNER:
      return {
        ...state,
        doubleBannerAccepted: true,
        lastDoubleBannerTimeMs: action.payload.timestamp
      };
    case ActionTypes.REJECT_DOUBLE_BANNER:
      return {
        ...state,
        doubleBannerAccepted: false,
        lastDoubleBannerTimeMs: action.payload.timestamp
      };
    case ActionTypes.NOTIFICATION_PERMISSION_UPDATED:
      return {
        ...state,
        notificationPermission: action.payload
      };
    case ActionTypes.SHOW_TOAST_NOTIFICATION:
      return {
        ...state,
        toasts: [...state.toasts, action.payload]
      };

    case ActionTypes.HIDE_TOAST_NOTIFICATION:
      return {
        ...state,
        toasts: state.toasts.filter((item) => item.id !== action.payload)
      };
  }
  return state;
};

export default reducer;
