import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { GroupActions } from '@getgo/caps-redux';
import { navigateTo } from '../services/NavService';
import CreateGroup from '../components/Chat/CreateGroup/CreateGroup';
import { selectContact } from '../view-model/capsActions';
import errorActions from '../view-model/modules/core/errors/errorActions';
import { clearQuery as clearPeopleQuery, queryAllPeople } from '../view-model/modules/messaging/people/peopleActions';
import { Dispatch, State } from '../view-model/types';

const { clearCache, clearQuery, createGroup, searchGroupExactMatch } = GroupActions;

const mapStateToProps = (state: State) => {
  return {
    initialGroupName: state.core.app.lastGroupQuery,
    isExistingPublicGroup: state.messaging.group.suggestions.some((suggestion) => suggestion.isPublic),
    isFetching: state.messaging.group.isFetching,
    people: state.messaging.people
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onCreateGroup: async (name: any, members: { length: any }, isPublic: any) => {
      dispatch(clearCache());
      dispatch(clearQuery());

      const { payload } = await dispatch(createGroup(name, isPublic));
      if (members?.length) {
        await dispatch(GroupActions.inviteMembers(payload.jid, members));
      }

      // selecting the contact will navigate back to the chat page
      dispatch(selectContact(payload.jid));

      // load group info for hasExternalMembers
      await dispatch(GroupActions.loadGroupAccountInfo(payload.jid));
    },

    onCancel: () => {
      dispatch(clearCache());
      dispatch(clearQuery());
      dispatch(clearPeopleQuery());
      navigateTo('chat');
    },

    onQueryGroup: debounce(
      (query, startIndex = 0) => {
        if (query?.trim && query.trim().length) {
          dispatch(clearQuery());
          dispatch(searchGroupExactMatch(query, startIndex));
        }
      },
      400,
      { leading: true }
    ),

    onQuery: (query?: string, nextPageKey?: string) => {
      if ((query?.trim && query.trim().length) || (nextPageKey && Number(nextPageKey) > 0)) {
        dispatch(errorActions.clearSocialGraphError());
        dispatch(queryAllPeople(query, Number(nextPageKey)));
      } else {
        dispatch(clearPeopleQuery());
      }
    },

    clearQuery: () => {
      dispatch(clearPeopleQuery());
    }
  };
};

export default (connect(mapStateToProps, mapDispatchToProps)(CreateGroup) as unknown) as React.ComponentType;
export { mapStateToProps };
