/*
 * Mobile - viewport width < 768px
 * Tablet - viewport width < 1024px
 * Laptop - viewport width < 1440px
 * default CSS-Styles apply for screens >= 1440px and can be overwritten for smaller devices
 */

const device: { [device: string]: string } = {
  smallMobile: '(max-width: 369px)',
  mobile: '(max-width: 767px)',
  tablet: '(max-width: 1023px)',
  laptop: '(max-width: 1439px)'
};

export default device;
