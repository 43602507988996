import { Reducer } from 'redux';
import Paging from '../../../../../models/Paging';
import { CoreContact } from '../../../../../types/pulse-web';
import { ActionTypes, Actions } from './peopleActions';
import { ActionTypes as ErrorActionTypes, Actions as ErrorActions } from '../../core/errors/errorActions';

const setSuggestions = (state: State, data: { to: number; total: number; people: CoreContact[] }) => {
  const nextIndex = data.to < data.total ? data.to : 0;
  const paging = new Paging(nextIndex);

  const deDuplicatedSuggestions = state.suggestions.concat(
    data.people.filter((person) => !state.suggestions.find((suggestion) => suggestion.id === person.id))
  );

  return {
    suggestions: state.isQueryUpdated ? data.people : deDuplicatedSuggestions,
    paging
  };
};

type State = Readonly<{
  apiError: boolean;
  isFetching: boolean;
  isQueryUpdated: boolean;
  paging: Paging;
  query: string;
  suggestions: CoreContact[];
}>;

export const defaultState: State = {
  apiError: false,
  isFetching: false,
  isQueryUpdated: false,
  paging: new Paging(),
  query: '',
  suggestions: []
};

const reducer: Reducer<State, Actions | ErrorActions> = (state: State = defaultState, action) => {
  switch (action.type) {
    case ErrorActionTypes.API_ERROR:
      return {
        ...state,
        isFetching: false,
        apiError: true
      };
    case ActionTypes.PEOPLE_REQUEST:
      return {
        ...state,
        isFetching: true,
        apiError: false,
        isQueryUpdated: state.query !== action.payload.query,
        query: action.payload.query
      };
    case ActionTypes.PEOPLE_RECEIVE:
      return {
        ...state,
        ...setSuggestions(state, action.payload),
        isFetching: false,
        apiError: false
      };
    case ActionTypes.PEOPLE_CLEAR:
      return defaultState;
    default:
      return state;
  }
};

export default reducer;
