import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from '../../../styled-components';
import { Box } from '../../Common/Toolkit/Box/Box';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { Spinner } from '../../Common/Toolkit/Spinner/Spinner';
import { PersonalizeMeetingProps } from '../../../view-model/modules/meetings/meetingsReducer';
import { ButtonBar } from '../../Common/Toolkit/Button/ButtonBar';
import ThemeSelector from './ThemeSelector';
import { Dialog } from '../../Common/Toolkit/Box/Dialog';
import config from 'config';
import { State as UserState } from '../../../view-model/modules/core/user/userReducer';
import { MeetingLinkInput } from './MeetingLinkInput';
import { validateProfileId } from '../../../services/MeetingService';
import schedulerMessages from './Messages';
import { fetchAvailableThemes, BrandTheme } from '../../../../models/Meeting';
import { Profile } from '../../../view-model/modules/meetings/MeetingsTypes';
import FeatureService from '../../../services/FeatureService';

const PersonalizeWindow = styled.div.attrs({ 'data-testid': 'personalize-window' })`
  padding: 0 24px 24px 24px;
  width: 570px;
  max-width: calc(100vw - 48px);
  box-sizing: border-box;
  font-size: 14px;
  color: #25282d;
  border-top: 6px solid #f68d2e;
`;

const Title = styled.h2`
  color: #333;
`;

const ConfirmDialog = styled(Dialog)`
  margin-top: -17px;
`;

const messages = defineMessages({
  personalizeTitle: {
    id: 'meetingDashboard.personalize.title',
    defaultMessage: 'Personalize Meeting Room'
  },
  createPersonalRoomTitle: {
    id: 'meetingDashboard.personalize.createTitle',
    defaultMessage: 'Set Up Your Room'
  },
  profileId: {
    id: 'meetingDashboard.personalize.profileId',
    defaultMessage: 'Meeting link'
  },
  save: {
    id: 'meetingDashboard.personalize.save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'meetingDashboard.personalize.cancel',
    defaultMessage: 'Cancel'
  },
  confirmMessage: {
    id: 'meetingDashboard.personalize.confirmMessage',
    defaultMessage:
      'Once you make this change, someone else will be able to reserve your old link. Do you want to continue?'
  },
  continue: {
    id: 'meetingDashboard.personalize.continue',
    defaultMessage: 'Continue'
  },
  dontContinue: {
    id: 'meetingDashboard.personalize.dontContinue',
    defaultMessage: "Don't Continue"
  }
});

export interface Props extends PersonalizeMeetingProps {
  profile?: Profile;
  user: UserState;
  updateProfile: (profileId: string, profile: Profile) => void;
  createProfile: (profile: Profile, subject?: string) => void;
  hidePersonalizeMeeting: () => void;
  showProfilePreview: (theme: string, roomDisplayName?: string) => void;
  isNew: boolean;
  profileIdFetching?: boolean;
  profileIdSuggestion?: string;
  suggestProfileId: (params: { firstName: string; lastName: string }) => void;
  brandingEnabled: boolean;
}

interface State {
  profileId: string;
  theme: any;
  themes: BrandTheme[] | undefined;
  profileIdChanged: boolean;
  showConfirm: boolean;
  validProfileIds: string[];
}

class PersonalizeMeeting extends React.Component<Props & WrappedComponentProps, State> {
  state: State = {
    profileIdChanged: false,

    profileId: this.profile.profileId,
    theme: this.profile.theme,
    themes: undefined,
    showConfirm: false,
    validProfileIds: []
  };

  componentDidMount() {
    if (this.props.isNew && this.props.user.hasLoaded) {
      this.props.suggestProfileId({
        firstName: this.props.user.firstName || '',
        lastName: this.props.user.lastName || ''
      });
    }

    this.setState({
      profileId: this.profile.profileId,
      theme: this.profile.theme
    });

    (async () => {
      if (this.props.brandingEnabled || FeatureService.isEnabled('hub-branding-account-themes-enabled')) {
        const themes = await fetchAvailableThemes();
        if (typeof themes !== 'undefined') {
          this.setState({ themes });
        }
      }
    })();
  }

  componentDidUpdate(prevProps: Readonly<Props & WrappedComponentProps>): void {
    if (prevProps.profileIdSuggestion !== this.props.profileIdSuggestion) {
      const newProfileId = this.props.profileIdSuggestion || '';
      this.setState({
        profileId: newProfileId
      });
    }
  }

  private onProfileIdChange = (newProfileId: string) => {
    this.setState({
      profileIdChanged: true,
      profileId: newProfileId
    });
  };

  private onUpdateProfile = () => {
    if (this.props.isNew) {
      let subject;
      if (this.props.user?.hasLoaded) {
        const name = [this.props.user.firstName, this.props.user.lastName].filter((item) => !!item);
        subject = name.length > 0 ? `${name.join(' ')}'s Meeting` : 'Meeting';
      }
      this.props.createProfile(
        {
          ...this.profile,

          profileId: this.state.profileId,
          theme: this.state.theme
        },
        subject
      );
    } else {
      this.props.updateProfile(this.profile.profileId, {
        ...this.profile,

        profileId: this.state.profileId,
        theme: this.state.theme
      } as Profile);
    }
  };

  private onCancelProfileId = () => {
    this.setState({
      profileIdChanged: false,
      profileId: this.profile.profileId,
      showConfirm: false
    });
  };

  private onSave = () => {
    if (this.hasProfileIdChanged && !this.props.isNew) {
      this.setState({
        showConfirm: true
      });
    } else {
      this.onUpdateProfile();
    }
  };

  private onChangeTheme = (theme: string) => {
    this.setState({ theme });
  };

  get hasChanged(): boolean {
    return this.profile.theme !== this.state.theme || this.hasProfileIdChanged;
  }

  get hasProfileIdChanged(): boolean {
    return this.state.profileId !== this.profile.profileId;
  }

  get profile(): Profile {
    if (this.props.isNew || !this.props.profile) {
      return {
        profileId: '',
        meetingId: '',
        theme: 'default',
        created: '',
        html5: true,
        html5Present: true
      };
    }
    return this.props.profile;
  }

  get isValidProfileIdState(): boolean {
    return this.state.validProfileIds.indexOf(this.state.profileId) !== -1;
  }

  get saveButtonDisabled(): boolean {
    if (this.props.isNew) {
      return !this.isValidProfileIdState;
    }

    return (this.hasProfileIdChanged && !this.isValidProfileIdState) || this.state.showConfirm || !this.hasChanged;
  }

  private validateProfileId = async (profileId: string) => {
    const result = await validateProfileId(profileId, this.profile.profileId);
    if (result === true) {
      if (!this.isValidProfileIdState) {
        this.setState({
          validProfileIds: [...this.state.validProfileIds, profileId]
        });
      }
      return true;
    }
    return {
      message: <FormattedMessage {...schedulerMessages[result]} />
    };
  };

  render() {
    const isFetching =
      this.props.profileIdFetching ||
      (typeof this.state.themes === 'undefined' &&
        (this.props.brandingEnabled || FeatureService.isEnabled('hub-branding-account-themes-enabled')));

    return (
      <PersonalizeWindow>
        <Title>
          {this.props.isNew ? (
            <FormattedMessage {...messages.createPersonalRoomTitle} />
          ) : (
            <FormattedMessage {...messages.personalizeTitle} />
          )}
        </Title>

        {this.props.isUpdating || isFetching ? (
          <Box flexDirection="column" alignItems="center">
            <Spinner />
          </Box>
        ) : (
          <>
            <MeetingLinkInput
              label={<FormattedMessage {...messages.profileId} />}
              value={this.state.profileId}
              onChange={this.onProfileIdChange}
              disabled={this.state.showConfirm || this.props.profileIdFetching}
              prefix={config.externalLinks.g2mm}
              initial={this.profile.profileId}
              validateInput={this.validateProfileId}
              hideMessage={this.state.showConfirm}
              sanitize={true}
            />

            {this.state.showConfirm && (
              <ConfirmDialog
                buttonOk={<FormattedMessage {...messages.continue} />}
                buttonCancel={<FormattedMessage {...messages.dontContinue} />}
                onOk={this.onUpdateProfile}
                onCancel={this.onCancelProfileId}
              >
                <FormattedMessage {...messages.confirmMessage} />
              </ConfirmDialog>
            )}

            <ThemeSelector
              theme={this.state.theme}
              onChange={this.onChangeTheme}
              showProfilePreview={this.props.showProfilePreview}
              disabled={this.state.showConfirm}
              themes={this.state.themes || []}
              useNewSelector={this.props.brandingEnabled}
            />

            <ButtonBar
              buttonCancel={<FormattedMessage {...messages.cancel} />}
              buttonOk={<FormattedMessage {...messages.save} />}
              onCancel={this.props.hidePersonalizeMeeting}
              onOk={this.onSave}
              okDisabled={this.saveButtonDisabled}
            />
          </>
        )}
      </PersonalizeWindow>
    );
  }
}

export default injectIntl(PersonalizeMeeting);
