import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import actions from '../../view-model/modules/meetings/meetingsActions';
import styled from '../../styled-components';
import { Healthcare } from '../Common/Toolkit/Icon/ReactIcons';
import { IconButton } from './MeetingComponents';

const messages = defineMessages({
  diagnostics: {
    id: 'meetingHub.meetingComponents.diagnostics',
    defaultMessage: 'diagnostics'
  },
  diagnosticsExpiredTooltip: {
    id: 'meetingHub.meetingComponents.diagnosticsExpiredTooltip',
    defaultMessage: 'Meeting Diagnostics report has expired'
  }
});

interface Props {
  targetUrl?: string;
  className?: string;
  expired?: boolean;
  isLive: boolean;
}

export const DiagnosticsButton: React.FC<Props> = (props) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const openHandler = () => {
    window.open(props.targetUrl, '_blank');
    dispatch(actions.diagnosticsOpened(props.isLive ? 'live' : 'historical'));
  };

  const DiagnosticsButton = styled(() => (
    <span data-tip={props.expired ? f(messages.diagnosticsExpiredTooltip) : null}>
      <IconButton
        data-testid="button-diagnostics"
        className={props.className}
        onClick={openHandler}
        icon={<Healthcare />}
        disabled={props.expired}
      >
        {f(messages.diagnostics)}
      </IconButton>
    </span>
  ))`
    padding: 0;
    flex-grow: 0;
  `;

  return <DiagnosticsButton />;
};
