import { FilesMessage, File } from '@getgo/caps-redux';
import * as React from 'react';
import { debounce } from 'lodash';
import { Avatar } from '../Avatar/Avatar';
import { getPreviewUrl } from '../../../services/PreviewService';
import './ImagePreview.css';
import { KeyNames } from '../../../../lib/keycodes';
import { CenteredSpinner } from '../Toolkit/Spinner/Spinner';
import FocusTrap from 'focus-trap-react';
import { defineMessages, useIntl } from 'react-intl';

const previewHeight = 1024;

const messages = defineMessages({
  close: {
    id: 'chat.imagePreview.close',
    defaultMessage: 'Close'
  },
  left: {
    id: 'chat.imagePreview.left',
    defaultMessage: 'Left cycle'
  },
  right: {
    id: 'chat.imagePreview.right',
    defaultMessage: 'Right cycle'
  },
  download: {
    id: 'chat.imagePreview.download',
    defaultMessage: 'Download link'
  }
});

interface Props {
  avatarUrl?: string;
  name?: string | null;
  message?: FilesMessage;
  onNavigation: (delta: -1 | 1) => void;
  onPreviewClose: (event: KeyboardEvent | React.MouseEvent<HTMLButtonElement>) => void;
}

interface State {
  showSpinner: boolean;
}

export class ImagePreview extends React.Component<Props, State> {
  state = {
    showSpinner: true
  };
  overlayRef: HTMLElement | null = null;

  goBack = () => {
    this.props.onNavigation(-1);
  };

  goForward = () => {
    this.props.onNavigation(1);
  };

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('wheel', this.wheelHandler);

    if (this.overlayRef) {
      this.overlayRef.focus();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('wheel', this.wheelHandler);
  }

  onImageLoad = () => {
    this.setState({ showSpinner: false });
  };
  onImageError = () => {
    this.setState({ showSpinner: false });
  };

  handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === KeyNames.ESCAPE) {
      event.preventDefault();
      this.props.onPreviewClose(event);
    } else if (event.key === KeyNames.ARROW_LEFT) {
      event.preventDefault();
      this.goBack();
    } else if (event.key === KeyNames.ARROW_RIGHT) {
      event.preventDefault();
      this.goForward();
    }
  };

  handleWheel = (event: WheelEvent) => {
    event.preventDefault();
    this.props.onNavigation(event.deltaY > 0 ? 1 : -1);
  };

  wheelHandler = debounce(this.handleWheel, 50, { leading: true, trailing: false });

  render() {
    // TODO: Check if this solution does not change behavior
    if (!this.props.message) {
      return null;
    }
    const [file]: File[] = this.props.message.files;

    return (
      <FocusTrap>
        <div className="preview-container-overlay" tabIndex={-1} ref={(ref) => (this.overlayRef = ref)}>
          <div className="header">
            <Avatar name={this.props.name === null ? undefined : this.props.name} img={this.props.avatarUrl} />
            <div className="description">
              <div className="file">{file.name}</div>
              <div className="message-from">{this.props.name}</div>
            </div>
            <div className="controls">
              <DownloadLink href={file.href} />
              <CloseButtonWrapper onClick={this.props.onPreviewClose}>
                <i className="togo-icon togo-icon-closes" />
              </CloseButtonWrapper>
            </div>
          </div>
          <div className="image-container">
            {this.state.showSpinner ? <CenteredSpinner /> : null}
            <img
              className="image"
              key={file.href}
              src={getPreviewUrl(file.href, previewHeight)}
              onLoad={this.onImageLoad}
              onError={this.onImageError}
              alt=""
            />
            <LeftButton onClick={this.goBack} />
            <RightButton onClick={this.goForward} />
          </div>
        </div>
      </FocusTrap>
    );
  }
}

interface CloseButtonWrapperProps {
  onClick?: (event: KeyboardEvent | React.MouseEvent<HTMLButtonElement>) => void;
}

const CloseButtonWrapper: React.FC<CloseButtonWrapperProps> = ({ children, onClick }) => {
  const { formatMessage: f } = useIntl();
  return (
    <button
      className="close-preview btn-icon remove-button"
      onClick={onClick}
      data-testid="button-close-preview"
      aria-label={f(messages.close)}
    >
      {children}
    </button>
  );
};

const LeftButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { formatMessage: f } = useIntl();
  return (
    <button className="navigation left" onClick={onClick} aria-label={f(messages.left)}>
      <i className="togo-icon togo-icon-arrow-left" />
    </button>
  );
};

const RightButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { formatMessage: f } = useIntl();
  return (
    <button className="navigation right" onClick={onClick} aria-label={f(messages.right)}>
      <i className="togo-icon togo-icon-arrow-right" />
    </button>
  );
};

const DownloadLink: React.FC<{ href?: string }> = ({ href }) => {
  const { formatMessage: f } = useIntl();
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" aria-label={f(messages.download)}>
      <span className="download-icon">
        <i className="togo-icon togo-icon-download" />
      </span>
    </a>
  );
};
