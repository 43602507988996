import { throttle } from 'lodash';
import appActions, { changePageVisibility } from '../view-model/modules/core/app/appActions';
import errorActions, { offlineWarning } from '../view-model/modules/core/errors/errorActions';
import { Store } from '../view-model/types';
import { startTodayDateUpdater } from './TimeService';
import sharedActions, { UserWithPreferences } from '../view-model/sharedActions';
import { getTodayDate } from '../../lib/date';
import { getTodayFromState } from '../view-model/selectors';
import { selectMeeting } from '../view-model/modules/meetings/meetingsActions';
import { getShortLocale } from '../../lib/locales';

export const updateMeetingOnFocus = throttle(
  (store) => {
    const { selectedId, selectedItemType } = store.getState().meetings;
    store.dispatch(selectMeeting(selectedItemType, selectedId));
  },
  15000,
  { trailing: false }
);

export class AppService {
  private store: Store;

  constructor(store: Store) {
    this.store = store;
  }

  start(store = this.store, user: UserWithPreferences) {
    this.store = store;

    startTodayDateUpdater((today) => {
      this.store.dispatch(appActions.setTodayDate(today));
    });
    this.setDocumentLanguage(user.locale);

    return this.updateUserInStore(user);
  }

  changeAppVisibility(isVisible: boolean) {
    this.store.dispatch(changePageVisibility(isVisible));
  }

  changeAppFocus() {
    updateMeetingOnFocus(this.store);
  }

  changeAppOnlineState(online: boolean) {
    this.store.dispatch(appActions.setOnlineStatus(online));

    if (online) {
      const todayFromState = getTodayFromState(this.store.getState());
      const today = getTodayDate();
      if (today.getTime() !== todayFromState.getTime()) {
        this.store.dispatch(appActions.setTodayDate(today));
      }
      this.store.dispatch(errorActions.clearNetworkError());
      this.store.dispatch(errorActions.clearMeetingError());
    } else {
      this.store.dispatch(offlineWarning());
    }
  }

  setDocumentLanguage(locale = 'en_US') {
    document.documentElement.lang = getShortLocale(locale) || 'en';
  }

  updateUserInStore(user: UserWithPreferences) {
    return this.store.dispatch(sharedActions.updateUser(user));
  }
}
