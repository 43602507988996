import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { getFullName } from '../../../lib/person';
import { User } from '../../../types/pulse-web';
import { Avatar } from '../Common/Avatar/Avatar';
import './NoContactChat.css';

const messages = defineMessages({
  welcomeTitle: {
    id: 'chat.noContactChat.welcomeTitle',
    defaultMessage: 'Hello {name},'
  },
  welcomeBody: {
    id: 'chat.noContactChat.welcomeBody',
    defaultMessage: 'Invite others to join your conversation.'
  }
});

export interface Props {
  onSetSidebarOpen?: () => void;
  sidebarDocked?: boolean;
  user: User;
}

export const NoContactChat: React.FC<Props> = (props) => {
  const { onSetSidebarOpen = () => {}, sidebarDocked = false } = props;
  let sidebarOpenButton;
  if (!sidebarDocked) {
    sidebarOpenButton = (
      <button data-testid="sidebar-open-button" className="btn btn-icon sidebar-open-button" onClick={onSetSidebarOpen}>
        ☰
      </button>
    );
  }

  return (
    <div className="no-contact-chat">
      <header className="chat-header">{sidebarOpenButton}</header>
      <div className="content">
        <div className="no-contact-chat-avatar">
          <Avatar name={getFullName(props.user)} img={props.user.avatarUrl} size="large" />
        </div>
        <div className="no-contact-chat-text">
          <FormattedMessage
            tagName="h2"
            {...messages.welcomeTitle}
            values={{ name: props.user ? getFullName(props.user) : '' }}
          />
          <FormattedMessage tagName="p" {...messages.welcomeBody} />
        </div>
      </div>
    </div>
  );
};
