import styled from '../../../../styled-components';
import React from 'react';
import { useIntl } from 'react-intl';
import { getFormattedDay, getTodayDate } from '../../../../../lib/date';
import { themeGet } from '@styled-system/theme-get';

const MeetingSeparatorLi = styled.li`
  padding-top: 16px;
  font-family: Lato-Bold;
  font-size: 18px;
  font-weight: bold;
  height: 24px;
  color: ${themeGet('colors.neutralScale.450')};
  line-height: 1.5em;

  &:first-of-type {
    padding-top: 8px;
  }
`;

interface Props {
  date?: Date | string;
  className?: string;
}

export const MeetingsDateSeparator: React.FC<Props> = (props) => {
  const intl = useIntl();
  let { date = '' } = props;
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const today = getTodayDate();

  return (
    <MeetingSeparatorLi className={props.className}>
      <span>{getFormattedDay(intl, date, today)}</span>
    </MeetingSeparatorLi>
  );
};
