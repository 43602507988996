import styled from '../../../styled-components';

export const DashboardContainer = styled.div`
  flex: 1 1 auto;
  background-color: #f0f2f5;
  overflow: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;
