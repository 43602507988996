import { SearchListContent, TranscriptsTabContainer } from '../GlobalSearchDetailsView';
import { Box, Item } from '../../Common/Toolkit/Box/Box';
import { Clock, PeopleFilled } from '../../Common/Toolkit/Icon/ReactIcons';
import { GlobalSearchMessages as messages } from '../GlobalSearchMessages';
import {
  evaluateMeetingYear,
  OpenRecordingContainer,
  OpenSearchRecordingResultButton
} from '../GlobalSearchComponents';
import React, { useState } from 'react';
import styled from '../../../styled-components';
import device from '../../Common/Toolkit/devices';
import { Participant, SessionFromElasticSearch } from '../../../view-model/modules/meetings/meetingsReducer';
import { useIntl } from 'react-intl';
import {
  fetchSingleSession,
  selectMeeting,
  triggerTelemetryElasticSearch
} from '../../../view-model/modules/meetings/meetingsActions';
import { useDispatch } from 'react-redux';
import { SelectableItemType } from '../../../view-model/modules/meetings/MeetingsTypes';

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-wrap: wrap;
  align-items: center;
`;

const ResultItemTime = styled(Item)`
  flex: 0 0 40px;
  text-align: center;
  padding: 0 18px 0 0;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    padding: 0 16px 0 0;
  }
`;

const ItemDate = styled(Item)`
  font-size: 20px;
`;

const SearchDetails = styled(Item)`
  margin-right: 10px;
  @media ${device.mobile} {
    margin-top: 5px;
  }
`;

const IconContainer = styled(({ component, ...props }) => React.cloneElement(component, props))`
  display: inline-block;
  margin-right: 3px;
  vertical-align: middle;
  margin-bottom: 1px;
`;

const SearchText = styled.span`
  vertical-align: middle;
`;

const MeetingsTitle = styled.h3<{ color?: string; textAlign?: string; whiteSpace?: string }>`
  margin: -4px 0 5px 0;
  padding-top: 4px;
  font-size: 20px;
  line-height: 24px;
  font-family: Lato-bold;
  font-weight: bold;
  text-align: ${(props) => (props.textAlign ? props.textAlign : '')};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  overflow: hidden;
  text-overflow: ellipsis;

  em {
    background: #fff7d9;
    font-style: normal;
  }
`;

const ItemMonth = styled(MeetingsTitle)`
  color: #e02d52;
  font-size: 11px;
  font-weight: 600;
  margin: -4px 0 3px 0;
  text-transform: uppercase;
`;

const MeetingDetails = styled.span`
  color: #25282d;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const TranscriptsSubject = styled(Item)`
  flex: 1;
  display: flex;
  flex-direction: column;
  display: inline-grid;
  word-break: break-all;
  padding-top: 3px;
`;

const ListItems = styled.li<{ recorded: boolean }>`
  margin-bottom: 16px;
  position: relative;
  border-radius: 3px;
  border: solid 1px #eeeeee;
  cursor: ${(props) => (props.recorded ? 'pointer' : 'default')};
  pointer-events: ${(props) => (props.recorded ? 'auto' : 'none')};
  &:hover {
    border: solid 1px rgba(76, 83, 93, 0.24);
  }
`;

const MeetingDate = styled.span`
  margin-left: 5px;
  margin-right: 5px;
`;

interface Props {
  sessionId: string;
  isCbr: boolean;
  participants: Participant[];
  title: string;
  meetingStartTime: string;
  duration: number;
  month: any;
  date: number | string;
  handleRecording: (sessionId: SessionFromElasticSearch) => void;
  session: SessionFromElasticSearch;
  disableOnClickOutside: () => void;
  startTime: number;
}

export const TitleTabRow: React.FC<Props> = (props: Props) => {
  const intl = useIntl();
  const { formatMessage: f } = intl;
  const {
    sessionId,
    duration,
    meetingStartTime,
    month,
    isCbr,
    participants,
    handleRecording,
    date,
    title,
    startTime
  } = props;
  const dispatch = useDispatch();
  const meetingDate = evaluateMeetingYear(startTime);

  const [showOpenButton, setShowOpenButton] = useState(false);

  const handleMouseEnter = () => {
    setShowOpenButton(true);
  };

  const handleMouseLeave = () => {
    setShowOpenButton(false);
  };

  const openTitleSearchResultView = () => {
    const id = sessionId.split('-')[1];
    props.disableOnClickOutside();
    dispatch(fetchSingleSession(id));
    dispatch(triggerTelemetryElasticSearch('sessionPreviewFromGlobalSearch'));
    dispatch(selectMeeting(SelectableItemType.ELASTICSEARCHRESULTSELECTED, ''));
  };

  const titleSearchResultItem = (
    <ListItems
      recorded={isCbr}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid="title-container"
    >
      <SearchListContent onClick={openTitleSearchResultView}>
        <TranscriptsTabContainer>
          <Box flexDirection="row">
            <ResultItemTime>
              <ItemMonth>{month}</ItemMonth>
              <ItemDate>{date}</ItemDate>
            </ResultItemTime>
            <TranscriptsSubject>
              <MeetingsTitle dangerouslySetInnerHTML={{ __html: title }} />
              <DetailsContainer>
                <MeetingDetails>
                  <i className="togo-icon togo-icon-calendar togo-icon-lg" />
                  <MeetingDate>{meetingDate}</MeetingDate>
                </MeetingDetails>
                <SearchDetails>
                  <IconContainer component={<Clock />} />
                  <SearchText>
                    {meetingStartTime}
                    {duration ? ` - ${duration} ${f(messages.min)}` : ''}
                  </SearchText>
                </SearchDetails>
                <SearchDetails>
                  <IconContainer component={<PeopleFilled />} />
                  <SearchText>
                    {participants ? participants.length : 0} {f(messages.attendees)}
                  </SearchText>
                </SearchDetails>
              </DetailsContainer>
            </TranscriptsSubject>
          </Box>
        </TranscriptsTabContainer>
      </SearchListContent>
      {showOpenButton && (
        <OpenRecordingContainer>
          <OpenSearchRecordingResultButton onClick={() => handleRecording(props.session)} />
        </OpenRecordingContainer>
      )}
    </ListItems>
  );

  return <>{titleSearchResultItem}</>;
};
