import { connect } from 'react-redux';
import actions, {
  changeInvitation,
  createProfile,
  deleteMeeting,
  deleteSessionRecording,
  endMeeting,
  fetchMyMeetings,
  fetchProfile,
  getProfileIdSuggestion,
  join,
  meetNow,
  selectMeeting,
  showDeleteMeeting,
  startMeeting,
  updateMeetingSessionDetails,
  updateProfile
} from '../view-model/modules/meetings/meetingsActions';
import schedulerActions, {
  createMeeting,
  showCreateMeeting,
  showEditMeeting,
  updateMeeting,
  interstitialCopyInvitation
} from '../view-model/modules/scheduler/schedulerActions';
import MeetingsDashboard from '../components/Meetings/MeetingsDashboard';
import errorActions from '../view-model/modules/core/errors/errorActions';
import sharedActions from '../view-model/sharedActions';
import {
  getInvitationForMeetingId,
  getIsOnline,
  getItemForCurrentSelection,
  getProfileFromMeetingList,
  getRemainingTrialDays,
  isFreeUser,
  isTrialUser
} from '../view-model/selectors';
import { Dispatch, State } from '../view-model/types';
import { User } from '../view-model/modules/core/user/userReducer';
import { MeetingRequest, SchedulerFullMeeting } from '../view-model/modules/scheduler/schedulerReducer';
import { SchedulerTabs } from '../components/Meetings/MeetingScheduler/MeetingScheduler';
import { RouteComponentProps } from 'react-router-dom';
import { Meeting, Profile, SelectableItemType } from '../view-model/modules/meetings/MeetingsTypes';
import { SessionUpdates } from '../view-model/modules/meetings/meetingsReducer';

const isPstnAvailable = (settings: any) => {
  return settings.longDistanceAllowed || settings.tollFreeAllowed;
};

const mapStateToProps = (state: State, ownProps: RouteComponentProps<any, any>) => {
  return {
    location: ownProps.location,
    user: state.core.user,
    scheduler: state.scheduler,
    isOnline: getIsOnline(state),
    isFetching: state.meetings.isFetching,
    isFetchingProfile: state.meetings.isFetchingProfile,
    pstnAllowed: isPstnAvailable(state.core.preferences.meetings.current),
    brandingEnabled:
      state.core.preferences.meetings.current.isBrandingEntitled &&
      state.core.preferences.meetings.current.isBrandingProvisioned,

    invitation: getInvitationForMeetingId(state),

    profile: state.meetings.profile,
    profileMeeting: getProfileFromMeetingList(state),

    meetings: state.meetings.meetings,
    selectedItem: getItemForCurrentSelection(state),
    selectedId: state.meetings.selectedId,
    selectedItemType: state.meetings.selectedItemType,

    currentDeleteMeeting: state.meetings.currentDeleteMeeting,
    currentMeetingIsInSessionErrorData: state.meetings.currentMeetingIsInSessionErrorData,
    errorWindow: state.meetings.errorWindow,

    currentPersonalizeMeeting: state.meetings.currentPersonalizeMeeting,
    profilePreview: state.meetings.profilePreview,

    trialExpiresInDays: getRemainingTrialDays(state),
    isTrialUser: isTrialUser(state),
    isFreeUser: isFreeUser(state),
    elasticSearchContentList: state.meetings.elasticSearchContentList,
    elasticSearchPagesInfo: state.meetings.elasticSearchPagesInfo,
    isFetchingElasticSearch: state.meetings.isFetchingElasticSearch,
    elasticSearchSession: state.meetings.elasticSearchSession
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchProfile() {
      dispatch(fetchProfile());
    },

    fetchMyMeetings() {
      dispatch(errorActions.clearMeetingError());
      dispatch(fetchMyMeetings());
    },

    joinMeeting(meetingId: string) {
      dispatch(join(meetingId));
    },

    interstitialCopyInvitation(meeting: Meeting, profile?: Profile) {
      dispatch(interstitialCopyInvitation(meeting, profile));
    },

    showCreateMeeting(user: User) {
      dispatch(showCreateMeeting(user));
    },

    showEditMeeting(meetingId: string) {
      dispatch(showEditMeeting(meetingId));
    },

    schedulerChangeProperty(property: keyof SchedulerFullMeeting, value: any) {
      dispatch(schedulerActions.schedulerChangeProperty(property, value));
    },

    schedulerValidationError(property: keyof SchedulerFullMeeting, tab: SchedulerTabs) {
      dispatch(schedulerActions.schedulerValidationError(property, tab));
    },

    schedulerValidationSuccess(property: keyof SchedulerFullMeeting, tab: SchedulerTabs) {
      dispatch(schedulerActions.schedulerValidationSuccess(property, tab));
    },

    async createMeeting(meeting: MeetingRequest) {
      await dispatch(createMeeting(meeting));
    },

    async updateMeeting(
      meetingId: string,
      meeting: MeetingRequest,
      updatedMeeting: MeetingRequest,
      isPersonalMeeting: boolean
    ) {
      await dispatch(updateMeeting(meetingId, meeting, updatedMeeting, isPersonalMeeting));
    },

    hideScheduleMeeting() {
      dispatch(actions.hideScheduleMeeting());
    },

    deleteMeeting(meetingId: string) {
      dispatch(deleteMeeting(meetingId));
    },

    showDeleteMeeting(meetingId: string) {
      dispatch(showDeleteMeeting(meetingId));
    },

    showPersonalizeMeeting() {
      dispatch(actions.showPersonalizeMeeting());
    },

    showCreatePersonalMeeting() {
      dispatch(actions.showCreatePersonalMeeting());
    },

    showProfilePreview(theme: string, roomDisplayName?: string) {
      dispatch(actions.showProfilePreview(theme, roomDisplayName));
    },

    suggestProfileId(params: { firstName: string; lastName: string }) {
      dispatch(getProfileIdSuggestion(params));
    },

    hideProfilePreview() {
      dispatch(actions.hideProfilePreview());
    },

    hideErrorWindow() {
      dispatch(actions.hideErrorWindow());
    },

    updateProfile(profileId: string, profile: Profile) {
      dispatch(updateProfile(profileId, profile));
    },

    createProfile(profile: Profile, subject?: string) {
      dispatch(createProfile(profile, subject));
    },

    hidePersonalizeMeeting() {
      dispatch(actions.hidePersonalizeMeeting());
    },

    hideDeleteMeeting() {
      dispatch(actions.hideDeleteMeeting());
    },

    hideMeetingInSessionError() {
      dispatch(actions.hideMeetingInSessionError());
    },

    startMeeting(meetingId: string, meetingType: SelectableItemType) {
      return dispatch(startMeeting(meetingId, meetingType));
    },

    invitationCopied() {
      dispatch(actions.invitationCopied());
    },

    changeInvitation(meetingId: string, invitation: string) {
      dispatch(changeInvitation(meetingId, invitation));
    },

    selectMeeting(itemType: SelectableItemType, id: string | null = null) {
      dispatch(selectMeeting(itemType, id));
    },

    meetNow() {
      return dispatch(meetNow());
    },

    endMeeting(meeting: Meeting) {
      dispatch(endMeeting(meeting));
    },

    deleteSessionRecording(sessionId: string, meetingId: string, recordingId: string) {
      dispatch(deleteSessionRecording(sessionId, meetingId, recordingId));
    },
    recordingSessionOpened() {
      dispatch(actions.recordingSessionOpened());
    },
    downloadSessionRecording(meetingId: string, recordingId: string) {
      dispatch(actions.recordingSessionDownloaded(meetingId, recordingId));
    },
    copyStandalonePlayerUrl(meetingId: string, recordingId: string) {
      dispatch(actions.standalonePlayerUrlCopied(meetingId, recordingId));
    },
    reportNavigation(destination: string) {
      dispatch(sharedActions.navigationByClick(destination));
    },
    updateMeetingSessionData(sessionId: string, updatedSession: SessionUpdates) {
      dispatch(updateMeetingSessionDetails(sessionId, updatedSession));
    },
    meetingTitleEdited(view: string) {
      dispatch(actions.meetingTitleEdited(view));
    },
    updateMeetingTitleEditError(error: boolean) {
      dispatch(actions.updatingMeetingSessionDataError(error));
    }
  };
};

const options = {
  forwardRef: true
};
const Container = connect(mapStateToProps, mapDispatchToProps, null, options)(MeetingsDashboard);

export default Container;
