import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, defineMessages, injectIntl, IntlShape } from 'react-intl';
import FileDrop from 'react-file-drop';
import classNames from 'classnames';
import { PreferencesBlock } from '../../Common/Preferences/PreferencesBlock';
import { Box } from '../../Common/Toolkit/Box/Box';
import './LogoPreferenceBlock.css';

const messages = defineMessages({
  logoHeader: {
    id: 'logoPreferenceBlock.logoHeader',
    defaultMessage: 'Personal Logo'
  },
  logoDescription: {
    id: 'logoPreferenceBlock.logoDescription',
    defaultMessage: 'Your attendees will see this logo when screen sharing is stopped.'
  },
  logoConstraints: {
    id: 'logoPreferenceBlock.logoConstraints',
    defaultMessage: 'Upload a GIF or JPG<br>(400 x 200 pixels, max 100KB)'
  },
  logoTooltip: {
    id: 'logoPreferenceBlock.logoTooltip',
    defaultMessage: 'For now, this logo only appears in the classic meeting design. More developments are underway.'
  },
  uploadLogo: {
    id: 'logoPreferenceBlock.uploadLogo',
    defaultMessage: 'Upload Logo'
  },
  removeLogo: {
    id: 'logoPreferenceBlock.removeLogo',
    defaultMessage: 'Remove Logo'
  },
  dimensionsTooLargeError: {
    id: 'logoPreferenceBlock.dimensionsTooLargeError',
    defaultMessage: 'File must be 400 x 200 pixels or less.'
  },
  sizeTooLargeError: {
    id: 'logoPreferenceBlock.sizeTooLargeError',
    defaultMessage: 'File must be smaller than 100KB.'
  },
  fileFormatError: {
    id: 'logoPreferenceBlock.fileFormatError',
    defaultMessage: 'File must be a .gif or .jpg.'
  }
});

const loadLogoFromDisk = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string), false);
    reader.addEventListener('error', () => reject(reader.error), false);
    reader.readAsDataURL(file);
  });

const isWithinAllowedDimensions = (dataUrl: string) =>
  new Promise<boolean>((resolve) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image.width <= 400 && image.height <= 200), false);
    image.src = dataUrl;
  });

interface State {
  showSizeError: boolean;
  showDimensionsError: boolean;
  showFormatError: boolean;
  isDraggingFile: boolean;
}

interface Props {
  logo?: null | { dataUrl: string; allowDeletion: boolean };
  onChange: (setting: 'logo', logo: null | { dataUrl: string; allowDeletion: boolean }) => void;
  intl: IntlShape;
}

class LogoPreferenceBlock extends React.Component<Props, State> {
  state: State = {
    showSizeError: false,
    showDimensionsError: false,
    showFormatError: false,
    isDraggingFile: false
  };

  private removeLogo = () => {
    this.setState({ showSizeError: false, showDimensionsError: false, showFormatError: false });
    this.props.onChange('logo', null);
  };

  private handleFileChange = async (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (!changeEvent.target.files) {
      return;
    }
    return this.processFile(changeEvent.target.files[0]);
  };

  private handleDragOver = () => {
    this.setState({
      isDraggingFile: true
    });
  };

  private handleDragLeave = () => {
    this.setState({
      isDraggingFile: false
    });
  };

  private handleDrop = (files: FileList | null) => {
    this.setState({
      isDraggingFile: false
    });

    if (!files) {
      return;
    }

    return this.processFile(files[0]);
  };

  private processFile = async (file: File) => {
    this.setState({ showSizeError: false, showDimensionsError: false, showFormatError: false });

    if (!(file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/pjpeg')) {
      this.setState({ showFormatError: true });
      return;
    }
    if (file.size > 100000) {
      this.setState({ showSizeError: true });
      return;
    }
    const dataUrl = await loadLogoFromDisk(file);
    const dimensionCheck = await isWithinAllowedDimensions(dataUrl);
    if (!dimensionCheck) {
      this.setState({ showDimensionsError: true });
      return;
    }
    this.props.onChange('logo', { dataUrl, allowDeletion: true });
  };

  render() {
    return (
      <PreferencesBlock
        id="personal-logo"
        titleMessage={messages.logoHeader}
        descriptionMessage={messages.logoDescription}
        infoMessage={messages.logoTooltip}
      >
        <Box flexWrap="wrap">
          <FileDrop
            onDragOver={this.handleDragOver}
            onDragLeave={this.handleDragLeave}
            onDrop={this.handleDrop}
            dropEffect="copy"
          >
            <div
              data-testid="personal-logo-preview"
              className={classNames('logo-upload', this.state.isDraggingFile && 'dragging')}
              style={{ backgroundImage: this.props.logo ? `url(${this.props.logo.dataUrl})` : undefined }}
              data-dnd
            >
              <div className="logo-upload-overlay" />
              <div className="upload-label">
                <FormattedMessage {...messages.uploadLogo} />
                {!this.state.isDraggingFile && !!this.props.logo?.allowDeletion && (
                  <>
                    {' | '}
                    <a className="remove-link" onClick={this.removeLogo}>
                      <FormattedMessage {...messages.removeLogo} />
                    </a>
                  </>
                )}
              </div>
              <input
                aria-label={this.props.intl.formatMessage(messages.logoHeader)}
                data-testid="personal-logo-input"
                value=""
                onChange={this.handleFileChange}
                type="file"
                accept="image/jpeg,image/gif,image/pjpeg"
              />
            </div>
          </FileDrop>
          <div className="logo-constraints">
            <FormattedHTMLMessage {...messages.logoConstraints} />
            <div className="logo-error">
              {this.state.showFormatError && (
                <>
                  <FormattedMessage {...messages.fileFormatError} />
                  <br />
                </>
              )}
              {this.state.showDimensionsError && (
                <>
                  <FormattedMessage {...messages.dimensionsTooLargeError} />
                  <br />
                </>
              )}
              {this.state.showSizeError && <FormattedMessage {...messages.sizeTooLargeError} />}
            </div>
          </div>
        </Box>
      </PreferencesBlock>
    );
  }
}

export default injectIntl(LogoPreferenceBlock);
