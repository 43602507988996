import { connect } from 'react-redux';
import { GroupActions } from '@getgo/caps-redux';
import { navigateTo } from '../services/NavService';
import { selectContact } from '../view-model/capsActions';
import AddGroup from '../components/Chat/AddGroup/AddGroup';
import { Dispatch, State } from '../view-model/types';

const { clearCache, clearQuery, searchGroups } = GroupActions;

interface OwnProps {
  enableCancel?: boolean;
  maxInputLength?: number;
}

const mapStateToProps = (state: State) => {
  return {
    connectedAndReady: state.messaging.chat.connectionStatus.xmppConnected && state.messaging.contacts.initialized,
    group: state.messaging.group
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onQuery: (query: string, startIndex = 0, itemsPerPage = 50) => {
      if (query?.trim && query.trim().length) {
        dispatch(clearQuery());
      }

      // allow empty queries to go through to show
      // the full list of groupsGroupSearchContainer
      dispatch(searchGroups(query, startIndex, itemsPerPage));
    },

    onShowCreateGroup: async () => {
      dispatch(clearQuery());
      navigateTo('createGroup');
    },

    onAddGroup: async (jid: string, name: string, isPublic: boolean) => {
      await dispatch(GroupActions.joinGroup(jid, name, { isPublic }));
      dispatch(selectContact(jid));
      dispatch(clearCache());
      dispatch(clearQuery());
    },

    onCancel: () => {
      dispatch(clearCache());
      dispatch(clearQuery());
      navigateTo('chat');
    }
  };
};

export default connect<ReturnType<typeof mapStateToProps>, ReturnType<typeof mapDispatchToProps>, OwnProps, State>(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(AddGroup);
export { mapStateToProps };
