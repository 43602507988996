import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import bytes from 'bytes';
import config from 'config';

const messages = defineMessages({
  uploadingFile: {
    id: 'common.upload.uploadProgress.description.file',
    defaultMessage: 'Uploading {file}'
  },
  errorUploadingFile: {
    id: 'common.upload.uploadProgress.description.error',
    defaultMessage: 'Error uploading {file}'
  },
  sizeLimitExceeded: {
    id: 'common.upload.uploadProgress.description.sizeLimitExceeded',
    defaultMessage: 'File size limit of {limit} exceeded.'
  },
  unsupportedFileType: {
    id: 'common.upload.uploadProgress.description.unsupportedFileType',
    defaultMessage: 'This file type is not supported. Try uploading a .zip version instead.'
  }
});

interface Props {
  file: {
    name: string;
    size: number;
  };
  error?: {
    message: 'uploadingFile' | 'errorUploadingFile' | 'sizeLimitExceeded' | 'unsupportedFileType';
  };
}

export const Description: React.FC<Props> = ({ file, error }) => {
  const mainMessage = error ? messages.errorUploadingFile : messages.uploadingFile;

  const renderErrorMessage = () => {
    if (error && messages[error.message]) {
      return (
        <div className="upload-error">
          <FormattedMessage {...messages[error.message]} values={{ limit: bytes(config.upload.limit) }} />
        </div>
      );
    }

    return null;
  };

  return (
    <div className="description">
      <FormattedMessage {...mainMessage} values={{ file: file.name }} />
      {renderErrorMessage()}
      <span className="file-size">{bytes(file.size)}</span>
    </div>
  );
};
