import React from 'react';

import Select from 'react-select';
import styled from '../../../styled-components';
import { injectIntl, WrappedComponentProps } from 'react-intl';

const StyledSelect = styled(Select)`
  .react-select__value-container {
    padding: 2px 4px;
  }

  .react-select__option--is-focused {
    background-color: #f0f0f0;
  }

  .react-select__option--is-selected {
    background-color: #0071e6;
  }

  .react-select__single-value {
    color: #006aff;
  }

  .react-select__menu {
    z-index: 10;
  }

  .react-select__single-value__label {
    padding: 3px 6px 3px 3px;
  }
`;
StyledSelect.displayName = 'StyledSelect';

interface Props {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  selectValueChange?: (value: any) => void;
  selectedValue: number;
  id: string;
  menuPosition?: 'top' | 'bottom' | 'auto';
  options: ListOptions[];
  ariaLabel: string;
}

interface ListOptions {
  value: number;
  label: string;
}

class PreferencesItemSelectList extends React.PureComponent<Props & WrappedComponentProps> {
  render() {
    const {
      id,
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      selectedValue,
      menuPosition,
      options,
      ariaLabel
    } = this.props;
    const value = options.find((item: any) => item.value === selectedValue);
    return (
      <StyledSelect
        id={id}
        menuPlacement={menuPosition}
        defaultValue={value}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isSearchable={isSearchable}
        options={options}
        onChange={this.props.selectValueChange}
        classNamePrefix="react-select"
        aria-label={ariaLabel}
      />
    );
  }
}

export default injectIntl(PreferencesItemSelectList);
