/**
 * returns the closest number to num in [low, high] interval
 * @param num: number to be clamped
 * @param low: lower interval boundary
 * @param high: higher interval boundary
 */
export const clamp = (num: number, low: number, high: number) => {
  if (high < low) {
    throw new Error('clamp: High cannot be less than low');
  }
  if (isNaN(num) || isNaN(low) || isNaN(high)) {
    throw new Error('clamp: Parameters cannot be NaN');
  }

  if (num > high) {
    return high;
  }
  if (num < low) {
    return low;
  }

  return num;
};
