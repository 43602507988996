import { FilesMessage, File } from '@getgo/caps-redux';
import bytes from 'bytes';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { FileIcon } from '../../../Common/FileIcon/FileIcon';
import { isHoverSupported } from '../../../../services/ClientService';
import { getPreviewUrl } from '../../../../services/PreviewService';
import './ImageFileMessageEntry.css';
import '../../../../styles/chevron.css';

const messages = defineMessages({
  previewError: {
    id: 'chat.messageHistory.imageFileMessageEntry.previewError',
    defaultMessage: 'Unable to preview file'
  },
  toggle: {
    id: 'chat.messageHistory.imageFileMessageEntry.toggle',
    defaultMessage: 'Toggle preview visibility'
  },
  download: {
    id: 'chat.messageHistory.imageFileMessageEntry.download',
    defaultMessage: 'Download file'
  },
  preview: {
    id: 'chat.messageHistory.imageFileMessageEntry.preview',
    defaultMessage: 'Preview file'
  }
});

const previewHeight = 200;

interface Props {
  message: FilesMessage;
  onPreviewClick(message: FilesMessage): void;
  isDownloadEnabled: boolean;
  disabledTooltip: string;
}

interface State {
  isCollapsed: boolean;
  imageError: boolean;
}

interface ClickWrapperProps {
  onClick?: () => void;
}

const ExpandButtonWrapper: React.FC<ClickWrapperProps> = ({ children, onClick }) => {
  const { formatMessage: f } = useIntl();
  return (
    <button
      data-testid="expand-icon"
      className="btn-icon remove-button"
      onClick={onClick}
      aria-label={f(messages.toggle)}
    >
      {children}
    </button>
  );
};

const DownloadLinkWrapper: React.FC = ({ children, ...props }) => {
  const { formatMessage: f } = useIntl();
  return (
    <a data-testid="download-link" className="download-link" {...props} aria-label={f(messages.download)}>
      {children}
    </a>
  );
};

const PreviewImageButtonWrapper: React.FC<ClickWrapperProps> = ({ children, onClick }) => {
  const { formatMessage: f } = useIntl();
  return (
    <button aria-label={f(messages.preview)} onClick={onClick} className="preview-button">
      {children}
    </button>
  );
};

export class ImageFileMessageEntry extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isCollapsed: false,
      imageError: false
    };
  }

  onPreviewError = () => {
    this.setState({ imageError: true });
  };

  onPreviewClick = () => {
    if (this.props.isDownloadEnabled) {
      this.props.onPreviewClick(this.props.message);
    }
  };

  onChevronClick = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    });
  };

  render() {
    const file: File = this.props.message.files[0];

    let downloadLinkProperties = {};
    let downloadIconProperties = {};
    if (this.props.isDownloadEnabled) {
      downloadLinkProperties = {
        href: file.href,
        target: '_blank',
        rel: 'noopener noreferrer'
      };
    } else {
      downloadIconProperties = {
        'data-offset': '{"top": -5 }',
        'data-tip': isHoverSupported ? this.props.disabledTooltip : null,
        'data-place': 'top',
        'data-multiline': 'true'
      };
    }

    return (
      <div className={classNames('image-file-message', { disabled: !this.props.isDownloadEnabled })}>
        <div className="header">
          <span className="description">
            <div className="file-name">{file.name}</div>
            <div className="file-size">{bytes(file.size)}</div>
          </span>
          <span className="controls">
            <ExpandButtonWrapper onClick={this.onChevronClick}>
              <i className={classNames('chevron', { down: this.state.isCollapsed })} />
            </ExpandButtonWrapper>
            <DownloadLinkWrapper {...downloadLinkProperties}>
              <span className="download-icon">
                <i className="togo-icon togo-icon-download" {...downloadIconProperties} />
              </span>
            </DownloadLinkWrapper>
          </span>
        </div>
        <div className={classNames('image-preview', { collapsed: this.state.isCollapsed })}>
          {!this.state.imageError ? (
            <PreviewImageButtonWrapper onClick={this.onPreviewClick}>
              <img
                className="image"
                style={{ maxHeight: `${previewHeight}px` }}
                src={getPreviewUrl(file.href, previewHeight)}
                alt=""
                onError={this.onPreviewError}
                data-testid="image-file-message"
              />
            </PreviewImageButtonWrapper>
          ) : (
            <div className="image-preview-error">
              <div className="file-icon">
                <FileIcon type={file.contentType} />
              </div>
              <div className="error-message">
                <FormattedMessage {...messages.previewError} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
