import React, { useState } from 'react';
import { Admin } from '../../../../types/pulse-web';
import { buildAdminInfo } from '../../../../lib/person';
import styled from '../../../styled-components';
import { defineMessages, useIntl } from 'react-intl';
import { LinkButton } from '../../Common/Toolkit/Button/LinkButton';

const messages = defineMessages({
  showAll: {
    id: 'about.adminList.showAll',
    defaultMessage: '[Show all]'
  },
  more: {
    id: 'about.adminList.more',
    defaultMessage: '...and {number} more'
  }
});

const maxNumberOfAdmins = 10;

const sortAdminList = (admins: Admin[]) => {
  return admins.sort((a, b) => a.firstName.localeCompare(b.firstName));
};

const ListItem = styled.li<{ isCollapsed: boolean; length: number }>`
  &:nth-child(n + ${maxNumberOfAdmins + 1}) {
    display: ${(props) => (props.isCollapsed ? 'none' : 'list-item')};
  }
  &:last-child {
    display: ${(props) => (props.isCollapsed && props.length > maxNumberOfAdmins ? 'list-item' : 'none')};
  }
`;

const List = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
`;

interface Props {
  admins: Admin[];
}

const AdminList: React.FC<Props> = (props) => {
  const { admins } = props;
  const { formatMessage: f } = useIntl();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleShowAllClick = () => {
    setIsCollapsed(false);
  };

  if (admins.length <= 1) {
    return <>{buildAdminInfo(admins[0])}</>;
  }

  return (
    <List>
      {sortAdminList(admins).map((admin) => (
        <ListItem key={admin.email} isCollapsed={isCollapsed} length={admins.length}>
          {buildAdminInfo(admin)}
        </ListItem>
      ))}
      <ListItem isCollapsed={isCollapsed} length={admins.length}>
        {`${f(messages.more, { number: admins.length - maxNumberOfAdmins })} `}
        <LinkButton onClick={handleShowAllClick}>{f(messages.showAll)}</LinkButton>
      </ListItem>
    </List>
  );
};

export default AdminList;
