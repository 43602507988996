import { connect } from 'react-redux';
import { fetchMyMeetings, selectMeeting } from '../view-model/modules/meetings/meetingsActions';
import { UpcomingMeetings } from '../components/Meetings/UpcomingMeetings/UpcomingMeetings';
import errorActions from '../view-model/modules/core/errors/errorActions';
import { getIsOnline, getScheduledMeetings } from '../view-model/selectors';
import { Dispatch, State } from '../view-model/types';
import { SelectableItemType } from '../view-model/modules/meetings/MeetingsTypes';

const mapStateToProps = (state: State) => {
  return {
    user: state.core.user,
    isOnline: getIsOnline(state),
    isFetching: state.meetings.isFetching,
    upcomingMeetings: getScheduledMeetings(state),
    selectedId: state.meetings.selectedId,
    selectedItemType: state.meetings.selectedItemType
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchMyMeetings() {
      dispatch(errorActions.clearMeetingError());
      dispatch(fetchMyMeetings());
    },

    selectMeeting(itemType: SelectableItemType, id?: string) {
      dispatch(selectMeeting(itemType, id));
    }
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(UpcomingMeetings);

export default Container;
