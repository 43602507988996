import * as React from 'react';
import config from 'config';
import { formatId } from '@getgo/format-meeting-id';
import styled from '../../../styled-components';
import { FormattedMessage, defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { Invitation } from '../../../view-model/modules/meetings/meetingsReducer';
import { MeetingDetailsHeader } from '../MeetingDetailsHeader';
import {
  CopyButton,
  DeleteButton,
  EditButton,
  EndButton,
  JoinButton,
  MeetingsTabSpinner,
  PersonalizeButton,
  StartButton
} from '../MeetingComponents';
import { ButtonContainer, ButtonsRow, Description, ButtonWrapper } from './MeetingDetailsWrapper';
import { User } from '../../../view-model/modules/core/user/userReducer';
import { OrganizerList } from './OrganizerList';
import { formatCoorganizersNames, getFullName } from '../../../../lib/person';
import { CreateMeetingResponse } from '../../../../models/Meeting';
import FeatureService from '../../../services/FeatureService';
import device from '../../Common/Toolkit/devices';
import { MediaQueryFeatureSelector } from '../../Common/MediaQueryFeatureSelector';
import { DiagnosticsButton } from '../DiagnosticsButton';
import { InvitationSelector, InvitationTypes } from './InvitationSelector';
import {
  isScheduledMeeting,
  Meeting,
  Profile,
  SelectableItemType
} from '../../../view-model/modules/meetings/MeetingsTypes';

const InvitationBlock = (props: { className?: string; invitation: Invitation | null }) => {
  const invitationHtml = props.invitation?.invitationHtml || '';
  const shouldShowSpinner = !props.invitation || props.invitation.isFetching;
  const hasError = !!props.invitation?.hasError;
  const shouldShow = props.invitation && !hasError && invitationHtml;
  return (
    <div className={props.className}>
      {shouldShowSpinner && <MeetingsTabSpinner />}
      {hasError && <FormattedMessage {...messages.errorFetching} />}
      {shouldShow && <div dangerouslySetInnerHTML={{ __html: invitationHtml }} />}
    </div>
  );
};

const messages = defineMessages({
  errorFetching: {
    id: 'meetingHub.meetingDetails.errorFetching',
    defaultMessage: 'Error while fetching description'
  }
});

interface Props {
  meeting: Meeting;
  profile?: Profile;
  user: User;
  pstnAllowed?: boolean;
  selectedItemType: SelectableItemType;
  invitation: Invitation | null;
  startMeeting: () => Promise<void | CreateMeetingResponse>;
  copyInvitation: () => Promise<{ copied: boolean; error: boolean }>;
  showEditMeeting: (meetingId: string) => void;
  changeInvitation: (meetingId: string, invitation: string) => void;
  showDeleteMeeting: (meetingId: string) => void;
  showPersonalizeMeeting: () => void;
  hidePersonalizeMeeting: () => void;
  endMeeting: (meeting: Meeting) => void;
}

const MeetingDetails: React.FC<Props & WrappedComponentProps> = (props) => {
  let header = null;
  if (props.selectedItemType === SelectableItemType.SCHEDULED && isScheduledMeeting(props.meeting)) {
    header = (
      <MeetingDetailsHeader
        subject={props.meeting.subject}
        subtitle={`ID: ${formatId(props.meeting.meetingId)}`}
        beginDate={new Date(props.meeting.scheduledStartTime)}
        endDate={new Date(props.meeting.scheduledEndTime)}
      />
    );
  } else {
    header = (
      <MeetingDetailsHeader subject={props.meeting.subject} subtitle={`ID: ${formatId(props.meeting.meetingId)}`} />
    );
  }

  const startMeeting = () => {
    props.startMeeting().catch(() => {}); // errors should already be caught in the actions
  };

  const StyledSecondButtonsRow = styled(ButtonsRow)`
    flex-grow: 1;
  `;

  const StyledMarginRightWrapper = styled.div`
    margin: 0 0 0 auto;

    @media ${device.tablet} {
      margin: 0 auto 0 0;
    }
  `;

  return (
    <>
      {header}
      <ButtonContainer>
        <ButtonsRow>
          <ButtonWrapper>
            {props.meeting.inSession ? <JoinButton onClick={startMeeting} /> : <StartButton onClick={startMeeting} />}
          </ButtonWrapper>
        </ButtonsRow>
        <ButtonsRow>
          <ButtonWrapper>
            <CopyButton onClick={props.copyInvitation} />
          </ButtonWrapper>
          {props.meeting.isOrganizer && !props.meeting.inSession && (
            <ButtonWrapper>
              <EditButton onClick={() => props.showEditMeeting(props.meeting.meetingId)} />
            </ButtonWrapper>
          )}
          {props.selectedItemType === SelectableItemType.PERSONAL && props.profile && (
            <ButtonWrapper>
              <PersonalizeButton
                onClick={() => {
                  if (props.profile) {
                    props.showPersonalizeMeeting();
                  }
                }}
              />
            </ButtonWrapper>
          )}
          {props.selectedItemType !== SelectableItemType.PERSONAL &&
            props.meeting.isOrganizer &&
            !props.meeting.inSession && (
              <ButtonWrapper>
                <DeleteButton onClick={() => props.showDeleteMeeting(props.meeting.meetingId)} />
              </ButtonWrapper>
            )}
          {props.meeting.inSession && (
            <ButtonWrapper>
              <EndButton onClick={() => props.endMeeting(props.meeting)} />
            </ButtonWrapper>
          )}
        </ButtonsRow>
        {FeatureService.isEnabled('show-live-diagnostics-button') &&
          props.meeting.inSession &&
          props.meeting.screenSharing?.sessionId && (
            <MediaQueryFeatureSelector
              query={device.mobile}
              queryNotMatched={
                <StyledMarginRightWrapper>
                  <StyledSecondButtonsRow>
                    <DiagnosticsButton
                      targetUrl={`${config.externalLinks.meetingDiagnostics}/#/sessions/${props.meeting.screenSharing?.sessionId}`}
                      isLive={true}
                    />
                  </StyledSecondButtonsRow>
                </StyledMarginRightWrapper>
              }
            />
          )}
        {props.invitation && props.pstnAllowed && (
          <InvitationSelector
            isFetching={props.invitation?.isFetching}
            onChange={(invitation) => props.changeInvitation(props.meeting.meetingId, invitation)}
            invitationType={props.meeting.invitationType || InvitationTypes.DEFAULT}
          />
        )}
      </ButtonContainer>
      <Description hideOnMobile={true}>
        <OrganizerList
          organizer={getFullName(props.meeting.organizer)}
          coorganizers={formatCoorganizersNames(props.meeting.coorganizers)}
        />
        <InvitationBlock invitation={props.invitation} />
      </Description>
    </>
  );
};

export default injectIntl(MeetingDetails);
