import * as React from 'react';
import Select, { components } from 'react-select';
import { defineMessages, IntlShape } from 'react-intl';
import { Contact, ContactSuggestion, CoreContact } from '../../../../types/pulse-web';
import styled from '../../../styled-components';
import { KeyNames } from '../../../../lib/keycodes';

const MultivalueLabel = styled.div`
  padding: 2px 5px;
  font-size: 12px;
`;

const SelectOption = styled.div``;

const StyledSelect = styled(Select)`
  .react-select__value-container {
    padding: 2px 4px;
  }

  .react-select__menu {
    margin-top: 0;
    border: 1px solid #ccc;
    border-top-color: #e6e6e6;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  .react-select__control {
    margin-bottom: 1em;
  }

  .react-select__control,
  .react-select__control.react-select__control--is-focused {
    border: 2px solid #ccc;
    box-shadow: none;
    border-radius: 2px;
  }

  .react-select__option.react-select__option--is-focused {
    color: #333;
    background-color: #caced3;
  }

  .react-select__option--is-disabled {
    cursor: not-allowed;
  }

  .react-select__multi-value {
    border-color: #342e41;
    background-color: #342e41;
    color: #fff;
  }
  .react-select__multi-value__remove {
    cursor: pointer;

    &:hover {
      background-color: #18151e;
      color: #fff;
    }
  }
`;

const messages = defineMessages({
  placeholder: {
    id: 'common.multiContactSelector.multiSelectSuggestionsList.placeholder',
    defaultMessage: 'Search by name or email'
  },
  emptySearch: {
    id: 'common.multiContactSelector.multiSelectSuggestionsList.emptySearchResult',
    defaultMessage: 'No one found matching "{query}"'
  }
});

interface Props {
  autoFocus?: boolean;
  intl: IntlShape;
  isLoading?: boolean;
  listLabel: (contact: Contact) => string;
  listValue: (input: { jid: string }) => string;
  onInputChange: (input: string) => void;
  onMenuScrollToBottom?: () => void;
  onSelectValue: (input: Contact[]) => void;
  renderSelectedSuggestion?: (input: string) => string;
  renderSuggestion?: (contact: CoreContact) => React.ReactElement<any> | null;
  suggestions: CoreContact[];
  onBlurResetsInput?: boolean;
  onCancel?: () => void;
}

interface State {
  query: string;
  selectedSuggestions: ContactSuggestion[];
}
export class MultiSelectSuggestionsList extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: '',
      selectedSuggestions: []
    };
  }

  handleInputChange = (input: string): string => {
    this.setState({
      query: input
    });
    this.props.onInputChange(input);
    return input;
  };

  handleSelectValue = (selectedSuggestions: any) => {
    this.setState({
      query: '',
      selectedSuggestions
    });
    this.props.onSelectValue(this.getValuesFromMap(selectedSuggestions));
  };

  getValuesFromMap(suggestions: any) {
    if (!(suggestions && suggestions instanceof Array)) {
      return [];
    }

    return suggestions.map((suggestion) => {
      return suggestion.value;
    });
  }

  getMapFromValues(suggestions: any, formatLabel: any, formatValue: any) {
    if (!(suggestions && suggestions instanceof Array)) {
      return [];
    }

    return suggestions.map((suggestion) => {
      return {
        label: formatLabel(suggestion),
        suggestion,
        value: formatValue(suggestion),
        isDisabled: !!suggestion.isDisabled
      };
    });
  }

  setInputCursorPositionByKeyCode = (keyCode: number, input: HTMLInputElement) => {
    const pos = keyCode === 36 ? 0 : input.value.length;
    input.setSelectionRange(pos, pos);
  };

  /*
   event.preventDefault prevents react-select's custom implementation and bubbled up default implementation.
   setInputCursorPositionByKeyCode mimics bubbled up default implementation for home and end key.
   Todo: update this code once react-select integrates better way to prevent their custom implementation on keydown
   */
  onKeyDown = (event: any) => {
    const { onCancel = () => {} } = this.props;
    switch (event.key) {
      case KeyNames.END:
      case KeyNames.HOME:
        this.setInputCursorPositionByKeyCode(event.keyCode, event.target);
        event.preventDefault();
        break;
      case KeyNames.ESCAPE:
        if (this.state.query === '') {
          onCancel();
        }
        break;
    }
  };

  renderOption = (props: any) => {
    const option = props.data;
    const optionProps = {
      ...props,
      innerProps: {
        ...props.innerProps,
        'data-testid': 'select-option'
      }
    };

    if (!this.props.renderSuggestion) {
      return <components.Option {...optionProps} />;
    }

    return (
      <SelectOption>
        <components.Option {...optionProps}>
          {this.props.renderSuggestion(option.suggestion) || option.label}
        </components.Option>
      </SelectOption>
    );
  };

  renderSelectedOption = (props: any) => {
    const option = props.data;
    if (!this.props.renderSelectedSuggestion) {
      return <components.MultiValueLabel {...props} />;
    }
    return <MultivalueLabel>{this.props.renderSelectedSuggestion(option.suggestion) || option.label}</MultivalueLabel>;
  };

  renderSelectControl = (props: any) => {
    const innerProps = {
      ...props,
      innerProps: {
        ...props.innerProps,
        'data-testid': 'multi-select-suggestion-list'
      }
    };

    return <components.Control {...innerProps} />;
  };

  render() {
    const { onMenuScrollToBottom = () => {}, isLoading = false, onBlurResetsInput = false } = { ...this.props };

    const { formatMessage } = this.props.intl;
    const suggestionsList = this.getMapFromValues(this.props.suggestions, this.props.listLabel, this.props.listValue);
    const noSearchResultsFound =
      this.state.query.trim().length > 0 && !this.props.isLoading
        ? formatMessage(messages.emptySearch, { query: this.state.query })
        : '';

    return (
      <div className="multi-contact-selector">
        <StyledSelect
          autoFocus={this.props.autoFocus}
          isMulti
          isClearable={false}
          options={suggestionsList}
          value={this.state.selectedSuggestions}
          onChange={this.handleSelectValue}
          onInputChange={this.handleInputChange}
          isLoading={isLoading}
          onMenuScrollToBottom={() => onMenuScrollToBottom()}
          placeholder={formatMessage(messages.placeholder)}
          noResultsText={noSearchResultsFound}
          components={{
            MultiValueLabel: this.renderSelectedOption,
            Option: this.renderOption,
            DropdownIndicator: null,
            Control: this.renderSelectControl
          }}
          optionRenderer={this.renderOption}
          onKeyDown={this.onKeyDown}
          onBlurResetsInput={onBlurResetsInput}
          classNamePrefix="react-select"
        />
      </div>
    );
  }
}
