import { MessageActions, Selectors } from '@getgo/caps-redux';
import { ActionsUnion, Thunk, createAction } from '../../../type-helpers';

export enum ActionTypes {
  APP_PAGE_VISIBILITY_CHANGE = 'APP_PAGE_VISIBILITY_CHANGE',
  APP_LOAD = 'APP_LOAD',
  APP_LOADED = 'APP_LOADED',
  APP_UNLOADED = 'APP_UNLOADED',
  SET_ONLINE_STATUS = 'SET_ONLINE_STATUS',
  SET_TODAY_DATE = 'SET_TODAY_DATE',
  APP_UPDATE_APPLY = 'APP_UPDATE_APPLY',
  APP_UPDATE_READY = 'APP_UPDATE_READY',
  APP_UPDATE_FEATURES = 'APP_UPDATE_FEATURES'
}

const actions = {
  appPageVisibilityChange: (visibility: boolean) => createAction(ActionTypes.APP_PAGE_VISIBILITY_CHANGE, visibility),
  appLoad: () => createAction(ActionTypes.APP_LOAD),
  appLoaded: () => createAction(ActionTypes.APP_LOADED),
  appUnloaded: (loaded: number, uptime: number) => createAction(ActionTypes.APP_UNLOADED, { loaded, uptime }),
  setOnlineStatus: (onlineStatus: boolean) => createAction(ActionTypes.SET_ONLINE_STATUS, onlineStatus),
  setTodayDate: (date: Date) => createAction(ActionTypes.SET_TODAY_DATE, date),
  updateApply: () => {
    window.location.reload();
    return createAction(ActionTypes.APP_UPDATE_APPLY);
  },
  updateReady: () => createAction(ActionTypes.APP_UPDATE_READY),
  updateFeatures: (features: string[]) => createAction(ActionTypes.APP_UPDATE_FEATURES, features)
};

export default actions;

export type Actions = ActionsUnion<typeof actions>;

export const changePageVisibility = (visible: boolean): Thunk => {
  return (dispatch, getState) => {
    dispatch(actions.appPageVisibilityChange(visible));

    const isChatVisible = visible && getState().core.app.isChatVisible;
    if (isChatVisible) {
      const selectedContact = Selectors.getSelectedContact(getState());
      if (selectedContact?.jid) {
        dispatch(MessageActions.markMessagesAsRead(selectedContact.jid));
      }
    }
  };
};

export const unload = (): Thunk => {
  return (dispatch, getState) => {
    const { loaded } = getState().core.app;
    dispatch(actions.appUnloaded(loaded, Math.floor((Date.now() - loaded) / 1000 / 60)));
  };
};
