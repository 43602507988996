import config from 'config';
import * as React from 'react';
import { defineMessages, FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import Select, { components } from 'react-select';
import { CSSProperties } from 'styled-components';
import themeBlueImg from '../../../../assets/images/meeting-scheduler/theme/main.blue.png';
import themeCustomImg from '../../../../assets/images/meeting-scheduler/theme/main.custom.png';
import themeDefaultImg from '../../../../assets/images/meeting-scheduler/theme/main.default.png';
import themePurpleImg from '../../../../assets/images/meeting-scheduler/theme/main.purple.png';
import themeYellowImg from '../../../../assets/images/meeting-scheduler/theme/main.yellow.png';
import { BrandTheme } from '../../../../models/Meeting';
import styled from '../../../styled-components';
import { Box } from '../../Common/Toolkit/Box/Box';
import { SecondaryButton } from '../../Common/Toolkit/Button/SecondaryButton';
import device from '../../Common/Toolkit/devices';
import { InputFieldset } from '../../Common/Toolkit/Input/InputFieldset';
import { styledSelectMenu, styleSelect } from '../../Common/Toolkit/Input/SelectStyles';

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  color: string;
}

interface SelectorThemeGroup {
  label: string;
  value: string;
  options: Array<SelectorThemeItem>;
}

interface SelectorThemeItem {
  label: string;
  value: string;
}

enum ThemeAvailabilityLevel {
  GLOBAL = 'GLOBAL',
  ACCOUNT = 'ACCOUNT',
  USER = 'USER'
}

enum PublishMode {
  DEFAULT = 'DEFAULT',
  AVAILABLE = 'AVAILABLE'
}

const themes = [
  {
    name: 'green',
    color: '#2CC06B'
  },
  {
    name: 'blue',
    color: '#379EDA'
  },
  {
    name: 'purple',
    color: '#915393'
  },
  {
    name: 'yellow',
    color: '#F3A639'
  }
];

const StyledSelect = styleSelect(Select);
const Menu = styledSelectMenu(components.Menu);
const selectGroupStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
};
const selectGroupIconStyle: CSSProperties = {
  marginRight: '.8em'
};
const selectGroupLabelStyle: CSSProperties = {
  fontSize: '1.2em'
};
const selectGroupBadgeStyle: CSSProperties = {
  backgroundColor: '#ebecf0',
  borderRadius: '2em',
  color: '#172b4d',
  display: 'inline-block',
  fontSize: '1em',
  lineHeight: '1em',
  minWidth: '1px',
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center'
};

const availLevelIconMapping = (level: string) => {
  const prefix = 'togo-icon togo-icon-';
  switch (level) {
    case ThemeAvailabilityLevel.USER:
      return [prefix, 'person-filled'].join('');
    case ThemeAvailabilityLevel.ACCOUNT:
      return [prefix, 'business-reverse'].join('');
    case ThemeAvailabilityLevel.GLOBAL:
      return [prefix, 'globe'].join('');
  }
  return [prefix, 'gotomeeting'].join('');
};

const availLevelGroupLabelMapping = (level: string) => {
  switch (level) {
    case ThemeAvailabilityLevel.USER:
      return messages.availabilityLevelGroupUser;
    case ThemeAvailabilityLevel.ACCOUNT:
      return messages.availabilityLevelGroupAccount;
    case ThemeAvailabilityLevel.GLOBAL:
      return messages.availabilityLevelGroupGlobal;
  }
  return messages.availabilityLevelGroupUser;
};

const formattedSelectGroupLabel = (data: SelectorThemeGroup) => (
  <div style={selectGroupStyle}>
    <span style={selectGroupLabelStyle}>
      <i style={selectGroupIconStyle} className={availLevelIconMapping(data.value)} aria-hidden={true} />
      {data.label}
    </span>
    <span style={selectGroupBadgeStyle}>{data.options.length}</span>
  </div>
);

const LabelRadio = ({ className, color, ...props }: RadioProps) => (
  <label className={className}>
    <input data-testid="theme-selector-radio" type="radio" {...props} />
    <span style={{ backgroundColor: color }} />
  </label>
);

const ThemeRadio = styled(LabelRadio)`
  & > input {
    display: none;
  }
  & > input ~ span {
    display: block;
    width: 30px;
    height: 30px;
    background-color: #efefef;
    position: relative;
    cursor: pointer;
  }
  & > input:checked ~ span::before {
    content: '';
    transform: rotate(-45deg);
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 6px;
    top: 6px;
    width: 16px;
    height: 8px;
    display: block;
    position: absolute;
  }

  &:last-of-type {
    margin-right: 20px;
  }

  @media ${device.mobile} {
    &:first-of-type {
      margin-left: auto;
    }
    &:last-of-type {
      margin-right: auto;
    }
  }
`;

const BoxContainer = styled(Box)<{ disabled: boolean }>`
  margin-top: 20px;

  ${(props) =>
    props.disabled
      ? `
    pointer-events: none;
    opacity: 0.5;
  `
      : ``}

  ${InputFieldset} {
    width: 100%;
  }

  & > div {
    box-sizing: border-box;
  }

  @media ${device.mobile} {
    text-align: center;
  }
`;

const ThemeImg = styled.img`
  width: 100%;
  height: auto;
  max-width: 200px;
`;

const BoxLeft = styled.div`
  width: 40%;

  @media ${device.mobile} {
    width: 100%;
    padding-left: 25px;
  }
`;

const BrandTitle = styled.span`
  @media ${device.mobile} {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const CustomBrandTitle = styled(BrandTitle)`
  font-weight: 700;
  margin-bottom: 10px;
`;

const BoxRight = styled(Box)`
  width: 60%;
  padding-left: 20px;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const PreviewWrapper = styled.div`
  @media ${device.mobile} {
    flex-basis: 100%;
  }
`;

const PreviewButton = styled(SecondaryButton)`
  font-weight: bold;
  font-size: 12px;
  padding: 0 16px;
  height: 32px;
  line-height: 30px;

  @media ${device.mobile} {
    margin: 10px auto;
  }
`;

const CustomPreviewButton = styled(PreviewButton)`
  margin-left: 10px;
  margin-top: 8px;
  height: 44px;
`;

const ThemeBox = styled(Box)`
  margin-bottom: 10px;
  align-items: center;
`;

const ProfileLink = styled.a`
  &:link,
  &:visited {
    color: #006aff;
  }
  &:visited:hover,
  &:hover {
    color: #004fbd;
  }
`;

const ThemeRadioWrapper = styled.div`
  display: flex;
`;

const messages = defineMessages({
  theme: {
    id: 'meetingScheduler.theme',
    defaultMessage: 'Theme'
  },
  customBrand: {
    id: 'meetingScheduler.customBrand',
    defaultMessage: "You're using a custom branding"
  },
  preview: {
    id: 'meetingScheduler.preview',
    defaultMessage: 'Preview'
  },
  description: {
    id: 'meetingScheduler.description',
    defaultMessage:
      'You can add and change personal information via your {userProfile}, e.g. your job title or a link to your LinkedIn profile.'
  },
  userProfile: {
    id: 'meetingScheduler.userProfile',
    defaultMessage: 'user profile'
  },
  publishModeGroupDefault: {
    id: 'meetingScheduler.defaultTheme',
    defaultMessage: 'Default theme'
  },
  availabilityLevelGroupGlobal: {
    id: 'meetingScheduler.globalThemes',
    defaultMessage: 'Global themes'
  },
  availabilityLevelGroupAccount: {
    id: 'meetingScheduler.accountThemes',
    defaultMessage: 'Account wide themes'
  },
  availabilityLevelGroupUser: {
    id: 'meetingScheduler.userThemes',
    defaultMessage: 'Your themes'
  }
});

const renderMenu = (props: any) => <Menu {...props} />;

const defaultThemeId: string = 'green';

interface Props {
  theme: string;
  themes: BrandTheme[];
  onChange: (theme: string) => void;
  showProfilePreview: (theme: string) => void;
  disabled?: boolean;
  useNewSelector: boolean;
}

class ThemeSelector extends React.Component<Props & WrappedComponentProps> {
  private onChangeTheme = (theme: string) => {
    this.props.onChange(theme);
  };

  private currentThemeMapping: SelectorThemeItem | undefined = undefined;

  get isCustomTheme() {
    return this.theme !== 'default' && themes.findIndex((theme) => theme.name === this.theme) === -1;
  }

  get theme() {
    const currentTheme = this.props.theme;
    return ThemeSelector.themeOrDefault(currentTheme);
  }

  private static themeOrDefault(currentTheme: string) {
    if (currentTheme === 'orange') {
      return defaultThemeId;
    }
    return currentTheme;
  }

  get themeImg() {
    switch (this.theme) {
      case 'default':
        return themeDefaultImg;
      case 'yellow':
        return themeYellowImg;
      case 'purple':
        return themePurpleImg;
      case 'blue':
        return themeBlueImg;
      case 'green':
        return themeDefaultImg;
    }

    return themeCustomImg;
  }

  get groupedThemesMapping() {
    const selectedThemeId = this.selectedThemeId;
    return this.props.themes.reduce((result: SelectorThemeGroup[], theme: BrandTheme) => {
      const groupBy = theme.availabilityLevel;

      const { formatMessage } = this.props.intl;
      const groupFinder = (group: SelectorThemeGroup): boolean => group.value === groupBy;
      const themeOrDefault = ThemeSelector.themeOrDefault(theme.themeId);
      const themeMapper = (theme: BrandTheme): SelectorThemeItem => ({ value: theme.themeId, label: theme.title });

      if (ThemeSelector.themeOrDefault(selectedThemeId) === themeOrDefault) {
        this.currentThemeMapping = themeMapper(theme);
      }

      if (theme.publishMode === PublishMode.DEFAULT) {
        const group: SelectorThemeGroup = {
          label: formatMessage(messages.publishModeGroupDefault),
          value: 'DEFAULT',
          options: [themeMapper(theme)]
        };
        result.unshift(group);
      } else {
        const existingGroup = result.find(groupFinder);
        if (!existingGroup) {
          const group: SelectorThemeGroup = {
            label: formatMessage(availLevelGroupLabelMapping(groupBy)),
            value: groupBy,
            options: [themeMapper(theme)]
          };
          result.push(group);
        } else {
          existingGroup.options.push(themeMapper(theme));
          existingGroup.options.sort((a: SelectorThemeItem, b: SelectorThemeItem) => {
            return a.label.localeCompare(b.label);
          });
        }
      }
      return result;
    }, []);
  }

  private get selectedThemeId(): string {
    let selectedThemeId = defaultThemeId;
    for (const theme of this.props.themes) {
      if (this.theme === ThemeSelector.themeOrDefault(theme.themeId)) {
        selectedThemeId = theme.themeId;
      }
      if (this.theme === 'default' && theme.publishMode === PublishMode.DEFAULT) {
        return theme.themeId;
      }
    }
    return selectedThemeId;
  }

  private useNewSelector(): boolean {
    return (
      typeof this.props.themes !== 'undefined' &&
      (this.props.useNewSelector ||
        this.props.themes.some((theme) => theme.availabilityLevel === ThemeAvailabilityLevel.ACCOUNT))
    );
  }

  render() {
    const description = (
      <FormattedMessage
        {...messages.description}
        values={{
          userProfile: (
            <ProfileLink href={config.externalLinks.profileInfo} target="_blank" rel="noopener noreferrer">
              <FormattedMessage {...messages.userProfile} />
            </ProfileLink>
          )
        }}
      />
    );

    if (this.useNewSelector()) {
      return (
        <Box flexDirection="column">
          <BoxContainer disabled={!!this.props.disabled}>
            <InputFieldset legend={<FormattedMessage {...messages.theme} />}>
              <StyledSelect
                classNamePrefix="react-select"
                options={this.groupedThemesMapping}
                defaultValue={this.currentThemeMapping}
                isSearchable
                onChange={({ value }: { value: string }) =>
                  this.props.onChange(this.mapToDefaultIfNonGlobalDefaultExistsOrIfGreen(value))
                }
                components={{
                  Menu: renderMenu
                }}
                styles={{
                  menuPortal: (base: Record<string, any>) => ({ ...base, zIndex: 100 })
                }}
                menuPortalTarget={document.getElementById('app')}
                formatGroupLabel={formattedSelectGroupLabel}
              />
            </InputFieldset>
            <CustomPreviewButton
              data-testid="theme-selector-preview-button"
              onClick={() => {
                this.props.showProfilePreview(this.theme);
              }}
            >
              <FormattedMessage {...messages.preview} />
            </CustomPreviewButton>
          </BoxContainer>
          <div>{description}</div>
        </Box>
      );
    }
    console.log('Selected: ' + this.theme);
    return (
      <BoxContainer flexDirection="row" flexWrap="wrap" disabled={!!this.props.disabled}>
        <BoxLeft>
          <ThemeImg src={this.themeImg} alt="" />
        </BoxLeft>
        <BoxRight flexDirection="column">
          {this.isCustomTheme ? (
            <CustomBrandTitle>
              <FormattedMessage {...messages.customBrand} />
            </CustomBrandTitle>
          ) : (
            <BrandTitle>
              <FormattedMessage {...messages.theme} />
            </BrandTitle>
          )}
          <ThemeBox flexDirection="row" flexWrap="wrap">
            {!this.isCustomTheme && (
              <ThemeRadioWrapper>
                {themes.map((theme) => (
                  <ThemeRadio
                    key={theme.name}
                    checked={this.theme === theme.name || (this.theme === 'default' && theme.name === 'green')}
                    color={theme.color}
                    data-theme={theme.name}
                    onChange={() => {
                      this.onChangeTheme(theme.name === defaultThemeId ? 'default' : theme.name);
                    }}
                  />
                ))}
              </ThemeRadioWrapper>
            )}

            <PreviewWrapper>
              <PreviewButton
                data-testid="theme-selector-preview-button"
                onClick={() => {
                  this.props.showProfilePreview(this.theme);
                }}
              >
                <FormattedMessage {...messages.preview} />
              </PreviewButton>
            </PreviewWrapper>
          </ThemeBox>
          <div>{description}</div>
        </BoxRight>
      </BoxContainer>
    );
  }

  private mapToDefaultIfNonGlobalDefaultExistsOrIfGreen(value: string): string {
    let mappedValue = value;
    let existNonGlobalDefault = false;
    for (const theme of this.props.themes) {
      if (theme.themeId === mappedValue && theme.publishMode === PublishMode.DEFAULT) {
        mappedValue = 'default';
      }
      if (theme.publishMode === PublishMode.DEFAULT) {
        existNonGlobalDefault = true;
      }
    }
    if (!existNonGlobalDefault && mappedValue === defaultThemeId) {
      mappedValue = 'default';
    }
    return mappedValue;
  }
}

export default injectIntl(ThemeSelector);
