import * as React from 'react';
import imageMap from './contentTypeToImageMap';
import defaultIcon from './icons/main.default.svg';
import './FileIcon.css';

interface Props {
  type: string;
  style?: React.CSSProperties;
}

export const FileIcon: React.FC<Props> = ({ type, style }) => {
  const icon = imageMap[type] || defaultIcon;

  return <img className="file-icon" src={icon} alt="" style={style || {}} />;
};
