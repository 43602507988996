import React from 'react';
import { FormattedMessage, WrappedComponentProps, defineMessages, injectIntl, MessageDescriptor } from 'react-intl';
import { PreferencesBlock } from '../../Common/Preferences/PreferencesBlock';
import { PreferencesItemBool } from '../../Common/Preferences/PreferencesItemBool';
import { AsyncPreferencesPage } from '../../Common/Preferences/PreferencesPage';
import LogoPreferenceBlock from './LogoPreferenceBlock';
import { ValidationErrors } from '../../../view-model/modules/core/preferences/preferencesReducer';
import './MeetingPreferences.css';
import config from 'config';
import { PreferencesItemText } from '../../Common/Preferences/PreferencesItemText';
import { AudioSettings } from '../AudioSettings/AudioSettings';
import { getDisabledToolTipMessage } from '../../../../lib/disabled-message';
import styled from '../../../styled-components';
import PreferencesItemSelectList from '../../Common/Preferences/PreferencesItemSelectList';

const LearnMoreLink = styled.a`
  color: #309ddc;
  text-decoration: none;
`;

const ShareSubHeader = styled.h3`
  color: #495265;
  margin-bottom: 10px;
`;

const messages = defineMessages({
  header: {
    id: 'meetingPreferences.header',
    defaultMessage: 'Select in meeting experience'
  },
  newLabel: {
    id: 'meetingPreferences.newLabel',
    defaultMessage: 'New experience (experimental)'
  },
  newLabelSecondary: {
    id: 'meetingPreferences.newLabelSecondary',
    defaultMessage: 'Provides a faster, simpler, and more cohesive meeting experience for you and your attendees.'
  },
  audioHeader: {
    id: 'meetingPreferences.audioHeader',
    defaultMessage: 'Audio'
  },
  audioDescription: {
    id: 'meetingPreferences.audioDescription',
    defaultMessage:
      'Select your default audio connection settings. You can customize these settings anytime you schedule a new meeting.'
  },
  joinHeader: {
    id: 'meetingPreferences.joinHeader',
    defaultMessage: 'Attendee experience'
  },
  organizerPIN: {
    id: 'meetingPreferences.organizerPIN',
    defaultMessage: 'Organizer PIN'
  },
  organizerPINDescription: {
    id: 'meetingPreferences.organizerPIN.description',
    defaultMessage: 'For added security, use your own PIN to start meetings on your phone.'
  },
  organizerPINInputPlaceholder: {
    id: 'meetingPreferences.organizerPIN.inputPlaceholder',
    defaultMessage: 'Enter up to 12 digits'
  },
  openPresenterModeLabel: {
    id: 'meetingPreferences.openPresenterModeLabel',
    defaultMessage: 'Give attendees full access to screen sharing'
  },
  openPresenterModeDescription: {
    id: 'meetingPreferences.openPresenterModeDescription',
    defaultMessage:
      'Attendees can make themselves presenter and share their screen without having to ask the organizer.'
  },
  openPresenterModeNote: {
    id: 'meetingPreferences.openPresenterMode.note',
    defaultMessage:
      'Note: When this feature is turned off, screen sharing is unavailable unless an organizer is present.'
  },
  organizerPINOpenPresenterModeNote: {
    id: 'meetingPreferences.organizerPIN.openPresenterMode.note',
    defaultMessage:
      'Tip: give attendees full access to screen sharing (above) so they can share their screen when no organizer is present.'
  },
  forceAttendeeWebLabel: {
    id: 'meetingPreferences.forceAttendeeWebLabel',
    defaultMessage:
      'New Attendees using the Google Chrome™ browser will join your meetings using the GoToMeeting web application.'
  },
  forceAttendeeWebDescription: {
    id: 'meetingPreferences.forceAttendeeWebDescription',
    defaultMessage:
      'No download required! Drawing tools and keyboard and mouse controls will not be available. Attendees that have GoToMeeting already installed will join using the desktop application.'
  },
  forceAttendeeWebVideoLink: {
    id: 'meetingPreferences.forceAttendeeWebVideoLink',
    defaultMessage: 'Watch a Video'
  },
  forceAttendeeWebMoreLink: {
    id: 'meetingPreferences.forceAttendeeWebMoreLink',
    defaultMessage: 'Learn more'
  },
  transcriptionsHeader: {
    id: 'meetingPreferences.transcriptionsHeader',
    defaultMessage: 'Cloud Recordings'
  },
  transcriptionsDescription: {
    id: 'meetingPreferences.transcriptionsDescription',
    defaultMessage:
      'Get a transcript of your recorded meetings. Easily search for keywords, phrases, and speaker names. (Available in English only)'
  },
  transcriptionsLabel: {
    id: 'meetingPreferences.transcriptionsLabel',
    defaultMessage: 'Transcribe recorded meetings'
  },
  shareRecordingContentHeader: {
    id: 'meetingPreferences.shareRecordingContentHeader',
    defaultMessage: 'Content Sharing & Security'
  },
  shareRecordingContentDescription: {
    id: 'meetingPreferences.shareRecordingContentDescription',
    defaultMessage:
      'Select your sharing settings for meeting content such as recordings, transcripts, notes, and meeting info. You can also customize sharing & security settings for specific meetings at any time. {learnMore}'
  },
  shareRecordingContentLabel: {
    id: 'meetingPreferences.shareRecordingContentLabel',
    defaultMessage: 'Enable automatic sharing'
  },
  shareLearnMore: {
    id: 'meetingPreferences.shareRecordingLearnMore',
    defaultMessage: 'Learn more'
  },
  shareRecordingAnyone: {
    id: 'meetingPreferences.shareRecordingAnyone',
    defaultMessage: 'Anyone with the link'
  },
  shareRecordingMyself: {
    id: 'meetingPreferences.shareRecordingMyself',
    defaultMessage: 'Only myself and people I choose'
  },
  shareRecordingWhoCanAccess: {
    id: 'meetingPreferences.shareRecordingWhoCanAccess',
    defaultMessage: 'By default, who can access your meeting content?'
  },
  videoInsightsHeader: {
    id: 'meetingPreferences.videoInsightsHeader',
    defaultMessage: 'Meeting Insights'
  },
  videoInsightsDescription: {
    id: 'meetingPreferences.videoInsightsDescription',
    defaultMessage:
      'Automatically captures slides shared during your meeting and syncs them with your video. Great for navigating recordings & transcripts and downloadable as a PDF.'
  },
  videoInsightsLabel: {
    id: 'meetingPreferences.videoInsightsLabel',
    defaultMessage: 'Detect Slides'
  },
  organizerPINIsMandatoryError: {
    id: 'preferenceActions.errors.organizerPINIsMandatoryError',
    defaultMessage: 'Please create a PIN.'
  },
  organizerPINMinLengthError: {
    id: 'preferenceActions.errors.organizerPINMinLengthError',
    defaultMessage: 'Your PIN needs at least 4 digits.'
  },
  organizerPINFormatError: {
    id: 'preferenceActions.errors.organizerPINFormatError',
    defaultMessage: 'Oops, your PIN may contain numbers only (no letters).'
  },
  shareLinkExpirationHeader: {
    id: 'meetingPreferences.shareLinkExpirationHeader',
    defaultMessage: 'Select your default share link expiration duration'
  },
  oneDay: {
    id: 'meetingPreferences.selectItemList.oneDay',
    defaultMessage: '1 Day'
  },
  sevenDays: {
    id: 'meetingPreferences.selectItemList.sevenDays',
    defaultMessage: '7 Days'
  },
  fifteenDays: {
    id: 'meetingPreferences.selectItemList.fifteenDays',
    defaultMessage: '15 Days'
  },
  oneMonth: {
    id: 'meetingPreferences.selectItemList.oneMonth',
    defaultMessage: '1 Month'
  },
  threeMonths: {
    id: 'meetingPreferences.selectItemList.threeMonths',
    defaultMessage: '3 Months'
  },
  sixMonths: {
    id: 'meetingPreferences.selectItemList.sixMonths',
    defaultMessage: '6 Months'
  },
  oneYear: {
    id: 'meetingPreferences.selectItemList.oneYear',
    defaultMessage: '12 Months'
  },
  shareLink: {
    id: 'meetingPreferences.selectItemList.shareLink',
    defaultMessage: 'Share Link Expiry Duration'
  }
});

const getOrganizerPinError = (validationErrors: string[]): MessageDescriptor | undefined => {
  const pinEmpty: boolean = validationErrors.indexOf(ValidationErrors.CONFERENCE_MODERATOR_PIN_MANDATORY) !== -1;
  if (pinEmpty) {
    return messages.organizerPINIsMandatoryError;
  }

  const wrongFormat: boolean = validationErrors.indexOf(ValidationErrors.CONFERENCE_MODERATOR_PIN_FORMAT) !== -1;
  if (wrongFormat) {
    return messages.organizerPINFormatError;
  }

  const wrongLength: boolean = validationErrors.indexOf(ValidationErrors.CONFERENCE_MODERATOR_PIN_MIN_LENGTH) !== -1;
  if (wrongLength) {
    return messages.organizerPINMinLengthError;
  }

  return undefined;
};

const MeetingPreferences = (props: WrappedComponentProps) => {
  const forceWebDescription = (
    <>
      <FormattedMessage {...messages.forceAttendeeWebDescription} />
      <br />
      <a
        className="force-attendee-web-link"
        target="_blank"
        rel="noopener noreferrer"
        href={config.externalLinks.forceAttendeeWebVideoLink}
      >
        <i className="togo-icon togo-icon-play force-attendee-web-icon" />
        <FormattedMessage {...messages.forceAttendeeWebVideoLink} />
      </a>
      {' | '}
      <a
        className="force-attendee-web-link"
        target="_blank"
        rel="noopener noreferrer"
        href={config.externalLinks.forceAttendeeWebMoreLink}
      >
        <FormattedMessage {...messages.forceAttendeeWebMoreLink} />
      </a>
    </>
  );

  const shareDescription = (
    <>
      <FormattedMessage
        {...messages.shareRecordingContentDescription}
        values={{
          learnMore: (
            <LearnMoreLink href={config.externalLinks.shareSessionHelpLink} target="_blank" rel="noopener noreferrer">
              <FormattedMessage {...messages.shareLearnMore} />
            </LearnMoreLink>
          )
        }}
      />
    </>
  );
  const { formatMessage } = props.intl;

  const expiryDurationOptions = [
    { value: 1, label: formatMessage(messages.oneDay) },
    { value: 7, label: formatMessage(messages.sevenDays) },
    { value: 15, label: formatMessage(messages.fifteenDays) },
    { value: 30, label: formatMessage(messages.oneMonth) },
    { value: 90, label: formatMessage(messages.threeMonths) },
    { value: 180, label: formatMessage(messages.sixMonths) },
    { value: 365, label: formatMessage(messages.oneYear) }
  ];

  return (
    <AsyncPreferencesPage group="meetings">
      {({ settings, onChange, isDisabled, validationErrors }) => {
        const shouldShowVideoInsightsSetting = settings.videoInsightsProvisioned;
        const shouldShowTranscriptSetting = settings.transcriptsProvisioned;
        const shouldShowCbrSetting =
          settings.cbrProvisioned &&
          settings.cbrEnabled &&
          (shouldShowTranscriptSetting || shouldShowVideoInsightsSetting);
        const shouldShowCbrAndSharingSetting = settings.cbrProvisioned && settings.cbrEnabled;

        const shouldShowOrganizerPINSection =
          settings.isStandaloneAudioProvisioned &&
          settings.isStandaloneAudioEntitled &&
          settings.isStandaloneAudioEnabled;

        return (
          <div className="meeting-preferences">
            {shouldShowOrganizerPINSection && (
              <PreferencesBlock
                titleMessage={messages.organizerPIN}
                descriptionMessage={messages.organizerPINDescription}
                learnMoreLink={config.externalLinks.organizerPinLearnMoreLink}
                disabled={isDisabled}
              >
                <PreferencesItemText
                  id="conference-moderator-pin"
                  label={formatMessage(messages.organizerPIN)}
                  value={settings.conferenceModeratorPin}
                  placeholder={formatMessage(messages.organizerPINInputPlaceholder)}
                  maxLength={12}
                  disabled={isDisabled}
                  onChange={(newValue) => onChange('conferenceModeratorPin', newValue.replace(/[^0-9]/g, ''))}
                  errorMessage={getOrganizerPinError(validationErrors)}
                />
              </PreferencesBlock>
            )}
            <PreferencesBlock titleMessage={messages.audioHeader} descriptionMessage={messages.audioDescription}>
              <AudioSettings
                isDisabled={isDisabled}
                audioSettings={settings}
                onChange={onChange}
                preferredCountryEnabled
              />
            </PreferencesBlock>
            <PreferencesBlock titleMessage={messages.joinHeader}>
              <PreferencesItemBool
                id="open-presenter-mode"
                type="checkbox"
                badge="new"
                checked={settings.presenterMode === 'open'}
                onChange={(isOpen) => onChange('presenterMode', isOpen ? 'open' : 'moderated')}
                disabled={isDisabled}
                labelMessage={messages.openPresenterModeLabel}
                descriptionMessage={messages.openPresenterModeDescription}
                noteMessage={settings.presenterMode !== 'open' ? messages.openPresenterModeNote : undefined}
              />
              <PreferencesItemBool
                id="force-attendee-web"
                type="checkbox"
                checked={settings.webViewerDefault}
                onChange={(isDefault) => onChange('webViewerDefault', isDefault)}
                disabled={isDisabled || !settings.webViewerAllowed}
                disabledTooltip={getDisabledToolTipMessage(false, !settings.webViewerAllowed)}
                labelMessage={messages.forceAttendeeWebLabel}
                description={forceWebDescription}
              />
            </PreferencesBlock>

            {shouldShowCbrSetting && (
              <PreferencesBlock titleMessage={messages.transcriptionsHeader}>
                {shouldShowTranscriptSetting && (
                  <PreferencesItemBool
                    id="transcriptions-enabled"
                    type="checkbox"
                    checked={settings.transcriptsEnabled}
                    onChange={(isEnabled) => onChange('transcriptsEnabled', isEnabled)}
                    disabled={isDisabled || settings.transcriptsEntitled === false}
                    disabledTooltip={getDisabledToolTipMessage(
                      false,
                      isDisabled || settings.transcriptsEntitled === false
                    )}
                    labelMessage={messages.transcriptionsLabel}
                    descriptionMessage={messages.transcriptionsDescription}
                  />
                )}
                {shouldShowVideoInsightsSetting && (
                  <PreferencesItemBool
                    id="video-insights-enabled"
                    type="checkbox"
                    checked={settings.videoInsightsEnabled}
                    onChange={(isEnabled) => onChange('videoInsightsEnabled', isEnabled)}
                    disabled={isDisabled || settings.videoInsightsEntitled === false}
                    disabledTooltip={getDisabledToolTipMessage(
                      false,
                      isDisabled || settings.videoInsightsEntitled === false
                    )}
                    labelMessage={messages.videoInsightsLabel}
                    descriptionMessage={messages.videoInsightsDescription}
                  />
                )}
              </PreferencesBlock>
            )}
            {shouldShowCbrAndSharingSetting && (
              <PreferencesBlock titleMessage={messages.shareRecordingContentHeader} description={shareDescription}>
                <ShareSubHeader>
                  <FormattedMessage {...messages.shareRecordingWhoCanAccess} />
                </ShareSubHeader>
                <PreferencesItemBool
                  id="share-container-everyone"
                  type="radio"
                  checked={settings.shareRecordingContentEnabled}
                  disabled={isDisabled}
                  onChange={(isEnabled) => onChange('shareRecordingContentEnabled', isEnabled)}
                  labelMessage={messages.shareRecordingAnyone}
                />

                <PreferencesItemBool
                  id="share-container-myself"
                  type="radio"
                  checked={!settings.shareRecordingContentEnabled}
                  disabled={isDisabled}
                  onChange={(isEnabled) => onChange('shareRecordingContentEnabled', !isEnabled)}
                  labelMessage={messages.shareRecordingMyself}
                />

                <ShareSubHeader>
                  <FormattedMessage {...messages.shareLinkExpirationHeader} />
                </ShareSubHeader>
                <PreferencesItemSelectList
                  id="share-link-expiry"
                  selectedValue={settings.shareRecordingLinkExpirationInDays}
                  selectValueChange={(value) => onChange('shareRecordingLinkExpirationInDays', value.value)}
                  menuPosition={'bottom'}
                  options={expiryDurationOptions}
                  ariaLabel={formatMessage(messages.shareLink)}
                />
              </PreferencesBlock>
            )}
            <LogoPreferenceBlock logo={settings.logo} onChange={onChange} />
          </div>
        );
      }}
    </AsyncPreferencesPage>
  );
};

export default injectIntl(MeetingPreferences);
