import React from 'react';
import { useIntl, MessageDescriptor } from 'react-intl';
import { PreferencesHeader } from './PreferencesHeader';
import styled from '../../../styled-components';
import { themeGet } from '@styled-system/theme-get';

const Block = styled.fieldset<{ disabled?: boolean }>`
  padding: 0;
  border: 0;
  margin: 0 0 32px;
  ${(props) =>
    props.disabled &&
    `
    filter: grayscale(1);
    opacity: 0.5;
  `}
`;

const Description = styled.p`
  margin-top: 5px;
  color: ${themeGet('colors.neutralScale.400')};
`;

interface Props {
  id?: string;
  titleMessage: MessageDescriptor;
  disabled?: boolean;
  description?: React.ReactNode;
  descriptionMessage?: MessageDescriptor;
  learnMoreLink?: string;
  learnMoreLinkLabel?: string;
  learnMoreLinkIcon?: JSX.Element;
  infoMessage?: MessageDescriptor;
  badge?: 'new' | 'beta';
}

export const PreferencesBlock: React.FC<Props> = ({
  id,
  titleMessage,
  description,
  descriptionMessage,
  disabled,
  children,
  learnMoreLink,
  learnMoreLinkLabel,
  learnMoreLinkIcon,
  infoMessage,
  badge
}) => {
  const { formatMessage: f } = useIntl();

  return (
    <Block disabled={disabled}>
      <PreferencesHeader
        id={id}
        titleMessage={titleMessage}
        disabled={disabled}
        learnMoreLink={learnMoreLink}
        infoMessage={infoMessage}
        learnMoreLinkLabel={learnMoreLinkLabel}
        learnMoreLinkIcon={learnMoreLinkIcon}
        badge={badge}
      />
      {descriptionMessage && <Description>{f(descriptionMessage)}</Description>}
      {description && <Description>{description}</Description>}
      {children}
    </Block>
  );
};
