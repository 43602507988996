import React from 'react';
import styled from '../../../styled-components';
import hubLogo from '../../../../assets/images/logo.svg';
import g2mLogo from '../../../../assets/images/logo.svg';
import device from '../Toolkit/devices';

export const HubLogo = styled((props) => {
  const logo = props.g2mLogo ? g2mLogo : hubLogo;
  const altText = props.g2mLogo ? 'GoToMeeting' : 'GoToMeeting Hub';
  return (
    <div className={props.className}>
      <img src={logo} alt={altText} />
    </div>
  );
})`
  flex: 1 1 0%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 32px;
  overflow: hidden;

  img {
    height: 48px;
    padding: 18px 0;
  }

  @media ${device.laptop} {
    padding: 0 24px;
  }

  @media ${device.tablet} {
    padding: 0 16px;
    img {
      height: 40px;
      padding: 14px 0;
    }
  }

  @media ${device.mobile} {
    padding: 0 8px;
    img {
      height: 28px;
      padding: 10px 0;
    }
  }
`;
