import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage, defineMessages, injectIntl, WrappedComponentProps } from 'react-intl';
import { isEqual } from 'lodash';
import { isScrolledIntoViewPosition } from '../../../lib/scrolling';
import './Scroller.css';

const messages = defineMessages({
  scrollerUnread: {
    id: 'common.scroller.unread',
    defaultMessage: 'Unread messages'
  }
});

const defaultState: State = {
  top: false,
  bottom: false,
  shouldUpdate: true,
  topElement: null,
  bottomElement: null
};

interface Props {
  className?: string;
  selector?: string;
  watch?: any;
  children?: any;
}

interface State {
  top: boolean;
  bottom: boolean;
  shouldUpdate: boolean;
  topElement: Element | null;
  bottomElement: Element | null;
}

class Scroller extends React.PureComponent<Props & WrappedComponentProps, State> {
  element?: any;
  elements?: any[];

  constructor(props: Props & WrappedComponentProps) {
    super(props);
    this.state = { ...defaultState };
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkPosition);
    this.element.addEventListener('scroll', this.checkPosition);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      shouldUpdate: !isEqual(this.props.watch, nextProps.watch)
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkPosition);
    this.element.removeEventListener('scroll', this.checkPosition);
  }

  checkPosition = () => {
    const newState = { ...defaultState };

    if (this.elements) {
      for (let i = 0; i < this.elements.length; i++) {
        const position = isScrolledIntoViewPosition(this.element, this.elements[i]);

        if (position === 1) {
          newState.top = true;
          // save first occurrence of unread above current viewport
          if (!newState.topElement) {
            newState.topElement = this.elements[i];
          }
        } else if (position === -1) {
          newState.bottom = true;
          // save last occurrence of unread above current viewport
          newState.bottomElement = this.elements[i];
        }
      }
    }

    this.setState(newState);
  };

  findElements = () => {
    this.elements = this.element.querySelectorAll(this.props.selector);
  };

  scrollIntoView(dir: 'top' | 'bottom') {
    const element = (this.state as any)[`${dir}Element`] as Element | null;
    if (element?.parentElement) {
      element.parentElement.scrollIntoView(dir === 'top');
    }
  }

  updatePostRender = () => {
    window.requestAnimationFrame(() => {
      if (this.element) {
        this.findElements();
        this.checkPosition();
      }
    });
  };

  renderHint(dir: 'top' | 'bottom') {
    if (this.state[dir]) {
      return (
        <div className={`scroll-hint-${dir}`} onClick={() => this.scrollIntoView(dir)}>
          <i className="arrow togo-icon togo-icon-chevron" />
          <FormattedMessage {...messages.scrollerUnread} />
        </div>
      );
    }
    return null;
  }

  render() {
    if (this.state.shouldUpdate) {
      this.updatePostRender();
    }

    return (
      <div className={classNames('scroller', this.props.className)}>
        <div
          className="contents"
          ref={(c) => {
            this.element = c;
          }}
        >
          {this.props.children}
        </div>
        {this.renderHint('top')}
        {this.renderHint('bottom')}
      </div>
    );
  }
}

export default injectIntl(Scroller, { forwardRef: true });
