import { connect } from 'react-redux';
import sharedActions from '../view-model/sharedActions';
import { Dispatch } from '../view-model/types';
import { CalendarSyncPreferences } from '../components/Preferences/CalendarsSyncPreferences/CalendarSyncPreferences';

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    outlookCalendarClick() {
      dispatch(sharedActions.outlookCalendarLinkClicked());
    },
    googleCalendarClick() {
      dispatch(sharedActions.googleCalendarLinkClicked());
    }
  };
};

export default connect(null, mapDispatchToProps)(CalendarSyncPreferences);
