import { WrappedComponentProps, injectIntl } from 'react-intl';
import { CreateMeetingResponse } from '../../../../models/Meeting';
import { MeetingDetailsHeader } from '../MeetingDetailsHeader';
import { messages as oneTimeMeetingMessages } from '../AnytimeMeetings/OneTimeMeetingItem';
import { StartButton } from '../MeetingComponents';
import * as React from 'react';
import { ButtonContainer, ButtonsRow, ButtonWrapper, Description } from './MeetingDetailsWrapper';
import { SelectableItemType } from '../../../view-model/modules/meetings/MeetingsTypes';

interface Props {
  startMeeting: () => Promise<void | CreateMeetingResponse>;
  selectMeeting: (itemType: SelectableItemType, id: string | null) => void;
}

class OneTimeMeetingDetails extends React.Component<Props & WrappedComponentProps> {
  state = {
    isPolling: false
  };

  startMeeting = () => {
    this.setState({
      isPolling: true
    });
    this.props
      .startMeeting()
      .then((meetingResponse: CreateMeetingResponse | void) => {
        this.setState({ isPolling: false });
        if (meetingResponse) {
          this.props.selectMeeting(SelectableItemType.IMPROMPTU, meetingResponse.meetingId);
        }
      })
      .catch(() => {
        // errors should already be caught in the actions
        this.setState({ isPolling: false });
      });
  };

  render() {
    const { formatMessage } = this.props.intl;

    return (
      <>
        <MeetingDetailsHeader
          subject={formatMessage(oneTimeMeetingMessages.title)}
          subtitle={formatMessage(oneTimeMeetingMessages.description)}
        />
        <ButtonContainer>
          <ButtonsRow>
            <ButtonWrapper>
              <StartButton onClick={this.startMeeting} loading={this.state.isPolling} disabled={this.state.isPolling} />
            </ButtonWrapper>
          </ButtonsRow>
        </ButtonContainer>

        <Description mobilePadding={'16px 0 0 0'}>
          {formatMessage(oneTimeMeetingMessages.detailedDescription)}
        </Description>
      </>
    );
  }
}

export default injectIntl(OneTimeMeetingDetails);
