import * as React from 'react';
import styled from '../../../../styled-components';
import { GhostButton } from '../Button/GhostButton';
import { PrimaryButton } from '../Button/PrimaryButton';
import { themeGet } from '@styled-system/theme-get';

const DialogBox = styled.div`
  padding: 20px;
  border-radius: 2px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 3px -2px rgba(0, 0, 0, 0.8);
`;

const DialogBoxButtons = styled.div`
  text-align: right;
`;

const SecondaryButton = styled(GhostButton)`
  color: ${themeGet('colors.primary')};

  &:enabled:hover {
    color: ${themeGet('colors.hover.primary')};
  }
`;

const OkPrimaryButton = styled(PrimaryButton)`
  margin-left: 12px;
`;

interface Props {
  buttonOk: React.ReactChild;
  buttonCancel?: React.ReactChild;

  onOk: () => void;
  onCancel: () => void;
  children?: React.ReactChild;
  className?: string;
}

export const Dialog = ({ buttonOk, buttonCancel, children, onOk, onCancel, ...rest }: Props) => (
  <DialogBox {...rest}>
    {children}

    <DialogBoxButtons>
      {buttonCancel && (
        <SecondaryButton data-testid="button-cancel" onClick={onCancel}>
          {buttonCancel}
        </SecondaryButton>
      )}
      <OkPrimaryButton data-testid="button-ok" onClick={onOk}>
        {buttonOk}
      </OkPrimaryButton>
    </DialogBoxButtons>
  </DialogBox>
);
