import * as React from 'react';
import styled from '../../../../styled-components';
import device from '../../../Common/Toolkit/devices';
import { DisableStateBox } from '../../../Common/Toolkit/Box/DisableStateBox';
import { FormattedNumber } from 'react-intl';

export interface TabChild<T extends string> {
  name: T;
  title: string;
  child: React.ReactChild;
  icon: React.ReactChild;
  disabled?: boolean;
  error?: boolean;
  count?: number;
  plusSymbol?: boolean;
}

const NavElement = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-bottom: 1px solid #9aa8bb;
  margin: 0;
  width: 100%;
  padding: 4px 4px 0;
  box-sizing: border-box;
  flex-shrink: 0;
  @media ${device.mobile} {
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

const NavBody = DisableStateBox;

const LinkHighlighter = styled.div<{ active: boolean }>`
  border-top: 4px solid ${(props) => (props.active ? '#f58f3b' : 'transparent')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const TabIcon = styled.span`
  display: none;
  @media ${device.mobile} {
    display: inline-block;
    width: 32px;
    height: 32px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media ${device.smallMobile} {
    width: 20px;
    height: 20px;
  }
`;

const TabText = styled.span`
  @media ${device.mobile} {
    display: none;
  }
`;

const TabCount = styled.span<{ active: boolean }>`
  padding: 2px 5px 2px 6px;
  color: ${(props) => (props.active ? '#ffffff' : '#6b7582')};
  background-color: ${(props) => (props.active ? '#f58f3b' : 'rgba(154, 168, 187, 0.16)')};
  border-radius: 16px;
  margin-left: 8px;
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
const Tab = styled(
  ({
    className,
    children,
    active,
    error,
    ...rest
  }: {
    className?: string;
    children: React.ReactChild;
    active: boolean;
    disabled?: boolean;
    error?: boolean;
  }) => {
    return (
      <li className={className} {...rest}>
        {children}
        <LinkHighlighter active={active} />
      </li>
    );
  }
)`
  margin-right: 36px;
  @media ${device.mobile} {
    margin-right: 24px;
  }
  margin-bottom: -1px;

  a {
    display: block;
    padding: 8px;
    text-decoration: inherit;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    font-family: lato-bold;
    color: ${(props) => (props.active ? `#25282d` : `#6b7582`)};
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    cursor: ${(props) => (props.disabled ? `not-allowed` : 'pointer')};
    ${(props) =>
      props.error &&
      `
      &:after {
        content: '';
        width: 5px;
        height: 5px;
        display: inline-block;
        position: absolute;
        background-color: #f56880;
        border-radius: 50%;
        margin-top: 6px;
        margin-left: 5px;
      }
    `}
  }
`;

export interface Props<T extends string> {
  children: Array<TabChild<T>>;
  active?: string;
  onTabChange: (tab: string) => void;
  disabled?: boolean;
}

export const Tabs = <T extends string>({ active, children, onTabChange, disabled }: Props<T>) => {
  const activeTab = children.find((item: TabChild<T>) => item.name === active);

  const onClick = (name: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onTabChange(name);
  };

  return (
    <>
      <NavElement>
        {children.map(
          ({
            name,
            icon,
            title,
            count,
            plusSymbol,
            ...rest
          }: {
            name: string;
            icon: React.ReactNode;
            title: string;
            count?: number | undefined;
            plusSymbol?: boolean;
          }) => {
            return (
              <Tab {...rest} active={name === active} key={name} disabled={disabled} data-testid={`tab-${name}`}>
                <a href="#" onClick={onClick(name)} aria-label={title}>
                  <TabIcon>{icon}</TabIcon>
                  <TabText>{title}</TabText>
                  {count! > -1 && (
                    <TabCount active={name === active}>
                      <FormattedNumber value={Number(count)} notation={'compact'} compactDisplay={'short'} />
                      {plusSymbol && name === 'attendees' && <span>+</span>}
                    </TabCount>
                  )}
                </a>
              </Tab>
            );
          }
        )}
      </NavElement>
      <NavBody disabled={disabled}>{activeTab ? activeTab.child : null}</NavBody>
    </>
  );
};
