import styled from '../../../../styled-components';
import * as React from 'react';
import { cleanValueForInputField, formatForInputField } from '../../../../../lib/meetingid';
import device from '../devices';
import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex: auto;
`;

const StyledInput = styled.input<{ hasError?: boolean }>`
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  padding: 0px 8px;
  height: 36px;
  line-height: 36px;
  border: 1px solid ${(props) => (props.hasError ? '#f56880' : '#9aa8bb')};
  border-radius: 4px;
  background-color: transparent;
  font-family: 'lato-regular';
  font-size: 14px;

  &:focus {
    outline: none;
    ${(props) =>
      props.hasError &&
      `
    box-shadow: 0 0 2px #f56880;
    `};
  }

  @media ${device.mobile} {
    height: 32px;
    line-height: 32px;
  }
`;

const messages = defineMessages({
  invalidMeetingId: {
    id: 'common.joinMeetingForm.invalidMeetingId',
    defaultMessage: 'This meeting ID or link is invalid. Please check your invitation and try again.'
  }
});

interface Props {
  value: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  placeholder?: string;
  g2mmUrlPrefix?: string;
  unifiedLaunchUrlPrefix?: string;
  hasError?: boolean;
}

export const MeetingIdInput = ({
  value,
  g2mmUrlPrefix,
  unifiedLaunchUrlPrefix,
  onChange,
  hasError,
  ...props
}: Props) => {
  const { formatMessage: f } = useIntl();
  const formattedValue = formatForInputField(value, g2mmUrlPrefix, unifiedLaunchUrlPrefix);
  const input = useRef((null as any) as HTMLInputElement);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      const cleanValue = cleanValueForInputField(event.target.value, g2mmUrlPrefix, unifiedLaunchUrlPrefix);
      onChange(cleanValue);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
    ReactTooltip.show(input.current);
    if (hasError) {
      window.setTimeout(() => ReactTooltip.hide(), 5000);
    }
  }, [hasError]);

  return (
    <InputWrapper>
      <StyledInput
        id="meeting-id-input"
        type="text"
        value={formattedValue}
        onChange={onChangeHandler}
        hasError={hasError}
        data-tip={hasError ? f(messages.invalidMeetingId) : undefined}
        data-testid="meeting-id-input"
        data-type="error"
        data-place="bottom"
        data-event="none"
        ref={input}
        {...props}
        aria-label={props.placeholder}
      />
    </InputWrapper>
  );
};
