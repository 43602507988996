import moment from 'moment-timezone';
import { defineMessages, IntlShape } from 'react-intl';

const messages = defineMessages({
  'Pacific/Tongatapu': {
    id: 'timezone.Pacific/Tongatapu',
    defaultMessage: 'Nukualofa'
  },
  'Pacific/Fiji': {
    id: 'timezone.Pacific/Fiji',
    defaultMessage: 'Fiji, Kamchatka, Marshall Is.'
  },
  'Pacific/Auckland': {
    id: 'timezone.Pacific/Auckland',
    defaultMessage: 'Auckland, Wellington'
  },
  'Asia/Magadan': {
    id: 'timezone.Asia/Magadan',
    defaultMessage: 'Magadan, Solomon Is., New Caledonia'
  },
  'Asia/Vladivostok': {
    id: 'timezone.Asia/Vladivostok',
    defaultMessage: 'Vladivostok'
  },
  'Australia/Hobart': {
    id: 'timezone.Australia/Hobart',
    defaultMessage: 'Hobart'
  },
  'Pacific/Guam': {
    id: 'timezone.Pacific/Guam',
    defaultMessage: 'Guam, Port Moresby'
  },
  'Australia/Sydney': {
    id: 'timezone.Australia/Sydney',
    defaultMessage: 'Canberra, Melbourne, Sydney'
  },
  'Australia/Brisbane': {
    id: 'timezone.Australia/Brisbane',
    defaultMessage: 'Brisbane'
  },
  'Australia/Darwin': {
    id: 'timezone.Australia/Darwin',
    defaultMessage: 'Darwin'
  },
  'Australia/Adelaide': {
    id: 'timezone.Australia/Adelaide',
    defaultMessage: 'Adelaide'
  },
  'Asia/Yakutsk': {
    id: 'timezone.Asia/Yakutsk',
    defaultMessage: 'Yakutsk'
  },
  'Asia/Seoul': {
    id: 'timezone.Asia/Seoul',
    defaultMessage: 'Seoul'
  },
  'Asia/Tokyo': {
    id: 'timezone.Asia/Tokyo',
    defaultMessage: 'Osaka, Sapporo, Tokyo'
  },
  'Asia/Taipei': {
    id: 'timezone.Asia/Taipei',
    defaultMessage: 'Taipei'
  },
  'Australia/Perth': {
    id: 'timezone.Australia/Perth',
    defaultMessage: 'Perth'
  },
  'Asia/Singapore': {
    id: 'timezone.Asia/Singapore',
    defaultMessage: 'Kuala Lumpur, Singapore'
  },
  'Asia/Irkutsk': {
    id: 'timezone.Asia/Irkutsk',
    defaultMessage: 'Irkutsk, Ulaan Bataar'
  },
  'Asia/Shanghai': {
    id: 'timezone.Asia/Shanghai',
    defaultMessage: 'Beijing, Chongqing, Hong Kong, Urumqi'
  },
  'Asia/Krasnoyarsk': {
    id: 'timezone.Asia/Krasnoyarsk',
    defaultMessage: 'Krasnoyarsk'
  },
  'Asia/Bangkok': {
    id: 'timezone.Asia/Bangkok',
    defaultMessage: 'Bangkok'
  },
  'Asia/Jakarta': {
    id: 'timezone.Asia/Jakarta',
    defaultMessage: 'Hanoi, Jakarta'
  },
  'Asia/Rangoon': {
    id: 'timezone.Asia/Rangoon',
    defaultMessage: 'Rangoon'
  },
  'Asia/Dhaka': {
    id: 'timezone.Asia/Dhaka',
    defaultMessage: 'Astana, Dhaka'
  },
  'Asia/Novosibirsk': {
    id: 'timezone.Asia/Novosibirsk',
    defaultMessage: 'Almaty, Novosibirsk'
  },
  'Asia/Katmandu': {
    id: 'timezone.Asia/Katmandu',
    defaultMessage: 'Kathmandu'
  },
  'Asia/Colombo': {
    id: 'timezone.Asia/Colombo',
    defaultMessage: 'Colombo, Sri Jayawardenepura'
  },
  'Asia/Calcutta': {
    id: 'timezone.Asia/Calcutta',
    defaultMessage: 'Calcutta, Chennai, Mumbai, New Delhi'
  },
  'Asia/Karachi': {
    id: 'timezone.Asia/Karachi',
    defaultMessage: 'Islamabad, Karachi, Tashkent'
  },
  'Asia/Yekaterinburg': {
    id: 'timezone.Asia/Yekaterinburg',
    defaultMessage: 'Ekaterinburg'
  },
  'Asia/Kabul': {
    id: 'timezone.Asia/Kabul',
    defaultMessage: 'Kabul'
  },
  'Asia/Tbilisi': {
    id: 'timezone.Asia/Tbilisi',
    defaultMessage: 'Baku, Tbilisi, Yerevan'
  },
  'Asia/Muscat': {
    id: 'timezone.Asia/Muscat',
    defaultMessage: 'Abu Dhabi, Muscat'
  },
  'Asia/Tehran': {
    id: 'timezone.Asia/Tehran',
    defaultMessage: 'Tehran'
  },
  'Africa/Nairobi': {
    id: 'timezone.Africa/Nairobi',
    defaultMessage: 'Nairobi'
  },
  'Europe/Moscow': {
    id: 'timezone.Europe/Moscow',
    defaultMessage: 'Moscow, St. Petersburg, Volgograd'
  },
  'Asia/Kuwait': {
    id: 'timezone.Asia/Kuwait',
    defaultMessage: 'Kuwait, Riyadh'
  },
  'Asia/Baghdad': {
    id: 'timezone.Asia/Baghdad',
    defaultMessage: 'Baghdad'
  },
  'Asia/Jerusalem': {
    id: 'timezone.Asia/Jerusalem',
    defaultMessage: 'Jerusalem'
  },
  'Europe/Helsinki': {
    id: 'timezone.Europe/Helsinki',
    defaultMessage: 'Helsinki, Riga, Tallinn'
  },
  'Africa/Harare': {
    id: 'timezone.Africa/Harare',
    defaultMessage: 'Harare, Pretoria'
  },
  'Africa/Cairo': {
    id: 'timezone.Africa/Cairo',
    defaultMessage: 'Cairo'
  },
  'Europe/Bucharest': {
    id: 'timezone.Europe/Bucharest',
    defaultMessage: 'Bucharest'
  },
  'Europe/Athens': {
    id: 'timezone.Europe/Athens',
    defaultMessage: 'Athens, Sofia, Minsk, Vilnius'
  },
  'Africa/Malabo': {
    id: 'timezone.Africa/Malabo',
    defaultMessage: 'West Central Africa'
  },
  'Europe/Warsaw': {
    id: 'timezone.Europe/Warsaw',
    defaultMessage: 'Sarajevo, Skopje, Warsaw, Zagreb'
  },
  'Europe/Brussels': {
    id: 'timezone.Europe/Brussels',
    defaultMessage: 'Brussels, Copenhagen, Madrid, Paris'
  },
  'Europe/Prague': {
    id: 'timezone.Europe/Prague',
    defaultMessage: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague'
  },
  'Europe/Amsterdam': {
    id: 'timezone.Europe/Amsterdam',
    defaultMessage: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'
  },
  GMT: {
    id: 'timezone.GMT',
    defaultMessage: 'Greenwich Mean Time'
  },
  'Europe/London': {
    id: 'timezone.Europe/London',
    defaultMessage: 'Dublin, Edinburgh, Lisbon, London'
  },
  'Africa/Casablanca': {
    id: 'timezone.Africa/Casablanca',
    defaultMessage: 'Casablanca, Monrovia'
  },
  'Atlantic/Cape_Verde': {
    id: 'timezone.Atlantic/Cape_Verde',
    defaultMessage: 'Cape Verde Is.'
  },
  'Atlantic/Azores': {
    id: 'timezone.Atlantic/Azores',
    defaultMessage: 'Azores'
  },
  'America/Buenos_Aires': {
    id: 'timezone.America/Buenos_Aires',
    defaultMessage: 'Buenos Aires, Georgetown'
  },
  'America/Sao_Paulo': {
    id: 'timezone.America/Sao_Paulo',
    defaultMessage: 'Brasilia'
  },
  'America/St_Johns': {
    id: 'timezone.America/St_Johns',
    defaultMessage: 'Newfoundland'
  },
  'America/Santiago': {
    id: 'timezone.America/Santiago',
    defaultMessage: 'Santiago'
  },
  'America/Caracas': {
    id: 'timezone.America/Caracas',
    defaultMessage: 'Caracas, La Paz'
  },
  'America/Halifax': {
    id: 'timezone.America/Halifax',
    defaultMessage: 'Atlantic Time (Canada)'
  },
  'America/Indianapolis': {
    id: 'timezone.America/Indianapolis',
    defaultMessage: 'Indiana (East)'
  },
  'America/New_York': {
    id: 'timezone.America/New_York',
    defaultMessage: 'Eastern Time (US and Canada)'
  },
  'America/Bogota': {
    id: 'timezone.America/Bogota',
    defaultMessage: 'Bogota, Lima, Quito'
  },
  'America/Mexico_City': {
    id: 'timezone.America/Mexico_City',
    defaultMessage: 'Mexico City'
  },
  'America/Chicago': {
    id: 'timezone.America/Chicago',
    defaultMessage: 'Central Time (US and Canada)'
  },
  'America/Denver': {
    id: 'timezone.America/Denver',
    defaultMessage: 'Mountain Time (US and Canada)'
  },
  'America/Phoenix': {
    id: 'timezone.America/Phoenix',
    defaultMessage: 'Arizona'
  },
  'America/Los_Angeles': {
    id: 'timezone.America/Los_Angeles',
    defaultMessage: 'Pacific Time (US and Canada), Tijuana'
  },
  'America/Anchorage': {
    id: 'timezone.America/Anchorage',
    defaultMessage: 'Alaska'
  },
  'Pacific/Honolulu': {
    id: 'timezone.Pacific/Honolulu',
    defaultMessage: 'Hawaii'
  },
  'Pacific/Samoa': {
    id: 'timezone.Pacific/Samoa',
    defaultMessage: 'Midway Island, Samoa'
  }
});

export const TimeZones = [
  'Pacific/Tongatapu',
  'Pacific/Fiji',
  'Pacific/Auckland',
  'Asia/Magadan',
  'Asia/Vladivostok',
  'Australia/Hobart',
  'Pacific/Guam',
  'Australia/Sydney',
  'Australia/Brisbane',
  'Australia/Darwin',
  'Australia/Adelaide',
  'Asia/Yakutsk',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Asia/Taipei',
  'Australia/Perth',
  'Asia/Singapore',
  'Asia/Irkutsk',
  'Asia/Shanghai',
  'Asia/Krasnoyarsk',
  'Asia/Bangkok',
  'Asia/Jakarta',
  'Asia/Rangoon',
  'Asia/Dhaka',
  'Asia/Novosibirsk',
  'Asia/Katmandu',
  'Asia/Colombo',
  'Asia/Calcutta',
  'Asia/Karachi',
  'Asia/Yekaterinburg',
  'Asia/Kabul',
  'Asia/Tbilisi',
  'Asia/Muscat',
  'Asia/Tehran',
  'Africa/Nairobi',
  'Europe/Moscow',
  'Asia/Kuwait',
  'Asia/Baghdad',
  'Asia/Jerusalem',
  'Europe/Helsinki',
  'Africa/Harare',
  'Africa/Cairo',
  'Europe/Bucharest',
  'Europe/Athens',
  'Africa/Malabo',
  'Europe/Warsaw',
  'Europe/Brussels',
  'Europe/Prague',
  'Europe/Amsterdam',
  'GMT',
  'Europe/London',
  'Africa/Casablanca',
  'Atlantic/Cape_Verde',
  'Atlantic/Azores',
  'America/Buenos_Aires',
  'America/Sao_Paulo',
  'America/St_Johns',
  'America/Santiago',
  'America/Caracas',
  'America/Halifax',
  'America/Indianapolis',
  'America/New_York',
  'America/Bogota',
  'America/Mexico_City',
  'America/Chicago',
  'America/Denver',
  'America/Phoenix',
  'America/Los_Angeles',
  'America/Anchorage',
  'Pacific/Honolulu',
  'Pacific/Samoa'
];

const formatTimeZoneOffset = (offset: number) => {
  return (
    (offset >= 0 ? '+' : '-') +
    ('0' + Math.floor(Math.abs(offset))).slice(-2) +
    ':' +
    ('0' + Math.round((Math.abs(offset) % 1) * 60)).slice(-2)
  );
};

export const getTimeZonesTranslated = (intl: IntlShape) => {
  return TimeZones.map((region) => {
    const numericOffset = moment.tz(region).utcOffset() / 60;
    const formattedOffset = formatTimeZoneOffset(numericOffset);
    const regionName = intl.formatMessage(messages[region as keyof typeof messages]);

    return {
      offset: formattedOffset,
      zone: numericOffset === 0 ? `GMT ${regionName}` : `GMT${formattedOffset} ${regionName}`,
      numericOffset,
      region
    };
  }).sort((tz1, tz2) => tz2.numericOffset - tz1.numericOffset);
};
