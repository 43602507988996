import Logger from '@getgo/logger';
import LoggingServiceLogTarget from '@getgo/logger-target-logging-service';
import platform from 'platform';
import config from 'config';
import machine from '../../lib/machine';

function createAppLogger() {
  const plugins = [];

  if (['development', 'production'].includes(config.env)) {
    plugins.push(
      new LoggingServiceLogTarget({
        server: config.logging.url,
        context: {
          ls_source: config.logging.source.web,
          product: config.name.web,
          env: config.env
        }
      })
    );
  }

  const appLogger = Logger.createRoot(plugins);

  const loggingId = Date.now();
  appLogger.setContext('loggingId', loggingId);
  appLogger.setContext('machineId', machine.id);
  // eslint-disable-next-line no-undef
  appLogger.setContext('version', VERSION);
  // eslint-disable-next-line no-undef
  appLogger.setContext('buildVersion', BUILD);
  appLogger.setContext('browser', {
    name: platform.name,
    version: platform.version,
    'os.family': platform.os ? platform.os.family : 'unknown OS',
    'os.version': platform.os ? platform.os.version : 'unknown version',
    language: navigator.language
  });

  return appLogger;
}

const logger = createAppLogger();
export default logger;

export type LoggerType = typeof logger;
